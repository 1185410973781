import { useMemo } from "react";

const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
};

export const DOTS = "...";

export const usePagination = ({
    currentPage,
    totalPageCount,
    siblingCount = 1,
    boundaryPageCount = 1,
}) => {
    return useMemo(() => {
        const totalPageNumbers = siblingCount + 5;

        if (totalPageCount <= totalPageNumbers) {
            return range(1, totalPageCount);
        }

        const leftBoundary = range(1, boundaryPageCount);
        const rightBoundary = range(
            totalPageCount - boundaryPageCount + 1,
            totalPageCount,
        );

        const startPage = Math.max(2, currentPage - siblingCount);
        const endPage = Math.min(
            totalPageCount - 1,
            currentPage + siblingCount,
        );

        const shouldShowLeftDots = startPage > 2;
        const shouldShowRightDots = endPage < totalPageCount - 1;

        const middleRange = range(startPage, endPage);

        if (!shouldShowLeftDots && shouldShowRightDots) {
            const leftItemCount = 3 + 2 * siblingCount;
            const leftRange = range(1, leftItemCount);
            return [...leftRange, DOTS, ...rightBoundary];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            const rightItemCount = 3 + 2 * siblingCount;
            const rightRange = range(
                totalPageCount - rightItemCount + 1,
                totalPageCount,
            );
            return [...leftBoundary, DOTS, ...rightRange];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            return [
                ...leftBoundary,
                DOTS,
                ...middleRange,
                DOTS,
                ...rightBoundary,
            ];
        }
    }, [currentPage, totalPageCount, siblingCount, boundaryPageCount]);
};
