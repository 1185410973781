import Input from "components/OldInput";
import Loader from "components/Loader/Loader";
import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    > .title {
        font-size: 2rem;
        padding: 1rem;
    }
    > .description {
        padding: 0 1rem;
    }

    > .form {
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    > .buttons {
        padding: 1rem;
    }

    ul {
        padding: 1rem 0;
        > li {
            list-style-type: disc;
            list-style-position: outside;
            margin-left: 2rem;
        }
    }

    h2 {
        font-size: 20px;
    }

    > .error {
        padding: 0 1rem;
        color: #a00;
        font-weight: bold;
    }
`;

function Component(props) {
    const context = useContext(Context);
    const [formData, setFormData] = useState(null);
    const [plans, setPlans] = useState([]);

    const addQuickButik = async () => {
        const customerId = context.user.customerId;
        const data = {
            ...formData,
            planId: plans ? plans[0].id : null,
        };
        await Api.createCustomerIntegration({ customerId, data })
            .then((response) => {
                props.history.push(`/addons/${response.id}`);
            })
            .catch((error) => console.log(error));
    };
    async function loadIntegration() {
        setPlans(
            await Api.getIntegrationPlans({
                integrationId: 1,
            }),
        );
    }

    useEffect(() => {
        loadIntegration().catch((error) => console.error(error));
    }, []);

    if (!plans) return <Loader />;

    return (
        <Container>
            <div className="title">Lägg till Quickbutik</div>
            <div className="description">
                Följ stegen nedan för att aktivera integrationen mot Quickbutik
            </div>
            <h2 className="ml-4 mt-5">Aktivera webhook:</h2>
            <ul className="steps mb-5">
                <li>
                    Öppna Quickbutik och gå till <strong>Settings</strong>.
                    <br />
                    Aktivera och öppna <strong>Webhook</strong>.
                </li>
                <li>
                    Skapa en ny webhook och lägg in denna adress:
                    <br />
                    <strong>
                        {process.env.REACT_APP_API_URL}
                        /api/wh/9cOiJGxs07di61420qkdJvWO21K1GSI821HA3xxu61035cEd4Da8Z0emWRi6buE4
                    </strong>
                </li>
                <li>
                    Se till att <strong>secret key</strong> och{" "}
                    <strong>order.new</strong> är ikryssade.
                </li>
                <li>
                    När du fått din nyckel får du även en hash som du kopierar
                    och klistrar in i fältet för "Secret Hash" nedan.
                </li>
            </ul>

            <h2 className="ml-4 mt-3">API Steps:</h2>
            <ul className="steps mb-5">
                <li>
                    Öppna Quickbutik och gå till <strong>Settings</strong>.
                    <br />
                    Aktivera och öppna <strong>API</strong>.
                </li>
                <li>
                    Skapa en ny API-nyckel och döp den till valfritt namn (tex
                    'Zendr'). När du sparar får du tillgång till din API-nyckel
                    som du kopierar och klistrar in i fältet nedan för 'API
                    Key'.
                </li>
            </ul>
            {plans.length > 0 && (
                <div className="form">
                    <Input
                        type="list"
                        onChange={setFormData}
                        object={{
                            type: {
                                type: "text",
                                hidden: true,
                                value: "QuickButik",
                            },
                            apiKey: {
                                type: "text",
                                hidden: false,
                                placeholder: "Klistra in din api-nyckel",
                                title: "API Key",
                            },
                            webhookSecret: {
                                type: "text",
                                hidden: false,
                                placeholder: "Klistra in din hash",
                                title: "Secret Hash",
                            },
                        }}
                    />
                    <div className="buttons">
                        <button
                            onClick={addQuickButik}
                            className="c-button c-button--raised mt-5"
                        >
                            Aktivera
                        </button>
                    </div>
                </div>
            )}
        </Container>
    );
}

export default Component;
