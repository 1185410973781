import moment from "moment";
import Papa from "papaparse";
import { v4 as uuidv4 } from "uuid";

function parseUPSOrderRow({ row, fileName }) {
    // Label "Adresskorrigering": When row[34] == "ADJ" and row[35] == "ADC"
    // Label "Privatleverans": When row[34] == "ADJ" and row[35] == "RADJ"
    // Label "Fraktkostnad": When row[34] == "SHP" and row[43] == "FRT"
    // Label "Bränsle": When row[34] == "SHP" and row[43] == "FSC"
    // Add tax if row[49] === 1
    // Ignore: When row[43] == "INF" || "EXM"
    // Ignore: When row[43] == "TAX"

    // row[34] = "ADJ", "SHP";
    // row[35] = "ADC" means address correction - label it as "Adresskorrigering"
    // row[35] = "RADJ" and other shit.. RADJ is the only one interesting.
    // row[43] = "TAX" means that it is a tax, "FRT" means it's a shipment, "FSC" means it is fuel

    let type = "extra";
    let label = row[45];

    if (row[34] === "ADJ" && row[35] === "ADC") {
        label = "Adresskorrigering";
    } else if (row[34] === "ADJ" && row[35] === "RADJ") {
        label = "Privatleverans";
    } else if (
        row[34] === "ADJ" &&
        row[43] === "FRT" &&
        row[45] === "Transport" &&
        row[174]
    ) {
        label = row[174];
    } else if (["SHP", "RTN"].indexOf(row[34]) >= 0 && row[43] === "FRT") {
        type = "transport";
    } else if (["SHP", "RTN"].indexOf(row[34]) >= 0 && row[43] === "FSC") {
        label = "Bränsle";
    }

    if (["TAX", "INF", "EXM"].indexOf(row[43]) >= 0) {
        return null;
    }

    if (type === "transport") {
        let totalShipmentCost = parseFloat(row[52]);
        if (row[73] !== "SE" && row[81] === "SE") {
            // Add 25 SEK since there's a pickup cost at some locations.
            // 25 SEK is just an average price just to cover the expenses.
            // This is added in the UPS quoting logic as well. The numbers should be synced.
            totalShipmentCost += 25;
        }
        const vat = row[49] == 1;

        const totalShipmentCostVat = vat ? totalShipmentCost : 0;
        const totalShipmentCostNoVat = !vat ? totalShipmentCost : 0;

        return {
            type: type,
            trackingNumber: row[13],
            shipment: {
                fileName: fileName,
                transporter: "UPS",
                trackingNumber: row[13],
                reference: row[15],
                currentInvoiceNumber: row[5],
                date: moment(row[11]).format("YYYY-MM-DD"),
                deliveryDate: moment(row[11]).format("YYYY-MM-DD"),
                title: row[45],
                sender: [row[66], row[67]].filter((v) => v).join(", "),
                senderCountry: row[73],
                recipient: row[75],
                recipientCountry: row[81],
                totalShipmentCost: totalShipmentCost,
                totalShipmentCostVat: totalShipmentCostVat,
                totalShipmentCostNoVat: totalShipmentCostNoVat,
                vat: vat,
                rows: [
                    {
                        title: "Fakturerad vikt: " + row[28],
                        key: uuidv4(),
                    },
                ],
            },
        };
    } else {
        if (label.indexOf("Returer ") === 0) {
            label = label.substr(8);
        }
        if (
            [
                "Avgift f�r f�rbjuden vara",
                "Avgift fï¿½r fï¿½rbjuden vara",
            ].indexOf(label) >= 0
        ) {
            label = "Avgift för förbjuden vara";
        }
        if (["T.avg. avl�gset omr. - destination"].indexOf(label) >= 0) {
            label = "T.avg. avlägset omr. - destination";
        }
        if (["T.avg. upph. avl�g. omr. - ursprung"].indexOf(label) >= 0) {
            label = "T.avg. upph. avläg. omr. - ursprung";
        }
        if ([" T.avg. f�r extra avl�gset omr�de"].indexOf(label) >= 0) {
            label = " T.avg. för extra avlägset område";
        }
        if (["Gr�nsavgift f�r�Storbritannien"].indexOf(label) >= 0) {
            label = "Gränsavgift för Storbritannien";
        }
        if (["Avgift f�r tull- och momshantering"].indexOf(label) >= 0) {
            label = "Avgift för tull- och momshantering";
        }
        if (["H�gs�songsavgift - Bostad"].indexOf(label) >= 0) {
            label = "Högsäsongsavgift - Bostad";
        }
        if (["H�gs�songsavgift - F�retag"].indexOf(label) >= 0) {
            label = "Högsäsongsavgift - Företag";
        }
        if (["H�gs�songsavgift - Extrahantering"].indexOf(label) >= 0) {
            label = "Högsäsongsavgift - Extrahantering";
        }
        if (["Till�ggsavgift f�r stora paket"].indexOf(label) >= 0) {
            label = "Tilläggsavgift för stora paket";
        }
        if (["Utl�ggsers�ttning"].indexOf(label) >= 0) {
            label = "Utläggsersättning";
        }
        if (["Inf�rselavgift"].indexOf(label) >= 0) {
            label = "Införselavgift";
        }
        if (["Inf�rselmoms"].indexOf(label) >= 0) {
            label = "Införselmoms";
        }
        if (label === "�H�gs�songsavgift - �ve�rskr. dim.") {
            label = "Högsäsongsavgift - Överskr. dim.";
        } else if (label === "H�gs�songsavgift - Stort paket") {
            label = "Högsäsongsavgift - Stort paket.";
        } else if (label === "�ver h�gsta vikt") {
            label = "Över högsta vikt";
        } else if (label === "Deklarerat varuv�rde") {
            label = "Deklarerat varuvärde";
        } else if (label === "H�gs�song/belastningsavg. - F�retag") {
            label = "Högsäsong/belastningsavg. - Företag";
        }
        let addSurcharge = false;
        if (["Bränsle", "Returer Bränsletillägg"].indexOf(label) >= 0) {
            addSurcharge = true;
        }

        let isInsurance = false;

        if (label === "Insurance") {
            isInsurance = true;
        }

        const vat = row[49] == 1;

        let totalShipmentCostVat = vat ? Math.round(row[52]) : 0;
        let totalShipmentCostNoVat = !vat ? Math.round(row[52]) : 0;

        return {
            type: "extra",
            trackingNumber: row[13],
            shipment: {
                transporter: "UPS",
                fileName: fileName,
                trackingNumber: row[13],
                reference: row[15],
                currentInvoiceNumber: row[5],
                date: moment(row[11]).format("YYYY-MM-DD"),
                deliveryDate: moment(row[11]).format("YYYY-MM-DD"),
                title: row[45],
                sender: row[67],
                senderCountry: row[73],
                recipient: row[75],
                recipientCountry: row[81],
                totalShipmentCost: 0,
                totalShipmentCostVat: totalShipmentCostVat,
                totalShipmentCostNoVat: totalShipmentCostNoVat,
                vat: vat,
                rows: [
                    {
                        title: "Fakturerad vikt: " + row[28],
                        key: uuidv4(),
                    },
                ],
            },
            row: {
                title: label,
                vat: vat,
                cost: Math.round(row[52]),
                isInsurance,
                surcharge: addSurcharge,
                key: uuidv4(),
            },
        };
    }
}

function parse({ data, fileName }) {
    let rows = Papa.parse(data, {
        delimiter: ",",
    });

    if (rows.errors.length) {
        window.alert(
            "Filen är inte korrekt formatterad. Granska (!!!) och skicka till Tobbe om något saknas.\nFelmeddelanden:\n" +
                rows.errors.map((err) => err.message).join("\n"),
        );
    }

    let orders = {};

    for (const row of rows.data) {
        if (!row[13]) {
            continue;
        }
        if (row[13].indexOf("1Z") != 0 && !row[20].indexOf("1Z")) {
            row[13] = row[20];
        }
        let order = parseUPSOrderRow({ row, fileName });

        if (order && order.trackingNumber.startsWith("1Z")) {
            if (!orders[order.trackingNumber]) {
                if (order.type === "transport") {
                    orders[order.trackingNumber] = order.shipment;
                } else {
                    orders[order.trackingNumber] = {
                        ...order.shipment,
                        rows: [order.row],
                    };
                }
            } else {
                if (order.type === "transport") {
                    orders[order.trackingNumber] = {
                        ...order.shipment,
                        totalShipmentCostVat:
                            order.totalShipmentCostVat +
                            orders[order.trackingNumber].totalShipmentCostVat,
                        totalShipmentCostNoVat:
                            order.totalShipmentCostNoVat +
                            orders[order.trackingNumber].totalShipmentCostNoVat,
                        rows: orders[order.trackingNumber].rows,
                    };
                } else {
                    orders[order.trackingNumber].totalShipmentCostVat +=
                        order.totalShipmentCostVat;
                    orders[order.trackingNumber].totalShipmentCostNoVat +=
                        order.totalShipmentCostNoVat;
                    orders[order.trackingNumber].rows.push(order.row);
                }
            }
        }
    }

    return Object.keys(orders).map((key) => {
        // If shipment cost is zero it means we are invoicing some additional cost so we skip this.
        if (orders[key].totalShipmentCost > 0) {
            orders[key].totalShipmentCost = orders[key].rows.reduce(
                (acc, row) => {
                    return acc + (row.cost || 0);
                },
                orders[key].totalShipmentCost,
            );
        }
        return orders[key];
    });
}

export default {
    parse,
};
