import React, { useEffect, useState } from "react";
import Api from "services/Api/Api";
import SalesCustomersTable from "./SalesCustomersTable";
import SalesCustomersFilter from "./SalesCustomersFilter";
import WeekFilter from "./WeekFilter";

const SalesCustomers = () => {
    const [salesCustomers, setSalesCustomers] = useState(null);
    const [clientManagers, setClientManagers] = useState(null);
    const [clientManagerId, setClientManagerId] = useState(null);
    const [isLastWeek, setLastWeek] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // fetch sales customers with firsh booking for current week
    const fetchSalesCustomers = async (
        clientManagerId = null,
        fetchLastWeek = isLastWeek,
    ) => {
        try {
            setLoading(true);
            const report = await Api.getSalesCustomersCurrentWeek(
                clientManagerId,
                fetchLastWeek,
            );
            setSalesCustomers(report);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false); // Ensure that loading is set to false in all cases.
        }
    };

    // fetch client managers and prepare data for filters
    const fetchClientManagers = async () => {
        try {
            const clientManagers = await Api.getClientManagers({
                includeOwners: true,
            });
            const mappedClientManagers = clientManagers.map(({ name, id }) => ({
                title: name,
                value: id,
            }));
            setClientManagers(mappedClientManagers);
        } catch (error) {
            setError(error);
        }
    };

    // handles filter for specific sales person
    const handleSalesFilter = ({ target: { value } }) => {
        switch (value) {
            case "All":
                setClientManagerId(null);
                break;
            default:
                setClientManagerId(value);
                break;
        }
    };

    useEffect(() => {
        fetchSalesCustomers(clientManagerId);
        !clientManagers && fetchClientManagers();
    }, [clientManagerId, isLastWeek]);

    return (
        <div className="p-5 w-full">
            <div className="flex justify-between items-center">
                <h3 className="font-bold uppercase pl-0 p-3">
                    Kunder med första bokning denna vecka
                </h3>
                <div className={"flex gap-3"}>
                    <WeekFilter onChange={setLastWeek} />
                    <SalesCustomersFilter
                        clientManagers={clientManagers}
                        onChange={handleSalesFilter}
                    />
                </div>
            </div>
            <SalesCustomersTable
                customers={salesCustomers}
                loading={isLoading}
                error={error}
            />
        </div>
    );
};

export default SalesCustomers;
