import React, { useState, useEffect, useContext } from "react";
import {
    selectedOrdersAreLoading,
    selectedOrdersHasNonEUAddress,
    selectedOrdersHaveDiffrentPickupDates,
    selectedOrdersHaveExpiredQuotes,
    selectedOrdersHaveInvalidFields,
} from "utils/batchTableUtils";
import useBatchStore from "../store";
import Context from "context/Global";

const DisplayMessage = () => {
    const selectedOrders = useBatchStore((state) => state.selectedOrders);
    const [message, setMessage] = useState({ text: "", type: "" });
    const context = useContext(Context);
    const settings = context.user.customer.settings;

    const getOrdersErrorMessage = () => {
        if (selectedOrdersHasNonEUAddress(selectedOrders)) {
            return setMessage({
                text: "För att boka försändelser utanför EU, skapa en ny försändelse.",
                type: "error",
            });
        }
        if (selectedOrdersHaveExpiredQuotes(selectedOrders)) {
            return setMessage({
                text: "Dina valda försändelser har utgångna priser.",
                type: "error",
            });
        }
        if (selectedOrdersHaveInvalidFields(selectedOrders, settings)) {
            return setMessage({
                text: "Dina valda försändelser har felaktiga fält.",
                type: "error",
            });
        }

        if (selectedOrdersHaveDiffrentPickupDates(selectedOrders)) {
            return setMessage({
                text: "Dina valda försändelser har olika estimerade upphämtningsdatum.",
                type: "info",
            });
        }

        if (message.text.length > 1) {
            return setMessage({ text: "", type: "" });
        }
    };

    const getMessageIcon = () => {
        if (message.type === "error") {
            return "/images/icons/warn-circle.svg";
        }
        if (message.type === "info") {
            return "/images/icons/info-circle.svg";
        }

        return "";
    };

    const getMessageStyle = () => {
        if (message.type === "error") {
            return "text-error-550";
        }
        if (message.type === "info") {
            return "text-purple";
        }

        return "";
    };

    useEffect(() => {
        getOrdersErrorMessage();
    }, [selectedOrders]);

    return (
        <div className={"flex gap-2 ml-2 py-3 h-12 pl-1 " + getMessageStyle()}>
            {message?.text.length > 1 && (
                <>
                    <img
                        src={getMessageIcon()}
                        className="w-6 h-6 text-purple"
                    />
                    <p>{message.text}</p>
                </>
            )}
        </div>
    );
};

export default DisplayMessage;
