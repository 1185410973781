/**
 * Checks if a given URL is an HTTPS URL.
 * @param {string} url - The URL to be tested.
 * @returns {boolean} True if the URL is an HTTPS URL, false otherwise.
 */
const isHttpsUrl = (url) => {
    const pattern = new RegExp(
        "^https:\\/\\/[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}(:[0-9]+)?(\\/.*)?$",
        "i",
    );
    return pattern.test(url);
};

export { isHttpsUrl };
