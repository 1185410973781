import React, { Component } from "react";
import styled from "styled-components";

let Container = styled.div`
    width: 100%;

    > .inputContainer {
        background: #fff;
        padding: 4px;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 40px;
        overflow: hidden;

        > input {
            padding: 4px;
            width: 100%;
            border: 0;
            outline: none;
        }

        > input[disabled] {
            background: inherit;
        }
    }

    &.is-invalid {
        > .inputContainer {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }
        > .message {
            color: rgba(255, 0, 0, 0.6);
        }
    }

    &.is-disabled {
        > .inputContainer {
            background: rgb(235, 235, 228);
            border: 1px solid rgba(0, 0, 0, 0.05);

            > input {
                color: rgba(0, 0, 0, 0.5);
                background: transparent;
            }
        }
    }
`;

class InputField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || "",
            disabled: props.disabled,
        };

        if (typeof this.props.onChange === "function") {
            this.onChange = this.props.onChange;
        }
    }

    onChange() {}

    value() {
        return this.state.value;
    }

    set(value) {
        this.setState({
            value: value,
        });
    }

    empty() {
        this.setState({
            value: "",
        });
    }

    validate() {
        if (this.props.required && this.state.value.length === 0) {
            this.setState({
                isInvalid: true,
            });
            return false;
        }
        this.setState({
            isInvalid: false,
        });
        return true;
    }
    render() {
        return (
            <Container
                className={
                    (this.state.disabled ? " is-disabled" : "") +
                    (this.state.isInvalid ? " is-invalid" : "")
                }
            >
                <div className="inputContainer">
                    <input
                        type="date"
                        {...this.props}
                        value={this.state.value}
                        onChange={(event) => {
                            this.setState(
                                {
                                    value: event.target.value,
                                    isInvalid: false,
                                },
                                () => {
                                    this.onChange(this.value());
                                },
                            );
                        }}
                    />
                </div>
            </Container>
        );
    }
}

InputField.defaultProps = {
    disabled: false,
};

export default InputField;
