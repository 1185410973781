import Context from "context/Global";
import React, { useContext, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

let updateAvailableAnimation = keyframes`
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
`;

let Container = styled.div`
	width: 100%;
    padding: 10px;
    box-sizing: border-box;
    box-shadow 0 0 4px 0 rgba(0,0,0,.4);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    > .c-logos {
        width: fit-content;
        height: 100%;
        display: flex;
        gap: 2.2rem;

        > .c-logo {
            width: 280px;
            height: 100%;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    > .updateAvailable {
        position: absolute;
        right: 0;
        animation: ${updateAvailableAnimation} 1s ease;
        background: rgba(200,220,255,1);
        color: #000;
        padding: .5em 2em;
        margin-right: 1em;
        border-radius: 5px;
        cursor: pointer;
        transition: all .3s ease;
        z-index: 2;

        &:hover {
            background: rgba(210,230,255,1);
            color: #555;
        }
    }

    > .version {
        margin-left: auto;
        opacity: 1;
        transition: opacity .3s ease;

        &.is-hidden {
            opacity: 0;
        }
    }
`;

function Component(props) {
    const context = useContext(Context);
    const [bannerText, setBannerText] = useState(null);

    useEffect(() => {
        const environment = process.env.REACT_APP_ENVIRONMENT;

        if (environment === "development") {
            setBannerText("LOCALHOST");
        } else if (environment === "test") {
            setBannerText("TEST");
        }
    }, []);

    return (
        <>
            <Container {...props}>
                <div className="c-logos">
                    <div className="c-logo"></div>
                </div>
                {bannerText && (
                    <div className="w-full p-2 mr-6 bg-red-600 text-white font-semibold text-center z-50">
                        {bannerText}
                    </div>
                )}
                <div className="version">v{context.appVersion}</div>
            </Container>
        </>
    );
}

export default Component;
