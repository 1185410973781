import useBatchStore from "views/Batch/store";

const useOrdersAndSelectedUpdater = () => {
    const setOrders = useBatchStore((state) => state.setOrders);
    const setSelectedOrders = useBatchStore((state) => state.setSelectedOrders);
    const orders = useBatchStore((state) => state.orders);
    const selectedOrders = useBatchStore((state) => state.selectedOrders);

    const updateOrdersAndSelectedOrders = (newOrders) => {
        const updatesLookup = newOrders.reduce((acc, order) => {
            acc[order.id] = order;
            return acc;
        }, {});

        const updatedOrders = orders.map((order) => {
            return updatesLookup[order.id]
                ? {
                      ...order,
                      ...updatesLookup[order.id],
                  }
                : order;
        });

        const updatedSelectedOrders = selectedOrders.map((order) => {
            return updatesLookup[order.id]
                ? {
                      ...order,
                      ...updatesLookup[order.id],
                  }
                : order;
        });

        setOrders(updatedOrders);
        setSelectedOrders(updatedSelectedOrders);
    };

    return {
        updateOrdersAndSelectedOrders,
    };
};

export default useOrdersAndSelectedUpdater;
