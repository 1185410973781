import React, {
    useState,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
} from "react";
import styled from "styled-components/macro";

let Container = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    overflow: hidden;
    cursor: pointer;
    position: relative;

    > .tick,
    > .partial {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: var(--color-good);
        padding: 4px;

        > svg {
            transition: transform 0.1s ease;
            transform: scale3d(0, 0, 1);
            width: 100%;
            height: 100%;
        }
    }

    &:focus {
        border: 1px solid rgba(0, 0, 0, 0.8);
        outline: none;
    }

    &.is-checked {
        border: 0;

        > .tick {
            opacity: 1;

            > svg {
                transform: scale3d(1, 1, 1);
            }
        }
    }

    &.is-partial {
        border: 0;

        > .partial {
            opacity: 1;

            > svg {
                transform: scale3d(1, 1, 1);
            }
        }
    }

    &.is-disabled {
        background: rgba(0, 0, 0, 0.1);
        pointer-events: none;

        > .tick,
        > .partial {
            box-shadow: 0;
            border: 0;
        }
    }

    &.is-disabled.is-checked {
        background: rgba(97, 184, 146, 0.5);
        border-color: rgba(97, 184, 146, 0.5);
        filter: grayscale(100%);

        > .tick > .partial {
            margin-left: 14px;
        }
    }
`;

function Component({ onChange, ...props }, ref) {
    const [value, setValue] = useState(
        typeof props.value === "undefined" ? false : props.value,
    );
    const [disabled, setDisabled] = useState(props.disabled || false);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        setDisabled(props.disabled);
    }, [props.disabled]);

    function onClick() {
        let newValue = !value;
        setValue(newValue);

        if (typeof onChange === "function") {
            onChange(newValue);
        }
    }

    return (
        <Container
            tabIndex={props.tabindex || "0"}
            className={
                (value === true ? " is-checked" : "") +
                (value === null ? " is-partial" : "") +
                (disabled ? " is-disabled" : "")
            }
            onClick={() => {
                onClick();
            }}
            onKeyPress={(ev) => {
                if (ev.key === " ") {
                    onClick();
                }
            }}
        >
            <div className="tick">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                    <path d="M0 11.522l1.578-1.626 7.734 4.619 13.335-12.526 1.353 1.354-14 18.646z" />
                </svg>
            </div>
            <div className="partial">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                    <path d="M0 10h24v4h-24z" />
                </svg>
            </div>
        </Container>
    );
}

export default forwardRef(Component);
