import InputDropdown from "components/Input/Dropdown";
import TextareaInput from "components/Input/Textarea";
import Context from "context/Global";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import ShipmentPackages from "views/Drafts/Create/Packages";
import CompanyForm from "views/Drafts/Create/Route/CompanyForm";

let Container = styled.div`
    box-sizing: border-box;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > .header {
        display: flex;
        align-items: center;

        > .edit {
            margin-left: auto;
            cursor: pointer;
        }
    }

    > .type {
        padding: 0 1rem;
        display: flex;
        justify-content: flex-start;
        gap: 1rem;

        > .text,
        > .status {
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            padding: 1rem;
        }
    }

    > .route {
        display: flex;
        gap: 1rem;
        padding: 0 1rem;
    }

    > .packages {
        padding: 0 1rem;
    }

    > .information {
        padding: 0 1rem;
        display: grid;
        grid: auto / 1fr 1fr;
        gap: 0 1rem;

        > .title {
            font-size: 1.2rem;
            margin-bottom: 0.2rem;
        }

        > .input {
            width: 100%;
        }
    }

    > .forwardingCompanies {
        padding: 0 1rem;

        > .title {
            font-size: 1.2rem;
            margin-bottom: 0.2rem;
        }

        > .emptyState {
            font-style: italic;
        }

        > .list {
            display: flex;
            gap: 1rem;

            > .company {
                display: flex;
                flex-direction: column;
                padding: 1rem;
                text-align: center;
                border-radius: 1rem;

                position: relative;

                > .delete {
                    position: absolute;
                    right: 0;
                    top: 0;
                    padding: 0.5rem;
                    width: 1.5rem;
                    height: 1.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    pointer-events: all;

                    > svg {
                        width: 100%;
                        height: 100%;
                    }
                }
                > .name {
                    font-weight: bold;
                }

                &:hover {
                    background: #dddeff;
                }

                &.is-selected {
                    background: #979bed;
                }

                &.is-used {
                    background: #aaedc0;
                    pointer-events: none;
                }
            }
        }
    }

    > .cost {
        padding: 0 1rem;
        display: grid;
        grid: auto / 1fr 1fr 1fr;
        gap: 0 1rem;

        > .title {
            font-size: 1.2rem;
            margin-bottom: 0.2rem;
        }

        > .customerPrice {
            display: flex;
            gap: 1rem;
        }
    }

    > .notes {
        padding: 1rem;

        > .title {
            font-size: 1.2rem;
            margin-bottom: 0.2rem;
        }
    }

    > .buttons {
        padding: 1rem;

        > .delete {
            color: var(--color-bad);
            border: 1px solid var(--color-bad);

            &:hover {
                background: var(--color-bad);
                color: #fff;
            }
        }
    }

    > .addCompany {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;

        > .container {
            background: #fff;
            padding: 1rem;
            border-radius: 1rem;
            display: flex;
            gap: 1rem;

            > .c-button {
                margin-left: 0;
            }
        }
    }
`;

let Title = styled.div`
    font-size: 24px;
    color: rgba(0, 0, 0, 0.6);
    padding: 1rem;
`;

function View({ history, forwardingId }) {
    const context = useContext(Context);
    const [forwarding, setForwarding] = useState(null);
    const [notes, setNotes] = useState();

    const [otherInformation, setOtherInformation] = useState("");
    const [pickupInformation, setPickupInformation] = useState("");
    const [sender, setSender] = useState();
    const [recipient, setRecipient] = useState();
    const [packages, setPackages] = useState();
    const [status, setStatus] = useState("");
    const [type, setType] = useState("");

    useEffect(() => {
        loadForwarding();
    }, []);

    async function loadForwarding() {
        const forwarding = await Api.getForwarding({
            forwardingId,
        });
        setStatus(forwarding.status);
        setType(forwarding.data.type);
        setSender(forwarding.data.sender);
        setRecipient(forwarding.data.recipient);
        setPackages(forwarding.data.packages);
        setPickupInformation(forwarding.data.information.pickup);
        setOtherInformation(forwarding.data.information.other);
        setForwarding(forwarding);
        setNotes(forwarding.notes);
    }

    async function save() {
        await Api.updateForwarding({
            forwardingId: forwarding.id,
            data: {
                forwarding: {
                    ...forwarding,
                    status,
                    notes,
                    data: {
                        ...forwarding.data,
                        information: {
                            ...forwarding.data.information,
                            pickup: pickupInformation,
                            other: otherInformation,
                        },
                        type,
                        packages,
                        recipient,
                        sender,
                    },
                },
            },
        });
        history.push(`/admin/spedition/${forwarding.id}`);
    }

    function cancel() {
        history.push(`/admin/spedition/${forwarding.id}`);
    }

    if (!forwarding) {
        return null;
    }

    return (
        <Container>
            <div className="header">
                <Title>Spedition #{forwarding?.id}</Title>
                {false && !!context.user.brand && (
                    <div className="edit">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z" />
                        </svg>
                    </div>
                )}
            </div>
            <div className="type">
                <InputDropdown
                    options={[
                        {
                            title: "Typ av transport",
                            value: "",
                        },
                        {
                            title: "Väg",
                            value: "ROAD",
                        },
                        {
                            title: "Flyg",
                            value: "AIR",
                        },
                        {
                            title: "Sjö",
                            value: "SEA",
                        },
                    ]}
                    value={type}
                    onChange={setType}
                />
                <InputDropdown
                    options={[
                        {
                            title: "Ny",
                            value: "NEW",
                        },
                        {
                            title: "Pågående",
                            value: "ONGOING",
                        },
                        {
                            title: "Bokad",
                            value: "BOOKED",
                        },
                        {
                            title: "Fakturerad",
                            value: "INVOICED",
                        },
                        {
                            title: "Stängd",
                            value: "CLOSED",
                        },
                    ]}
                    value={status}
                    onChange={setStatus}
                />
            </div>
            <div className="route">
                {sender && (
                    <CompanyForm
                        enableAddressBook={false}
                        customsRequired={false}
                        company={sender}
                        onChange={setSender}
                        enableDifferentPickupAddress={false}
                        requireEmail={false}
                        requireCompanyName={false}
                        requireAddress={false}
                        requireContactName={false}
                        requirePhoneNumber={false}
                        enableDhlPalletExchangeNumber={false}
                    />
                )}
                {recipient && (
                    <CompanyForm
                        enableAddressBook={false}
                        customsRequired={false}
                        company={recipient}
                        onChange={setRecipient}
                        enableDifferentPickupAddress={false}
                        requireEmail={false}
                        requireCompanyName={false}
                        requireAddress={false}
                        requireContactName={false}
                        requirePhoneNumber={false}
                        enableDhlPalletExchangeNumber={false}
                    />
                )}
            </div>
            <div className="packages">
                {packages && (
                    <ShipmentPackages
                        packages={packages}
                        onChange={setPackages}
                    />
                )}
            </div>
            <div className="information">
                <div className="title">Upphämtning</div>
                <div className="title">Övrigt</div>
                <div className="input">
                    <TextareaInput
                        value={pickupInformation}
                        onChange={setPickupInformation}
                    />
                </div>
                <div className="input">
                    <TextareaInput
                        value={otherInformation}
                        onChange={setOtherInformation}
                    />
                </div>
            </div>
            <div className="buttons">
                <button className="c-button c-button--raised" onClick={save}>
                    Spara
                </button>
                <button className="c-button c-button--ghost" onClick={cancel}>
                    Avbryt
                </button>
            </div>
        </Container>
    );
}

export default View;
