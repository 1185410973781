function PrettyState(state) {
    switch (state) {
        case "NEW": {
            return "Ny";
        }
        case "APPROVED": {
            return "Bokad";
        }
        case "SHIPPED": {
            return "Upphämtad";
        }
        case "IN_TRANSIT": {
            return "I transit";
        }
        case "REJECTED": {
            return "Ej bokad";
        }
        case "DELIVERING": {
            return "Levererar";
        }
        case "DELIVERED": {
            return "Levererad";
        }
        case "CANCELLED": {
            return "Avbokad";
        }
        default: {
            return "Okänt";
        }
    }
}

export { PrettyState };

export default {
    NEW: "NEW",
    REJECTED: "REJECTED",
    APPROVED: "APPROVED",
    SHIPPED: "SHIPPED",
    IN_TRANSIT: "IN_TRANSIT",
    DELIVERED: "DELIVERED",
    CANCELLED: "CANCELLED",
};
