import React, { useMemo, useState } from "react";
import { ReactComponent as BoxesIcon } from "../../../../imgs/PickupTypes/boxes.svg";
import { ReactComponent as PalletsIcon } from "../../../../imgs/PickupTypes/pallet.svg";
import { ReactComponent as CagesIcon } from "../../../../imgs/PickupTypes/cages.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const weekdayStyle = `rounded-full p-4 w-12 h-12 flex items-center justify-center text-white font-medium shadow-sm `;

const PickupItem = ({ serviceIcon, boxes, pallets, cages, daysData }) => {
    const [bookedPickups, setBookedPickups] = useState();
    const [nonBooked, setNonBooked] = useState(false);
    const { t } = useTranslation();

    useMemo(() => {
        Object.values(daysData).forEach((day) => {
            if (day === true) setNonBooked(true);
        });
        let weekdays = [];
        weekdays.push({ name: t("weekdays.mon"), status: daysData.mon });
        weekdays.push({ name: t("weekdays.tue"), status: daysData.tue });
        weekdays.push({ name: t("weekdays.wed"), status: daysData.wed });
        weekdays.push({ name: t("weekdays.thu"), status: daysData.thu });
        weekdays.push({ name: t("weekdays.fri"), status: daysData.fri });
        setBookedPickups(weekdays);
    }, [daysData]);

    return (
        <div className="p-6 flex flex-col justify-between gap-2 shadow-md outline-slate-200 rounded-lg">
            <div className="flex justify-between w-72 h-full">
                <div
                    className={
                        !nonBooked
                            ? "pickup-images  w-full m-auto h-full"
                            : "pickup-images_unBooked ml-auto mr-auto"
                    }
                >
                    <img
                        src={serviceIcon.imgUrl}
                        height={nonBooked ? 200 : 130}
                        width={nonBooked ? 200 : 130}
                        alt="delivery-service"
                        className="w-full h-full object-contain"
                    />
                </div>
                {nonBooked && (
                    <div className="flex gap-3 items-center justify-center">
                        <div className="flex flex-col gap-2 justify-center items-center">
                            <BoxesIcon />
                            {boxes}
                        </div>
                        <div className="flex flex-col gap-2 justify-center items-center">
                            <CagesIcon />
                            {cages}
                        </div>
                        <div className="flex flex-col gap-2 justify-center items-center">
                            <PalletsIcon /> {pallets}
                        </div>
                    </div>
                )}
            </div>
            {nonBooked && (
                <div className="flex gap-3 justify-between text-sm pointer-events-none mt-1">
                    {bookedPickups?.map((day, index) => (
                        <div
                            key={index}
                            className={
                                weekdayStyle +
                                (day.status
                                    ? "bg-red-500"
                                    : "border border-gray-500 text-gray-500")
                            }
                        >
                            <span>{day.name}</span>
                        </div>
                    ))}
                </div>
            )}
            <Link
                to={serviceIcon.path}
                className="px-4 bg-blue-500 rounded-md text-white py-2 mt-2 transition-all hover:bg-blue-600 flex items-center justify-center"
            >
                <button>{t("bookNow")}</button>
            </Link>
        </div>
    );
};

export default PickupItem;
