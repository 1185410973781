exports.getProductName = function (productCode) {
    switch (productCode) {
        case "BEGOMA_STANDARD": {
            return "Begoma";
        }
        case "DHL_EUROCONNECT": {
            return "DHL EuroConnect";
        }
        case "DHL_EUROLINE": {
            return "DHL Euroline";
        }
        case "DHL_EUROCONNECT_PLUS": {
            return "DHL EuroConnect Plus";
        }
        case "DHL_HOME_DELIVERY": {
            return "DHL Home Delivery";
        }
        case "DHL_PAKET2":
        case "DHL_PAKET": {
            return "DHL Paket";
        }
        case "DHL_PAKET_EXPORT": {
            return "DHL Paket Export";
        }
        case "DHL_PALL": {
            return "DHL Pall";
        }
        case "DHL_HALV_PALL": {
            return "DHL Halv Pall";
        }
        case "DHL_PARCEL_CONNECT": {
            return "DHL Parcel Connect";
        }
        case "DHL_PARTI": {
            return "DHL Parti";
        }
        case "DHL_SERVICE_POINT2":
        case "DHL_SERVICE_POINT": {
            return "DHL Service Point";
        }
        case "DHL_STYCKE": {
            return "DHL Stycke";
        }
        case "FEDEX_ECONOMY": {
            return "Fedex Economy";
        }
        case "FEDEX_ECONOMY_FREIGHT": {
            return "Fedex Economy Freight";
        }
        case "FEDEX_PRIORITY": {
            return "Fedex Priority";
        }
        case "FEDEX_PRIORITY_FREIGHT": {
            return "Fedex Priority Freight";
        }
        case "TNT_ECONOMY": {
            return "TNT Economy";
        }
        case "TNT_EXPRESS": {
            return "TNT Express";
        }
        case "UPS_ACCESSPOINT": {
            return "UPS Access Point";
        }
        case "UPS_EXPEDITED": {
            return "UPS Expedited";
        }
        case "UPS_EXPRESS": {
            return "UPS Express";
        }
        case "UPS_SAVER": {
            return "UPS Saver";
        }
        case "UPS_STANDARD": {
            return "UPS Standard";
        }
    }
};
