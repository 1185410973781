import InputDropdown from "components/OldInput/Dropdown/InputDropdown";
import InputField from "components/OldInput/Text";
import React from "react";

const searchIcon = "/images/icons/search.svg";

const FilterSearchBar = ({
    setSearchQuery,
    setFilter,
    setSort,
    limit,
    setLimit,
}) => {
    const filterOptions = [
        { title: "Alla", value: "" },
        { title: "Inrikes", value: "SE" },
        { title: "Utrikes", value: "ROW" },
        { title: "Transporttjänst", value: "selectedQuote" },
    ];

    const sortOptions = [
        { title: "Senast skapad", value: "" },
        { title: "Est. Upphämtningsdatum", value: "pickupDate" },
    ];

    const limitOptions = [{ title: 10 }, { title: 20 }, { title: 50 }];

    return (
        <div className="px-1 bg-white justify-between items-center inline-flex">
            <div className="justify-center items-center gap-4 flex">
                <div className="justify-center items-center gap-2 flex">
                    <div className="text-right text-neutral-800 text-sm font-extralight ]">
                        Filtrera försändelser på:
                    </div>
                    <div className="h-10 w-32 bg-white  justify-between items-center flex">
                        <InputDropdown
                            options={filterOptions}
                            onChange={setFilter}
                        />
                    </div>
                </div>
                <div className="h-10 w-80 px-4 justify-between items-center flex">
                    <InputField
                        placeholder={"Sök..."}
                        icon={searchIcon}
                        onClickSearch={setSearchQuery}
                        maxLength={20}
                    />
                </div>
                <div className="justify-center items-center gap-2 flex">
                    <div className="text-right text-neutral-800 text-sm font-extralight ]">
                        Sortera försändelser på:
                    </div>
                    <div className="h-10 bg-white  justify-between items-center flex">
                        <InputDropdown
                            options={sortOptions}
                            onChange={setSort}
                        />
                    </div>
                </div>
            </div>
            <div className="justify-center items-center gap-2 flex">
                <div className="text-right text-neutral-800 text-sm font-extralight ">
                    Visa antal per sida:
                </div>
                <div className="h-10 justify-between items-center flex">
                    <InputDropdown
                        options={limitOptions}
                        default={limit}
                        onChange={setLimit}
                    />
                </div>
            </div>
        </div>
    );
};

export default FilterSearchBar;
