import React, { Component } from "react";
import DynamicQuote from "./Dynamic";

class Quotation extends Component {
    render() {
        switch (this.props.data.service.name.toUpperCase()) {
            default: {
                return (
                    <DynamicQuote
                        service={this.props.data.service}
                        route={this.props.order.route}
                        deliveryDate={this.props.data.deliveryDate}
                        pickupDate={this.props.data.pickupDate}
                        quotation={this.props.data}
                        errorMessages={this.props.data.errorMessages}
                        warningMessages={this.props.data.warningMessages}
                        informationMessages={
                            this.props.data.informationMessages
                        }
                        receiverPays={this.props.receiverPays}
                        price={this.props.data.price}
                        forwarding={this.props.data.forwarding}
                        onSetDap={this.props.onSetDap}
                    />
                );
            }
        }
    }
}

export default Quotation;
