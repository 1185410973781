import React from "react";
import DHLSettings from "./DHL";
import DHLExpressSettings from "./DHLExpress";
import DSVSettings from "./DSV";
import FedexSettings from "./Fedex";
import LemanSettings from "./Leman";
import TNTSettings from "./TNT";
import PostnordSettings from "./PostNord";
import UPSSettings from "./UPS";
import BringSettings from "./Bring";

function Component({ order, service, onChange, bookOrder, receiverPays }) {
    switch (service.name.toUpperCase()) {
        case "DHL": {
            return (
                <DHLSettings
                    order={order}
                    service={service}
                    onChange={onChange}
                    bookOrder={bookOrder}
                    receiverPays={receiverPays}
                />
            );
        }
        case "FEDEX": {
            return (
                <FedexSettings
                    order={order}
                    service={service}
                    onChange={onChange}
                    bookOrder={bookOrder}
                    receiverPays={receiverPays}
                />
            );
        }
        case "TNT": {
            return (
                <TNTSettings
                    order={order}
                    service={service}
                    onChange={onChange}
                    bookOrder={bookOrder}
                    receiverPays={receiverPays}
                />
            );
        }
        case "UPS": {
            return (
                <UPSSettings
                    order={order}
                    service={service}
                    onChange={onChange}
                    bookOrder={bookOrder}
                    receiverPays={receiverPays}
                />
            );
        }
        case "DSV": {
            return (
                <DSVSettings
                    order={order}
                    service={service}
                    onChange={onChange}
                    bookOrder={bookOrder}
                    receiverPays={receiverPays}
                />
            );
        }
        case "DHL EXPRESS": {
            return (
                <DHLExpressSettings
                    order={order}
                    service={service}
                    onChange={onChange}
                    bookOrder={bookOrder}
                    receiverPays={receiverPays}
                />
            );
        }
        case "POSTNORD": {
            return (
                <PostnordSettings
                    order={order}
                    service={service}
                    onChange={onChange}
                    bookOrder={bookOrder}
                    receiverPays={receiverPays}
                />
            );
        }
        case "POSTNORD": {
            return (
                <PostnordSettings
                    order={order}
                    service={service}
                    onChange={onChange}
                    bookOrder={bookOrder}
                />
            );
        }
        case "TNT LEMAN": {
            return (
                <LemanSettings
                    order={order}
                    service={service}
                    onChange={onChange}
                    bookOrder={bookOrder}
                    receiverPays={receiverPays}
                />
            );
        }
        case "BRING": {
            return (
                <BringSettings
                    order={order}
                    service={service}
                    onChange={onChange}
                    bookOrder={bookOrder}
                    receiverPays={receiverPays}
                />
            );
        }
        default: {
            console.error(
                "OrderSettings not defined for service " + service.name,
            );
            return null;
        }
    }
}

export default Component;
