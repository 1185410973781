import Input from "components/OldInput";
import axios from "axios";
import ClientManager from "components/Input/ClientManager";
import Loader from "components/Loader/Loader";
import Table from "components/Table";
import Context from "context/Global";
import moment from "moment";
import "moment/locale/sv";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;

    > .emptyState {
        padding: 0 1rem;
        font-style: italic;
    }

    > .title {
        padding: 1rem;
        display: flex;
        width: 100%;

        > .text {
            font-size: 2rem;
        }

        > .actions {
            margin-left: auto;
            display: flex;
            align-items: center;

            > * + * {
                margin-left: 1rem;
            }
        }
    }

    > .buttons {
        padding: 16px;
    }
`;

let Customer = styled.tr`
    cursor: default;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    text-decoration: none;
    color: #000;

    flex-shrink: 0;

    &:last-child {
        border-bottom: 0;
    }

    > .title {
        font-size: 16px;
    }

    > .price {
        font-size: 16px;
        text-align: right;
    }

    &:hover {
        background: rgba(0, 0, 0, 0.02);
    }
`;

function Component(props) {
    const [loading, setLoading] = useState(true);
    const [customers, setCustomers] = useState(null);
    const [customerSearchString, setCustomerSearchString] = useState("");
    const [prospects, setProspects] = useState(null);
    const [clientManagers, setClientManagers] = useState(null);
    const [selectedClientManagerId, setSelectedClientManagerId] =
        useState(null);
    const [addingClientManager, setAddingClientManager] = useState(false);
    const clientManagerInputRef = useRef();
    const context = useContext(Context);
    const cancelSearchTokenRef = useRef();

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (cancelSearchTokenRef.current) {
            cancelSearchTokenRef.current.cancel();
        }
        cancelSearchTokenRef.current = axios.CancelToken.source();

        getCustomers();
    }, [customerSearchString]);

    async function removeClientManager(user) {
        let remove = window.confirm(
            `Vill du verkligen ta bort ${user.name} som kundansvarig?`,
        );

        if (remove) {
            await Api.removeClientManager({
                userId: user.id,
            });
            loadClientManagers();
        }
    }

    async function loadData() {
        setProspects(await Api.getProspects());
        getCustomers();
        if (context.user.administrator || context.user.role === "OWNER") {
            await loadClientManagers();
        }
        setLoading(false);
    }

    async function getCustomers() {
        setCustomers(
            await Api.getCustomers({
                cancelToken: cancelSearchTokenRef.current?.token,
                q: customerSearchString,
                clientManagerId:
                    context.user.role === "CLIENT_MANAGER"
                        ? context.user.id
                        : null,
            }),
        );
    }

    async function loadClientManagers() {
        setClientManagers(
            await Api.getClientManagers({
                includeOwners: true,
            }),
        );
    }

    async function addClientManager() {
        setAddingClientManager(true);
        await Api.addClientManager({
            brandId: context.user.brand.id,
            userId: selectedClientManagerId,
        });
        clientManagerInputRef.current.empty();
        setAddingClientManager(false);

        loadClientManagers();

        // clientManagerInputRef.current.empty();
    }

    if (loading) {
        return (
            <Container>
                <div className="loader">
                    <Loader />
                </div>
            </Container>
        );
    } else {
        return (
            <Container>
                <div className="title">
                    <div className="text">Prospekt</div>
                </div>
                {!prospects?.length && (
                    <div className="emptyState">
                        Just nu finns det inga prospekt
                    </div>
                )}
                {!!prospects?.length && (
                    <Table className="Prospects">
                        <thead>
                            <tr>
                                <th>Namn</th>
                                {context.user.administrator && <th>Brand</th>}
                                <th>Skapad</th>
                            </tr>
                        </thead>
                        <tbody>
                            {prospects.map((customer, index) => {
                                return (
                                    <Customer
                                        key={index}
                                        href={`/admin/customers/${customer.id}`}
                                        onClick={() => {
                                            props.history.push(
                                                `/admin/customers/${customer.id}`,
                                            );
                                        }}
                                    >
                                        <td className="name">
                                            {customer.name}
                                        </td>
                                        {context.user.administrator && (
                                            <td className="name">
                                                {customer.brand &&
                                                    customer.brand.name}
                                            </td>
                                        )}
                                        <td
                                            className="created"
                                            title={moment(
                                                customer.created,
                                            ).format("YYYY-MM-DD HH:mm:ss")}
                                        >
                                            {moment(
                                                customer.created,
                                            ).calendar()}
                                        </td>
                                    </Customer>
                                );
                            })}
                        </tbody>
                    </Table>
                )}
                {(context.user.administrator ||
                    context.user.role === "OWNER") && (
                    <>
                        <div className="title">
                            <div className="text">Kundansvariga</div>
                            <div className="actions">
                                <Input
                                    ref={clientManagerInputRef}
                                    type={ClientManager}
                                    placeholder="Sök"
                                    onChange={(value) => {
                                        setSelectedClientManagerId(value);
                                    }}
                                />
                                <button
                                    className="c-button c-button--raised"
                                    disabled={
                                        !selectedClientManagerId ||
                                        addingClientManager
                                    }
                                    onClick={addClientManager}
                                >
                                    Lägg till kundansvarig
                                </button>
                            </div>
                        </div>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Namn</th>
                                    {context.user.administrator && (
                                        <th>Brand</th>
                                    )}
                                    <th>Skapad</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {clientManagers.map((user, index) => {
                                    return (
                                        <Customer key={index}>
                                            <td className="name">
                                                <Link
                                                    to={`/admin/users/${user.id}`}
                                                >
                                                    {user.name}
                                                </Link>
                                            </td>
                                            {context.user.administrator && (
                                                <td className="name">
                                                    {user.brand &&
                                                        user.brand.name}
                                                </td>
                                            )}
                                            <td
                                                className="created"
                                                title={moment(
                                                    user.created,
                                                ).format("YYYY-MM-DD HH:mm:ss")}
                                            >
                                                {moment(
                                                    user.created,
                                                ).calendar()}
                                            </td>
                                            <td className="created">
                                                <button
                                                    className="c-button c-button--ghost"
                                                    onClick={() =>
                                                        removeClientManager(
                                                            user,
                                                        )
                                                    }
                                                >
                                                    Ta bort
                                                </button>
                                            </td>
                                        </Customer>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </>
                )}
                <div className="title">
                    <div className="text">Kunder</div>
                    <div className="actions">
                        <Input
                            type="text"
                            placeholder="Sök"
                            onChange={setCustomerSearchString}
                        />
                        <Link
                            to="/admin/customers/create"
                            className="c-button c-button--raised"
                        >
                            Ny kund
                        </Link>
                    </div>
                </div>
                <Table className="customers">
                    <thead>
                        <tr>
                            <th>Kundnummer</th>
                            <th>Namn</th>
                            {(context.user.administrator ||
                                context.user.role === "OWNER") && (
                                <th>Kundansvarig</th>
                            )}
                            <th>Användare</th>
                            {context.user.administrator && <th>Brand</th>}
                            <th>Skapad</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customers &&
                            customers.map((customer, index) => {
                                return (
                                    <Customer
                                        key={index}
                                        href={`/admin/customers/${customer.id}`}
                                        onClick={() => {
                                            props.history.push(
                                                `/admin/customers/${customer.id}`,
                                            );
                                        }}
                                    >
                                        <td className="name">
                                            {customer.clientNumber}
                                        </td>
                                        <td className="name">
                                            {customer.name}
                                        </td>
                                        {(context.user.administrator ||
                                            context.user.role === "OWNER") && (
                                            <td className="name">
                                                {customer.clientManagerName}
                                            </td>
                                        )}
                                        <td className="name">
                                            {customer.usernames.join(", ")}
                                        </td>
                                        {context.user.administrator && (
                                            <td className="name">
                                                {customer.brand &&
                                                    customer.brand.name}
                                            </td>
                                        )}
                                        <td
                                            className="created"
                                            title={moment(
                                                customer.created,
                                            ).format("YYYY-MM-DD HH:mm:ss")}
                                        >
                                            {moment(
                                                customer.created,
                                            ).calendar()}
                                        </td>
                                    </Customer>
                                );
                            })}
                    </tbody>
                </Table>
            </Container>
        );
    }
}

export default withRouter(Component);
