import React, { Component } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import Input from "../";

let Container = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: auto;

    table {
        border-collapse: collapse;

        th,
        td {
            font-weight: normal;
            padding: 4px 8px;
            text-align: left;
            height: 100%;
            vertical-align: top;
        }

        th {
            padding: 4px 16px;

            &:first-child {
                padding-left: 8px;
            }

            &:last-child {
                padding-right: 8px;
            }

            > .required {
                margin-left: 0.2em;
                color: rgba(220, 10, 10, 1);
            }
        }

        td:first-child {
            padding-left: 0px;
        }

        td:last-child {
            padding-right: 0px;
        }

        .addItem {
            padding: 8px;

            > svg {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }
        }

        .removeItem {
            vertical-align: top;
            padding-top: 12px;

            > svg {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }
        }
    }
`;

class InputTable extends Component {
    constructor(props) {
        super(props);

        this.itemRefs = [];

        let keys = Object.keys(props.object).map((key) => {
            return key;
        });
        let refs = {};

        if (props.items.length > 0) {
            Object.keys(props.items).forEach((key) => {
                keys.reduce(function (prev, curr) {
                    prev[curr] = React.createRef();
                    return prev;
                }, refs);
            });
        }
        this.state = {
            value: props.value || [],
            object: props.object || {},
            items: props.items || [],
            disabled: props.disabled,
            maxRows: props.maxRows,
            refs: refs,
            keys: keys,
        };

        if (typeof this.props.onChange === "function") {
            this.onChange = this.props.onChange;
        }

        this.addItem = this.addItem.bind(this);
        this.addItems = this.addItems.bind(this);
    }

    componentDidMount() {
        if (this.state.value.length > 0) {
            this.addItems(this.state.value);
        }
    }

    value() {
        let value = [];
        this.itemRefs.forEach((item) => {
            let row = {};
            this.state.keys.forEach((key) => {
                row[key] = item[key].current.value();
            });
            value.push(row);
        });
        return value;
    }

    set(value) {
        this.state.keys.forEach((key) => {
            if (typeof value[key] !== "undefined") {
                if (
                    this.state.refs &&
                    this.state.refs[key] &&
                    this.state.refs[key].current
                ) {
                    this.state.refs[key].current.set(value[key]);
                }
            }
        });
    }

    async empty() {
        this.setState({
            items: [],
            refs: [],
        });
        // this.state.keys.forEach((key) => {
        // 	if(this.state.refs[key] && this.state.refs[key].current) {
        // 		this.state.refs[key].current.empty();
        // 	}
        // });
    }

    onChange() {}

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            object: nextProps.object || {},
        };
    }

    validate() {
        let valid = true;
        this.itemRefs.forEach((item) => {
            this.state.keys.forEach((key) => {
                if (!item[key].current.validate()) {
                    valid = false;
                }
            });
        });
        return valid;
    }

    addItems(items) {
        let newItems = [];
        items.forEach((item) => {
            let newItem = JSON.parse(JSON.stringify(this.props.object));
            newItem["_index"] = uuidv4();

            if (item) {
                Object.keys(this.props.object).forEach((key) => {
                    newItem[key].value =
                        typeof item[key] !== "undefined"
                            ? item[key]
                            : this.props.object[key].value;
                });
            } else if (this.props.defaultValue) {
                Object.keys(this.props.defaultValue).forEach((key) => {
                    newItem[key].value = this.props.defaultValue[key];
                });
            }

            newItems.push(newItem);
        });

        this.setState(
            {
                items: [...this.state.items, ...newItems],
            },
            () => {
                this.onChange();
            },
        );
    }

    addItem(item) {
        this.addItems([item]);
    }

    removeItem(index) {
        this.setState(
            {
                items: this.state.items.filter(function (item, itemIndex) {
                    return itemIndex !== index;
                }),
            },
            () => {
                this.onChange();
            },
        );

        // let items = [...this.state.items];

        // // items.forEach((item, index) => {
        // // 	let itemValue = {};
        // // 	this.state.keys.forEach((key) => {
        // // 		itemValue[key] = this.itemRefs[index][key].current.value()
        // // 	});
        // // 	item.value = itemValue
        // // });

        // items.splice(index, 1);
        // this.itemRefs.splice(index, 1);

        // // items.forEach((item) => {
        // // 	console.log(item.ref.current.value());
        // // 	item.ref.current.set(item.ref.current.value());
        // // });

        // this.setState({
        // 	items: items
        // });
    }

    render() {
        this.itemRefs = [];
        return (
            <Container>
                <table>
                    <thead>
                        <tr>
                            {this.state.keys.map((key, index) => {
                                return (
                                    <th
                                        key={index}
                                        key={key}
                                        style={{
                                            minWidth:
                                                this.props.minColumnWidth +
                                                "px",
                                        }}
                                    >
                                        {this.state.object[key].title}
                                        {this.state.object[key].required ? (
                                            <span className="required">*</span>
                                        ) : (
                                            ""
                                        )}
                                    </th>
                                );
                            })}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.items.map((item, itemIndex) => {
                            this.itemRefs[itemIndex] = {};
                            return (
                                <tr key={item["_index"]}>
                                    {this.state.keys.map((key, keyIndex) => {
                                        let ref = React.createRef();
                                        this.itemRefs[itemIndex][key] = ref;
                                        let style = {};
                                        if (item[key].minWidth) {
                                            style.minWidth = item[key].minWidth;
                                        }
                                        return (
                                            <td key={key} style={style}>
                                                <Input
                                                    ref={ref}
                                                    type={item[key].type}
                                                    value={item[key].value}
                                                    {...this.state.object[key]}
                                                    onChange={() => {
                                                        this.onChange();
                                                    }}
                                                />
                                                {/* <Input ref={ref} type={item[key].type} value={item[key].value} {...this.state.object[key]} onChange={(value) => {
											if(this.state.object[key].onChange) {
												this.state.object[key].onChange(value)
											}
											this.onChange()
										}}/> */}
                                            </td>
                                        );
                                    })}
                                    {!this.state.disabled && (
                                        <td className="removeItem">
                                            <svg
                                                onClick={() => {
                                                    this.removeItem(itemIndex);
                                                }}
                                                width="24"
                                                height="24"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm0 10.293l5.293-5.293.707.707-5.293 5.293 5.293 5.293-.707.707-5.293-5.293-5.293 5.293-.707-.707 5.293-5.293-5.293-5.293.707-.707 5.293 5.293z"
                                                />
                                            </svg>
                                        </td>
                                    )}
                                </tr>
                            );
                        })}
                        {!this.state.disabled &&
                            (this.state.maxRows
                                ? this.state.items.length < this.state.maxRows
                                : true) && (
                                <tr>
                                    <td
                                        colSpan={this.state.keys.length}
                                        className="addItem"
                                    >
                                        <svg
                                            onClick={() => {
                                                this.addItem();
                                            }}
                                            width="24"
                                            height="24"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z"
                                            />
                                        </svg>
                                    </td>
                                </tr>
                            )}
                    </tbody>
                </table>
            </Container>
        );
    }
}

InputTable.defaultProps = {
    disabled: false,
    items: [],
    minColumnWidth: 80,
};

export default InputTable;
