import FileList from "components/FileList";
import Context from "context/Global";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import nl2br from "react-nl2br";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import UploadList from "./UploadList";
import { quickMessages } from "../quickMessages";

let Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    gap: 1rem;

    > .messages {
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 1rem;
        padding-bottom: 1rem;
        overflow: auto;
        padding-right: 1rem;

        > .message {
            &:first-child {
                margin-top: auto;
            }

            &.is-message {
                background: #fafafa;
                padding: 1rem;
                border: 1px solid #ddd;
                border-radius: 1rem;
                width: 80%;

                > .text {
                    margin-bottom: 1rem;
                }

                &.is-mine {
                    background: #efe;
                    border: 1px solid #aba;
                    border-radius: 1rem;
                    align-self: flex-end;
                }
            }

            &.is-event {
                text-align: center;
                color: #555;
                font-style: italic;
            }

            &.is-admin_note {
                text-align: center;
                color: #555;
                font-style: italic;
            }

            > .attachments {
                display: flex;
                gap: 1rem;
                margin-bottom: 1rem;

                > .file {
                    height: 80px;
                    width: 80px;
                    background: rgba(0, 0, 0, 0.1);
                    object-fit: contain;
                    object-position: center;
                }
            }

            > .timestamp,
            > .sender {
                font-size: 0.8rem;
                color: #555;
            }
        }
    }
    > .input {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        position: relative;

        > .closed-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(252, 252, 252, 0.9);
            font-weight: bold;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 2;

            > p {
                text-align: center;

                > a {
                    color: #00f;
                    text-decoration: underline;
                    cursor: pointer;

                    &:hover {
                        color: #55f;
                    }
                }
            }
        }

        > .buttons {
            display: flex;
            gap: 1rem;

            > div {
                display: flex;
                align-items: center;
                width: auto;
                gap: 0.5rem;
                font-size: 0.8rem;
                cursor: pointer;
                padding: 1rem 0.5rem;
                border-radius: 1rem;

                &:hover {
                    background: rgba(0, 0, 0, 0.2);
                }

                > .input {
                    display: none;
                }
            }

            > .quickMessage {
                position: relative;

                > .quickMessageListBackdrop {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 100;
                }

                > .list {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    bottom: 100%;
                    background: #fff;
                    border-radius: 4px;
                    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
                    opacity: 0;
                    transition: all 0.3s ease;
                    z-index: 101;
                    pointer-events: none;

                    &.is-visible {
                        opacity: 1;
                        pointer-events: all;
                    }

                    > .option {
                        display: flex;
                        white-space: nowrap;
                        align-items: center;

                        > .title {
                            padding: 1rem;
                            margin-right: auto;
                        }

                        > .language {
                            height: 100%;
                            width: 100%;
                            padding: 1rem;
                            width: 18px;
                            min-widht: 18px;
                            box-sizing: content-box;
                            cursor: pointer;

                            &:hover {
                                background: rgba(0, 0, 0, 0.1);
                            }
                        }

                        > .language {
                            font-weight: bold;
                        }

                        > .title {
                        }
                    }
                }
            }

            > .copyTranscript {
                position: relative;

                > .icon {
                    transition: all 0.3s ease;
                }

                > .check {
                    position: absolute;
                    color: #00c300;
                    opacity: 0;
                    transition: all 0.3s ease;
                }

                &.is-copied {
                    > .icon {
                        opacity: 0.2;
                    }

                    > .check {
                        opacity: 1;
                    }
                }
            }
        }

        > .notOwnerWarning {
            font-style: italic;
            color: rgb(244, 67, 54);
        }

        > .text {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 1rem;

            > .inputField {
                width: 100%;
                min-height: 60px;
                border: 1px solid #aaa;
                resize: none;
                display: flex;
                flex-wrap: wrap;

                > .attachments {
                    display: flex;
                    gap: 1rem;
                    height: 100%;
                    align-items: center;
                    padding: 1rem;

                    img {
                        width: 40px;
                        height: 40px;
                        object-fit: contain;
                        object-position: center;
                        background: rgba(0, 0, 0, 0.1);
                        box-sizing: border-box;

                        &.is-file {
                            padding: 0.5rem;
                            border-radius: 4px;
                        }
                    }
                }

                > textarea {
                    background: none;
                    border: none;
                    width: 100%;
                    height: 100%;
                    padding: 1rem;
                    outline: none;
                }
            }
        }
    }

    > .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.1);
        z-index: 100;

        > .container {
            padding: 1rem;
            background: #fff;
            border-radius: 1rem;
            box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);

            > .buttons {
                padding-top: 1rem;
                display: flex;
                justify-content: flex-end;

                > .close {
                    color: #b31414;
                    border-color: #b31414;
                    margin-right: auto;
                    &:hover {
                        background-color: #dfa2a2;
                    }
                }
            }
        }
    }
`;

function Component({ ticket, ...props }) {
    const context = useContext(Context);
    const [messages, setMessages] = useState(props.messages);
    const [order, setOrder] = useState(null);
    const [attachments, setAttachments] = useState([]);
    const [copyDone, setCopyDone] = useState(false);
    const [showQuickMessageList, setShowQuickMessageList] = useState(false);
    const [message, setMessage] = useState("");
    const messageInputRef = useRef();
    const messagesRef = useRef();
    const messagesScrollToBottomRef = useRef();
    const messagesIsScrolling = useRef();
    const fileUploadRef = useRef();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        messagesRef.current.scrollTo({
            left: 0,
            top: messagesRef.current.scrollHeight,
            behavior: "smooth",
        });
    }, []);

    function getMessageContent(message) {
        switch (message.type) {
            case "EVENT": {
                return (
                    <>
                        <div className="text">
                            {t(`tickets.event.${message.data.event}`)}
                        </div>
                        <div className="timestamp">
                            {moment(message.created).calendar(null, {
                                sameDay: "[Idag] HH:mm",
                                lastDay: "[Igår] HH:mm",
                                sameElse: "DD/MM HH:mm",
                            })}
                        </div>
                        <div className="sender">
                            {message.userName ?? "Användare borttagen"}
                            {message.customerName ? ", " : ""}
                            {message.isSupport
                                ? "Kundservice"
                                : message.customerName}
                        </div>
                    </>
                );
            }
            case "ADMIN_NOTE": {
                return (
                    <>
                        <div className="text">{message.data.text}</div>
                        <div className="timestamp">
                            {moment(message.created).calendar(null, {
                                sameDay: "[Idag] HH:mm",
                                lastDay: "[Igår] HH:mm",
                                sameElse: "DD/MM HH:mm",
                            })}
                        </div>
                        <div className="sender">
                            {message.userName ?? "Användare borttagen"}
                            {message.customerName ? ", " : ""}
                            {message.isSupport
                                ? "Kundservice"
                                : message.customerName}
                        </div>
                    </>
                );
            }
            case "MESSAGE": {
                return (
                    <>
                        {!!message.data.attachments?.length && (
                            <div className="attachments">
                                <FileList
                                    files={message.data.attachments?.map(
                                        (attachment) => {
                                            return {
                                                key: attachment.id,
                                                url: attachment.url,
                                                mimetype: attachment.mimetype,
                                            };
                                        },
                                    )}
                                />
                            </div>
                        )}
                        <div className="text">{nl2br(message.data.text)}</div>
                        <div className="sender">
                            {message.userName ?? "Användare borttagen"}
                            {message.isSupport || message.customerName
                                ? ", "
                                : ""}
                            {message.isSupport
                                ? "Kundservice"
                                : message.customerName}
                            {message.created ? ", " : ""}
                            {moment(message.created).calendar(null, {
                                sameDay: "[Idag] HH:mm",
                                lastDay: "[Igår] HH:mm",
                                lastWeek: "DD MMM HH:mm",
                                sameElse: "D MMM HH:mm",
                            })}
                        </div>
                    </>
                );
            }
            default: {
                return null;
            }
        }
    }

    function newMessage(message) {
        setMessages((messages) => {
            if (
                Math.ceil(messagesRef.current.scrollTop) +
                    messagesRef.current.clientHeight >=
                messagesRef.current.scrollHeight
            ) {
                messagesScrollToBottomRef.current = true;
            } else {
                messagesScrollToBottomRef.current = false;
            }
            return messages.concat(message);
        });
    }

    function sendMessage() {
        if (messageInputRef.current) {
            if (message.trim() || attachments.length > 0) {
                context.socket.emit("sendTicketMessage", {
                    ticketId: ticket.id,
                    message: {
                        type: "MESSAGE",
                        text: message.trim(),
                        attachments: attachments,
                    },
                });
                setAttachments([]);
            }
            setMessage("");
            messageInputRef.current.value = "";
            messageInputRef.current.focus();
        }
    }

    function textareaKeyPress(ev) {
        if (ev.code === "Enter" && !!ev.ctrlKey && !ev.shiftKey && !ev.altKey) {
            sendMessage();
            ev.preventDefault();
        }
    }

    useEffect(() => {
        setMessages(props.messages);

        context.socket.on(`messageSent`, newMessage);

        return () => {
            context.socket.off(`messageSent`);
        };
    }, []);

    useEffect(() => {
        if (
            messagesIsScrolling.current ||
            (messagesRef.current && messagesScrollToBottomRef.current)
        ) {
            clearTimeout(messagesIsScrolling.current);
            messagesRef.current.scrollTo({
                left: 0,
                top: messagesRef.current.scrollHeight,
                behavior: "smooth",
            });
            messagesIsScrolling.current = setTimeout(() => {
                messagesIsScrolling.current = null;
            }, 1000);
        }
    }, [messages]);

    async function onPaste(ev) {
        if (ev.clipboardData.files?.length) {
            const newAttachments = [];
            for (const file of ev.clipboardData.files) {
                const bufferData = await getBufferData(file);
                newAttachments.push({
                    type: file.type,
                    name: file.name,
                    bufferData,
                    base64dataUrl:
                        "data:image;base64, " +
                        Buffer.from(bufferData).toString("base64"),
                });
            }

            setAttachments((attachments) => {
                return attachments.concat(newAttachments);
            });
        }
    }

    async function getBufferData(file) {
        return await new Promise(async (resolve, reject) => {
            var reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.onerror = function (error) {
                reject(error);
            };
        });
    }

    function uploadFile() {
        fileUploadRef.current.click();
    }

    async function fileInputChanged(ev) {
        const newAttachments = await Promise.all(
            Object.keys(ev.target.files).map(async (key) => {
                const file = ev.target.files[key];
                const bufferData = await getBufferData(file);

                return {
                    type: file.type,
                    name: file.name,
                    bufferData,
                    base64dataUrl:
                        "data:image;base64, " +
                        Buffer.from(bufferData).toString("base64"),
                };
            }),
        );

        setAttachments((attachments) => {
            return [].concat(attachments).concat(newAttachments);
        });
        messageInputRef.current.focus();
    }

    function copyTranscript() {
        const text =
            `Konversation mellan ${ticket.customerName} (kund) och Zendr.\r\n\r\n` +
            messages
                .filter((message) => {
                    return message.type === "MESSAGE" && !!message.data.text;
                })
                .map((message) => {
                    return (
                        (message.isSupport ? "Zendr:" : "Kund:") +
                        "\r\n- " +
                        message.data.text
                    );
                })
                .join("\r\n\r\n");
        navigator.clipboard.writeText(text);
        setCopyDone(true);
        setTimeout(() => {
            setCopyDone(false);
        }, 1000);
    }

    function canSendMessage() {
        return !!message || !!attachments.length;
    }

    function removeUploadedFileOnIndex(index) {
        setAttachments((files) => {
            files.splice(index, 1);
            return [].concat(files);
        });
    }

    async function reopenTicket() {
        context.socket.emit("updateTicket", {
            ticketId: ticket.id,
            data: {
                status: "ONGOING",
            },
        });

        context.socket.emit("sendTicketMessage", {
            message: {
                type: "EVENT",
                event: `REOPEN`,
            },
            ticketId: ticket.id,
        });
    }

    return (
        <Container>
            <div ref={messagesRef} className="messages">
                {messages.map((message) => {
                    return (
                        <div
                            key={message.id}
                            className={`message is-${message.type.toLowerCase()} ${
                                message.userId === context.user.id
                                    ? "is-mine"
                                    : ""
                            }`}
                        >
                            {getMessageContent(message)}
                        </div>
                    );
                })}
            </div>
            <div className="input">
                {ticket.status === "CLOSED" && (
                    <div className="closed-overlay">
                        <p>
                            Ärendet är stängt. Stängde vi det för tidigt?
                            <br />
                            <a className="reopenTicket" onClick={reopenTicket}>
                                Öppna ärendet igen här
                            </a>
                            .<p></p>
                            Gäller det något annat får ni gärna{" "}
                            <Link to="/tickets/create">
                                skapa ett nytt ärendet
                            </Link>{" "}
                            istället.
                        </p>
                    </div>
                )}
                <div className="buttons">
                    {!!context.user.brand?.id && (
                        <div
                            className="quickMessage"
                            onClick={() => {
                                setShowQuickMessageList((oldValue) => {
                                    return !oldValue;
                                });
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="currentColor"
                                    d="M12 3c5.514 0 10 3.592 10 8.007 0 4.917-5.145 7.961-9.91 7.961-1.937 0-3.383-.397-4.394-.644-1 .613-1.595 1.037-4.272 1.82.535-1.373.723-2.748.602-4.265-.838-1-2.025-2.4-2.025-4.872-.001-4.415 4.485-8.007 9.999-8.007zm0-2c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 1.418.345 2.775.503 4.059.503 7.084 0 11.91-4.837 11.91-9.961-.001-5.811-5.702-10.006-12.001-10.006zm0 14h-5v-1h5v1zm5-3h-10v-1h10v1zm0-3h-10v-1h10v1z"
                                />
                            </svg>
                            Snabbtext
                            {showQuickMessageList && (
                                <div
                                    className="quickMessageListBackdrop"
                                    onClick={(ev) => {
                                        setShowQuickMessageList(false);
                                        ev.stopPropagation();
                                    }}
                                ></div>
                            )}
                            <div
                                className={`list ${
                                    showQuickMessageList ? "is-visible" : ""
                                }`}
                            >
                                {quickMessages.map((message, index) => {
                                    return (
                                        <div className="option" key={index}>
                                            <div className="title">
                                                {message.title}
                                            </div>
                                            {message.content.map(
                                                (content, index) => {
                                                    return (
                                                        <div
                                                            className="language"
                                                            key={index}
                                                            onClick={() => {
                                                                let newValue =
                                                                    messageInputRef
                                                                        .current
                                                                        .value;
                                                                if (
                                                                    messageInputRef
                                                                        .current
                                                                        .value
                                                                        .length
                                                                ) {
                                                                    newValue +=
                                                                        "\n";
                                                                }
                                                                newValue +=
                                                                    content.text;
                                                                setMessage(
                                                                    newValue,
                                                                );
                                                                messageInputRef.current.value +=
                                                                    newValue;
                                                            }}
                                                        >
                                                            {content.language}
                                                        </div>
                                                    );
                                                },
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    <div className="upload" onClick={uploadFile}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12-5.373-12-12-12-12 5.373-12 12zm18-1h-4v7h-4v-7h-4l6-6 6 6z"
                            />
                        </svg>
                        Ladda upp fil
                        <input
                            className="input"
                            multiple
                            ref={fileUploadRef}
                            type="file"
                            onChange={fileInputChanged}
                        />
                    </div>
                    {!!context.user.brand?.id && (
                        <div
                            className={`copyTranscript ${
                                copyDone ? "is-copied" : ""
                            }`}
                            onClick={copyTranscript}
                        >
                            <svg
                                className="icon"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path d="M18 6v-6h-18v18h6v6h18v-18h-6zm-12 10h-4v-14h14v4h-10v10zm16 6h-14v-14h14v14z" />
                            </svg>
                            Kopiera text
                            <svg
                                className="check"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="currentColor"
                                    d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
                                />
                            </svg>
                        </div>
                    )}
                </div>
                {context.user.brand?.id &&
                    context.user.id !== ticket.assignedToId && (
                        <div className="notOwnerWarning">
                            Ärendet hanteras av {ticket.assignedToName}
                        </div>
                    )}
                <div className="text">
                    <div className="inputField">
                        {attachments.length > 0 && (
                            <div className="attachments">
                                <UploadList
                                    files={attachments.map((file) => {
                                        return {
                                            key: file.base64dataUrl,
                                            url: file.base64dataUrl,
                                            mimetype: file.type,
                                        };
                                    })}
                                    removeIndex={removeUploadedFileOnIndex}
                                />
                            </div>
                        )}
                        <textarea
                            ref={messageInputRef}
                            onChange={(ev) => {
                                setMessage(ev.target.value);
                            }}
                            onKeyPress={textareaKeyPress}
                            onPaste={onPaste}
                            value={message}
                        />
                    </div>
                    <button
                        onClick={sendMessage}
                        className="c-button c-button--raised"
                        disabled={!canSendMessage()}
                    >
                        Skicka
                    </button>
                </div>
            </div>
        </Container>
    );
}

export default Component;
