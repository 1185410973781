export const incoTerms = {
    dap: {
        code: "DAP",
        description: "DAP - Mottagaren betalar",
    },
    ddp: {
        code: "DDP",
        description: "DDP - Avsändaren betalar",
    },
    dpu: {
        code: "DPU",
        description: "DPU - Levereras på plats utan avlastning",
    },
    exw: {
        code: "EXW",
        description: "EXW - Tillgängligt på säljarens lager",
    },
    fca: {
        code: "FCA",
        description: "FCA - Hos transportören med angiven plats",
    },
    cip: {
        code: "CIP",
        description: "CIP - Transport och försäkring betald till angiven plats",
    },
    cpt: {
        code: "CPT",
        description: "CPT - Transport betald till angiven plats",
    },
    dhl: {
        ddp: {
            code: "DDP",
            description: "DDP - ex Duty & VAT",
        },
    },
    ups: {
        ddp_no_vat: {
            code: "DDP_NO_VAT",
            description: "DDP - ex VAT",
        },
    },
};
