import moment from "moment";
import React, { useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled, { keyframes } from "styled-components";

let spinAnimation = keyframes`
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0);
    }
`;

let Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    > .timeline {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 2em;
        margin: 1em 0;
        box-sizing: border-box;

        > .line {
            position: absolute;
            background: rgba(200, 200, 200, 1);
            width: calc(100% - 4em);
            height: 2px;
            top: 7px;
        }

        > .progressLine {
            position: absolute;
            background: green;
            width: calc(100% - 4em);
            height: 4px;
            top: 6px;
            transition: all 1s ease;
        }

        .checkpoint {
            display: flex;
            flex-direction: column;
            z-index: 2;
            width: 16px;
            max-width: 16px;

            > .icon {
                background: rgba(200, 200, 200, 1);
                padding: 4px;
                border-radius: 100%;
                width: 16px;
                height: 16px;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                transform: scale3d(1, 1, 1);
                transition: all 1s ease;
                box-sizing: border-box;

                &.is-done {
                    background: green;
                }

                &.is-current {
                    transform: scale3d(2, 2, 1);

                    &.is-error {
                        background: #e57c1b;
                        padding: 0px;
                        border: 1px solid #fff;
                        width: 16px;
                        height: 16px;
                        transform: scale3d(3, 3, 1);

                        > svg {
                            width: 100%;
                            height: 100%;
                            transform: scale3d(0.5, 0.5, 1)
                                translate3d(0, -1px, 0);
                        }
                    }

                    &.is-cancelled {
                        background: rgba(250, 160, 60, 1);
                    }
                }

                > svg {
                    width: 9px;
                    height: 9px;
                }
            }

            > .title {
                color: rgba(0, 0, 0, 0.4);
                display: flex;
                justify-content: center;
                white-space: nowrap;
                padding-top: 1em;
            }
        }
    }

    > .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > .toggleEvents {
            cursor: pointer;
            display: flex;
            align-items: center;
            cursor: pointer;

            > .arrow {
                margin-left: 8px;
                height: 8px;
                width: 8px;
                transform: rotate3d(0, 0, 1, 90deg);
                transition: transform 0.3s ease;

                &.is-open {
                    transform: rotate3d(0, 0, 1, 180deg);
                }
            }

            &:hover {
                color: #999;
            }

            &.is-disabled {
                cursor: default;
                pointer-events: none;
                opacity: 0.5;
            }
        }
        > .update {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: auto;

            > svg {
                height: 16px;
                width: 16px;
                margin-left: 8px;
            }

            &:hover {
                color: #999;
            }

            &.is-disabled {
                color: #ccc;
                cursor: default;
                pointer-events: none;

                > svg {
                    animation: ${spinAnimation} 1s ease infinite;
                }
            }
        }

        > .deliveryDate {
            margin-left: auto;
        }
    }
    > .events {
        th,
        td {
            padding-right: 16px;

            &:last-child {
                padding-right: 0;
            }
        }

        td {
            white-space: nowrap;

            &:last-child {
                white-space: normal;
            }
        }
    }
`;

function Component(props) {
    const [order, setOrder] = useState(props.order);
    const [state, setState] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [deliveryDate, setDeliveryDate] = useState(null);
    const [updating, setUpdating] = useState(false);
    const [stateLevel, setStateLevel] = useState(null);
    const [events, setEvents] = useState(null);
    const [showEvents, setShowEvents] = useState(false);
    const [lastUpdate, setLastUpdate] = useState();

    useEffect(() => {
        if (!state) {
            return;
        }
        let stateLevel = 0;
        switch (state) {
            case "APPROVED": {
                stateLevel = 0;
                break;
            }
            case "SHIPPED": {
                stateLevel = 1;
                break;
            }
            case "IN_TRANSIT": {
                stateLevel = 2;
                break;
            }
            case "DELIVERING": {
                stateLevel = 3;
                break;
            }
            case "DELIVERED": {
                stateLevel = 4;
                break;
            }
            default: {
                console.error("Invalid state: " + state);
            }
        }
        setStateLevel(stateLevel);
    }, [state]);

    useEffect(() => {
        setState(order.state);
        setDeliveryDate(order.deliveryDate);
        setEvents(order.events || []);
        setLastUpdate(order.lastStateUpdate);
        if (order.hasError) {
            setHasError(true);
        }
    }, [order]);

    useEffect(() => {
        setOrder(props.order);
    }, [props.order]);

    function getIcon(level, requiredLevel) {
        let icon = null;
        let className = `icon is-${state.toLowerCase()}`;
        if (level >= requiredLevel) {
            className += " is-done";
            if (state === "CANCELLED") {
                icon = (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path fill="currentColor" d="M0 10h24v4h-24z" />
                    </svg>
                );
            } else {
                icon = (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
                        />
                    </svg>
                );
            }
        }
        if (level === requiredLevel) {
            className += " is-current";
            if (hasError) {
                icon = (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
                        />
                    </svg>
                );
                className += " is-error";
            }
        }
        return <div className={className}>{icon}</div>;
    }

    async function trackOrder() {
        setUpdating(true);
        try {
            let response = await Api.trackOrder({
                orderId: order.id,
            });

            if (response.data.state) {
                setState(response.data.state);
                if (response.data.state === "DELIVERED") {
                    setDeliveryDate(response.data.date);
                }
                setEvents(response.data.events);
            }

            setLastUpdate(moment().format("YYYY-MM-DD HH:mm:ss"));
        } catch (error) {
            console.error(error);
        }
        setUpdating(false);
    }

    function toggleEvents() {
        setShowEvents(!showEvents);
    }

    if (stateLevel === null) {
        return null;
    }

    return (
        <Container>
            <div className="timeline">
                <div className="line"></div>
                <div
                    className="progressLine"
                    style={{
                        width: `calc((100% - 4em)/4*${stateLevel})`,
                    }}
                ></div>
                <div className="checkpoint booked">
                    {getIcon(stateLevel, 0)}
                    <div className="title">Bokad</div>
                </div>
                <div className="checkpoint pickup">
                    {getIcon(stateLevel, 1)}
                    <div className="title">Upphämtad</div>
                </div>
                <div className="checkpoint inTransit">
                    {getIcon(stateLevel, 2)}
                    <div className="title">I transit</div>
                </div>
                <div className="checkpoint delivering">
                    {getIcon(stateLevel, 3)}
                    <div className="title">Levereras</div>
                </div>
                <div className="checkpoint delivered">
                    {getIcon(stateLevel, 4)}
                    <div className="title">Levererad</div>
                </div>
            </div>
            <div className="buttons">
                {events && !!events.length && (
                    <div
                        className={
                            "toggleEvents" +
                            (!events.length ? " is-disabled" : "")
                        }
                        onClick={() => {
                            toggleEvents();
                        }}
                    >
                        {showEvents && events && events.length
                            ? "Dölj detaljer"
                            : "Visa detaljer"}
                        <svg
                            className={
                                "arrow" +
                                (showEvents && events && events.length
                                    ? "  is-open"
                                    : "")
                            }
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M23.677 18.52c.914 1.523-.183 3.472-1.967 3.472h-19.414c-1.784 0-2.881-1.949-1.967-3.472l9.709-16.18c.891-1.483 3.041-1.48 3.93 0l9.709 16.18z"
                            />
                        </svg>
                    </div>
                )}
                {(state !== "DELIVERED" || !deliveryDate) && (
                    <div
                        className={"update" + (updating ? " is-disabled" : "")}
                        onClick={() => {
                            trackOrder();
                        }}
                    >
                        {lastUpdate
                            ? `Senast uppdaterad ${moment(
                                  lastUpdate,
                              ).calendar()}`
                            : "Uppdatera"}{" "}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M23 12c0 1.042-.154 2.045-.425 3h-2.101c.335-.94.526-1.947.526-3 0-4.962-4.037-9-9-9-1.706 0-3.296.484-4.655 1.314l1.858 2.686h-6.994l2.152-7 1.849 2.673c1.684-1.049 3.659-1.673 5.79-1.673 6.074 0 11 4.925 11 11zm-6.354 7.692c-1.357.826-2.944 1.308-4.646 1.308-4.962 0-9-4.038-9-9 0-1.053.191-2.06.525-3h-2.1c-.271.955-.425 1.958-.425 3 0 6.075 4.925 11 11 11 2.127 0 4.099-.621 5.78-1.667l1.853 2.667 2.152-6.989h-6.994l1.855 2.681z"
                            />
                        </svg>
                    </div>
                )}
                {!(state !== "DELIVERED" || !deliveryDate) && (
                    <div className="deliveryDate">
                        Levererad{" "}
                        {deliveryDate ? moment(deliveryDate).fromNow() : ""}
                    </div>
                )}
            </div>
            {!!(showEvents && events && events.length) && (
                <table className="events">
                    <thead>
                        <tr>
                            <th>Tid</th>
                            <th>Plats</th>
                            <th>Beskrivning</th>
                        </tr>
                    </thead>
                    <tbody>
                        {events
                            .filter((v) => v)
                            .map((event) => {
                                return (
                                    <tr key={Math.random()}>
                                        {event.timestamp && (
                                            <td
                                                title={moment(
                                                    event.timestamp,
                                                ).format("YYYY-MM-DD HH:mm:ss")}
                                            >
                                                {moment(
                                                    event.timestamp,
                                                ).calendar()}
                                            </td>
                                        )}
                                        {!event.timestamp &&
                                            event.date &&
                                            event.time && (
                                                <td title="Lokal tid">
                                                    {moment(
                                                        event.date +
                                                            " " +
                                                            event.time,
                                                    ).format(
                                                        "YYYY-MM-DD HH:mm:ss",
                                                    )}
                                                </td>
                                            )}
                                        {!(
                                            event.timestamp ||
                                            (event.date && event.time)
                                        ) && <td></td>}
                                        <td>
                                            {event.location}
                                            {event.country
                                                ? ` (${event.country})`
                                                : ""}
                                        </td>
                                        <td>{event.description}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            )}
        </Container>
    );
}

export default Component;
