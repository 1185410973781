import Input from "components/OldInput";
import Loader from "components/Loader/Loader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;

    > .desiredPickupTimes {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        > .input {
            padding: 0 16px;
        }
    }

    > .instructions {
        margin-bottom: 16px;
    }
    > .servicePoint {
        margin-bottom: 32px;
    }

    > .title {
        color: rgba(0, 0, 0, 0.5);
        margin: 16px 0;

        &:first-child {
            margin-top: 0;
        }
    }

    > .pickupDate {
        display: flex;
        align-items: center;

        > .suggestion {
            margin-left: 1em;
        }
    }

    .datetime {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 0 1rem;
        width: fit-content;

        > label {
            font-weight: bold;
        }
    }

    .subtitle {
        font-size: 1rem;
        font-weight: bold;
        margin-top: 1rem;
    }

    .deliveryDate {
        font-size: 1rem;
        margin-top: 0.5rem;

        > .loader {
            height: 24px;
            width: 24px;
        }
    }
`;

function Component(props) {
    const [order, setOrder] = useState(props.order);
    const [servicePoints, setServicePoints] = useState([]);
    const [loading, setLoading] = useState(true);
    const [desiredPickupDate, setDesiredPickupDate] = useState(null);
    const [pickupInstructions, setPickupInstructions] = useState(null);
    const [deliveryInstructions, setDeliveryInstructions] = useState(null);
    const [selectedServicePointIndex, setSelectedServicePointIndex] =
        useState(null);
    const [selectedServicePoint, setSelectedServicePoint] = useState(null);

    const [suggestedPickupDate, setSuggestedPickupDate] = useState(null);
    const [suggestedDeliveryDate, setSuggestedDeliveryDate] = useState(null);

    const [desiredPickupStartTime, setDesiredPickupStartTime] = useState(null);
    const [desiredPickupEndTime, setDesiredPickupEndTime] = useState(null);
    const [availablePickupHours, setAvailablePickupHours] = useState([]);

    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        onDesiredPickupDateChanged(moment().format("YYYY-MM-DD"));

        if (order.service.productCode === "DHLServicePointB2C") {
            Api.getServicePoints({
                destination: order.route.to,
                service: order.service,
            })
                .then((servicePoints) => {
                    setServicePoints(servicePoints);
                })
                .then(() => {
                    // setLoading(false);
                })
                .catch((error) => {
                    console.error("Failed to get servicepoints");
                    console.error(error);
                });
        }
    }, []);

    useEffect(() => {
        if (!availablePickupHours || !availablePickupHours.length) {
            return;
        }
        setDesiredPickupStartTime(availablePickupHours[0].value);
        setDesiredPickupEndTime(
            availablePickupHours[availablePickupHours.length - 1].value,
        );
    }, [availablePickupHours]);

    useEffect(() => {
        if (!suggestedPickupDate) {
            return;
        }

        let startHour = 6;
        let endHour = 19;

        if (
            suggestedPickupDate === moment().format("YYYY-MM-DD") &&
            startHour < parseInt(moment().add(1, "hours").format("HH"))
        ) {
            startHour = parseInt(moment().add(1, "hours").format("HH"));
        }

        let hours = [];

        for (var i = startHour; i < endHour; i++) {
            hours.push({
                title: ("0" + i).slice(-2) + ":00",
                value: ("0" + i).slice(-2) + ":00",
            });
        }

        setAvailablePickupHours(hours);
    }, [suggestedPickupDate]);

    useEffect(() => {
        if (desiredPickupDate) {
            setSuggestedPickupDate(null);
            Api.getPickupAvailability({
                route: order.route,
                packages: order.packages,
                service: order.service,
                pickupDate: desiredPickupDate,
            })
                .then((response) => {
                    if (response.suggestion) {
                        setLoading(false);
                        setSuggestedPickupDate(response.suggestion.pickupDate);
                        setSuggestedDeliveryDate(
                            response.suggestion.deliveryDate,
                        );
                    }
                })
                .catch((error) => {
                    console.error("Failed to get pickup availability");
                    console.error(error);
                });
        }
    }, [desiredPickupDate]);

    function getDesiredPickupDates() {
        return [0, 1, 2, 3, 4, 5, 6, 7]
            .map((i) => {
                let day = moment().add(i, "days");
                if ([6, 7].indexOf(day.isoWeekday()) < 0) {
                    return {
                        title: day.format("YYYY-MM-DD"),
                        value: day.format("YYYY-MM-DD"),
                    };
                }
                return null;
            })
            .filter((pickup) => {
                return !!pickup;
            });
    }

    function onDesiredPickupDateChanged(value) {
        setDesiredPickupDate(value);
    }

    useEffect(() => {
        // servicePoint
        servicePoints.forEach((servicePoint) => {
            if (
                parseInt(servicePoint.index) ===
                parseInt(selectedServicePointIndex)
            ) {
                setSelectedServicePoint(servicePoint);
            }
        });
    }, [selectedServicePointIndex, servicePoints]);

    async function bookPickup() {
        try {
            let pickupStart =
                suggestedPickupDate + " " + desiredPickupStartTime + ":00";
            let pickupEnd =
                suggestedPickupDate + " " + desiredPickupEndTime + ":00";
            await Api.bookPickup({
                orderId: order.id,
                pickup: {
                    date: suggestedPickupDate,
                    instructions: pickupInstructions,
                    timeInterval: {
                        start: pickupStart,
                        end: pickupEnd,
                    },
                },
                delivery: {
                    servicePoint: selectedServicePoint,
                    instructions: deliveryInstructions,
                },
            });
            props.history.replace(`/orders/${order.id}`);
            return;
        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.error
            ) {
                switch (error.response.data.error) {
                    case "INVALID_TIME_ERROR": {
                        setErrorMessage(`Ogiltig tid, försök igen.`);
                        break;
                    }
                    case "CUT_OFF_TIME_PASSED_ERROR": {
                        setErrorMessage(
                            `Stopptiden har passerats, boka en upphämtning nästa möjliga dag.`,
                        );
                        break;
                    }
                    default: {
                        setErrorMessage(`Ett fel inträffade. Försök igen.`);
                        break;
                    }
                }
            } else {
                setErrorMessage(`Ett fel inträffade. Försök igen.`);
            }
        }
    }

    function renderPickupDate(date) {
        if (!date) {
            return null;
        }
        switch (date) {
            case "ONE_WORK_DAY": {
                return (
                    <div className="suggestion">
                        <div className="timestamp">
                            Kan hämtas nästkommande arbetsdag.
                        </div>
                    </div>
                );
            }
            case "TWO_WORK_DAYS": {
                return (
                    <div className="suggestion">
                        <div className="timestamp">
                            Kan hämtas om två arbetsdagar.
                        </div>
                    </div>
                );
            }
            default: {
                return (
                    <div className="suggestion">
                        <div className="timestamp">
                            Kan hämtas{" "}
                            {moment(date).calendar(null, {
                                sameDay: "[idag]",
                                nextDay: "[imorgon]",
                                nextWeek: "[på] dddd",
                                sameElse: "LL",
                            })}
                            .
                        </div>
                    </div>
                );
            }
        }
    }

    function renderDeliveryDate(date) {
        if (!date) {
            return null;
        }
        switch (date) {
            case "INTERVAL_2_7": {
                return (
                    <div className="timestamp">
                        Beräknad leverans 2-7 arbetsdagar beroende på avstånd.
                    </div>
                );
            }
            case "INTERVAL_2_5": {
                return (
                    <div className="timestamp">
                        Beräknad leverans 2-5 arbetsdagar beroende på avstånd.
                    </div>
                );
            }
            default: {
                return (
                    <div className="timestamp">
                        Beräknad leverans{" "}
                        {moment(date).calendar(null, {
                            sameDay: "[idag]",
                            nextDay: "[imorgon]",
                            nextWeek: "[på] dddd",
                            sameElse: "LL",
                        })}
                        .
                    </div>
                );
            }
        }
    }

    function parseSuggestedPickup(date) {
        switch (date) {
            case "ONE_WORK_DAY": {
                let workDaysInFuture = 0;
                let dayPointer = moment();

                while (workDaysInFuture < 1) {
                    dayPointer.add(1, "day");
                    if (dayPointer.isoWeekday() < 6) {
                        workDaysInFuture++;
                    }
                }
                return dayPointer.format("YYYY-MM-DD");
            }
            case "TWO_WORK_DAYS": {
                let workDaysInFuture = 0;
                let dayPointer = moment();

                while (workDaysInFuture < 2) {
                    dayPointer.add(1, "day");
                    if (dayPointer.isoWeekday() < 6) {
                        workDaysInFuture++;
                    }
                }
                return dayPointer.format("YYYY-MM-DD");
            }
            default: {
                return date;
            }
        }
    }

    function showServicePointPicker() {
        if (
            order.service.productCode === "DHLServicePointB2C" ||
            order.service.productCode === "DHLParcelConnect"
        ) {
            if (
                ["DE", "IE", "IT", "LU"].indexOf(order.route.to.countryCode) >=
                0
            ) {
                return false;
            }
            return true;
        }
        return false;
    }

    if (loading) {
        return (
            <Container>
                <div className="loader">
                    <Loader />
                </div>
            </Container>
        );
    } else {
        return (
            <Container>
                <div className="datetime">
                    <label>Datum</label>
                    <label>Från</label>
                    <label>Till</label>
                    <Input
                        onChange={setDesiredPickupDate}
                        type="dropdown"
                        options={getDesiredPickupDates()}
                    />
                    {suggestedPickupDate === desiredPickupDate && (
                        <>
                            {!!(
                                desiredPickupStartTime && desiredPickupEndTime
                            ) && (
                                <>
                                    <Input
                                        type="dropdown"
                                        options={availablePickupHours}
                                        onChange={setDesiredPickupStartTime}
                                        value={desiredPickupStartTime}
                                    />
                                    <Input
                                        type="dropdown"
                                        options={availablePickupHours}
                                        onChange={setDesiredPickupEndTime}
                                        value={desiredPickupEndTime}
                                    />
                                </>
                            )}
                        </>
                    )}
                </div>
                <div className="deliveryDate">
                    {suggestedPickupDate === desiredPickupDate &&
                        renderDeliveryDate(suggestedDeliveryDate)}
                    {suggestedPickupDate &&
                        suggestedPickupDate !== desiredPickupDate &&
                        "Detta datum går inte att boka en upphämtning på."}
                    {!suggestedPickupDate && desiredPickupDate && (
                        <div className="loader">
                            <Loader />
                        </div>
                    )}
                </div>
                <div className="subtitle">Upphämtningsinstruktioner</div>
                <Input
                    onChange={setPickupInstructions}
                    type="textarea"
                    value=""
                    maxLength={90}
                />
                {showServicePointPicker() && (
                    <div className="servicePoint">
                        Levereras till Service Point
                        <Input
                            type="dropdown"
                            onChange={setSelectedServicePointIndex}
                            options={servicePoints.map((servicePoint) => {
                                return {
                                    value: servicePoint.id,
                                    title: servicePoint.title,
                                };
                            })}
                        />
                    </div>
                )}
                <div className="subtitle">Leveransinstruktioner</div>
                <Input
                    onChange={setDeliveryInstructions}
                    type="textarea"
                    value=""
                    maxLength={140}
                />
                <div className="buttons">
                    <button
                        className="c-button c-button--raised"
                        onClick={bookPickup}
                        disabled={suggestedPickupDate !== desiredPickupDate}
                    >
                        Boka upphämtning
                    </button>
                </div>
                {errorMessage && (
                    <div className="errorMessage">{errorMessage}</div>
                )}
            </Container>
        );
    }
}

export default withRouter(Component);
