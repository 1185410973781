import React from "react";
import ReceiverPaysTable from "../ReceiverPaysTable";
import InformationHover from "components/InformationHover";
import moment from "moment";

function ReceiverPaysInvoiceTable() {
    const renderHeader = () => (
        <thead>
            <tr>
                <th>Kund-ID</th>
                <th>Namn</th>
                <th>Abonnemang</th>
                <th>Period</th>
                <th>Fakturadatum</th>
                <th>Fraktsedlar</th>
                <th>Övriga kostnader</th>
                <th>Pris</th>
                <th></th>
            </tr>
        </thead>
    );
    const renderRow = (receiverPaysData, formatPrice) => (
        <tr key={`receiverPays_invoice_row_${receiverPaysData.customerId}`}>
            <td>{receiverPaysData.customerId}</td>
            <td>{receiverPaysData.customerName}</td>
            <td>
                {receiverPaysData.customerSettingsReceiverPays
                    ?.subscriptionType ?? "-"}{" "}
                /{" "}
                {receiverPaysData.customerSettingsReceiverPays?.billingType ??
                    "-"}
            </td>
            <td>{`${moment(receiverPaysData.intervalFrom).format(
                "YYYY-MM-DD",
            )} - ${moment(receiverPaysData.intervalTo).format(
                "YYYY-MM-DD",
            )}`}</td>
            <td>
                {moment(receiverPaysData.intervalTo)
                    .tz("Europe/Stockholm")
                    .format("YYYY-MM-DD")}
            </td>
            <td>{receiverPaysData.shipmentLabels}</td>
            <td>
                <div className="grid grid-cols-3">
                    <div>{formatPrice(receiverPaysData.otherCosts)}</div>
                    <div>
                        <InformationHover>
                            {receiverPaysData.otherCostsDetails.length > 0
                                ? receiverPaysData.otherCostsDetails.map(
                                      (detail, i) => (
                                          <div
                                              key={`detail_info_${receiverPaysData.customerId}_${detail.title}_${i}`}
                                          >{`${detail.title}: ${formatPrice(
                                              detail.value,
                                          )}`}</div>
                                      ),
                                  )
                                : "Inga extra kostnader"}
                        </InformationHover>
                    </div>
                </div>
            </td>

            <td>{formatPrice(receiverPaysData.totalPrice)}</td>
            <td />
        </tr>
    );
    return (
        <ReceiverPaysTable
            renderHeader={renderHeader}
            renderRow={renderRow}
            initialDates={{
                start: moment().startOf("day"),
                end: moment().add(7, "day").endOf("day"),
            }}
            maxLengthDays={14}
        />
    );
}

export default ReceiverPaysInvoiceTable;
