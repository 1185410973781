import InputNumber from "components/Input/Number";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components/macro";

let Container = styled.div`
    margin-bottom: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 1rem;
    max-height: 300px;

    > .title {
        font-weight: bold;
    }

    > .description {
        margin-top: 0.5rem;
        display: flex;
        justify-content: space-between;

        .link {
            width: 1rem;
            height: 1rem;
            cursor: pointer;
            position: relative;

            > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
    > .input {
        margin-top: 0.5rem;
        display: flex;
        align-items: center;

        > .index {
            padding-right: 1rem;
        }
    }
`;

function getIcon(linked) {
    return linked ? (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M13.723 18.654l-3.61 3.609c-2.316 2.315-6.063 2.315-8.378 0-1.12-1.118-1.735-2.606-1.735-4.188 0-1.582.615-3.07 1.734-4.189l4.866-4.865c2.355-2.355 6.114-2.262 8.377 0 .453.453.81.973 1.089 1.527l-1.593 1.592c-.18-.613-.5-1.189-.964-1.652-1.448-1.448-3.93-1.51-5.439-.001l-.001.002-4.867 4.865c-1.5 1.499-1.5 3.941 0 5.44 1.517 1.517 3.958 1.488 5.442 0l2.425-2.424c.993.284 1.791.335 2.654.284zm.161-16.918l-3.574 3.576c.847-.05 1.655 0 2.653.283l2.393-2.389c1.498-1.502 3.94-1.5 5.44-.001 1.517 1.518 1.486 3.959 0 5.442l-4.831 4.831-.003.002c-1.438 1.437-3.886 1.552-5.439-.002-.473-.474-.785-1.042-.956-1.643l-.084.068-1.517 1.515c.28.556.635 1.075 1.088 1.528 2.245 2.245 6.004 2.374 8.378 0l4.832-4.831c2.314-2.316 2.316-6.062-.001-8.377-2.317-2.321-6.067-2.313-8.379-.002z" />
        </svg>
    ) : (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M7.092 5.099l1.439-.205-.439-3.083-1.44.204.44 3.084zm-2.211 3.445l.205-1.44-3.084-.44-.205 1.441 3.084.439zm-.494-5.163l-1.03 1.03 2.203 2.204 1.029-1.03-2.202-2.204zm12.541 15.565l-1.439.205.438 3.083 1.441-.204-.44-3.084zm2.21-3.446l-.206 1.441 3.085.439.205-1.44-3.084-.44zm.495 5.163l1.028-1.029-2.204-2.204-1.027 1.03 2.203 2.203zm2.64-18.904c2.344 2.346 2.344 6.149.001 8.494l-3.896 3.896-1.417-1.417 3.895-3.895c1.562-1.562 1.562-4.101 0-5.662-1.562-1.562-4.101-1.562-5.662 0l-3.894 3.895-1.416-1.416 3.895-3.895c2.344-2.345 6.147-2.345 8.494 0zm-8.138 16.631l-3.852 3.851c-2.344 2.347-6.146 2.345-8.494.001-2.344-2.346-2.345-6.149 0-8.494l3.854-3.851 1.414 1.415-3.851 3.851c-1.562 1.562-1.562 4.102-.001 5.663 1.563 1.561 4.102 1.561 5.664-.001l3.85-3.851 1.416 1.416z" />
        </svg>
    );
}

function Component({ row, index, onChange, currency, ...props }) {
    const [linked, setLinked] = useState(true);
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    let packages = [];
    for (var i = 0; i < row.count; i++) {
        packages.push({ ...row });
    }

    function toggleLinked() {
        setLinked(!linked);
    }

    function getHelptext() {
        return linked
            ? "Övre raden kontrollerar alla rader"
            : "Alla rader ändras individuellt";
    }

    return (
        <Container>
            <div className="title">Kollirad #{index + 1}</div>
            <div className="description">
                <div className="text">
                    {row.length}x{row.width}x{row.height}cm och vikt{" "}
                    {row.weight}kg
                </div>
                {packages.length > 1 && (
                    <>
                        <div
                            data-tip
                            data-for={`tooltip-${index}`}
                            className="link"
                            onClick={toggleLinked}
                        >
                            {getIcon(linked)}
                        </div>
                        <ReactTooltip
                            id={`tooltip-${index}`}
                            type="light"
                            effect="solid"
                            getContent={getHelptext}
                            arrowColor="transparent"
                            backgroundColor="#eee"
                        />
                    </>
                )}
            </div>
            {packages.map((p, packageIndex) => {
                return (
                    <div className="input">
                        {packages.length > 5 && (
                            <div className="index">#{packageIndex + 1}</div>
                        )}
                        <InputNumber
                            placeholder={0}
                            autoValidate
                            min={0}
                            max={418505}
                            onChange={(value) => {
                                onChange({
                                    rowIndex: index,
                                    packageIndex,
                                    value,
                                    linked,
                                });
                            }}
                            value={value[packageIndex]}
                            unit={currency || "SEK"}
                            messages={{
                                min: "Värdet får inte vara mindre än ${min}",
                                max: "Värdet kan inte överstiga ${max}",
                            }}
                        />
                    </div>
                );
            })}
        </Container>
    );
}

export default Component;
