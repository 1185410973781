import PackageAggregate from "components/PackageAggregate";
import Context from "context/Global";
import React, { useContext } from "react";
import styled from "styled-components/macro";

let Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 8px;
    align-items: flex-start;
    background: #fff;
    padding: 16px;
    width: 100%;
`;

let Route = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    width: 100%;

    > .party {
        width: 50%;

        > .title {
            color: rgba(0, 0, 0, 0.6);
        }

        > .company {
            font-weight: bold;
        }
    }
`;

let PackagesTable = styled.table`
    margin-bottom: 16px;
    width: 100%;
`;

function Component(props) {
    const context = useContext(Context);

    let sender = props.order.sender;
    let receiver = props.order.receiver;

    function prettyType(type) {
        switch (type) {
            case "pallet":
                return "Pall";
            case "package":
                return "Paket";
            case "envelope":
                return "Envelope";
        }
    }

    return (
        <Container>
            <Route>
                <div className="party">
                    <div className="title">Avsändare</div>
                    {sender.contact.company && (
                        <>
                            <div className="company">
                                {sender.contact.company}
                            </div>
                            <div className="contact">
                                Kontaktperson: {sender.contact.name}
                            </div>
                        </>
                    )}
                    {!sender.contact.company && (
                        <div className="contact">{sender.contact.name}</div>
                    )}
                    <div className="streetAddress">{sender.addressLine1}</div>
                    <div className="streetAddress">{sender.addressLine2}</div>
                    <div className="streetAddress">{sender.addressLine3}</div>
                    <div className="city">
                        {sender.postalCode}, {sender.postalTown}
                    </div>
                </div>
                {sender.otherPickupAddress && (
                    <div className="party">
                        <div className="title">Annan upphämtningsadress</div>
                        {sender.pickup.contact.company && (
                            <>
                                <div className="company">
                                    {sender.pickup.contact.company}
                                </div>
                                <div className="contact">
                                    Kontaktperson: {sender.pickup.contact.name}
                                </div>
                            </>
                        )}
                        {!sender.pickup.contact.company && (
                            <div className="contact">
                                {sender.pickup.contact.name}
                            </div>
                        )}
                        <div className="streetAddress">
                            {sender.pickup.addressLine1}
                        </div>
                        <div className="streetAddress">
                            {sender.pickup.addressLine2}
                        </div>
                        <div className="streetAddress">
                            {sender.pickup.addressLine3}
                        </div>
                        <div className="city">
                            {sender.pickup.postalCode},{" "}
                            {sender.pickup.postalTown}
                        </div>
                    </div>
                )}
                <div className="party">
                    <div className="title">Mottagare</div>
                    {receiver.contact.private && (
                        <div className="company">{receiver.contact.name}</div>
                    )}
                    {!receiver.contact.private && (
                        <>
                            <div className="company">
                                {receiver.contact.company}
                            </div>
                            <div className="contact">
                                Kontaktperson: {receiver.contact.name}
                            </div>
                        </>
                    )}
                    <div className="streetAddress">{receiver.addressLine1}</div>
                    <div className="streetAddress">{receiver.addressLine2}</div>
                    <div className="streetAddress">{receiver.addressLine3}</div>
                    <div className="city">
                        {receiver.postalCode}, {receiver.postalTown}
                    </div>
                </div>
            </Route>
            {props.displayPackageInformation !== false && (
                <>
                    {props.order.packages && (
                        <PackagesTable>
                            <thead>
                                <tr>
                                    <th>Antal</th>
                                    <th>Typ</th>
                                    <th>Dimensioner</th>
                                    <th>Vikt</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.order.packages.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.count} st</td>
                                            <td>{prettyType(item.type)}</td>
                                            {item.length > 0 ? (
                                                <td>
                                                    {item.length}x{item.width}x
                                                    {item.height} cm
                                                </td>
                                            ) : item.volume ? (
                                                <td>
                                                    {item.volume} m<sup>3</sup>
                                                </td>
                                            ) : (
                                                <td>-</td>
                                            )}
                                            <td>{item.weight} kg</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </PackagesTable>
                    )}
                    <PackageAggregate packages={props.order.packages} />
                </>
            )}
        </Container>
    );
}

export default Component;
