import Table from "components/Table";
import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import DialogGoodsType from "./DialogGoodsType";
import ManageGoodsGroup from "./ManageGoodsGroup";
import toast from "react-hot-toast";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;

    .buttons {
        padding: 1rem;
    }

    .c-button--text {
        background-color: #ffc1c1;
        color: #fff;
        border: none;
    }

    .c-button--text:hover {
        background-color: #ff0000;
    }

    #table {
        width: 100%;
        table-layout: fixed;

        th,
        td {
            min-width: 120px;
            max-width: 300px;
        }

        th {
            background-color: #fafafa;
            color: #333;
            font-weight: bold;
            border-bottom: 2px solid #ccc;
            padding: 10px;
        }

        td {
            white-space: normal;
            overflow-wrap: break-word;
            word-break: break-word;
        }

        .expand-collapse-all-icon {
            cursor: pointer;
            width: 32px;
            height: 32px;
        }

        .group-header {
            td {
                width: 100%;
                font-size: 18px;
                font-weight: 700;
                text-transform: none;
                text-align: left;
                color: #333;
                padding: 8px;
                border-top: 1px solid #ccc;

                &:hover {
                    background-color: #f2f2f2;
                    cursor: pointer;
                }

                .group-header-content {
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    span {
                        white-space: normal;
                        overflow-wrap: break-word;
                        word-break: break-word;
                    }
                }
            }
        }
    }
`;

const NO_GROUP_ADDED = "Ingen grupp";
const EXPAND_ICON = "/images/icons/chevron-down.svg";
const COLLAPSE_ICON = "/images/icons/chevron-up.svg";

function Component() {
    const context = useContext(Context);
    const [packageTypes, setPackageTypes] = useState([]);
    const [showPackageTypeDialog, setShowPackageTypeDialog] = useState(false);
    const [showGroupInput, setShowGroupInput] = useState(false);
    const [currentPackageType, setCurrentPackageType] = useState(null);
    const [existingGroups, setExistingGroups] = useState([]);
    const [expandedGroups, setExpandedGroups] = useState({});
    const [areAllGroupsExpanded, setAreAllGroupsExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Load package types and set initial expanded groups from localStorage
    useEffect(() => {
        loadPackageTypes();
        const savedState = localStorage.getItem("expandedGroups");
        if (savedState) {
            setExpandedGroups(JSON.parse(savedState));
        }
    }, []);

    useEffect(() => {
        // Filter out NO_GROUP_ADDED from existing groups
        const filteredGroups = Array.from(
            new Set(packageTypes.map((type) => type.data.packageGroup)),
        ).filter((group) => group && group !== NO_GROUP_ADDED);

        setExistingGroups(filteredGroups);

        setExpandedGroups((prevState) => {
            const newState = { ...prevState };
            filteredGroups.forEach((group) => {
                if (newState[group] === undefined) {
                    newState[group] = true;
                }
            });
            return newState;
        });
    }, [packageTypes]);

    useEffect(() => {
        localStorage.setItem("expandedGroups", JSON.stringify(expandedGroups));
    }, [expandedGroups]);

    async function loadPackageTypes() {
        const loadedPackageTypes = await Api.getPackageTypes({
            customerId: context.user.customer.id,
        });

        const sortedPackageTypes = [...loadedPackageTypes].sort((a, b) => {
            const groupA = a.data.packageGroup || NO_GROUP_ADDED;
            const groupB = b.data.packageGroup || NO_GROUP_ADDED;

            return (
                (groupA === NO_GROUP_ADDED
                    ? -1
                    : groupB === NO_GROUP_ADDED
                    ? 1
                    : 0) || groupA.localeCompare(groupB)
            );
        });

        setPackageTypes(sortedPackageTypes);
    }

    async function addPackageType(packageType) {
        await Api.addPackageType({
            customerId: context.user.customer.id,
            packageType: packageType,
        });
        setShowPackageTypeDialog(false);

        loadPackageTypes();

        // Auto-expand the group the new packageType was added to
        const updatedGroup = packageType.data?.packageGroup || NO_GROUP_ADDED;
        setExpandedGroups((prevState) => ({
            ...prevState,
            [updatedGroup]: true,
        }));
    }

    async function updatePackageGroup(packageGroup) {
        try {
            await Api.updatePackageGroup({
                customerId: context.user.customer.id,
                packageGroup,
                packageType: currentPackageType,
            });
            loadPackageTypes();
            setShowGroupInput(false);
        } catch (error) {
            console.error("Failed to update package group:", error);
            toast.error("Misslyckades med att uppdatera godsgrupp");
        }
    }

    async function removePackageType(packageType) {
        setIsLoading(true);
        try {
            await Api.removePackageType({
                packageTypeId: packageType.id,
            });
            toast.success(`Godstypen: "${packageType.name}" har tagits bort`);
            loadPackageTypes();
        } catch (error) {
            console.error("Failed to remove package type:", error);
            toast.error(
                `Misslyckades med att ta bort godstypen: "${packageType.name}"`,
            );
        } finally {
            setIsLoading(false);
        }
    }

    const toggleGroup = (groupName) => {
        setExpandedGroups((prevState) => ({
            ...prevState,
            [groupName]: !prevState[groupName],
        }));
    };

    const toggleAllGroups = () => {
        const allGroups = [...existingGroups, NO_GROUP_ADDED];
        const areAllOpen = allGroups.every((group) => expandedGroups[group]);
        const newState = {};
        allGroups.forEach((group) => {
            newState[group] = !areAllOpen;
        });
        setExpandedGroups(newState);
        setAreAllGroupsExpanded(!areAllOpen);
    };
    let lastGroup = null;

    return (
        <Container>
            <Table id="table" className="packageTypes">
                <thead>
                    <tr>
                        <th>Namn</th>
                        <th>Dimensioner</th>
                        <th>Stapelbar</th>
                        <th>Grupp</th>
                        <th>Beskrivning</th>
                        <th>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <span onClick={toggleAllGroups}>
                                    {areAllGroupsExpanded ? (
                                        <img
                                            src={EXPAND_ICON}
                                            alt="Expand All"
                                            className="expand-collapse-all-icon"
                                        />
                                    ) : (
                                        <img
                                            src={COLLAPSE_ICON}
                                            alt="Collapse All"
                                            className="expand-collapse-all-icon"
                                        />
                                    )}
                                </span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {packageTypes.map((packageType) => {
                        const currentGroup =
                            packageType.data.packageGroup || NO_GROUP_ADDED;
                        const isNewGroup = lastGroup !== currentGroup;
                        lastGroup = currentGroup;

                        return (
                            <>
                                {isNewGroup && (
                                    <>
                                        <tr
                                            className="group-header"
                                            onClick={() =>
                                                toggleGroup(currentGroup)
                                            }
                                        >
                                            <td colSpan={6}>
                                                <div className="group-header-content">
                                                    <span>{currentGroup}</span>
                                                    <span>
                                                        {expandedGroups[
                                                            currentGroup
                                                        ] ? (
                                                            <img
                                                                src={
                                                                    EXPAND_ICON
                                                                }
                                                                alt="Expand"
                                                            />
                                                        ) : (
                                                            <img
                                                                src={
                                                                    COLLAPSE_ICON
                                                                }
                                                                alt="Collapse"
                                                            />
                                                        )}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                )}
                                {expandedGroups[currentGroup] && (
                                    <tr
                                        key={packageType.id}
                                        className="packageType group-content"
                                    >
                                        <td>{packageType.name}</td>
                                        {packageType.data.volume ? (
                                            <td>
                                                {packageType.data.volume} m³,{" "}
                                                {packageType.data.weight} kg
                                            </td>
                                        ) : (
                                            <td>
                                                {packageType.data.length} x{" "}
                                                {packageType.data.width} x{" "}
                                                {packageType.data.height} cm,{" "}
                                                {packageType.data.weight} kg
                                            </td>
                                        )}
                                        <td>
                                            {packageType.data.stackable
                                                ? "Stapelbar"
                                                : "Ej stapelbar"}
                                        </td>
                                        <td>
                                            {packageType.data.packageGroup ? (
                                                packageType.data.packageGroup
                                            ) : (
                                                <button
                                                    className="c-button c-button--raised"
                                                    onClick={() => {
                                                        setCurrentPackageType(
                                                            packageType,
                                                        );
                                                        setShowGroupInput(true);
                                                    }}
                                                >
                                                    Koppla godsgrupp
                                                </button>
                                            )}
                                        </td>
                                        <td>
                                            {packageType.data.goodsDescription}
                                        </td>
                                        <td>
                                            <button
                                                onClick={() => {
                                                    removePackageType(
                                                        packageType,
                                                    );
                                                }}
                                                className="c-button c-button--text"
                                                disabled={isLoading}
                                            >
                                                Ta bort
                                            </button>
                                        </td>
                                    </tr>
                                )}
                            </>
                        );
                    })}
                </tbody>
            </Table>
            <div className="buttons">
                <button
                    className="c-button c-button--raised"
                    onClick={() => setShowPackageTypeDialog(true)}
                >
                    Ny godstyp
                </button>
            </div>
            <DialogGoodsType
                visible={showPackageTypeDialog}
                close={() => setShowPackageTypeDialog(false)}
                save={addPackageType}
                existingGroups={existingGroups}
            />
            <ManageGoodsGroup
                visible={showGroupInput}
                close={() => setShowGroupInput(false)}
                save={updatePackageGroup}
                existingGroups={existingGroups}
            />
        </Container>
    );
}

export default Component;
