import moment from "moment";
import { v4 as uuidv4 } from "uuid";

function parse({ data, fileName }) {
    if (!data.Download?.Invoice_Download?.length) {
        data.Download.Invoice_Download = [data.Download?.Invoice_Download];
    }
    return data.Download?.Invoice_Download.map((fedexRow) => {
        if (!fedexRow.charge_label_desc) {
            return null;
        }
        if (!fedexRow.charge_label_desc.length) {
            fedexRow.charge_label_desc = [fedexRow.charge_label_desc];
        }
        if (!fedexRow.charge_amt.length) {
            fedexRow.charge_amt = [fedexRow.charge_amt];
        }

        const vat = !!fedexRow.charge_label_desc.find((desc) => {
            return desc._text === "Sverige moms 25.%";
        });
        const totalShipmentCost = fedexRow.charge_label_desc
            .map((desc, index) => {
                return {
                    title: desc._text,
                    cost:
                        Math.round(
                            parseFloat(
                                fedexRow.charge_amt[index]._text.replace(
                                    ",",
                                    "",
                                ),
                            ) * 100,
                        ) / 100,
                };
            })
            .filter((row) => {
                return ["Sverige moms 25.%"].indexOf(row.title) < 0;
            })
            .reduce((cost, row) => {
                return cost + row.cost;
            }, 0);

        if (!fedexRow.charge_label_desc.length) {
            fedexRow.charge_label_desc = [fedexRow.charge_label_desc];
        }
        if (!fedexRow.charge_amt.length) {
            fedexRow.charge_amt = [fedexRow.charge_amt];
        }

        let totalShipmentCostVat = 0;
        let totalShipmentCostNoVat = 0;
        if (vat) {
            totalShipmentCostVat += totalShipmentCost;
        } else {
            totalShipmentCostNoVat += totalShipmentCost;
        }
        let rows = fedexRow.charge_label_desc
            .map((desc, index) => {
                return {
                    title: desc._text,
                    cost:
                        Math.round(
                            parseFloat(
                                fedexRow.charge_amt[index]._text.replace(
                                    ",",
                                    "",
                                ),
                            ) * 100,
                        ) / 100,
                };
            })
            .filter((row) => {
                return (
                    ["Transportavgift", "Rabatt", "Sverige moms 25.%"].indexOf(
                        row.title,
                    ) < 0
                );
            })
            .map((row) => {
                const surcharge = ["Bränsleavgift"].indexOf(row.title) >= 0;
                if (vat) {
                    totalShipmentCostVat += row.cost;
                } else {
                    totalShipmentCostNoVat += row.cost;
                }

                if (row.title === "Avgift för deklarerat värde") {
                    row.isInsurance = true;
                }

                return {
                    title: row.title,
                    cost: row.cost,
                    isInsurance: !!row.isInsurance,
                    vat,
                    surcharge,
                    key: uuidv4(),
                };
            })
            .concat([
                {
                    title: `Fakturerad vikt: ${fedexRow.Rated_Weight_Amount._text} kg`,
                    key: uuidv4(),
                },
            ]);

        return {
            fileName: fileName,
            transporter: "Fedex",
            trackingNumber: fedexRow.package_tracking_nbr._text,
            reference: fedexRow.shipper_ref_field_1_desc._text,
            currentInvoiceNumber: fedexRow.local_seq_setlm_nbr._text,
            date: moment(fedexRow.ship_dt._text).format("YYYY-MM-DD"),
            title: fedexRow.svc_base_pack_label_desc._text, // TODO: Maybe get a pretty title here?
            sender: fedexRow.scompany_nm._text || fedexRow.scontact_nm._text,
            senderCountry: fedexRow.scntry_cd._text,
            recipient: fedexRow.rcompany_nm._text || fedexRow.rcontact_nm._text,
            recipientCountry: fedexRow.rcntry_cd._text,
            totalShipmentCost: Math.round(totalShipmentCost * 100) / 100,
            totalShipmentCostVat: Math.round(totalShipmentCostVat * 100) / 100,
            totalShipmentCostNoVat:
                Math.round(totalShipmentCostNoVat * 100) / 100,
            vat: vat,
            rows: rows,
        };
    }).filter((v) => v);
}

export default {
    parse,
};
