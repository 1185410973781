import Input from "components/OldInput";
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import styled from "styled-components";

let Container = styled.div`
    display: inline-flex;
    flex-direction: column;

    > .message {
        font-weight: bold;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
    }

    &.is-invalid {
        > .inputContainer {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }
        > .message {
            color: rgba(255, 0, 0, 0.6);
        }
    }

    &.is-disabled {
        > .inputContainer {
            background: rgb(235, 235, 228);
            border: 1px solid rgba(0, 0, 0, 0.05);

            > input {
                color: rgba(0, 0, 0, 0.5);
                background: transparent;
            }
        }
    }

    > .inputContainer {
        > .addDescription {
            display: flex;
            gap: 1rem;
        }

        > .list {
            display: flex;
            padding: 0.5rem 0;
            gap: 0.5rem;

            > .description {
                display: inline-flex;
                align-items: center;
                background: #b3b3ed;
                padding: 0.2rem 1rem;
                border-radius: 1rem;

                > .text {
                    font-weight: bold;
                }

                > .remove {
                    width: 12px;
                    height: 12px;
                    margin-left: 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    > svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        > .freetext {
            display: flex;
            gap: 0.5rem;
            align-items: center;
        }
    }

    > .buttons {
        padding: 1rem 0;
    }
`;

function Component(props, ref) {
    const [message, setMessage] = useState();
    const [disabled, setDisabled] = useState();
    const [descriptions, setDescriptions] = useState(
        props.value?.options || [],
    );
    const [freetext, setFreetext] = useState(props.value?.freetext || false);
    const descriptionRef = useRef();

    useEffect(() => {}, []);

    useEffect(() => {
        setMessage(props.message);
    }, [props.message]);

    useEffect(() => {
        setDisabled(props.disabled || false);
    }, [props.disabled]);

    useEffect(() => {
        setDisabled(props.disabled || false);
    }, [descriptions]);

    function validate() {}
    function clear() {}

    useEffect(() => {
        props.onChange({
            descriptions,
            freetext,
        });
    }, [descriptions, freetext]);

    useImperativeHandle(ref, () => {
        return {
            validate: validate,
            clear: clear,
            value: () => ({
                options: descriptions,
                freetext,
            }),
        };
    });

    function removeDescription(description) {
        setDescriptions((descriptions) => {
            return descriptions.filter((desc) => desc !== description);
        });
    }

    function onKeyUp(ev) {
        if (ev.key === "Enter" && ev.target.value) {
            addDescription(ev.target.value);
        }
    }

    function addDescription(desc) {
        if (!desc) {
            return;
        }
        setDescriptions((descriptions) => {
            return descriptions
                .concat(desc)
                .filter((v, i, a) => a.indexOf(v) === i);
        });
        descriptionRef.current.empty();
    }

    return (
        <Container className={disabled ? " is-disabled" : ""}>
            <div className="inputContainer">
                <div className="addDescription">
                    <Input
                        ref={descriptionRef}
                        type="text"
                        placeholder="Beskrivning"
                        onKeyUp={onKeyUp}
                    />
                    <button
                        className="c-button c-button--ghost"
                        onClick={() =>
                            addDescription(descriptionRef.current.value())
                        }
                    >
                        Lägg till
                    </button>
                </div>
                <div className="list">
                    {descriptions.map((text) => {
                        return (
                            <div className="description" key={text}>
                                <div className="text">{text}</div>
                                <div
                                    className="remove"
                                    onClick={(ev) => removeDescription(text)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
                                        />
                                    </svg>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="freetext">
                    <Input
                        type="checkbox"
                        onChange={setFreetext}
                        value={!!props.value?.freetext}
                    />{" "}
                    Tillåt fritext
                </div>
            </div>
            {message && <div className="message">{message}</div>}
        </Container>
    );
}

export default forwardRef(Component);
