import { getTransporterLogo } from "helpers/Logos";
import React from "react";
import { LoaderIcon } from "react-hot-toast";
import { Link } from "react-router-dom";
import moment from "moment";

// Table error components if there is any
const TableError = ({ message }) => {
    return (
        <div className="text-center mt-10 text-red-600 border p-2 rounded font-bold border-red-600">
            {" "}
            {message}{" "}
        </div>
    );
};

// If there is no records return this components
const TableRecordsNotFound = () => {
    return <div className="text-center mt-10">Inga uppgifter hittades</div>;
};

const SalesCustomersTable = ({ customers, loading, error }) => {
    // handle error, records not found and loader
    if (error) {
        return <TableError message={error.message} />;
    } else if (customers?.length <= 0) {
        return <TableRecordsNotFound />;
    } else if (!customers || loading) {
        return <LoaderIcon className="mx-auto mt-10" />;
    }

    return (
        <>
            <hr></hr>
            <div className="mt-5">{customers?.length} resultat hittades</div>
            <table className="table-auto border border-collapse border-slate-400 w-full mt-10 overflow-y-scroll">
                <thead>
                    <tr className="bg-gray-200">
                        <th className="border border-slate-400 p-2 font-medium">
                            Säljare
                        </th>
                        <th className="border border-slate-400 p-2 font-medium">
                            Kundens Namn
                        </th>
                        <th className="border border-slate-400 p-2 font-medium">
                            Kundens e-post
                        </th>
                        <th className="border border-slate-400 p-2 font-medium">
                            Telefon
                        </th>
                        <th className="border border-slate-400 p-2 font-medium">
                            Bärare
                        </th>
                        <th className="border border-slate-400 p-2 font-medium">
                            Avsändare
                        </th>
                        <th className="border border-slate-400 p-2 font-medium">
                            Mottagare
                        </th>
                        <th className="border border-slate-400 p-2 font-medium">
                            Shipment Date
                        </th>
                        <th className="border border-slate-400 p-2 font-medium"></th>
                    </tr>
                </thead>
                <tbody>
                    {customers?.map(
                        ({
                            id,
                            orderId,
                            salesPerson,
                            contactEmail,
                            contactPhoneNumber,
                            firstShipment,
                            carrier,
                            shipment,
                            name,
                        }) => {
                            const { from, to } = shipment?.route;
                            return (
                                <>
                                    <tr
                                        key={id}
                                        className="group hover:bg-gray-100"
                                    >
                                        <td className="p-2 border border-slate-400 ">
                                            {" "}
                                            {salesPerson}{" "}
                                        </td>
                                        <td className="p-2 border border-slate-400 ">
                                            {" "}
                                            {name}{" "}
                                        </td>
                                        <td className="p-2 border border-slate-400 ">
                                            {" "}
                                            {contactEmail || "N/A"}{" "}
                                        </td>
                                        <td className="p-2 border border-slate-400 ">
                                            {" "}
                                            {contactPhoneNumber || "N/A"}{" "}
                                        </td>
                                        <td className="p-2 border border-slate-400">
                                            {" "}
                                            <img
                                                width={120}
                                                src={`/images/icons/${getTransporterLogo(
                                                    carrier,
                                                )}`}
                                            />{" "}
                                        </td>
                                        <td className="p-2 border border-slate-400 font-medium">
                                            <p>{from.postalCode}</p>
                                            <p>{from.postalTown}</p>
                                        </td>
                                        <td className="p-2 border border-slate-400 font-medium">
                                            <p>{to.postalCode}</p>
                                            <p>{to.postalTown}</p>
                                        </td>
                                        <td className="p-2 border border-slate-400 font-medium">
                                            {" "}
                                            {moment(firstShipment).format(
                                                "DD/MM/YYYY",
                                            )}{" "}
                                        </td>
                                        <td className="text-center">
                                            <Link
                                                className="group-hover:bg-indigo-500 group-hover:text-white p-2 rounded bg-gray-100 mr-2"
                                                to={`/admin/customers/${id}`}
                                            >
                                                Kund
                                            </Link>
                                            <Link
                                                className="group-hover:bg-indigo-500 group-hover:text-white p-2 rounded bg-gray-100"
                                                to={`/orders/${orderId}`}
                                            >
                                                Försändelse
                                            </Link>
                                        </td>
                                    </tr>
                                </>
                            );
                        },
                    )}
                </tbody>
            </table>
        </>
    );
};

export default SalesCustomersTable;
