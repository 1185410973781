import { parseDHLGoodsRows } from "./formats/dhl";
import { XMLParser } from "fast-xml-parser";

async function determineFormat(event) {
    return new Promise((resolve, reject) => {
        const file = event?.target?.files[0];
        const fileExt = file?.name?.split(".").pop();
        const xmlParser = new XMLParser();
        const domParser = new DOMParser();
        const reader = new FileReader();

        if (fileExt === "xml") {
            reader.onload = (event) => {
                const xmlDocument = domParser.parseFromString(
                    event.target.result,
                    "application/xml",
                );
                const xmlString = new XMLSerializer().serializeToString(
                    xmlDocument,
                );
                const jObj = xmlParser.parse(xmlString);

                if (jObj["DHL-SHIPMENT-IMPORT"]) {
                    return resolve({
                        format: "dhl",
                        ext: fileExt,
                        content: jObj,
                    });
                }
                return resolve({
                    format: "unknown",
                    ext: fileExt,
                    content: undefined,
                });
            };
        }

        if (fileExt === "csv") {
            reader.onload = (event) => {
                const csv = event.target.result;
                const rows = csv.split("\n");
                const columnLength = rows[0].split(",").length;

                if (columnLength === 14) {
                    return resolve({
                        format: "dhl",
                        ext: fileExt,
                        content: rows.filter((row) => "" !== row),
                    });
                }
                return resolve({
                    format: "unknown",
                    ext: fileExt,
                    content: undefined,
                });
            };
        }
        if (fileExt === "txt") {
            reader.onload = (event) => {
                const txt = event.target.result;
                const rows = txt.split("\n");
                const columnLength = rows[0].split("|").length;

                if (columnLength === 15) {
                    return resolve({
                        format: "dhl",
                        ext: fileExt,
                        content: rows,
                    });
                }
                return resolve({
                    format: "unknown",
                    ext: fileExt,
                    content: undefined,
                });
            };
        }

        if (fileExt !== "xml" && fileExt !== "csv" && fileExt !== "txt") {
            return resolve({
                format: "unknown",
                ext: fileExt,
                content: undefined,
            });
        }

        reader.readAsText(file);
    });
}

export default async function parseGoodsRows(event) {
    const getFormat = await determineFormat(event);

    if (getFormat.format === "unknown") {
        return undefined;
    }

    if (getFormat.format === "dhl") {
        return parseDHLGoodsRows(getFormat);
    }
}
