import Input from "components/OldInput";
import axios from "axios";
import Context from "context/Global";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import Confirmation from "../../Confirmation";
import InsuranceInput from "./Insurance";
import AdditionalOptions from "./AdditionalOptions";
import toast from "react-hot-toast";
import { getTotalAmountOfPackages } from "helpers/TotalPackages";
import {
    calculateMaximumInsuranceValue,
    determineAdditionalServices,
    determineShippingOptions,
} from "helpers/PostNord";
import InputCheckbox from "components/Input/Checkbox";
import { PN_MARGIN_MULTIPLIER, POSTNORD } from "constants/carriers/postNord";
import { useNotificationSettings } from "../hooks/useNotificationSettings";

let Title = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.6);
`;

let Card = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 8px;
    min-height: 60px;
    background: #fff;
    padding: 1rem;

    .subtitle {
        font-size: 1rem;
        font-weight: bold;
    }

    .textarea {
        max-width: 400px;
    }

    &.insurance {
        display: flex;
        flex-direction: column;

        > .toggle {
            display: flex;
            align-items: center;
            height: 38px;
            width: 100%;

            > .label {
                padding-left: 1rem;
            }

            > .note {
                padding-left: 1rem;
                font-size: 0.9em;
            }
        }

        > .information {
            display: flex;
            align-items: center;
            margin-top: 1rem;

            > .label {
                padding-left: 1rem;
            }

            > .note {
                padding-left: 1rem;
                font-size: 0.9em;
            }
        }

        > .reminder {
            display: flex;
            align-items: center;
            margin-top: 1rem;
            font-style: italic;
        }

        > .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 1rem;

            > .packageRows {
                display: grid;
                grid: 1fr / repeat(3, 1fr);
                grid-gap: 1rem;
                width: 100%;
            }

            > .summary {
                font-weight: bold;
            }

            > .price {
                margin-top: 1rem;

                > b {
                    > .Loader {
                        height: 1rem;
                        width: 1rem;
                        display: inline-block;
                        align-self: center;

                        > svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    &.pickup {
        display: flex;
        flex-direction: column;

        > .lateCancelFee {
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-bottom: 1rem;

            > img {
                height: 20px;
            }
        }

        > .toggle {
            display: flex;
            align-items: center;

            > .label {
                padding-right: 1rem;
            }

            > .note {
                padding-left: 1rem;
                font-size: 0.9em;
            }
        }

        .datetime {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 0 1rem;
            width: fit-content;
            padding-bottom: 1rem;
            margin-top: 1rem;

            > label {
                font-weight: bold;
            }
        }

        .timeMessage {
            padding-bottom: 1rem;
            font-size: 0.9rem;
            color: red;
            font-weight: bold;
        }

        .localtime {
            padding: 1rem 0;
            font-size: 0.9rem;
        }
    }

    &.confirm {
        > .summary {
            font-size: 1.2rem;
            margin-bottom: 1rem;
            font-style: italic;
        }
    }

    > .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;

        > .verification {
            display: flex;
            align-items: center;
            width: 100%;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding-top: 16px;
            margin-top: 32px;

            > .service {
                max-height: 40px;
                max-width: 100px;
            }

            > .price {
                font-size: 24px;
                padding-left: 32px;
                font-weight: bold;

                > .loader {
                    height: 20px;
                    width: 20px;
                }

                > .transport {
                    .unit {
                        font-size: 0.6em;
                    }
                }

                > .insurance {
                    font-size: 0.6em;

                    .unit {
                        font-size: 0.6em;
                    }
                }
            }

            > .u-push-left {
                margin-left: auto;
            }
        }

        > .intervalMessage {
            font-size: 0.8em;
            padding: 1em 0;
            color: rgba(250, 140, 0, 1);
            font-weight: bold;
        }

        .pickupDate {
            display: flex;
            align-items: center;

            .suggestion {
                padding-left: 1em;
                color: rgba(220, 140, 0, 1);
            }
        }

        > .message {
            > .text {
                font-weight: bold;
                font-size: 14px;
                color: #900;
            }

            > .details {
                font-size: 12px;
                color: #900;
            }
        }
    }
`;

function Component({ order, service, onChange, bookOrder, receiverPays }) {
    const context = useContext(Context);
    const [price, setPrice] = useState(null);
    const [basePrice, setBasePrice] = useState(null);
    const [deliveryDate, setDeliveryDate] = useState(null);
    const [message, setMessage] = useState(null);
    const [insuranceOptions, setInsuranceOptions] = useState({});
    const [insuranceTermsAccepted, setInsuranceTermsAccepted] = useState(false);
    const [additionalOptions, setAdditionalOptions] = useState([]);
    const [senderEmail, setSenderEmail] = useState(order.sender.contact.email);
    const [receiverEmail, setReceiverEmail] = useState(
        order.receiver.contact.email,
    );
    const [servicePoints, setServicePoints] = useState([]);
    const [selectedServicePoint, setSelectedServicePoint] = useState(null);
    const cancelSearchTokenRef = useRef();
    const cancelSearchRef = useRef();
    const senderEmailRef = useRef();
    const receiverEmailRef = useRef();
    const [totalInsuranceCost, setTotalInsuranceCost] = useState(0);
    const [shipmentOptions, setShipmentOptions] = useState([]);

    const { notificationSettings, setNotificationSettings } =
        useNotificationSettings(order, ["onTender"]);

    useEffect(() => {
        setPrice(null);

        let query = context.getApiOrderObject();
        query.service = context.quotation.service;

        setPrice(null);
        if (cancelSearchRef.current) {
            clearTimeout(cancelSearchRef.current);
            cancelSearchRef.current = null;
        }

        cancelSearchRef.current = setTimeout(() => {
            if (cancelSearchTokenRef.current) {
                cancelSearchTokenRef.current.cancel();
            }
            cancelSearchTokenRef.current = axios.CancelToken.source();
            if (!receiverPays) {
                Api.quoteOrder({
                    cancelToken: cancelSearchTokenRef.current?.token,
                    quote: query,
                })
                    .then((response) => {
                        setDeliveryDate(response.data.deliveryDate);
                        setPrice(response.data.price);
                        setBasePrice(response.data.price);
                    })
                    .catch(() => {
                        toast.error("Något gick fel vid hämtning av pris.");
                    });
            }
        }, 300);
        setServicePoints(context.quotation.servicePoints);
        setSelectedServicePoint(context.quotation.servicePoints[0]);
    }, []);

    const totalPackages = getTotalAmountOfPackages(order.packages);

    useEffect(() => {
        if (basePrice) {
            const additionalOptionsTotalPrice = additionalOptions.reduce(
                (acc, option) =>
                    option.value
                        ? acc +
                          Math.ceil(
                              option.price *
                                  PN_MARGIN_MULTIPLIER *
                                  totalPackages,
                          )
                        : acc,
                0,
            );
            const additionalOptionsCost = additionalOptions.reduce(
                (acc, option) =>
                    option.value ? acc + option.price * totalPackages : acc,
                0,
            );

            const newTransportCost =
                basePrice.transportCost +
                additionalOptionsCost +
                totalInsuranceCost / PN_MARGIN_MULTIPLIER;
            const newTotal =
                basePrice.total +
                additionalOptionsTotalPrice +
                totalInsuranceCost;

            setPrice({
                ...price,
                transportCost: newTransportCost,
                insuranceCost: totalInsuranceCost,
                total: newTotal,
            });
            context.setQuotation({
                ...context.quotation,
                price: price,
            });
        }
    }, [additionalOptions, totalInsuranceCost, basePrice]);

    useEffect(() => {
        setShipmentOptions(
            determineShippingOptions(service.productCode, order.packages),
        );
        let availableAdditionalOptions = determineAdditionalServices(
            service.productCode,
            order.packages,
        );

        setAdditionalOptions(
            availableAdditionalOptions.map((option) => {
                return {
                    ...option,
                    value: false,
                };
            }),
        );
    }, []);

    useEffect(() => {
        context.updateOrder({
            ...context.order,
            sender: {
                ...context.order.sender,
                contact: {
                    ...context.order.sender.contact,
                    email: senderEmail,
                },
            },
            receiver: {
                ...context.order.receiver,
                contact: {
                    ...context.order.receiver.contact,
                    email: receiverEmail,
                },
            },
        });
    }, [senderEmail, receiverEmail]);

    function getPriceNote() {
        if (order?.customs?.information?.terms?.startsWith("DDP")) {
            return "Tull och moms-kostnader faktureras till avsändaren.";
        } else {
            return null;
        }
    }

    const showServicePointPicker =
        service.productCode === POSTNORD.services.mypack_collect;

    const dropdownOptionsMapper = servicePoints?.map((servicePoint) => {
        const rawDistance = servicePoint.location.distanceFromRecipientAddress;
        const distance =
            rawDistance > 1000
                ? `${(rawDistance / 1000).toFixed(2)} km`
                : `${rawDistance} m`;
        return {
            value: servicePoint.location.name,
            title: `${servicePoint.location.name} (${distance})`,
        };
    });

    const handleServicePointChange = (value) => {
        const selectedValue = value;
        const newSelectedServicePoint = servicePoints.find((servicePoint) => {
            return servicePoint.location.name === selectedValue;
        });

        setSelectedServicePoint(newSelectedServicePoint);
    };

    const handleCheckboxChange = (index) => {
        setNotificationSettings((prevSettings) =>
            prevSettings?.map((setting, i) =>
                i === index
                    ? { ...setting, onTender: !setting.onTender }
                    : setting,
            ),
        );
    };

    const validateEmail = () => {
        if (isVarubrev || hasReturnDropOff) {
            if (!senderEmailRef?.current?.validate()) {
                return false;
            }
            if (!receiverEmailRef.current.validate()) {
                return false;
            }
        }
        return true;
    };

    const handleShipmentOptionChange = (name) => {
        setShipmentOptions((shipmentOptions) => {
            return shipmentOptions.map((option) => {
                if (option.name === name) {
                    return {
                        ...option,
                        value: !option.value,
                    };
                } else {
                    return option;
                }
            });
        });
    };

    const selectedAdditionalOptions = additionalOptions?.filter(
        (option) => option.value,
    );
    const additionalOptionsWithNotifications = [
        ...selectedAdditionalOptions,
        {
            name: "notificationSettings",
            value: notificationSettings,
        },
    ];

    const bookPostNordOrder = async () => {
        await bookOrder({
            pickup: {
                skipPickupOrder: true,
                estimatedPickupDate: context.quotation.pickupDate,
                pickupStopDateTime: context.quotation.pickupStopDateTime,
            },
            insurance: totalInsuranceCost > 0 ? insuranceOptions : null,
            insuranceValue: totalInsuranceCost > 0 ? totalInsuranceCost : null,

            additionalOptions: additionalOptionsWithNotifications,
            shipmentOptions: shipmentOptions?.filter((option) => option.value),
            notifications: notificationSettings,
            delivery: {
                date: moment(deliveryDate).format("YYYY-MM-DD"),
                servicePoint: selectedServicePoint,
            },
            order: context.order,
            receiverPays: receiverPays || undefined,
            service: context.quotation.service,
        });
    };

    const isVarubrev = service.productCode.includes("Varubrev");
    const hasReturnDropOff = additionalOptions.some(
        (option) =>
            option.code === POSTNORD.additional_services.return_drop_off.code &&
            option.value,
    );
    const isPrivatePallet = additionalOptions.some(
        (option) =>
            option.code ===
                POSTNORD.additional_services.private_receiver_pallet.code &&
            option.value,
    );

    const pickupText = isVarubrev
        ? "Leverans till brevlåda. Observera att varubrev lämnas hos PostNord Företagscenter. Enstaka upphämtning ej möjligt. Kontakta din kundansvarig vid större volymer."
        : "Upphämtning med PostNord bokar du manuellt via 'Boka upphämtning' i menyn till vänster. Detta görs en gång per dag för samtliga försändelser som ska skickas.";

    return (
        <>
            <Title>Upphämtning</Title>
            <Card className="pickup">
                {pickupText}
                <div
                    className={`border rounded border-yellow-300 p-4 mb-4 mt-4`}
                >
                    <b>
                        <div>
                            Observera att den adress som anges i boka
                            upphämtningsvyn är den som gäller för upphämtning.
                        </div>
                        <div>
                            Den avsändaradress som angivits på ordern kommer
                            inte att användas för PostNord upphämtning.
                        </div>
                    </b>
                </div>
            </Card>

            {showServicePointPicker && (
                <>
                    <Title>Leverans</Title>
                    <Card className="delivery">
                        <div>
                            <div className="text">
                                <div className="font-semibold pb-1">
                                    Utlämningsställe
                                </div>
                                <div className="description pb-1">
                                    Paketet levereras till ett utlämningsställe
                                    där mottagaren hämtar ut godset när den
                                    vill.
                                </div>

                                <Input
                                    type="dropdown"
                                    onChange={handleServicePointChange}
                                    value={selectedServicePoint?.location?.name}
                                    options={dropdownOptionsMapper}
                                />
                            </div>
                        </div>
                    </Card>
                </>
            )}
            {!isVarubrev && (
                <>
                    <Title>Försäkring</Title>
                    <Card className="insurance">
                        <InsuranceInput
                            onInsuranceChange={(value) =>
                                setInsuranceOptions({
                                    value: value,
                                    currency: "SEK",
                                })
                            }
                            packagesCount={totalPackages}
                            onTermsAccepted={setInsuranceTermsAccepted}
                            termsAccepted={insuranceTermsAccepted}
                            setTotalInsuranceCost={setTotalInsuranceCost}
                            totalInsuranceCost={totalInsuranceCost}
                            insuranceMaxValue={calculateMaximumInsuranceValue()}
                        />
                    </Card>
                </>
            )}
            {additionalOptions && (
                <>
                    <Title>Tillägg</Title>
                    <Card>
                        <AdditionalOptions
                            setAdditionalOptions={setAdditionalOptions}
                            additionalOptions={additionalOptions}
                            receiverPays={receiverPays}
                            packagesCount={totalPackages}
                        />
                    </Card>
                </>
            )}
            {!!shipmentOptions.length && (
                <>
                    <Title>Tillval</Title>
                    <Card>
                        {shipmentOptions.map((option) => {
                            if (isPrivatePallet) {
                                option.disabled = true;
                            } else {
                                option.disabled = false;
                            }
                            return (
                                <div
                                    className="flex items-center mb-4"
                                    key={option.name}
                                >
                                    <div className="px-8 items-center">
                                        <InputCheckbox
                                            value={option.value}
                                            disabled={
                                                option.disabled || receiverPays
                                            }
                                            onChange={() =>
                                                handleShipmentOptionChange(
                                                    option.name,
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="text-xl font-bold">
                                        <div>{option.name}</div>
                                        <div className="text-lg font-normal">
                                            {option.description}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Card>
                </>
            )}
            {isVarubrev || hasReturnDropOff ? (
                <>
                    <div className="flex flex-row justify-between pr-16 mr-2">
                        <Title>Notiser</Title>
                        <Title>Bokningsbekräftelse från Zendr</Title>
                    </div>
                    <Card>
                        <div className="flex flex-row">
                            <div className="flex flex-col">
                                <div className="description">
                                    Denna tjänst kräver e-postadress för både
                                    avsändare och mottagare.
                                </div>
                                <div className=" pt-3 ">
                                    <div className="flex flex-row pb-2 items-center">
                                        <div className="font-medium pr-2">
                                            Avsändare:
                                        </div>
                                        <Input
                                            onChange={setSenderEmail}
                                            ref={senderEmailRef}
                                            value={senderEmail}
                                            type="email"
                                            required
                                            messages={{
                                                required:
                                                    "Fältet är obligatoriskt för tjänsten",
                                                invalid:
                                                    "Det ifyllda värdet verkar inte vara en e-postadress",
                                            }}
                                            className="w-3/4"
                                        />
                                    </div>
                                    <div className="flex flex-row pb-2 items-center">
                                        <div className="font-medium pr-2">
                                            Mottagare:
                                        </div>
                                        <Input
                                            onChange={setReceiverEmail}
                                            ref={receiverEmailRef}
                                            value={receiverEmail}
                                            type="email"
                                            required
                                            messages={{
                                                required:
                                                    "Fältet är obligatoriskt för tjänsten",
                                                invalid:
                                                    "Det ifyllda värdet verkar inte vara en e-postadress",
                                            }}
                                            className="w-3/4"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col pl-14">
                                <div className="description">
                                    Välj bokningsbekräftelse från Zendr via
                                    epost till:
                                </div>
                                <div className=" pt-3 ">
                                    <div className="flex flex-row pb-3 gap-3 items-center">
                                        <InputCheckbox
                                            onChange={() =>
                                                handleCheckboxChange(0)
                                            }
                                            value={
                                                notificationSettings?.[0]
                                                    ?.onTender
                                            }
                                        />
                                        <div className="font-medium pr-2">
                                            Avsändare
                                        </div>
                                    </div>
                                    <div className="flex flex-row pb-2 gap-3 items-center">
                                        <InputCheckbox
                                            onChange={() =>
                                                handleCheckboxChange(1)
                                            }
                                            value={
                                                notificationSettings?.[1]
                                                    ?.onTender
                                            }
                                        />
                                        <div className="font-medium pr-2">
                                            Mottagare
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </>
            ) : (
                <>
                    <Title>Notiser</Title>
                    <Card>
                        <div className="description">
                            Välj vid vilka tillfällen och till vilka
                            e-postadresser ni vill att notiser ska skickas.
                        </div>
                        <div className="notifications">
                            {notificationSettings && (
                                <Input
                                    type="table"
                                    onChange={setNotificationSettings}
                                    value={notificationSettings}
                                    object={{
                                        name: {
                                            type: "text",
                                            title: "Namn",
                                        },
                                        email: {
                                            type: "text",
                                            title: "Email",
                                        },
                                        onTender: {
                                            type: "checkbox",
                                            title: "Bokningsbekräftelse",
                                        },
                                    }}
                                />
                            )}
                        </div>
                    </Card>
                </>
            )}
            <Title>Bekräfta & beställ</Title>
            <Card className="confirm">
                {
                    <div className="summary">
                        Om upphämtning bokas idag
                        {deliveryDate && (
                            <>
                                {" "}
                                estimeras leveransen till{" "}
                                {moment(deliveryDate).format("YYYY-MM-DD")}.
                            </>
                        )}
                    </div>
                }
                <Confirmation
                    message={price}
                    price={price}
                    service={context.quotation.service}
                    note={getPriceNote()}
                    receiverPays={receiverPays}
                    valid={validateEmail}
                    bookOrder={bookPostNordOrder}
                    proceedWithoutPrice={undefined}
                    disabled={totalInsuranceCost && !insuranceTermsAccepted}
                />
                {message && (
                    <div className="message">
                        <div className="text">{message.title}</div>
                        <div className="details">{message.text}</div>
                    </div>
                )}
            </Card>
        </>
    );
}

export default Component;
