import React from "react";
import styled from "styled-components/macro";

let Container = styled.table`
    width: 100%;
    margin-bottom: 16px;
    flex-direction: column;

    > thead {
        > tr {
            > th {
                background: #fafafa;
                padding: 1em 0;
                border-bottom: 1px solid #ddd;
                border-top: 1px solid #ddd;
                color: #333;
                font-weight: normal;

                &:first-child {
                    padding-left: 1em;
                }
                &:last-child {
                    padding-right: 1em;
                    text-align: right;
                }
            }
        }
    }

    > tbody {
        > tr {
            > td {
                background: #fff;
                padding: 0.5em 0;

                &:first-child {
                    padding-left: 1em;
                }
                &:last-child {
                    padding-right: 1em;
                    text-align: right;
                }

                &.service {
                    > img {
                        width: 32px;
                        object-size: contain;
                    }
                }
            }

            &:hover > td {
                background: rgba(250, 250, 250, 1);
            }
        }

        > .emptyState {
            > td {
                text-align: center !important;
                font-style: italic;
            }
        }
    }
`;

function Component(props) {
    return <Container {...props}></Container>;
}

export default Component;
