import React, { useEffect, useState } from "react";
import toast, { LoaderIcon } from "react-hot-toast";
import Api from "services/Api/Api";
import useOrdersAndSelectedUpdater from "hooks/batch/useOrdersAndSelectedUpdater";

const SelectServicePoint = ({
    order,
    setSelectedQuotation,
    selectedQuotation,
}) => {
    const [servicePoints, setServicePoints] = useState([]);
    const [error, setError] = useState(false);

    const { updateOrdersAndSelectedOrders } = useOrdersAndSelectedUpdater();

    const onChangeSetServicePoint = (servicePoint) => {
        if (selectedQuotation.servicePointId !== servicePoint.id) {
            setSelectedQuotation({
                ...selectedQuotation,
                servicePoint,
            });
        } else {
            setSelectedQuotation({
                ...selectedQuotation,
                servicePoint: undefined,
            });
        }
    };

    async function getServicePoints() {
        if (selectedQuotation.servicePoints) {
            const formattedServicePoints = selectedQuotation.servicePoints.map(
                (servicePoint) => {
                    const rawDistance =
                        servicePoint.location.distanceFromRecipientAddress;
                    const distance = {
                        amount:
                            rawDistance > 1000
                                ? `${(rawDistance / 1000).toFixed(2)}`
                                : `${rawDistance}`,
                        unit: rawDistance > 1000 ? "km" : "m",
                    };
                    return {
                        name: servicePoint.location.name,
                        distance: distance.amount,
                        id: servicePoint.bookingInstructions.servicePointId,
                        servicePointId:
                            servicePoint.bookingInstructions.servicePointId,
                        distanceUnit: distance.unit,
                        bookingInstructions: servicePoint.bookingInstructions,
                        location: servicePoint.location,
                    };
                },
            );
            return setServicePoints(formattedServicePoints);
        }
        try {
            if (
                order.data?.servicePoints?.[
                    selectedQuotation.service.productCode
                ]
            ) {
                return setServicePoints(
                    order.data.servicePoints[
                        selectedQuotation.service.productCode
                    ],
                );
            }

            const res = await Api.getServicePoints({
                destination: order.data?.receiver,
                service: selectedQuotation.service,
            });

            const sortedServicePoints = res
                .sort((a, b) => a.distance - b.distance)
                .slice(0, 5);

            updateOrdersAndSelectedOrders([
                {
                    ...order,
                    data: {
                        ...order.data,
                        servicePoints: {
                            ...order.data.servicePoints,
                            [selectedQuotation.service.productCode]:
                                sortedServicePoints,
                        },
                    },
                },
            ]);

            setServicePoints(sortedServicePoints);
        } catch (error) {
            setError(true);
            toast.error(
                "Kunde inte hämta utlämningsställen, försändelsen kommer att skickas till närmaste utlämningsställe",
            );
        }
    }

    useEffect(() => {
        getServicePoints();
    }, []);

    return (
        <>
            {!error && (
                <>
                    {servicePoints.length > 0 ? (
                        <div>
                            <h1>Välj utlämningsställe:</h1>
                            {servicePoints?.map((servicePoint) => (
                                <div
                                    key={servicePoint.id}
                                    className="flex gap-3 pl-3 py-2 items-center"
                                >
                                    <input
                                        type="radio"
                                        name="servicePoint"
                                        className="h-5 w-5"
                                        style={{
                                            accentColor: "#1E40AF",
                                        }}
                                        value={servicePoint.id}
                                        checked={
                                            selectedQuotation.servicePoint
                                                ?.id === servicePoint.id ??
                                            order.data?.selectedQuote
                                                ?.servicePoint?.id ===
                                                servicePoint.id
                                        }
                                        onChange={() =>
                                            onChangeSetServicePoint(
                                                servicePoint,
                                            )
                                        }
                                    />
                                    <div className="flex w-3/5">
                                        <p>
                                            {servicePoint.shopName ||
                                                servicePoint.name}
                                        </p>
                                        <p className="ml-auto flex gap-3">
                                            {servicePoint.distance}{" "}
                                            {servicePoint.distanceUnit}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="flex justify-center p-3">
                            <LoaderIcon className="h-8 w-8" />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default SelectServicePoint;
