import Input from "components/OldInput";
import { getCustomsTermsOptions } from "helpers/CustomsTerms";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import Context from "context/Global";
import { useContext } from "react";
import { incoTerms } from "constants/IncoTerms";
import { currencyList } from "constants/currency";

const CustomsTerms = () => {
    let Title = styled.div`
        font-size: 24px;
        color: rgba(0, 0, 0, 0.6);
        padding: 16px 0px;
    `;

    const context = useContext(Context);

    const carrier = context?.quotation?.service?.name?.toUpperCase();

    const customsTermsOptions = getCustomsTermsOptions({
        carrier,
        fromCountryCode: context?.order?.sender?.countryCode,
        toCountryCode: context?.order?.receiver?.countryCode,
    });

    const [customsInformation, setCustomsInformation] = useState(() => {
        const defaultCurrency = "SEK";
        const defaultCustomsValue = "0";

        const availableCurrencies = currencyList.map(
            (currency) => currency.value,
        );
        const availableCustomsTerms = customsTermsOptions.map(
            (term) => term.value,
        );

        const orderCurrency = context.order?.customsInvoice?.currency;
        const orderCustomsValue =
            context.order?.customsInvoice?.customsValue?.toString();
        const orderCustomsTerms = context.order?.customsInvoice?.incoTerms;

        const isValidCurrency = availableCurrencies.includes(orderCurrency);
        const isValidCustomsTerms =
            availableCustomsTerms.includes(orderCustomsTerms);

        return {
            currency: isValidCurrency ? orderCurrency : defaultCurrency,
            customsValue:
                isValidCurrency && orderCustomsValue
                    ? orderCustomsValue
                    : defaultCustomsValue,
            customsTerms: isValidCustomsTerms
                ? orderCustomsTerms
                : customsTermsOptions[0].value,
        };
    });
    useEffect(() => {
        context.updateOrder({
            information: {
                ...context.order.information,
                customsValue: customsInformation?.customsValue.replace(
                    ",",
                    ".",
                ), // clean faulty decimal values
                customsTerms: customsInformation?.customsTerms,
                currency: customsInformation?.currency,
            },
        });
    }, [
        customsInformation?.customsValue,
        customsInformation?.customsTerms,
        customsInformation?.currency,
    ]);

    return (
        <div>
            <Title>Tull-villkor</Title>
            <form>
                <Input
                    onChange={setCustomsInformation}
                    value={customsInformation}
                    type="list"
                    object={{
                        customsTerms: {
                            type: "dropdown",
                            title: "Tull-villkor",
                            required: true,
                            options: customsTermsOptions,
                        },
                        currency: {
                            type: "dropdown",
                            title: "Valuta för tullvärde",
                            required: true,
                            options: currencyList,
                        },
                        customsValue: {
                            type: "text",
                            title: "Tullvärde",
                            required: true,
                            placeholder: "",
                            maxLength: 35,
                            unit: customsInformation?.currency || "SEK",
                        },
                    }}
                />
            </form>
            {customsInformation?.customsTerms === incoTerms.ddp.code && (
                <p className="text-sm text-left mr-36 pt-3">
                    Tull och moms-kostnader faktureras till avsändaren. OBS! Med
                    DHL Freight går alltid tull och moms till mottagaren. Hos
                    UPS kostar det 197 SEK extra pga administrativ avgift.
                </p>
            )}
        </div>
    );
};

export default CustomsTerms;
