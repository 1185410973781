import React from "react";
import Alert, { AlertTypes } from "../../../../components/Alerts/Alert";
import Integrations from "../../../../constants/integrations";
import FortnoxReAuth from "../IntegrationsReAuth/FortnoxReAuth";
import BusinessCentralReAuth from "../IntegrationsReAuth/BusinessCentralReAuth";

const createIntegrationMessages = (integrationName) => ({
    expiredMessage: `${integrationName} integration har löpt ut.`,
    expiringMessage: `${integrationName} integrationen är på väg att utgå för att fortsätta importera försändelser automatiskt.`,
});

const integrationConfig = {
    [Integrations.BUSINESS_CENTRAL]: {
        component: BusinessCentralReAuth,
        ...createIntegrationMessages("Business Central"),
    },
    [Integrations.FORTNOX]: {
        component: FortnoxReAuth,
        ...createIntegrationMessages("Fortnox"),
    },
};

const NotificationHandler = ({ data }) => {
    const renderNotification = () => {
        const integrationInfo = integrationConfig[data.integration.type];
        if (!integrationInfo) return null;

        const alertType =
            data.status === "EXPIRED" ? AlertTypes.ERROR : AlertTypes.WARNING;

        let message =
            data.status === "EXPIRED"
                ? integrationInfo.expiredMessage
                : `${integrationInfo.expiringMessage}`;

        const IntegrationComponent = integrationInfo.component;

        return (
            <Alert type={alertType}>
                {message}
                <IntegrationComponent
                    integrationData={data?.integration.data}
                    integrationId={data?.integration.id}
                    className="nav-link text-sm border-b-2 font-medium border-red-500"
                />
            </Alert>
        );
    };

    return renderNotification();
};

export default NotificationHandler;
