import Context from "context/Global";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    > .box {
        width: 50vw;
        height: 50vw;
        background: #fff;
        border-radius: 1rem;
        box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.5);
    }
`;

function Component({ history, closeDialog = () => {}, ...props }) {
    const context = useContext(Context);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        // Ask which products this order could work on. Make the request fast.
        const applicableServices = await Api.getApplicableServices({
            order: props.order.data,
        });

        // List the products
        setProducts(applicableServices);
    }

    function close(ev) {
        ev.stopPropagation();
        closeDialog();
    }

    return (
        <Container onClick={close}>
            <div className="box" onClick={(ev) => ev.stopPropagation()}>
                {products.map((product) => {
                    return (
                        <div className="product">
                            <img
                                src={`/images/transporters/${product.transporter}.svg`}
                            />
                            {product.name}
                            <button className="c-button c-button--raised">
                                Quote
                            </button>
                            <button className="c-button c-button--raised">
                                Boka
                            </button>
                        </div>
                    );
                })}
            </div>
        </Container>
    );
}

export default withRouter(Component);
