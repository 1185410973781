import { v4 as uuidv4 } from "uuid";
import XLSX from "xlsx";

function parse({ workbook, fileName }) {
    const sheet = workbook.Sheets[workbook.SheetNames[0]];
    var range = XLSX.utils.decode_range(sheet["!ref"]);

    const headers = [];
    const entries = [];
    for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
        if (!sheet[XLSX.utils.encode_cell({ r: rowNum, c: 0 })]) {
            break;
        }
        if (
            sheet[XLSX.utils.encode_cell({ r: rowNum, c: 0 })].v === "Faktura"
        ) {
            // Set headers.
            for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
                headers.push(
                    sheet[XLSX.utils.encode_cell({ r: rowNum, c: colNum })].v,
                );
            }
        } else {
            let entry = {};

            for (let colNum = range.s.c; colNum <= 22; colNum++) {
                if (headers[colNum] === "Faktura datum") {
                    entry[headers[colNum]] =
                        sheet[
                            XLSX.utils.encode_cell({ r: rowNum, c: colNum })
                        ].w;
                } else {
                    entry[headers[colNum]] =
                        sheet[
                            XLSX.utils.encode_cell({ r: rowNum, c: colNum })
                        ].v;
                }
            }
            entry.rows = [];
            entry.totalShipmentCost = 0;
            entry.totalShipmentCostVat = 0;
            entry.totalShipmentCostNoVat = 0;
            for (let colNum = 24; colNum <= range.e.c; colNum++) {
                if (headers[colNum] === "Fakturerat") {
                    entry.totalShipmentCost +=
                        sheet[
                            XLSX.utils.encode_cell({ r: rowNum, c: colNum })
                        ].v;
                    entry.totalShipmentCostVat +=
                        sheet[
                            XLSX.utils.encode_cell({ r: rowNum, c: colNum })
                        ].v;
                } else if (headers[colNum] === "Valuta") {
                    entry.currency =
                        sheet[
                            XLSX.utils.encode_cell({ r: rowNum, c: colNum })
                        ].v;
                } else {
                    // if(header should be surcharged set row.surcharge to true)
                    if (
                        sheet[XLSX.utils.encode_cell({ r: rowNum, c: colNum })]
                            .v
                    ) {
                        entry.rows.push({
                            title: headers[colNum],
                            cost: sheet[
                                XLSX.utils.encode_cell({ r: rowNum, c: colNum })
                            ].v,
                            vat: true,
                            surcharge:
                                headers[colNum] ===
                                "Drivmedel och dieselskattetillägg",
                            key: uuidv4(),
                        });
                    }
                }
            }

            entries.push(entry);
        }
    }

    return entries.map((entry) => {
        if (entry.Antal) {
            entry.rows.push({
                title: `Antal kolli: ${entry.Antal} st`,
            });
        }
        if (entry.Flakmeter) {
            entry.rows.push({
                title: `Flakmeter: ${entry.Flakmeter} flm`,
            });
        }
        if (entry["Fraktdragande vikt"]) {
            entry.rows.push({
                title: `Fraktdragande vikt: ${entry["Fraktdragande vikt"]} kg`,
            });
        }
        if (entry["Kubikmeter"]) {
            entry.rows.push({
                title: `Kubikmeter: ${entry["Kubikmeter"]} m3`,
            });
        }
        if (entry["Terms"]) {
            entry.rows.push({
                title: `Villkor: ${entry["Terms"]}`,
            });
        }
        if (entry["Vikt"]) {
            entry.rows.push({
                title: `Vikt: ${entry["Vikt"]} kg`,
            });
        }
        return {
            fileName: fileName,
            transporter: "DSV",
            trackingNumber: entry.Fraktsedel,
            reference: entry.Kundref,
            currentInvoiceNumber: entry.Faktura,
            date: entry["Faktura datum"],
            title: "Transport",
            sender: entry["Hämtning namn"],
            senderCountry: entry["Hämtning land"],
            recipient: entry["Leverans namn"],
            recipientCountry: entry["Leverans land"],
            totalShipmentCost: entry.totalShipmentCost,
            totalShipmentCostVat: entry.totalShipmentCostVat,
            totalShipmentCostNoVat: entry.totalShipmentCostNoVat,
            vat: true,
            rows: entry.rows,
        };
    });
}

export default {
    parse,
};
