import React, { useContext, useEffect, useState } from "react";
import Context from "context/Global";
import "moment/locale/sv";
import SearchableDropdown from "../../../../components/Input/SearchableDropdown";
import Api from "../../../../services/Api/Api";
import useCreateDraftsStore from "../createDraftsStore";
import toast from "react-hot-toast";

function Component() {
    const context = useContext(Context);
    const {
        receiverPaysContactId,
        setReceiverPaysAccountNumber,
        setReceiverPaysCarrier,
    } = useCreateDraftsStore();

    const userSettings = context.user.customer.settings;
    const [checked, setChecked] = useState(false);
    const [receiverPaysOptions, setReceiverPaysOptions] = useState([]);
    const [selectedReceiverPaysOption, setSelectedReceiverPaysOption] =
        useState(null);

    useEffect(() => {
        resetStates();
        if (receiverPaysContactId && receiverPaysContactId !== "self") {
            fetchReceiverPaysOptions(receiverPaysContactId);
        }
    }, [receiverPaysContactId]);

    const fetchReceiverPaysOptions = async (contactId) => {
        try {
            const receiverPaysContacts = await Api.getContact({ contactId });
            setReceiverPaysOptions(
                receiverPaysContacts.receiverPays
                    ?.map((item) => ({
                        value: `${item.carrier} - ${item.accountNumber}`,
                        label: `${item.carrier} - ${item.accountNumber}`,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label)) || [],
            );
            setChecked(false);
        } catch (error) {
            resetStates();
            toast.error("Något gick fel vid hämtning av mottagarfrakt.");
        }
    };

    const resetStates = () => {
        setChecked(false);
        setReceiverPaysOptions([]);
        setSelectedReceiverPaysOption(null);
        setReceiverPaysCarrier(null);
        setReceiverPaysAccountNumber(null);
    };

    const handleReceiverPaysOptionChange = (selectedOption) => {
        setSelectedReceiverPaysOption(selectedOption);
        const [carrier, accountNumber] = selectedOption.split(" - ");
        setReceiverPaysCarrier(carrier);
        setReceiverPaysAccountNumber(accountNumber);
    };

    const handleOnCheck = () => {
        const newCheckedState = !checked;
        setChecked(newCheckedState);
        if (!newCheckedState) {
            setReceiverPaysCarrier(null);
            setReceiverPaysAccountNumber(null);
            setSelectedReceiverPaysOption(null);
        }
    };

    return userSettings.receiverPays ? (
        <div className="mb-8">
            <label className="text-lg pb-4 pl-4 text-secondary-700">
                Mottagarfrakt
            </label>
            <div className="bg-white p-4">
                <div className="flex items-center">
                    <input
                        checked={checked}
                        onChange={handleOnCheck}
                        type="checkbox"
                        className="h-5 w-5"
                        disabled={receiverPaysOptions.length === 0}
                        style={{ accentColor: "rgba(76, 80, 175, 1)" }}
                    />
                    <label className="mr-2 ml-2 flex-grow-0">
                        Skicka med mottagarfrakt
                    </label>
                    <p className="text-right font-thin flex-grow">
                        Om du ändrar avtal för mottagarfrakt behöver du söka om
                        frakten för att få upp rätt transportör
                    </p>
                </div>
                {checked && (
                    <div className="pt-4 w-1/4">
                        <SearchableDropdown
                            options={receiverPaysOptions}
                            placeholder="Välj avtal"
                            required={true}
                            onChange={handleReceiverPaysOptionChange}
                            value={selectedReceiverPaysOption}
                        />
                    </div>
                )}
            </div>
        </div>
    ) : null;
}

export default Component;
