import Input from "components/OldInput";
import Loader from "components/Loader/Loader";
import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import { handleDownloadS3 } from "../../../../utils/s3";

let Container = styled.div`
    > .title {
        font-size: 2rem;
        padding: 1rem;
    }
    > .description {
        padding: 0 1rem;
    }

    > .form {
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    > .buttons {
        padding: 1rem;
    }

    ul {
        padding: 1rem 0;
        > li {
            list-style-type: disc;
            list-style-position: inside;
            margin-left: 2rem;
        }
    }

    a {
        text-decoration: underline;
        cursor: pointer;
        color: #2563eb;

        &:hover {
            color: #1e40af;
            text-decoration: underline;
        }

        &:visited {
            color: #7c3aed;
        }
    }
`;

function Component(props) {
    const context = useContext(Context);
    const [data, setData] = useState({});
    const [plans, setPlans] = useState(null);
    const [identificationToken, setIdentificationToken] = useState();
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadIntegration();
    }, []);

    useEffect(() => {
        if (identificationToken && plans && plans.length) {
            setData({
                type: "Magento",
                identifier: identificationToken,
                planId: plans[0].id,
            });
        }
    }, [identificationToken, plans]);

    async function loadIntegration() {
        setIdentificationToken(await Api.getIntegrationIdentifier());
        setPlans(
            await Api.getIntegrationPlans({
                integrationId: 4,
            }),
        );
    }

    async function addIntegration() {
        try {
            data.identifier = new URL(data.identifier).hostname;
            setLoading(true);
            await Api.createCustomerIntegration({
                customerId: context.user.customer.id,
                data: data,
            });
            context.setUser(await Api.getCurrentUser());
            props.history.push("/addons");
        } catch (error) {
            setErrorMessage(
                "Vi kunde inte aktivera integrationen med din aktiveringskod. Verifiera att du gjort rätt och testa igen.",
            );
            setLoading(false);
        }
    }

    if (plans === null) {
        return <Loader />;
    }

    return (
        <Container>
            <div className="title">Lägg till Magento</div>
            <div className="description">
                Med vår koppling mot Magento kan ni enkelt hämta de
                beställningar ni vill. Uppgifterna skrivs in automatiskt i de
                olika fälten och ni får snabbt upp pris. Installationen kräver
                viss teknisk vana då man behöver ladda upp och installera
                modulen manuellt på servern.
            </div>
            <ul className="steps">
                <li>
                    Ladda ner vår{" "}
                    <a
                        onClick={() =>
                            handleDownloadS3({
                                url: `${
                                    process.env.REACT_APP_API_URL
                                }/api/s3/integration?objectKey=${encodeURIComponent(
                                    "magento/zendr-magento.zip",
                                )}`,
                                desiredFileName: "zendr-magento.zip",
                            })
                        }
                    >
                        modul till Magento
                    </a>
                </li>
                <li>
                    Zippa upp filen och lägg in Zendr-foldern på Magento-servern
                </li>
                <li>
                    Hitta Magento i filsystemet (ex.{" "}
                    <code>/var/www/magento</code>) och lägg Zendr-folder under{" "}
                    <code>app/code</code>.
                </li>
                <li>
                    Gå till magento-installationens root och kör kommandot nedan
                    för att installera och aktivera modulen
                </li>
                <li>
                    Kör detta kommando:
                    <code className="my-2 inline-block">
                        bin/magento setup:di:compile && bin/magento
                        setup:upgrade && bin/magento setup:static-content:deploy
                        -f && bin/magento indexer:reindex && bin/magento
                        cache:flush
                    </code>
                </li>
                <li>Skriv in adressen till er webshop i fältet nedan</li>
                <li>Aktivera sedan integrationen med knappen</li>
            </ul>
            {plans.length > 0 && (
                <>
                    <div className="form">
                        <Input
                            type="list"
                            onChange={setData}
                            object={{
                                type: {
                                    type: "text",
                                    hidden: true,
                                    value: "Magento",
                                },
                                identifier: {
                                    title: "Din webshops domän",
                                    placeholder: "webshop.mitt-foretag.se",
                                    type: "text",
                                },
                                planId: {
                                    type: "text",
                                    hidden: true,
                                    title: "Prenumerationstyp",
                                    value: plans[0].id,
                                },
                                // planId: {
                                // 	type: MultiToggle,
                                // 	title: 'Prenumerationstyp',
                                // 	options: plans.map((plan) => {
                                // 		return {
                                // 			title: `${plan.price} SEK / ${plan.paymentInterval}`,
                                // 			value: plan.id
                                // 		}
                                // 	})
                                // }
                            }}
                        />
                    </div>
                    <div className="buttons">
                        <button
                            className="c-button c-button--raised"
                            disabled={loading}
                            onClick={addIntegration}
                        >
                            Aktivera
                        </button>
                    </div>
                </>
            )}
            {errorMessage && <div className="error">{errorMessage}</div>}
        </Container>
    );
}

export default Component;
