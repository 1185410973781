import Context from "context/Global";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";

let File = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;

    > .remove {
        position: absolute;
        right: -12px;
        top: -12px;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        border-radius: 24px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 6px;
        cursor: pointer;
        color: #000;

        > svg {
            width: 100%;
            height: 100%;
        }
    }

    > img {
        object-fit: contain;
        object-position: center;
        width: 100%;
        height: 100%;
    }
`;

let FullscreenContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
        width: 90%;
        height: 90%;
        object-fit: contain;
        object-position: center;
    }
`;

function Component({ removeIndex = (index) => {}, ...props }) {
    const context = useContext(Context);
    const [files, setFiles] = useState(props.files || []);

    useEffect(() => {
        setFiles(props.files || []);
    }, [props.files]);

    return (
        <>
            {files.map((file, index) => {
                let fileType = "unknown";
                if (file.mimetype.indexOf("image") === 0) {
                    fileType = "image";
                }
                if (file.mimetype === "application/pdf") {
                    fileType = "pdf";
                }
                return (
                    <File key={file.key || file.url}>
                        <div
                            className="remove"
                            onClick={() => removeIndex(index)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
                            </svg>
                        </div>
                        {fileType === "image" && <img src={file.url}></img>}
                        {fileType === "pdf" && (
                            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTEuMzYzIDJjNC4xNTUgMCAyLjYzNyA2IDIuNjM3IDZzNi0xLjY1IDYgMi40NTd2MTEuNTQzaC0xNnYtMjBoNy4zNjN6bS44MjYtMmgtMTAuMTg5djI0aDIwdi0xNC4zODZjMC0yLjM5MS02LjY0OC05LjYxNC05LjgxMS05LjYxNHptNC44MTEgMTNoLTIuNjI4djMuNjg2aC45MDd2LTEuNDcyaDEuNDl2LS43MzJoLTEuNDl2LS42OThoMS43MjF2LS43ODR6bS00LjkgMGgtMS41OTl2My42ODZoMS41OTljLjUzNyAwIC45NjEtLjE4MSAxLjI2Mi0uNTM1LjU1NS0uNjU4LjU4Ny0yLjAzNC0uMDYyLTIuNjkyLS4yOTgtLjMtLjcxMi0uNDU5LTEuMi0uNDU5em0tLjY5Mi43ODNoLjQ5NmMuNDczIDAgLjgwMi4xNzMuOTE1LjY0NC4wNjQuMjY3LjA3Ny42NzktLjAyMS45NDgtLjEyOC4zNTEtLjM4MS41MjgtLjc1NC41MjhoLS42Mzd2LTIuMTJ6bS0yLjc0LS43ODNoLTEuNjY4djMuNjg2aC45MDd2LTEuMjc3aC43NjFjLjYxOSAwIDEuMDY0LS4yNzcgMS4yMjQtLjc2My4wOTUtLjI5MS4wOTUtLjU5NyAwLS44ODUtLjE2LS40ODQtLjYwNi0uNzYxLTEuMjI0LS43NjF6bS0uNzYxLjczMmguNTQ2Yy4yMzUgMCAuNDY3LjAyOC41NzYuMjI4LjA2Ny4xMjMuMDY3LjM2NiAwIC40ODktLjEwOS4xOTktLjM0MS4yMjctLjU3Ni4yMjdoLS41NDZ2LS45NDR6Ii8+PC9zdmc+" />
                        )}
                        {fileType === "unknown" && (
                            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTUgMnY1aDV2MTVoLTE2di0yMGgxMXptMS0yaC0xNHYyNGgyMHYtMThsLTYtNnoiLz48L3N2Zz4="></img>
                        )}
                    </File>
                );
            })}
        </>
    );
}

export default Component;
