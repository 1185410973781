import React from "react";

const ReceiverPaysTerms = () => {
    return (
        <>
            <h1 className="text-xl font-bold mb-4">
                Tilläggsavtal för Mottagarfrakt
            </h1>
            <p className="mb-4">
                Detta tilläggsavtal ("Avtalet") reglerar användningen av
                funktionen Mottagarfrakt "Funktionen" i webbtjänsten ”Portalen”
                tillhandahållen av Zendr "Leverantören". Alla villkor som inte
                uttryckligen ändras eller ersätts i detta Tilläggsavtal ska
                gälla enligt Användaravtalet.
            </p>

            <h2 className="text-lg font-semibold my-2">1. Funktionen</h2>
            <h3 className="text-md font-semibold mb-2">1.1 Mottagarfrakt</h3>
            <p className="mb-4">
                Genom att acceptera detta Avtal kommer Leverantören
                tillhandahålla en Funktion i Portalen för bokning med
                Mottagarfrakt. Genom denna Funktion kommer Användaren ha
                möjlighet att boka frakter baserat på mottagarens avtal med
                transportör. Följaktligen kommer mottagaren att vara ansvarig
                för fraktkostnaderna som bokas genom Portalen.
            </p>
            <h3 className="text-md font-semibold mb-2">
                1.2 Användarens godkännande
            </h3>
            <p className="mb-4">
                Användaren godkänner att använda Funktionen enligt de villkor
                som anges i detta Avtal.
            </p>

            <h2 className="text-lg font-semibold my-2">2. Kostnader</h2>
            <h3 className="text-md font-semibold mb-2">2.1 Kostnadsmodell</h3>
            <p className="mb-4">
                Funktionen är baserad på en prenumerationsmodell enligt angivna
                villkor och förlängs automatiskt tills aktivt avslut för
                användning av Funktionen har ägt rum enligt punkt 5, Avslutande
                av Avtalet.
            </p>
            <h3 className="text-md font-semibold mb-2">
                2.2 Abonnemang 1 månad
            </h3>
            <p className="mb-4">
                Användaren samtycker till att betala en fast månadsavgift enligt
                följande villkor:
            </p>
            <li className="mb-2">
                Månadsavgift: 249 kr för upp till 20 fraktetiketter per månad.
            </li>
            <li className="mb-2">
                Månadsavgift: 499 kr för obegränsat antal fraktetiketter per
                månad.
            </li>

            <h3 className="text-md font-semibold mb-2">
                2.3 Abonnemang 12 månader
            </h3>
            <p className="mb-4">
                Användaren samtycker till att betala en fast årsavgift enligt
                följande villkor:
            </p>
            <li className="mb-2">
                Årsavgift: 2390 kr för upp till 20 fraktetiketter per månad.
            </li>
            <li className="mb-2">
                Årsavgift: 4790 kr för obegränsat antal fraktetiketter per
                månad.
            </li>

            <h3 className="text-md font-semibold mb-2">
                2.4 Kostnad per etikett
            </h3>
            <p className="mb-4">
                Utöver abonnemangskostnaden tillkommer en per etikettkostnad
                enligt följande villkor:
            </p>
            <li className="mb-2">
                Abonnemang för upp till 20 fraktetiketter per månad: 3,5 kr per
                fraktetikett.
            </li>
            <li className="mb-2">
                Abonnemang för obegränsat antal fraktetiketter per månad: 2,5 kr
                per fraktetikett.
            </li>

            <h3 className="text-md font-semibold mb-2">
                2.5 Upphämtningskostnader
            </h3>
            <p className="mb-4">
                Eventuella upphämtningskostnader som tillkommer från
                transportören är Användarens ansvar att bekosta.
            </p>

            <h2 className="text-lg font-semibold my-2">
                3. Ansvar och skyldigheter
            </h2>
            <h3 className="text-md font-semibold mb-2">
                3.1 Användarens ansvar
            </h3>
            <p className="mb-4">
                Användaren ansvarar för att all information som lämnas i samband
                med bokningar med Funktionen är korrekt och får lämnas ut enligt
                tillämpliga lagar och förordningar.
            </p>

            <h3 className="text-md font-semibold mb-2">3.2 Betalningsansvar</h3>
            <p className="mb-4">
                Om mottagaren inte betalar fakturan för fraktkostnader, ska
                Användaren ansvara för att betala den obetalda fakturan samt
                eventuella tillkommande kostnader.
            </p>

            <h2 className="text-lg font-semibold my-2">4. Zendr Kundservice</h2>
            <h3 className="text-md font-semibold mb-2">
                4.1 Begränsningar för stöd från Zendr Kundservice
            </h3>
            <p className="mb-4">
                Zendrs kundservice kommer endast att tillhandahålla stöd för att
                genomföra bokningar av Mottagarfrakt via Portalen. Genom att
                godkänna detta avtal bekräftar och godkänner kunden att
                Kundservice inte kommer att kunna tillhandahålla ytterligare
                stöd eller assistans avseende frågor som rör kollin bokade med
                Mottagarfrakt. Mottagaren ansvarar för att själv hantera och
                lösa eventuella andra frågor med transportörerna direkt.
            </p>

            <h2 className="text-lg font-semibold my-2">
                5. Ändringar av villkoren
            </h2>
            <h3 className="text-md font-semibold mb-2">5.1 Ändringsrätt</h3>
            <p className="mb-4">
                Zendr förbehåller sig rätten att när som helst ändra i Villkoren
                genom att publicera ändringar online (i avtalet) utan att först
                meddela Användaren. Användaren är ansvarig för att regelbundet
                kontrollera information online (i avtalet) för att få reda på
                ändringar. Genom att fortsätta använda Funktionen efter
                publiceringen av ändringar, anses Användaren ha accepterat de
                ändrade Villkoren.
            </p>

            <h2 className="text-lg font-semibold my-2">
                6. Avslutande av Avtalet
            </h2>
            <h3 className="text-md font-semibold mb-2">
                6.1 Avslutande på Leverantörens initiativ
            </h3>
            <p className="mb-4">
                Leverantören förbehåller sig rätten att avsluta detta avtal med
                omedelbar verkan genom att inaktivera Funktionen i Portalen.
            </p>
            <h3 className="text-md font-semibold mb-2">
                6.2 Avslutande på Användarens initiativ
            </h3>
            <p className="mb-4">
                Användaren har rätt att avsluta detta avtal när som helst genom
                att avsluta abonnemanget i Portalen. Användaren kommer därefter
                ha fortsatt tillgång till Funktionen till slutet av
                faktureringsperioden. Betalningar är inte återbetalningsbara.
                Betalningsansvaret enligt punkt 3.2 för försändelser som har
                bokats med Funktionen kvarstår hos Användaren även efter att
                avtalet har upphört att gälla.
            </p>
            <h4 className="text-md font-semibold mb-2">
                6.2.1 Vid Abonnemang 1 månad
            </h4>
            <p className="mb-4">
                Under den månad då avtalet avslutas kommer Användaren att
                faktureras för hela månadsavgiften för den pågående månaden.
            </p>
            <h4 className="text-md font-semibold mb-2">
                6.2.2 Vid Abonnemang 12 månader
            </h4>
            <p className="mb-4">
                Ingen återbetalning av avgifter kommer att ske om avtalet
                avslutas under löptiden. Avtalet förlängs automatiskt med
                ytterligare 12 månader om det inte sägs upp av någon av parterna
                före den sista dagen i månaden före abonnemangets utgångsdatum.
            </p>
            <h3 className="text-md font-semibold mb-2">6.3 Efter avslutande</h3>
            <p className="mb-4">
                Efter avslutande kommer Användaren inte längre kunna använda
                Funktionen i Portalen. Det är dock möjligt att få tillgång till
                Funktionen på nytt genom att godkänna Avtalet igen.
            </p>

            <p className="mt-4">
                Genom att klicka på "Acceptera" detta Avtal, intygar Användaren
                att de har läst, förstått och accepterat att vara bunden av dess
                villkor.
            </p>
        </>
    );
};
export default ReceiverPaysTerms;
