function getProductName(productType, fromCountry, toCountry) {
    if (fromCountry === "SE" && toCountry === "SE") {
        switch (productType) {
            case "express12": {
                return "TNT 12:00";
            }
            case "express10": {
                return "TNT 10:00";
            }
            case "express9": {
                return "TNT 09:00";
            }
            default: {
                return "TNT Inrikes";
            }
        }
    } else if (productType === "economyExpress") {
        return "TNT Ekonomi";
    } else {
        switch (productType) {
            case "economyExpress": {
                return "TNT Ekonomi";
            }
            case "economyExpress12": {
                return "TNT Ekonomi 12:00";
            }
            case "express12": {
                return "TNT Express 12:00";
            }
            case "express10": {
                return "TNT Express 10:00";
            }
            case "express9": {
                return "TNT Express 09:00";
            }
            default: {
                return "TNT Express";
            }
        }
    }
}

function getTNTLemanDisplayData({ service, route }) {
    let name;

    switch (service.productCode) {
        case "LemanTNT Economy Export": {
            name = "TNT Ekonomi";
        }
        case "LemanTNT Economy Import": {
            name = "TNT Ekonomi";
        }
        case "LemanTNT Express Export": {
            name = "TNT Express";
        }
        case "LemanTNT Express Import": {
            name = "TNT Express";
        }
    }

    return {
        originalName: service.productCode,
        name,
        isExpress: false,
        tags: [],
    };
}

export { getTNTLemanDisplayData };
