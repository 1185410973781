import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    padding: 1rem;

    > .title {
        font-weight: bold;
        margin-top: 1rem;
    }

    > .list {
        display: grid;
        grid-template: auto / repeat(4, minmax(200px, 1fr));
        grid-gap: 1rem;

        > .template {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            padding: 0.2rem 1rem;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.05);
            &:hover {
                background: rgba(0, 0, 0, 0.1);
            }

            > .name {
                color: #000;
                text-decoration: none;
            }

            > .remove {
                width: 16px;
                height: 16px;
                min-width: 16px;
                min-height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #aaa;
                cursor: pointer;

                &:hover {
                    color: #555;
                }

                > svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    > .buttons {
        padding: 1rem 0;
    }
`;

function Component({ ...props }) {
    const context = useContext(Context);
    const [integration, setIntegration] = useState(props.integration);

    async function removeIntegration() {
        if (!confirm("Är du säker på att du vill ta bort integrationen?")) {
            return;
        }
        await Api.removeCustomerIntegration({
            customerId: context.user.customer.id,
            integrationId: integration.id,
        });
        props.history.push("/addons");
    }

    async function removeTemplate(template) {
        if (!integration.data.templates) {
            integration.data.templates = [];
        }

        await Api.updateIntegration({
            integrationId: integration.id,
            data: {
                templates: integration.data.templates.filter((t) => {
                    return t.productCode !== template.productCode;
                }),
            },
        });
        context.setUser(await Api.getCurrentUser());
        setIntegration(
            (
                await Api.getCustomerIntegrations({
                    customerId: context.user.customer.id,
                })
            ).find((i) => {
                return i.id === integration.id;
            }),
        );
    }

    return (
        <Container>
            <div>Integration: Magento</div>
            <div>Identifierare: {integration.data.identifier}</div>
            {/* <div>Betalplan (id): {integration.integrationPlanId}</div> */}
            <div className="title">Mallar</div>
            <div className="list">
                {integration.data.templates?.map((template) => {
                    return (
                        <div key={template.productCode} className="template">
                            <Link
                                to={`/addons/${integration.id}/templates/${template.productCode}`}
                                className="name"
                            >
                                {template.productCode}
                            </Link>
                            <div
                                className="remove"
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    removeTemplate(template);
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
                                    />
                                </svg>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="buttons">
                {/* <Link
                    to={`/addons/${integration.id}/templates/create`}
                    className={`c-button c-button--raised`}
                >
                    Skapa mall
                </Link> */}
                <button
                    className="c-button c-button--raised"
                    onClick={removeIntegration}
                >
                    Ta bort
                </button>
            </div>
        </Container>
    );
}

export default Component;
