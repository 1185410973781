import Input from "components/OldInput";
import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    padding: 1rem;

    > .title {
        font-weight: bold;
        font-size: 2rem;
    }
    > .subTitle {
        font-weight: bold;
        margin-top: 1rem;
    }

    > .settings {
        display: inline-block;
    }

    > .list {
        display: grid;
        grid-template: auto / repeat(auto-fit, minmax(200px, 300px));
        grid-gap: 1rem;

        > .template {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            padding: 0.5rem 1rem;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.05);
            &:hover {
                background: rgba(0, 0, 0, 0.1);
            }

            > .name {
                color: #000;
                text-decoration: none;
            }

            > .remove {
                width: 16px;
                height: 16px;
                min-width: 16px;
                min-height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #aaa;
                cursor: pointer;

                &:hover {
                    color: #555;
                }

                > svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    > .buttons {
        padding: 1rem 0;
    }
`;

function Component({ ...props }) {
    const context = useContext(Context);
    const [integration, setIntegration] = useState(props.integration);

    async function removeIntegration() {
        await Api.removeCustomerIntegration({
            customerId: context.user.customer.id,
            integrationId: integration.id,
        });
        props.history.push("/addons");
    }

    return (
        <Container>
            <div className="title">Quickbutik</div>
            <div className="subTitle">Inställingar</div>
            <div className="settings">
                <div className="buttons">
                    <button
                        onClick={removeIntegration}
                        className="c-button c-button--raised mt-5"
                    >
                        Remove Integration
                    </button>
                </div>
            </div>
        </Container>
    );
}

export default Component;
