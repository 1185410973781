import React, { memo } from "react";

const FreightParty = memo(({ party, type }) => {
    return party ? (
        <div className="flex flex-col justify-start text-left">
            <p className="font-bold">
                {party.contact?.private
                    ? party.contact?.name
                    : party.contact?.company}
            </p>
            <p>
                {party.postalTown}, {party.countryCode} {party.postalCode}
            </p>
            <p>{party.addressLine1}</p>
        </div>
    ) : (
        <div>
            <p className="text-red-400">
                {type === "sender" ? "Ingen avsändare" : "Ingen mottagare"}
            </p>
        </div>
    );
});

export default FreightParty;
