import axios from "axios";
import Loader from "components/Loader/Loader";
import Input from "components/OldInput";
import Context from "context/Global";
import moment from "moment";
import "moment/locale/sv";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    > .graphContainer {
        display: flex;

        > .menu {
            width: 20%;
            display: flex;
            flex-direction: column;
            padding: 1rem;
            box-sizing: border-box;

            > .box {
                padding-top: 1rem;

                > .list {
                    max-height: 200px;
                    overflow: auto;
                }

                > .title {
                    font-weight: bold;
                }
            }
        }

        > .chart {
            width: 80%;
            height: 600px;
            margin: 1em 1em;
            display: flex;
            flex-direction: column;
            padding: 1em;
            background: #fff;
            border-radius: 5px;
            box-shadow: 0 3px 12px 1px rgba(0, 0, 0, 0.1);

            > .graph {
                width: 100%;
                height: 100%;
                flex-shrink: 1;
            }
        }
    }

    > .header {
        padding: 1rem;
        display: flex;
        flex-direction: row;

        > .input {
            display: flex;
            margin-right: 1rem;
            align-items: center;

            > .title {
                padding-right: 1rem;
            }
        }

        .pagination {
            display: flex;
            align-items: center;
            margin-left: auto;
            cursor: pointer;

            > .previous,
            > .next {
                width: 16px;
                height: 16px;
                padding: 0 8px;
                box-sizing: content-box;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &.is-disabled {
                    color: #aaa;
                    pointer-events: none;
                }

                > svg {
                    width: 100%;
                    height: 100%;
                }
            }
            > .previous {
                transform: rotateZ(180deg);
            }
        }
    }

    > .batch {
        padding: 1rem;
        padding-bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
    }

    > table {
        width: 100%;
        margin-bottom: 16px;
        flex-direction: column;

        > thead {
            > tr {
                > th {
                    background: #fafafa;
                    padding: 1em 0;
                    border-bottom: 1px solid #ddd;
                    border-top: 1px solid #ddd;
                    color: #333;
                    font-weight: normal;

                    &:first-child {
                        padding-left: 1em;
                    }
                    &:last-child {
                        padding-right: 1em;
                    }
                }
            }
        }

        > tbody {
            > tr {
                > td {
                    background: #fff;
                    padding: 0.5em 0;

                    &:first-child {
                        padding-left: 1em;
                    }
                    &:last-child {
                        padding-right: 1em;
                    }

                    &.service {
                        > img {
                            width: 32px;
                            object-size: contain;
                        }
                    }
                }

                &:hover > td {
                    background: rgba(250, 250, 250, 1);
                }

                &.is-done > td {
                    background: rgba(200, 250, 200, 1);
                }
                &.is-deleting {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
        }
    }

    .delete {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #aaa;
        padding: 8px;
        cursor: pointer;

        &:hover {
            color: #555;
        }

        > svg {
            width: 100%;
            height: 100%;
        }
    }
`;

function View(props) {
    const context = useContext(Context);
    const [loadingInvoiceList, setLoadingInvoiceList] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [invoiceStatistics, setInvoiceStatistics] = useState(null);
    const [statisticsAggregation, setStatisticsAggregation] = useState();
    const [deletingInvoiceList, setDeletingInvoiceList] = useState([]);
    const [graphSettings, setGraphSettings] = useState({
        interval: "week",
        aggregation: "",
    });

    const [searchstring, setSearchstring] = useState("");
    const [startDate, setStartDate] = useState(
        sessionStorage.getItem("adminViewInvoiceListStartDate") || "",
    );
    const [endDate, setEndDate] = useState(
        sessionStorage.getItem("adminViewInvoiceListEndDate") || "",
    );
    const [dateInterval, setDateInterval] = useState(
        sessionStorage.getItem("adminViewInvoiceListDateInterval") || "all",
    );
    const [limit, setLimit] = useState(200);
    const [offset, setOffset] = useState(0);
    const [invoiceCount, setInvoiceCount] = useState(0);
    const cancelSearchTokenRef = useRef();
    const searchTimeoutRef = useRef();
    const startDateRef = useRef();
    const endDateRef = useRef();
    const dateIntervalRef = useRef();
    const isBatchSendingInvoicesRef = useRef(false);
    const [successfulInvoiceCount, setSuccessfulInvoiceCount] = useState(0);
    const [failedInvoiceCount, setFailedInvoiceCount] = useState(0);
    const [skippedInvoiceCount, setSkippedInvoiceCount] = useState(0);

    useEffect(() => {
        if (
            !(
                context.user.administrator ||
                (context.user.brand && context.user.brand.id)
            )
        ) {
            props.history.replace("/admin");
        } else {
            init();
        }
    }, []);

    async function init() {
        if (context.user.administrator || context.user.role === "OWNER") {
            let aggregationDropdown = [
                {
                    title: "Alla kunder",
                    value: "",
                },
            ];

            if (context.user.administrator) {
                const brands = (await Api.getBrands()).map((brand) => {
                    return {
                        title: brand.name,
                        value: "brand-" + brand.id,
                    };
                });
                aggregationDropdown = []
                    .concat(aggregationDropdown)
                    .concat(brands);
            }

            const clientManagers = (
                await Api.getClientManagers({
                    brandId: context.user.administrator
                        ? null
                        : context.user.brand.id,
                    includeOwners: true,
                })
            ).map((clientManager) => {
                return {
                    title: clientManager.name,
                    value: clientManager.id,
                };
            });

            aggregationDropdown = []
                .concat(aggregationDropdown)
                .concat(clientManagers);

            setStatisticsAggregation(aggregationDropdown);
        }
    }

    useEffect(() => {
        loadInvoices({});
        if (
            startDate === endDate &&
            startDate === moment().format("YYYY-MM-DD")
        ) {
            dateIntervalRef.current.set("today");
        } else if (
            startDate === endDate &&
            startDate === moment().subtract(1, "day").format("YYYY-MM-DD")
        ) {
            dateIntervalRef.current.set("yesterday");
        } else if (
            startDate === moment().startOf("isoWeek").format("YYYY-MM-DD") &&
            endDate === moment().endOf("isoWeek").format("YYYY-MM-DD")
        ) {
            dateIntervalRef.current.set("week");
        } else if (
            startDate ===
                moment()
                    .startOf("isoWeek")
                    .subtract(1, "week")
                    .format("YYYY-MM-DD") &&
            endDate ===
                moment()
                    .endOf("isoWeek")
                    .subtract(1, "week")
                    .format("YYYY-MM-DD")
        ) {
            dateIntervalRef.current.set("lastWeek");
        } else if (startDate === "" && endDate === "") {
            dateIntervalRef.current.set("all");
        } else {
            dateIntervalRef.current.set("manual");
        }
    }, [searchstring, startDate, endDate, limit, offset]);

    useEffect(() => {
        switch (dateInterval) {
            case "today": {
                setStartDate(moment().format("YYYY-MM-DD"));
                setEndDate(moment().format("YYYY-MM-DD"));
                startDateRef.current.set(moment().format("YYYY-MM-DD"));
                endDateRef.current.set(moment().format("YYYY-MM-DD"));
                break;
            }
            case "yesterday": {
                setStartDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
                setEndDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
                startDateRef.current.set(
                    moment().subtract(1, "day").format("YYYY-MM-DD"),
                );
                endDateRef.current.set(
                    moment().subtract(1, "day").format("YYYY-MM-DD"),
                );
                break;
            }
            case "week": {
                setStartDate(moment().startOf("isoWeek").format("YYYY-MM-DD"));
                setEndDate(moment().endOf("isoWeek").format("YYYY-MM-DD"));
                startDateRef.current.set(
                    moment().startOf("isoWeek").format("YYYY-MM-DD"),
                );
                endDateRef.current.set(
                    moment().endOf("isoWeek").format("YYYY-MM-DD"),
                );
                break;
            }
            case "lastWeek": {
                setStartDate(
                    moment()
                        .startOf("isoWeek")
                        .subtract(1, "week")
                        .format("YYYY-MM-DD"),
                );
                setEndDate(
                    moment()
                        .endOf("isoWeek")
                        .subtract(1, "week")
                        .format("YYYY-MM-DD"),
                );
                startDateRef.current.set(
                    moment()
                        .startOf("isoWeek")
                        .subtract(1, "week")
                        .format("YYYY-MM-DD"),
                );
                endDateRef.current.set(
                    moment()
                        .endOf("isoWeek")
                        .subtract(1, "week")
                        .format("YYYY-MM-DD"),
                );
                break;
            }
            case "all": {
                setStartDate("");
                setEndDate("");
                startDateRef.current.set("");
                endDateRef.current.set("");
                break;
            }
        }
        sessionStorage.removeItem("adminViewInvoiceListStartDate");
        sessionStorage.removeItem("adminViewInvoiceListEndDate");
        sessionStorage.setItem(
            "adminViewInvoiceListDateInterval",
            dateInterval,
        );
    }, [dateInterval]);

    useEffect(() => {
        Api.getInvoiceStatistics({
            interval: graphSettings.interval,
            brandId:
                graphSettings.aggregation.indexOf("brand-") === 0
                    ? parseInt(graphSettings.aggregation.substr(6))
                    : null,
            clientManagerId: parseInt(graphSettings.aggregation)
                ? parseInt(graphSettings.aggregation)
                : null,
        })
            .then(setInvoiceStatistics)
            .catch(() => {});
    }, [graphSettings]);

    async function loadInvoices({ loader = true }) {
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        if (cancelSearchTokenRef.current) {
            cancelSearchTokenRef.current.cancel();
        }
        cancelSearchTokenRef.current = axios.CancelToken.source();

        if (loader) {
            setLoadingInvoiceList(true);
        }

        searchTimeoutRef.current = setTimeout(async () => {
            const { invoices, count } = await Api.getInvoices({
                query: searchstring,
                startDate: startDate,
                endDate: endDate,
                limit: limit,
                offset: offset,
                cancelToken: cancelSearchTokenRef.current.token,
            });

            setInvoices(invoices);
            setInvoiceCount(count);
            if (loader) {
                setLoadingInvoiceList(false);
            }
        }, 500);
    }

    async function emailInvoice(invoice) {
        setInvoices(
            invoices.map((i) => {
                if (i.id === invoice.id) {
                    return {
                        ...i,
                        sendingEmail: true,
                    };
                } else {
                    return i;
                }
            }),
        );
        try {
            await Api.emailInvoice({
                invoiceId: invoice.id,
            });
            await loadInvoices({ loader: false });
        } catch (error) {
            switch (error.response.data.code) {
                case "INVOICE_EMAIL_MISSING": {
                    alert("Kunden saknar e-postadress");
                    break;
                }
                default: {
                    alert("Ett okänt fel uppstod. Ring Tobbe!");
                }
            }
        }
    }

    async function deleteInvoice({ invoiceId }) {
        if (window.confirm("Är du säker på att du vill ta bort fakturan?")) {
            setDeletingInvoiceList((oldList) => {
                return oldList.concat(invoiceId);
            });

            await Api.deleteInvoice({
                invoiceId,
            });

            setInvoices((invoices) => {
                const newList = invoices.filter((invoice) => {
                    return invoice.id !== invoiceId;
                });
                return newList;
            });
        }
    }

    async function toggleLimit() {
        if (limit < 200 && invoiceCount > 100) {
            setLimit(200);
        } else if (limit < 500 && invoiceCount > 200) {
            setLimit(500);
        } else if (limit < 1000 && invoiceCount > 500) {
            setLimit(1000);
        } else if (limit < 5000 && invoiceCount > 1000) {
            setLimit(5000);
        } else {
            setLimit(100);
        }
    }

    async function previousPage() {
        setOffset(offset - limit < 0 ? 0 : offset - limit);
    }
    async function nextPage() {
        if (offset + limit < invoiceCount) {
            setOffset(offset + limit);
        }
    }

    return (
        <Container>
            {invoiceStatistics && (
                <div className="graphContainer">
                    <div className="menu">
                        <Input
                            onChange={setGraphSettings}
                            value={graphSettings}
                            type="list"
                            object={{
                                interval: {
                                    title: "Intervall",
                                    type: "dropdown",
                                    options: [
                                        {
                                            title: "År",
                                            value: "year",
                                        },
                                        {
                                            title: "Månad",
                                            value: "month",
                                        },
                                        {
                                            title: "Vecka",
                                            value: "week",
                                        },
                                        {
                                            title: "Dag",
                                            value: "day",
                                        },
                                    ],
                                },
                                aggregation: {
                                    title: "Aggregering",
                                    type: "dropdown",
                                    options: statisticsAggregation || [],
                                    hidden: !(
                                        context.user.administrator ||
                                        context.user.role === "OWNER"
                                    ),
                                },
                            }}
                        />
                    </div>
                    <div className="chart">
                        <Line
                            data={{
                                labels: invoiceStatistics.map((row) => {
                                    switch (graphSettings.interval) {
                                        case "year": {
                                            return moment(
                                                row.title,
                                                "YYYY",
                                            ).format("YYYY");
                                        }
                                        case "month": {
                                            return moment(
                                                row.title,
                                                "YYYY-MM",
                                            ).format("MMM");
                                        }
                                        case "week": {
                                            return `v${row.title.substr(5)}`;
                                        }
                                        case "day": {
                                            return moment(
                                                row.title,
                                                "YYYY-MM-DD",
                                            ).format("YYY-MM-DD");
                                        }
                                    }
                                }),
                                datasets: [
                                    {
                                        label: "Försändelser",
                                        type: "line",
                                        fill: false,
                                        data: invoiceStatistics.map((row) => {
                                            return Math.round(
                                                row.numberOfInvoices,
                                            );
                                        }),
                                        yAxisID: "y-axis-2",
                                        borderColor: "rgba(0.0.0,.6)",
                                    },
                                    {
                                        label: "Omsättning",
                                        type: "bar",
                                        fill: false,
                                        pointHoverRadius: 5,
                                        pointRadius: 1,
                                        pointHitRadius: 10,
                                        borderWidth: 1,
                                        borderColor: "rgb(176,196,222)",
                                        backgroundColor: "rgb(173,216,230)",
                                        data: invoiceStatistics.map((row) => {
                                            return Math.round(row.income);
                                        }),
                                        spanGaps: false,
                                        yAxisID: "y-axis-1",
                                    },
                                ],
                            }}
                            options={{
                                maintainAspectRatio: false,
                                tooltips: {
                                    callbacks: {
                                        label: function (tooltipItem, data) {
                                            var label =
                                                data.datasets[
                                                    tooltipItem.datasetIndex
                                                ].label || "";

                                            if (label) {
                                                label += ": ";
                                            }
                                            label += tooltipItem.yLabel
                                                .toString()
                                                .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    " ",
                                                );

                                            return label;
                                        },
                                    },
                                },
                                scales: {
                                    xAxes: [
                                        {
                                            stacked: true,
                                        },
                                    ],
                                    yAxes: [
                                        {
                                            id: "y-axis-2",
                                            position: "right",
                                            scaleLabel: {
                                                display: true,
                                                labelString: "Antal",
                                            },
                                            ticks: {
                                                beginAtZero: true,
                                            },
                                        },
                                        {
                                            id: "y-axis-1",
                                            scaleLabel: {
                                                display: true,
                                                labelString: "Kronor",
                                            },
                                            ticks: {
                                                beginAtZero: true,
                                                callback: (
                                                    value,
                                                    index,
                                                    values,
                                                ) => {
                                                    if (
                                                        parseInt(value) >= 1000
                                                    ) {
                                                        return value
                                                            .toString()
                                                            .replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                " ",
                                                            );
                                                    } else {
                                                        return value;
                                                    }
                                                },
                                            },
                                        },
                                    ],
                                },
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="header">
                <div className="input">
                    <Input
                        type="text"
                        onChange={setSearchstring}
                        placeholder={"Sök..."}
                    />
                </div>
                <div className="input">
                    <div className="title">Från</div>
                    <Input
                        ref={startDateRef}
                        type="date"
                        value={startDate}
                        onChange={(value) => {
                            sessionStorage.removeItem(
                                "adminViewInvoiceListDateInterval",
                            );
                            sessionStorage.setItem(
                                "adminViewInvoiceListStartDate",
                                value,
                            );
                            setStartDate(value);
                        }}
                    />
                </div>
                <div className="input">
                    <div className="title">Till</div>
                    <Input
                        ref={endDateRef}
                        type="date"
                        value={endDate}
                        onChange={(value) => {
                            sessionStorage.removeItem(
                                "adminViewInvoiceListDateInterval",
                            );
                            sessionStorage.setItem(
                                "adminViewInvoiceListEndDate",
                                value,
                            );
                            setEndDate(value);
                        }}
                    />
                </div>
                <div className="input">
                    <div className="title">Byt intervall</div>
                    <Input
                        ref={dateIntervalRef}
                        type="dropdown"
                        value={dateInterval}
                        onChange={setDateInterval}
                        options={[
                            {
                                title: "Välj",
                                value: "manual",
                            },
                            {
                                title: "Dagens",
                                value: "today",
                            },
                            {
                                title: "Gårdagens",
                                value: "yesterday",
                            },
                            {
                                title: "Veckans",
                                value: "week",
                            },
                            {
                                title: "Föregående vecka",
                                value: "lastWeek",
                            },
                            {
                                title: "Visa alla fakturor",
                                value: "all",
                            },
                        ]}
                    />
                </div>
                <div className="pagination">
                    <div
                        className={`previous ${
                            offset === 0 ? "is-disabled" : ""
                        }`}
                        onClick={previousPage}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path fill="currentColor" d="M6 0l12 12-12 12z" />
                        </svg>
                    </div>
                    <div className="showing" onClick={toggleLimit}>
                        Visar {invoiceCount ? offset + 1 : 0} -{" "}
                        {invoiceCount < offset + limit
                            ? invoiceCount
                            : offset + limit}{" "}
                        av {invoiceCount}
                    </div>
                    <div
                        className={`next ${
                            offset + limit > invoiceCount ? "is-disabled" : ""
                        }`}
                        onClick={nextPage}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path fill="currentColor" d="M6 0l12 12-12 12z" />
                        </svg>
                    </div>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Kund</th>
                        <th>Skapades</th>
                        <th>Antal dokument</th>
                        <th>Mail skickades</th>
                        <th>Värde</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {loadingInvoiceList && (
                        <tr>
                            <td colSpan="100">
                                <Loader />
                            </td>
                        </tr>
                    )}
                    {invoices.map((invoice) => {
                        return (
                            <tr
                                key={invoice.id}
                                className={`invoice ${
                                    invoice.emailDate ? " is-done" : ""
                                } ${
                                    deletingInvoiceList.indexOf(invoice.id) >= 0
                                        ? "is-deleting"
                                        : ""
                                }`}
                            >
                                <td>
                                    <Link to={`/invoices/${invoice.id}`}>
                                        {invoice.externalId}
                                    </Link>
                                </td>
                                <td>
                                    <Link
                                        to={`/admin/customers/${invoice.customer.id}`}
                                    >
                                        {invoice.customer.name}
                                    </Link>
                                </td>
                                <td
                                    title={moment(invoice.created).format(
                                        "YYYY-MM-DD HH:mm:ss",
                                    )}
                                >
                                    {moment(invoice.created).calendar()}
                                </td>
                                <td>{invoice.docCount}</td>
                                {invoice.emailDate && (
                                    <td
                                        title={moment(invoice.emailDate).format(
                                            "YYYY-MM-DD HH:mm:ss",
                                        )}
                                    >
                                        {moment(invoice.emailDate).calendar()}
                                    </td>
                                )}
                                {!invoice.emailDate && invoice.sendingEmail && (
                                    <td>
                                        <button
                                            className="c-button c-button--raised"
                                            disabled
                                        >
                                            Skicka faktura
                                        </button>
                                    </td>
                                )}
                                {!invoice.emailDate &&
                                    !invoice.sendingEmail && (
                                        <td>
                                            <button
                                                className="c-button c-button--raised"
                                                onClick={() =>
                                                    emailInvoice(invoice)
                                                }
                                            >
                                                Skicka faktura
                                            </button>
                                        </td>
                                    )}
                                <td>{invoice.net} SEK</td>
                                <td>
                                    <div
                                        className="delete"
                                        onClick={() =>
                                            deleteInvoice({
                                                invoiceId: invoice.id,
                                            })
                                        }
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
                                            />
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Container>
    );
}

export default View;
