import Input from "components/OldInput";
import axios from "axios";
import Loader from "components/Loader/Loader";
import { isMemberOfEU } from "constants/countryList";
import Context from "context/Global";
import { priceParser } from "helpers/StringParser";
import moment from "moment-timezone";
import React, { useContext, useEffect, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import Confirmation from "../../Confirmation";
import { useNotificationSettings } from "../hooks/useNotificationSettings";

let Title = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.6);
`;

let Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 8px;
    min-height: 60px;
    background: #fff;
    padding: 1rem;

    .subtitle {
        font-size: 1rem;
        font-weight: bold;
    }

    .textarea {
        width: 50%;
    }

    &.insurance {
        display: flex;
        flex-direction: column;

        > .toggle {
            display: flex;
            align-items: center;
            height: 38px;
            width: 100%;

            > .label {
                padding-left: 1rem;
            }

            > .note {
                padding-left: 1rem;
                font-size: 0.9em;
            }
        }

        > .information {
            display: flex;
            align-items: center;
            margin-top: 1rem;

            > .label {
                padding-left: 1rem;
            }

            > .note {
                padding-left: 1rem;
                font-size: 0.9em;
            }
        }

        > .reminder {
            display: flex;
            align-items: center;
            margin-top: 1rem;
            font-style: italic;
        }

        > .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 1rem;

            > .packageRows {
                display: grid;
                grid: 1fr / repeat(3, 1fr);
                grid-gap: 1rem;
                width: 100%;
            }

            > .label {
                font-weight: bold;
            }

            > .price {
                margin-top: 1rem;

                > b {
                    > .Loader {
                        height: 1rem;
                        width: 1rem;
                        display: inline-block;
                        align-self: center;

                        > svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    &.pickup {
        display: flex;
        flex-direction: column;

        .datetime {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 0 1rem;
            width: 50%;
            padding-bottom: 1rem;

            > label {
                font-weight: bold;
            }
        }

        .timeMessage {
            padding-bottom: 1rem;
            font-size: 0.9rem;
            color: red;
            font-weight: bold;
        }

        .localtime {
            padding: 1rem 0;
            font-size: 0.9rem;
        }
    }

    &.confirm {
        > .summary {
            font-size: 1.2rem;
            margin-bottom: 1rem;
            font-style: italic;
        }
    }

    > .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;

        > .verification {
            display: flex;
            align-items: center;
            width: 100%;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding-top: 16px;
            margin-top: 32px;

            > .service {
                max-height: 40px;
                max-width: 100px;
            }
            > .price {
                font-size: 24px;
                padding-left: 32px;
                font-weight: bold;

                > .loader {
                    height: 20px;
                    width: 20px;
                }

                > .transport {
                    .unit {
                        font-size: 0.6em;
                    }
                }

                > .insurance {
                    font-size: 0.6em;

                    .unit {
                        font-size: 0.6em;
                    }
                }
            }

            > .u-push-left {
                margin-left: auto;
            }
        }

        > .intervalMessage {
            font-size: 0.8em;
            padding: 1em 0;
            color: rgba(250, 140, 0, 1);
            font-weight: bold;
        }

        .pickupDate {
            display: flex;
            align-items: center;

            .suggestion {
                padding-left: 1em;
                color: rgba(220, 140, 0, 1);
            }
        }

        > .message {
            > .text {
                font-weight: bold;
                font-size: 14px;
                color: #900;
            }

            > .details {
                font-size: 12px;
                color: #900;
            }
        }
    }
`;

const Toggle = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const PickupContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`;

function Component({ bookOrder, receiverPays }) {
    const context = useContext(Context);
    const [price, setPrice] = useState(null);
    const [includePickup, setIncludePickup] = useState(true);
    const pickupDateRef = useRef();
    const [pickupDate, setPickupDate] = useState(null);
    const [invalidDate, setInvalidDate] = useState(false);
    const [loadingNewQuote, setLoadingNewQuote] = useState(false);
    const [checkLocalTime, setCheckLocalTime] = useState(null);
    const [delivery, setDelivery] = useState(null);
    const [message, setMessage] = useState(null);
    const [timeMessage, setTimeMessage] = useState(null);
    const [availablePickupDates, setAvailablePickupDates] = useState([]);
    const [desiredPickupStartTime, setDesiredPickupStartTime] = useState(null);
    const [desiredPickupEndTime, setDesiredPickupEndTime] = useState(null);
    const [pickupInstructions, setPickupInstructions] = useState("");
    const [deliveryInstructions, setDeliveryInstructions] = useState("");
    const [availablePickupStartTimes, setAvailablePickupStartTimes] = useState(
        [],
    );
    const [availablePickupEndTimes, setAvailablePickupEndTimes] = useState([]);
    const desiredPickupStartTimeRef = useRef();
    const desiredPickupEndTimeRef = useRef();
    const pickupInstructionsRef = useRef();
    const deliveryInstructionsRef = useRef();
    const [intervalMessage] = useState(null);
    const cancelSearchTokenRef = useRef();
    const [addInsurance, setAddInsurance] = useState(false);
    const [insuranceValue, setInsuranceValue] = useState(
        (context.order.customs?.information?.currency ||
            context.order.information?.currency) === "SEK"
            ? context.order.customs?.value?.totalValue ||
                  context.order.information?.customsValue
            : 0,
    );
    const [insuranceTermsAccepted, setInsuranceTermsAccepted] = useState(false);
    const insuranceInputRef = useRef();

    const { notificationSettings, setNotificationSettings } =
        useNotificationSettings(context.order, ["onTender"]);

    function getTimeList(start, end) {
        let [startHour, startMinute] = start.split(":");
        let [endHour, endMinute] = end.split(":");
        let availableTimes = [];
        for (let i = parseInt(startHour); i <= parseInt(endHour); i++) {
            if (i === parseInt(startHour)) {
                if (parseInt(startMinute) == 0) {
                    availableTimes.push({
                        title: ("0" + i).slice(-2) + ":00",
                    });
                }
                if (parseInt(startMinute) <= 15) {
                    availableTimes.push({
                        title: ("0" + i).slice(-2) + ":15",
                    });
                }
                if (parseInt(startMinute) <= 30) {
                    availableTimes.push({
                        title: ("0" + i).slice(-2) + ":30",
                    });
                }
                if (parseInt(startMinute) <= 45) {
                    availableTimes.push({
                        title: ("0" + i).slice(-2) + ":45",
                    });
                }
            } else if (i === parseInt(endHour)) {
                if (parseInt(endMinute) === 0) {
                    availableTimes.push({
                        title: ("0" + i).slice(-2) + ":00",
                    });
                }
                if (parseInt(endMinute) >= 15) {
                    availableTimes.push({
                        title: ("0" + i).slice(-2) + ":15",
                    });
                }
                if (parseInt(endMinute) >= 30) {
                    availableTimes.push({
                        title: ("0" + i).slice(-2) + ":30",
                    });
                }
                if (parseInt(endMinute) >= 45) {
                    availableTimes.push({
                        title: ("0" + i).slice(-2) + ":45",
                    });
                }
            } else {
                availableTimes.push({
                    title: ("0" + i).slice(-2) + ":00",
                });
                availableTimes.push({
                    title: ("0" + i).slice(-2) + ":15",
                });
                availableTimes.push({
                    title: ("0" + i).slice(-2) + ":30",
                });
                availableTimes.push({
                    title: ("0" + i).slice(-2) + ":45",
                });
            }
        }
        return availableTimes;
    }

    useEffect(() => {
        setAvailablePickupDates(getAvailablePickupDates());
        setPickupDate(context.quotation.pickupDate);
    }, []);

    function getAvailablePickupDates() {
        return [0, 1, 2, 3, 4, 5, 6, 7]
            .map((i) => {
                let day = moment().add(i, "days");
                if ([6, 7].indexOf(day.isoWeekday()) < 0) {
                    return {
                        title: day.format("YYYY-MM-DD"),
                        value: day.format("YYYY-MM-DD"),
                    };
                }
                return null;
            })
            .filter((pickup) => {
                return !!pickup;
            });
    }

    useEffect(() => {
        if (pickupDate) {
            loadDate();
        }
    }, [pickupDate, insuranceValue, addInsurance]);

    async function loadDate() {
        if (
            insuranceInputRef.current &&
            !insuranceInputRef.current.validate()
        ) {
            return;
        }

        setPrice(null);
        setLoadingNewQuote(true);
        let query = context.getApiOrderObject();
        query.service = context.quotation.service;
        query.pickupDate = pickupDate;

        if (addInsurance) {
            query.insurance = {
                value: insuranceValue || 0,
                currency:
                    context.order.customs?.information?.currency ||
                    context.order.information?.currency ||
                    "SEK",
            };
        }

        setPickupDate(query.pickupDate);
        pickupDateRef.current.set(query.pickupDate);

        if (cancelSearchTokenRef.current) {
            cancelSearchTokenRef.current.cancel();
        }
        cancelSearchTokenRef.current = axios.CancelToken.source();
        const response = await Api.quoteOrder({
            cancelToken: cancelSearchTokenRef.current?.token,
            quote: query,
        });

        if (response.data.code === 500) {
            setMessage({
                title: "Meddelande från leverantören",
                text: response.data.error,
            });
        } else {
            context.setQuotation({
                ...response.data,
                receiverPays: receiverPays,
            });
            if (pickupDate !== response.data.pickupDate) {
                setInvalidDate(true);
            } else {
                let startTime = moment(response.data.pickupTime.start).format(
                    "HH:mm",
                );
                let endTime = moment(response.data.pickupTime.end).format(
                    "HH:mm",
                );

                if (desiredPickupStartTimeRef.current) {
                    if (desiredPickupStartTime) {
                        if (desiredPickupStartTime < startTime) {
                            desiredPickupStartTimeRef.current.set(startTime);
                            setDesiredPickupStartTime(startTime);
                        }
                    } else {
                        desiredPickupStartTimeRef.current.set(startTime);
                        setDesiredPickupStartTime(startTime);
                    }
                }
                if (desiredPickupEndTimeRef.current) {
                    if (desiredPickupEndTime) {
                        if (desiredPickupEndTime > endTime) {
                            desiredPickupEndTimeRef.current.set(endTime);
                            setDesiredPickupEndTime(endTime);
                        }
                    } else {
                        desiredPickupEndTimeRef.current.set(endTime);
                        setDesiredPickupEndTime(endTime);
                    }
                }

                if (response.data.pickupTimezone) {
                    setAvailablePickupStartTimes(
                        getGoodTimes({
                            pickupDate: pickupDate,
                            availableTimes: getTimeList(startTime, endTime),
                            timezone: response.data.pickupTimezone,
                        }),
                    );
                    setAvailablePickupEndTimes(
                        getGoodTimes({
                            pickupDate: pickupDate,
                            availableTimes: getTimeList(startTime, endTime),
                            timezone: response.data.pickupTimezone,
                        }),
                    );
                } else {
                    setCheckLocalTime(true);
                    setAvailablePickupStartTimes(
                        getTimeList(startTime, endTime),
                    );
                    setAvailablePickupEndTimes(getTimeList(startTime, endTime));
                }

                if (!receiverPays) {
                    setPrice(response.data.price);
                }
                setDelivery(response.data.deliveryDate);
                setInvalidDate(false);
            }
            setLoadingNewQuote(false);
        }
    }

    useEffect(() => {
        if (
            availablePickupStartTimes.length &&
            availablePickupEndTimes.length
        ) {
            setDesiredPickupStartTime(availablePickupStartTimes[0].title);
            setDesiredPickupEndTime(
                availablePickupEndTimes[availablePickupEndTimes.length - 1]
                    .title,
            );
        }
    }, [availablePickupStartTimes, availablePickupEndTimes]);

    useEffect(() => {
        if (desiredPickupStartTime && desiredPickupEndTime) {
            const diff = moment()
                .set({
                    hour: desiredPickupEndTime.split(":")[0],
                    minute: desiredPickupEndTime.split(":")[1],
                })
                .diff(
                    moment().set({
                        hour: desiredPickupStartTime.split(":")[0],
                        minute: desiredPickupStartTime.split(":")[1],
                    }),
                );
            if (diff < 60 * 60 * 1000) {
                setTimeMessage(
                    "Intervallet för upphämtningen måste vara på minst 1 timme.",
                );
            } else {
                setTimeMessage(null);
            }
        }
    }, [desiredPickupStartTime, desiredPickupEndTime]);

    function getGoodTimes({ pickupDate, availableTimes, timezone }) {
        let goodTimes = availableTimes
            .map((localTime) => {
                return {
                    time: localTime.title,
                    timestamp: moment().format(
                        `${pickupDate}T${localTime.title.substr(
                            0,
                            2,
                        )}:${localTime.title.substr(3, 2)}:ss${timezone}`,
                    ),
                };
            })
            .filter((data) => {
                if (
                    moment(data.timestamp).diff(moment().add(10, "minute")) < 0
                ) {
                    return false;
                }
                return true;
            })
            .map((data) => {
                return {
                    title: data.time,
                };
            });

        return goodTimes;
    }

    function valid() {
        let valid = true;
        if (
            pickupInstructionsRef.current &&
            !pickupInstructionsRef.current.validate()
        ) {
            valid = false;
        }
        if (
            deliveryInstructionsRef.current &&
            !deliveryInstructionsRef.current.validate()
        ) {
            valid = false;
        }
        if (intervalMessage) {
            valid = false;
        }
        if (timeMessage) {
            valid = false;
        }
        if (
            insuranceInputRef.current &&
            !insuranceInputRef.current.validate()
        ) {
            valid = false;
        }
        if (addInsurance) {
            if (!insuranceTermsAccepted) {
                valid = false;
            }
            if (context.order.customs?.value?.totalValue) {
                if (context.order.customs?.value?.totalValue < insuranceValue) {
                    valid = false;
                }
            }
        }

        return valid;
    }

    function getMessage() {
        if (invalidDate && !loadingNewQuote) {
            return "Upphämtningen kan inte ske denna dag. Försök med ett annat datum.";
        }
    }

    function showMaxInsuranceValueReminder(order) {
        if (order.customs?.value?.totalValue) {
            return true;
        }
        return false;
    }

    function showInsuranceValueReminder(order) {
        if (showMaxInsuranceValueReminder(order)) {
            return false;
        }
        if (
            !isMemberOfEU(order.sender.countryCode) ||
            !isMemberOfEU(order.receiver.countryCode)
        ) {
            return true;
        }
        return false;
    }

    return (
        <>
            <Title>Upphämtning</Title>
            <Card className="pickup">
                <Toggle>
                    <label>Boka upphämtning</label>
                    <Input
                        type="checkbox"
                        value={includePickup}
                        onChange={setIncludePickup}
                    />
                </Toggle>

                {includePickup && (
                    <PickupContent>
                        {" "}
                        <div className="datetime">
                            <label>Datum</label>
                            <label>Från</label>
                            <label>Till</label>
                            <Input
                                ref={pickupDateRef}
                                onChange={setPickupDate}
                                type="dropdown"
                                options={availablePickupDates}
                                value={pickupDate}
                            />
                            {availablePickupStartTimes && !invalidDate && (
                                <Input
                                    onChange={setDesiredPickupStartTime}
                                    type="dropdown"
                                    value={desiredPickupStartTime}
                                    options={availablePickupStartTimes}
                                    ref={desiredPickupStartTimeRef}
                                />
                            )}
                            {availablePickupEndTimes && !invalidDate && (
                                <Input
                                    onChange={setDesiredPickupEndTime}
                                    type="dropdown"
                                    value={desiredPickupEndTime}
                                    options={availablePickupEndTimes}
                                    ref={desiredPickupEndTimeRef}
                                />
                            )}
                        </div>
                        {timeMessage && (
                            <div className="timeMessage">{timeMessage}</div>
                        )}
                        {checkLocalTime && (
                            <div className="localtime">
                                OBS! Tiderna anges i lokal tid.
                            </div>
                        )}
                        <div className="subtitle">
                            Upphämtningsinstruktioner
                        </div>
                        <Input
                            className="textarea"
                            ref={pickupInstructionsRef}
                            onChange={setPickupInstructions}
                            value={pickupInstructions}
                            type="textarea"
                            maxLength={24}
                        />
                        <div className="subtitle">Leveransinstruktioner</div>
                        <Input
                            className="textarea"
                            ref={deliveryInstructionsRef}
                            onChange={setDeliveryInstructions}
                            value={deliveryInstructions}
                            type="textarea"
                            maxLength={60}
                        />
                    </PickupContent>
                )}
            </Card>
            <Title>Försäkring</Title>
            <Card className="insurance">
                <div className="toggle">
                    <Input
                        type="checkbox"
                        value={addInsurance}
                        onChange={setAddInsurance}
                    />
                    <div className="label">Jag vill försäkra min transport</div>
                </div>
                {addInsurance && (
                    <>
                        <div className="content">
                            <div className="label">Försäkringsvärde</div>
                            <Input
                                ref={insuranceInputRef}
                                type="number"
                                placeholder="0"
                                onChange={setInsuranceValue}
                                min={0}
                                max={3000000}
                                value={insuranceValue}
                                unit={
                                    context.order.customs?.information
                                        ?.currency ||
                                    context.order.information?.currency ||
                                    "SEK"
                                }
                            />
                            {!receiverPays && (
                                <div className="price">
                                    Med ett försäkringsvärde på{" "}
                                    {priceParser(insuranceValue || 0)}{" "}
                                    {context.order.customs?.information
                                        ?.currency ||
                                        context.order.information?.currency ||
                                        "SEK"}{" "}
                                    kostar försäkringen{" "}
                                    <b>
                                        {price?.insuranceCost !== undefined ? (
                                            price?.insuranceCost
                                        ) : (
                                            <Loader />
                                        )}{" "}
                                        SEK
                                    </b>
                                    .
                                </div>
                            )}
                        </div>
                        {showMaxInsuranceValueReminder(context.order) && (
                            <div className="reminder">
                                Ni har angett ett tullvärde på totalt{" "}
                                {priceParser(
                                    context.order.customs?.value?.totalValue,
                                )}{" "}
                                {context.order.customs?.information?.currency ||
                                    context.order.information?.currency ||
                                    "SEK"}{" "}
                                på tullfakturan vilket försäkringsvärdet ovan
                                inte för överstiga.
                            </div>
                        )}
                        {showInsuranceValueReminder(context.order) && (
                            <div className="reminder">
                                Observera att försäkringsvärdet inte får
                                överstiga det värde som skrivs på tullfakturan
                            </div>
                        )}
                        <div className="information">
                            <Input
                                type="checkbox"
                                value={insuranceTermsAccepted}
                                onChange={setInsuranceTermsAccepted}
                            />
                            <div className="label">
                                Jag förstår och accepterar{" "}
                                <a
                                    target="_blank"
                                    href="https://www.tnt.com/express/sv_se/site/shipping-services/insurance.html"
                                >
                                    TNT:s villkor vid försäkring
                                </a>{" "}
                                samt att försäkringsvärdet inte kan överstiga
                                tull-värdet.
                            </div>
                        </div>
                    </>
                )}
            </Card>
            <Title>Bekräfta & beställ</Title>
            <Card className="confirm">
                <div className="summary">
                    Försändelsen hämtas {pickupDate}
                    {delivery && (
                        <>
                            {" "}
                            och estimerad leverans är{" "}
                            {moment(delivery).format("YYYY-MM-DD")}.
                        </>
                    )}
                </div>
                <Title>Hej</Title>
                <Card>
                    <div className="description">
                        Välj vid vilka tillfällen och till vilka e-postadresser
                        ni vill att notiser ska skickas.
                    </div>
                    <div className="notifications">
                        {notificationSettings && (
                            <Input
                                type="table"
                                onChange={setNotificationSettings}
                                value={notificationSettings}
                                object={{
                                    name: {
                                        type: "text",
                                        title: "Namn",
                                    },
                                    email: {
                                        type: "text",
                                        title: "Email",
                                    },
                                    onTender: {
                                        type: "checkbox",
                                        title: "Bokningsbekräftelse",
                                    },
                                }}
                            />
                        )}
                    </div>
                </Card>
                <Confirmation
                    message={getMessage()}
                    price={price}
                    disabled={!valid()}
                    service={context.quotation.service}
                    receiverPays={receiverPays}
                    bookOrder={() => {
                        let insurance = null;
                        if (addInsurance) {
                            insurance = {
                                value: parseInt(insuranceValue),
                                currency:
                                    context.order.customs?.information
                                        ?.currency ||
                                    context.order.information?.currency ||
                                    "SEK",
                            };
                        }

                        bookOrder({
                            insurance: insurance,
                            receiverPays: receiverPays || undefined,
                            pickup: includePickup
                                ? {
                                      date: `${moment(pickupDate).format(
                                          "YYYY-MM-DD",
                                      )}`,
                                      timeInterval: {
                                          start: desiredPickupStartTime,
                                          end: desiredPickupEndTime,
                                      },

                                      instructions: pickupInstructions,
                                  }
                                : null,
                            notifications: notificationSettings,
                            delivery: {
                                date: moment(delivery).format("YYYY-MM-DD"),
                                instructions: deliveryInstructions,
                            },
                        });
                    }}
                />
                {message && (
                    <div className="message">
                        <div className="text">{message.title}</div>
                        <div className="details">{message.text}</div>
                    </div>
                )}
            </Card>
        </>
    );
}

export default Component;
