import React, { useContext, useEffect } from "react";
import ReceiverPaysRevenueTable from "./ReceiverPaysRevenueTable";
import Context from "context/Global";
import ReceiverPaysInvoiceTable from "./ReceiverPaysInvoiceTable";
import { useHistory } from "react-router-dom";
import ReceiverPaysCustomerTable from "./ReceiverPaysCustomerTable";

function ReceiverPaysView(props) {
    const context = useContext(Context);
    const history = useHistory();
    useEffect(() => {
        if (!context.user.administrator && !context.user.isAdmin) {
            history.replace("/admin");
        }
    }, []);
    return (
        <div className="p-4">
            <h2 className="text-3xl my-4">Omsättning</h2>
            <p>
                Visar fakturering för mottagarfrakt per fakturadatum under en
                period. Fraktsedlarna som inkluderas är alla inom fakturerade
                perioder. Om fakturadatum är med i intervall inkluderas all
                fraktsedlar en månad bakåt.
            </p>
            <ReceiverPaysRevenueTable />
            <h2 className="text-3xl my-4">Fakturering</h2>
            <p>
                Visar fakturadatum och pris per kund för mottagarfrakt under
                perioden. Max längd på interval är 14 dagar
            </p>
            <ReceiverPaysInvoiceTable />
            <h2 className="text-3xl my-4">Alla kunder</h2>
            <p>Visar alla kunder som har eller har haft mottagarfrakt.</p>
            <ReceiverPaysCustomerTable />
        </div>
    );
}

export default ReceiverPaysView;
