import { PrettyState } from "constants/orderState";
import Context from "context/Global";
import { getTransporterLogo } from "helpers/Logos";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    > table.orders {
        width: 100%;
        margin-bottom: 16px;
        flex-direction: column;

        > thead {
            > tr {
                > th {
                    height: 40px;
                    background: #fafafa;
                    padding: 1vh 0;
                    border-bottom: 1px solid #ddd;
                    border-top: 1px solid #ddd;
                    color: #333;
                    font-weight: normal;

                    &:first-child {
                        padding-left: 1vw;
                    }
                    &:last-child {
                        padding-right: 1vw;
                    }
                }
            }
        }

        > tbody {
            > tr {
                > td {
                    height: 80px;
                    background: #fff;
                    padding: 1vh 0;

                    > .emptyState {
                        font-style: italic;
                        color: #555;
                    }

                    &:first-child {
                        padding-left: 1vw;
                    }
                    &:last-child {
                        padding-right: 1vw;
                    }

                    &.service {
                        > img {
                            width: 32px;
                            object-size: contain;
                        }
                    }
                }
            }
        }
    }
`;

let Order = styled.tr`
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    text-decoration: none;
    color: #000;

    flex-shrink: 0;

    &:last-child {
        border-bottom: 0;
    }

    .trackingNumber {
        display: flex;
        flex-direction: row;
        align-items: center;

        > img {
            width: 60px;
            margin-right: 8px;
        }
    }

    > .timestamp {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.8);
    }

    > .notifications {
        > .notification {
            position: relative;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;

            > .text {
                opacity: 0;
                pointer-events: none;
                position: absolute;
                transition: all 0.3s ease;
                background: #fff;
                white-space: nowrap;
                display: flex;
                justify-content: center;
                align-items: center;
                left: calc(100% + 8px);
                box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
                padding: 4px 12px;
                border-radius: 4px;
                z-index: 100;
            }

            &:hover {
                .text {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            > .icon {
                border-radius: 100%;
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 48px;
                height: 48px;
                color: #fff;

                > svg {
                    width: 100%;
                    height: 100%;
                }
            }

            &.issue {
                > .icon {
                    background: #e57c1b;
                }
            }

            &.information {
                > .icon {
                    background: #72c3ed;
                }
            }

            &.noPickup {
                > .icon {
                    > svg {
                        margin-left: 2px;
                        margin-top: 4px;
                    }
                }
            }

            &.notPickedUp {
                > .icon {
                    > svg {
                        margin-left: 2px;
                        margin-top: 4px;
                    }
                }
            }
            &.exception {
                > .icon {
                    > svg {
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }

    > .type {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 500;
    }

    > .state {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 500;
    }

    > .pickup {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 500;

        > .wrapper {
            display: flex;
            align-items: center;

            > .pickup-required {
                padding: 2px 4px;
                min-height: 20px;
                font-size: 10px;
            }
        }
    }

    &.is-delivered {
        > .state {
            color: var(--color-good);
        }
    }

    &.is-warning {
        > .state {
            color: var(--color-bad);
        }
    }

    &.is-rejected {
        > .state {
            color: var(--color-bad);
        }
    }

    &.is-intransit {
        > .state {
            color: rgba(0, 80, 180, 1);
        }
    }

    &.is-rejected,
    &.is-new {
        > .price {
            text-decoration: line-through;
        }
    }

    > .title {
        font-size: 16px;
    }

    > .price {
        font-size: 16px;
        text-align: right;
    }

    &:hover {
        background: rgba(0, 0, 0, 0.02);
    }
`;

function View(props) {
    const context = useContext(Context);
    const [orders, setOrders] = useState(null);

    useEffect(() => {
        if (
            !(
                context.user.administrator ||
                (context.user.brand && context.user.brand.id)
            )
        ) {
            props.history.replace("/admin");
        } else {
            Api.adminOverdueOrders()
                .then(setOrders)
                .catch(() => {
                    // Cancelled
                });
        }
    }, []);

    return (
        <Container>
            {orders && (
                <table className="orders">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Referens</th>
                            <th>Fraktsedelnummer</th>
                            {context.user.administrator && <th>Brand</th>}
                            <th>Mottagare</th>
                            <th>Bokad</th>
                            <th>Status</th>
                            <th>Pris</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.length === 0 && (
                            <tr>
                                <td colSpan="100%">
                                    <div className="emptyState">
                                        Hittade inte några försändelser som
                                        matchade din sökning.
                                    </div>
                                </td>
                            </tr>
                        )}
                        {orders.map((order) => {
                            let exception = null;
                            let pickupInPast = false;
                            let noPickup = false;
                            let orderBookingFailed = false;
                            if (!order.trackingNumber) {
                                orderBookingFailed = true;
                            } else if (order.hasError) {
                                exception = order.messages.find((message) => {
                                    return message.type === "error";
                                });
                            } else if (
                                order.state === "APPROVED" &&
                                order.confirmationNumber &&
                                moment().diff(moment(order.pickupDate)) > 0
                            ) {
                                pickupInPast = true;
                            } else if (
                                order.state === "APPROVED" &&
                                !order.confirmationNumber
                            ) {
                                noPickup = true;
                            }

                            return (
                                <Order
                                    key={order.id}
                                    className={`is-${order.state
                                        .toLowerCase()
                                        .replace(" ", "")}`}
                                    href={`/orders/${order.id}`}
                                    onClick={() => {
                                        props.history.push(
                                            `/orders/${order.id}`,
                                        );
                                    }}
                                >
                                    <td className="notifications">
                                        {exception && (
                                            <div className="notification issue exception">
                                                <div className="text">
                                                    {getMessageText(exception)}
                                                </div>
                                                <div className="icon">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        )}
                                        {pickupInPast && (
                                            <div className="notification information notPickedUp">
                                                <div className="text">
                                                    Upphämtningen har inte
                                                    registrerats ännu.
                                                </div>
                                                <div className="icon">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M2 11.741c-1.221-1.009-2-2.535-2-4.241 0-3.036 2.464-5.5 5.5-5.5 1.706 0 3.232.779 4.241 2h4.259c.552 0 1 .448 1 1v2h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035v4.48c0 1.156-.616 2-2 2h-1c0 1.656-1.344 3-3 3s-3-1.344-3-3h-4c0 1.656-1.344 3-3 3s-3-1.344-3-3h-2c-.552 0-1-.448-1-1v-6.259zm6 6.059c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm10 0c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm-7.207-11.8c.135.477.207.98.207 1.5 0 3.036-2.464 5.5-5.5 5.5-.52 0-1.023-.072-1.5-.207v4.207h1.765c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h5.53c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h1.765v-4.575l-1.711-2.929c-.179-.307-.508-.496-.863-.496h-4.426v6h-2v-9h-2.207zm5.207 4v3h5l-1.427-2.496c-.178-.312-.509-.504-.868-.504h-2.705zm-10.5-6c1.932 0 3.5 1.568 3.5 3.5s-1.568 3.5-3.5 3.5-3.5-1.568-3.5-3.5 1.568-3.5 3.5-3.5zm.5 3h2v1h-3v-3h1v2z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        )}
                                        {noPickup && (
                                            <div className="notification issue noPickup">
                                                <div className="text">
                                                    Ingen upphämtning bokad!
                                                </div>
                                                <div className="icon">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M2 11.741c-1.221-1.009-2-2.535-2-4.241 0-3.036 2.464-5.5 5.5-5.5 1.706 0 3.232.779 4.241 2h4.259c.552 0 1 .448 1 1v2h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035v4.48c0 1.156-.616 2-2 2h-1c0 1.656-1.344 3-3 3s-3-1.344-3-3h-4c0 1.656-1.344 3-3 3s-3-1.344-3-3h-2c-.552 0-1-.448-1-1v-6.259zm6 6.059c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm10 0c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm-7.207-11.8c.135.477.207.98.207 1.5 0 3.036-2.464 5.5-5.5 5.5-.52 0-1.023-.072-1.5-.207v4.207h1.765c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h5.53c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h1.765v-4.575l-1.711-2.929c-.179-.307-.508-.496-.863-.496h-4.426v6h-2v-9h-2.207zm5.207 4v3h5l-1.427-2.496c-.178-.312-.509-.504-.868-.504h-2.705zm-10.5-6c1.932 0 3.5 1.568 3.5 3.5s-1.568 3.5-3.5 3.5-3.5-1.568-3.5-3.5 1.568-3.5 3.5-3.5zm.5 3h2v1h-3v-3h1v2z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        )}
                                    </td>
                                    <td className="type">
                                        {order.information.reference}
                                    </td>
                                    <td>
                                        <div className="trackingNumber">
                                            <img
                                                src={`/images/icons/${getTransporterLogo(
                                                    order.service.name,
                                                )}`}
                                            />
                                            {order.trackingNumber}
                                        </div>
                                    </td>
                                    {context.user.administrator && (
                                        <td>{order.brand.name}</td>
                                    )}
                                    <td>
                                        <div className="receiver">
                                            <div className="title">
                                                {order.route.to.contact.private
                                                    ? order.route.to.contact
                                                          .name
                                                    : order.route.to.contact
                                                          .company}
                                            </div>
                                            <div className="location">
                                                {order.route.to.postalTown},{" "}
                                                {order.route.to.countryCode}
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        className="timestamp"
                                        title={moment(order.created).format(
                                            "YYYY-MM-DD HH:mm:ss",
                                        )}
                                    >
                                        {moment(order.created).calendar()}
                                    </td>
                                    <td className="state">
                                        {renderOrderState(order)}
                                    </td>
                                    <td className="price">
                                        {order.price.amount
                                            ?.toString()
                                            .replace(
                                                /(\d)(?=(\d\d\d)+(?!\d))/g,
                                                "$1 ",
                                            )}{" "}
                                        {order.price.unit || ""}
                                    </td>
                                </Order>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </Container>
    );
}

function renderOrderState(order) {
    if (order.state === "DELIVERED") {
        return `Levererad ${moment(order.deliveryDate).format("YYYY-MM-DD")}`;
    } else if (
        order.state === "APPROVED" &&
        order.confirmationNumber &&
        order.pickupDate
    ) {
        return `Bokad för upphämtning ${moment(order.pickupDate).format(
            "YYYY-MM-DD",
        )}`;
    }
    return PrettyState(order.state);
}

function getMessageText(message) {
    switch (message.code) {
        case "DELIVERY_FAILED": {
            return "Leveransen misslyckades. Se detaljer och kontakta kundservice vid behov.";
        }
        case "MISROUTED": {
            return "Försändelsen försenas pga felsortering.";
        }
        case "MISSING_CUSTOMS": {
            return "Saknas eller felaktiga tull-dokument. Var god kontakta kundservice!";
        }
        case "DELIVERY_FAILED": {
            return "Misslyckat leveransförsök. Inväntar nästa utkörningstillfälle.";
        }
        case "INVALID_ADDRESS": {
            return "Felaktig leveransadress. Var god kontakta kundservice!";
        }
        case "CHANGED": {
            return "Ändring av transportuppdrag. Kontakta kundservice för detaljer.";
        }
        case "INCOMPLETE": {
            return "Saknas ellerfelaktiga dokument. Var god kontakta kundservice!";
        }
        case "RECLAIMED": {
            return "Försändelsen är reklamerad. Var god kontakta kundservice!";
        }
        case "REFUSED": {
            return "Mottagaren vägrar ta emot godset. Sändningen returneras till avsändaren.";
        }
        default: {
            return message.text;
        }
    }
}

export default View;
