import Modal from "components/Modal";
import { getTransporterLogo } from "helpers/Logos";
import {
    getProductName,
    getProductNameWithService,
    getTransporterName,
    isValidProduct,
} from "helpers/Services";
import React, { useContext, useEffect, useRef, useState } from "react";
import useBatchStore from "../../store";
import useSaveOrders from "hooks/batch/useSaveOrders";
import Api from "services/Api/Api";
import Context from "context/Global";
import {
    isEligibleForDirectPackageBooking,
    isEligibleForDirectServicePointBooking,
    isObjectEmpty,
    isServicePoint,
    serviceForCustomer,
} from "utils/batchTableUtils";
import { DHLPACKAGE } from "constants/batch/batchConstants";
import Product from "./Product";
import SelectServicePoint from "./SelectServicePoint";

const QuotesModal = ({ setIsOpen, isOpen, order, mode }) => {
    const [sharedQuotations, setSharedQuotations] = useState([]);
    const [customerProducts, setCustomerProducts] = useState([]);
    const [selectedQuotation, setSelectedQuotation] = useState(
        order?.data.selectedQuote || {},
    );

    const showPriceView = mode === "single" && order.data.quotations;

    const context = useContext(Context);
    let modalRef = useRef();

    const { saveOrders } = useSaveOrders();
    const selectedOrders = useBatchStore((state) => state.selectedOrders);

    const singleMode = mode === "single";

    const getCustomerProducts = async () => {
        const customerProducts = await Api.getCustomerProducts({
            customerId: context.user.customer.id,
        });

        setCustomerProducts(customerProducts);
    };

    useEffect(() => {
        getCustomerProducts();
    }, []);

    useEffect(() => {
        const products = [];
        const quotes = [];

        if (singleMode) {
            order.data.quotations?.forEach((quote) => {
                const product = getProductNameWithService(quote);
                if (!products.includes(product) && isValidProduct(quote)) {
                    products.push(product);
                    quotes.push(quote);
                }
            });
            return setSharedQuotations(
                quotes.sort((a, b) => {
                    return a.price?.total - b.price?.total;
                }),
            );
        } else {
            selectedOrders?.every((order) => {
                return (
                    order.data.selectedQuote?.service?.productCode ===
                    selectedOrders[0].data?.selectedQuote?.service?.productCode
                );
            })
                ? setSelectedQuotation(selectedOrders[0].data.selectedQuote)
                : setSelectedQuotation({});

            selectedOrders?.forEach((order) => {
                order.data.quotations?.forEach((quote) => {
                    const product = getProductNameWithService(quote);
                    if (!products.includes(product) && isValidProduct(quote)) {
                        products.push(product);
                        quotes.push(quote);
                    }
                });
            });
            return setSharedQuotations(
                quotes.sort((a, b) => {
                    return a.service?.name.localeCompare(b.service?.name);
                }),
            );
        }
    }, [isOpen, selectedOrders]);

    const onClickSaveSelectedQuote = () => {
        const hasPrice = !isObjectEmpty(selectedQuotation.price);
        if (singleMode) {
            if (
                hasPrice &&
                getProductName(selectedQuotation) ===
                    getProductName(order.data.selectedQuote) &&
                getTransporterName(selectedQuotation) ===
                    getTransporterName(order.data.selectedQuote) &&
                order.data.selectedQuote.servicePoint?.id ===
                    selectedQuotation.servicePoint?.id
            ) {
                return setIsOpen(false);
            }
            saveOrders([
                {
                    ...order,
                    data: {
                        ...order.data,
                        selectedQuote: selectedQuotation,
                    },
                },
            ]);
        } else {
            saveOrders(
                selectedOrders.map((order) => {
                    return {
                        ...order,
                        data: {
                            ...order.data,
                            selectedQuote: hasPrice
                                ? order.data?.quotations?.find(
                                      (quoteFromQuotations) =>
                                          getProductNameWithService(
                                              quoteFromQuotations,
                                          ) ===
                                          getProductNameWithService(
                                              selectedQuotation,
                                          ),
                                  ) ?? order.data.selectedQuote
                                : selectedQuotation,
                        },
                    };
                }),
            );
        }

        return setIsOpen(false);
    };

    const renderProducts = (products) => {
        return products?.map((product, i) => {
            return (
                <Product
                    key={i}
                    setSelectedQuotation={setSelectedQuotation}
                    selectedQuotation={selectedQuotation}
                    product={product}
                    order={order}
                    singleMode={singleMode}
                    showPriceView={showPriceView}
                    modalRef={modalRef}
                />
            );
        });
    };

    const renderServicePointProducts = () => {
        const ServicePointProducts = sharedQuotations?.filter((quote) => {
            return isServicePoint(quote.service);
        });

        return renderProducts(ServicePointProducts);
    };

    const renderReturnProducts = () => {
        const returnProducts = sharedQuotations?.filter((quote) => {
            return quote.service.productCode?.includes("Return");
        });
        return renderProducts(returnProducts);
    };

    const hasServicePointQuote = sharedQuotations.some((quote) => {
        return isServicePoint(quote.service);
    });

    const hasReturnQuote = sharedQuotations.some((quote) => {
        return quote.service.productCode?.includes("Return");
    });

    const isB2BOrder = singleMode
        ? order?.data.receiver.contact.private === false
        : selectedOrders?.every(
              (order) => order?.data.receiver.contact.private === false,
          );

    const renderDirectBookableProducts = () => {
        const setDirectBookingProduct = (isPackage) => {
            setSelectedQuotation(
                serviceForCustomer(
                    isPackage ? DHLPACKAGE : "",
                    customerProducts,
                ),
            );
        };

        return (
            <div>
                {isEligibleForDirectPackageBooking(
                    mode,
                    order,
                    selectedOrders,
                ) && (
                    <div
                        className="py-3"
                        onClick={() => {
                            setDirectBookingProduct(true);
                        }}
                    >
                        <div
                            className={
                                selectedQuotation?.service?.productCode.includes(
                                    "Paket",
                                )
                                    ? "flex items-center gap-3 p-3 bg-lightGreen rounded-md border cursor-pointer border-gray-300 "
                                    : "flex items-center gap-3 p-3 bg-white rounded-md border cursor-pointer border-gray-300 hover:bg-gray-50"
                            }
                        >
                            <img
                                className="w-28 h-10 border-r border-gray-300 pr-3"
                                src={`/images/icons/${getTransporterLogo(
                                    "dhl",
                                )}`}
                            />
                            <div className="flex flex-col">
                                <span className="flex items-center">
                                    <p>Direktbokning DHL Paket</p>
                                </span>
                            </div>
                        </div>
                    </div>
                )}

                {isEligibleForDirectServicePointBooking(
                    mode,
                    order,
                    selectedOrders,
                ) && (
                    <div
                        onClick={() => {
                            setDirectBookingProduct(false);
                        }}
                    >
                        <div
                            className={
                                selectedQuotation?.service?.productCode.includes(
                                    "Service",
                                )
                                    ? "flex items-center gap-3 p-3 bg-lightGreen rounded-md border cursor-pointer border-gray-300 "
                                    : "flex items-center gap-3 p-3 bg-white rounded-md border cursor-pointer border-gray-300 hover:bg-gray-50"
                            }
                        >
                            <img
                                className="w-28 h-10 border-r border-gray-300 pr-3"
                                src={`/images/icons/${getTransporterLogo(
                                    "dhl",
                                )}`}
                            />
                            <div className="flex flex-col">
                                <span className="flex items-center">
                                    <p>Direktbokning DHL Service Point</p>
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                {selectedQuotation?.service?.productCode.includes(
                    "Service",
                ) && (
                    <SelectServicePoint
                        order={order}
                        selectedQuotation={selectedQuotation}
                        setSelectedQuotation={setSelectedQuotation}
                    />
                )}
            </div>
        );
    };

    const renderAllQuotedProducts = () => {
        const getProductsToRender = (sharedQuotations) => {
            if (isB2BOrder) {
                return sharedQuotations.filter((quote) => {
                    return (
                        !isServicePoint(quote.service) &&
                        !quote.service.productCode?.includes("Return")
                    );
                });
            }
            return sharedQuotations;
        };

        const productsToRender = getProductsToRender(sharedQuotations);

        return renderProducts(productsToRender);
    };

    return (
        <Modal
            description={
                singleMode
                    ? "Välj transporttjänst för din försändelse"
                    : "Välj transporttjänster för valda försändelser. \n\nNotera att uppdateringen enbart kommer att ske för de försändelser där vald transporttjänst är möjlig."
            }
            title={"Transporttjänst"}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            maxWidth="max-w-3xl"
            initialFocus={modalRef}
        >
            <div className="max-h-2xl overflow-y-auto flex flex-col gap-3 px-3 pb-3 ">
                {(!sharedQuotations.length &&
                    isEligibleForDirectPackageBooking(
                        mode,
                        order,
                        selectedOrders,
                    )) ||
                (!sharedQuotations.length &&
                    isEligibleForDirectServicePointBooking(
                        mode,
                        order,
                        selectedOrders,
                    ))
                    ? renderDirectBookableProducts()
                    : renderAllQuotedProducts()}
                {hasServicePointQuote && isB2BOrder && (
                    <>
                        <div className="flex p-3 border-b-2 w-max-xl border-gray-300 border-solid" />
                        <div className="flex justify-start gap-3">
                            <span className="font-medium text-lg">
                                Service Point
                            </span>
                        </div>
                        {renderServicePointProducts()}
                    </>
                )}
                {hasReturnQuote && isB2BOrder && (
                    <>
                        <div className="flex p-3 border-b-2 w-max-xl border-gray-300 border-solid" />
                        <div className="flex justify-start gap-3">
                            <span className="font-medium text-lg">Retur</span>
                        </div>
                        {renderReturnProducts()}
                    </>
                )}
            </div>
            <div
                className={
                    showPriceView
                        ? `border rounded-md w-72 mt-3  border-gray-300 border-solid mx-auto`
                        : ``
                }
            >
                {showPriceView && (
                    <div className="flex justify-center py-3 text-lg">
                        <span>
                            <p>
                                Pris: {selectedQuotation.price?.total}{" "}
                                {selectedQuotation.price?.unit}
                            </p>
                        </span>
                    </div>
                )}
                <div className="flex justify-center py-3">
                    <div className="flex space-between">
                        <button
                            className="c-button c-button--raised"
                            onClick={onClickSaveSelectedQuote}
                        >
                            bekräfta
                        </button>
                        <button
                            className="c-button c-button--outline"
                            onClick={() => setIsOpen(false)}
                        >
                            avbryt
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default QuotesModal;
