const Origins = {
    Order: "order",
    ImportedOrder: "imported_order",
};

const Errors = {
    Booking: {
        Failed: "booking_failed",
    },
    Pickup: {
        Failed: "pickup_failed",
    },
    Quote: {
        Failed: "quote_failed",
    },
};

const State = {
    Loading: "loading",
    Error: "error",
    Success: "success",
};

export const BATCH_ORDER = {
    Origins,
    Errors,
    State,
};

export const DHLPACKAGE = "DHL Paket";
