import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

const Modal = ({
    isOpen,
    setIsOpen,
    children,
    title,
    description,
    locked,
    initialFocus,
    maxWidth = "max-w-xl",
}) => {
    const closeModal = () => {
        setIsOpen(false);
    };
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative"
                style={{ zIndex: 1000 }}
                onClose={locked ? () => {} : closeModal}
                initialFocus={initialFocus}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 z-[100] bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 z-[101] overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className={
                                    "w-full transform rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all " +
                                    maxWidth
                                }
                            >
                                {title && (
                                    <Dialog.Title
                                        as="div"
                                        className="mb-8 flex w-full items-center justify-between"
                                    >
                                        <div>
                                            {title && (
                                                <h2 className="font-inter text-2xl font-bold text-black-100">
                                                    {title}
                                                </h2>
                                            )}
                                            {description && (
                                                <span className="font-inter text-secondary-700">
                                                    {description}
                                                </span>
                                            )}
                                        </div>
                                    </Dialog.Title>
                                )}
                                {children}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default Modal;
