import React from "react";
import ProductComponent from "./Product/Product";
import Information from "./Information";
import Packages from "./Packages";
import FreightParty from "./FreightParty";
import Select from "./Select";
import { getStateStyle } from "../../../../utils/batchTableUtils";
import { BATCH_ORDER } from "constants/batch/batchConstants";
import useBatchStore from "views/Batch/store";
import { getIntegrationImage } from "helpers/Logos";
import InformationHover from "components/InformationHover";

const Order = ({ order, selectSingleShipment, openEditNotificationModal }) => {
    const selectedOrders = useBatchStore((state) => state.selectedOrders);

    const isIntegrationOrder =
        order?.origin === BATCH_ORDER.Origins.ImportedOrder;

    const renderOrderSource = (orderSource) => {
        if (isIntegrationOrder) {
            return (
                <img
                    src={`/images/icons/${getIntegrationImage(orderSource)}`}
                    width={orderSource === "CUSTOM" ? "40" : "100"}
                />
            );
        }
        return <div className="flex items-center text">{orderSource}</div>;
    };

    const notificationSettings = order.data.additionalOptions?.find(
        (option) => option.name === "notificationSettings",
    )?.value;
    const hasNotificationSettingsEnabled = notificationSettings?.find(
        (setting) => setting.onTender,
    );

    const handleOpenEditNotificationModal = (e) => {
        e.stopPropagation();
        openEditNotificationModal([order]);
    };

    return (
        <tr
            className={getStateStyle(order.state, order.error)}
            onClick={() => {
                selectSingleShipment(order);
            }}
        >
            <td
                className="py-3 h-32 px-3 flex justify-start items-center"
                onClick={(ev) => {
                    ev.stopPropagation();
                }}
            >
                <Select
                    order={order}
                    disabled={
                        order.state === BATCH_ORDER.State.Loading ||
                        selectedOrders.some(
                            (order) =>
                                order.state === BATCH_ORDER.State.Loading,
                        )
                    }
                />
            </td>
            <td className="py-3 h-32">
                <FreightParty party={order?.data.sender} type="sender" />
            </td>
            <td className="py-3 h-32">
                <FreightParty party={order?.data.receiver} type="receiver" />
            </td>
            <td className="py-3 h-32">
                <Packages
                    packageRows={order?.data.packages}
                    selectedQuote={order?.data.selectedQuote}
                />
            </td>
            <td className="py-3 h-32">
                <Information
                    information={order?.data.information}
                    externalId={order.external_id}
                />
            </td>

            <td
                className="h-32"
                onClick={(ev) => {
                    ev.stopPropagation();
                }}
            >
                <ProductComponent order={order} />
            </td>
            <td style={{ minWidth: 32 }}>
                {hasNotificationSettingsEnabled && (
                    <InformationHover
                        icon="/images/icons/notification-multiple.svg"
                        onClick={handleOpenEditNotificationModal}
                    >
                        <>
                            <p>Bokningsbekräftelse skickas till:</p>
                            {notificationSettings
                                .reduce((all, setting, index) => {
                                    return setting.email && setting.onTender
                                        ? [
                                              ...all,
                                              `${
                                                  index === 0
                                                      ? "Avsändare"
                                                      : "Mottagare"
                                              }: ${setting.email}`,
                                          ]
                                        : all;
                                }, [])
                                .map((email, index) => (
                                    <p
                                        key={`hover-row-${order?.id}-${email}-${index}`}
                                    >
                                        {email}
                                    </p>
                                ))}
                        </>
                    </InformationHover>
                )}
            </td>
            <td>
                <div className="py-3 h-32 m-auto flex items-center justify-center">
                    {renderOrderSource(order?.orderSource)}
                </div>
            </td>
        </tr>
    );
};

export default Order;
