import { incoTerms } from "constants/IncoTerms";

// All carrier that use flight as transport offers the same customs terms. (DHL EXPRESS, UPS, TNT, FEDEX)
export const FlightExport = [
    {
        title: incoTerms.dap.description,
        value: incoTerms.dap.code,
    },
    {
        title: incoTerms.ddp.description,
        value: incoTerms.ddp.code,
    },
    {
        title: incoTerms.cip.description,
        value: incoTerms.cip.code,
    },
    {
        title: incoTerms.dpu.description,
        value: incoTerms.dpu.code,
    },
    {
        title: incoTerms.cpt.description,
        value: incoTerms.cpt.code,
    },
    {
        title: incoTerms.exw.description,
        value: incoTerms.exw.code,
    },
    {
        title: incoTerms.fca.description,
        value: incoTerms.fca.code,
    },
];

export const FlightImport = [
    {
        title: incoTerms.exw.description,
        value: incoTerms.exw.code,
    },
    {
        title: incoTerms.fca.description,
        value: incoTerms.fca.code,
    },
];

export const DHLExport = [
    {
        title: incoTerms.dap.description,
        value: incoTerms.dap.code,
    },
    // DHL Has a special DDP option that is not available for other carriers.
    {
        title: incoTerms.dhl.ddp.description,
        value: incoTerms.dhl.ddp.code,
    },
];

export const BringImportExport = [
    {
        title: incoTerms.dap.description,
        value: incoTerms.dap.code,
    },
    {
        title: incoTerms.ddp.description,
        value: incoTerms.ddp.code,
    },
    {
        title: incoTerms.fca.description,
        value: incoTerms.fca.code,
    },
    {
        title: incoTerms.exw.description,
        value: incoTerms.exw.code,
    },
];

export const DHLImport = [
    {
        title: incoTerms.exw.description,
        value: incoTerms.exw.code,
    },
];

export const UPSExport = [
    ...FlightExport,
    {
        title: incoTerms.ups.ddp_no_vat.description,
        value: incoTerms.ups.ddp_no_vat.code,
    },
];

export const UPSImport = [
    ...FlightImport,
    {
        title: incoTerms.ups.ddp_no_vat.description,
        value: incoTerms.ups.ddp_no_vat.code,
    },
];

export const DSVExportUK = [
    {
        title: incoTerms.dap.description,
        value: incoTerms.dap.code,
    },
];
