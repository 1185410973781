import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import Row from "./Row";

let Container = styled.div`
    display: inline-flex;
    flex-direction: column;

    > .message {
        font-weight: bold;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
    }

    &.is-invalid {
        > .inputContainer {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }
        > .message {
            color: rgba(255, 0, 0, 0.6);
        }
    }

    &.is-disabled {
        > .inputContainer {
            background: rgb(235, 235, 228);
            border: 1px solid rgba(0, 0, 0, 0.05);

            > input {
                color: rgba(0, 0, 0, 0.5);
                background: transparent;
            }
        }
    }

    > .inputContainer {
        display: inline-table;

        th,
        td {
            padding: 0.2rem 0.5rem;

            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
        th {
            font-size: 0.8rem;
        }

        td {
            width: 160px;
        }
    }

    > .buttons {
        padding: 1rem 0;
    }
`;

function Component(props, ref) {
    const [rows, setRows] = useState(
        props.value || [
            {
                id: uuidv4(),
                length: 0,
                width: 0,
                height: 0,
                weight: 0,
                stackable: true,
            },
        ],
    );
    const [message, setMessage] = useState();
    const [disabled, setDisabled] = useState();

    useEffect(() => {
        setMessage(props.message);
    }, [props.message]);

    useEffect(() => {
        setDisabled(props.disabled || false);
    }, [props.disabled]);

    function validate() {}
    function clear() {}

    useEffect(() => {
        props.onChange(rows);
    }, [rows]);

    useImperativeHandle(ref, () => {
        return {
            validate: validate,
            clear: clear,
            value: () => rows,
        };
    });

    function changeValue(row) {
        setRows((rows) => {
            return rows.map((r) => {
                if (r.id === row.id) {
                    return row;
                }
                return r;
            });
        });
    }

    function addRow(row = null) {
        if (row) {
            setRows((rows) => {
                return rows.concat(row);
            });
        } else {
            setRows((rows) => {
                return rows.concat({
                    id: uuidv4(),
                    length: 0,
                    width: 0,
                    height: 0,
                    weight: 0,
                    stackable: null,
                });
            });
        }
    }

    function removeRow(id) {
        setRows((rows) => {
            return rows.filter((row) => row.id !== id);
        });
    }

    return (
        <Container className={disabled ? " is-disabled" : ""}>
            <table className="inputContainer">
                <thead>
                    <tr>
                        <th>Namn</th>
                        <th>Typ</th>
                        <th>Längd</th>
                        <th>Bredd</th>
                        <th>Höjd</th>
                        <th>Vikt</th>
                        <th>Stapelbar</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row) => {
                        return (
                            <Row
                                {...row}
                                key={row.id}
                                template={props.template}
                                onChange={changeValue}
                                onRemove={removeRow}
                                canDelete={rows.length > 1}
                            />
                        );
                    })}
                </tbody>
            </table>
            {props.multiRow && (
                <div className="buttons">
                    <button
                        className="c-button c-button--ghost"
                        onClick={() => addRow()}
                    >
                        Lägg till
                    </button>
                </div>
            )}
            {message && <div className="message">{message}</div>}
        </Container>
    );
}

export default forwardRef(Component);
