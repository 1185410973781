import Input from "components/OldInput";
import MultiToggle from "components/Input/MultiToggle";
import Context from "context/Global";
import React, { useContext, useEffect, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    padding: 1rem;

    > .title {
        font-size: 2rem;
    }

    > .form {
        width: 100%;
        max-width: 320px;
    }

    > .buttons {
        padding: 1rem 0;
    }
`;

function View(props) {
    const context = useContext(Context);
    const [integration, setIntegration] = useState(null);
    const [plan, setPlan] = useState(null);
    const formRef = useRef();

    useEffect(() => {
        loadIntegration();
    }, []);

    async function loadIntegration() {
        setIntegration(
            await Api.getIntegration({
                integrationId: props.match.params.integrationId,
            }),
        );
    }

    async function addPlan() {
        if (formRef.current.validate()) {
            await Api.createIntegrationPlan({
                integrationId: integration.id,
                plan: plan,
            });
            props.history.push("/admin/addons");
        }
    }

    if (!integration) {
        return "Loading...";
    }

    return (
        <Container>
            <div className="title">
                Lägg till betalplan för {integration.name}
            </div>
            <div className="form">
                <Input
                    ref={formRef}
                    type="list"
                    onChange={setPlan}
                    object={{
                        paymentInterval: {
                            title: "Betalningsintervall",
                            type: MultiToggle,
                            required: true,
                            options: [
                                {
                                    title: "Månadsvis",
                                    value: "MONTH",
                                },
                                {
                                    title: "Årsvis",
                                    value: "YEAR",
                                },
                            ],
                        },
                        price: {
                            required: true,
                            title: "Pris",
                            type: "number",
                            min: 0,
                        },
                    }}
                />
            </div>
            <div className="buttons">
                <div className="c-button c-button--raised" onClick={addPlan}>
                    Lägg till
                </div>
            </div>
        </Container>
    );
}

export default View;
