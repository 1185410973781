import { useQuery } from "@tanstack/react-query";
import Context from "context/Global";
import React, { useContext, useMemo, useState } from "react";
import Api from "../../../../services/Api/Api";
import PickupItem from "../PickupItem";

const serviceIcons = [
    {
        imgUrl: "/images/icons/dhl.png",
        path: "/pickup/dhl",
        service: "DHL",
    },
    // {
    //   imgUrl: '/images/icons/dhl-express.png',
    //   path: '/pickup/dhl-express',
    //   service: 'DHL-EXPRESS'
    // },
    {
        imgUrl: "/images/icons/fedex.svg",
        path: "/pickup/fedex",
        service: "FEDEX",
    },
    // {
    //   imgUrl: '/images/icons/dsv.svg',
    //   path: '/pickup/dsv',
    //   service: 'DSV'
    // },
    {
        imgUrl: "/images/icons/ups.svg",
        path: "/pickup/ups",
        service: "UPS",
    },
    {
        imgUrl: "/images/icons/tnt.svg",
        path: "/pickup/tnt",
        service: "TNT",
    },
    {
        imgUrl: "/images/icons/postnord.svg",
        path: "/pickup/postnord",
        service: "POSTNORD",
    },

    {
        imgUrl: "/images/icons/bring.svg",
        path: "/pickup/bring",
        service: "BRING",
    },
];

const PickupGrid = () => {
    const context = useContext(Context);
    const customerId = context?.user?.customerId;
    const [pickups, setPickups] = useState([]);
    const { data: pickupData, refetch: refetchPickups } = useQuery(
        ["customerPickups", customerId],
        Api.fetchAllPickupsByCustomerId,
        {
            refetchOnWindowFocus: false,
            enabled: customerId !== null,
        },
    );
    useMemo(() => {
        setPickups(
            pickupData?.filter(
                (item) =>
                    item.carrier !== "DHL_EXPRESS" && item.carrier !== "DSV",
            ),
        );
    }, [pickupData]);

    return (
        <div className="flex flex-wrap gap-5  rounded-md ">
            {pickups?.map((item, index) => {
                return (
                    <PickupItem
                        key={index}
                        boxes={item.data.packages}
                        pallets={item.data.pallets}
                        cages={item.data.cages}
                        serviceIcon={serviceIcons.find(
                            (icon) => icon.service === item.carrier,
                        )}
                        daysData={item.data.weekdays}
                    />
                );
            })}
        </div>
    );
};

export default PickupGrid;
