import Api from "services/Api/Api";

export async function getCompanies(integration) {
    try {
        const companies = await Api.post({
            url: `/api/businesscentral/companies`,
            data: {
                integration,
            },
        });
        return companies?.data?.value || [];
    } catch (error) {
        throw new Error(error.response.data.errorMessage || error.message);
    }
}

export const getDeliveryCodes = async (integration) => {
    try {
        const deliveryCodes = await Api.post({
            url: `/api/businesscentral/deliverycodes`,
            data: {
                integration,
            },
        });
        return deliveryCodes?.data?.value || [];
    } catch (error) {
        throw new Error(error.response.data.errorMessage || error.message);
    }
};
