import React, { Component } from "react";
import styled from "styled-components";
import { POINT_CONVERSION_COMPRESSED } from "constants";

let Container = styled.div`
    > .inputContainer {
        background: #fff;
        padding: 4px;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 40px;

        > input {
            padding: 4px;
            width: 100%;
            border: 0;
            outline: none;
            min-width: 60px;
        }

        > .unit {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            width: 60px;
            min-width: 60px;
            color: rgba(0, 0, 0, 0.4);
        }
    }

    > .message {
        font-weight: bold;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
    }

    &.is-invalid {
        > .inputContainer {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }
        > .message {
            color: rgba(255, 0, 0, 0.6);
        }
    }

    &.is-disabled {
        > .inputContainer {
            background: rgb(235, 235, 228);
            border: 1px solid rgba(0, 0, 0, 0.05);

            > input {
                color: rgba(0, 0, 0, 0.5);
                background: transparent;
            }
        }
    }
`;

class InputField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value:
                isNaN(props.value) || props.value === null ? "" : props.value,
            disabled: props.disabled,
        };

        // if(typeof this.props.onChange === 'function') {
        //     this.onChange = this.props.onChange;
        // }
        this.onChange = this.onChange.bind(this);

        if (typeof this.props.onKeyUp === "function") {
            this.onKeyUp = this.props.onKeyUp;
        }
    }

    onChange(event) {
        this.setState(
            {
                value: isNaN(event.target.value) ? "" : event.target.value,
            },
            () => {
                if (this.state.isInvalid) {
                    this.validate();
                }
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this.state.value);
                }
            },
        );
    }

    onKeyUp() {}

    value() {
        return parseFloat(this.state.value);
    }

    set(value) {
        if (!(isNaN(value) || value === null) && this.state.value !== value) {
            this.setState(
                {
                    value: value,
                },
                () => {
                    this.props.onChange(this.state.value);
                },
            );
        }
    }

    empty() {
        this.setState({
            value: "",
        });
    }

    setValidationMessage(message) {
        this.setState({
            isInvalid: true,
            message: message,
        });
    }

    validate() {
        let value = this.value();

        function parse(str) {
            var args = [].slice.call(arguments, 1),
                i = 0;
            return str.replace(/%s/g, function () {
                return args[i++];
            });
        }

        if (this.props.required && (value === "" || isNaN(value))) {
            this.setState({
                isInvalid: true,
                message: this.props.message_required,
            });
            return false;
        }
        if (
            typeof this.props.min !== "undefined" &&
            parseFloat(this.props.min) > value
        ) {
            this.setState({
                isInvalid: true,
                message: parse(this.props.message_minlimit, this.props.min),
            });
            return false;
        }
        if (
            typeof this.props.max !== "undefined" &&
            parseFloat(this.props.max) < value
        ) {
            this.setState({
                isInvalid: true,
                message: parse(this.props.message_maxlimit, this.props.max),
            });
            return false;
        }
        this.setState({
            isInvalid: false,
            message: null,
        });
        return true;
    }
    render() {
        return (
            <Container
                className={
                    (this.state.disabled ? " is-disabled" : "") +
                    (this.state.isInvalid ? " is-invalid" : "")
                }
            >
                <div className="inputContainer">
                    <input
                        type="number"
                        {...this.props}
                        value={this.state.value}
                        onKeyUp={this.onKeyUp}
                        onChange={this.onChange}
                    />
                    {this.props.unit && (
                        <div className="unit">{this.props.unit}</div>
                    )}
                </div>
                {this.state.message && (
                    <div className="message">{this.state.message}</div>
                )}
            </Container>
        );
    }
}

InputField.defaultProps = {
    disabled: false,
    step: "1",
    message_required: "This is a required field",
    message_minlimit: "Minimum value is %s",
    message_maxlimit: "Maximum value is %s",
};

export default InputField;
