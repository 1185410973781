import Input from "components/OldInput";
import InputDropdown from "components/Input/Dropdown";
import InputMultiToggle from "components/Input/MultiToggle";
import InputNumber from "components/Input/Number2";
import Context from "context/Global";
import React, {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

let Container = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    > .dimension {
        width: 160px;
    }

    > .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        width: 32px;
        height: 32px;
        color: rgba(0, 0, 0, 0.5);
        cursor: pointer;

        &.delete:hover {
            color: rgba(200, 0, 0, 0.7);
        }

        &.duplicate:hover {
            color: rgba(0, 0, 100, 0.7);
        }

        > svg {
            width: 100%;
            height: 100%;
        }
    }
`;

const initValue = {
    count: 1,
    type: "package",
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
    stackable: null,
};

function Component(props, ref) {
    const context = useContext(Context);
    const [message, setMessage] = useState();
    const [disabled, setDisabled] = useState(props.disabled || false);
    const [value, setValue] = useState({
        id: props.value?.id || uuidv4(),
        ...initValue,
        ...props.value,
    });
    const [freetext, setFreetext] = useState(false);
    const [packageOptions, setPackageOptions] = useState(props.packages || []);
    const typeRef = useRef();
    const lengthRef = useRef();
    const widthRef = useRef();
    const heightRef = useRef();
    const volumeRef = useRef();
    const weightRef = useRef();
    const stackableRef = useRef();
    const [packageDefinition, setPackageDefinition] = useState(props.package);

    useEffect(() => {
        if (props.onChange) {
            props.onChange({
                ...value,
                stackable: props.showStackable ? value.stackable : true,
            });
        }
    }, []);

    useEffect(() => {
        if (context.user.config.packageSizeDefinition === "volume") {
            if (!value.volume) {
                changeValue("volume", 0);
                volumeRef.current.set(0);
                changeValue("height", 0);
                changeValue("width", 0);
                changeValue("length", 0);
            }
        } else {
            if (value.volume) {
                changeValue("volume", 0);
                changeValue("height", 0);
                changeValue("width", 0);
                changeValue("length", 0);
                heightRef?.current.set(0);
                widthRef?.current.set(0);
                lengthRef?.current.set(0);
            }
        }
    }, [context.user.config.packageSizeDefinition]);

    useImperativeHandle(ref, () => {
        return {
            validate: validate,
            clear: clear,
            value: () => value,
        };
    });

    function validate() {}
    function clear() {}

    function changeValue(parameter, value) {
        setValue((v) => {
            v[parameter] = value;
            if (parameter === "stackable") {
                v["stackable"] = props.showStackable ? value : true;
            }
            if (props.onChange) {
                props.onChange({
                    ...v,
                });
            }
            return v;
        });
    }

    function typeChanged(pkg) {
        setValue((v) => {
            if (!v) {
                v = {};
            }
            if (typeof pkg === "object") {
                v["type"] = pkg.type;
                v["length"] = pkg.length;
                v["width"] = pkg.width;
                v["height"] = pkg.height;
                v["weight"] = pkg.weight;
                lengthRef.current.set(pkg.length);
                widthRef.current.set(pkg.width);
                heightRef.current.set(pkg.height);
                weightRef.current.set(pkg.weight);
                if (stackableRef.current) {
                    stackableRef.current.set(pkg.stackable);
                    v["stackable"] = pkg.stackable;
                } else {
                    v["stackable"] = true;
                }
            } else {
                v["type"] = pkg;
            }
            if (props.onChange) {
                props.onChange({
                    ...v,
                });
            }
            return v;
        });
    }

    function getStandardTypes() {
        if (props.isPallet) {
            return [
                {
                    title: "Pall",
                    value: "pallet",
                },
                {
                    type: "divider",
                },
            ];
        }
        return [
            {
                title: "Paket",
                value: "package",
            },
            {
                title: "Pall",
                value: "pallet",
            },
            {
                type: "divider",
            },
        ];
    }

    return (
        <Container className={disabled ? " is-disabled" : ""}>
            <InputNumber
                className="dimension"
                placeholder={0}
                min={0}
                value={value.count}
                onChange={(value) => changeValue("count", value)}
            />
            <InputDropdown
                className="dimension"
                ref={typeRef}
                value={value.type}
                options={getStandardTypes().concat(
                    packageOptions.map((pkg) => {
                        return {
                            title: pkg.name,
                            value: pkg,
                        };
                    }),
                )}
                onChange={typeChanged}
            />
            {context.user.config.packageSizeDefinition === "volume" ? (
                <Input
                    ref={volumeRef}
                    name="volume"
                    className="dimension"
                    placeholder="0"
                    onChange={(value) => changeValue("volume", value)}
                    value={value.volume}
                    type="text"
                    unit="m³"
                />
            ) : (
                <>
                    <Input
                        ref={lengthRef}
                        name="length"
                        className="dimension"
                        placeholder="0"
                        disabled={props.isPallet}
                        onChange={(value) => changeValue("length", value)}
                        value={value.length}
                        type="text"
                        unit="cm"
                    />
                    <Input
                        ref={widthRef}
                        name="width"
                        className="dimension"
                        placeholder="0"
                        disabled={props.isPallet}
                        onChange={(value) => changeValue("width", value)}
                        value={value.width}
                        type="text"
                        unit="cm"
                    />
                    <Input
                        ref={heightRef}
                        name="height"
                        className="dimension"
                        placeholder="0"
                        onChange={(value) => changeValue("height", value)}
                        value={value.height}
                        type="text"
                        unit="cm"
                    />
                </>
            )}
            <Input
                ref={weightRef}
                name="weight"
                className="dimension"
                placeholder="0"
                onChange={(value) => changeValue("weight", value)}
                min={0}
                value={value.weight}
                type="text"
                unit="kg"
            />
            {props.showStackable && (
                <Input
                    ref={stackableRef}
                    name="stackable"
                    className="stackable"
                    onChange={(value) => changeValue("stackable", value)}
                    value={value.stackable}
                    type={InputMultiToggle}
                    options={[
                        {
                            title: "Ja",
                            value: true,
                        },
                        {
                            title: "Nej",
                            value: false,
                        },
                    ]}
                />
            )}
            {props.showRemove && (
                <div
                    className="button delete"
                    onClick={() => {
                        props.onDelete(value.id);
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.094l-4.157-4.104 4.1-4.141-1.849-1.849-4.105 4.159-4.156-4.102-1.833 1.834 4.161 4.12-4.104 4.157 1.834 1.832 4.118-4.159 4.143 4.102 1.848-1.849z"
                        />
                    </svg>
                </div>
            )}
        </Container>
    );
}

export default forwardRef(Component);
