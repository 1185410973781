import React from "react";
import { EmailInput } from "../EmailInput";
import { CheckboxInput } from "../CheckboxInput";

function EmailNotificationsForm({
    senderEmailNotification,
    setSenderEmailNotification,
    receiverEmailNotification,
    setReceiverEmailNotification,
    showSender = true,
    showReceiver = true,
}) {
    return (
        <div>
            <p>Välj bokningsbekräftelse via epost till:</p>
            <div className="flex flex-col gap-2 mt-2">
                {showSender && (
                    <EmailNotificationsFormRow
                        title={"Avsändare"}
                        inputName={"sender"}
                        emailNotification={senderEmailNotification}
                        setEmailNotification={setSenderEmailNotification}
                    />
                )}
                {showReceiver && (
                    <EmailNotificationsFormRow
                        title={"Mottagare"}
                        inputName={"receiver"}
                        emailNotification={receiverEmailNotification}
                        setEmailNotification={setReceiverEmailNotification}
                    />
                )}
            </div>
        </div>
    );
}

function EmailNotificationsFormRow({
    title,
    inputName,
    emailNotification,
    setEmailNotification,
}) {
    return (
        <div className="flex w-full items-center gap-4 w-full">
            <CheckboxInput
                value={emailNotification.enabled}
                setValue={(newValue) =>
                    setEmailNotification((oldValue) => ({
                        ...oldValue,
                        enabled: newValue,
                    }))
                }
            />
            <label htmlFor={inputName}>{title}:</label>

            <EmailInput
                name="sender"
                inputState={emailNotification.email}
                shouldValidate={emailNotification.enabled}
                onChange={(state) => {
                    setEmailNotification((oldValue) => ({
                        ...oldValue,
                        email: {
                            value: state.value,
                            error: state.error,
                        },
                    }));
                }}
            />
        </div>
    );
}

export default EmailNotificationsForm;
