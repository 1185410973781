import React, { Component } from "react";
import styled from "styled-components/macro";

let Container = styled.div`
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    transform: translate3d(0, 100%, 0);
    transition: all 1s ease;

    &.is-visible {
        transform: translate3d(0, 0, 0);
    }
`;
let Content = styled.div`
    min-width: 400px;
    max-width: 90%;
    background: #363636;
    border-radius: 10px 10px 0 0;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    padding: 20px;
    box-sizing: border-box;
`;

class Snackbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            text: "",
            hide: false,
        };
    }

    componentDidMount() {}

    componentDidUpdate() {
        if (this.state.visible) {
            setTimeout(() => {
                this.setState({
                    visible: false,
                    hide: true,
                });
            }, this.props.duration);
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.text && !state.hide) {
            return {
                visible: true,
                text: props.text,
            };
        } else {
            return {
                visible: false,
                hide: false,
            };
        }
    }

    render() {
        return (
            <Container className={this.state.visible ? "is-visible" : ""}>
                <Content>{this.state.text}</Content>
            </Container>
        );
    }
}

Snackbar.defaultProps = {
    duration: "5000",
};

export default Snackbar;
