import Context from "context/Global";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import Order from "./Order";

let ChangeCustomer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    > .container {
        background: #fff;
        border-radius: 1rem;
        padding: 1rem;
    }
`;

let Menu = styled.div`
	> .button {
		color: rgba(0,0,0,.5);
		display: inline-flex;
		align-items: center;
		justify-content: flex-end;
		position: relative;
		cursor: pointer;

		&:hover {
			color: rgba(0,0,0,1);
		}

		> .menu-wrapper {
			pointer-events: none;
			display: flex;
			justify-content: center;
			align-items: center;

			> .menu-background {
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 100;
			}

			> .menu-container {
				position: absolute;
				background: #fff;
				box-shadow: 0 0 5px 1px rgba(0,0,0,.1);
				right: 0;
				transition: all .3s ease;
				opacity: 0;
				z-index: 101;
				transform: translateY(10px);

				> .option {
					padding: 1rem;
					white-space: nowrap;
					color: #000;

					&:hover {
						background: #eaeaea;
					}
				}
			}

			&.is-visible {
				pointer-events: all;

				> .menu-container {
					opacity: 1;
					transform: translateY(0);
				}
			}
		}
	}
}
`;

function roundToTwo(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}

function Orders(props) {
    const context = useContext(Context);
    const [orders, setOrders] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [orderOffset, setOrderOffset] = useState(0);
    const [orderLimit, setOrderLimit] = useState(5);

    useEffect(() => {
        setOrders(props.orders);
    }, [props.orders]);

    useEffect(() => {
        setDisabled(props.disabled);
    }, [props.disabled]);

    // useEffect(() => {
    // 	props.onChange(orders);
    // }, [orders])

    function addOrder() {
        setOrders((orders) => {
            return orders.concat([
                {
                    rows: [{}],
                },
            ]);
        });
    }

    function previousPage() {
        setOrderOffset((offset) => {
            if (offset - orderLimit >= 0) {
                return offset - orderLimit;
            }
            return 0;
        });
    }

    function nextPage() {
        setOrderOffset((offset) => {
            if (offset + orderLimit < orders.length) {
                return offset + orderLimit;
            }
            return offset;
        });
    }

    return (
        <>
            <div className="pagination">
                <div className="previous" onClick={previousPage}>
                    {"<"}
                </div>
                <div className="showing">
                    Visar {orderOffset + 1} -{" "}
                    {orderOffset + orderLimit > orders.length
                        ? orders.length
                        : orderOffset + orderLimit}{" "}
                    av {orders.length}
                </div>
                <div className="next" onClick={nextPage}>
                    {">"}
                </div>
            </div>
            <div className={`rows ${disabled ? "is-disabled" : ""}`}>
                <div className="title">Transportör</div>
                <div className="title">Försändelse</div>
                <div className="title">Avs. Datum</div>
                <div className="title">Benämning</div>
                <div className="title">Avsändare</div>
                <div className="title"></div>
                <div className="title">Mottagare</div>
                <div className="title"></div>
                <div className="title">Netto</div>
                <div className="title">Moms</div>
                <div className="title"></div>
                {orders
                    .slice(orderOffset, orderOffset + orderLimit)
                    .map((order) => {
                        return (
                            <Order
                                key={order.id}
                                order={order}
                                onRemove={() => {
                                    if (
                                        window.confirm(
                                            "Ta bort ordern från fakturaunderlaget?",
                                        )
                                    ) {
                                        props.onChange(
                                            orders.filter((o) => {
                                                return o.id !== order.id;
                                            }),
                                        );
                                    }
                                }}
                                updateRows={(rows) => {
                                    props.onChange(
                                        orders.map((o) => {
                                            if (o.id === order.id) {
                                                return {
                                                    ...o,
                                                    rows: rows,
                                                };
                                            } else {
                                                return o;
                                            }
                                        }),
                                    );
                                }}
                                moveOrder={async ({ customer }) => {
                                    if (!customer) return;

                                    props.moveOrder({ order, customer });
                                    await Api.moveOrder({
                                        orderId: order.id,
                                        customerId: customer.id,
                                    });
                                    props.onChange(
                                        orders.filter((o) => {
                                            return o.id !== order.id;
                                        }),
                                    );
                                }}
                                onChange={(updatedOrder) => {
                                    props.onChange(
                                        orders.map((o) => {
                                            if (o.id === updatedOrder.id) {
                                                return updatedOrder;
                                            } else {
                                                return o;
                                            }
                                        }),
                                    );
                                }}
                            ></Order>
                        );
                    })}
                <div className="c-button c-button-raised" onClick={addOrder}>
                    Ny order
                </div>
            </div>
        </>
    );
}

export default Orders;
