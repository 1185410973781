import React, { Component } from "react";
import styled from "styled-components/macro";

let Container = styled.div`
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background: #fff;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    align-items: flex-start;
`;

class Card extends Component {
    render() {
        return <Container {...this.props} />;
    }
}

export default Card;
