function getProductName(productType, fromCountry, toCountry) {
    if (fromCountry === "SE" && toCountry === "SE") {
        switch (productType) {
            case "express12": {
                return "TNT 12:00";
            }
            case "express10": {
                return "TNT 10:00";
            }
            case "express9": {
                return "TNT 09:00";
            }
            default: {
                return "TNT Inrikes";
            }
        }
    } else if (productType === "economyExpress") {
        return "TNT Ekonomi";
    } else {
        switch (productType) {
            case "economyExpress": {
                return "TNT Ekonomi";
            }
            case "economyExpress12": {
                return "TNT Ekonomi 12:00";
            }
            case "express12": {
                return "TNT Express 12:00";
            }
            case "express10": {
                return "TNT Express 10:00";
            }
            case "express9": {
                return "TNT Express 09:00";
            }
            default: {
                return "TNT Express";
            }
        }
    }
}

function getTNTDisplayData(productType, fromCountry, toCountry) {
    let productName = getProductName(productType, fromCountry, toCountry);
    let isDomestic = fromCountry === "SE" && toCountry == "SE";

    switch (productType) {
        case "economyExpress": {
            return {
                originalName: "TNT Economy Express",
                name: productName,
                isExpress: false,
                tags: [],
            };
        }
        case "express": {
            return {
                originalName: "TNT Express",
                name: productName,
                isExpress: isDomestic ? false : true,
                tags: [],
            };
        }
        case "express12": {
            return {
                originalName: "TNT Express 12:00",
                name: productName,
                isExpress: isDomestic ? false : true,
                tags: [],
            };
        }
        case "economyExpress12": {
            return {
                originalName: "TNT Economy Express 12:00",
                name: productName,
                isExpress: false,
                tags: [],
            };
        }
        default: {
            return {
                originalName: "TNT",
                name: productName,
                isExpress: false,
                tags: [],
            };
        }
    }
}

export { getTNTDisplayData };
