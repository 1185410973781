import React, { useContext, useEffect } from "react";
import {
    getRefreshToken,
    loginRequest,
    clearLoginStorage,
} from "../../../Addons/Create/BusinessCentral/authConfig";
import { useMsal } from "@azure/msal-react";
import Api from "../../../../services/Api/Api";
import toast from "react-hot-toast";
import IntegrationsTokenLifespans from "../../../../constants/integrations/IntegrationsTokenLifespans";
import Context from "../../../../context/Global";

/**
 * This component will do reauth for Business Central
 * @param integrationData JSON data from integration table in column data
 * @param integrationId integration ID
 * @param className add your classes styles for reauth button
 * @param onSuccess
 * @returns {JSX.Element}
 * @constructor
 */
const BusinessCentralReAuth = ({
    integrationData,
    integrationId,
    className,
    onSuccess = () => {},
}) => {
    const { instance } = useMsal();
    const { integrationsExpiry, setExpiryIntegrations } =
        useContext(Context) || {};

    async function handleReAuth() {
        const login = await instance.loginPopup(loginRequest);
        if (login?.accessToken) {
            const updateData = {
                ...integrationData,
                accessToken: login.accessToken,
                refreshToken: getRefreshToken().secret,
                refreshTokenExpiration:
                    IntegrationsTokenLifespans.BUSINESS_CENTRAL_REFRESH_TOKEN_LIFESPAN,
            };
            try {
                await Api.updateIntegration({
                    integrationId,
                    data: updateData,
                });
                const updatedNotificationsList = integrationsExpiry?.filter(
                    (notification) =>
                        notification.integration.id !== integrationId,
                );
                setExpiryIntegrations(updatedNotificationsList);
                toast.success("Integrationen uppdaterades framgångsrikt");
                onSuccess();
            } catch (error) {
                toast.error(
                    "Ett fel uppstod vid autentisering av integrationen",
                );
            } finally {
                // Whatever happens remove all data regarding business from storage to avoid errors
                localStorage.removeItem("businessCentral");
                clearLoginStorage();
            }
        }
    }

    // This useEffect is handling clientId that is used from MSAL config in storage.
    // Reason why we do reload and setup of it is the way it was done before to init settings in order
    // to be able to make OAuth popup work.
    useEffect(() => {
        // We are setting storage client id because flow of Business Central is working with it
        if (!localStorage.getItem("businessCentral")) {
            localStorage.setItem(
                "businessCentral",
                JSON.stringify({ clientId: integrationData?.clientId }),
            );
            // We have to reload page in order to init msal config so OAuth can work
            window.location.reload();
        }
    }, []);
    return (
        <button className={className} onClick={handleReAuth}>
            Uppdatera nu
        </button>
    );
};

export default BusinessCentralReAuth;
