import React, { forwardRef } from "react";
import Input from "../../../../components/OldInput";
import CalendarComponent from "components/Calendar/CalendarComponent";

const PickupInput = forwardRef(
    ({ isManualPickup, setPickupDate, pickupDate }, ref) => {
        const inputObject = {
            count: {
                title: "Antal paket",
                type: "number",
                min: 1,
                unit: "st",
                hidden: !isManualPickup,
                required: true,
                message_required: "Ange antalet paket ni vill ska hämtas upp",
                message_minlimit: "Antalet paket måste vara 1 eller fler",
            },
            packageType: {
                title: "Pakettyp",
                type: "dropdown",
                required: true,
                hidden: !isManualPickup,
                options: [
                    {
                        title: "Paket",
                        value: "PC",
                    },
                    {
                        title: "Pall 120x80cm",
                        value: "PE",
                    },
                    {
                        title: "Halvpall 60x80cm",
                        value: "AF",
                    },
                    {
                        title: "Kvartspall 60x40cm",
                        value: "OA",
                    },
                    {
                        title: "Specialpall (Bredd 181-120cm)",
                        value: "OF",
                    },
                ],
            },
            totalWeight: {
                title: "Total vikt",
                type: "number",
                min: 0.01,
                hidden: !isManualPickup,
                unit: "kg",
                required: true,
                message_required: "Ange totala vikten för alla paket",
                message_minlimit: "Vikten måste vara större än 0",
            },
            instructions: {
                title: "Instruktioner",
                type: "textarea",
            },
        };

        return (
            <div className="p-5 rounded border border-zinc-100 flex-col justify-start items-start gap-6 inline-flex">
                <div className="text-stone-400 text-2xl font-normal w-full">
                    {isManualPickup ? "Steg 2" : "Steg 3"} - Välj när du önskar
                    tidigast upphämtning:
                </div>
                <div className="flex-col justify-center items-center gap-6 space-y-2 w-full">
                    <CalendarComponent
                        onChange={setPickupDate}
                        value={pickupDate}
                    />
                    <Input ref={ref} type="list" object={inputObject} />
                </div>
            </div>
        );
    },
);

export default PickupInput;
