import React, { useEffect, useState } from "react";
import styled from "styled-components";

let Container = styled.div`
    > .inputContainer {
        background: #fff;
        padding: 4px;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 40px;
        overflow: hidden;

        > input {
            padding: 4px;
            width: 100%;
            border: 0;
            outline: none;
        }

        > input[disabled] {
            background: inherit;
        }

        > .prefix {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            margin-right: 8px;
            width: 60px;
            min-width: 60px;
            color: rgba(0, 0, 0, 0.4);
        }

        > .unit {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            margin-left: 8px;
            width: 60px;
            min-width: 60px;
            color: rgba(0, 0, 0, 0.4);
        }

        > .length {
            font-size: 0.8rem;
            padding: 2px 4px;
            font-style: italic;
            font-weight: bold;
            color: #555;

            &.is-invalid {
                color: #ff5555;
            }
        }
    }

    &.align-right {
        > .inputContainer {
            > input {
                text-align: right;
            }
        }
    }

    > .message {
        font-weight: bold;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
    }

    &.is-invalid {
        > .inputContainer {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }
        > .message {
            color: rgba(255, 0, 0, 0.6);
        }
    }

    &.is-disabled {
        > .inputContainer {
            background: rgb(235, 235, 228);
            border: 1px solid rgba(0, 0, 0, 0.05);

            > input {
                color: rgba(0, 0, 0, 0.5);
                background: transparent;
            }
        }
    }
`;

function Component(props) {
    const [value, setValue] = useState();
    const [unit, setUnit] = useState();
    const [message, setMessage] = useState();
    const [disabled, setDisabled] = useState();
    const [isInvalid, setIsInvalid] = useState(false);
    const [isInteger, setIsInteger] = useState();

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        setUnit(props.unit);
    }, [props.unit]);

    useEffect(() => {
        setDisabled(props.disabled || false);
    }, [props.disabled]);

    useEffect(() => {
        setIsInteger(props.isInteger || false);
    }, [props.isInteger]);

    function onKeyUp() {}

    function onChange(event) {
        setValue(event.target.value);
        if (props.onChange) {
            props?.onChange(event.target.value);
        }
    }

    return (
        <Container
            className={
                (disabled ? " is-disabled" : "") +
                (isInvalid ? " is-invalid" : "") +
                (props.align ? " align-" + props.align : "")
            }
        >
            <div className="inputContainer">
                <input
                    type="text"
                    placeholder={props.placeholder}
                    onChange={onChange}
                    value={value}
                />
                {unit && <div className="unit">{unit}</div>}
            </div>
            {message && <div className="message">{message}</div>}
        </Container>
    );
}

export default Component;
