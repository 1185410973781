import Input from "components/OldInput";
import Loader from "components/Loader/Loader";
import Context from "context/Global";
import React, { Component } from "react";
import Api from "services/Api/Api";
import { io } from "socket.io-client";
import styled from "styled-components/macro";

let Container = styled.div`
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > .loader {
        width: 200px;
        height: 200px;
    }

    > .newBrand {
        display: flex;
        gap: 1rem;
        align-items: center;
        padding-top: 4rem;

        > img {
            height: 32px;
        }
    }
    > .newBrand-text {
        padding-top: 1rem;
        display: flex;
        text-align: center;
    }
`;

let Form = styled.form`
    display: flex;
    flex-direction: column;

    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    padding: 32px;
    border-radius: 8px;
    box-sizing: border-box;
    width: 100%;
    max-width: 320px;

    > .inputFields {
        margin-bottom: 16px;
    }

    > .logo {
        width: 100%;
        margin-bottom: 20px;

        > .c-logo {
            width: 100%;
            height: 50px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }

    > .forgot {
        text-align: center;
        margin-top: 20px;
        gap: 1rem;
        display: flex;
        justify-content: center;

        > a {
            text-decoration: none;
            font-size: 12px;
            text-align: center;
            font-weight: bold;
            color: #777;
        }
        > a:hover {
            text-decoration: underline;
        }
    }

    > .disclaimer {
        text-align: center;
        margin-top: 20px;
        font-size: 0.8rem;
        color: rgba(0, 0, 0, 0.5);
    }
`;

class Login extends Component {
    inputData = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

        this.login = this.login.bind(this);
    }

    componentDidMount() {
        this.inputData.current.focus("email");
    }

    login(ev) {
        this.setState({
            loading: true,
        });
        var credentials = this.inputData.current.value();
        Api.login(credentials)
            .then((user) => {
                this.context.setUser(user);
                if (process.env.REACT_APP_CHAT_SOCKET_URL) {
                    const socket = io(process.env.REACT_APP_CHAT_SOCKET_URL, {
                        auth: {
                            token: Api.getAuthenticationToken(),
                        },
                    });

                    this.context.setSocket(socket);
                }
            })
            .catch((data) => {
                switch (data.response?.status) {
                    case 401: {
                        console.warn(
                            "Invalid credentials - generate error message for user",
                        );
                        break;
                    }
                    default: {
                        console.error("Unknown error");
                        console.error(data?.response?.status);
                        console.error(data?.response);
                    }
                }
                this.setState({
                    loading: false,
                });
            });

        ev.preventDefault();
        return false;
    }

    render() {
        if (this.state.loading) {
            return (
                <Container>
                    <div className="loader">
                        <Loader />
                    </div>
                </Container>
            );
        } else {
            return (
                <Container>
                    <Form>
                        <div className="logo">
                            <div className="c-logo"></div>
                        </div>
                        <Input
                            ref={this.inputData}
                            className="inputFields"
                            type="list"
                            object={{
                                email: {
                                    type: "email",
                                    placeholder: "E-post",
                                },
                                password: {
                                    type: "password",
                                    placeholder: "Lösenord",
                                    autoComplete: true,
                                },
                            }}
                        />
                        <input
                            type="submit"
                            className="c-button c-button--raised"
                            onClick={this.login}
                            value="Logga in"
                        />
                        <div className="forgot">
                            <a href="/forgot">
                                Glömt dina inloggningsuppgifter?
                            </a>
                        </div>
                        <div className="disclaimer">
                            Genom att logga in godkänner ni användaravtal och
                            integritetspolicy
                        </div>
                        <div className="forgot">
                            <a
                                target="_blank"
                                href="https://www.zendr.se/anvandaravtal"
                            >
                                Användaravtal
                            </a>
                            <a
                                target="_blank"
                                href="https://www.zendr.se/personlig-information"
                            >
                                Integritetspolicy
                            </a>
                        </div>
                    </Form>
                </Container>
            );
        }
    }
}

Login.contextType = Context;

export default Login;
