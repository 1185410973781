import React from "react";

export const ActionListItem = ({
    actionItem,
    close,
    subItems,
    classes = "",
}) => {
    const [hovering, setHovering] = React.useState(false);
    const isDisabled = actionItem.checkIfDisabled?.() ?? false;
    const disabledClasses = isDisabled ? "opacity-50 cursor-not-allowed" : "";
    const hasSubItems = subItems?.length;
    return (
        <div
            className={`relative hover:bg-gray-100 w-full text-left border-b border-gray-300 ${disabledClasses} ${classes}`}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
        >
            <button
                onClick={() => {
                    actionItem.onClick?.();
                    close();
                }}
                disabled={isDisabled || hasSubItems}
                className="flex w-full h-full p-3"
            >
                {actionItem.title}
                {hasSubItems && (
                    <img
                        className="ml-auto"
                        src="/images/icons/chevron-right.svg"
                        alt="chevron-right"
                    />
                )}
            </button>

            {hasSubItems && hovering && (
                <div className="absolute top-0 left-full pl-1 z-10 w-full">
                    <div className="flex flex-col items-start bg-white border border-gray-300 rounded-md shadow-lg w-full">
                        {subItems.map((subItem, index) => (
                            <ActionListItem
                                key={`sub-action-item-${index}-${subItem.title}`}
                                actionItem={subItem}
                                close={close}
                                subItems={subItem.subItems}
                                classes={subItem.extraClasses}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
