import "moment/locale/sv";
import React, { useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import { clearLoginStorage } from "./authConfig";
import toast from "react-hot-toast";

let Container = styled.div`
    > .title {
        font-size: 2rem;
        padding: 1rem;
    }
    > .description {
        padding: 0 1rem;
    }

    > .form {
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    > .buttons {
        padding: 1rem;
    }

    > .error {
        padding: 0 1rem;
        color: #a00;
        font-weight: bold;
    }
`;

function Component(props) {
    const getTodaysDate = () => {
        return new Date().toISOString().split("T")[0];
    };

    const getPreviousMonthDate = () => {
        let date = new Date();
        date.setMonth(date.getMonth() - 1);
        return date.toISOString().split("T")[0];
    };

    const [configuration, setConfiguration] = useState({
        type: "BusinessCentral",
        fetchStatus: "Draft",
        initialFetchDate: getTodaysDate(),
        environment: "production",
    });

    useEffect(() => {
        if (
            new Date(configuration.initialFetchDate) <
            new Date(getPreviousMonthDate())
        ) {
            setConfiguration({
                ...configuration,
                initialFetchDate: getPreviousMonthDate(),
            });
        }
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setConfiguration({
            ...configuration,
            [name]: value,
        });
    };

    const storeCustomerConfiguration = async () => {
        if (localStorage.getItem("businessCentral")) {
            localStorage.removeItem("businessCentral");
        }

        localStorage.setItem("businessCentral", JSON.stringify(configuration));

        try {
            const data = { ...configuration };
            const res = await createIntegration(data);
            window.location.replace(`/addons/${res.id}`);
        } catch (error) {
            clearLoginStorage();
            toast.error("Något gick fel, försök igen.");
        }
    };

    async function createIntegration(data) {
        const customer = await Api.getCurrentUser();
        return await Api.createCustomerIntegration({
            customerId: customer.customer.id,
            data,
        });
    }

    return (
        <Container>
            <div className="title">Lägg till Business Central</div>
            <div className="description mb-5">
                Microsoft Business Central Integration
            </div>
            <div className="form mb-5">
                <div className="customer-credentials flex flex-col">
                    <div className="mt-3">
                        <div className="flex items-center text-sm font-semibold text-gray-700">
                            Klient-ID -
                            <p className="text-xs ml-1">
                                Program-ID (klient) från Azure Appregistreringar
                            </p>
                        </div>
                        <input
                            className="border rounded w-80 p-3"
                            onChange={handleInputChange}
                            name="clientId"
                            type="text"
                            placeholder="Client ID"
                        />
                    </div>

                    <div className="mt-3">
                        <div className="flex items-center text-sm font-semibold text-gray-700">
                            Miljö -
                            <p className="text-xs ml-1">
                                Om ingen miljö anges används "production"
                            </p>
                        </div>
                        <input
                            className="border rounded w-80 p-3"
                            onChange={handleInputChange}
                            name="environment"
                            type="text"
                            placeholder="production"
                        />
                    </div>

                    <div className="mt-3">
                        <div className="flex items-center text-sm font-semibold text-gray-700">
                            Startdatum för hämtning -
                            <p className="text-xs ml-1">
                                Max 1 månad bakåt i tiden
                            </p>
                        </div>
                        <input
                            className="border rounded w-80 p-3"
                            onChange={handleInputChange}
                            name="initialFetchDate"
                            type="date"
                            min={getPreviousMonthDate()}
                            max={getTodaysDate()}
                            value={configuration.initialFetchDate}
                        />
                    </div>

                    <div className="mt-3">
                        <div className="flex items-center text-sm font-semibold text-gray-700">
                            Status för hämtning -
                            <p className="text-xs ml-1">
                                Öppen/Släppt status i BC
                            </p>
                        </div>
                        <select
                            className="border rounded w-80 p-3"
                            onChange={handleInputChange}
                            name="fetchStatus"
                            value={configuration.fetchStatus}
                        >
                            <option value="Draft">Öppen</option>
                            <option value="Open">Släppt</option>
                        </select>
                    </div>

                    <div className="mt-5">
                        <button
                            onClick={storeCustomerConfiguration}
                            className="c-button c-button--raised"
                            disabled={!configuration.clientId}
                        >
                            Aktivera
                        </button>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default Component;
