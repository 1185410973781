import useDebounce from "hooks/useDebounce";
import React, { useEffect, useRef, useState } from "react";

const SearchableDropdown = ({
    options,
    placeholder,
    onChange,
    title,
    required,
    value,
    searchViaApi,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedItem, setSelectedItem] = useState(null);
    const wrapperRef = useRef(null);

    const debouncedSearchTerm = useDebounce(searchTerm, 300);

    useEffect(() => {
        if (options.length === 1) {
            setSelectedItem(options[0]);
            onChange(options[0].value);
        } else if (value) {
            const selectedOption = options.find(
                (option) => option.value === value,
            );
            setSelectedItem(selectedOption || null);
        } else {
            setSelectedItem(null);
        }
    }, [value, options]);

    if (searchViaApi && searchTerm.length > 0) {
        searchViaApi(debouncedSearchTerm);
    }

    const filteredOptions = debouncedSearchTerm
        ? options.filter((option) =>
              option.label
                  .toLowerCase()
                  .includes(debouncedSearchTerm.toLowerCase()),
          )
        : options;

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const searchEventHandler = (value) => {
        setSearchTerm(value);
    };

    const handleChange = (e) => {
        searchEventHandler(e.target.value);
    };

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (option) => {
        setSelectedItem(option);
        onChange(option.value);
        setIsOpen(false);
        setSearchTerm("");
    };

    return (
        <div className="relative" ref={wrapperRef}>
            {title && (
                <div className="text-sm font-bold mb-2 flex items-center">
                    {title}
                    {required && <span className="text-red-500 ml-1">*</span>}
                </div>
            )}
            <div
                className="cursor-pointer border border-gray-300 rounded-md p-2 flex justify-between items-center"
                onClick={toggleDropdown}
            >
                {selectedItem ? (
                    <div className="font-medium">{selectedItem.label}</div>
                ) : (
                    <div className="text-gray-500">{placeholder}</div>
                )}
                <img
                    src={`/images/icons/chevron-${isOpen ? "up" : "down"}.svg`}
                    alt="Chevron"
                    className="w-4 h-4"
                />
            </div>
            {isOpen && (
                <div className="absolute z-10 left-0 right-0 mt-1 bg-white border border-gray-300 rounded-md">
                    <input
                        type="text"
                        className="p-2 w-full outline-none"
                        placeholder="Sök"
                        value={searchTerm}
                        onChange={handleChange}
                    />
                    <ul className="max-h-60 overflow-auto font-medium">
                        {filteredOptions.map((option) => (
                            <li
                                key={option.value}
                                className="p-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleOptionClick(option)}
                            >
                                {option.label}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default SearchableDropdown;
