import Input from "components/OldInput";
import history from "components/Application/History";
import InputMultiToggle from "components/Input/MultiToggle";
import InputPostalCode from "components/Input/PostalCode";
import Loader from "components/Loader/Loader";
import Context from "context/Global";
import React, { useContext, useEffect, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
`;

let Form = styled.div`
    padding: 16px;

    > .button {
        margin-top: 32px;
    }
`;

let Title = styled.div`
    padding: 16px;
    padding-bottom: 4px;
    font-size: 26px;
`;

let cutoffTimeOptions = [];

for (var i = 8; i <= 18; i++) {
    cutoffTimeOptions.push({
        title: ("0" + i).slice(-2) + ":00",
        value: ("0" + i).slice(-2) + ":00",
    });
    cutoffTimeOptions.push({
        title: ("0" + i).slice(-2) + ":30",
        value: ("0" + i).slice(-2) + ":30",
    });
}

function View({ customerId, ...props }) {
    const context = useContext(Context);
    const [customer, setCustomer] = useState(null);
    const [updating, setUpdating] = useState(false);
    const customerInputRef = useRef();
    const settingsInputRef = useRef();

    useEffect(() => {
        if (
            context.user.administrator ||
            (context.user.brand && context.user.brand.id) ||
            context.user.customer.id === parseInt(customerId)
        ) {
            loadCustomer();
        } else {
            history.replace("/admin");
        }
    }, []);

    async function loadCustomer() {
        setCustomer(
            await Api.getCustomer({
                customerId: customerId,
            }),
        );
    }

    async function updateCustomer() {
        setUpdating(true);
        await Api.updateCustomer({
            customerId: customerId,
            customer: customerInputRef.current.value(),
            settings: settingsInputRef.current.value(),
        });
        setTimeout(() => {
            history.back();
        }, 500);
    }

    if (!customer) {
        return <Loader />;
    }

    return (
        <Container>
            <Title>Redigera företag</Title>
            <Form>
                <Input
                    ref={customerInputRef}
                    value={customer}
                    type="list"
                    object={{
                        name: {
                            title: "Företagsnamn",
                            type: "text",
                            maxLength: 35,
                            autoComplete: "chrome-off",
                            required: true,
                        },
                        countryCode: {
                            title: "Land",
                            type: "dropdown",
                            autoComplete: "chrome-off",
                            disabled: true,
                            value: "SE",
                            options: [
                                {
                                    title: "Sverige",
                                    value: "SE",
                                },
                            ],
                        },
                        postalCode: {
                            type: InputPostalCode,
                            title: "Postnummer",
                            autoComplete: "chrome-off",
                            required: true,
                            countryCode: "SE",
                            onPostalTownSelected: (item) => {
                                customerInputRef.current.set({
                                    postalTown: item.value.postalTown,
                                });
                            },
                        },
                        postalTown: {
                            title: "Postort",
                            type: "text",
                            autoComplete: "chrome-off",
                            placeholder: "",
                            required: true,
                        },
                        addressLine1: {
                            title: "Adress",
                            type: "text",
                            autoComplete: "chrome-off",
                            placeholder: "",
                            maxLength: 30,
                            required: true,
                        },
                        addressLine2: {
                            title: "",
                            type: "text",
                            autoComplete: "chrome-off",
                            placeholder: "",
                            maxLength: 30,
                            required: false,
                        },
                        vatNumber: {
                            type: "text",
                            autoComplete: "chrome-off",
                            title: "Tull-id",
                            required: true,
                            helpText:
                                "Momsnummer, VAT number, Tax number, etc.",
                        },
                        contactName: {
                            type: "text",
                            autoComplete: "chrome-off",
                            title: "Kontaktperson",
                            required: true,
                        },
                        contactEmail: {
                            type: "text",
                            autoComplete: "chrome-off",
                            title: "Kontakt-mail",
                            required: true,
                        },
                        contactPhoneNumber: {
                            type: "text",
                            autoComplete: "chrome-off",
                            title: "Kontakt-telefonnummer",
                            required: true,
                        },
                        labelFormat: {
                            type: InputMultiToggle,
                            autoComplete: "chrome-off",
                            title: "Föredraget format på etiketter",
                            helpText:
                                "Används på de leverantörer där det är applicerbart.",
                            required: true,
                            options: [
                                {
                                    title: "Etikett",
                                    value: "label",
                                },
                                {
                                    title: "A4 - halv sida",
                                    value: "a4",
                                },
                                {
                                    title: "A4 - 2 liggande etiketter",
                                    value: "a4-2-landscape",
                                },
                                {
                                    title: "A4 - 3 liggande etiketter",
                                    value: "a4-3-landscape",
                                },
                                {
                                    title: "A4 - 2 stående etiketter",
                                    value: "a4-2-portrait",
                                },
                                {
                                    title: "A4 - 2 stående etiketter centrerat",
                                    value: "a4-2-portrait-center",
                                },
                                {
                                    title: "A4 - 1 stående etikett",
                                    value: "a4-1-portrait",
                                },
                                {
                                    title: "Etikettskrivare 107 x 251 mm",
                                    value: "107x251",
                                },
                                {
                                    title: "Etikettskrivare 103 x 165 mm",
                                    value: "103x165",
                                },
                            ],
                        },
                        invoiceEmail: {
                            type: "text",
                            autoComplete: "chrome-off",
                            title: "Faktura-mail",
                            required: true,
                        },
                    }}
                />
                <Input
                    ref={settingsInputRef}
                    value={customer.settings}
                    type="list"
                    object={{
                        dhlCutoffTime: {
                            title: "Stopptid DHL",
                            type: "dropdown",
                            options: cutoffTimeOptions,
                            autoComplete: "chrome-off",
                        },
                    }}
                />
                <button
                    className="button c-button c-button--raised"
                    disabled={!!updating}
                    onClick={updateCustomer}
                >
                    Spara
                </button>
            </Form>
        </Container>
    );
}

export default View;
