import React, { memo, useContext, useState } from "react";
import { LoaderIcon } from "react-hot-toast";
import QuotesModal from "../../../modals/QuotesModal/QuotesModal";
import PickupModal from "../../../modals/PickupModal";
import ProductContent from "./ProductContent";
import { ProductButton } from "./ProductButton";
import { useQuoteOrders } from "hooks/batch/useQuoteOrders";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { BATCH_ORDER } from "constants/batch/batchConstants";
import {
    checkIfNonEU,
    hasQuotesExpired,
    isEligibleForDirectPackageBooking,
    isEligibleForDirectServicePointBooking,
    validateBatchOrder,
} from "utils/batchTableUtils";
import Context from "context/Global";

const ProductComponent = memo(({ order }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [quotesIsOpen, setQuotesIsOpen] = useState(false);
    const [pickupIsOpen, setPickupIsOpen] = useState(false);

    const history = useHistory();
    const context = useContext(Context);

    const bookBatchDirectWithoutQuote =
        context.user.customer.settings.bookBatchDirectWithoutQuote;

    const toggleIsOpen = () => {
        setIsOpen((prev) => !prev);
    };

    const nonEUOrder = checkIfNonEU(order);

    const handleBlur = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setIsOpen(false);
        }
    };

    const { quoteOrders } = useQuoteOrders();

    const getErrorMessage = (error) => {
        switch (error) {
            case BATCH_ORDER.Errors.Quote.Failed:
                return "Ingen tjänst hittades, se till att alla uppgifter är korrekta och försök igen.";
            case BATCH_ORDER.Errors.Booking.Failed:
                return "Bokning misslyckades, se till att alla uppgifter är korrekta och försök igen.";
            default:
                return "Oväntat fel uppstod, försök igen eller kontakta vår kundtjänst";
        }
    };

    const renderProductButton = () => {
        switch (true) {
            case hasQuotesExpired(order):
                return false;
            case !validateBatchOrder(order, bookBatchDirectWithoutQuote):
                return false;
            case !hasQuotesExpired(order) && !!order.data.quotations:
                return true;
            case isEligibleForDirectPackageBooking("single", order, []) &&
                bookBatchDirectWithoutQuote:
                return true;
            case isEligibleForDirectServicePointBooking("single", order, []) &&
                bookBatchDirectWithoutQuote:
                return true;
            default:
                return false;
        }
    };

    const orderIsPostNord =
        order.data.selectedQuote?.service.name.toLowerCase() === "postnord";

    {
        switch (order?.state) {
            case BATCH_ORDER.State.Loading:
                return (
                    <div className="flex w-96 h-full items-center justify-center">
                        <div className="w-full flex-shrink-0">
                            <LoaderIcon
                                style={{
                                    width: "1.5rem",
                                    height: "1.5rem",
                                    marginRight: "auto",
                                }}
                            />
                        </div>
                    </div>
                );
            case BATCH_ORDER.State.Error:
                return (
                    <div className="text-error-550 flex w-96 h-full items-center justify-start whitespace-normal">
                        {getErrorMessage(order?.error)}
                    </div>
                );
            default:
                return (
                    <div
                        className="relative  h-full"
                        style={{ width: "400px" }}
                    >
                        <div
                            className={
                                isOpen
                                    ? "rounded-md transition-shadow duration-300 w-full absolute top-4 items-center shadow-modal z-20"
                                    : "transition-shadow duration-300 w-full absolute rounded-md top-4"
                            }
                            onBlur={handleBlur}
                        >
                            {quotesIsOpen && (
                                <QuotesModal
                                    mode="single"
                                    setIsOpen={setQuotesIsOpen}
                                    order={order}
                                    isOpen={quotesIsOpen}
                                />
                            )}
                            {pickupIsOpen && (
                                <PickupModal
                                    setIsOpen={setPickupIsOpen}
                                    isOpen={pickupIsOpen}
                                    mode={"single"}
                                    quoteOrders={quoteOrders}
                                    order={order}
                                />
                            )}
                            {nonEUOrder ? (
                                <button
                                    className={
                                        "flex bg-white p-3 rounded-md gap-3 items-center w-full  h-24 z-10"
                                    }
                                    onClick={() =>
                                        history.push(
                                            order?.origin === "imported_order"
                                                ? `/create/imported/${order?.id}`
                                                : `/create/order/${order?.id}`,
                                        )
                                    }
                                >
                                    <ProductContent
                                        order={order}
                                        nonEUOrder={nonEUOrder}
                                    />
                                    <div
                                        className={" flex flex-row justify-end"}
                                    >
                                        <div className="text-buttonColor text-lg font-normal flex-row my-auto  mr-1  ">
                                            Hantera
                                        </div>
                                        <img
                                            alt="Chevron right"
                                            src="/images/icons/chevron-right.svg"
                                            style={{ transform: "scale(1.8)" }}
                                        />
                                    </div>
                                </button>
                            ) : (
                                <button
                                    className={
                                        isOpen
                                            ? "flex bg-beige p-3 rounded-md rounded-b-none gap-3 items-center w-full border h-24 z-10"
                                            : "flex bg-beige p-3 rounded-md gap-3 items-center w-full border h-24 z-10"
                                    }
                                    onClick={toggleIsOpen}
                                >
                                    {!order.quotesExpired ? (
                                        <ProductContent order={order} />
                                    ) : (
                                        <div className="text-error-550  flex flex-col items-start">
                                            <p>Prisförslaget har gått ut,</p>
                                            <p>vänligen sök pris igen.</p>
                                        </div>
                                    )}

                                    <div
                                        className={
                                            isOpen
                                                ? "ml-auto transform rotate-180 transition duration-300"
                                                : "ml-auto transform transition duration-300"
                                        }
                                    >
                                        <img
                                            alt="Chevron down"
                                            src="/images/icons/chevron-down.svg"
                                        />
                                    </div>
                                </button>
                            )}
                            {isOpen && (
                                <div className="z-30 w-full border relative mt-15 border-t-0 rounded-md rounded-t-none bg-secondary-400">
                                    <div
                                        className={
                                            "p-3 flex flex-col gap-3 max-h-96"
                                        }
                                    >
                                        <div className="flex flex-col gap-3">
                                            {renderProductButton() && (
                                                <ProductButton
                                                    onClick={() => {
                                                        setIsOpen(false);
                                                        setQuotesIsOpen(true);
                                                    }}
                                                >
                                                    Hantera transporttjänst
                                                </ProductButton>
                                            )}
                                            <ProductButton
                                                onClick={() =>
                                                    setPickupIsOpen(
                                                        (prev) => !prev,
                                                    )
                                                }
                                                disabled={orderIsPostNord}
                                            >
                                                Hantera upphämtning
                                            </ProductButton>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );
        }
    }
});

export default ProductComponent;
