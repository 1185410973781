import Input from "components/OldInput";
import InputDateRange from "components/Input/DateRange";
import MarkdownInput from "components/Input/Markdown";
import Context from "context/Global";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    width: 100%;
    max-width: 600px;

    > .form {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    > .buttons {
        width: 100%;
        padding: 1rem;
        display: flex;
        justify-content: space-between;

        > .delete {
            border-color: var(--color-bad);
            color: var(--color-bad);

            &:hover {
                background-color: #f2cccc;
            }
        }
    }
`;

function View({ history, messageId }) {
    const context = useContext(Context);
    const [message, setMessage] = useState(null);
    const inputRef = useRef();

    useEffect(() => {
        if (messageId) {
            loadMessage();
        } else {
            setMessage({
                title: null,
                type: null,
                shortText: null,
                longText: null,
                dateRange: {
                    start: moment().format("YYYY-MM-DD"),
                    end: null,
                },
            });
        }
    }, []);

    async function loadMessage() {
        const message = await Api.getPortalMessage({
            messageId,
        });
        setMessage({
            ...message,
            dateRange: {
                start: !!message.start
                    ? moment(message.start).format("YYYY-MM-DD")
                    : null,
                end: !!message.end
                    ? moment(message.end).format("YYYY-MM-DD")
                    : null,
            },
        });
    }

    async function save() {
        if (inputRef.current?.validate()) {
            if (messageId) {
                context.socket.emit("updatePortalMessage", {
                    id: messageId,
                    title: message.title,
                    type: message.type,
                    shortText: message.shortText,
                    longText: message.longText,
                    start: message.dateRange.start,
                    end: message.dateRange.end,
                });
                history.push("/admin/messages");
            } else {
                context.socket.emit("createPortalMessage", {
                    title: message.title,
                    type: message.type,
                    shortText: message.shortText,
                    longText: message.longText,
                    start: message.dateRange.start,
                    end: message.dateRange.end,
                });
                history.push("/admin/messages");
            }
        }
    }

    function deleteMessage() {
        if (window.confirm("Är du säker på att du vill ta bort meddelandet?")) {
            context.socket.emit("deletePortalMessage", messageId);
            history.push("/admin/messages");
        }
    }

    if (!message) {
        return null;
    }

    return (
        <Container>
            <div className="form">
                <Input
                    type="list"
                    ref={inputRef}
                    onChange={setMessage}
                    value={message}
                    object={{
                        title: {
                            title: "Titel",
                            type: "text",
                            required: true,
                        },
                        type: {
                            title: "Typ",
                            type: "dropdown",
                            required: true,
                            options: [
                                {
                                    title: "Info",
                                    value: "INFO",
                                },
                                {
                                    title: "Varning",
                                    value: "WARNING",
                                },
                                {
                                    title: "Lösning",
                                    value: "RESOLUTION",
                                },
                            ],
                        },
                        shortText: {
                            required: true,
                            title: "Kort text",
                            type: "textarea",
                        },
                        longText: {
                            title: "Lång text",
                            type: MarkdownInput,
                        },
                        dateRange: {
                            title: "Datum",
                            type: InputDateRange,
                        },
                    }}
                />
            </div>
            <div className="buttons">
                <div className="c-button c-button--raised" onClick={save}>
                    {messageId ? "Spara" : "Skapa"}
                </div>
                {!!messageId && (
                    <div
                        className="c-button c-button--ghost delete"
                        onClick={deleteMessage}
                    >
                        Ta bort
                    </div>
                )}
            </div>
        </Container>
    );
}

export default withRouter(View);
