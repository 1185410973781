import Input from "components/OldInput";
import Loader from "components/Loader/Loader";
import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background: #fff;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;

    > .pricelists {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        > .label {
            margin-right: 1rem;
            font-weight: bold;
        }
    }

    table {
        thead {
            th {
                font-weight: normal;
                padding: 4px 16px;
            }
        }

        tbody {
            th {
                font-weight: normal;
            }
            .product {
                th {
                    width: 100%;
                    padding: 4px 16px;

                    > .wrapper {
                        display: flex;
                        justify-content: space-between;

                        > .note {
                            font-style: italic;
                        }
                    }
                }

                th,
                td {
                    &:first-child {
                        border-radius: 5px 0 0 5px;
                    }
                    &:last-child {
                        border-radius: 0 5px 5px 0;
                    }
                }

                .input {
                    padding: 4px 16px;
                }

                &:hover {
                    th,
                    td {
                        background: rgba(0, 0, 0, 0.05);
                    }
                }
            }
        }
    }
`;

function Component({ customer, ...props }) {
    const context = useContext(Context);
    const [pricelists, setPricelists] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        initialize();
    }, []);

    async function initialize() {
        setPricelists(
            await Api.getBrandPricelists({
                brandId: customer.brand.id,
            }),
        );
        setProducts(
            await Api.getCustomerProducts({
                customerId: customer.id,
            }),
        );

        setLoading(false);
    }

    function getPricelists() {
        return [
            {
                title: "Prislistor",
                value: "",
            },
        ].concat(
            pricelists.map((pricelist, index) => {
                return {
                    title: pricelist.name,
                    value: index,
                };
            }),
        );
    }

    function pricelistChanged(value) {
        if (pricelists[value]) {
            setLoading(true);
            setProducts(
                products.map((product) => {
                    const match = pricelists[value].products.find((p) => {
                        return p.name === product.name;
                    });
                    if (match) {
                        return {
                            ...product,
                            partnerMultiplier: match.partnerMultiplier,
                            customerMultiplier: match.customerMultiplier,
                            enabled: match.enabled,
                        };
                    }
                    return product;
                }),
            );

            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    }

    async function saveProductSettings() {
        setSaving(true);
        const time = Date.now();

        await Api.updateCustomerProducts({
            customerId: customer.id,
            products: products.map((product) => {
                return {
                    id: product.id,
                    partnerMultiplier: product.partnerMultiplier,
                    customerMultiplier: product.customerMultiplier,
                    enabled: product.enabled,
                };
            }),
        });

        setTimeout(
            () => {
                setSaving(false);
            },
            1000 - Date.now() + time,
        );
    }

    function updateProductPartnerMultiplier(productId, value) {
        setProducts(
            products.map((product) => {
                if (product.id === productId) {
                    product.partnerMultiplier = value;
                }
                return product;
            }),
        );
    }
    function updateProductCustomerMultiplier(productId, value) {
        setProducts(
            products.map((product) => {
                if (product.id === productId) {
                    product.customerMultiplier = value;
                }
                return product;
            }),
        );
    }
    function updateProductAvailability(productId, value) {
        setProducts(
            products.map((product) => {
                if (product.id === productId) {
                    product.enabled = value;
                }
                return product;
            }),
        );
    }

    if (loading) {
        return (
            <Container>
                <div className="loader">
                    <Loader />
                </div>
            </Container>
        );
    } else {
        return (
            <Container>
                <div className="pricelists">
                    <div className="label">Hämta priser från</div>
                    <Input
                        type="dropdown"
                        onChange={pricelistChanged}
                        options={getPricelists()}
                    />
                </div>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Partner</th>
                            <th>Kund</th>
                            <th>Aktiverad</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products
                            .sort((a, b) => {
                                if (
                                    a.name.indexOf("UPS") === 0 &&
                                    b.name.indexOf("UPS") === 0
                                ) {
                                    if (
                                        a.name.indexOf("Zendr") > 0 &&
                                        b.name.indexOf("Zendr") < 0
                                    ) {
                                        return 1;
                                    } else if (
                                        a.name.indexOf("Zendr") < 0 &&
                                        b.name.indexOf("Zendr") > 0
                                    ) {
                                        return -1;
                                    } else {
                                        if (a.name < b.name) {
                                            return -1;
                                        } else if (a.name > b.name) {
                                            return 1;
                                        }
                                    }
                                    return 0;
                                }
                            })
                            .map((product) => {
                                return (
                                    <tr key={product.id} className="product">
                                        <th>
                                            <div className="wrapper">
                                                <div className="name">
                                                    {product.name}
                                                </div>
                                                {product.name.indexOf("UPS") ===
                                                    0 &&
                                                    product.name.indexOf(
                                                        "Zendr",
                                                    ) > 0 &&
                                                    product.customerMultiplier ===
                                                        0 && (
                                                        <div className="note">
                                                            Använder
                                                            landsspecifika
                                                            förinställda procent
                                                        </div>
                                                    )}
                                            </div>
                                        </th>
                                        <td>
                                            <div className="input">
                                                <Input
                                                    onChange={(value) => {
                                                        updateProductPartnerMultiplier(
                                                            product.id,
                                                            value,
                                                        );
                                                    }}
                                                    type="number"
                                                    min={0}
                                                    value={
                                                        product.partnerMultiplier
                                                    }
                                                    unit="%"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="input">
                                                <Input
                                                    onChange={(value) => {
                                                        updateProductCustomerMultiplier(
                                                            product.id,
                                                            value,
                                                        );
                                                    }}
                                                    type="number"
                                                    min={0}
                                                    value={
                                                        product.customerMultiplier
                                                    }
                                                    unit="%"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="input">
                                                <Input
                                                    onChange={(value) => {
                                                        updateProductAvailability(
                                                            product.id,
                                                            value,
                                                        );
                                                    }}
                                                    type="toggle"
                                                    value={product.enabled}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
                <div className="buttons">
                    <button
                        className="c-button c-button--raised"
                        disabled={saving}
                        onClick={saveProductSettings}
                    >
                        Spara produktinställningar
                    </button>
                </div>
            </Container>
        );
    }
}

export default withRouter(Component);
