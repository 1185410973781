import Loader from "components/Loader/Loader";
import Input from "components/OldInput";
import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    > .title {
        font-size: 2rem;
        padding: 1rem;
    }
    > .description {
        padding: 0 1rem;
    }

    > .form {
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    > .buttons {
        padding: 1rem;
    }

    ul {
        padding: 1rem 0;
        > li {
            list-style-type: disc;
            list-style-position: inside;
            margin-left: 2rem;
        }
    }

    a {
        color: #0b0bc2;

        &:hover {
            text-decoration: underline;
        }
    }
`;

function Component(props) {
    const context = useContext(Context);
    const [data, setData] = useState({});
    const [plans, setPlans] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadIntegration();
    }, []);

    useEffect(() => {
        if (plans && plans.length) {
            setData({
                type: "VismaAdministration",
                planId: plans[0].id,
            });
        }
    }, [plans]);

    async function loadIntegration() {
        setPlans(
            await Api.getIntegrationPlans({
                integrationId: 4,
            }),
        );
    }

    async function addIntegration() {
        try {
            setLoading(true);
            await Api.createCustomerIntegration({
                customerId: context.user.customer.id,
                data: data,
            });
            context.setUser(await Api.getCurrentUser());
            props.history.push("/addons");
        } catch (error) {
            setErrorMessage(
                "Vi kunde inte aktivera integrationen med din aktiveringskod. Verifiera att du gjort rätt och testa igen.",
            );
            setLoading(false);
        }
    }

    if (plans === null) {
        return <Loader />;
    }

    return (
        <Container>
            <div className="title">Lägg till Visma Administration</div>
            <div className="description">
                Med vår koppling mot VismaAdministration kan ni enkelt hämta de
                beställningar ni vill. Uppgifterna skrivs in automatiskt i de
                olika fälten och ni får snabbt upp pris.
            </div>
            <ul className="steps">
                <li>
                    Ladda ner och installera vår{" "}
                    <a
                        target="_blank"
                        href="https://github.com/MKM-Group-AB/zendr-electron-release/releases/latest"
                    >
                        Zendr-Integration-Setup.exe
                    </a>
                </li>
                <li>Aktivera integrationen med knappen nedan.</li>
                <li>
                    Använd integrations-nyckeln ni får efter aktiveringen och
                    lägg in den i windows-clientens inställningar.
                </li>
            </ul>
            {plans.length > 0 && (
                <>
                    <div className="form">
                        <Input
                            type="list"
                            onChange={setData}
                            object={{
                                type: {
                                    type: "text",
                                    hidden: true,
                                    value: "Visma Administration",
                                },
                                planId: {
                                    type: "text",
                                    hidden: true,
                                    title: "Prenumerationstyp",
                                    value: plans[0].id,
                                },
                                // planId: {
                                // 	type: MultiToggle,
                                // 	title: 'Prenumerationstyp',
                                // 	options: plans.map((plan) => {
                                // 		return {
                                // 			title: `${plan.price} SEK / ${plan.paymentInterval}`,
                                // 			value: plan.id
                                // 		}
                                // 	})
                                // }
                            }}
                        />
                    </div>
                    <div className="buttons">
                        <button
                            className="c-button c-button--raised"
                            disabled={loading}
                            onClick={addIntegration}
                        >
                            Aktivera
                        </button>
                    </div>
                </>
            )}
            {errorMessage && <div className="error">{errorMessage}</div>}
        </Container>
    );
}

export default Component;
