import moment from "moment";
import React, { useContext, useRef, useState } from "react";
import styled from "styled-components/macro";

import Input from "components/OldInput";
import Context from "context/Global";
import Api from "services/Api/Api";
import CompanyForm from "views/Drafts/Create/Route/CompanyForm";

let Container = styled.div`
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
    width: 100%;
    max-width: 600px;

    > .title {
        font-size: 32px;
        color: #aaa;
        margin-bottom: 16px;
    }

    > .address {
        margin: 16px 0;
    }

    > .error {
        margin-top: 16px;
        color: #d77;
        font-weight: bold;
    }
`;

let timeOptions = [];
for (var i = 8; i < 19; i++) {
    let hours = ("0" + i + "").slice(-2);
    timeOptions.push({
        title: hours + ":00",
    });
    if (hours < 18) {
        timeOptions.push({
            title: hours + ":30",
        });
    }
}

function Component(props) {
    const context = useContext(Context);
    const [otherPickupAddress, setOtherPickupAddress] = useState(false);
    const [pickup, setPickup] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const packagesRef = useRef();
    const companyRef = useRef();

    async function bookPickup() {
        if (packagesRef.current.validate()) {
            let packages = packagesRef.current.value();
            if (packages.totalWeight === 0) {
                packagesRef.current.setValidationMessage({
                    key: "totalWeight",
                    message: "Vikten måste vara större än 0",
                });
            } else {
                // order pickup for packages.
                let pickupLocation = {
                    addressLine1: context.user.customer.addressLine1,
                    addressLine2: context.user.customer.addressLine2,
                    postalTown: context.user.customer.postalTown,
                    postalCode: context.user.customer.postalCode,
                    countryCode: context.user.customer.countryCode,
                    contact: {
                        name: context.user.name,
                        company: context.user.customer.name,
                        email: context.user.email,
                        phoneNumber: context.user.phoneNumber,
                    },
                };

                if (otherPickupAddress) {
                    pickupLocation = companyRef.current.value();
                }

                try {
                    let pickup = await Api.bookPickup({
                        packages: {
                            count: packages.count,
                            totalWeight: packages.totalWeight,
                        },
                        location: pickupLocation,
                        instructions: packages.instructions,
                        timeInterval: {
                            start:
                                packages.date +
                                " " +
                                packages.startTime +
                                ":00",
                            end: packages.date + " " + packages.endTime + ":00",
                        },
                        serviceName: "fedex",
                    });

                    setPickup(pickup);
                } catch (error) {
                    setErrorMessage(error.response.data.message);
                }
            }
        }
    }

    if (pickup) {
        return (
            <Container>
                <div className="title">Fedex</div>
                Din upphämtning är bokad med bokningsnummer{" "}
                {pickup.confirmationNumber}.
            </Container>
        );
    }

    return (
        <Container>
            <div className="title">Fedex</div>
            <Input
                ref={packagesRef}
                type="list"
                object={{
                    count: {
                        title: "Antal paket",
                        type: "number",
                        min: 1,
                        unit: "st",
                        required: true,
                        message_required:
                            "Ange antalet paket ni vill ska hämtas upp",
                        message_minlimit:
                            "Antalet paket måste vara 1 eller fler",
                    },
                    totalWeight: {
                        title: "Total vikt",
                        type: "number",
                        min: 0,
                        unit: "kg",
                        required: true,
                        message_required: "Ange totala vikten för alla paket",
                        message_minlimit: "Vikten måste vara större än 0",
                    },
                    date: {
                        title: "Datum",
                        type: "date",
                        unit: "kg",
                        required: true,
                        value: moment().format("YYYY-MM-DD"),
                    },
                    startTime: {
                        title: "Från",
                        type: "dropdown",
                        options: timeOptions,
                    },
                    endTime: {
                        title: "Till",
                        type: "dropdown",
                        options: timeOptions,
                        value: timeOptions[timeOptions.length - 1].title,
                    },
                    instructions: {
                        title: "Instruktioner",
                        type: "textarea",
                    },
                }}
            />
            <div className="address">
                {!!otherPickupAddress && (
                    <CompanyForm
                        ref={companyRef}
                        disableSaveButton={true}
                        company={{
                            ...context.user.customer,
                            contact: {
                                ...context.user,
                                company: context.user.customer.name,
                            },
                        }}
                    />
                )}
                {!otherPickupAddress && (
                    <div className="text">
                        Upphämtning bokas till{" "}
                        <b>{context.user.customer.name}</b> på{" "}
                        <b>{context.user.customer.addressLine1}</b>.
                    </div>
                )}
            </div>
            <div className="buttons">
                <button
                    onClick={bookPickup}
                    className="c-button c-button--raised"
                >
                    Boka upphämtning
                </button>
                {!otherPickupAddress && (
                    <button
                        className="c-button c-button--ghost"
                        onClick={() => setOtherPickupAddress(true)}
                    >
                        Ändra adress
                    </button>
                )}
            </div>
            {errorMessage && (
                <div className="error">
                    <div className="message">
                        Ett fel uppstod, kontrollera att informationen ovan är
                        korrekt och försök igen. Ta gärna kontakt med vår
                        kundservice om ni vill ha hjälp!
                    </div>
                </div>
            )}
        </Container>
    );
}

export default Component;
