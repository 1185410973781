import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import { toast } from "react-hot-toast";
import { getIntegrationExpiryStatusById } from "../../../../utils/ExpiryIntegrationsUtils";
import Alert, { AlertTypes } from "../../../../components/Alerts/Alert";
import FortnoxReAuth from "../../../Orders/Imported/IntegrationsReAuth/FortnoxReAuth";
import IntegrationStatuses from "../../../../constants/integrations/IntegrationStatuses";

const Switch = ({ label, isToggled, onToggle }) => (
    <div className="flex flex-col mb-4">
        <label className="font-medium mb-2">{label}:</label>
        <div className="flex items-center">
            <label className="switch">
                <input
                    type="checkbox"
                    checked={isToggled}
                    onChange={onToggle}
                />
                <span className="slider"></span>
            </label>
        </div>
    </div>
);

const Select = ({ label, options, value, onChange }) => (
    <div className="flex flex-col mb-4">
        <label className="font-medium">{label}:</label>
        <select
            className="w-4/5 mt-1 p-2 rounded border"
            value={value}
            onChange={onChange}
        >
            {options.map((option, index) => (
                <option key={index} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    </div>
);

const Button = ({ label, onClick, disabled = false, color = "default" }) => {
    const baseClass = "py-2 px-4 rounded";
    let colorClass;

    switch (color) {
        case "red":
            colorClass = disabled
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-bad text-white hover:bg-bad-darken opacity-90";
            break;
        case "green":
            colorClass = disabled
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-good text-white hover:bg-good-darken opacity-90";
            break;
        default:
            colorClass = disabled
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-button-color text-white hover:bg-button-color-darken opacity-90";
    }

    return (
        <button
            className={`${baseClass} ${colorClass}`}
            disabled={disabled}
            onClick={onClick}
        >
            {label}
        </button>
    );
};

export function FortnoxIntegration({
    history,
    integration: initialIntegration,
}) {
    const context = useContext(Context);
    const [integration, setIntegration] = useState(initialIntegration);
    const [testingIntegration, setTestingIntegration] = useState(false);
    const [savingIntegrationSettings, setSavingIntegrationSettings] =
        useState(false);
    const [expireNotification, setExpireNotification] = useState(null);

    useEffect(() => {
        setExpireNotification(
            getIntegrationExpiryStatusById(
                context?.integrationsExpiry,
                integration.id,
            ),
        );
    }, []);

    async function removeIntegration() {
        if (!confirm("Är du säker på att du vill ta bort integrationen?")) {
            return;
        }

        try {
            await Api.removeCustomerIntegration({
                customerId: context.user.customer.id,
                integrationId: integration.id,
            });

            const updatedIntegrations =
                context.user.customer.integrations.filter(
                    (i) => i.id !== integration.id,
                );
            const newUser = {
                ...context.user,
                customer: {
                    ...context.user.customer,
                    integrations: {
                        ...updatedIntegrations,
                    },
                },
            };
            context.setUser(newUser);

            history.push("/addons");
        } catch (error) {
            toast.error("Något gick fel när integrationen skulle tas bort.");
        }
    }

    async function testIntegration() {
        setTestingIntegration(true);

        // TODO: Send request to integration service instead.
        const response = await Api.testCustomerIntegration({
            integrationId: integration.id,
        });

        if (response.status === "OK") {
            alert("Integrationen fungerar som den ska.");
        } else {
            if (
                confirm(
                    "Tyvärr fungerar inte integrationen som det är tänkt. Vill du koppla om integrationen?",
                )
            ) {
                openFortnoxConnectPage();
            }
        }

        setTestingIntegration(false);
    }

    function openFortnoxConnectPage() {
        const windowRef = window.open(
            `https://apps.fortnox.se/oauth-v1/auth?client_id=${process.env.REACT_APP_FORTNOX_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FORTNOX_OAUTH_REDIRECT_URL}&scope=article%20order%20customer&state=random%20string&access_type=offline&response_type=code`,
            "Connect to Fortnox",
            "width=1000,height=800",
        );

        var timer = setInterval(async function () {
            if (windowRef?.closed) {
                clearInterval(timer);
                context.setUser(await Api.getCurrentUser());
                history.push("/addons");
            }
        }, 1000);
    }

    async function updateIntegrationSettings(newSettings) {
        setIntegration((prevIntegration) => {
            return {
                ...prevIntegration,
                data: {
                    ...prevIntegration.data,
                    settings: {
                        ...prevIntegration.data.settings,
                        ...newSettings,
                    },
                },
            };
        });
    }

    async function saveIntegrationSettings() {
        setSavingIntegrationSettings(true);
        try {
            await Api.updateIntegration({
                integrationId: integration.id,
                data: { settings: integration.data.settings },
            });
            toast.success("Inställningar sparade", {
                duration: 2000,
            });
        } catch (error) {
            toast.error(
                "Något gick fel när inställningarna skulle sparas. Försök igen.",
            );
        } finally {
            setTimeout(() => {
                setSavingIntegrationSettings(false);
            }, 2000);
        }
    }

    return (
        <div className="p-6 bg-gray-100">
            <h1 className="text-3xl font-bold mb-4">Fortnox Integration</h1>
            {expireNotification && (
                <Alert
                    type={
                        expireNotification?.status ===
                        IntegrationStatuses.EXPIRED
                            ? AlertTypes.ERROR
                            : AlertTypes.WARNING
                    }
                    className={"mt-3 mb-3"}
                >
                    Du behöver uppdatera din integration.
                </Alert>
            )}
            {/* Allmänna inställningar */}
            <div className="bg-white p-4 rounded-lg shadow-md mb-4 flex">
                <div className="w-4/5 pr-4">
                    <h2 className="text-xl font-bold mb-2">
                        Allmänna inställningar
                    </h2>
                    <Switch
                        label="Importera även fakturerade ordrar"
                        isToggled={
                            integration?.data?.settings?.includeInvoicedOrders
                        }
                        onToggle={() =>
                            updateIntegrationSettings({
                                includeInvoicedOrders:
                                    !integration?.data?.settings
                                        ?.includeInvoicedOrders,
                            })
                        }
                    />
                    <Switch
                        label="Importera endast ordrar med ett värde större än 0"
                        isToggled={
                            integration?.data?.settings?.requireOrderValue
                        }
                        onToggle={() =>
                            updateIntegrationSettings({
                                requireOrderValue:
                                    !integration?.data?.settings
                                        ?.requireOrderValue,
                            })
                        }
                    />
                    <Switch
                        label="Importera endast ordrar efter att orderbekräftelse skickats"
                        isToggled={
                            integration?.data?.settings?.fetchOnlySentOrders
                        }
                        onToggle={() =>
                            updateIntegrationSettings({
                                fetchOnlySentOrders:
                                    !integration?.data?.settings
                                        ?.fetchOnlySentOrders,
                            })
                        }
                    />
                    <Select
                        label="Leveransalternativ"
                        options={[
                            {
                                label: "Kundinformation",
                                value: "CustomerDetails",
                            },
                            {
                                label: "Leveransinformation",
                                value: "DeliveryDetails",
                            },
                        ]}
                        value={integration?.data?.settings?.deliveryOptions}
                        onChange={(e) =>
                            updateIntegrationSettings({
                                deliveryOptions: e.target.value,
                            })
                        }
                    />
                    <Select
                        label="Godsbeskrivning"
                        options={[
                            { label: "Artikelnummer", value: "ArticleNumber" },
                            { label: "Benämning", value: "Name" },
                        ]}
                        value={integration?.data?.settings?.packageDescription}
                        onChange={(e) =>
                            updateIntegrationSettings({
                                packageDescription: e.target.value,
                            })
                        }
                    />
                    <Select
                        label="Referens"
                        options={[
                            { label: "Er referens", value: "YourReference" },
                            {
                                label: "Ert ordernummer",
                                value: "YourOrderNumber",
                            },
                            { label: "Ingen referens", value: "NoReference" },
                        ]}
                        value={integration?.data?.settings?.reference}
                        onChange={(e) =>
                            updateIntegrationSettings({
                                reference: e.target.value,
                            })
                        }
                    />
                    <Button
                        label={
                            savingIntegrationSettings
                                ? "Sparar inställningar..."
                                : "Spara inställningar"
                        }
                        disabled={savingIntegrationSettings}
                        onClick={saveIntegrationSettings}
                    />
                </div>

                {/* Configuration Explanation Box */}
                <div className="w-4/5 bg-white ">
                    <h2 className="text-xl font-bold mb-2">
                        Inställningsförklaringar
                    </h2>
                    <p className="text-sm text-gray-700 mb-8">
                        Dessa inställningar hämtas från varje order och kommer
                        att gälla för samtliga ordrar som er organisation har i
                        Fortnox. Den hämtade datan fördefinierar en "Ny
                        försändelse".
                    </p>
                    <ul className="text-sm text-gray-500 space-y-4">
                        <li>
                            <strong>Importera även fakturerade ordrar:</strong>{" "}
                            Om denna inställning är aktiverad kommer även
                            fakturerade ordrar att inkluderas vid importering.
                            Detta är användbart för att hålla reda på alla
                            ordrar, oavsett faktureringsstatus.
                        </li>
                        <li>
                            <strong>
                                Importera endast ordrar med ett värde större än
                                0:
                            </strong>{" "}
                            Om denna inställning är aktiverad, kommer enbart
                            ordrar med ett totalt värde större än noll att
                            importeras. Detta kan hjälpa till att filtrera ut
                            test- eller ogiltiga ordrar.
                        </li>
                        <li>
                            <strong>
                                Importera endast ordrar efter att
                                orderbekräftelse skickats:
                            </strong>{" "}
                            Med denna inställning aktiverad, importeras ordrar
                            enbart efter att en orderbekräftelse har skickats
                            till kunden.
                        </li>
                        <li>
                            <strong>Leveransalternativ:</strong> Denna
                            inställning låter dig välja vilka uppgifter som ska
                            användas för leveransadressen. Du kan antingen
                            använda kundens generella uppgifter eller specifika
                            leveransuppgifter för varje order.
                        </li>
                        <li>
                            <strong>Godsbeskrivning:</strong> Den här
                            inställningen bestämmer hur paketen ska beskrivas
                            under transporten. Du kan välja mellan att använda
                            artikelnummer eller artikelns namn som beskrivning.
                        </li>
                        <li>
                            <strong>Referens:</strong> Denna inställning används
                            för att ange vilken typ av referensinformation som
                            ska bifogas med varje försändelse. Det kan vara en
                            intern referens från er organisation, det specifika
                            ordernumret, eller ingen referens alls.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="flex gap-4">
                <Button
                    label="Ta bort"
                    color="red"
                    disabled={testingIntegration}
                    onClick={removeIntegration}
                />
                <Button
                    label={
                        testingIntegration
                            ? "Testar koppling..."
                            : "Testa koppling"
                    }
                    color="green"
                    disabled={testingIntegration}
                    onClick={testIntegration}
                />
                {expireNotification && (
                    <FortnoxReAuth
                        className={
                            "py-2 px-4 rounded bg-good text-white hover:bg-good-darken opacity-90"
                        }
                    />
                )}
            </div>
        </div>
    );
}

export default FortnoxIntegration;
