import React from "react";
import { formatDateString, validateDates } from "utils/batchTableUtils";

const ProductDates = ({
    pickupDate,
    deliveryDate,
    requestedPickupDate,
    service,
    blackText,
}) => {
    const isValid = validateDates(pickupDate, deliveryDate);

    const textColor = blackText ? "text-black" : "text-brown";

    function renderDates() {
        if (requestedPickupDate) {
            if (!isValid) {
                return <p className={textColor}>Estimerat datum saknas</p>;
            } else {
                return (
                    <div className="flex flex-col whitespace-normal items-start text-sm">
                        {service.toLowerCase() === "postnord" ? (
                            <span className="text-brown flex flex-col text-left">
                                <p>Ingen upphämtning</p>
                                <p>Upphämtning bokar du via huvudmenyn.</p>
                            </span>
                        ) : (
                            <>
                                <span className="flex text-left gap-1">
                                    {pickupDate ? (
                                        <>
                                            <p>Est. Upphämtning:</p>
                                            <p>
                                                {formatDateString(pickupDate)}
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <p>Önskad Upphämtning:</p>
                                            <p>
                                                {formatDateString(
                                                    requestedPickupDate,
                                                )}
                                            </p>
                                        </>
                                    )}
                                </span>
                                {deliveryDate && (
                                    <span className="flex text-left gap-1">
                                        <p>Est. Leverans:</p>
                                        <p>{formatDateString(deliveryDate)}</p>
                                    </span>
                                )}
                            </>
                        )}
                    </div>
                );
            }
        } else {
            return (
                <>
                    <div className="flex flex-col whitespace-normal items-start text-sm">
                        <p className={textColor}>Ingen upphämtning</p>
                        {service.toLowerCase() === "postnord" && (
                            <span className="text-brown flex justify-start flex-wrap text-left gap-1">
                                Upphämtning bokar du via huvudmenyn.
                            </span>
                        )}
                    </div>
                </>
            );
        }
    }

    return <>{renderDates()}</>;
};

export default ProductDates;
