import React from "react";
import styled from "styled-components";

const HelpTextContainer = styled.div`
    display: inline-block;
    justify-content: center;
    align-items: center;
    position: relative;

    > .helpText {
        position: relative;
        display: inline-block;
        margin-left: 8px;
    }

    .text {
        position: absolute;
        padding: 10px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        left: 100%;
        top: 50%;
        transform: translateX(10px) translateY(-50%);
        transition: opacity 0.3s ease;
        opacity: 0;
        pointer-events: none;
        z-index: 1000;
        text-align: left;
        max-width: 500px;
        width: auto;
        white-space: pre-wrap;
        min-height: 50px;
    }

    &:hover .text {
        opacity: 1;
        pointer-events: all;
    }

    .icon {
        width: 12px;
        height: 12px;
        padding: 4px;
        box-sizing: content-box;
        cursor: pointer;
    }
`;

const HelpText = ({ helpText }) => {
    return (
        <HelpTextContainer className="helpText">
            <div className="text">{helpText}</div>
            <svg
                className="icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z" />
            </svg>
        </HelpTextContainer>
    );
};

export default HelpText;
