import React from "react";

const InfoModal = ({ packagesLength, bookBatchDirectWithoutQuote }) => {
    return (
        <div
            className={
                packagesLength === 1
                    ? "absolute top-7 left-28 ml-2 w-64 bg-white p-4 border rounded-lg shadow-lg whitespace-normal"
                    : "absolute top-7 left-12 ml-2 w-64 bg-white p-4 border rounded-lg shadow-lg whitespace-normal"
            }
        >
            <p className="text-sm font-normal">
                {bookBatchDirectWithoutQuote && (
                    <span>
                        Med enbart vikt går det endast att göra en
                        direktbokning.{" "}
                    </span>
                )}
                <span>Fyll i dimensioner för att söka pris.</span>
            </p>
        </div>
    );
};

export default InfoModal;
