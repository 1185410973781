import Input from "components/OldInput";
import Loader from "components/Loader/Loader";
import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";

function Component(props) {
    const context = useContext(Context);
    const [data, setData] = useState({});
    const [plans, setPlans] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadIntegration();
    }, []);

    async function loadIntegration() {
        setPlans(
            await Api.getIntegrationPlans({
                integrationId: 1,
            }),
        );
    }

    function openFortnoxConnectPage(ev) {
        ev.preventDefault();
        const windowRef = window.open(
            `https://apps.fortnox.se/oauth-v1/auth?client_id=${process.env.REACT_APP_FORTNOX_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FORTNOX_OAUTH_REDIRECT_URL}&scope=article%20order%20customer&state=random%20string&access_type=offline&response_type=code`,
            "Connect to Fortnox",
            "width=1000,height=800",
        );

        var timer = setInterval(async function () {
            if (windowRef?.closed) {
                clearInterval(timer);
                context.setUser(await Api.getCurrentUser());
                props.history.push("/addons");
            }
        }, 1000);
    }

    if (plans === null) {
        return <Loader />;
    }

    return (
        <div className="p-4">
            <div className="text-2xl pb-4">Lägg till Fortnox</div>
            <div className="pb-4">
                Genom att koppla till Fortnox kan ni automatiskt importera era
                beställningar. Detta gör att alla relevanta fält fylls i åt er,
                och ni får snabbt en prisöversikt.
            </div>
            <ul className="list-disc list-inside pb-4">
                <li>
                    Tryck på "Aktivera" för att öppna
                    Fortnox-inloggningsfönstret.
                </li>
                <li>
                    Följ de steg-för-steg instruktioner som visas i det nya
                    fönstret för att slutföra kopplingen.
                </li>
            </ul>
            {plans.length > 0 && (
                <>
                    <div className="flex flex-col items-start pb-4">
                        <Input
                            type="list"
                            onChange={setData}
                            object={{
                                type: {
                                    type: "text",
                                    hidden: true,
                                    value: "Fortnox",
                                },
                                planId: {
                                    type: "text",
                                    hidden: true,
                                    title: "Prenumerationstyp",
                                    value: plans[0].id,
                                },
                            }}
                        />
                    </div>
                    <div className="pb-4">
                        <button
                            className="bg-button-color hover:bg-button-color-darken opacity-90 text-white font-bold py-2 px-4 rounded"
                            disabled={loading}
                            onClick={openFortnoxConnectPage}
                        >
                            Aktivera
                        </button>
                    </div>
                </>
            )}
            {errorMessage && (
                <div className="text-red-500 font-bold">{errorMessage}</div>
            )}
        </div>
    );
}

export default Component;
