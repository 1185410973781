import Input from "components/OldInput";
import axios from "axios";
import Loader from "components/Loader/Loader";
import Context from "context/Global";
import moment from "moment";
import "moment/locale/sv";
import React, { useContext, useEffect, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import ImportDialog from "./Dialog";
import OrderRow from "./row";
import toast from "react-hot-toast";
import NotificationHandler from "./IntegrationExpireNotifications/NotificationHandler";
import Integrations from "../../../constants/integrations";
import {
    getClosestExpireSoonIntegration,
    hasExpireSoonIntegration,
} from "../../../utils/ExpiryIntegrationsUtils";
import Alert, { AlertTypes } from "../../../components/Alerts/Alert";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;

    > .loader {
        width: 200px;
        height: 200px;
    }

    > .header {
        padding: 1em;
        display: flex;
        flex-direction: row;
        align-items: center;

        > .input {
            display: flex;
            margin-right: 1rem;
            align-items: center;

            > .title {
                padding-right: 1rem;
            }
        }

        .pagination {
            display: flex;
            align-items: center;
            margin-left: auto;
            cursor: pointer;

            > .previous,
            > .next {
                width: 16px;
                height: 16px;
                padding: 0 8px;
                box-sizing: content-box;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &.is-disabled {
                    color: #aaa;
                    pointer-events: none;
                }

                > svg {
                    width: 100%;
                    height: 100%;
                }
            }
            > .previous {
                transform: rotateZ(180deg);
            }
        }
    }

    > table.orders {
        width: 100%;
        margin-bottom: 16px;
        flex-direction: column;

        > thead {
            > tr {
                > th {
                    background: #fafafa;
                    padding: 1em 0;
                    border-bottom: 1px solid #ddd;
                    border-top: 1px solid #ddd;
                    color: #333;
                    font-weight: normal;

                    &:first-child {
                        padding-left: 1em;
                    }
                    &:last-child {
                        padding-right: 1em;
                    }
                }
            }
        }

        > tbody {
            > tr {
                > td {
                    background: #fff;
                    padding: 0.5em 0;

                    &:first-child {
                        padding-left: 1em;
                    }
                    &:last-child {
                        padding-right: 1em;
                    }

                    &.service {
                        > img {
                            width: 32px;
                            object-size: contain;
                        }
                    }
                }

                &:hover > td {
                    background: rgba(250, 250, 250, 1);
                }
            }

            > .emptyState {
                > td {
                    text-align: center !important;
                    font-style: italic;
                }
            }
        }
    }

    .receiver {
        display: flex;
        flex-direction: column;

        > .title {
            font-weight: bold;
        }
    }

    > .buttons {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    > .links {
        margin-top: 16px;
        text-align: center;

        > a {
            text-decoration: none;
            color: rgba(0, 0, 0, 0.7);
            padding: 8px 16px;
            display: inline-block;
            font-size: 10px;
            font-weight: bold;
        }
    }
`;

function Component() {
    const context = useContext(Context);
    const cancelSearchTokenRef = useRef();
    const [orders, setOrders] = useState([]);
    const [loadingList, setLoadingList] = useState(true);
    const [importingOrders, setImportingOrders] = useState(false);
    const [searchstring, setSearchstring] = useState("");
    const [limit, setLimit] = useState(200);
    const [offset, setOffset] = useState(0);
    const [totalOrders, setTotalOrders] = useState(0);
    const [startDate, setStartDate] = useState(
        sessionStorage.getItem("orderListStartDate") || "",
    );
    const [endDate, setEndDate] = useState(
        sessionStorage.getItem("orderListEndDate") || "",
    );
    const [dateInterval, setDateInterval] = useState(
        sessionStorage.getItem("orderListDateInterval") || "all",
    );

    const startDateRef = useRef();
    const endDateRef = useRef();
    const dateIntervalRef = useRef();
    const [showImportDialog, setShowImportDialog] = useState(false);

    const expireSoonIntegration = getClosestExpireSoonIntegration(
        context?.integrationsExpiry,
    );

    useEffect(() => {
        context.checkIntegrationsExpiry().then(() => {
            loadOrders();
        });
    }, []);

    async function loadOrders() {
        setLoadingList(true);

        if (cancelSearchTokenRef.current) {
            cancelSearchTokenRef.current.cancel();
        }
        cancelSearchTokenRef.current = axios.CancelToken.source();

        const { orders, count } = await Api.getCustomerImportedOrders({
            customerId: context.user.customer.id,
            query: searchstring,
            startDate: startDate,
            endDate: endDate,
            limit: limit,
            offset: offset,
            cancelToken: cancelSearchTokenRef.current.token,
        });

        setOrders(orders);
        setTotalOrders(count);
        setLoadingList(false);
    }

    useEffect(() => {
        loadOrders();
        // Check if date interval matches an interval in the dropdown
        if (
            startDate === endDate &&
            startDate === moment().format("YYYY-MM-DD")
        ) {
            dateIntervalRef.current.set("today");
        } else if (
            startDate === endDate &&
            startDate === moment().subtract(1, "day").format("YYYY-MM-DD")
        ) {
            dateIntervalRef.current.set("yesterday");
        } else if (
            startDate === moment().startOf("isoWeek").format("YYYY-MM-DD") &&
            endDate === moment().endOf("isoWeek").format("YYYY-MM-DD")
        ) {
            dateIntervalRef.current.set("week");
        } else if (
            startDate ===
                moment()
                    .startOf("isoWeek")
                    .subtract(1, "week")
                    .format("YYYY-MM-DD") &&
            endDate ===
                moment()
                    .endOf("isoWeek")
                    .subtract(1, "week")
                    .format("YYYY-MM-DD")
        ) {
            dateIntervalRef.current.set("lastWeek");
        } else if (startDate === "" && endDate === "") {
            dateIntervalRef.current.set("all");
        } else {
            dateIntervalRef.current.set("manual");
        }
    }, [searchstring, startDate, endDate, offset, limit]);

    useEffect(() => {
        switch (dateInterval) {
            case "today": {
                setStartDate(moment().format("YYYY-MM-DD"));
                setEndDate(moment().format("YYYY-MM-DD"));
                startDateRef.current.set(moment().format("YYYY-MM-DD"));
                endDateRef.current.set(moment().format("YYYY-MM-DD"));
                break;
            }
            case "yesterday": {
                setStartDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
                setEndDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
                startDateRef.current.set(
                    moment().subtract(1, "day").format("YYYY-MM-DD"),
                );
                endDateRef.current.set(
                    moment().subtract(1, "day").format("YYYY-MM-DD"),
                );
                break;
            }
            case "week": {
                setStartDate(moment().startOf("isoWeek").format("YYYY-MM-DD"));
                setEndDate(moment().endOf("isoWeek").format("YYYY-MM-DD"));
                startDateRef.current.set(
                    moment().startOf("isoWeek").format("YYYY-MM-DD"),
                );
                endDateRef.current.set(
                    moment().endOf("isoWeek").format("YYYY-MM-DD"),
                );
                break;
            }
            case "lastWeek": {
                setStartDate(
                    moment()
                        .startOf("isoWeek")
                        .subtract(1, "week")
                        .format("YYYY-MM-DD"),
                );
                setEndDate(
                    moment()
                        .endOf("isoWeek")
                        .subtract(1, "week")
                        .format("YYYY-MM-DD"),
                );
                startDateRef.current.set(
                    moment()
                        .startOf("isoWeek")
                        .subtract(1, "week")
                        .format("YYYY-MM-DD"),
                );
                endDateRef.current.set(
                    moment()
                        .endOf("isoWeek")
                        .subtract(1, "week")
                        .format("YYYY-MM-DD"),
                );
                break;
            }
            case "all": {
                setStartDate("");
                setEndDate("");
                startDateRef.current.set("");
                endDateRef.current.set("");
                break;
            }
        }
        sessionStorage.removeItem("orderListStartDate");
        sessionStorage.removeItem("orderListEndDate");
        sessionStorage.setItem("orderListDateInterval", dateInterval);
    }, [dateInterval]);

    async function toggleLimit() {
        if (limit < 200 && totalOrders > 100) {
            setLimit(200);
        } else if (limit < 500 && totalOrders > 200) {
            setLimit(500);
        } else if (limit < 1000 && totalOrders > 500) {
            setLimit(1000);
        } else if (limit < 5000 && totalOrders > 1000) {
            setLimit(5000);
        } else {
            setLimit(100);
        }
    }

    async function previousPage() {
        setOffset(offset - limit < 0 ? 0 : offset - limit);
    }
    async function nextPage() {
        if (offset + limit < totalOrders) {
            setOffset(offset + limit);
        }
    }

    async function triggerImportOrders() {
        setImportingOrders(true);
        try {
            await Api.triggerIntegrationImport({
                integrationIds: context.user.customer.integrations.map(
                    (integration) => integration.id,
                ),
            });
        } catch (error) {
            toast.error(
                "Det gick inte att importera beställningar från integrationer",
            );
        }
        setTimeout(() => {
            setImportingOrders(false);
            loadOrders();
        }, 500);
    }
    function closeImportDialog(reloadOrders) {
        setShowImportDialog(false);
        if (reloadOrders) {
            loadOrders();
        }
    }

    function showImportButton() {
        return !!context.user.customer.integrations?.find((integration) => {
            return (
                [Integrations.FORTNOX, Integrations.BUSINESS_CENTRAL].indexOf(
                    integration.type,
                ) >= 0
            );
        });
    }

    async function deleteOrder(order) {
        if (
            window.confirm(
                "Är du säker på att du vill ta bort den importerade ordern?",
            )
        ) {
            await Api.deleteImportedOrder({
                importedOrderId: order.id,
            });
            loadOrders();
        }
    }

    return (
        <Container>
            <div className="header">
                <div className="input">
                    <Input
                        type="text"
                        onChange={setSearchstring}
                        placeholder={"Sök..."}
                    />
                </div>
                <div className="input">
                    <div className="title">Från</div>
                    <Input
                        ref={startDateRef}
                        type="date"
                        value={startDate}
                        onChange={(value) => {
                            sessionStorage.removeItem("orderListDateInterval");
                            sessionStorage.setItem("orderListStartDate", value);
                            setStartDate(value);
                        }}
                    />
                </div>
                <div className="input">
                    <div className="title">Till</div>
                    <Input
                        ref={endDateRef}
                        type="date"
                        value={endDate}
                        onChange={(value) => {
                            sessionStorage.removeItem("orderListDateInterval");
                            sessionStorage.setItem("orderListEndDate", value);
                            setEndDate(value);
                        }}
                    />
                </div>
                <div className="input">
                    <div className="title">Byt intervall</div>
                    <Input
                        ref={dateIntervalRef}
                        type="dropdown"
                        value={dateInterval}
                        onChange={setDateInterval}
                        options={[
                            {
                                title: "Välj",
                                value: "manual",
                            },
                            {
                                title: "Dagens",
                                value: "today",
                            },
                            {
                                title: "Gårdagens",
                                value: "yesterday",
                            },
                            {
                                title: "Veckans",
                                value: "week",
                            },
                            {
                                title: "Föregående vecka",
                                value: "lastWeek",
                            },
                            {
                                title: "Visa alla försändelser",
                                value: "all",
                            },
                        ]}
                    />
                </div>
                {showImportButton() && (
                    <button
                        className="c-button c-button--raised"
                        disabled={importingOrders}
                        onClick={triggerImportOrders}
                    >
                        Importera
                    </button>
                )}
                {hasExpireSoonIntegration(context?.integrationsExpiry) && (
                    <Alert
                        type={AlertTypes.WARNING}
                        className={"ml-3 mt-3 font-bold"}
                    >
                        {expireSoonIntegration.daysUntilExpire} dagar kvar
                    </Alert>
                )}
                <div className="pagination">
                    <div
                        className={`previous ${
                            offset === 0 ? "is-disabled" : ""
                        }`}
                        onClick={previousPage}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path fill="currentColor" d="M6 0l12 12-12 12z" />
                        </svg>
                    </div>
                    <div className="showing" onClick={toggleLimit}>
                        Visar {totalOrders ? offset + 1 : 0} -{" "}
                        {totalOrders < offset + limit
                            ? totalOrders
                            : offset + limit}{" "}
                        av {totalOrders}
                    </div>
                    <div
                        className={`next ${
                            offset + limit > totalOrders ? "is-disabled" : ""
                        }`}
                        onClick={nextPage}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path fill="currentColor" d="M6 0l12 12-12 12z" />
                        </svg>
                    </div>
                </div>
            </div>
            <div className={"p-4"}>
                {context?.integrationsExpiry?.map((notification) => {
                    return (
                        <NotificationHandler
                            key={notification.id}
                            data={notification}
                        />
                    );
                })}
            </div>
            <table className="orders">
                <thead>
                    <tr>
                        <th>Källa</th>
                        <th>Externt ID</th>
                        <th>Referens</th>
                        <th>Importerad</th>
                        <th>Avsändare</th>
                        <th>Mottagare</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {loadingList && (
                        <tr>
                            <td>
                                <Loader />
                            </td>
                        </tr>
                    )}
                    {!loadingList && orders.length === 0 && (
                        <tr className="emptyState">
                            <td colSpan={100}>
                                Ingen försändelse kunde hittas i detta intervall
                            </td>
                        </tr>
                    )}
                    {!loadingList &&
                        orders.map((order, index) => {
                            return (
                                <OrderRow
                                    refresh={loadOrders}
                                    key={order.id}
                                    order={order}
                                    onDelete={deleteOrder}
                                />
                            );
                        })}
                </tbody>
            </table>
            {showImportDialog && <ImportDialog close={closeImportDialog} />}
        </Container>
    );
}

export default Component;
