import React, { useEffect, useState } from "react";
import Input from "../../../../../../components/OldInput";
import InputNumber from "../../../../../../components/Input/Number";
import { priceParser } from "../../../../../../helpers/StringParser";
import { calculateInsurancePrice } from "helpers/PostNord";
import { PN_MARGIN_MULTIPLIER } from "constants/carriers/postNord";

export function InsuranceInput({
    onInsuranceChange,
    packagesCount,
    onTermsAccepted,
    termsAccepted,
    setTotalInsuranceCost,
    totalInsuranceCost,
    insuranceMaxValue,
}) {
    const [insuranceValue, setInsuranceValue] = useState(0);
    const [addInsurance, setAddInsurance] = useState(false);

    useEffect(() => {
        if (!addInsurance) {
            setInsuranceValue(0);
            setTotalInsuranceCost(0);
            onInsuranceChange(0);
            onTermsAccepted(false);
        }
    }, [addInsurance]);

    const handleInsuranceValueChange = (value) => {
        if (!addInsurance) {
            return;
        }
        setInsuranceValue(value);
        const calculatedInsuranceCost =
            calculateInsurancePrice(value) * packagesCount;
        const insuranceCostWithMargin = Math.ceil(
            calculatedInsuranceCost * PN_MARGIN_MULTIPLIER,
        );

        setTotalInsuranceCost(insuranceCostWithMargin);
        onInsuranceChange(value);
    };

    return (
        <div className="w-full flex flex-col align-center ml-8">
            <div className="flex items-center mb-4">
                <Input
                    type="checkbox"
                    checked={addInsurance}
                    onChange={setAddInsurance}
                />
                <div className="ml-8 text-xl font-medium">
                    Jag vill försäkra min transport
                </div>
            </div>
            {addInsurance && (
                <div>
                    <h2 className="text-xl font-bold">Försäkringsvärde</h2>
                    <InputNumber
                        className="w-80 mb-2"
                        placeholder="0"
                        onChange={handleInsuranceValueChange}
                        min={0}
                        max={insuranceMaxValue}
                        value={insuranceValue}
                        unit="SEK"
                        messages={{
                            max: `Försäkringsvärdet får ej överstiga ${insuranceMaxValue} SEK`,
                        }}
                        autoValidate
                    />
                    {!!insuranceValue &&
                        !!totalInsuranceCost &&
                        totalInsuranceCost < insuranceMaxValue && (
                            <>
                                <div className="mb-8">
                                    Med ett försäkringsvärde på{" "}
                                    {priceParser(insuranceValue || 0)} SEK
                                    kostar försäkringen
                                    <b> {totalInsuranceCost} SEK</b>.
                                </div>

                                <div className="flex items-center">
                                    <Input
                                        type="checkbox"
                                        checked={termsAccepted}
                                        onChange={(accepted) => {
                                            onTermsAccepted(accepted);
                                        }}
                                    />
                                    <div className="ml-4">
                                        Jag förstår och accepterar{" "}
                                        <a
                                            href="https://www.postnord.se/siteassets/pdf/villkor/forsakringsomfattning-for-varuforsakring-avtalskunder-2023.pdf"
                                            target="_blank"
                                        >
                                            Postnord's villkor vid försäkring
                                        </a>{" "}
                                        samt att försäkringsvärdet inte kan
                                        överstiga tull-värdet.
                                    </div>
                                </div>
                            </>
                        )}
                </div>
            )}
        </div>
    );
}

export default InsuranceInput;
