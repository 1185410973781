import React, { useState } from "react";
import styled from "styled-components/macro";

import "moment/locale/sv";
import Input from "components/OldInput";
import Api from "services/Api/Api";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    > .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
        padding: 1rem;
        border-radius: 1rem;

        > .title {
            font-size: 2em;
        }

        > .form {
            width: 100%;
            max-width: 400px;
        }

        > .buttons {
            display: flex;
            padding: 1rem 0;
        }
    }
`;

function Component(props) {
    const [prospect, setProspect] = useState({});

    async function register() {
        const response = await Api.registerProspect({
            prospect,
        });

        Api.updateAuthenticationToken(response.token);
        location.reload();
    }

    return (
        <Container>
            <div className="content">
                <div className="title">Välkommen!</div>
                <Input
                    type="list"
                    onChange={setProspect}
                    className="form"
                    object={{
                        name: {
                            title: "Företag",
                            type: "text",
                            placeholder: "Ditt företagsnamn",
                        },
                        contactName: {
                            title: "Ditt namn",
                            type: "text",
                            placeholder: "För- och efternamn",
                        },
                        contactEmail: {
                            title: "Din e-postadress",
                            type: "text",
                            placeholder: "ditt.namn@exempel.se",
                        },
                    }}
                />
                <div className="buttons">
                    <div
                        className="c-button c-button--raised"
                        onClick={register}
                    >
                        Sätt igång!
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default Component;
