import Context from "context/Global";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    width: 100%;
    max-width: 1200px;

    > .buttons {
        width: 100%;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
    }
`;

function View(props) {
    const context = useContext(Context);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setMessages(await Api.getPortalMessages({}));
    }

    return (
        <Container>
            <div className="buttons">
                <Link
                    to="/admin/messages/create"
                    className="c-button c-button--raised"
                >
                    Nytt meddelande
                </Link>
                <Link
                    to="/admin/messages/templates"
                    className="c-button c-button--flat"
                >
                    Mallar
                </Link>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Titel</th>
                        <th>Startdatum</th>
                        <th>Slutdatum</th>
                    </tr>
                </thead>
                <tbody>
                    {messages.map((message) => {
                        return (
                            <tr key={message.id}>
                                <td>{message.title}</td>
                                <td>{message.start}</td>
                                <td>{message.end}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Container>
    );
}

export default View;
