function numberOfInvoicedShipments(customer) {
    return customer.orders.reduce((invoicedShipmentCount, order) => {
        if (
            order.externalInvoices &&
            order.externalInvoices.indexOf("" + order.currentInvoiceNumber) >= 0
        ) {
            return invoicedShipmentCount + 1;
        }
        return invoicedShipmentCount;
    }, 0);
}

function numberOfCustomerWarnings(customer) {
    return customer.orders.reduce((warnings, order) => {
        if (
            !(
                order.externalInvoices &&
                order.externalInvoices.indexOf(
                    "" + order.currentInvoiceNumber,
                ) >= 0
            )
        ) {
            if (order.invoiceIds?.length) {
                return warnings + 1;
            }
        }
        return warnings;
    }, 0);
}

function numberOfInsurances(customer) {
    return customer.orders.filter((order) => !!order.insuranceValue).length;
}

export {
    numberOfInvoicedShipments,
    numberOfCustomerWarnings,
    numberOfInsurances,
};
