import React, { useContext, useState } from "react";
import InputCheckbox from "../../../../../components/Input/Checkbox";
import Api from "../../../../../services/Api/Api";
import toast from "react-hot-toast";
import Context from "../../../../../context/Global";
import ReceiverPaysTerms from "../ReceiverPaysTerms";
import Radiobutton from "../../../../../components/Input/Radiobutton";

function Component() {
    const [checkedApprovedTerms, setCheckedApprovedTerms] = useState(false);
    const [subscriptionType, setSubscriptionType] = useState("limited");
    const [billingType, setBillingType] = useState("monthly");
    const context = useContext(Context);
    const userSettings = context.user.customer.settings;
    const borderColor = checkedApprovedTerms
        ? "border-green-500"
        : "border-yellow-400";

    const updatedSettings = {
        ...userSettings,
        receiverPays: {
            userId: context.user.id,
            userName: context.user.name,
            activatedAt: new Date().toISOString(),
            subscriptionType: subscriptionType,
            billingType: billingType,
        },
    };

    async function activateReceiverPays() {
        try {
            const result = await Api.updateCustomer({
                customerId: context.user.customer.id,
                settings: updatedSettings,
            });
            if (result) {
                const updatedUser = {
                    ...context.user,
                    customer: {
                        ...context.user.customer,
                        settings: updatedSettings,
                    },
                };
                context.setUser(updatedUser);
            }
            toast.success("Du har nu aktiverat mottagarfrakt!");
        } catch (error) {
            toast.error(
                "Oväntat fel uppstod. Försök igen senare eller kontakta support",
            );
        }
    }

    const handleSubscriptionChange = (type) => {
        setSubscriptionType(type);
    };

    const handleBillingType = (type) => {
        setBillingType(type);
    };

    return (
        <div className="w-1/2 min-h-screen flex flex-col ml-8">
            <p className="text-2xl mb-4">
                Läs och godkänn villkoren för att aktivera mottagarfrakt
            </p>
            <div
                className={`border-solid rounded-md border-0.5 border-spacing-4  ${borderColor} p-2`}
            >
                <ReceiverPaysTerms />
            </div>
            <div className="flex flex-col items-start mt-8">
                <p className="font-bold mb-4">Välj hur du vill betala</p>
                <div className="w-full flex justify-start items-center mb-8">
                    <Radiobutton
                        options={[
                            { value: "monthly", title: "Månadsvis" },
                            {
                                value: "yearly",
                                title: "Årsvis (20% rabatt på abonnemangskostanden)",
                            },
                        ]}
                        value={billingType}
                        onChange={handleBillingType}
                    />
                </div>

                <p className="font-bold mb-4">Välj ditt abonnemang</p>
                <div className="w-full flex justify-start items-center mb-8">
                    <Radiobutton
                        options={[
                            {
                                value: "limited",
                                title: "20 fraktetiketter (249kr/månad och 3,5kr per etikett)",
                            },
                            {
                                value: "unlimited",
                                title: "Obegränsat antal fraktetiketter (499kr/månad och 2,5kr per etikett)",
                            },
                        ]}
                        value={subscriptionType}
                        onChange={handleSubscriptionChange}
                    />
                </div>

                <div className="w-full flex justify-start items-center">
                    <InputCheckbox onChange={setCheckedApprovedTerms} />
                    <label className="mr-2 ml-2 font-bold">
                        Jag har läst och godkänner villkoren
                    </label>
                </div>

                <button
                    className="c-button c-button--raised mt-8 mb-8"
                    onClick={activateReceiverPays}
                    disabled={!checkedApprovedTerms}
                >
                    Godkänn
                </button>
            </div>
        </div>
    );
}

export default Component;
