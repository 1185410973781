import React from "react";
import useCreateDraftsStore from "../../Drafts/Create/createDraftsStore";

// Creating a HoC to wrap the Component with the useCreateDraftsStore hook. Unfortunately this is needed
// because the ViewOrder index.js is a non-functional component and cannot use hooks.
export function withCreateDraftsStore(Component) {
    return function WrappedComponent(props) {
        const { setReceiverPaysContactId } = useCreateDraftsStore();
        return (
            <Component
                {...props}
                setReceiverPaysContactId={setReceiverPaysContactId}
            />
        );
    };
}
