import React, {
    useEffect,
    useState,
    useContext,
    useImperativeHandle,
    useRef,
    forwardRef,
} from "react";
import styled from "styled-components/macro";
import Commodity from "./Commodity";
import Context from "context/Global";

import { v4 as uuidv4 } from "uuid";

let Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    > .wrapper {
        width: 100%;

        .titles {
            display: grid;
            grid-template: 1fr / 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            padding: 1rem;
            grid-gap: 1rem;
        }

        .commodities {
            .commodityRow {
                display: grid;
                grid-template: 1fr / 6fr 1fr;
                padding: 8px;
                border-radius: 5px;
                grid-gap: 1rem;

                &:hover {
                    background: rgba(0, 0, 0, 0.02);
                }

                .buttons {
                    display: flex;
                    align-items: center;
                    padding-left: 8px;

                    .button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 8px;
                        width: 32px;
                        height: 32px;
                        color: rgba(0, 0, 0, 0.5);
                        cursor: pointer;

                        &.delete:hover {
                            color: rgba(200, 0, 0, 0.7);
                        }

                        &.duplicate:hover {
                            color: rgba(0, 0, 100, 0.7);
                        }

                        > svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }

        .footer {
            display: flex;
            align-items: center;
            padding-top: 1rem;

            .validationMessage {
                padding: 1rem;
                color: #f55;
            }
        }
    }
`;

function checkUsePackageConnections(context) {
    return context.quotation.service.name.toUpperCase() === "TNT";
}

function Component(props, ref) {
    const context = useContext(Context);
    const [commodities, setCommodities] = useState([]);
    const [currency, setCurrency] = useState();
    const [valididationMessage, setValidationMessage] = useState();
    const inputRefs = useRef({});

    const connectCommoditiesToPackage = checkUsePackageConnections(context);
    const packages = context.order.packages;

    useEffect(() => {
        if (connectCommoditiesToPackage) {
            const commodities = packages.map((pkg) => ({
                id: uuidv4(),
                quantity: pkg.count,
                packageId: pkg.id,
                unitWeight: pkg.weight.toFixed(2),
                unitValue:
                    context.order.information.customsValue /
                    packages.length /
                    pkg.count,
                description: context.order.information.description,
                origin: "SE",
            }));
            setCommodities(commodities);
        } else {
            const unitWeight = context.order.packages.reduce((acc, pkg) => {
                return acc + pkg.weight * pkg.count;
            }, 0);

            const commodities = props.commodities || [
                {
                    id: uuidv4(),
                    quantity: 1,
                    unitWeight: unitWeight.toFixed(2),
                    unitValue: context.order.information.customsValue,
                    description: context.order.information.description,
                    origin: "SE",
                },
            ];
            setCommodities(commodities);
        }
    }, [props.commodities]);

    useEffect(() => {
        setCurrency(props.currency || "SEK");
    }, [props.currency]);

    useEffect(() => {
        props.onChange(commodities);
    }, []);
    useEffect(() => {
        props.onChange(commodities);
    }, [commodities]);

    function addCommodity(packageId) {
        setValidationMessage(null);
        setCommodities(
            commodities.concat([
                {
                    id: uuidv4(),
                    ...(packageId && { packageId }),
                    description: "",
                    quantity: 1,
                    unitWeight: null,
                    uniValue: null,
                    origin: "SE",
                },
            ]),
        );
    }

    function deleteCommodity(commodityId) {
        setCommodities((prevCommodities) =>
            prevCommodities.filter((commodity) => commodityId !== commodity.id),
        );

        inputRefs.current = Object.keys(inputRefs.current).reduce(
            (acc, key) => {
                if (key !== commodityId) {
                    acc[key] = inputRefs.current[key];
                }
                return acc;
            },
            {},
        );
    }

    function duplicateCommodity(index, commodityId) {
        let newCommodity = { ...commodities.find((c) => c.id === commodityId) };
        newCommodity.id = uuidv4();
        let list = [].concat(commodities);
        list.splice(index, 0, newCommodity);
        setCommodities(list);
    }

    const validate = () => {
        let valid = true;
        if (!Object.keys(inputRefs.current).length) {
            setValidationMessage("Minst 1 godsrad krävs");
            return false;
        } else {
            setValidationMessage(null);
        }
        Object.values(inputRefs.current).forEach((ref) => {
            if (!ref.current.validate()) {
                valid = false;
            }
        });
        return valid;
    };

    const value = () => {
        return commodities;
    };

    useImperativeHandle(ref, () => {
        return {
            validate: validate,
            value: value,
        };
    });

    function renderCommodities(pkg) {
        const commoditiesList = pkg
            ? commodities.filter((row) => row.packageId === pkg.id)
            : commodities;

        return (
            <div className="wrapper">
                <div className="titles">
                    <div className="title">Beskrivning</div>
                    <div className="title">Antal</div>
                    <div className="title">Enhetsvikt</div>
                    <div className="title">Enhetsvärde</div>
                    <div className="title">HS-kod</div>
                    <div className="title">Ursprung</div>
                </div>
                <div className="commodities">
                    {commoditiesList.map((row, index) => {
                        let ref = React.createRef();
                        inputRefs.current[row.id] = ref;

                        return (
                            <div key={row.id} className="commodityRow">
                                <Commodity
                                    ref={ref}
                                    {...row}
                                    currency={currency}
                                    onChange={(value) => {
                                        setCommodities(
                                            commodities.map((r) => {
                                                if (r.id === row.id) {
                                                    return {
                                                        ...r,
                                                        ...value,
                                                    };
                                                }
                                                return r;
                                            }),
                                        );
                                    }}
                                />
                                <div className="buttons">
                                    <div
                                        className="button duplicate"
                                        onClick={() => {
                                            duplicateCommodity(index, row.id);
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M22 6v16h-16v-16h16zm2-2h-20v20h20v-20zm-24 17v-21h21v2h-19v19h-2z"
                                            />
                                        </svg>
                                    </div>

                                    {commoditiesList.length > 1 && (
                                        <div
                                            className="button delete"
                                            onClick={() => {
                                                deleteCommodity(row.id);
                                            }}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.094l-4.157-4.104 4.1-4.141-1.849-1.849-4.105 4.159-4.156-4.102-1.833 1.834 4.161 4.12-4.104 4.157 1.834 1.832 4.118-4.159 4.143 4.102 1.848-1.849z"
                                                />
                                            </svg>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="footer mb-8 ml-2 mt-0">
                    <div
                        className="c-button c-button--raised"
                        onClick={() => addCommodity(pkg?.id)}
                    >
                        Lägg till godsrad
                    </div>
                    {valididationMessage && (
                        <div className="validationMessage">
                            {valididationMessage}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    function getPrettyType(type) {
        return type === "pallet" ? "Pall" : "Paket";
    }

    return (
        <Container>
            {connectCommoditiesToPackage
                ? context.order.packages.map((pkg, pkgIndex) => (
                      <div key={pkg.id} className={"wrapper"}>
                          <div className="text-xl">
                              {pkg.count}st {getPrettyType(pkg.type)} (
                              {pkg.length}x{pkg.width}x{pkg.height}cm)
                          </div>

                          {renderCommodities(pkg)}
                      </div>
                  ))
                : renderCommodities()}
        </Container>
    );
}

export default forwardRef(Component);
