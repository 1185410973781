import React, { useState, useRef } from "react";
import styled from "styled-components";

const InputContainer = styled.div`
    position: relative;

    .existing-groups {
        list-style-type: none;
        margin: 0;
        padding: 0;
        border: 1px solid #ccc;
        border-radius: 4px;
        max-height: 200px;
        overflow-y: auto;
        position: absolute;
        width: 100%;
        z-index: 1;
        background-color: #fff;

        li {
            padding: 10px;
            cursor: pointer;
            border-bottom: 1px solid #ccc;

            &:last-child {
                border-bottom: none;
            }

            &:hover {
                background-color: #f2f2f2;
            }
        }
    }

    .custom-input-container {
        background: #fff;
        padding: 4px;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 40px;
        overflow: hidden;

        > input {
            padding: 4px;
            width: 100%;
            border: 0;
            outline: none;
        }
    }
`;

const CustomInput = ({
    existingGroups,
    value,
    type,
    placeholder,
    onChange,
    maxLength,
}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [filteredGroups, setFilteredGroups] = useState([]);
    const inputRef = useRef(null);

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        onChange(inputValue);

        const newFilteredGroups = existingGroups.filter((group) =>
            group?.toLowerCase().startsWith(inputValue.toLowerCase()),
        );
        setFilteredGroups(newFilteredGroups);
        setShowDropdown(true);
    };

    const selectGroup = (group) => {
        onChange(group);
        setShowDropdown(false);
    };

    const handleBlur = () => {
        setTimeout(() => setShowDropdown(false), 200);
    };

    const handleFocus = () => {
        setFilteredGroups(existingGroups);
        setShowDropdown(true);
    };

    return (
        <InputContainer>
            <div className="custom-input-container">
                <input
                    ref={inputRef}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    maxLength={maxLength}
                />
            </div>
            {showDropdown && filteredGroups.length > 0 && (
                <ul className="existing-groups">
                    {filteredGroups.map((group, index) => {
                        if (!group) return null;
                        return (
                            <li key={index} onClick={() => selectGroup(group)}>
                                {group}
                            </li>
                        );
                    })}
                </ul>
            )}
        </InputContainer>
    );
};

export default CustomInput;
