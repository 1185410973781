import Input from "components/OldInput";
import InputNumber from "components/Input/Number";
import InputDropdown from "components/Input/Dropdown";
import TextareaInput from "components/Input/Textarea";
import Context from "context/Global";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import Address from "./Address";
import Packages from "./Packages";
import { convertToSEK } from "constants/currency";
import PayingCustomer from "./PayingCustomer";

let Container = styled.div`
    box-sizing: border-box;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > .header {
        display: flex;
        align-items: center;

        > .edit {
            margin-left: auto;
            cursor: pointer;
        }
    }

    > .type {
        padding: 0 1rem;
        display: flex;
        justify-content: flex-start;
        gap: 1rem;

        > .text,
        > .status {
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            padding: 1rem;
        }
    }

    > .route {
        display: flex;
        gap: 1rem;
        padding: 0 1rem;
    }

    .route {
        display: flex;
        gap: 1rem;
        padding: 0 1rem;
    }

    .route > .address-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .route > .address-wrapper.paying-customer {
        flex: 1.25;
    }
    > .packages {
        padding: 0 1rem;
    }

    > .information {
        padding: 0 1rem;
        display: grid;
        grid: auto / 1fr 1fr;
        gap: 0 1rem;

        > .title {
            font-size: 1.2rem;
            margin-bottom: 0.2rem;
        }

        > .text {
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            padding: 1rem;
        }
    }

    > .forwardingCompanies {
        padding: 0 1rem;

        > .title {
            font-size: 1.2rem;
            margin-bottom: 0.2rem;
        }

        > .emptyState {
            font-style: italic;
        }

        > .list {
            display: flex;
            gap: 1rem;

            > .company {
                display: flex;
                flex-direction: column;
                padding: 1rem;
                text-align: center;
                border-radius: 1rem;

                position: relative;

                > .delete {
                    position: absolute;
                    right: 0;
                    top: 0;
                    padding: 0.5rem;
                    width: 1.5rem;
                    height: 1.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    pointer-events: all;

                    > svg {
                        width: 100%;
                        height: 100%;
                    }
                }
                > .name {
                    font-weight: bold;
                }

                &:hover {
                    background: #dddeff;
                }

                &.is-selected {
                    background: #979bed;
                }

                &.is-used {
                    background: #aaedc0;
                    pointer-events: none;
                }
            }
        }
    }

    > .cost {
        padding: 0 1rem;
        display: grid;
        grid: auto / auto 1fr;
        gap: 1rem;

        > .title {
            font-size: 1.2rem;
            margin-bottom: 0.2rem;
        }

        > .transporterCost {
            display: flex;
            gap: 1rem;
            align-items: center;
        }

        > .customerPrice {
            display: flex;
            gap: 1rem;
        }
    }

    > .notes {
        padding: 1rem;

        > .title {
            font-size: 1.2rem;
            margin-bottom: 0.2rem;
        }
    }

    > .buttons {
        padding: 1rem;

        > .delete {
            color: var(--color-bad);
            border: 1px solid var(--color-bad);

            &:hover {
                background: var(--color-bad);
                color: #fff;
            }
        }
    }

    > .addCompany {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;

        > .container {
            background: #fff;
            padding: 1rem;
            border-radius: 1rem;
            display: flex;
            gap: 1rem;

            > .c-button {
                margin-left: 0;
            }
        }
    }
`;

let Title = styled.div`
    font-size: 24px;
    color: rgba(0, 0, 0, 0.6);
    padding: 1rem;
`;

function View({ history, forwardingId }) {
    const context = useContext(Context);
    const [forwarding, setForwarding] = useState(null);
    const [forwardingCompanies, setForwardingCompanies] = useState([]);
    const [selectedForwardingCompanies, setSelectedForwardingCompanies] =
        useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [transporter, setTransporter] = useState(null);
    const [customerPrice, setCustomerPrice] = useState();
    const [transporterCost, setTransporterCost] = useState();
    const [cost, setCost] = useState();
    const [saving, setSaving] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [notes, setNotes] = useState();
    const [currency, setCurrency] = useState("SEK");
    const [showAddCompany, setShowAddCompany] = useState(false);
    const [newCompanyName, setNewCompanyName] = useState("");
    const [newCompanyEmail, setNewCompanyEmail] = useState("");
    const customerPriceRef = useRef();

    useEffect(() => {
        loadForwarding();
    }, []);

    useEffect(() => {
        setCost(
            transporterCost
                ? convertToSEK(transporterCost.toString(), currency).toString()
                : "",
        );
    }, [transporterCost, currency]);

    async function loadForwarding() {
        const forwarding = await Api.getForwarding({
            forwardingId,
        });
        setTransporter(forwarding.transporter);
        setTransporterCost(forwarding.transporterCost);
        setCurrency(forwarding.currency || "SEK");
        setCost(forwarding.cost);
        setCustomerPrice(forwarding.customerPrice);
        setForwarding(forwarding);
        setNotes(forwarding.notes);
        setForwardingCompanies(await Api.getForwardingCompanies({}));
    }

    async function save() {
        setSaving(true);
        Api.updateForwarding({
            forwardingId: forwarding.id,
            data: {
                forwarding: {
                    customerId: forwarding?.customerId,
                    data: forwarding.data,
                    transporter,
                    notes,
                    customerPrice,
                    cost,
                    currency,
                    transporterCost,
                },
            },
        });
        setTimeout(() => {
            setSaving(false);
        }, 500);
    }

    async function markAsBooked() {
        await Api.updateForwarding({
            forwardingId: forwarding.id,
            data: {
                forwarding: {
                    customerId: forwarding?.customerId,
                    status: "BOOKED",
                },
            },
        });
        loadForwarding();
    }
    async function markAsInvoiced() {
        await Api.updateForwarding({
            forwardingId: forwarding.id,
            data: {
                forwarding: {
                    customerId: forwarding?.customerId,
                    status: "INVOICED",
                },
            },
        });
        loadForwarding();
    }
    async function markAsClosed() {
        await Api.updateForwarding({
            forwardingId: forwarding.id,
            data: {
                forwarding: {
                    status: "CLOSED",
                },
            },
        });
        loadForwarding();
    }

    async function deleteForwarding() {
        if (window.confirm("Är du säker på att du vill ta bort speditionen?")) {
            await Api.updateForwarding({
                forwardingId: forwarding.id,
                data: {
                    forwarding: {
                        status: "DELETED",
                    },
                },
            });
            history.push(`/admin/spedition`);
        }
    }

    useEffect(() => {
        const perc = parseInt(percentage);
        if (!perc) {
            return;
        }

        const newCustomerPrice = (Math.round(cost) * (100 + perc)) / 100;
        setCustomerPrice(newCustomerPrice);
        customerPriceRef.current.set(newCustomerPrice);
    }, [percentage, cost]);

    async function createNewCompany() {
        await Api.createForwardingCompany({
            name: newCompanyName,
            email: newCompanyEmail,
        });

        setForwardingCompanies(await Api.getForwardingCompanies({}));
        setShowAddCompany(false);
    }

    function toggleSelectedCompany(company) {
        if (selectedForwardingCompanies.indexOf(company.email) >= 0) {
            setSelectedForwardingCompanies((companies) => {
                return companies.filter((companyEmail) => {
                    return companyEmail !== company.email;
                });
            });
        } else {
            setSelectedForwardingCompanies((companies) => {
                return [].concat(companies).concat(company.email);
            });
        }
    }

    async function sendRequest() {
        await Api.sendForwardingRequest({
            forwardingId: forwarding.id,
            recipients: selectedForwardingCompanies,
        });
        loadForwarding();
        setSelectedForwardingCompanies([]);
    }

    async function deleteForwardingCompany(company) {
        if (
            window.confirm(
                `Är du säker på att du vill ta bort ${company.name}?`,
            )
        ) {
            await Api.deleteForwardingCompany({
                companyId: company.id,
            });
            setForwardingCompanies(await Api.getForwardingCompanies({}));
        }
    }

    const updateForwardingCustomer = (newCustomer) => {
        setForwarding((prevForwarding) => ({
            ...prevForwarding,
            customerId: newCustomer.id,
            data: {
                ...prevForwarding?.data,
                customer: newCustomer,
            },
        }));
    };

    if (!forwarding) {
        return null;
    }

    return (
        <Container>
            <div className="header">
                <Title>Spedition #{forwarding.id}</Title>
                <div className="created">
                    {moment(forwarding.created).calendar()}
                </div>
            </div>
            <div className="type">
                <div className="text">Typ: {forwarding.data.type}</div>
                <div className="status">Status: {forwarding.status}</div>
                <div className="status">
                    Tull-villkor: {forwarding.data.information.terms}
                </div>
            </div>
            <div className="route">
                <div className="address-wrapper">
                    <div className="title">Avsändare</div>
                    <Address address={forwarding.data.sender} />
                </div>
                <div className="address-wrapper">
                    <div className="title">Mottagare</div>
                    <Address address={forwarding.data.recipient} />
                </div>
                <div className="address-wrapper paying-customer">
                    <div className="title">Betalande kund</div>
                    <PayingCustomer
                        customer={forwarding.data.customer}
                        onCustomerChange={updateForwardingCustomer}
                    />
                </div>
            </div>
            <div className="packages">
                <Packages packages={forwarding.data.packages} />
            </div>
            <div className="information">
                <div className="title">Upphämtning</div>
                <div className="title">Övrigt</div>
                <div className="text">{forwarding.data.information.pickup}</div>
                <div className="text">{forwarding.data.information.other}</div>
            </div>
            {!!context.user.brand && (
                <>
                    <div className="buttons">
                        <Link
                            className="c-button c-button--raised edit"
                            to={`/admin/spedition/${forwarding.id}/edit`}
                        >
                            Redigera
                        </Link>
                    </div>
                    <div className="forwardingCompanies">
                        <div className="title">Speditörer</div>
                        <div className="list">
                            {forwardingCompanies.length ? (
                                forwardingCompanies.map((company) => {
                                    return (
                                        <div
                                            key={company.email}
                                            className={`company ${
                                                selectedForwardingCompanies.indexOf(
                                                    company.email,
                                                ) >= 0
                                                    ? "is-selected"
                                                    : ""
                                            } ${
                                                forwarding.data.requestedQuotes?.indexOf(
                                                    company.email,
                                                ) >= 0
                                                    ? "is-used"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                toggleSelectedCompany(company)
                                            }
                                        >
                                            <div
                                                className="delete"
                                                onClick={(ev) => {
                                                    ev.stopPropagation();
                                                    deleteForwardingCompany(
                                                        company,
                                                    );
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
                                                </svg>
                                            </div>
                                            <div className="name">
                                                {company.name}
                                            </div>
                                            <div className="email">
                                                {company.email}
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="emptyState">
                                    Det finns inga speditörer i systemet ännu.
                                    Lägg till med knappen nedan.
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="buttons">
                        <div
                            className={`c-button c-button--raised ${
                                selectedForwardingCompanies.length <= 0
                                    ? "is-disabled"
                                    : ""
                            }`}
                            onClick={sendRequest}
                        >
                            Skicka förfrågan
                        </div>
                        <div
                            className="c-button c-button--ghost"
                            onClick={() => setShowAddCompany(true)}
                        >
                            Lägg till ny speditör
                        </div>
                    </div>
                    <div className="cost">
                        <div className="title">Vald speditör</div>
                        <Input
                            type="text"
                            value={transporter}
                            onChange={setTransporter}
                        />
                        <div className="title">Kostnad</div>
                        <div className="transporterCost">
                            <Input
                                type="number"
                                value={transporterCost}
                                onChange={setTransporterCost}
                            />
                            <InputDropdown
                                options={[
                                    {
                                        title: "SEK",
                                        value: "SEK",
                                    },
                                    {
                                        title: "EUR",
                                        value: "EUR",
                                    },
                                    {
                                        title: "GBP",
                                        value: "GBP",
                                    },
                                    {
                                        title: "USD",
                                        value: "USD",
                                    },
                                ]}
                                value={currency}
                                onChange={setCurrency}
                            />
                            <InputNumber
                                disabled
                                type="number"
                                value={cost}
                                onChange={setCost}
                            />
                        </div>
                        <div className="title">Kundens pris</div>
                        <div className="customerPrice">
                            <Input
                                ref={customerPriceRef}
                                type="number"
                                unit="SEK"
                                value={customerPrice}
                                onChange={setCustomerPrice}
                            />
                            <Input
                                type="number"
                                unit="%"
                                value={percentage}
                                onChange={setPercentage}
                            />
                        </div>
                    </div>
                    <div className="notes">
                        <div className="title">Anteckningar</div>
                        <TextareaInput value={notes} onChange={setNotes} />
                    </div>
                    <div className="buttons">
                        <button
                            className="c-button c-button--raised"
                            disabled={saving}
                            onClick={save}
                        >
                            Spara
                        </button>
                        {["BOOKED", "INVOICED", "CLOSED"].indexOf(
                            forwarding.status,
                        ) < 0 && (
                            <button
                                className="c-button c-button--ghost"
                                onClick={markAsBooked}
                            >
                                Markera som bokad
                            </button>
                        )}
                        {["BOOKED"].indexOf(forwarding.status) === 0 && (
                            <button
                                className="c-button c-button--ghost"
                                onClick={markAsInvoiced}
                            >
                                Markera som fakturerad
                            </button>
                        )}
                        {["INVOICED"].indexOf(forwarding.status) === 0 && (
                            <button
                                className="c-button c-button--ghost"
                                onClick={markAsClosed}
                            >
                                Stäng ärendet
                            </button>
                        )}
                        <button
                            className="c-button c-button--ghost delete"
                            onClick={deleteForwarding}
                        >
                            Ta bort
                        </button>
                    </div>
                    {showAddCompany && (
                        <div className="addCompany">
                            <div className="container">
                                <Input
                                    type="text"
                                    placeholder="Namn"
                                    onChange={setNewCompanyName}
                                />
                                <Input
                                    type="email"
                                    placeholder="E-post"
                                    onChange={setNewCompanyEmail}
                                />
                                <div
                                    className="c-button c-button--raised"
                                    onClick={createNewCompany}
                                >
                                    Skapa
                                </div>
                                <div
                                    className="c-button c-button--ghost"
                                    onClick={() => setShowAddCompany(false)}
                                >
                                    Avbryt
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </Container>
    );
}

export default View;
