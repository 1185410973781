import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import Api from "services/Api/Api";
import styled from "styled-components";

let Container = styled.div`
    > .inputContainer {
        background: #fff;
        padding: 4px;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 40px;
        position: relative;

        > input {
            padding: 4px;
            width: 100%;
            border: 0;
            outline: none;
        }

        > input[disabled] {
            background: inherit;
        }

        > .prefix {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            margin-right: 8px;
            width: 60px;
            min-width: 60px;
            color: rgba(0, 0, 0, 0.4);
        }

        > .unit {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            margin-left: 8px;
            width: 60px;
            min-width: 60px;
            color: rgba(0, 0, 0, 0.4);
        }

        > .length {
            font-size: 0.8rem;
            padding: 2px 4px;
            font-style: italic;
            font-weight: bold;
            color: #555;

            &.is-invalid {
                color: #ff5555;
            }
        }

        > .backdrop {
            opacity: 0;
            position: fixed;
            z-index: 10;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        > .searchResult {
            max-height: 50vh;
            overflow-y: auto;
            position: absolute;
            opacity: 1;
            width: 100%;
            background: #fff;
            box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.3);
            border-radius: 5px;
            top: 100%;
            padding: 8px 0;
            z-index: 11;

            > .item {
                padding: 4px 8px;
                cursor: pointer;
                border-bottom: 1px solid #aaa;

                &:last-child {
                    border-bottom: 0;
                }

                > .title {
                    font-weight: bold;
                }

                &.is-selected {
                    background: rgba(0, 0, 0, 0.04);
                }

                &:hover {
                    background: rgba(0, 0, 0, 0.02);
                }
            }

            &.is-visible {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    &.align-right {
        > .inputContainer {
            > input {
                text-align: right;
            }
        }
    }

    > .message {
        font-weight: bold;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
    }

    &.is-invalid {
        > .inputContainer {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }
        > .message {
            color: rgba(255, 0, 0, 0.6);
        }
    }

    &.is-disabled {
        > .inputContainer {
            background: rgb(235, 235, 228);
            border: 1px solid rgba(0, 0, 0, 0.05);

            > input {
                color: rgba(0, 0, 0, 0.5);
                background: transparent;
            }
        }
    }
`;

function Component({ onSelect, ...props }, ref) {
    const [value, setValue] = useState(props.value);
    const [message, setMessage] = useState();
    const [disabled, setDisabled] = useState();
    const [isInvalid, setIsInvalid] = useState(false);
    const [searching, setSearching] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const searchRef = useRef();

    useEffect(() => {
        if (typeof props.onChange === "function") {
            props.onChange(value);
        }
    }, [value]);

    function getLength() {
        return value?.length;
    }

    function showLength() {
        if (
            props.maxLength &&
            (props.maxLength <= 10 || getLength() / props.maxLength > 0.8)
        ) {
            return true;
        }
        return false;
    }

    function getLengthClass() {
        let classes = ["length"];
        if (getLength() > props.maxLength) {
            classes.push("is-invalid");
        }
        return classes.join(" ");
    }

    useEffect(() => {
        setDisabled(props.disabled || false);
    }, [props.disabled]);

    function onChange(event) {
        setValue(event.target.value);
        clearTimeout(searchRef.current);
        if (event.target.value.length) {
            searchRef.current = setTimeout(() => {
                searchContacts(event.target.value);
            }, 500);
        } else {
            setSearchResult([]);
        }
    }

    async function searchContacts(name) {
        setSearching(true);
        setSearchResult(
            await Api.searchContactsByName({
                name: name,
            }),
        );
        setSearching(false);
    }

    useImperativeHandle(ref, () => {
        return {
            validate: validate,
            clear: clear,
            empty: clear,
            set: set,
            value: () => value,
        };
    });

    function clear() {
        setValue("");
    }
    function set(value) {
        setValue(value);
    }

    function validate() {
        if (getLength() > props.maxLength) {
            setIsInvalid(true);
            setMessage(props.messages.maxLength);
            return false;
        }
        if (getLength() < props.minLength) {
            setIsInvalid(true);
            setMessage(props.messages.minLength);
            return false;
        }
        setIsInvalid(false);
        setMessage(null);
        return true;
    }

    function selectClient(client) {
        onSelect(client);
        setSearchResult([]);
    }

    return (
        <Container
            className={
                (disabled ? " is-disabled" : "") +
                (isInvalid ? " is-invalid" : "")
            }
        >
            <div className="inputContainer">
                <input
                    type="text"
                    autoComplete="chrome-off"
                    onChange={onChange}
                    value={value}
                    name={props.name}
                />
                {showLength() && (
                    <div className={getLengthClass()}>
                        {getLength()}/{props.maxLength}
                    </div>
                )}
                {!!searchResult.length && (
                    <>
                        <div
                            className="backdrop"
                            onClick={() => {
                                setSearchResult([]);
                            }}
                        ></div>
                        <div className="searchResult">
                            {searchResult?.map((client) => {
                                return (
                                    <div
                                        key={client.id}
                                        className={"item"}
                                        onClick={() => {
                                            selectClient(client);
                                        }}
                                    >
                                        <div className="title">
                                            {[
                                                client.contact.company,
                                                client.contact.name,
                                            ]
                                                .filter((v) => v)
                                                .join(", ")}
                                        </div>
                                        <div className="address">
                                            {[
                                                client.addressLine1,
                                                client.postalCode,
                                                client.postalTown,
                                            ]
                                                .filter((v) => v)
                                                .join(", ")}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
            {message && <div className="message">{message}</div>}
        </Container>
    );
}

export default forwardRef(Component);
