import React from "react";

const defaultStyles =
    "px-4 py-2 inline-flex items-center justify-center border-none rounded-md transition-all cursor-pointer text-white text-sm";

const variants = {
    login: ` min-w-[64px] min-h-[36px] w-full buttonColor `,
    submit: `bg-green-600 `,
    reset: `bg-red-500 `,
};

const Button = ({ variant, type, children, onClick }) => {
    return (
        <button
            type={type}
            className={variants[variant] + defaultStyles}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default Button;
