import React from "react";
import { ActionListItem } from "./ActionListItem";

export const ActionList = ({ actionItems, close }) => {
    return (
        <div className="absolute bg-white border border-gray-300 rounded-md shadow-lg w-full mt-1">
            <div className="flex flex-col items-start w-full">
                {actionItems.map((actionItem, index) => (
                    <ActionListItem
                        key={`action-item-${index}-${actionItem.title}`}
                        actionItem={actionItem}
                        close={close}
                        subItems={actionItem.subItems}
                        classes={actionItem.extraClasses}
                    />
                ))}
            </div>
        </div>
    );
};
