import PackageAggregate from "components/PackageAggregate";
import { getCountryName } from "constants/countryList";
import Context from "context/Global";
import React, { useContext } from "react";
import styled from "styled-components/macro";

let Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    align-items: flex-start;
    background: #fff;
    padding: 16px;
    width: 100%;
`;

let Route = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    width: 100%;

    > .party {
        width: 50%;

        > .title {
            color: rgba(0, 0, 0, 0.6);
        }

        > .company {
            font-weight: bold;
        }
    }
`;

let PackagesTable = styled.table`
    margin-bottom: 16px;
    width: 100%;
`;

function Component({ address }) {
    const context = useContext(Context);

    return (
        <Container>
            {address.contact.company && (
                <>
                    <div className="company">{address.contact.company}</div>
                    <div className="contact">
                        Kontaktperson: {address.contact.name}
                    </div>
                </>
            )}
            <div className="contact">{getCountryName(address.countryCode)}</div>
            {!address.contact.company && (
                <div className="contact">{address.contact.name}</div>
            )}
            <div className="streetAddress">{address.addressLine1}</div>
            <div className="streetAddress">{address.addressLine2}</div>
            <div className="streetAddress">{address.addressLine3}</div>
            <div className="city">
                {address.postalCode}, {address.postalTown}
            </div>
            {address.otherPickupAddress && (
                <div className="party">
                    <div className="title">Annan upphämtningsadress</div>
                    {address.pickup.contact.company && (
                        <>
                            <div className="company">
                                {address.pickup.contact.company}
                            </div>
                            <div className="contact">
                                Kontaktperson: {address.pickup.contact.name}
                            </div>
                        </>
                    )}
                    {!address.pickup.contact.company && (
                        <div className="contact">
                            {address.pickup.contact.name}
                        </div>
                    )}
                    <div className="streetAddress">
                        {address.pickup.addressLine1}
                    </div>
                    <div className="streetAddress">
                        {address.pickup.addressLine2}
                    </div>
                    <div className="streetAddress">
                        {address.pickup.addressLine3}
                    </div>
                    <div className="city">
                        {address.pickup.postalCode}, {address.pickup.postalTown}
                    </div>
                </div>
            )}
        </Container>
    );
}

export default Component;
