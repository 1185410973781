import Input from "components/OldInput";
import axios from "axios";
import Checkbox from "components/Input/Checkbox";
import Loader from "components/Loader/Loader";
import Context from "context/Global";
import moment from "moment";
import "moment/locale/sv";
import React, { useContext, useEffect, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import OrderRow from "./row";

let baseUrl = "";
if (process.env.REACT_APP_API_URL) {
    baseUrl = process.env.REACT_APP_API_URL;
} else {
    baseUrl = `${window.location.protocol}//${window.location.host}`;
}

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;

    > .loader {
        width: 200px;
        height: 200px;
    }

    > .header {
        padding: 1em;
        display: flex;
        flex-direction: row;

        > .input {
            display: flex;
            margin-right: 1rem;
            align-items: center;

            > .title {
                padding-right: 1rem;
            }
        }

        .pagination {
            display: flex;
            align-items: center;
            margin-left: auto;
            cursor: pointer;

            > .previous,
            > .next {
                width: 16px;
                height: 16px;
                padding: 0 8px;
                box-sizing: content-box;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &.is-disabled {
                    color: #aaa;
                    pointer-events: none;
                }

                > svg {
                    width: 100%;
                    height: 100%;
                }
            }
            > .previous {
                transform: rotateZ(180deg);
            }
        }
    }

    > table.orders {
        width: 100%;
        margin-bottom: 16px;
        flex-direction: column;

        > thead {
            > tr {
                > th {
                    background: #fafafa;
                    padding: 1em 0;
                    border-bottom: 1px solid #ddd;
                    border-top: 1px solid #ddd;
                    color: #333;
                    font-weight: normal;

                    &:first-child {
                        padding-left: 1em;
                    }
                    &:last-child {
                        padding-right: 1em;
                        text-align: right;
                    }

                    &:nth-last-child(2) {
                        text-align: right;
                    }
                }
            }
        }

        > tbody {
            > tr {
                > td {
                    background: #fff;
                    padding: 0.5em 0;

                    &:first-child {
                        padding-left: 1em;
                    }
                    &:last-child {
                        padding-right: 1em;
                        text-align: right;
                    }

                    &:nth-last-child(2) {
                        text-align: right;
                    }

                    &.service {
                        > img {
                            width: 32px;
                            object-size: contain;
                        }
                    }
                }

                &:hover > td {
                    background: rgba(250, 250, 250, 1);
                }
            }

            > .emptyState {
                > td {
                    text-align: center !important;
                    font-style: italic;
                }
            }
        }
    }

    .receiver {
        display: flex;
        flex-direction: column;

        > .title {
            font-weight: bold;
        }
    }

    > .buttons {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    > .links {
        margin-top: 16px;
        text-align: center;

        > a {
            text-decoration: none;
            color: rgba(0, 0, 0, 0.7);
            padding: 8px 16px;
            display: inline-block;
            font-size: 10px;
            font-weight: bold;
        }
    }

    > .printDialog {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.4);
        z-index: 100;

        > .content {
            min-width: 20vw;
            background: #fff;
            border-radius: 4px;
            box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.4);
            display: flex;
            flex-direction: column;
            padding: 1rem;

            > .c-button {
                font-size: 1.4rem;
                padding: 1rem;
                border: 1px solid #aaa;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                margin-bottom: 1rem;

                + .c-button {
                    margin-left: 0;
                }
            }

            > .footer {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
`;

function Component() {
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [searchstring, setSearchstring] = useState("");
    const [startDate, setStartDate] = useState(
        sessionStorage.getItem("orderListStartDate") || "",
    );
    const [endDate, setEndDate] = useState(
        sessionStorage.getItem("orderListEndDate") || "",
    );
    const [allSelected, setAllSelected] = useState(false);
    const [showPrintDialog, setShowPrintDialog] = useState(false);
    const [dateInterval, setDateInterval] = useState(
        sessionStorage.getItem("orderListDateInterval") || "all",
    );
    const [limit, setLimit] = useState(200);
    const [offset, setOffset] = useState(0);
    const [totalOrders, setTotalOrders] = useState(0);
    const cancelSearchTokenRef = useRef();
    const startDateRef = useRef();
    const endDateRef = useRef();
    const dateIntervalRef = useRef();
    const context = useContext(Context);

    useEffect(() => {
        loadOrders();
        // Check if date interval matches an interval in the dropdown
        if (
            startDate === endDate &&
            startDate === moment().format("YYYY-MM-DD")
        ) {
            dateIntervalRef.current.set("today");
        } else if (
            startDate === endDate &&
            startDate === moment().subtract(1, "day").format("YYYY-MM-DD")
        ) {
            dateIntervalRef.current.set("yesterday");
        } else if (
            startDate === moment().startOf("isoWeek").format("YYYY-MM-DD") &&
            endDate === moment().endOf("isoWeek").format("YYYY-MM-DD")
        ) {
            dateIntervalRef.current.set("week");
        } else if (
            startDate ===
                moment()
                    .startOf("isoWeek")
                    .subtract(1, "week")
                    .format("YYYY-MM-DD") &&
            endDate ===
                moment()
                    .endOf("isoWeek")
                    .subtract(1, "week")
                    .format("YYYY-MM-DD")
        ) {
            dateIntervalRef.current.set("lastWeek");
        } else if (startDate === "" && endDate === "") {
            dateIntervalRef.current.set("all");
        } else {
            dateIntervalRef.current.set("manual");
        }
    }, [searchstring, startDate, endDate, offset, limit]);

    useEffect(() => {
        switch (dateInterval) {
            case "today": {
                setStartDate(moment().format("YYYY-MM-DD"));
                setEndDate(moment().format("YYYY-MM-DD"));
                startDateRef.current.set(moment().format("YYYY-MM-DD"));
                endDateRef.current.set(moment().format("YYYY-MM-DD"));
                break;
            }
            case "yesterday": {
                setStartDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
                setEndDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
                startDateRef.current.set(
                    moment().subtract(1, "day").format("YYYY-MM-DD"),
                );
                endDateRef.current.set(
                    moment().subtract(1, "day").format("YYYY-MM-DD"),
                );
                break;
            }
            case "week": {
                setStartDate(moment().startOf("isoWeek").format("YYYY-MM-DD"));
                setEndDate(moment().endOf("isoWeek").format("YYYY-MM-DD"));
                startDateRef.current.set(
                    moment().startOf("isoWeek").format("YYYY-MM-DD"),
                );
                endDateRef.current.set(
                    moment().endOf("isoWeek").format("YYYY-MM-DD"),
                );
                break;
            }
            case "lastWeek": {
                setStartDate(
                    moment()
                        .startOf("isoWeek")
                        .subtract(1, "week")
                        .format("YYYY-MM-DD"),
                );
                setEndDate(
                    moment()
                        .endOf("isoWeek")
                        .subtract(1, "week")
                        .format("YYYY-MM-DD"),
                );
                startDateRef.current.set(
                    moment()
                        .startOf("isoWeek")
                        .subtract(1, "week")
                        .format("YYYY-MM-DD"),
                );
                endDateRef.current.set(
                    moment()
                        .endOf("isoWeek")
                        .subtract(1, "week")
                        .format("YYYY-MM-DD"),
                );
                break;
            }
            case "all": {
                setStartDate("");
                setEndDate("");
                startDateRef.current.set("");
                endDateRef.current.set("");
                break;
            }
        }
        sessionStorage.removeItem("orderListStartDate");
        sessionStorage.removeItem("orderListEndDate");
        sessionStorage.setItem("orderListDateInterval", dateInterval);
    }, [dateInterval]);

    useEffect(() => {
        if (allOrdersSelected()) {
            setAllSelected(true);
        } else if (getSelectedCount() > 0) {
            setAllSelected(null);
        } else {
            setShowPrintDialog(false);
            setAllSelected(false);
        }
    }, [orders]);

    async function loadOrders() {
        setLoading(true);

        if (cancelSearchTokenRef.current) {
            cancelSearchTokenRef.current.cancel();
        }
        cancelSearchTokenRef.current = axios.CancelToken.source();

        const { orders, count } = await Api.getCustomerOrders({
            customerId: context.user.customer.id,
            query: searchstring,
            startDate: startDate,
            endDate: endDate,
            limit: limit,
            offset: offset,
            cancelToken: cancelSearchTokenRef.current.token,
        });

        setOrders(orders);
        setTotalOrders(count);
        setLoading(false);
    }

    async function openPrintDialog() {
        setShowPrintDialog(true);
    }

    function isCheckboxDisabled(order) {
        return moment(order.created, "YYYY-MM-DD HH:mm:ss").isBefore(
            moment("2022-10-31 23:59:59"),
        );
    }

    async function toggleCheckAll() {
        const currentlyAllSelected = orders.every(
            (order) => isCheckboxDisabled(order) || order.selected,
        );

        setOrders((orders) =>
            orders.map((order) => ({
                ...order,
                selected: isCheckboxDisabled(order)
                    ? order.selected
                    : !currentlyAllSelected,
            })),
        );
    }

    function allOrdersSelected() {
        return (
            getSelectedCount() > 0 &&
            !orders.find((order) => {
                return (
                    order.state === "APPROVED" &&
                    moment(order.created).utc().format("YYYY-MM-DD HH:mm:ss") >
                        "2021-10-10 16:00:00" &&
                    !order.selected
                );
            })
        );
    }

    function getSelectedCount() {
        return orders.filter((order) => order.selected).length;
    }

    function getSelectedOrders() {
        return orders.filter((order) => order.selected);
    }
    function getSelectedOrderIds() {
        return orders
            .filter((order) => order.selected)
            .map((order) => order.id);
    }

    async function toggleOrder(order, value) {
        setOrders(
            orders.map((o) => {
                if (order.id === o.id) {
                    return {
                        ...o,
                        selected: value,
                    };
                } else {
                    return o;
                }
            }),
        );
    }

    async function toggleLimit() {
        if (limit < 200 && totalOrders > 100) {
            setLimit(200);
        } else if (limit < 500 && totalOrders > 200) {
            setLimit(500);
        } else if (limit < 1000 && totalOrders > 500) {
            setLimit(1000);
        } else if (limit < 5000 && totalOrders > 1000) {
            setLimit(5000);
        } else {
            setLimit(100);
        }
    }

    async function previousPage() {
        setOffset(offset - limit < 0 ? 0 : offset - limit);
    }
    async function nextPage() {
        if (offset + limit < totalOrders) {
            setOffset(offset + limit);
        }
    }

    return (
        <Container>
            <div className="header">
                <button
                    className="c-button c-button--raised input"
                    onClick={openPrintDialog}
                    disabled={getSelectedCount() === 0 ? true : false}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path d="M16 18h-8v-1h8v1zm-2 1h-6v1h6v-1zm10-14v13h-4v6h-16v-6h-4v-13h4v-5h16v5h4zm-18 0h12v-3h-12v3zm12 10h-12v7h12v-7zm4-8h-20v9h2v-3h16v3h2v-9zm-1.5 1c-.276 0-.5.224-.5.5s.224.5.5.5.5-.224.5-.5-.224-.5-.5-.5z" />
                    </svg>
                </button>
                <div className="input">
                    <Input
                        type="text"
                        onChange={setSearchstring}
                        placeholder={"Sök..."}
                    />
                </div>
                <div className="input">
                    <div className="title">Från</div>
                    <Input
                        ref={startDateRef}
                        type="date"
                        value={startDate}
                        onChange={(value) => {
                            sessionStorage.removeItem("orderListDateInterval");
                            sessionStorage.setItem("orderListStartDate", value);
                            setStartDate(value);
                        }}
                    />
                </div>
                <div className="input">
                    <div className="title">Till</div>
                    <Input
                        ref={endDateRef}
                        type="date"
                        value={endDate}
                        onChange={(value) => {
                            sessionStorage.removeItem("orderListDateInterval");
                            sessionStorage.setItem("orderListEndDate", value);
                            setEndDate(value);
                        }}
                    />
                </div>
                <div className="input">
                    <div className="title">Byt intervall</div>
                    <Input
                        ref={dateIntervalRef}
                        type="dropdown"
                        value={dateInterval}
                        onChange={setDateInterval}
                        options={[
                            {
                                title: "Välj",
                                value: "manual",
                            },
                            {
                                title: "Dagens",
                                value: "today",
                            },
                            {
                                title: "Gårdagens",
                                value: "yesterday",
                            },
                            {
                                title: "Veckans",
                                value: "week",
                            },
                            {
                                title: "Föregående vecka",
                                value: "lastWeek",
                            },
                            {
                                title: "Visa alla försändelser",
                                value: "all",
                            },
                        ]}
                    />
                </div>
                <div className="pagination">
                    <div
                        className={`previous ${
                            offset === 0 ? "is-disabled" : ""
                        }`}
                        onClick={previousPage}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path fill="currentColor" d="M6 0l12 12-12 12z" />
                        </svg>
                    </div>
                    <div className="showing" onClick={toggleLimit}>
                        Visar {totalOrders ? offset + 1 : 0} -{" "}
                        {totalOrders < offset + limit
                            ? totalOrders
                            : offset + limit}{" "}
                        av {totalOrders}
                    </div>
                    <div
                        className={`next ${
                            offset + limit > totalOrders ? "is-disabled" : ""
                        }`}
                        onClick={nextPage}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path fill="currentColor" d="M6 0l12 12-12 12z" />
                        </svg>
                    </div>
                </div>
            </div>
            <table className="orders">
                <thead>
                    <tr>
                        <th>
                            <Checkbox
                                onChange={toggleCheckAll}
                                value={allSelected}
                            />
                        </th>
                        <th></th>
                        <th>Försändelse</th>
                        <th>Datum</th>
                        <th>Avsändare</th>
                        <th>Mottagare</th>
                        <th>Information</th>
                        <th>Pris</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {loading && (
                        <tr>
                            <td>
                                <Loader />
                            </td>
                        </tr>
                    )}
                    {!loading && orders.length === 0 && (
                        <tr className="emptyState">
                            <td colSpan={100}>
                                Ingen försändelse kunde hittas i detta intervall
                            </td>
                        </tr>
                    )}
                    {!loading &&
                        orders.map((order) => {
                            const isCheckboxDisabled = moment(
                                order.created,
                                "YYYY-MM-DD HH:mm:ss",
                            ).isBefore(moment("2022-10-31 23:59:59"));

                            return (
                                <OrderRow
                                    key={order.id}
                                    order={order}
                                    onToggleSelected={(value) =>
                                        toggleOrder(order, value)
                                    }
                                    isCheckboxDisabled={isCheckboxDisabled}
                                />
                            );
                        })}
                </tbody>
            </table>
            {showPrintDialog && (
                <div
                    className="printDialog"
                    onClick={() => setShowPrintDialog(false)}
                >
                    <div
                        className="content"
                        onClick={(ev) => ev.stopPropagation()}
                    >
                        <div
                            className="c-button c-button--raised"
                            onClick={() => {
                                var w = window.open(
                                    `${baseUrl}/api/print/orders/labels?accessToken=${Api.getAuthenticationToken()}&ids=[${getSelectedOrderIds()}]`,
                                    "PRINT",
                                    "title=Etiketter,height=800,width=800",
                                );
                                w.document.title = "Etiketter";
                            }}
                        >
                            Fraktsedlar
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path d="M16 18h-8v-1h8v1zm-2 1h-6v1h6v-1zm10-14v13h-4v6h-16v-6h-4v-13h4v-5h16v5h4zm-18 0h12v-3h-12v3zm12 10h-12v7h12v-7zm4-8h-20v9h2v-3h16v3h2v-9zm-1.5 1c-.276 0-.5.224-.5.5s.224.5.5.5.5-.224.5-.5-.224-.5-.5-.5z" />
                            </svg>
                        </div>
                        <div
                            className="c-button c-button--raised"
                            onClick={() => {
                                var w = window.open(
                                    `${baseUrl}/api/print/orders/documents?accessToken=${Api.getAuthenticationToken()}&ids=[${getSelectedOrderIds()}]`,
                                    "PRINT",
                                    "height=800,width=800",
                                );
                                w.document.title = "Fraktdokument";
                            }}
                        >
                            Fraktdokument
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path d="M16 18h-8v-1h8v1zm-2 1h-6v1h6v-1zm10-14v13h-4v6h-16v-6h-4v-13h4v-5h16v5h4zm-18 0h12v-3h-12v3zm12 10h-12v7h12v-7zm4-8h-20v9h2v-3h16v3h2v-9zm-1.5 1c-.276 0-.5.224-.5.5s.224.5.5.5.5-.224.5-.5-.224-.5-.5-.5z" />
                            </svg>
                        </div>
                        <div className="footer">
                            <div
                                className="c-button c-button--raised"
                                onClick={() => setShowPrintDialog(false)}
                            >
                                Stäng
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Container>
    );
}

export default Component;
