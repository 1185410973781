import React, { useContext, useEffect, useRef, useState } from "react";
import FreightPartyModal from "views/Batch/BatchTable/TopBar/Actions/FreightPartyModal";
import InformationModal from "views/Batch/BatchTable/TopBar/Actions/InformationModal";
import PackagesModal from "views/Batch/BatchTable/TopBar/Actions/PackagesModal";
import toast from "react-hot-toast";
import Context from "../../../../../context/Global";
import Api from "../../../../../services/Api/Api";
import QuotesModal from "views/Batch/modals/QuotesModal/QuotesModal";
import ConfirmModal from "../../../../../components/ConfirmModal";
import PickupModal from "../../../modals/PickupModal";
import {
    checkForArrayEquality,
    checkForObjectEquality,
} from "../../../utils/checkObjectEquality";
import useBatchStore from "views/Batch/store";
import {
    hasQuotesExpired,
    selectedOrdersHasNoSelectedQuotes,
    weightOnlyShipment,
} from "utils/batchTableUtils";
import { ActionList } from "./ActionList";

const Actions = ({
    getBatchOrders,
    quoteOrders,
    openEditNotificationModal,
}) => {
    const context = useContext(Context);
    const [open, setOpen] = useState(false);
    const [editHover, setEditHover] = useState(false);
    const [openConfirmBatchRemoveModal, setOpenConfirmBatchRemoveModal] =
        useState(false);
    const [openPackagesModal, setOpenPackagesModal] = useState(false);
    const [openFreightPartyModal, setOpenFreightPartyModal] = useState(false);
    const [FreightPartyType, setFreightPartyType] = useState("");
    const [openInformationModal, setOpenInformationModal] = useState(false);
    const [openQuotesModal, setOpenQuotesModal] = useState(false);
    const [openPickupModal, setOpenPickupModal] = useState(false);

    const selectedOrders = useBatchStore((state) => state.selectedOrders);
    const clearSelectedOrders = useBatchStore(
        (state) => state.clearSelectedOrders,
    );

    const close = () => {
        setOpen(false);
        setEditHover(false);
    };

    const actionsRef = useRef();

    const handleClickOutside = (e) => {
        if (actionsRef.current && !actionsRef.current.contains(e.target)) {
            close();
        }
    };

    const disableHandlePickupDate = () => {
        return selectedOrders.every(
            (order) =>
                order.data.selectedQuote?.service?.name.toLowerCase() ===
                "postnord",
        );
    };

    const disableHandleCarrierService = () => {
        if (
            context.user.customer.settings?.bookBatchDirectWithoutQuote &&
            selectedOrders?.some(
                (order) =>
                    order.data.sender.countryCode !== "SE" ||
                    order.data.receiver.countryCode !== "SE",
            )
        ) {
            return true;
        }

        if (
            selectedOrders?.some((order) => hasQuotesExpired(order)) ||
            selectedOrders.length === 0 ||
            (selectedOrdersHasNoSelectedQuotes(selectedOrders) &&
                context.user.customer.settings?.bookBatchDirectWithoutQuote &&
                !selectedOrders?.every((order) => weightOnlyShipment(order))) ||
            (selectedOrdersHasNoSelectedQuotes(selectedOrders) &&
                !context.user.customer.settings?.bookBatchDirectWithoutQuote)
        ) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        if (open) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open]);

    async function removeBatchOrders() {
        try {
            await Api.removeBatchOrders({
                customerId: context.user.customer.id,
                importedOrders: selectedOrders.filter(
                    (order) => order.origin === "imported_order",
                ),
                orders: selectedOrders.filter(
                    (order) => order.origin === "order",
                ),
            });
            const successText =
                selectedOrders.length === 1
                    ? selectedOrders.length + " försändelse togs bort"
                    : selectedOrders.length + " försändelser togs bort";
            toast.success(successText);

            getBatchOrders();
        } catch (error) {
            toast.error(
                "Något gick fel vid borttagning av ordrar, försök igen eller kontakta kundservice.",
            );
        }
    }

    return (
        <div>
            {openPickupModal && (
                <PickupModal
                    setIsOpen={setOpenPickupModal}
                    isOpen={openPickupModal}
                    quoteOrders={quoteOrders}
                />
            )}

            {openQuotesModal && (
                <QuotesModal
                    mode={"batch"}
                    setIsOpen={setOpenQuotesModal}
                    isOpen={openQuotesModal}
                />
            )}
            {openPackagesModal && (
                <PackagesModal
                    setIsOpen={setOpenPackagesModal}
                    isOpen={openPackagesModal}
                    commonPackages={checkForArrayEquality(
                        selectedOrders,
                        "package",
                    )}
                />
            )}
            {openFreightPartyModal && (
                <FreightPartyModal
                    setIsOpen={setOpenFreightPartyModal}
                    isOpen={openFreightPartyModal}
                    type={FreightPartyType}
                    commonAddress={checkForObjectEquality(
                        selectedOrders,
                        FreightPartyType,
                    )}
                />
            )}
            {openInformationModal && (
                <InformationModal
                    isOpen={openInformationModal}
                    setIsOpen={setOpenInformationModal}
                    commonInformation={checkForObjectEquality(
                        selectedOrders,
                        "information",
                    )}
                />
            )}
            <ConfirmModal
                isOpen={openConfirmBatchRemoveModal}
                setIsOpen={setOpenConfirmBatchRemoveModal}
                onConfirm={() => {
                    removeBatchOrders();
                    setOpenConfirmBatchRemoveModal(false);
                    clearSelectedOrders();
                }}
                title={"Bekräfta borttagning"}
                description={`Är du säker på att du vill ta bort valda försändelser? (${selectedOrders?.length} st)`}
            />
            <div
                className="flex items-center"
                onBlur={(e) => {
                    if (!e.currentTarget.contains(e.relatedTarget)) {
                        close();
                    }
                }}
            >
                <div className="w-60 relative" ref={actionsRef}>
                    <button
                        className={`flex border border-gray-300 rounded-md items-center gap-3 w-full px-3 py-2 hover:bg-gray-100 bg-white justify-between transition-colors ${
                            selectedOrders?.length === 0
                                ? "opacity-50 bg-gray-300 hover:bg-gray-300 cursor-default"
                                : ""
                        }`}
                        onClick={() => setOpen(!open)}
                        disabled={selectedOrders?.length === 0}
                    >
                        Åtgärder
                        <img
                            src="/images/icons/chevron-down.svg"
                            alt="chevron-down"
                        />
                    </button>
                    {open && (
                        <ActionList
                            close={close}
                            actionItems={[
                                {
                                    title: "Redigera",
                                    subItems: [
                                        {
                                            title: "Ändra avsändare",
                                            onClick: () => {
                                                setFreightPartyType("sender");
                                                setOpenFreightPartyModal(true);
                                            },
                                        },
                                        {
                                            title: "Ändra mottagare",
                                            onClick: () => {
                                                setFreightPartyType("receiver");
                                                setOpenFreightPartyModal(true);
                                            },
                                        },
                                        {
                                            title: "Ändra gods",
                                            onClick: () => {
                                                setOpenPackagesModal(true);
                                            },
                                        },
                                        {
                                            title: "Ändra information",
                                            onClick: () => {
                                                setOpenInformationModal(true);
                                            },
                                        },
                                    ],
                                },
                                {
                                    title: "Hantera transporttjänst",
                                    onClick: () => setOpenQuotesModal(true),
                                    checkIfDisabled: () =>
                                        selectedOrders.length === 0,
                                },
                                {
                                    title: "Hantera upphämtningsdag",
                                    onClick: () => setOpenPickupModal(true),
                                    checkIfDisabled: () =>
                                        disableHandlePickupDate(),
                                },
                                {
                                    title: "Bokningsbekräftelse",
                                    checkIfDisabled: () =>
                                        selectedOrders.length === 0,
                                    subItems: [
                                        {
                                            title: "Redigera avsändare",
                                            onClick: () =>
                                                openEditNotificationModal(
                                                    "sender",
                                                ),
                                        },
                                        {
                                            title: "Redigera mottagare",
                                            onClick: () =>
                                                openEditNotificationModal(
                                                    "receiver",
                                                ),
                                        },
                                    ],
                                },
                                {
                                    title: "Ta bort",
                                    onClick: () =>
                                        setOpenConfirmBatchRemoveModal(true),
                                    checkIfDisabled: () =>
                                        selectedOrders.length === 0,
                                    extraClasses: "text-error-500",
                                },
                            ]}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Actions;
