import InputImage from "components/Input/Image";
import MultiToggle from "components/Input/MultiToggle";
import InputPhoneNumber from "components/Input/PhoneNumber";
import Input from "components/OldInput";
import Context from "context/Global";
import queryString from "query-string";
import React, { useContext, useEffect, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import toast from "react-hot-toast";

let Container = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 100%;

    > .title {
        font-size: 2rem;
        color: #aaa;
        width: 100%;
    }

    > .form {
        display: flex;
        width: 100%;
        gap: 1rem;
        height: 100%;
        overflow: hidden;

        > .column {
            max-width: 320px;
            flex-basis: 1 1 25%;
            padding: 1rem 0;
            padding-right: 1rem;
            overflow: auto;
            box-sizing: border-box;
            height: 100%;

            > .divider {
                border-bottom: 1px solid #ccc;
                padding-bottom: 1rem;
                margin-bottom: 1rem;
            }
        }
    }

    > .buttons {
        padding: 1rem 0;
    }
`;

function Component({ history, ...props }) {
    const context = useContext(Context);
    const [formObject, setFormObject] = useState(null);
    const [ticketData, setTicketData] = useState(autofillTicketData());
    const [reclaimObject, setReclaimObject] = useState(null);
    const [reclaimData, setReclaimData] = useState(null);
    const [ticket, setTicket] = useState(autofillTicket());

    function autofillTicket() {
        switch (queryString.parse(history.location.search).autofill) {
            case "tnt-pickup": {
                return {
                    title: "Omboka TNT upphämtning",
                    type: "BOOKING",
                    trackingNumber:
                        queryString.parse(history.location.search)
                            .trackingNumber || "",
                };
            }
        }

        return {};
    }
    function autofillTicketData() {
        switch (queryString.parse(history.location.search).autofill) {
            case "tnt-pickup": {
                return {
                    type:
                        queryString.parse(history.location.search).category ||
                        "",
                    trackingNumber:
                        queryString.parse(history.location.search)
                            .trackingNumber || "",
                    description: "Vi vill boka en ny upphämtning.",
                };
            }
        }

        return {};
    }

    const ticketFormRef = useRef();
    const ticketDataFormRef = useRef();
    const reclaimDataFormRef = useRef();

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        switch (ticket?.type) {
            case "RECLAIM": {
                setFormObject(null);
                setTimeout(() => {
                    setFormObject({
                        contactPhone: {
                            type: InputPhoneNumber,
                            title: "Ditt telefonnummer",
                            value: context.user.phoneNumber,
                            required: false,
                        },
                        transporter: {
                            type: "dropdown",
                            title: "Transportör",
                            required: true,
                            options: [
                                {
                                    title: "Välj",
                                    value: "",
                                },
                                {
                                    value: "DHL Freight",
                                },
                                {
                                    value: "DHL Express",
                                },
                                {
                                    value: "DSV",
                                },
                                {
                                    value: "Fedex",
                                },
                                {
                                    value: "Postnord",
                                },
                                {
                                    value: "TNT",
                                },
                                {
                                    value: "UPS",
                                },
                            ],
                        },
                        trackingNumber: {
                            type: "text",
                            title: "Trackingnummer",
                            value:
                                queryString.parse(history.location.search)
                                    .trackingNumber || "",
                            required: true,
                        },
                        ticketNumber: {
                            type: "text",
                            title: "Ev. ärendenummer från transportören",
                            required: false,
                        },
                        description: {
                            type: "textarea",
                            title: "Beskrivning av ärendet",
                            value: "",
                            placeholder: "",
                            maxLength: 600,
                            required: true,
                        },
                    });
                }, 0);
                break;
            }
            case "INVOICE":
            case "BOOKING":
            case "SEARCH":
            case "OTHER": {
                setFormObject(null);
                setTimeout(() => {
                    setFormObject({
                        contactPhone: {
                            type: InputPhoneNumber,
                            title: "Ditt telefonnummer",
                            value: context.user.phoneNumber,
                        },
                        trackingNumber: {
                            type: "text",
                            title: "Trackingnummer",
                            value:
                                queryString.parse(history.location.search)
                                    .trackingNumber || "",
                        },
                        description: {
                            type: "textarea",
                            title: "Beskrivning av ärendet",
                            value: "",
                            placeholder: "",
                            maxLength: 600,
                            required: true,
                        },
                        files: {
                            type: InputImage,
                            title: "Bilder och andra dokument",
                            required: false,
                        },
                    });
                }, 0);
                break;
            }
            default: {
                setFormObject(null);
            }
        }
    }, [ticket?.type]);

    useEffect(() => {
        if (ticket?.type === "RECLAIM") {
            if (
                [
                    "Fedex",
                    "UPS",
                    "TNT",
                    "DHL Express",
                    "DSV",
                    "Postnord",
                ].indexOf(ticketData?.transporter) >= 0
            ) {
                setReclaimObject({
                    damagedGoodsCount: {
                        type: "number",
                        min: 1,
                        title: "Antal skadade varor",
                        value: 0,
                        required: false,
                    },
                    damagedGoodsWeight: {
                        type: "number",
                        title: "Vikt på skadade varor",
                        unit: "kg",
                        min: 0,
                        value: 0,
                        required: false,
                    },
                    damagedGoodsValue: {
                        type: "number",
                        title: "Anskaffnings- eller tillverknings-värde på skadade varor",
                        unit: "SEK",
                        min: 0,
                        value: 0,
                        required: false,
                    },
                    goodsDescription: {
                        type: "textarea",
                        title: "Beskrivning av skadorna på godset",
                        value: "",
                        placeholder: "Detaljerad beskrivning...",
                        maxLength: 600,
                        required: false,
                    },
                    packageDescription: {
                        type: "textarea",
                        title: "Beskrivning av emballage (yttre och inre t.ex. wellpapp och kartong)",
                        value: "",
                        placeholder: "Beskrivning här...",
                        maxLength: 600,
                        required: false,
                    },
                    visualDamages: {
                        type: MultiToggle,
                        title: "Fanns synliga skador på förpackningen vid leverans?",
                        options: [
                            {
                                title: "Ja",
                                value: true,
                            },
                            {
                                title: "Nej",
                                value: false,
                            },
                        ],
                        required: true,
                    },
                    discoveryDate: {
                        type: "date",
                        title: "Datum skadan upptäcktes",
                        required: true,
                    },
                    files: {
                        type: InputImage,
                        title: "Bilder och andra dokument",
                        description:
                            "Ladda upp bilder på paketering och gods i sin helhet samt närbilder på skador.",
                        required: false,
                    },
                });
            } else if (["DHL Freight"].indexOf(ticketData?.transporter) >= 0) {
                setReclaimObject({
                    damagedParcelCount: {
                        type: "number",
                        min: 1,
                        title: "Antal skadade kolli",
                        value: 0,
                        required: false,
                    },
                    damagedGoodsWeight: {
                        type: "number",
                        title: "Vikt på skadade varor",
                        unit: "kg",
                        min: 0,
                        value: 0,
                        required: false,
                    },
                    goodsDescription: {
                        type: "textarea",
                        title: "Beskrivning av skadorna på godset",
                        value: "",
                        placeholder: "Detaljerad beskrivning...",
                        maxLength: 600,
                        required: false,
                    },
                    goodsType: {
                        type: "dropdown",
                        title: "Varugrupp",
                        value: "",
                        options: [
                            {
                                title: "--",
                                value: "",
                            },
                            {
                                value: "Byggmaterial",
                            },
                            {
                                value: "Böcker & Trycksaker",
                            },
                            {
                                value: "Elektronikvaror",
                            },
                            {
                                value: "Industrivaror",
                            },
                            {
                                value: "Konfektion",
                            },
                            {
                                value: "Livsmedel",
                            },
                            {
                                value: "Möbler & inredningar",
                            },
                            {
                                value: "Vitvaror",
                            },
                            {
                                value: "Verktyg",
                            },
                        ],
                        required: true,
                    },
                    outerPackagingType: {
                        type: "dropdown",
                        title: "Ytteremballage",
                        value: "",
                        options: [
                            {
                                title: "--",
                                value: "",
                            },
                            {
                                value: "Bandat m hörn & kantskydd",
                            },
                            {
                                value: "Bur",
                            },
                            {
                                value: "Dunk",
                            },
                            {
                                value: "Fat",
                            },
                            {
                                value: "Häck",
                            },
                            {
                                value: "Kartong",
                            },
                            {
                                value: "Låda",
                            },
                            {
                                value: "Oemballerat",
                            },
                            {
                                value: "Paket",
                            },
                            {
                                value: "Pallkragar",
                            },
                            {
                                value: "Plastfilm",
                            },
                            {
                                value: "Säck",
                            },
                            {
                                title: "Annat, beskriv nedan",
                                value: "OTHER",
                            },
                        ],
                        required: true,
                    },
                    innerPackagingType: {
                        type: "dropdown",
                        title: "Innerballage",
                        value: "",
                        options: [
                            {
                                title: "--",
                                value: "",
                            },
                            {
                                value: "Bubbelplast",
                            },
                            {
                                value: "Distansbitar och hörnskydd",
                            },
                            {
                                value: "Frigolit chips",
                            },
                            {
                                value: "Frigolit formanpassad",
                            },
                            {
                                value: "Inget",
                            },
                            {
                                value: "Träull",
                            },
                            {
                                value: "Wellpapp",
                            },
                            {
                                title: "Annat, beskriv nedan",
                                value: "OTHER",
                            },
                        ],
                        required: true,
                    },
                    packageDescription: {
                        type: "textarea",
                        title: "Ytterligare beskrivning av emballage",
                        value: "",
                        placeholder: "Beskrivning här...",
                        maxLength: 600,
                        required: false,
                    },
                    visualDamages: {
                        type: MultiToggle,
                        title: "Fanns synliga skador på förpackningen vid leverans?",
                        options: [
                            {
                                title: "Ja",
                                value: true,
                            },
                            {
                                title: "Nej",
                                value: false,
                            },
                        ],
                        required: true,
                    },
                    damageExtent: {
                        type: "dropdown",
                        title: "Skadans omfattning",
                        value: "",
                        options: [
                            {
                                title: "--",
                                value: "",
                            },
                            {
                                value: "Totalskada/Godset obrukbart",
                            },
                            {
                                value: "Reparation möjlig",
                            },
                            {
                                value: "Prisreducering",
                            },
                        ],
                        required: true,
                    },
                    damageCost: {
                        type: "number",
                        title: "Uppskattad skadekostnad i SEK",
                        unit: "SEK",
                        min: 0,
                        value: 0,
                        required: false,
                    },
                    files: {
                        type: InputImage,
                        title: "Bilder, handelsfaktura och andra dokument",
                        description:
                            "Ladda upp bilder på paketering och gods i sin helhet samt närbilder på skador, eventuell handelsfaktura och andra dokument.",
                        required: false,
                    },
                });
            }
        } else {
            setReclaimObject(null);
        }
    }, [ticketData, ticket?.type]);

    useEffect(() => {
        if (formObject) {
            setTicketData(ticketDataFormRef.current.value());
        }
    }, [formObject]);

    async function init() {
        // Get my orders? Or replace the text input with a "search order input". Then you can select both.
    }

    async function createTicket(ev) {
        ev.preventDefault();
        let valid = true;
        valid = valid && ticketFormRef.current.validate();
        if (ticketDataFormRef && ticketDataFormRef.current) {
            valid = valid && ticketDataFormRef.current.validate();
        }

        if (ticketData.trackingNumber) {
            ticket.trackingNumber = ticketData.trackingNumber;
            delete ticketData.trackingNumber;
        }

        if (valid) {
            try {
                context.socket.emit(
                    "createTicket",
                    {
                        ...ticket,
                        data: {
                            ...ticketData,
                            ...reclaimData,
                        },
                    },
                    (newTicket) => {
                        history.replace(`/tickets/${newTicket.id}`);
                    },
                );
            } catch (error) {
                toast.error("Kunde inte skapa ett ärende");
            }
        }
    }

    return (
        <Container>
            <div className="title">Nytt ärende</div>
            <div className="form">
                <div className="column">
                    <Input
                        ref={ticketFormRef}
                        value={ticket}
                        type="list"
                        onChange={setTicket}
                        object={{
                            title: {
                                type: "text",
                                title: "Ämne",
                                required: true,
                            },
                            type: {
                                type: "dropdown",
                                title: "Kategori",
                                required: true,
                                options: [
                                    {
                                        title: "Välj kategori",
                                        value: "",
                                    },
                                    {
                                        title: "Problem vid bokning",
                                        value: "BOOKING",
                                    },
                                    {
                                        title: "Fråga om leverans",
                                        value: "SEARCH",
                                    },
                                    {
                                        title: "Fakturafråga",
                                        value: "INVOICE",
                                    },
                                    {
                                        title: "Reklamation",
                                        value: "RECLAIM",
                                    },
                                    {
                                        title: "Övrigt",
                                        value: "OTHER",
                                    },
                                ],
                            },
                        }}
                    />
                    {formObject && (
                        <>
                            <div className="divider"></div>
                            <Input
                                ref={ticketDataFormRef}
                                type="list"
                                onChange={setTicketData}
                                object={formObject}
                                value={ticketData}
                            />
                        </>
                    )}
                </div>
                {reclaimObject && (
                    <div className="column">
                        <Input
                            ref={reclaimDataFormRef}
                            type="list"
                            onChange={setReclaimData}
                            object={reclaimObject}
                        />
                    </div>
                )}
            </div>
            <div className="buttons">
                <button
                    className="c-button c-button--raised"
                    onClick={createTicket}
                >
                    Skapa ärende
                </button>
            </div>
        </Container>
    );
}

export default Component;
