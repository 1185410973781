import React, { useContext, useState } from "react";
import Api from "../../../../services/Api/Api";
import Context from "../../../../context/Global";
import toast from "react-hot-toast";
import FormSection from "../../components/FormSection";
import TextSection from "../../components/TextSection";
import Input from "../../../../components/OldInput";
import { handleDownloadS3 } from "../../../../utils/s3";
import Integrations from "../../../../constants/integrations";
import { getIntegrationName } from "../../../../helpers/Integrations";

const IntegrationForm = ({ history }) => {
    const context = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        system: "",
        otherSystem: "",
        webhookUrl: "",
    });

    const integrationOptions = Object.keys(Integrations)
        .map((integration) => {
            const prettyName = getIntegrationName(integration);
            if (prettyName) {
                return {
                    value: prettyName,
                };
            }

            return null;
        })
        .filter(Boolean);

    const handleChange = (value) => {
        setFormData((prevData) => ({
            ...prevData,
            ...value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (formData.system === Integrations.OTHER && !formData.otherSystem) {
            toast.error(
                "Fältet 'Ange System Namn' är obligatoriskt när 'Annat System' är valt.",
            );
            return;
        }

        if (formData.webhookUrl) {
            try {
                setIsLoading(true);
                await Api.verfifyWebhookUrl(formData.webhookUrl);
            } catch (error) {
                toast.error("Webhook URL är felaktig eller ej nåbar.");
                setIsLoading(false);
                return;
            }
        }

        try {
            const integration = await Api.createCustomerIntegration({
                customerId: context.user.customer.id,
                data: {
                    config: {
                        system: formData.system,
                        otherSystem: formData.otherSystem,
                        webhookUrl: formData.webhookUrl,
                    },
                    type: "Zendr",
                },
            });
            toast.success("API användare skapades!");
            history.push(`/addons/${integration.id}`);
        } catch (error) {
            toast.error(
                "Misslyckades med att skapa API användare. Se över dina inställningar och försök igen!",
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="max-w-3xl mx-auto my-4 p-4 bg-white shadow-lg rounded-md">
            <h2 className="text-2xl font-bold mb-4 text-left">
                Skapa API Användare
            </h2>
            <TextSection
                title="Fördelar med att använda vårt API"
                paragraphs={[
                    "Smidig orderhantering där du kan skapa, uppdatera och övervaka alla dina ordrar.",
                    "Realtidsuppdateringar med spårningsinformation så att du alltid vet var dina leveranser befinner sig.",
                    "Möjlighet att automatiskt ta emot uppdateringar via webhook för en ännu mer integrerad upplevelse.",
                ]}
            />
            <TextSection
                title="Hur fungerar webhooks?"
                paragraphs={[
                    "En webhook är en callback URL som vi använder för att skicka realtidsuppdateringar om dina ordrar. När en order bokats i vårt system, skickar vi automatiskt en POST-förfrågan till den angivna webhook-URL:en. För att kunna ta emot dessa uppdateringar måste du ha en endpoint som kan ta emot och hantera POST-förfrågningar.",
                    "Webhooken skickar data i JSON-format som innehåller information om orderns aktuella status, spårningsnummer och annan relevant spårningsdata. Detta gör det möjligt för dig att hålla ditt system synkroniserat med vår plattform och få uppdateringar i realtid om statusen för dina ordrar.",
                    "Om du inte anger en webhook-URL kommer du inte att ta emot dessa uppdateringar, men du kan fortfarande hämta spårningsinformation manuellt via vårt API.",
                ]}
            />
            <div className="mb-4">
                <TextSection
                    title="OpenAPI Specifikation"
                    paragraphs={[
                        "Du kan ladda ner vår OpenAPI-specifikation för att få en detaljerad beskrivning av alla våra API-endpoints och hur du använder dem.",
                    ]}
                />
                <button
                    className="c-button c-button--outlined"
                    onClick={() =>
                        handleDownloadS3({
                            url: `${
                                process.env.REACT_APP_API_URL
                            }/api/s3/integration?objectKey=${encodeURIComponent(
                                "zendr/zendr-openapi.yaml",
                            )}`,
                            desiredFileName: "zendr-openapi.yaml",
                        })
                    }
                >
                    Ladda ner OpenAPI Specifikation
                </button>
            </div>
            <form
                onSubmit={handleSubmit}
                className="grid grid-cols-1 md:grid-cols-2 gap-4"
                noValidate
            >
                <FormSection title="API-inloggningsuppgifter">
                    <Input
                        onChange={(value) =>
                            handleChange({
                                system: value.system,
                            })
                        }
                        type="list"
                        object={{
                            system: {
                                title: "Systeminformation",
                                type: "dropdown",
                                autoComplete: "chrome-off",
                                required: true,
                                options: integrationOptions,
                                value: formData.system,
                            },
                        }}
                    />
                    {formData.system === Integrations.OTHER && (
                        <Input
                            onChange={(value) =>
                                handleChange({
                                    otherSystem: value.otherSystem,
                                })
                            }
                            type="list"
                            object={{
                                otherSystem: {
                                    title: "Ange System Namn",
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    required: true,
                                    value: formData.otherSystem,
                                },
                            }}
                        />
                    )}
                    <Input
                        onChange={(value) =>
                            handleChange({
                                webhookUrl: value.webhookUrl,
                            })
                        }
                        type="list"
                        object={{
                            webhookUrl: {
                                title: "Webhook URL",
                                type: "text",
                                autoComplete: "chrome-off",
                                value: formData.webhookUrl,
                            },
                        }}
                    />
                </FormSection>

                <div className="col-span-1 md:col-span-2 flex justify-end">
                    <button
                        type="submit"
                        className={`w-1/3 c-button c-button--raised text-white p-2 rounded`}
                        disabled={isLoading}
                    >
                        {isLoading ? "Aktiverar..." : "Aktivera"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default IntegrationForm;
