import moment from "moment";

/**
 * Those are the lifespans of refresh tokens for integrations
 * @type {{BUSINESS_CENTRAL_REFRESH_TOKEN_LIFESPAN: number, FORNTOX_REFRESH_TOKEN_LIFESPAN: number}}
 *
 * @Fortnox official docs https://www.fortnox.se/developer/authorization
 * @Business Central offical docs https://learn.microsoft.com/en-gb/entra/identity-platform/configurable-token-lifetimes#configurable-token-lifetime-properties
 */
export default {
    // For SPA applications, the refresh token lifespan is 1 day for BC.
    BUSINESS_CENTRAL_REFRESH_TOKEN_LIFESPAN: moment().add(1, "days").unix(),
    FORNTOX_REFRESH_TOKEN_LIFESPAN: moment().add(45, "days").unix(),
};
