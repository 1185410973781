import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components/macro";

let Container = styled.div`
    width: 100%;

    .tabs {
        display: flex;
        flex-direction: row;

        > .tab {
            background: #ddd;
            border: 1px solid #aaa;
            border-right: 0;
            margin-bottom: -1px;
            padding: 0.5rem 1rem;
            z-index: 2;
            cursor: pointer;

            &:last-child {
                border-right: 1px solid #aaa;
            }

            &:hover {
                background: #eee;
            }

            &.is-selected {
                background: #fff;
                border-bottom: 0;
            }
        }
    }

    > .content {
        border: 1px solid #aaa;

        > .wrapper {
            display: none;
            width: 100%;

            > textarea,
            > .result {
                background: #fff;
                width: 100%;
                min-height: 240px;
                padding: 8px;
                border: 0;
                box-sizing: border-box;
                overflow-x: hidden;
                outline: none;
                resize: none;
            }

            &.is-visible {
                display: inline-block;
            }
        }
    }

    &.is-invalid {
        > textarea {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }
    }
`;

class InputTextarea extends Component {
    constructor(props) {
        super(props);
        this.textarea = React.createRef();
        this.state = {
            value: props.value || "",
            view: "edit",
        };
        if (typeof this.props.onChange === "function") {
            this.onChange = this.props.onChange;
        }

        this.view = this.view.bind(this);
        this.edit = this.edit.bind(this);
    }

    onChange() {}

    value() {
        return this.state.value;
    }

    set(value) {
        this.setState({
            value: value,
        });
    }

    empty() {
        this.setState({
            value: "",
        });
    }

    focus() {
        this.textarea.focus();
    }

    onBlur() {
        this.textarea.onBlur();
    }

    validate() {
        if (this.props.required && this.state.value.length === 0) {
            this.setState({
                isInvalid: true,
            });
            return false;
        }
        this.setState({
            isInvalid: false,
        });
        return true;
    }

    edit() {
        this.setState({
            view: "edit",
        });
    }

    view() {
        this.setState({
            view: "view",
        });
    }

    render() {
        return (
            <Container className={this.state.isInvalid ? " is-invalid" : ""}>
                <div className="tabs">
                    <div
                        className={`tab${
                            this.state.view === "edit" ? " is-selected" : ""
                        }`}
                        onClick={this.edit}
                    >
                        Redigera
                    </div>
                    <div
                        className={`tab${
                            this.state.view === "view" ? " is-selected" : ""
                        }`}
                        onClick={this.view}
                    >
                        Resultat
                    </div>
                </div>
                <div className="content">
                    <div
                        className={`wrapper${
                            this.state.view === "view" ? " is-visible" : ""
                        }`}
                    >
                        <ReactMarkdown
                            className="result"
                            renderers={{
                                link: (props) => (
                                    <a href={props.href} target="_blank">
                                        {props.children}
                                    </a>
                                ),
                            }}
                        >
                            {this.state.value}
                        </ReactMarkdown>
                    </div>
                    <div
                        className={`wrapper${
                            this.state.view === "edit" ? " is-visible" : ""
                        }`}
                    >
                        <textarea
                            ref={this.textarea}
                            {...this.props}
                            value={this.state.value}
                            onChange={(event) => {
                                this.setState(
                                    {
                                        value: event.target.value,
                                        isInvalid: false,
                                    },
                                    () => {
                                        this.onChange(this.value());
                                    },
                                );
                            }}
                        />
                    </div>
                </div>
                <a
                    href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
                    target="_blank"
                    className="cheatSheet"
                >
                    Hur fungerar det här?
                </a>
            </Container>
        );
    }
}

export default InputTextarea;
