import React, { useState, useContext, useEffect, useRef } from "react";
import JSONPretty from "react-json-pretty";
import styled from "styled-components/macro";

let Container = styled.div`
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background: #fff;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    align-items: flex-start;

    display: flex;
    flex-direction: row;

    > .section {
        width: 50%;
        min-width: 50%;

        > .title {
            font-weight: bold;
        }

        > .data {
            overflow-x: auto;
        }
    }
`;

function Component({ rawRequest, rawResponse }) {
    return (
        <Container>
            <div className="section">
                <div className="title">Request</div>
                <div className="data">
                    <JSONPretty data={rawRequest} />
                </div>
            </div>
            <div className="section">
                <div className="title">Response</div>
                <div className="data">
                    <JSONPretty data={rawResponse} />
                </div>
            </div>
        </Container>
    );
}

export default Component;
