import PackageAggregate from "components/PackageAggregate";
import Context from "context/Global";
import React, {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";
import Row from "./Row";

let Container = styled.div`
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background: #fff;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    > .rows {
        display: flex;
        flex-direction: column;
        min-height: 200px;

        > .titles {
            display: flex;
            flex-direction: row;
            color: #000;
            padding: 0 8px;

            > div {
                margin: 0 8px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            > .count {
                width: 80px;
                padding: 8px;
            }

            > .toggle {
                padding: 8px;
                cursor: pointer;
                display: flex;
                font-size: 8px;
                align-items: center;
                font-weight: bold;
                gap: 4px;
                border-radius: 500px;
                margin-left: auto;

                &:hover {
                    background: #fafafa;
                    color: #333;
                }

                > svg {
                    width: 12px;
                    height: 12px;
                }
            }

            > .type {
                width: 160px;
                padding: 8px;
            }

            > .dimension {
                width: 140px;
                padding: 8px;

                &.is-hidden {
                    opacity: 0;
                    pointer-events: none;
                }
            }
        }

        > .error {
            font-size: 1em;
            padding: 1em;
            color: rgba(255, 0, 0, 0.6);
            font-weight: bold;
        }

        > .packageRow {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 8px;
            border-radius: 5px;

            &:hover {
                background: rgba(0, 0, 0, 0.02);
            }
        }

        > .buttons {
            display: flex;
            justify-content: flex-end;
            padding: 16px 0 32px;
            margin-top: auto;
        }
    }
`;

function Component(props, ref) {
    const context = useContext(Context);
    const [rows, setRows] = useState(props.packages || []);
    const [error, setError] = useState(null);
    const [validMessage, setValidMessage] = useState("");
    const [packageTypes, setPackageTypes] = useState(null);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        if (!rows.length) {
            addRow();
        }

        setPackageTypes(
            await Api.getPackageTypes({
                customerId: context.user.customer.id,
                cancelToken: null,
            }),
        );
    }
    const canBookBatchDirect =
        context.user.customer.settings.bookBatchDirectWithoutQuote &&
        props.fromBatch;

    useEffect(() => {
        if (props.onChange) {
            props.onChange(rows);
        }
        setError(null);
        setValidMessage("");

        if (rows && rows.length) {
            rows.forEach((row) => {
                if (row.type === "envelope") {
                    if (!row.envelopeContents) {
                        setValidMessage(
                            "Välj vad för innehåll du har i ditt envelope.",
                        );
                    }
                } else if (
                    !row.productSpecificPackage &&
                    context.user.config.packageSizeDefinition !== "volume" &&
                    !canBookBatchDirect &&
                    (!row.count ||
                        !row.length ||
                        !row.width ||
                        !row.height ||
                        !row.weight)
                ) {
                    setValidMessage(
                        "Antal, längd, bredd, höjd och vikt måste vara större än 0.",
                    );
                } else if (
                    context.user.config.packageSizeDefinition === "volume" &&
                    (!row.count || !row.weight)
                ) {
                    setValidMessage("Antal och vikt måste vara större än 0.");
                } else if (row.stackable === null) {
                    setValidMessage(
                        "Välj om din pall är stapelbar eller inte.",
                    );
                } else if (
                    context.user.customer.settings.goodsRowDescription &&
                    !row.description
                ) {
                    setValidMessage(
                        "Godsbeskrivning måste anges på varje godsrad.",
                    );
                } else if (row.volume && row.loadingMeters) {
                    setValidMessage(
                        "Ett kolli kan inte beskrivas med volym och flakmeter samtidigt.",
                    );
                } else if (row.productSpecificPackage) {
                    switch (row.productSpecificPackage) {
                        case "DHL Paket":
                        case "DHL Service Point":
                        case "DHL Service Point Retur":
                        case "DHL Paket Export": {
                            break;
                        }
                        case "DHL Stycke":
                        case "DHL Parti":
                        case "DHL EuroConnect":
                        case "DHL Euroline":
                        case "DHL EuroConnectPlus": {
                            if (
                                context.user.config.packageSizeDefinition ===
                                "volume"
                            ) {
                                if (!row.volume && !row.loadingMeters) {
                                    setValidMessage(
                                        "Denna DHL-produkt kräver antingen volym eller flakmeter.",
                                    );
                                }
                            }
                            break;
                        }
                    }
                }
            });
        }
    }, [rows]);

    const validate = () => {
        if (!rows.length) {
            setError("Tryck på 'Lägg till rad' och beskriv er försändelse.");
            return false;
        }
        if (validMessage) {
            setError(validMessage);
            return false;
        }
        return true;
    };

    const clear = () => {
        setRows([
            {
                id: uuidv4(),
                count: 1,
                weight: 0,
                length: 0,
                width: 0,
                height: 0,
                stackable: true,
                type: "package",
            },
        ]);
    };

    const set = (rows) => {
        setRows(rows);
    };

    useImperativeHandle(ref, () => {
        return {
            validate: validate,
            clear: clear,
            set: set,
        };
    });

    function addRow(newRow = null) {
        setRows((rows) => {
            if (newRow) {
                return rows.concat(newRow);
            } else {
                return rows.concat([
                    {
                        id: uuidv4(),
                        count: 1,
                        weight: 0,
                        length: 0,
                        width: 0,
                        height: 0,
                        stackable: true,
                        type: "package",
                    },
                ]);
            }
        });
    }

    function deleteRow(rowId) {
        setRows((rows) => {
            return rows.filter((row) => {
                return rowId !== row.id;
            });
        });
    }

    function onlyDocuments() {
        return !rows.reduce((onlyDocuments, row) => {
            return onlyDocuments && row.type === "envelope";
        }, true);
    }

    if (packageTypes === null) {
        return null;
    }

    async function changePackageSizeDefinition() {
        await Api.updateUserConfig({
            cancelToken: null,
            userId: context.user.id,
            config: {
                packageSizeDefinition:
                    context.user.config.packageSizeDefinition === "dimensions"
                        ? "volume"
                        : "dimensions",
            },
        });
        context.setUser(await Api.getCurrentUser());
    }

    return (
        <Container>
            <div className="rows">
                <div className="titles">
                    <div className="count">Antal</div>
                    <div className="type">Typ</div>
                    {onlyDocuments() && (
                        <>
                            {context.user.config.packageSizeDefinition ===
                            "volume" ? (
                                <>
                                    <div className="dimension volume">
                                        Volym
                                    </div>
                                    <div className="dimension volume">
                                        Flakmeter
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="dimension">Längd</div>
                                    <div className={`dimension`}>Bredd</div>
                                    <div className={`dimension`}>Höjd</div>
                                </>
                            )}
                            <div className="dimension">Vikt</div>
                            {context.user.config.packageSizeDefinition !==
                                "volume" && (
                                <div className="dimension">Stapelbar</div>
                            )}
                            {context.user.customer.settings
                                .togglePackageSizeDefinition && (
                                <div
                                    className="toggle"
                                    onClick={changePackageSizeDefinition}
                                >
                                    dim{" "}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="0.26in"
                                        height="0.26in"
                                        viewBox="0 0 78 78"
                                    >
                                        <path
                                            id="Selection"
                                            fill="currentColor"
                                            d="M 58.00,1.00 C 61.12,-3.50 75.31,13.13 75.26,16.91 75.23,19.07 72.88,21.53 71.53,23.09 67.50,27.76 60.88,36.69 58.00,33.00 56.06,30.06 61.37,24.65 66.00,20.00 66.00,20.00 23.00,20.00 23.00,20.00 17.52,20.03 13.29,20.34 9.65,25.10 6.73,28.93 6.93,35.55 3.21,34.27 0.72,33.41 1.28,29.92 1.77,28.00 3.26,22.10 7.42,17.76 13.00,15.48 18.16,13.37 26.30,14.00 32.00,14.00 32.00,14.00 66.00,14.00 66.00,14.00 61.57,9.47 55.31,4.94 58.00,1.00 Z M 11.00,58.00 C 11.00,58.00 53.00,58.00 53.00,58.00 58.94,57.99 63.16,57.95 67.20,52.82 70.41,48.73 69.87,42.48 73.74,43.74 76.55,44.66 75.41,49.00 74.78,51.00 72.93,56.79 68.72,60.90 63.00,62.89 58.36,64.50 50.13,64.00 45.00,64.00 45.00,64.00 11.00,64.00 11.00,64.00 11.00,64.00 19.50,73.19 17.41,76.60 14.09,80.27 1.63,64.07 1.63,61.00 1.63,57.47 15.31,42.81 17.79,45.73 20.56,48.81 11.00,58.00 11.00,58.00 Z"
                                        />
                                    </svg>{" "}
                                    vol
                                </div>
                            )}
                        </>
                    )}
                </div>
                {rows.map((row, index) => {
                    return (
                        <div key={row.id} className="packageRow">
                            <Row
                                value={row}
                                packageTypes={packageTypes}
                                onChange={(value) => {
                                    setRows((rows) => {
                                        return rows.map((r, i) => {
                                            if (index === i) {
                                                return value;
                                            }
                                            return r;
                                        });
                                    });
                                }}
                                onDuplicate={addRow}
                                onDelete={deleteRow}
                                fromBatch={props.fromBatch}
                            />
                        </div>
                    );
                })}
                {error && <div className="error">{error}</div>}
                <div className="buttons">
                    <div
                        className="c-button c-button--raised"
                        onClick={() => addRow()}
                    >
                        Lägg till rad
                    </div>
                </div>
            </div>
            <PackageAggregate packages={rows} />
        </Container>
    );
}

export default forwardRef(Component);
