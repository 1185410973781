import PackageAggregate from "components/PackageAggregate";
import Context from "context/Global";
import React, { useContext } from "react";
import styled from "styled-components/macro";

let Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    align-items: flex-start;
    background: #fff;
    padding: 16px;
    width: 100%;
`;

let Route = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    width: 100%;

    > .party {
        width: 50%;

        > .title {
            color: rgba(0, 0, 0, 0.6);
        }

        > .company {
            font-weight: bold;
        }
    }
`;

let PackagesTable = styled.table`
    margin-bottom: 16px;
    width: 100%;
`;

function Component({ packages }) {
    const context = useContext(Context);

    function prettyType(type) {
        switch (type) {
            case "pallet":
                return "Pall";
            case "package":
                return "Paket";
            case "envelope":
                return "Envelope";
        }
    }

    return (
        <Container>
            <PackagesTable>
                <thead>
                    <tr>
                        <th>Antal</th>
                        <th>Typ</th>
                        <th>Dimensioner</th>
                        <th>Vikt</th>
                        <th>Stapelbar</th>
                    </tr>
                </thead>
                <tbody>
                    {packages.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.count} st</td>
                                <td>{prettyType(item.type)}</td>
                                {item.length > 0 ? (
                                    <td>
                                        {item.length}x{item.width}x{item.height}{" "}
                                        cm
                                    </td>
                                ) : item.volume ? (
                                    <td>
                                        {item.volume} m<sup>3</sup>
                                    </td>
                                ) : (
                                    <td>-</td>
                                )}
                                <td>{item.weight} kg</td>
                                <td>{item.stackable ? "Ja" : "Nej"}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </PackagesTable>
            <PackageAggregate packages={packages} />
        </Container>
    );
}

export default Component;
