import Context from "context/Global";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import ChatImage from "./Images/chat.png";
import PhoneImage from "./Images/phone.png";

let Container = styled.div`
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    > .left,
    > .right {
        width: 50%;
    }

    > .left {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        > .support,
        > .forwarding {
            > .title {
                font-size: 2rem;
                color: #aaa;
                margin-bottom: 1rem;
                width: 100%;

                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            > .tickets {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                > .emptyState {
                    background: url(${ChatImage}) 4rem 4rem no-repeat;

                    > .title {
                        font-size: 1.5rem;
                        padding-bottom: 320px;
                    }
                }

                > .ticket {
                    text-decoration: none;
                    color: #000;
                    display: flex;
                    flex-direction: column;
                    background: #fff;
                    border: 1px solid #aaa;
                    border-radius: 1rem;
                    padding: 1rem;

                    &.is-highlighted {
                        background: #fbefb6;
                    }

                    > .header {
                        display: flex;
                        font-size: 0.8rem;
                        align-items: center;
                        gap: 0.5rem;

                        > .info {
                            margin-right: auto;
                        }

                        .indicator {
                            width: 16px;
                            height: 16px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #000;

                            > svg {
                                width: 100%;
                                height: 1005;
                            }
                        }
                    }

                    > .title {
                        display: inline-block;
                        padding: 1rem 0;
                    }

                    > .footer {
                        font-style: italic;
                    }
                }
            }
        }
    }

    > .right {
        padding: 4rem 1rem;
        text-align: center;

        > .information {
            display: flex;
            flex-direction: column;
            font-size: 1.6rem;
            background: url(${PhoneImage}) center 4rem no-repeat;

            > .title {
                font-size: 1.5rem;
                padding-bottom: 320px;
            }

            .phone {
                font-size: 3rem;
                margin-bottom: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;

                > a {
                    text-decoration: none;
                    color: #000;
                    margin-right: 48px;
                }

                > svg {
                    height: 3rem;
                    width: 3rem;

                    &:nth-child(2) {
                        height: 9rem;
                        width: 9rem;
                    }
                }
            }
        }
    }
`;

function Component(props) {
    const context = useContext(Context);
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const ticketStatusOrder = {
        NEW: 0,
        ONGOING: 1,
        CLOSED: 2,
    };

    useEffect(() => {
        if (context.socket) {
            context.socket.emit("openCustomerTickets", (tickets) => {
                setTickets(tickets.sort(orderTickets));
            });
            context.socket.on("ticketUpdated", updateTicket);
            context.socket.on("ticketCreated", createTicket);

            return () => {
                context.socket.emit("closeCustomerTickets");
                context.socket.off("ticketUpdated");
                context.socket.off("ticketCreated");
            };
        }
    }, [context.socket]);

    function orderTickets(a, b) {
        if (a.customerNotification) {
            if (b.customerNotification) {
                return sortByStatus(a, b);
            }
            return -1;
        } else if (b.customerNotification) {
            return 1;
        } else {
            return sortByStatus(a, b);
        }
    }

    function sortByStatus(a, b) {
        if (ticketStatusOrder[a.status] < ticketStatusOrder[b.status]) {
            return -1;
        } else if (ticketStatusOrder[a.status] > ticketStatusOrder[b.status]) {
            return 1;
        } else {
            return 0;
        }
    }

    function updateTicket(updatedTicket) {
        // Update ticket in tickets.
        setTickets((ticketList) => {
            return ticketList
                .map((ticket) => {
                    if (ticket.id === updatedTicket.id) {
                        return updatedTicket;
                    } else {
                        return ticket;
                    }
                })
                .sort(orderTickets);
        });
    }

    function createTicket(newTicket) {
        // Add a ticket to tickets.
        setTickets((ticketList) => {
            return ticketList.concat(newTicket).sort(orderTickets);
        });
    }

    return (
        <Container>
            <div className="left">
                <div className="support">
                    <div className="title">
                        Mina ärenden{" "}
                        <Link
                            className="c-button c-button--raised"
                            to="/tickets/create"
                        >
                            Nytt ärende
                        </Link>
                    </div>
                    <div className="tickets">
                        {tickets
                            .filter((ticket) => ticket.type !== "FORWARDING")
                            .map((ticket) => {
                                return (
                                    <Link
                                        key={ticket.id}
                                        className={`ticket ${
                                            !!ticket.customerNotification
                                                ? "is-highlighted"
                                                : ""
                                        }`}
                                        to={`/tickets/${ticket.id}`}
                                    >
                                        <div className="header">
                                            <div className="info">
                                                #{ticket.id}:{" "}
                                                {t(
                                                    `tickets.type.${ticket.type}`,
                                                )}
                                            </div>
                                            <div
                                                className={`status is-${ticket.status.toLowerCase()}`}
                                            >
                                                {t(
                                                    `tickets.status.${ticket.status}`,
                                                )}
                                            </div>
                                            {!!ticket.customerNotification && (
                                                <div className="indicator">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M12 3c5.514 0 10 3.592 10 8.007 0 4.917-5.145 7.961-9.91 7.961-1.937 0-3.383-.397-4.394-.644-1 .613-1.595 1.037-4.272 1.82.535-1.373.723-2.748.602-4.265-.838-1-2.025-2.4-2.025-4.872-.001-4.415 4.485-8.007 9.999-8.007zm0-2c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 1.418.345 2.775.503 4.059.503 7.084 0 11.91-4.837 11.91-9.961-.001-5.811-5.702-10.006-12.001-10.006zm-3.5 10c0 .829-.671 1.5-1.5 1.5-.828 0-1.5-.671-1.5-1.5s.672-1.5 1.5-1.5c.829 0 1.5.671 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5zm5 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5z"
                                                        />
                                                    </svg>
                                                </div>
                                            )}
                                        </div>
                                        <div className="title">
                                            {ticket.title}
                                        </div>
                                        <div className="footer">
                                            Skapades av {ticket.createdByName}
                                        </div>
                                    </Link>
                                );
                            })}
                        {!loading && tickets.length === 0 && (
                            <div className="emptyState">
                                <div className="title">
                                    Skapa ett nytt ärende så hjälper vi dig!
                                </div>
                                <div className="text">
                                    Här får du hjälp med alla ärenden:
                                    <br />
                                    <ul>
                                        <li>Gällande sändningar</li>
                                        <li>Reklamation</li>
                                        <li>Fakturor</li>
                                        <li>Övrigt</li>
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="forwarding">
                    <div className="title">Speditionsärenden</div>
                    <div className="tickets">
                        {tickets
                            .filter((ticket) => ticket.type === "FORWARDING")
                            .map((ticket) => {
                                return (
                                    <Link
                                        key={ticket.id}
                                        className={`ticket ${
                                            !!ticket.customerNotification
                                                ? "is-highlighted"
                                                : ""
                                        }`}
                                        to={`/tickets/${ticket.id}`}
                                    >
                                        <div className="header">
                                            <div className="info">
                                                #{ticket.id}:{" "}
                                                {t(
                                                    `tickets.type.${ticket.type}`,
                                                )}
                                            </div>
                                            <div
                                                className={`status is-${ticket.status.toLowerCase()}`}
                                            >
                                                {t(
                                                    `tickets.status.${ticket.status}`,
                                                )}
                                            </div>
                                            {!!ticket.customerNotification && (
                                                <div className="indicator">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M12 3c5.514 0 10 3.592 10 8.007 0 4.917-5.145 7.961-9.91 7.961-1.937 0-3.383-.397-4.394-.644-1 .613-1.595 1.037-4.272 1.82.535-1.373.723-2.748.602-4.265-.838-1-2.025-2.4-2.025-4.872-.001-4.415 4.485-8.007 9.999-8.007zm0-2c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 1.418.345 2.775.503 4.059.503 7.084 0 11.91-4.837 11.91-9.961-.001-5.811-5.702-10.006-12.001-10.006zm-3.5 10c0 .829-.671 1.5-1.5 1.5-.828 0-1.5-.671-1.5-1.5s.672-1.5 1.5-1.5c.829 0 1.5.671 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5zm5 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5z"
                                                        />
                                                    </svg>
                                                </div>
                                            )}
                                        </div>
                                        <div className="title">
                                            {ticket.title}
                                        </div>
                                        <div className="footer">
                                            Skapades av {ticket.createdByName}
                                        </div>
                                    </Link>
                                );
                            })}
                        {!loading && tickets.length === 0 && (
                            <div className="emptyState">
                                <div className="title">
                                    Skapa ett nytt ärende så hjälper vi dig!
                                </div>
                                <div className="text">
                                    Här får du hjälp med alla ärenden:
                                    <br />
                                    <ul>
                                        <li>Gällande sändningar</li>
                                        <li>Reklamation</li>
                                        <li>Fakturor</li>
                                        <li>Övrigt</li>
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="right">
                <div className="information">
                    <div className="title">
                        Ring vår växel för att få snabb hjälp!
                    </div>
                    <div className="phone">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z" />
                        </svg>
                        <a
                            href={`tel:${context.user.customer.brand.supportPhoneNumber}`}
                        >
                            {context.user.customer.brand.supportPhoneNumber}
                        </a>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default Component;
