import Context from "context/Global";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    padding: 1rem 0;

    > table {
        margin-bottom: 1rem;
        th,
        td {
            padding: 0 1rem;
            text-align: left;
        }
    }
`;

function View(props) {
    const context = useContext(Context);
    const [statistics, setStatistics] = useState(null);

    useEffect(() => {
        load();
    }, []);

    async function load() {
        setStatistics(
            await Api.getShipmentStatistics({
                interval: "month",
                brandId: context.user.brand.id,
                clientManagerId: null,
            }),
        );
    }

    if (!statistics) {
        return null;
    }

    return (
        <Container>
            <table>
                <thead>
                    <tr>
                        <th>Inrikes försändelser</th>
                        <th>Exporter</th>
                        <th>Importer</th>
                        <th>Utanför Sverige</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{statistics.domesticCount}</td>
                        <td>{statistics.exportCount}</td>
                        <td>{statistics.importCount}</td>
                        <td>{statistics.outsideSwedenCount}</td>
                    </tr>
                </tbody>
            </table>
            <table>
                <thead>
                    <tr>
                        <th>Export till</th>
                        <th>Antal försändelser</th>
                    </tr>
                </thead>
                <tbody>
                    {statistics.exportCountries.map((data) => {
                        return (
                            <tr>
                                <td>{data.country}</td>
                                <td>{data.amount}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <table>
                <thead>
                    <tr>
                        <th>Import från</th>
                        <th>Antal försändelser</th>
                    </tr>
                </thead>
                <tbody>
                    {statistics.importCountries.map((data) => {
                        return (
                            <tr>
                                <td>{data.country}</td>
                                <td>{data.amount}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Container>
    );
}

export default View;
