import Context from "context/Global";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { downLoadFileBlob } from "../../../../utils/s3";
import Loader from "components/Loader/Loader";
import toast from "react-hot-toast";

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.1);
    z-index: 100;

    > .popup {
        background: #fff;
        box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.1);
        padding: 1em;
        width: 70vw;
        height: 87vh;

        > .header {
            display: flex;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            justify-content: space-between;
            padding-bottom: 1em;
            align-items: center;

            > .title {
                font-size: 1.6em;
            }

            > .close {
                cursor: pointer;
                width: 20px;
                height: 20px;
                margin-right: 0.5em;

                > svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        > .preview {
            width: 100%;
            height: 75vh;
            margin-bottom: 1em;
        }
    }
`;

function IntegratedInvoicePreview(props) {
    const context = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [pdfUrl, setPdfUrl] = useState(null);

    useEffect(() => {
        async function fetchPDF() {
            const customsInvoice = context.order.customsInvoice;

            try {
                const blob = await downLoadFileBlob({
                    url: `${
                        process.env.REACT_APP_API_URL
                    }/api/s3/shipment?objectKey=${encodeURIComponent(
                        customsInvoice.fileContent,
                    )}`,
                });

                if (!blob) {
                    throw new Error("Failed to fetch PDF blob");
                }

                const pdfUrl = URL.createObjectURL(blob);
                setPdfUrl(pdfUrl);
                setLoading(false);
            } catch (error) {
                toast.error("Misslyckades med att hämta tullfaktura");
                setLoading(false);
                props.onClose();
            }
        }

        fetchPDF();
    }, [context.order]);

    function handleAccept() {
        const customsInvoice = context.order.customsInvoice;

        const integratedInvoice = {
            id: customsInvoice.fileContent,
            path: customsInvoice.fileContent,
            title: customsInvoice.fileName,
        };

        context.updateOrder({
            customsInvoice: integratedInvoice,
        });
        props.onClose();
        props.history.push(`/orders/create`);
    }

    function handleCancel() {
        context.updateOrder(context.getApiOrderObject());
        props.onClose();
    }

    return (
        <Container>
            <div className="popup">
                <div className="header">
                    <div className="title">Förhandsgranska tullfaktura</div>
                    <div className="close" onClick={props.onClose}>
                        <img
                            alt="Close"
                            src="/images/icons/close.svg"
                            style={{ transform: "scale(1.8)" }}
                        />
                    </div>
                </div>
                {loading && <Loader />}
                {pdfUrl && (
                    <>
                        <embed
                            className="preview"
                            src={pdfUrl}
                            type="application/pdf"
                        />
                        <div
                            className="c-button c-button--ghost"
                            onClick={handleCancel}
                        >
                            Avbryt
                        </div>
                        <div
                            className="c-button c-button--raised"
                            onClick={handleAccept}
                        >
                            Använd faktura
                        </div>
                    </>
                )}
            </div>
        </Container>
    );
}

export default IntegratedInvoicePreview;
