import React, { Component } from "react";
import styled from "styled-components/macro";

let Container = styled.div`
    display: inline-block;

    > .inputContainer {
        padding: 0;
        border-radius: 5px;
        box-sizing: border-box;
        outline: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
        background: rgba(255, 255, 255, 1);
        overflow: hidden;
        border: 0;

        > .option {
            height: 100%;
            display: flex;
            align-items: center;
            color: #777;
            cursor: pointer;
            text-align: center;
            padding: 8px 16px;
            min-width: 50px;
            justify-content: center;
            border: 1px solid rgba(76, 80, 175, 0.5);
            border-right: none;

            &:first-child {
                border-radius: 5px 0 0 5px;
            }
            &:last-child {
                border-radius: 0 5px 5px 0;
                border-right: 1px solid rgba(76, 80, 175, 0.5);
            }

            &.is-selected {
                background: rgba(76, 80, 175, 1);
                cursor: default;
                color: #fff;
                pointer-events: none;
                font-weight: 500;
                border-top: none;
                border-bottom: none;
                border-left: none;
            }
        }
    }

    > .message {
        font-weight: bold;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
    }

    &.is-invalid {
        > .message {
            color: rgba(255, 0, 0, 0.6);
        }
    }
`;

class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: typeof props.value !== "undefined" ? props.value : null,
        };

        if (typeof this.props.onChange === "function") {
            this.onChange = this.props.onChange;
        }

        if (typeof this.props.onKeyUp === "function") {
            this.onKeyUp = this.props.onKeyUp;
        }

        this.select = this.select.bind(this);
    }

    onChange() {}

    onKeyUp() {}

    value() {
        return this.state.value;
    }

    set(value) {
        this.setState(
            {
                value: value,
            },
            () => {
                if (this.state.isInvalid) {
                    this.validate();
                }
            },
        );
    }

    empty() {
        this.setState({
            value: null,
        });
    }

    validate() {
        const valid = this.state.value !== null;
        if (!valid) {
            this.setState({
                isInvalid: true,
                message: this.props.messages.required,
            });
        } else {
            this.setState({
                isInvalid: false,
                message: null,
            });
        }
        return valid;
    }

    select(index) {
        this.setState(
            {
                value: this.props.options[index].value,
            },
            () => {
                if (this.state.isInvalid) {
                    this.validate();
                }
                this.onChange(this.value());
            },
        );
    }

    render() {
        return (
            <Container className={this.state.isInvalid ? " is-invalid" : ""}>
                <div
                    className="inputContainer"
                    name={this.props.name || undefined}
                >
                    {this.props.options.map((option, index) => {
                        return (
                            <div
                                key={index}
                                className={
                                    "option" +
                                    (this.state.value === option.value
                                        ? " is-selected"
                                        : "")
                                }
                                onClick={() => {
                                    this.select(index);
                                }}
                            >
                                {option.title}
                            </div>
                        );
                    })}
                </div>
                {this.state.message && (
                    <div className="message">{this.state.message}</div>
                )}
            </Container>
        );
    }
}

Input.defaultProps = {
    messages: {
        required: "You need to select one option",
    },
};

export default Input;
