import React, { Component } from "react";
import styled from "styled-components";

let Container = styled.div`
    > .inputContainer {
        background: #fff;
        padding: 4px;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 40px;
        overflow: hidden;

        > input {
            padding: 4px;
            width: 100%;
            border: 0;
            outline: none;
        }

        > input[disabled] {
            background: inherit;
        }

        > .prefix {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            margin-right: 8px;
            width: 60px;
            min-width: 60px;
            color: rgba(0, 0, 0, 0.4);
        }

        > .unit {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            margin-left: 8px;
            width: 60px;
            min-width: 60px;
            color: rgba(0, 0, 0, 0.4);
        }

        > .length {
            font-size: 0.8rem;
            padding: 2px 4px;
            font-style: italic;
            font-weight: bold;
            color: #555;

            &.is-invalid {
                color: #ff5555;
            }
        }
    }

    > .message {
        font-weight: bold;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
    }

    &.is-invalid {
        > .inputContainer {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }
        > .message {
            color: rgba(255, 0, 0, 0.6);
        }
    }

    &.is-disabled {
        > .inputContainer {
            background: rgb(235, 235, 228);
            border: 1px solid rgba(0, 0, 0, 0.05);

            > input {
                color: rgba(0, 0, 0, 0.5);
                background: transparent;
            }
        }
    }
`;

class InputField extends Component {
    constructor(props) {
        super(props);
        let v = props.value;
        if (props.prefix) {
            let pattern = new RegExp(
                "^(" +
                    props.prefix.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&") +
                    ")",
            );
            v = v.replace(pattern, "");
        }
        this.state = {
            value: v || "",
            disabled: props.disabled,
            isInvalid: false,
        };

        this.inputRef = React.createRef();

        if (typeof this.props.onKeyUp === "function") {
            this.onKeyUp = this.props.onKeyUp;
        }
        this.onChange = this.onChange.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let state = {};
        if (typeof nextProps.disabled !== "undefined") {
            state.disabled = nextProps.disabled;
        }
        if (typeof nextProps.unit !== "undefined") {
            state.unit = nextProps.unit;
        }
        if (typeof nextProps.prefix !== "undefined") {
            state.prefix = nextProps.prefix;
        }
        return state;
    }

    onChange(event) {
        this.setState(
            {
                value: this.convertLeadingZeroesToPlus(event.target.value),
            },
            () => {
                if (this.state.isInvalid) {
                    this.validate();
                }
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this.state.value.trim());
                }
            },
        );
    }

    onKeyUp() {}

    convertLeadingZeroesToPlus(value) {
        return value.replace(/^0{2}/, "+");
    }

    select() {
        this.inputRef.current.select();
    }

    value() {
        return (
            (this.props.prefix ? this.props.prefix : "") +
            this.state.value.toString().trim()
        );
    }

    set(value) {
        if (value === null) {
            return;
        }
        if (this.props.prefix) {
            let pattern = new RegExp(
                "^(" +
                    this.props.prefix.replace(
                        /[-\/\\^$*+?.()|[\]{}]/g,
                        "\\$&",
                    ) +
                    ")",
            );
            value = value.replace(pattern, "");
        }
        this.setState({
            value,
        });
    }

    empty() {
        this.setState({
            value: "",
        });
    }

    focus() {
        this.inputRef.current.focus();
    }

    setValidationMessage(message) {
        this.setState({
            isInvalid: true,
            message: message,
        });
    }

    validate() {
        if (this.props.required && this.state.value.trim().length === 0) {
            this.setState({
                isInvalid: true,
                message: this.props.messages.required,
            });
            return false;
        }
        if (this.getLength() > this.props.maxLength) {
            this.setState({
                isInvalid: true,
                message: this.props.messages.maxLength,
            });
            return false;
        }
        if (this.getLength() < this.props.minLength) {
            this.setState({
                isInvalid: true,
                message: this.props.messages.minLength,
            });
            return false;
        }
        if (this.state.value.match(/[^\d\+\-() ]/g)) {
            this.setState({
                isInvalid: true,
                message: this.props.messages.invalidCharacter,
            });
            return false;
        }
        this.setState({
            isInvalid: false,
            message: null,
        });
        return true;
    }

    getLength() {
        return this.state.value.length;
    }

    showLength() {
        const { maxLength } = this.props;

        return (
            maxLength && (maxLength <= 10 || this.getLength() / maxLength > 0.8)
        );
    }

    getLengthClass() {
        let classes = ["length"];
        if (this.getLength() > this.props.maxLength) {
            classes.push("is-invalid");
        }
        return classes.join(" ");
    }

    render() {
        let {
            messages,
            name,
            placeholder,
            autoComplete,
            maxLength,
            type,
            ...otherProps
        } = this.props;

        return (
            <Container
                className={
                    (this.state.disabled ? " is-disabled" : "") +
                    (this.state.isInvalid ? " is-invalid" : "")
                }
            >
                <div className="inputContainer">
                    {this.state.prefix && (
                        <div className="prefix">{this.state.prefix}</div>
                    )}
                    <input
                        type="text"
                        {...otherProps}
                        name={name || ""}
                        autoComplete={autoComplete}
                        placeholder={placeholder}
                        ref={this.inputRef}
                        disabled={this.state.disabled}
                        value={this.state.value}
                        onKeyUp={this.onKeyUp}
                        onChange={this.onChange}
                    />
                    {this.showLength() && (
                        <div className={this.getLengthClass()}>
                            {this.getLength()}/{maxLength}
                        </div>
                    )}
                    {this.state.unit && (
                        <div className="unit">{this.state.unit}</div>
                    )}
                </div>
                {this.state.message && (
                    <div className="message">{this.state.message}</div>
                )}
            </Container>
        );
    }
}

InputField.defaultProps = {
    disabled: false,
    messages: {
        required: "This is a required field",
        maxLength: "The field contains too many characters",
        minLength: "The field contains too few characters",
        invalidCharacter: "The phone number contains invalid characters",
    },
};

export default InputField;
