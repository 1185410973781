import Context from "context/Global";
import { match } from "helpers/Match";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    > .list {
        > .integration {
            border-bottom: 1px solid #eee;
            background: #fff;
            display: flex;
            justify-content: center;
            text-decoration: none;
            color: #000;

            > .container {
                width: 100%;
                max-width: 1000px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                > img {
                    width: 30%;
                    padding: 2rem;
                    max-height: 140px;
                    object-fit: contain;
                }

                > .text {
                    width: 60%;
                    padding: 2rem;

                    > .title {
                        font-size: 2rem;
                    }
                }
            }
            &:nth-child(2n + 1) > .container {
                flex-direction: row-reverse;
            }

            &:hover {
                background: #fafafa;
            }
        }

        > .more {
            border-bottom: 1px solid #eee;
            background: #fff;
            display: flex;
            justify-content: center;
            text-decoration: none;
            color: #000;

            > .container {
                width: 100%;
                max-width: 1000px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                > .questionmark {
                    width: 30%;
                    text-align: center;

                    > svg {
                        height: 100%;
                        width: 100%;
                        max-height: 80px;
                    }
                }

                > .text {
                    width: 70%;
                    padding: 2rem;

                    > .title {
                        font-size: 2rem;
                    }
                }
            }
            &:nth-child(2n + 1) > .container {
                flex-direction: row-reverse;
            }
        }

        > .item {
            border: 1px solid #aaa;
            border-radius: 1rem;
            width: 200px;
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1rem;
            text-decoration: none;
            color: #000;
            background: #fff;

            &:hover {
                color: #999;
            }

            > svg {
                width: 32px;
                height: 32px;
                margin-right: 1rem;
            }
        }
    }
`;

function Component(props) {
    const context = useContext(Context);
    const [integrations, setIntegrations] = useState([]);

    useEffect(() => {
        loadIntegrations();
    }, []);

    async function loadIntegrations() {
        setIntegrations(await Api.getIntegrations());
    }

    function getIntegrationImage(integration) {
        return match(integration.name, {
            Zendr: () => "../logos/zendr-logo-hallon.svg",
            LogTrade: () => "logtrade.png",
            Fortnox: () => "fortnox.svg",
            Starweb: () => "starweb.png",
            Shopify: () => "shopify.svg",
            WooCommerce: () => "woocommerce.svg",
            Magento: () => "magento.svg",
            VismaAdministration: () => "visma-administration.svg",
            BusinessCentral: () => "business-central.png",
            QuickButik: () => "quickbutik.jpg",
            JoomlaVirtueMart: () => "virtuemart.svg",
            Custom: () => "zendr.png",
            Pyramid: () => "pyramid.png",
            OngoingWms: () => "ongoing.svg",
        });
    }

    function getIntegrationTitle(integration) {
        return match(integration.name, {
            Zendr: () => "Zendr",
            LogTrade: () => "LogTrade",
            Fortnox: () => "Fortnox",
            Starweb: () => "Starweb",
            Shopify: () => "Shopify",
            WooCommerce: () => "WooCommerce",
            Magento: () => "Magento",
            VismaAdministration: () => "Visma Administration",
            BusinessCentral: () => "Dynamics 365 Business Central",
            QuickButik: () => "Quickbutik",
            JoomlaVirtueMart: () => "Joomla - VirtueMart",
            Custom: () => "DIY - Do It Yourself!",
            Pyramid: () => "Pyramid",
            OngoingWms: () => "Ongoing WMS",
        });
    }

    function getIntegrationDescription(integration) {
        return match(integration.name, {
            Zendr: () =>
                "Önskar ni att utveckla en egen integration emot vårat system så går det enkelt att använda vårat API",
            LogTrade: () =>
                "Vårt samarbete med LogTrade gör att ni automatiskt får in försändelser i Portalen.",
            Fortnox: () =>
                "Med vår integration mot Fortnox kan ni enkelt importera de ordrar ni får in i Fortnox.",
            Starweb: () =>
                "Integrationen med Starweb möjliggör en enklare transport-bokning.",
            Shopify: () =>
                "Integrationen med Shopify möjliggör en enklare transport-bokning.",
            WooCommerce: () =>
                "Integrationen med WooCommerce möjliggör en enklare transport-bokning.",
            Magento: () =>
                "Integrationen med Magento möjliggör en enklare transport-bokning.",
            VismaAdministration: () =>
                "Integrationen med Visma Administration möjliggör en enklare transport-bokning.",
            BusinessCentral: () =>
                "Integrationen med Microsoft Business Central möjliggör en enklare transport-bokning.",
            QuickButik: () =>
                "Integrationen med Quickbutik möjliggör en enklare transport-bokning.",
            JoomlaVirtueMart: () =>
                "Integrationen med Joomla - VirtueMart möjliggör en enklare transport-bokning.",
            Custom: () =>
                "Utvecklar ni ett eget system och vill integrera mot oss? Då har vi ett enkelt alternativ för att skapa integrationen själv!",
            OngoingWms: () =>
                "Integrationen med Ongoing WMS möjliggör en enklare transportbokning.",
            Pyramid: () =>
                "Integrationen med Pyramid möjliggör en enklare orderhantering.",
        });
    }

    return (
        <Container>
            <div className="list">
                {integrations.map((integration) => (
                    <Link
                        key={integration.id}
                        to={`/addons/create/${integration.name
                            .toLowerCase()
                            .replace(/[^A-Za-z0-9]+/gm, "-")}`}
                        className="integration"
                    >
                        <div className="container">
                            <img
                                alt={integration.name}
                                src={`/images/icons/${getIntegrationImage(
                                    integration,
                                )}`}
                            />
                            <div className="text">
                                <div className="title">
                                    {getIntegrationTitle(integration)}
                                </div>
                                <div className="description">
                                    {getIntegrationDescription(integration)}
                                </div>
                            </div>
                        </div>
                    </Link>
                ))}

                <div className="more">
                    <div className="container">
                        <div className="questionmark">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path d="M14.601 21.5c0 1.38-1.116 2.5-2.499 2.5-1.378 0-2.499-1.12-2.499-2.5s1.121-2.5 2.499-2.5c1.383 0 2.499 1.119 2.499 2.5zm-2.42-21.5c-4.029 0-7.06 2.693-7.06 8h3.955c0-2.304.906-4.189 3.024-4.189 1.247 0 2.57.828 2.684 2.411.123 1.666-.767 2.511-1.892 3.582-2.924 2.78-2.816 4.049-2.816 7.196h3.943c0-1.452-.157-2.508 1.838-4.659 1.331-1.436 2.986-3.222 3.021-5.943.047-3.963-2.751-6.398-6.697-6.398z" />
                            </svg>
                        </div>
                        <div className="text">
                            <div className="title">Vad saknar du här?</div>
                            <div className="description">
                                Vi får gärna förslag på vad för integrationer
                                just ni vill använda.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default Component;
