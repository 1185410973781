export const mergeAdditionalOptions = (
    options,
    optionsName,
    additionalOptions,
) => {
    if (options || additionalOptions) {
        return [
            ...(additionalOptions?.filter(
                (option) => option.name !== optionsName,
            ) ?? []),
            ...(options ?? []),
        ];
    }

    return additionalOptions;
};
