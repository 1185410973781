import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useState, useEffect } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import { useMsal } from "@azure/msal-react";
import {
    clearLoginStorage,
    getRefreshToken,
    loginRequest,
} from "../../Create/BusinessCentral/authConfig";
import { getCompanies } from "../../Create/BusinessCentral/businessCentralApi";
import Loader from "../../../../components/Loader/Loader";
import toast from "react-hot-toast";
import BusinessCentralReAuth from "../../../Orders/Imported/IntegrationsReAuth/BusinessCentralReAuth";
import { getIntegrationExpiryStatusById } from "../../../../utils/ExpiryIntegrationsUtils";
import Alert, { AlertTypes } from "../../../../components/Alerts/Alert";
import IntegrationsTokenLifespans from "../../../../constants/integrations/IntegrationsTokenLifespans";
import IntegrationStatuses from "../../../../constants/integrations/IntegrationStatuses";
import DeliveryCodesSelection from "./DeliveryCodesSelection";

let Container = styled.div`
    padding: 1rem;

    > .title {
        font-weight: bold;
        font-size: 2rem;
    }
    > .subTitle {
        font-weight: bold;
        margin-top: 1rem;
    }

    > .settings {
        display: inline-block;
    }

    > .list {
        display: grid;
        grid-template: auto / repeat(auto-fit, minmax(200px, 300px));
        grid-gap: 1rem;

        > .template {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            padding: 0.5rem 1rem;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.05);
            &:hover {
                background: rgba(0, 0, 0, 0.1);
            }

            > .name {
                color: #000;
                text-decoration: none;
            }

            > .remove {
                width: 16px;
                height: 16px;
                min-width: 16px;
                min-height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #aaa;
                cursor: pointer;

                &:hover {
                    color: #555;
                }

                > svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    > .buttons {
        padding: 1rem 0;
    }
`;

function Component({ ...props }) {
    const context = useContext(Context);
    const [integration, setIntegration] = useState(props.integration);
    const { instance, accounts } = useMsal();
    const [companies, setCompanies] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [finalizedConfiguration, setFinalizedConfiguration] = useState(false);
    const [loading, setLoading] = useState(false);
    const [expireNotification, setExpireNotification] = useState(null);

    useEffect(() => {
        setExpireNotification(
            getIntegrationExpiryStatusById(
                context.integrationsExpiry,
                integration.id,
            ),
        );
    }, []);

    /**
     * It triggers popup for login into microsoft account
     * It is using OAuth authentication for this purpose
     *
     */
    async function connectMicrosoftAccount() {
        setLoading(true);
        try {
            const login = await instance.loginPopup(loginRequest);
            if (login?.accessToken) {
                const updatedIntegration = {
                    ...integration,
                    data: {
                        ...integration.data,
                        refreshToken: getRefreshToken().secret,
                        accessToken: login.accessToken,
                        refreshTokenExpiration:
                            IntegrationsTokenLifespans.BUSINESS_CENTRAL_REFRESH_TOKEN_LIFESPAN,
                    },
                };
                setIntegration(updatedIntegration);
                await fetchCompanies(updatedIntegration);
            }
        } catch (error) {
            toast.error("Kunde inte ansluta till Microsoft: " + error.message);
        }
        setLoading(false);
    }

    /**
     * It connects to Microsoft Business Central API
     * which is going to retrieve all companies to related account.
     *
     */
    const fetchCompanies = async (updatedIntegration) => {
        setLoading(true);
        try {
            const companies = await getCompanies(updatedIntegration);
            setCompanies(companies);
        } catch (error) {
            toast.error("Kunde inte hämta företagen: " + error.message);
        }
        setLoading(false);
    };

    const handleCompanyChange = async (e) => {
        const selectedIndex = e.target.options.selectedIndex;
        const selectedCompany = e.target.value;
        const companyName =
            e.target.options[selectedIndex].getAttribute("name");

        setSelectedCompany(selectedCompany);
        setIntegration((prevIntegration) => ({
            ...prevIntegration,
            data: {
                ...prevIntegration.data,
                companyId: selectedCompany,
                companyName: companyName,
            },
        }));
    };

    /**
     * Update business central ingretaion data field
     * @param data
     */
    const updateIntegration = async (data) => {
        const integrationId = integration.id;
        try {
            await Api.updateIntegration({ integrationId, data });
            // Added return boolean because we don't have any info
            // from backend regarding if it was successfully?
            return true;
        } catch (error) {
            toast.error(error.message);
            return false;
        }
    };

    /**
     * Removes completely integration from database
     * @returns {Promise<void>}
     */
    async function removeIntegration() {
        if (!confirm("Är du säker på att du vill ta bort integrationen?")) {
            return;
        }

        const integrationId = integration.id;
        const customerId = context.user.customer.id;

        try {
            await Api.removeCustomerIntegration({
                customerId,
                integrationId,
            });

            clearLoginStorage();
            props.history.push("/addons");
        } catch (err) {
            clearLoginStorage();
            console.error(err);
        }
    }

    if (loading) return <Loader />;

    const fetchStatus = integration?.data.fetchStatus;

    let filteringText;
    if (fetchStatus === "Draft") {
        filteringText = "Status: Öppen";
    } else if (fetchStatus === "Open") {
        filteringText = "Status: Släppt";
    } else {
        filteringText = "Status: Ingen filtrering";
    }

    const finalizeConfiguration = async () => {
        const updatedIntegration = {
            ...integration,
            data: {
                ...integration.data,
                companyId: selectedCompany,
                companyName: integration.data.companyName,
            },
        };

        try {
            await updateIntegration(updatedIntegration.data);
            setIntegration(updatedIntegration);
            setLoading(false);
            setFinalizedConfiguration(true);
            clearLoginStorage();
            toast.success("Integrationen har sparats.");
        } catch (error) {
            clearLoginStorage();
            toast.error("Kunde inte uppdatera integrationen: " + error.message);
        }
    };

    return (
        <Container>
            <div className="title">Microsoft Business Central</div>
            <>
                {expireNotification && integration?.data?.companyId && (
                    <Alert
                        type={
                            expireNotification?.status ===
                            IntegrationStatuses.EXPIRED
                                ? AlertTypes.ERROR
                                : AlertTypes.WARNING
                        }
                        className={"mt-3 mb-3"}
                    >
                        Du behöver uppdatera din integration.
                    </Alert>
                )}
                <div className="form mb-5">
                    {!finalizedConfiguration && (
                        <>
                            {!integration.data.companyId &&
                                integration?.data.refreshToken && (
                                    <div className="mt-5">
                                        <select
                                            value={selectedCompany}
                                            onChange={(e) =>
                                                handleCompanyChange(e)
                                            }
                                            className="border p-2 w-1/3 mt-3 rounded"
                                        >
                                            <option
                                                defaultChecked={true}
                                                value=""
                                            >
                                                Välj företag...
                                            </option>
                                            {companies?.map((company) => (
                                                <option
                                                    name={company.name}
                                                    key={company.id}
                                                    value={company.id}
                                                >
                                                    {company.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                        </>
                    )}
                    {integration.data.companyId && (
                        <div className="mt-5">
                            <p> Miljö: {integration?.data.environment} </p>
                            <p> Företag: {integration?.data.companyName} </p>
                            <p>{filteringText}</p>
                            <DeliveryCodesSelection
                                integration={integration}
                                handleUpdate={updateIntegration}
                            />
                        </div>
                    )}
                </div>
                <div className="buttons">
                    {!integration?.data.refreshToken && (
                        <button
                            className="c-button c-button--raised"
                            onClick={connectMicrosoftAccount}
                        >
                            {" "}
                            Anslut företag...{" "}
                        </button>
                    )}
                    <button
                        className="c-button c-button--raised"
                        onClick={removeIntegration}
                    >
                        {" "}
                        Ta bort integrationen{" "}
                    </button>
                    {!finalizedConfiguration && selectedCompany && (
                        <button
                            className="c-button c-button--raised"
                            onClick={() => finalizeConfiguration()}
                        >
                            {" "}
                            Spara koppling{" "}
                        </button>
                    )}
                    {expireNotification && integration?.data?.companyId && (
                        <BusinessCentralReAuth
                            integrationData={integration?.data}
                            integrationId={integration?.id}
                            className={
                                "py-2 px-4 rounded bg-good text-white hover:bg-good-darken opacity-90 ml-2"
                            }
                            onSuccess={setExpireNotification}
                        />
                    )}
                </div>
            </>
        </Container>
    );
}

export default Component;
