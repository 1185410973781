export const handleDownloadS3 = async ({ url, desiredFileName }) => {
    try {
        const blob = await downLoadFileBlob({ url });

        const fileExtension = desiredFileName.split(".").pop().toLowerCase();

        if (fileExtension === "pdf" || fileExtension === "png") {
            // For PDF and PNG, open in a new tab
            const fileUrl = URL.createObjectURL(blob);
            window.open(fileUrl, "_blank");
            URL.revokeObjectURL(fileUrl);
        } else {
            // For other file types, download direct to the users /downloads folder
            const downloadUrl = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = downloadUrl;
            a.download = desiredFileName; // set the desired file name here
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(downloadUrl);
        }
    } catch (error) {
        console.error("Download error:", error);
    }
};

export const downloadInvoiceFile = async ({ url, desiredFileName }) => {
    try {
        const response = await fetch(url);
        if (!response.ok)
            throw new Error(`HTTP error! Status: ${response.status}`);
        const blob = await response.blob();

        // For other file types, download direct to the users /downloads folder
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = desiredFileName; // set the desired file name here
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        console.error("Download error for invoice file", error);
    }
};

export const downLoadFileBlob = async ({ url }) => {
    try {
        const response = await fetch(url);
        if (!response.ok)
            throw new Error(`HTTP error! Status: ${response.status}`);
        return await response.blob();
    } catch (error) {
        console.error("Error downloading file blob", error);
    }
};
