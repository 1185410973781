import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import moment from "moment";

import Api from "services/Api/Api";
import { withRouter } from "react-router-dom";
import axios, { CancelToken } from "axios";
import Input from "components/OldInput";
import Loader from "components/Loader/Loader";
import Context from "context/Global";

let Container = styled.div`
    width: 100%;
    max-width: 500px;

    > .input {
        width: 100%;
        max-width: 500px;
    }

    > .desiredPickupTimes {
        display: flex;
        flex-direction: row;
        align-items: center;

        > div {
            padding: 0 8px;
        }
    }

    > .notifications {
        > .description {
            display: flex;
            align-items: center;

            > .text {
                margin-right: 16px;
            }
        }

        > .option {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px;

            > .title {
                font-weight: normal;
                padding-left: 8px;
            }
        }
    }

    > .pickupReference {
        margin-top: 8px;

        > .errorMessage {
            font-size: 12px;
            color: rgba(200, 0, 0, 1);
        }
    }

    > .intervalMessage {
        font-size: 0.8em;
        padding: 1em 0;
        color: rgba(250, 140, 0, 1);
        font-weight: bold;
    }

    > .title {
        font-size: 1.2em;
        margin-top: 16px;

        &:first-child {
            margin-top: 0;
        }
    }

    > .description {
    }
`;

function Component(props) {
    const context = useContext(Context);
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(props.order);
    const [availablePickups, setAvailablePickups] = useState([]);
    const [selectedPickupIndex, setSelectedPickupIndex] = useState(0);

    const [message, setMessage] = useState(null);

    const [desiredPickup, setDesiredPickup] = useState(null);
    const [desiredPickupIndex, setDesiredPickupIndex] = useState(0);
    const [pickupStartTimes, setPickupStartTimes] = useState([]);
    const [pickupEndTimes, setPickupEndTimes] = useState([]);
    const [desiredPickupStartTime, setDesiredPickupStartTime] = useState();
    const [desiredPickupEndTime, setDesiredPickupEndTime] = useState();

    const [intervalMessage, setIntervalMessage] = useState(null);

    const [selectedTime, setSelectedTime] = useState("08:00");
    const [pickupInstructions, setPickupInstructions] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const timeStartInputRef = useRef();
    const timeEndInputRef = useRef();

    useEffect(() => {
        if (order) {
            if (
                order.service.name.toLowerCase() === "fedex" &&
                order.service.type.indexOf("_FREIGHT") > 0
            ) {
                return;
            }
            Api.getPickupAvailability({
                route: order.route,
                service: order.service,
            })
                .then((response) => {
                    setAvailablePickups(
                        response.availablePickups.filter((option) => {
                            if (
                                option.date !== moment().format("YYYY-MM-DD") ||
                                moment().format("HH:mm") < option.cutOffTime
                            ) {
                                return true;
                            }
                            return false;
                        }),
                    );
                })
                .catch((error) => {
                    console.error("Failed to get pickup availability");
                    console.error(error);
                });
        }
    }, [order]);

    function onPickupChanged(index) {
        setSelectedPickupIndex(index);
    }

    useEffect(() => {
        if (availablePickups && availablePickups.length > 0) {
            setDesiredPickupIndex(0);
        }
    }, [availablePickups]);

    useEffect(() => {
        if (
            availablePickups &&
            desiredPickupIndex !== null &&
            availablePickups[desiredPickupIndex]
        ) {
            setDesiredPickup(availablePickups[desiredPickupIndex]);
        }
    }, [desiredPickupIndex, availablePickups]);

    useEffect(() => {
        if (desiredPickup) {
            var selectTime = null;
            let startTimes = [];
            let endTimes = [];

            for (let i = 8; i <= 18; i++) {
                let hours = ("0" + i + "").slice(-2);
                if (
                    desiredPickup.date === moment().format("YYYY-MM-DD") &&
                    hours <= moment().format("HH")
                ) {
                    continue;
                }
                if (hours + ":00" <= desiredPickup.cutOffTime) {
                    startTimes.push({
                        title: hours + ":00",
                    });
                }
                if (hours + ":30" <= desiredPickup.cutOffTime) {
                    startTimes.push({
                        title: hours + ":30",
                    });
                }

                endTimes.push({
                    title: hours + ":00",
                });
                endTimes.push({
                    title: hours + ":30",
                });
            }

            if (startTimes.length) {
                setPickupStartTimes(startTimes);
                setPickupEndTimes(endTimes);

                setDesiredPickupStartTime(startTimes[0].title);
                setDesiredPickupEndTime(endTimes[endTimes.length - 1].title);

                timeStartInputRef.current?.set(selectTime);
                timeEndInputRef.current?.set(
                    endTimes[endTimes.length - 1].title,
                );
            }
        }
    }, [desiredPickup]);

    useEffect(() => {
        if (
            desiredPickupStartTime &&
            desiredPickupEndTime &&
            availablePickups
        ) {
            let startTime = moment(desiredPickupStartTime, "HH:mm");
            let endTime = moment(desiredPickupEndTime, "HH:mm");
            var duration = moment.duration(endTime.diff(startTime)).asMinutes();
            let minIntervalInMinutes =
                availablePickups[desiredPickupIndex].minInterval.hours * 60 +
                availablePickups[desiredPickupIndex].minInterval.minutes;

            if (duration < minIntervalInMinutes) {
                setIntervalMessage(
                    `Önskad upphämtning måste ha en lucka på minst ${
                        availablePickups[desiredPickupIndex].minInterval.hours
                    } ${
                        availablePickups[desiredPickupIndex].minInterval.hours >
                        1
                            ? "timmar"
                            : "timme"
                    }` +
                        (availablePickups[desiredPickupIndex].minInterval
                            .minutes
                            ? `och ${availablePickups[desiredPickupIndex].minInterval.minutes} minuter`
                            : ""),
                );
            } else {
                setIntervalMessage(null);
            }
        }
    }, [
        desiredPickupStartTime,
        desiredPickupEndTime,
        availablePickups,
        desiredPickupIndex,
    ]);

    async function bookPickup() {
        try {
            await Api.bookPickup({
                orderId: order.id,
                pickup: {
                    date: desiredPickup.date,
                    instructions: pickupInstructions,
                    timeInterval: {
                        start: desiredPickupStartTime,
                        end: desiredPickupEndTime,
                    },
                },
            });
            props.history.replace(`/orders/${order.id}`);

            // await Api.bookPickup({
            //     orderId: order.id,
            //     packages: order.packages,
            //     route: order.route,
            //     service: order.service,
            //     pickup: {
            //         start: moment(availablePickups[selectedPickupIndex].date + ' ' + selectedTime).format('YYYY-MM-DD HH:mm:ss'),
            //         end: moment(availablePickups[selectedPickupIndex].date + ' ' + availablePickups[selectedPickupIndex].cutOffTime).format('YYYY-MM-DD HH:mm:ss'),
            //         instructions: pickupInstructions
            //     }
            // });
            // props.history.push(`/orders/${order.id}`);
        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.error
            ) {
                switch (error.response.data.error) {
                    case "INVALID_TIME_ERROR": {
                        setErrorMessage(`Ogiltig tid, försök igen.`);
                        break;
                    }
                    case "CUT_OFF_TIME_PASSED_ERROR": {
                        setErrorMessage(
                            `Stopptiden har passerats, boka en upphämtning nästa möjliga dag.`,
                        );
                        break;
                    }
                    default: {
                        setErrorMessage(`Ett fel inträffade. Försök igen.`);
                        break;
                    }
                }
            } else {
                setErrorMessage(`Ett fel inträffade. Försök igen.`);
            }
        }
    }

    if (
        order.service.name.toLowerCase() === "fedex" &&
        order.service.type.indexOf("_FREIGHT") > 0
    ) {
        return (
            <Container>
                <div className="title">Boka upphämtning</div>
                <div className="text">
                    Kontakta vår support på{" "}
                    <a
                        href={`tel:${context.user.customer.brand.supportPhoneNumber}`}
                    >
                        {context.user.customer.brand.supportPhoneNumber}
                    </a>{" "}
                    så hjälper vi er.
                </div>
            </Container>
        );
    } else if (
        loading ||
        !order ||
        !availablePickups ||
        availablePickups.length === 0
    ) {
        return (
            <Container>
                <div className="loader">
                    <Loader />
                </div>
            </Container>
        );
    } else {
        return (
            <Container>
                <div className="title">Önskat upphämtningsdatum</div>
                <Input
                    type="dropdown"
                    value={desiredPickupIndex}
                    options={availablePickups.map((pickup, index) => {
                        return {
                            title: pickup.date,
                            value: index,
                        };
                    })}
                    onChange={setDesiredPickupIndex}
                />
                <div className="title">Önskad upphämtningstid</div>
                <div className="desiredPickupTimes">
                    Från{" "}
                    <Input
                        ref={timeStartInputRef}
                        type="dropdown"
                        options={pickupStartTimes}
                        onChange={setDesiredPickupStartTime}
                        value={desiredPickupStartTime}
                    />
                    till{" "}
                    <Input
                        ref={timeEndInputRef}
                        type="dropdown"
                        options={pickupEndTimes}
                        onChange={setDesiredPickupEndTime}
                        value={desiredPickupEndTime}
                    />
                </div>
                {intervalMessage && (
                    <div className="intervalMessage">{intervalMessage}</div>
                )}
                Instruktioner
                <Input onChange={setPickupInstructions} type="textarea" />
                <button
                    className="c-button c-button--raised"
                    onClick={bookPickup}
                >
                    Boka upphämtning
                </button>
                {errorMessage && (
                    <div className="errorMessage">{errorMessage}</div>
                )}
            </Container>
        );
    }
}

export default withRouter(Component);
