import Input from "components/OldInput";
import Loader from "components/Loader/Loader";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    width: 100%;
    max-width: 500px;

    > .input {
        width: 100%;
        max-width: 500px;
    }

    > .desiredPickupTimes {
        display: flex;
        flex-direction: row;
        align-items: center;

        > div {
            padding: 0 8px;
        }
    }

    > .notifications {
        > .description {
            display: flex;
            align-items: center;

            > .text {
                margin-right: 16px;
            }
        }

        > .option {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px;

            > .title {
                font-weight: normal;
                padding-left: 8px;
            }
        }
    }

    > .pickupReference {
        margin-top: 8px;

        > .errorMessage {
            font-size: 12px;
            color: rgba(200, 0, 0, 1);
        }
    }

    > .intervalMessage {
        font-size: 0.8em;
        padding: 1em 0;
        color: rgba(250, 140, 0, 1);
        font-weight: bold;
    }

    > .title {
        font-size: 1.2em;
        margin-top: 16px;

        &:first-child {
            margin-top: 0;
        }
    }

    > .description {
    }

    .datetime {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 0 1rem;
        width: fit-content;

        > label {
            font-weight: bold;
        }
    }

    .subtitle {
        font-size: 1rem;
        font-weight: bold;
        margin-top: 1rem;
    }

    .deliveryDate {
        font-size: 1rem;
        margin-top: 0.5rem;

        > .loader {
            height: 24px;
            width: 24px;
        }
    }
`;

function Component(props) {
    const [loading, setLoading] = useState(false);
    const [pickupDate, setPickupDate] = useState(null);
    const [deliveryDate, setDeliveryDate] = useState(null);
    const [cutoff, setCutoff] = useState(null);
    const [availablePickupDates, setAvailablePickupDates] = useState([]);
    const [desiredPickupDate, setDesiredPickupDate] = useState(
        moment().format("YYYY-MM-DD"),
    );
    const [pickupStartTime, setPickupStartTime] = useState(null);
    const [pickupEndTime, setPickupEndTime] = useState(null);
    const [pickupInstructions, setPickupInstructions] = useState("");
    const [deliveryInstructions, setDeliveryInstructions] = useState("");
    const [availablePickupStartTimes, setAvailablePickupStartTimes] = useState(
        [],
    );
    const [availablePickupEndTimes, setAvailablePickupEndTimes] = useState([]);
    const pickupStartTimeRef = useRef();
    const pickupEndTimeRef = useRef();
    const [errorMessage, setErrorMessage] = useState(null);

    function getTimeList(start, end) {
        let [startHour, startMinute] = start.split(":");
        let [endHour, endMinute] = end.split(":");
        let availableTimes = [];
        for (let i = parseInt(startHour); i <= parseInt(endHour); i++) {
            if (i !== parseInt(startHour) || parseInt(startMinute) === 0) {
                availableTimes.push({
                    title: ("0" + i).slice(-2) + ":00",
                });
            }
            if (i !== parseInt(endHour) || parseInt(endMinute) === 30) {
                availableTimes.push({
                    title: ("0" + i).slice(-2) + ":30",
                });
            }
        }
        return availableTimes;
    }

    useEffect(() => {
        setAvailablePickupDates(getDesiredPickupDates());
    }, []);

    useEffect(() => {
        if (desiredPickupDate) {
            setDeliveryDate(null);
            setPickupDate(null);
            Api.getPickupAvailability({
                route: props.order.route,
                packages: props.order.packages,
                service: props.order.service,
                pickupDate: desiredPickupDate,
            })
                .then((response) => {
                    if (response) {
                        setLoading(false);
                        setCutoff(response.pickup.cutoff);
                        setPickupDate(response.pickupDate);
                        setDeliveryDate(response.deliveryDate);
                        setAvailablePickupStartTimes(
                            getTimeList(
                                response.pickup.start,
                                response.pickup.cutoff,
                            ),
                        );
                        setAvailablePickupEndTimes(
                            getTimeList(
                                response.pickup.start,
                                response.pickup.end,
                            ),
                        );
                    }
                })
                .catch((error) => {
                    setErrorMessage(
                        `Ett fel inträffade när vi försökte hämta möjliga tider från UPS. Försök gärna igen.`,
                    );
                });
        }
    }, [desiredPickupDate]);

    useEffect(() => {
        if (availablePickupStartTimes && availablePickupStartTimes.length) {
            setPickupStartTime(availablePickupStartTimes[0].title);
            if (pickupStartTimeRef.current && !pickupStartTime) {
                pickupStartTimeRef.current.set(
                    availablePickupStartTimes[0].title,
                );
            }
        }
    }, [availablePickupStartTimes]);

    useEffect(() => {
        if (availablePickupEndTimes && availablePickupEndTimes.length) {
            setPickupEndTime(
                availablePickupEndTimes[availablePickupEndTimes.length - 1]
                    .title,
            );
            if (pickupEndTimeRef.current && !pickupEndTime) {
                pickupEndTimeRef.current.set(
                    availablePickupEndTimes[availablePickupEndTimes.length - 1]
                        .title,
                );
            }
        }
    }, [availablePickupEndTimes]);

    function getDesiredPickupDates() {
        return [0, 1, 2, 3, 4, 5, 6, 7]
            .map((i) => {
                let day = moment().add(i, "days");
                if ([6, 7].indexOf(day.isoWeekday()) < 0) {
                    return {
                        title: day.format("YYYY-MM-DD"),
                        value: day.format("YYYY-MM-DD"),
                    };
                }
                return null;
            })
            .filter((pickup) => {
                return !!pickup;
            });
    }

    async function bookPickup() {
        try {
            await Api.bookPickup({
                orderId: props.order.id,
                pickup: {
                    date: desiredPickupDate,
                    instructions: pickupInstructions,
                    timeInterval: {
                        start: pickupStartTime,
                        end: pickupEndTime,
                    },
                },
            });
            props.history.replace(`/orders/${props.order.id}`);
        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.error
            ) {
                switch (error.response.data.error) {
                    case "INVALID_TIME_ERROR": {
                        setErrorMessage(`Ogiltig tid, försök igen.`);
                        break;
                    }
                    case "CUT_OFF_TIME_PASSED_ERROR": {
                        setErrorMessage(
                            `Stopptiden har passerats, boka en upphämtning nästa möjliga dag.`,
                        );
                        break;
                    }
                    default: {
                        setErrorMessage(`Ett fel inträffade. Försök igen.`);
                        break;
                    }
                }
            } else {
                setErrorMessage(`Ett fel inträffade. Försök igen.`);
            }
        }
    }

    function renderDeliveryDate(date) {
        if (!date) {
            return null;
        }
        switch (date) {
            case "INTERVAL_2_7": {
                return (
                    <div className="timestamp">
                        Beräknad leverans 2-7 arbetsdagar beroende på avstånd.
                    </div>
                );
            }
            case "INTERVAL_2_5": {
                return (
                    <div className="timestamp">
                        Beräknad leverans 2-5 arbetsdagar beroende på avstånd.
                    </div>
                );
            }
            default: {
                return (
                    <div className="timestamp">
                        Beräknad leverans{" "}
                        {moment(date).calendar(null, {
                            sameDay: "[idag]",
                            nextDay: "[imorgon]",
                            nextWeek: "[på] dddd",
                            sameElse: "LL",
                        })}
                        .
                    </div>
                );
            }
        }
    }

    if (loading || !props.order) {
        return (
            <Container>
                <div className="loader">
                    <Loader />
                </div>
            </Container>
        );
    } else {
        return (
            <Container>
                <div className="datetime">
                    <label>Datum</label>
                    <label>Från</label>
                    <label>Till</label>
                    <Input
                        onChange={setDesiredPickupDate}
                        type="dropdown"
                        options={getDesiredPickupDates()}
                    />
                    {pickupDate === desiredPickupDate && (
                        <>
                            {!!(pickupStartTime && pickupEndTime) && (
                                <>
                                    <Input
                                        type="dropdown"
                                        options={availablePickupStartTimes}
                                        onChange={setPickupStartTime}
                                        value={pickupStartTime}
                                    />
                                    <Input
                                        type="dropdown"
                                        options={availablePickupEndTimes}
                                        onChange={setPickupEndTime}
                                        value={pickupEndTime}
                                    />
                                </>
                            )}
                        </>
                    )}
                </div>
                <div className="deliveryDate">
                    {pickupDate === desiredPickupDate &&
                        renderDeliveryDate(deliveryDate)}
                    {pickupDate &&
                        pickupDate !== desiredPickupDate &&
                        "Detta datum går inte att boka en upphämtning på."}
                    {!pickupDate && desiredPickupDate && (
                        <div className="loader">
                            <Loader />
                        </div>
                    )}
                </div>
                <div className="subtitle">Upphämtningsinstruktioner</div>
                <Input
                    onChange={setPickupInstructions}
                    value={pickupInstructions}
                    type="textarea"
                />

                <div className="subtitle">Leveransinstruktioner</div>
                <Input
                    onChange={setDeliveryInstructions}
                    value={deliveryInstructions}
                    type="textarea"
                />
                <div className="buttons">
                    <button
                        className="c-button c-button--raised"
                        onClick={bookPickup}
                        disabled={pickupDate !== desiredPickupDate}
                    >
                        Boka upphämtning
                    </button>
                </div>
                {errorMessage && (
                    <div className="errorMessage">{errorMessage}</div>
                )}
            </Container>
        );
    }
}

export default withRouter(Component);
