import Api from "../../../../services/Api/Api";

export async function getOrders(customerId) {
    try {
        let orders = await Api.getOrdersByCarrier({
            customerId: customerId,
            carrier: "POSTNORD",
            filterOrdersWithoutPickup: true,
        });
        const filteredOrders = orders.filter(
            (order) => !order.data.service.productCode.includes("Varubrev"),
        );
        return filteredOrders.map((order) => ({
            orderId: order.id,
            packages: order.data.packages,
            pickupStopDateTime: order.data.pickup.pickupStopDateTime,
        }));
    } catch (error) {
        throw error;
    }
}

/**
 * Books a pickup with PostNord for given packages and pickup location.
 * @param orderIds - Array of Order IDs to book the pickup for
 * @param packagesToPickup - Packages to be picked up
 * @param pickupLocation - Location of the pickup
 * @param pickupInstructions - Instructions and pickup date for the pickup
 * @param setErrorMessage - Function to set the error message
 * @returns {Promise<AxiosResponse<*>>} - Response from the API
 */
export async function bookPickup({
    orderIds,
    packagesToPickup,
    pickupLocation,
    pickupInstructions,
    setErrorMessage,
}) {
    try {
        return await bookPickupApiCall(
            orderIds,
            packagesToPickup,
            pickupLocation,
            pickupInstructions,
        );
    } catch (error) {
        setErrorMessage(error.response.data.message || error.message);
    }
}

/**
 * Returns the pickup location from the form.
 * @param user - The user from context
 * @param otherPickupAddress - Whether the pickup location is the user's address or a custom address
 * @param companyRef - Reference to the company input
 * @returns {{countryCode, postalCode, contact: {phoneNumber, name, company, email}, addressLine1, addressLine2, postalTown}|*} - The pickup location
 */
export function getPickupLocation(user, otherPickupAddress, companyRef) {
    if (otherPickupAddress) {
        return companyRef.current.value();
    }
    return {
        addressLine1: user.customer.addressLine1,
        addressLine2: user.customer.addressLine2,
        postalTown: user.customer.postalTown,
        postalCode: user.customer.postalCode,
        countryCode: user.customer.countryCode,
        contact: {
            name: user.name,
            company: user.customer.name,
            email: user.email,
            phoneNumber: user.phoneNumber,
        },
    };
}

export function getPackagesToPickup(
    selectedPackages,
    isManualPickup,
    pickupInputRefValue,
) {
    if (isManualPickup) {
        return [
            {
                count: pickupInputRefValue.count,
                packageType: pickupInputRefValue.packageType,
                totalWeight: pickupInputRefValue.totalWeight,
            },
        ];
    } else {
        return selectedPackages;
    }
}

/**
 * Performs the API call to book a pickup.
 * @param orderIds - Array of Order IDs to book the pickup for
 * @param packages - Packages to be picked up
 * @param pickupLocation - Location of the pickup
 * @param pickupInstructions - Instructions and pickup date for the pickup
 * @returns {Promise<AxiosResponse<any>>} - Response from the API
 */
async function bookPickupApiCall(
    orderIds,
    packages,
    pickupLocation,
    pickupInstructions,
) {
    return await Api.bookPickup({
        orderIds: orderIds,
        packages: packages,
        pickupLocation: pickupLocation,
        pickupInstructions: pickupInstructions,
        serviceName: "postnord",
    });
}
