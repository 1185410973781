import moment from "moment";
import Papa from "papaparse";
import { v4 as uuidv4 } from "uuid";

const noSurchargeTitles = [
    "Ändr av transportuppdrag",
    "Felaktig sändningsuppgift",
    "Leveranshinder",
    "Leverans till privatperson",
    "Varuförsäkring",
    "DHL Varuförsäkring",
    "Avisering",
    "PRE-7",
    "PRE-10",
    "PRE-12",
    "Farligt gods",
];

function parseCellTitle(original) {
    switch (original) {
        case "Storstads tillï¿½gg GOT":
            return "Storstads tillägg GOT";
        case "ï¿½ndr av transportuppdrag":
            return "Ändr av transportuppdrag";
        case "Kapacitetstillï¿½gg":
            return "Kapacitetstillägg";
        case "Ej uthï¿½mtad sï¿½ndning":
            return "Ej uthämtad sändning";
        case "Trafikstï¿½rningstillï¿½gg":
            return "Trafikstörningstillägg";
        case "Sï¿½songstillï¿½gg":
            return "Säsongstillägg";
    }
    original = original.replace(/tillï¿½gg/g, "tillägg");
    original = original.replace(/fï¿½rsï¿½kring/g, "försäkring");
    original = original.replace(/Utanfï¿½r/g, "Utanför");
    original = original.replace(/Vï¿½gskatt/g, "Vägskatt");
    original = original.replace(/sïndningsuppgift/g, "sändningsuppgift");
    return original;
}

function parse({ data, fileName }) {
    let rows = Papa.parse(data, {
        delimiter: ";",
        skipEmptyLines: true,
        quoteChar: "'",
    });

    if (rows.errors.length) {
        window.alert(
            "Filen är inte korrekt formatterad. Granska (!!!) och skicka till Tobbe om något saknas.\nFelmeddelanden:\n" +
                rows.errors.map((err) => err.message).join("\n"),
        );
    }

    let orders = [];

    for (let i = 1; i < rows.data.length; i++) {
        const trackingNumber =
            rows.data[i][35] || `z${Math.floor(Math.random() * 1000000000)}`;
        if (!rows.data[i][122]) {
            // If the three first columns are empty/falsy this is an empty row and we should skip it.
            if (!rows.data[i][1] && !rows.data[i][2] && !rows.data[i][3]) {
                break;
            }
            alert(
                `Row with no total price was found in this file. Skipping. ${trackingNumber}`,
            );
            continue;
        }

        let billedWeight = parseFloat(rows.data[i][50]);
        // let weight = parseFloat(rows.data[i][52]) || parseFloat(rows.data[i][51]) || null;
        // let volume = parseFloat(rows.data[i][54]) || parseFloat(rows.data[i][53]) || null;
        // let loadingMetres = parseFloat(rows.data[i][56]) || parseFloat(rows.data[i][55]) || null;
        let addVat = parseInt(rows.data[i][121]) !== 0;

        let totalShipmentCost = parseFloat(rows.data[i][122].replace(",", "."));
        let totalShipmentCostVat = 0;
        let totalShipmentCostNoVat = 0;

        let extras = [];

        if (rows.data[i][125]) {
            let price = parseFloat(rows.data[i][125].replace(",", "."));
            extras.push({
                title: "Drivmedelstillägg / -justering",
                cost: price,
                vat: addVat,
                surcharge: true,
                key: uuidv4(),
            });
            if (addVat) {
                totalShipmentCostVat += price;
            } else {
                totalShipmentCostNoVat += price;
            }
        }
        if (rows.data[i][137]) {
            let price = parseFloat(rows.data[i][137].replace(",", "."));
            extras.push({
                title: "Gotlandstillägg",
                cost: price,
                vat: addVat,
                surcharge: true,
            });
            if (addVat) {
                totalShipmentCostVat += price;
            } else {
                totalShipmentCostNoVat += price;
            }
        }
        if (rows.data[i][140]) {
            let price = parseFloat(rows.data[i][140].replace(",", "."));
            extras.push({
                title: "Storstads- / Ortstillägg",
                cost: price,
                vat: addVat,
                surcharge: true,
                key: uuidv4(),
            });
            if (addVat) {
                totalShipmentCostVat += price;
            } else {
                totalShipmentCostNoVat += price;
            }
        }

        let loop = true;
        let titleCellIndex = 147;
        while (loop) {
            if (!rows.data[i][titleCellIndex] || titleCellIndex >= 182) {
                loop = false;
            } else {
                let title = parseCellTitle(rows.data[i][titleCellIndex]);

                if (parseFloat(rows.data[i][titleCellIndex + 1])) {
                    const ignoreList = [
                        "Exportspedition 3:e land",
                        "Trafikstörningstillägg",
                        "Marpol tillägg",
                    ];
                    if (
                        title.toLowerCase().indexOf("vägskatt") < 0 &&
                        title.toLowerCase().indexOf("valutatill") < 0
                    ) {
                        if (ignoreList.indexOf(title) < 0) {
                            let price = parseFloat(
                                rows.data[i][titleCellIndex + 1].replace(
                                    ",",
                                    ".",
                                ),
                            );
                            let isInsurance = false;

                            // If insurance!
                            if (
                                [
                                    "Varuförsäkring",
                                    "DHL Varuförsäkring",
                                ].indexOf(title) >= 0
                            ) {
                                isInsurance = true;
                            }
                            extras.push({
                                title: title,
                                cost: price,
                                vat: addVat,
                                isInsurance,
                                surcharge: noSurchargeTitles.indexOf(title) < 0,
                                key: uuidv4(),
                            });
                            if (addVat) {
                                totalShipmentCostVat += price;
                            } else {
                                totalShipmentCostNoVat += price;
                            }
                        }
                    }
                }
                titleCellIndex += 5;
            }
        }

        if (addVat) {
            totalShipmentCostVat += totalShipmentCost;
        } else {
            totalShipmentCostNoVat += totalShipmentCost;
        }

        if (billedWeight) {
            extras.push({
                title: `Fakturerad vikt: ${billedWeight} kg`,
                key: uuidv4(),
            });
        }

        orders.push({
            fileName: fileName,
            transporter: "DHL",
            trackingNumber: trackingNumber,
            reference: rows.data[i][78],
            currentInvoiceNumber: rows.data[i][1],
            date: moment(rows.data[i][39], "YYYYMMDD").format("YYYY-MM-DD"),
            title: "Transport",
            sender: rows.data[i][70],
            senderCountry: rows.data[i][75],
            recipient: rows.data[i][80],
            recipientCountry: rows.data[i][85],
            totalShipmentCost: totalShipmentCost,
            totalShipmentCostVat,
            totalShipmentCostNoVat,
            vat: addVat,
            // pickupDate: moment(rows.data[i][39], "YYYYMMDD").format('YYYY-MM-DD'),
            // deliveryDate: moment(rows.data[i][2], "YYYYMMDD").subtract(1, 'day').format('YYYY-MM-DD'),
            // weight: weight,
            // loadingMetres: loadingMetres,
            // volume: volume,
            rows: extras,
        });
    }

    return orders;
}

export default {
    parse,
};
