const { v4 } = require("uuid");
const moment = require("moment");
const { isMemberOfEU } = require("../../../../../helpers/Country");
const XLSX = require("xlsx");

function parseHeaders(sheet, range, headerRowNum) {
    const headers = [];
    for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
        const cellAddress = XLSX.utils.encode_cell({
            r: headerRowNum,
            c: colNum,
        });
        const cell = sheet[cellAddress];
        headers[colNum] = cell ? cell.v : undefined;
    }
    return headers;
}

function getRowsGroupedByItemId(sheet, range, headerRowNum, headers) {
    const groupedRows = {};
    for (let rowNum = headerRowNum + 1; rowNum <= range.e.r; rowNum++) {
        let row = {};
        for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
            const cellAddress = XLSX.utils.encode_cell({
                r: rowNum,
                c: colNum,
            });
            const cell = sheet[cellAddress];
            row[headers[colNum]] = cell ? cell.v : null;
        }

        const itemId = row["Item ID"];
        if (!groupedRows[itemId]) {
            groupedRows[itemId] = [];
        }
        groupedRows[itemId].push(row);
    }
    return groupedRows;
}

function parseAdditionalRowData(row, vat) {
    const grossAmount = parseFloat(row["Gross amount"]) || 0;
    const creditSurcharge = parseFloat(row["Credit surcharge"]) || 0;

    const cost = grossAmount + creditSurcharge;

    return {
        title: row["Service name"],
        cost,
        vat,
        surcharge: true,
        key: v4(),
    };
}

function parseMainRowData(row, fileName, invoiceDate, invoiceNumber, vat) {
    return {
        fileName,
        transporter: "PostNord",
        invoiceDate: invoiceDate,
        postNordItemId: row["Item ID"],
        // Is replaced by trackingNumber(PN bookingId) in customer-mapping if itemId matches rawResponse itemId on an order
        trackingNumber: `Item ID: ${row["Item ID"]}`,
        reference: row["Reference"] ? row["Reference"] : "",
        currentInvoiceNumber: invoiceNumber,
        date: moment(row["Ordering date"], "YYYYMMDD")
            .toISOString()
            .slice(0, 10),
        title: row["Service name"],
        sender: "",
        recipient: "",
        senderCountry: row["From country"],
        recipientCountry: row["To country"],
        vat,
    };
}

function parse({ workbook, fileName }) {
    const sheet = workbook.Sheets[workbook.SheetNames[0]];
    const range = XLSX.utils.decode_range(sheet["!ref"]);

    const invoiceDate = sheet["B5"].v;
    const invoiceNumber = sheet["A8"].v;

    const headerRowNum = 7 - 1; // Subtract 1 because arrays are 0-based
    const headers = parseHeaders(sheet, range, headerRowNum);

    const groupedRows = getRowsGroupedByItemId(
        sheet,
        range,
        headerRowNum,
        headers,
    );

    const invoiceData = [];
    for (const itemId in groupedRows) {
        const rows = groupedRows[itemId];

        let orderData;
        const orderRows = [];
        let totalShipmentCost = 0;
        let totalShipmentCostVat = 0;
        let totalShipmentCostNoVat = 0;
        rows.forEach((row) => {
            const senderCountry = row["From country"];
            const recipientCountry = row["To country"];
            const isEUShipment =
                isMemberOfEU(senderCountry) && isMemberOfEU(recipientCountry);
            const vat = !row["VAT exempt"] && isEUShipment; // Assuming VAT is true unless explicitly exempt

            const isShipmentMainRow =
                row["Customer number during consignee freight"] ||
                row["Bulk letter reference"]; // Bulk letter reference indicates 'varubrev' and is considered a main row
            if (isShipmentMainRow) {
                // Handle the main shipment row
                orderData = parseMainRowData(
                    row,
                    fileName,
                    invoiceDate,
                    invoiceNumber,
                    vat,
                );

                const grossAmount = parseFloat(row["Gross amount"]) || 0;
                const fuelSurcharge = parseFloat(row["Fuel surcharge"]) || 0;
                const sulphurSurcharge =
                    parseFloat(row["Sulphur surcharge"]) || 0;
                const currencySurcharge =
                    parseFloat(row["Currency surcharge"]) || 0;
                const creditSurcharge =
                    parseFloat(row["Credit surcharge"]) || 0;
                const totalCost =
                    grossAmount +
                    fuelSurcharge +
                    sulphurSurcharge +
                    currencySurcharge +
                    creditSurcharge;

                totalShipmentCost += totalCost;
                if (vat) {
                    totalShipmentCostVat += totalCost;
                } else {
                    totalShipmentCostNoVat += totalCost;
                }

                orderRows.push({
                    title: "Drivmedelstillägg",
                    cost: fuelSurcharge,
                    vat,
                    surcharge: true,
                    key: v4(),
                });

                orderRows.push({
                    title: "Svaveltillägg",
                    cost: sulphurSurcharge,
                    vat,
                    surcharge: true,
                    key: v4(),
                });

                orderRows.push({
                    title: "Valutatillägg",
                    cost: currencySurcharge,
                    vat,
                    surcharge: true,
                    key: v4(),
                });

                orderRows.push({
                    title: "Kredittillägg",
                    cost: creditSurcharge,
                    vat,
                    surcharge: true,
                    key: v4(),
                });

                orderRows.push({
                    title: `Fakturerad vikt: ${parseFloat(
                        row["Chargeable weight"],
                    )} kg`,
                    key: v4(),
                });
            } else {
                // Handle as additional row-data
                const additionalRowData = parseAdditionalRowData(row, vat);
                orderRows.push(additionalRowData);

                totalShipmentCost += additionalRowData.cost;
                if (vat) {
                    totalShipmentCostVat += additionalRowData.cost;
                } else {
                    totalShipmentCostNoVat += additionalRowData.cost;
                }
            }
        });

        if (orderData) {
            orderData.totalShipmentCost = totalShipmentCost;
            orderData.totalShipmentCostVat = totalShipmentCostVat;
            orderData.totalShipmentCostNoVat = totalShipmentCostNoVat;
            orderData.rows = orderRows;
            invoiceData.push(orderData);
        }
    }

    return invoiceData;
}

module.exports = {
    parse,
};
