import React, { useState } from "react";
import { ReactComponent as InformationIcon } from "../../imgs/InfoIcon/InfoIcon.svg";
import InfoModal from "components/InfoModal/InfoModalComponent";

const InfoIcon = ({ packagesLength, bookBatchDirectWithoutQuote }) => {
    const [showInfoModal, setShowInfoModal] = useState(false);
    return (
        <div
            className="flex justify-start items-start "
            onMouseOver={() => setShowInfoModal(true)}
            onMouseOut={() => setShowInfoModal(false)}
            //for mobile devices
            onTouchStart={() => setShowInfoModal(true)}
            onTouchEnd={() => setShowInfoModal(false)}
        >
            <InformationIcon
                width={26}
                height={26}
                className={
                    bookBatchDirectWithoutQuote ? "text-purple" : "text-red-400"
                }
            />
            {showInfoModal && (
                <InfoModal
                    packagesLength={packagesLength}
                    bookBatchDirectWithoutQuote={bookBatchDirectWithoutQuote}
                />
            )}
        </div>
    );
};

export default InfoIcon;
