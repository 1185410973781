import moment from "moment";
import React, { useEffect, useState } from "react";

const PackagesTable = ({
    orders,
    checkedStates,
    setCheckedStates,
    errorMessage,
    pickupDate,
}) => {
    const [totalMeasurements, setTotalMeasurements] = useState({
        totalNumberOfPackages: 0,
        totalWeight: 0,
        totalVolume: 0.0,
    });

    useEffect(() => {
        if (!orders) return;
        const packages = orders.flatMap((order) => {
            if (checkedStates[order.orderId] === true) return order.packages;
        });
        const totalMeasurements = packages.reduce(
            (acc, pkg) => {
                if (!pkg) return acc;
                const packageVolume = parseFloat(
                    (
                        (pkg.length || 0) *
                        (pkg.width || 0) *
                        (pkg.height || 0) *
                        0.000001
                    ).toFixed(3),
                );
                return {
                    totalNumberOfPackages:
                        acc.totalNumberOfPackages + pkg.count || 0,
                    totalWeight: acc.totalWeight + pkg.weight * pkg.count || 0,
                    totalVolume: parseFloat(
                        (acc.totalVolume + packageVolume).toFixed(3),
                    ),
                };
            },
            {
                totalNumberOfPackages: 0,
                totalWeight: 0,
                totalVolume: 0.0,
            },
        );
        setTotalMeasurements(totalMeasurements);
    }, [checkedStates]);

    const handleCheckboxChange = (order) => {
        const orderId = order.orderId;
        if (isPastCutOffTime(order.pickupStopDateTime)) return;
        setCheckedStates((prevState) => ({
            ...prevState,
            [orderId]: !prevState[orderId],
        }));
    };

    const handleSelectAllCheckboxChange = () => {
        const allOrdersCheckedState = {};
        const shouldCheckAll = !Object.values(checkedStates).every(Boolean);

        orders.forEach((order) => {
            const key = order.orderId;
            if (isPastCutOffTime(order.pickupStopDateTime)) {
                allOrdersCheckedState[key] = checkedStates[key];
            } else {
                allOrdersCheckedState[key] = shouldCheckAll;
            }
        });

        setCheckedStates(allOrdersCheckedState);
    };

    const { totalNumberOfPackages, totalWeight, totalVolume } =
        totalMeasurements;

    const prettyType = (type) => {
        switch (type) {
            case "pallet":
                return "Pall";
            case "package":
                return "Paket";
            case "envelope":
                return "Envelope";
        }
    };

    const isPastCutOffTime = (cutOffTime) => {
        if (pickupDate === moment().format("YYYY-MM-DD") && cutOffTime) {
            return moment().format("HH:mm") > cutOffTime.slice(11, 16);
        }
        return false;
    };

    const disableSelectAllCheckbox = orders.every((order) =>
        isPastCutOffTime(order.pickupStopDateTime),
    );

    return (
        <div className="p-6 mb-6 rounded border border-zinc-100 flex-col justify-start items-start gap-6 inline-flex">
            <div className="text-stone-400 text-2xl font-normal">
                Steg 2 - Välj vilka försändelser som ska hämtas:
            </div>
            {errorMessage && (
                <div className="text-red-500 text-sm font-normal">
                    {errorMessage}
                </div>
            )}
            {!errorMessage && (
                <div className="flex-col justify-start items-start gap-2 w-full">
                    {orders && (
                        <>
                            <div className="flex justify-start gap-3 items-center pb-2 border-gray-800 border-b-1 w-full">
                                <input
                                    type="checkbox"
                                    checked={Object.values(checkedStates).every(
                                        Boolean,
                                    )}
                                    onChange={handleSelectAllCheckboxChange}
                                    disabled={disableSelectAllCheckbox}
                                    className="h-4 w-4 text-green-500 focus:ring-green-500 border-gray-300 rounded"
                                />
                                <div className="text-black text-base font-medium">
                                    Välj alla försändelser
                                </div>
                            </div>
                            {orders.map((order) => (
                                <div key={order.orderId}>
                                    <div className="flex justify-start gap-3 items-center pt-5 pb-2 w-full">
                                        <input
                                            type="checkbox"
                                            checked={
                                                checkedStates[order.orderId]
                                            }
                                            onChange={() =>
                                                handleCheckboxChange(order)
                                            }
                                            disabled={isPastCutOffTime(
                                                order.pickupStopDateTime,
                                            )}
                                            className="h-4 w-4 text-green-500 focus:ring-green-500 border-gray-300 rounded"
                                        />
                                        <div className="text-black text-sm font-extralight">
                                            Försändelse: {order.orderId}
                                        </div>
                                        {order.pickupStopDateTime && (
                                            <div className="flex flex-col flex-1">
                                                {isPastCutOffTime(
                                                    order.pickupStopDateTime,
                                                ) && (
                                                    <div className="text-error-400 text-sm font-medium text-right flex-1">
                                                        Kan ej bokas för
                                                        upphämtning idag, ändra
                                                        datum nedan
                                                    </div>
                                                )}
                                                <div className="text-black text-sm font-extralight text-right ">
                                                    Stopptid: vardagar{" "}
                                                    {order.pickupStopDateTime.slice(
                                                        11,
                                                        16,
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {order.packages &&
                                        order.packages.length > 0 && (
                                            <div className="border-t border-gray-200 shadow w-full">
                                                <dl>
                                                    {order.packages.map(
                                                        (pkg, index) => (
                                                            <div
                                                                key={index}
                                                                className={`${
                                                                    index %
                                                                        2 ===
                                                                    0
                                                                        ? "bg-gray-50"
                                                                        : "bg-white"
                                                                } 
                                                                py-5 px-3 w-full flex justify-between items-center`}
                                                            >
                                                                <div className="pl-10 text-sm font-extralight text-gray-500">
                                                                    {pkg.count}{" "}
                                                                    st{" "}
                                                                    {prettyType(
                                                                        pkg.type,
                                                                    )}
                                                                </div>
                                                                <div className="text-sm text-gray-900 font-extralight justify-self-end">
                                                                    {pkg.length >
                                                                    0 ? (
                                                                        <span>
                                                                            {
                                                                                pkg.length
                                                                            }
                                                                            x
                                                                            {
                                                                                pkg.width
                                                                            }
                                                                            x
                                                                            {
                                                                                pkg.height
                                                                            }{" "}
                                                                            cm
                                                                        </span>
                                                                    ) : pkg.volume ? (
                                                                        <span>
                                                                            {
                                                                                pkg.volume
                                                                            }{" "}
                                                                            m
                                                                            <sup>
                                                                                3
                                                                            </sup>
                                                                        </span>
                                                                    ) : (
                                                                        <span>
                                                                            -
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                <div className="text-sm text-gray-900 font-extralight justify-self-end">
                                                                    <span>
                                                                        {
                                                                            pkg.weight
                                                                        }{" "}
                                                                        kg
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ),
                                                    )}
                                                </dl>
                                            </div>
                                        )}
                                </div>
                            ))}
                        </>
                    )}
                </div>
            )}
            <div className="flex h-8 items-center justify-between py-2 w-full  border-gray-800 border-t-1">
                <div className="flex gap-2">
                    <div className="text-sm font-extrabold text-black">
                        Totalt:
                    </div>
                    <div className="text-sm font-normal text-neutral-800">
                        {totalNumberOfPackages} st kolli
                    </div>
                </div>
                <div className="text-sm font-normal text-neutral-800">
                    {totalVolume} m<sup className="text-xs">3</sup>
                </div>
                <div className="text-sm font-normal pr-2 text-neutral-800">
                    {totalWeight} kg
                </div>
            </div>
        </div>
    );
};

export default PackagesTable;
