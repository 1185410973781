import moment from "moment";
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import styled from "styled-components";

let Container = styled.div`
    > .fields {
        display: flex;
        gap: 1rem;
        align-items: center;

        > .dash {
            width: 10px;
            height: 10px;
            min-width: 10px;
            min-height: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            > svg {
                width: 100%;
                height: 100%;
            }
        }

        > .menu {
            background: #fff;
            padding: 4px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            min-height: 40px;
            min-width: 40px;
        }

        > .inputContainer {
            background: #fff;
            padding: 4px;
            width: 100%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            height: 40px;
            overflow: hidden;

            > input {
                padding: 4px;
                width: 100%;
                border: 0;
                outline: none;
            }

            > input[disabled] {
                background: inherit;
            }

            > .prefix {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                border-right: 1px solid rgba(0, 0, 0, 0.1);
                margin-right: 8px;
                width: 60px;
                min-width: 60px;
                color: rgba(0, 0, 0, 0.4);
            }

            > .unit {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                border-left: 1px solid rgba(0, 0, 0, 0.1);
                margin-left: 8px;
                width: 60px;
                min-width: 60px;
                color: rgba(0, 0, 0, 0.4);
            }

            > .length {
                font-size: 0.8rem;
                padding: 2px 4px;
                font-style: italic;
                font-weight: bold;
                color: #555;

                &.is-invalid {
                    color: #ff5555;
                }
            }
        }
    }

    &.align-right {
        > .fields {
            > .inputContainer {
                > input {
                    text-align: right;
                }
            }
        }
    }

    > .message {
        font-weight: bold;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
    }

    &.is-invalid {
        > .fields {
            > .inputContainer {
                border: 1px solid rgba(255, 0, 0, 0.2);
            }
        }
        > .message {
            color: rgba(255, 0, 0, 0.6);
        }
    }

    &.is-disabled {
        > .fields {
            > .inputContainer {
                background: rgb(235, 235, 228);
                border: 1px solid rgba(0, 0, 0, 0.05);

                > input {
                    color: rgba(0, 0, 0, 0.5);
                    background: transparent;
                }
            }
        }
    }
`;

function Component(props, ref) {
    const [value, setValue] = useState(
        props.value || {
            start: "",
            end: "",
        },
    );
    const [unit, setUnit] = useState();
    const [message, setMessage] = useState();
    const [disabled, setDisabled] = useState();
    const [isInvalid, setIsInvalid] = useState(false);
    const [isInteger, setIsInteger] = useState();
    const [startDate, setStartDate] = useState(props.value?.start || "");
    const [endDate, setEndDate] = useState(props.value?.end || "");

    useEffect(() => {
        setUnit(props.unit);
    }, [props.unit]);

    useEffect(() => {
        setDisabled(props.disabled || false);
    }, [props.disabled]);

    useEffect(() => {
        setIsInteger(props.isInteger || false);
    }, [props.isInteger]);

    function onKeyUp() {}

    function onChange(dateRange) {
        setValue((oldValue) => {
            const newValue = {
                ...oldValue,
                ...dateRange,
            };

            if (props.maxLengthDays) {
                const diff = Math.abs(
                    moment(newValue.end).diff(moment(newValue.start), "days"),
                );
                if (dateRange.start && diff > props.maxLengthDays) {
                    newValue.end = moment(newValue.start)
                        .add(props.maxLengthDays, "days")
                        .format("YYYY-MM-DD");
                } else if (dateRange.end && diff > props.maxLengthDays) {
                    newValue.start = moment(newValue.end)
                        .subtract(props.maxLengthDays, "days")
                        .format("YYYY-MM-DD");
                }
            }

            setStartDate(newValue.start);
            setEndDate(newValue.end);
            setTimeout(() => {
                props.onChange(newValue);
            }, 0);
            return newValue;
        });
    }

    function getValue() {
        return value;
    }

    function validate() {
        return true;
    }

    function set() {
        return true;
    }

    function clear() {
        return true;
    }

    useImperativeHandle(ref, () => {
        return {
            validate: validate,
            clear: clear,
            value: getValue,
            set: set,
        };
    });

    return (
        <Container
            className={
                (disabled ? " is-disabled" : "") +
                (isInvalid ? " is-invalid" : "") +
                (props.align ? " align-" + props.align : "")
            }
        >
            <div className="fields">
                <div className="inputContainer">
                    <input
                        type="date"
                        value={startDate}
                        onChange={(ev) => {
                            onChange({
                                start: ev.target.value,
                            });
                        }}
                    />
                </div>
                <div className="dash">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path d="M0 10h24v4h-24z" />
                    </svg>
                </div>
                <div className="inputContainer">
                    <input
                        type="date"
                        value={endDate}
                        onChange={(ev) => {
                            onChange({
                                end: ev.target.value,
                            });
                        }}
                    />
                </div>
                {/* <div className="menu">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
                </div> */}
            </div>
            {message && <div className="message">{message}</div>}
        </Container>
    );
}

export default forwardRef(Component);
