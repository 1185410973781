import moment from "moment";
import React, { useContext, useRef, useState } from "react";
import styled from "styled-components/macro";

import Input from "components/OldInput";
import Context from "context/Global";
import Api from "services/Api/Api";
import CompanyForm from "views/Drafts/Create/Route/CompanyForm";
import toast from "react-hot-toast";

let Container = styled.div`
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
    width: 100%;
    max-width: 600px;

    > .title {
        font-size: 32px;
        color: #aaa;
        margin-bottom: 16px;
    }

    > .address {
        margin: 16px 0;
    }

    > .error {
        margin-top: 16px;
        color: #d77;
        font-weight: bold;
    }
`;

function CreateBringPickup(props) {
    const context = useContext(Context);
    const [otherPickupAddress, setOtherPickupAddress] = useState(false);
    const [pickup, setPickup] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [approvedTerms, setApprovedTerms] = useState(false);
    const pickupFormRef = useRef();
    const companyRef = useRef();

    async function bookPickup() {
        if (pickupFormRef.current.validate()) {
            let pickupForm = pickupFormRef.current.value();

            let pickupDate = moment.utc(pickupForm.date).toISOString();

            if (pickupDate < moment().format("YYYY-MM-DD")) {
                return setErrorMessage("Datumet måste vara i framtiden.");
            }

            if (pickupForm.totalWeight === 0) {
                return pickupFormRef.current.setValidationMessage({
                    key: "totalWeight",
                    message: "Vikten måste vara större än 0",
                });
            } else {
                // order pickup for packages.
                let pickupLocation = {
                    addressLine1: context.user.customer.addressLine1,
                    addressLine2: context.user.customer.addressLine2,
                    postalTown: context.user.customer.postalTown,
                    postalCode: context.user.customer.postalCode,
                    countryCode: context.user.customer.countryCode,
                    contact: {
                        name: context.user.name,
                        company: context.user.customer.name,
                        ...(context.user.email && {
                            email: context.user.email,
                        }),
                        phoneNumber: context.user.phoneNumber,
                    },
                };

                let packages = [
                    {
                        count: pickupForm.count,
                        totalWeight: pickupForm.totalWeight,
                        packageType: pickupForm.packageType,
                    },
                ];

                if (otherPickupAddress) {
                    pickupLocation = companyRef.current.value();
                }

                if (
                    pickupFormRef.current.value().packageType === "pallet" &&
                    pickupFormRef.current.value().count > 1
                ) {
                    return setErrorMessage(
                        "Bring kan endast hämta en pall åt gången.",
                    );
                }

                try {
                    let pickup = await Api.bookPickup({
                        packages: packages,
                        pickupLocation: pickupLocation,
                        dateTime: pickupDate,
                        serviceName: "bring",
                    });

                    setPickup(pickup);
                } catch (error) {
                    toast.error(
                        "Ett fel uppstod, kontrollera att informationen ovan är korrekt och försök igen. Annars kontakta kundtjänst.",
                    );
                }
            }
        }
    }

    if (pickup) {
        return (
            <Container>
                <div className="title">Bring</div>
                Din upphämtning är bokad med bokningsnummer{" "}
                {pickup.confirmationNumber}.
            </Container>
        );
    }

    return (
        <Container>
            <div className="title">Bring</div>
            <Input
                ref={pickupFormRef}
                type="list"
                object={{
                    count: {
                        title: "Antal",
                        type: "number",
                        min: 1,
                        unit: "st",
                        required: true,
                        message_required:
                            "Ange antalet paket ni vill ska hämtas upp",
                        message_minlimit:
                            "Antalet paket måste vara 1 eller fler",
                    },
                    totalWeight: {
                        title: "Total vikt",
                        type: "number",
                        min: 0,
                        unit: "kg",
                        required: true,
                        message_required: "Ange totala vikten för alla paket",
                        message_minlimit: "Vikten måste vara större än 0",
                    },

                    packageType: {
                        title: "Pakettyp",
                        type: "dropdown",
                        required: true,
                        options: [
                            {
                                title: "Paket",
                                value: "package",
                            },
                            {
                                title: "Pall",
                                value: "pallet",
                            },
                        ],
                    },
                    date: {
                        title: "Datum",
                        type: "date",
                        unit: "kg",
                        required: true,
                        value: moment().format("YYYY-MM-DD"),
                    },
                }}
            />
            <div className="flex py-3 gap-3 items-center">
                <input
                    type="checkbox"
                    checked={approvedTerms}
                    onChange={() => setApprovedTerms(!approvedTerms)}
                    className="h-6 w-6 text-green-500 focus:ring-green-500 border-gray-300 rounded"
                />
                <p>
                    Jag är medveten om att upphämtningen ej går att avboka efter
                    att den är bokad.
                </p>
            </div>
            <div className="address">
                {!!otherPickupAddress && (
                    <CompanyForm
                        ref={companyRef}
                        disableSaveButton={true}
                        company={{
                            ...context.user.customer,
                            contact: {
                                ...context.user,
                                company: context.user.customer.name,
                            },
                        }}
                    />
                )}
                {!otherPickupAddress && (
                    <div className="text">
                        Upphämtning bokas till{" "}
                        <b>{context.user.customer.name}</b> på{" "}
                        <b>{context.user.customer.addressLine1}</b>.
                    </div>
                )}
            </div>
            <div className="buttons">
                <button
                    onClick={bookPickup}
                    className="c-button c-button--raised"
                    disabled={!approvedTerms}
                >
                    Boka upphämtning
                </button>
                {!otherPickupAddress && (
                    <button
                        className="c-button c-button--ghost"
                        onClick={() => setOtherPickupAddress(true)}
                    >
                        Ändra adress
                    </button>
                )}
            </div>
            {errorMessage && (
                <div className="error">
                    <div className="message">{errorMessage}</div>
                </div>
            )}
        </Container>
    );
}

export default CreateBringPickup;
