import "moment/locale/sv";
import React from "react";
import styled from "styled-components/macro";

let Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

function Component(props) {
    return <Container>{props.children}</Container>;
}

export default Component;
