import React, { useState } from "react";

const InformationHover = ({
    children,
    onClick,
    icon = "/images/icons/info-circle.svg",
    disabled = false,
}) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div
            className="relative flex items-center"
            onMouseEnter={() => setShowTooltip(!disabled)}
            onMouseLeave={() => setShowTooltip(false)}
            onClick={(e) => onClick?.(e)}
        >
            <img src={icon} alt="info" className="cursor-pointer z-20" />
            {showTooltip && (
                <div
                    className="w-80 absolute bottom-full mb-2 px-2 py-1 bg-white text-black border border-gray-400 rounded-lg text-sm z-30"
                    style={{
                        minWidth: "120px",
                        transform: "translateX(-50%)",
                        left: "50%",
                    }}
                >
                    {children}
                </div>
            )}
        </div>
    );
};

export default InformationHover;
