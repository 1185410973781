import moment from "moment";
import { swedishHolidays } from "../../../../../../utils/holidays";

/**
 * Calculates the cutoff times for the selected date.
 * @param timeSlots - The available time slots for the selected date.
 * @param setIntervalMessage - The function to set the interval message.
 * @param cutoffTime - The cutoff time for the selected date.
 * @returns {*} - The available time slots for the selected date.
 */
export function calculateCutoffTime({
    timeSlots,
    setIntervalMessage,
    cutoffTime,
}) {
    const availableTimes = timeSlots.filter(({ title: timeSlot }) => {
        return timeSlot <= cutoffTime;
    });
    if (availableTimes.length < 1) {
        setIntervalMessage(
            "Det finns inga tillgängliga hämtningstider för valt datum.",
        );
    } else {
        setIntervalMessage("");
    }
    return availableTimes;
}

/**
 * Calculates the start time for the "from" time picker for pickup booking.
 * @param earliestPickup - The earliest pickup time for the selected date.
 * @param cutoffOffset - The cutoff offset for the selected date.
 * @returns {string} - The end time for the "from" time picker.
 */
export function calculateStartTimeFrom({ earliestPickup, cutoffOffset }) {
    return moment(earliestPickup, "HH:mm")
        .add(cutoffOffset, "minutes")
        .format("HH:mm");
}

/**
 * Calculates the end time for the "to" time picker for pickup booking.
 * @param latestPickup - The latest pickup time for the selected date.
 * @param cutoffOffset - The cutoff offset for the selected date.
 * @returns {string} - The end time for the "to" time picker.
 */
export function calculateEndTimeTo({ latestPickup, cutoffOffset }) {
    return moment(latestPickup, "HH:mm")
        .subtract(cutoffOffset, "minutes")
        .format("HH:mm");
}

/**
 * Adds 15 minutes to current time and rounds up to nearest quarter when the current minute is passed the quarter.
 * @returns {string} - The time rounded up to nearest quarter.
 */
export function roundToNearestQuarter() {
    let startTimeObject = moment();
    let minutes = startTimeObject.minute();
    let roundedMinutes = Math.ceil(minutes / 15) * 15;
    if (roundedMinutes === 60) {
        startTimeObject.add(1, "hour").minute(0);
    } else {
        startTimeObject.minute(roundedMinutes);
    }

    return startTimeObject.format("HH:mm");
}

export function isWorkday(date) {
    return moment(date).isoWeekday() < 6 && swedishHolidays.indexOf(date) < 0;
}
