import React, { useEffect, useState } from "react";
import Table from "components/Table";
import Pagination from "components/Pagination";
import Loader from "components/Loader/Loader";
import toast from "react-hot-toast";
import Api from "services/Api/Api";
import InputField from "components/OldInput/Text";
import moment from "moment";

function ReceiverPaysCustomerTable() {
    const limit = 10;
    const [receiverPaysCustomerData, setReceiverPaysCustomerData] =
        useState(null);
    const [searchCustomer, setSearchCustomer] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [page, setPage] = useState(1);

    useEffect(() => {
        searchReceiverPaysCustomers();
    }, []);

    const loadReceiverPayCustomerList = async (search) => {
        setReceiverPaysCustomerData(null);
        setPage(1);
        try {
            const { customerData } =
                await Api.getReceiverPaysCustomerList(search);
            setReceiverPaysCustomerData(customerData);
        } catch (error) {
            toast.error(
                "Något gick fel när vi hämtade alla kunder med mottagarfrakt. Försök igen.",
            );
        }
    };

    const searchReceiverPaysCustomers = async () => {
        setIsSearching(true);
        await loadReceiverPayCustomerList(searchCustomer);
        setIsSearching(false);
    };

    const renderHeader = () => (
        <thead>
            <tr>
                <th>Kund-ID</th>
                <th>Namn</th>
                <th>Aktiverad av</th>
                <th>Abonnemang</th>
                <th>Aktiverad</th>
                <th>Slutdatum</th>
                <th>Status</th>
                <th></th>
            </tr>
        </thead>
    );

    const renderRow = (customerData) => (
        <tr key={`receiverPays_customer_row_${customerData.customerId}`}>
            <td>{customerData.customerId}</td>
            <td>{customerData.customerName}</td>
            <td>{`${customerData.username} (${customerData.userId})`}</td>
            <td>
                {`${customerData.subscriptionType} / ${customerData.billingType}`}
            </td>
            <td>{moment(customerData.activatedAt).format("YYYY-MM-DD")}</td>
            <td>
                {customerData.subscriptionEndsAt != null
                    ? moment(customerData.subscriptionEndsAt).format(
                          "YYYY-MM-DD",
                      )
                    : "-"}
            </td>
            <td
                className="font-bold"
                style={{
                    color: customerData.activeSubscription
                        ? "rgb(100, 200, 100)"
                        : "rgb(200, 100, 100)",
                }}
            >
                {customerData.activeSubscription ? "Aktiv" : "Inaktiv"}
            </td>
            <td />
        </tr>
    );

    const currentPage = (receiverPaysCustomerData ?? [])?.slice(
        (page - 1) * limit,
        page * limit,
    );

    return (
        <div className="w-full flex justify-start flex-col box-border">
            <div className="flex items-center gap-10 p-3">
                <div className="flex gap-4 h-full items-end self-end">
                    <InputField
                        placeholder="Sök mottagare"
                        maxLength={50}
                        onChange={setSearchCustomer}
                        onClickSearch={searchReceiverPaysCustomers}
                    />
                    <button
                        onClick={searchReceiverPaysCustomers}
                        className="px-4 py-2 bg-buttonColor text-white rounded-md hover:bg-primary500 disabled:opacity-50"
                        disabled={!!isSearching}
                    >
                        Sök
                    </button>
                </div>
            </div>
            {isSearching ? (
                <Loader />
            ) : (
                <>
                    <Table>
                        {renderHeader()}
                        <tbody>
                            {currentPage.map((receiverPaysCustomerData) =>
                                renderRow(receiverPaysCustomerData),
                            )}
                        </tbody>
                    </Table>
                    {!receiverPaysCustomerData?.length && !isSearching && (
                        <div className="flex justify-center items-center h-32">
                            <p className="text-xl">Inga kunder hittades</p>
                        </div>
                    )}

                    <Pagination
                        setPage={setPage}
                        page={page}
                        maxPage={Math.ceil(
                            (receiverPaysCustomerData?.length ?? 1) / limit,
                        )}
                        useUrlParam={false}
                    />
                </>
            )}
        </div>
    );
}

export default ReceiverPaysCustomerTable;
