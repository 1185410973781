import React, { useContext, useState } from "react";
import Api from "../../../../services/Api/Api";
import toast from "react-hot-toast";
import Context from "../../../../context/Global";
import FormSection from "../../components/FormSection";
import Loader from "../../../../components/Loader/Loader";
import { handleDownloadS3 } from "../../../../utils/s3";
import TextSection from "../../components/TextSection";
import Input from "../../../../components/OldInput";
import { useHistory } from "react-router-dom";
import DisplayField from "../../components/DisplayField";
import Integrations from "../../../../constants/integrations";
import { getIntegrationName } from "../../../../helpers/Integrations";

const ViewIntegrationDetails = ({ integration }) => {
    const context = useContext(Context);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [removing, setRemoving] = useState(false);
    const [formData, setFormData] = useState({
        system: integration.data.config.system || "",
        otherSystem: integration.data.config.otherSystem || "",
        webhookUrl: integration.data.config.webhookUrl || "",
        apiKey: integration.identifier || "",
    });

    const integrationOptions = Object.keys(Integrations)
        .map((integration) => {
            const prettyName = getIntegrationName(integration);
            if (prettyName) {
                return {
                    value: prettyName,
                };
            }

            return null;
        })
        .filter(Boolean);

    const handleChange = (value) => {
        setFormData((prevData) => ({
            ...prevData,
            ...value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (formData.system === "Annat System" && !formData.otherSystem) {
            toast.error(
                "Fältet 'Ange System Namn' är obligatoriskt när 'Annat System' är valt.",
            );
            return;
        }

        if (formData.webhookUrl) {
            try {
                setIsLoading(true);
                await Api.verfifyWebhookUrl(formData.webhookUrl);
            } catch (error) {
                toast.error("Webhook URL är felaktig eller ej nåbar.");
                setIsLoading(false);
                return;
            }
        }

        try {
            await Api.updateIntegration({
                customerId: context.user.customer.id,
                integrationId: integration.id,
                data: {
                    config: {
                        system: formData.system,
                        otherSystem:
                            formData.system === Integrations.OTHER
                                ? formData.otherSystem
                                : "",
                        webhookUrl: formData.webhookUrl,
                    },
                },
            });
            toast.success("API-användare uppdaterades!");
        } catch (error) {
            toast.error(
                "Misslyckades att uppdatera API-användare. Se över dina inställningar och försök igen!",
            );
        } finally {
            setIsLoading(false);
        }
    };

    const handleRemove = async () => {
        if (
            !window.confirm(
                "Är du säker på att du vill ta bort API-användaren?",
            )
        )
            return;

        setRemoving(true);
        try {
            await Api.removeCustomerIntegration({
                customerId: context.user.customer.id,
                integrationId: integration.id,
            });
            toast.success("API-användaren togs bort.");
            history.push("/addons");
        } catch (error) {
            toast.error("Något gick fel när API-användaren skulle tas bort.");
        } finally {
            setRemoving(false);
        }
    };

    if (isLoading) return <Loader />;

    return (
        <div className="max-w-3xl mx-auto my-4 p-4 bg-white shadow-lg rounded-md">
            <h2 className="text-2xl font-bold mb-4 text-left">
                Uppdatera API Användare
            </h2>
            <TextSection
                title="API Användare"
                paragraphs={[
                    "Här kan du uppdatera informationen för din API-användare.",
                    "Din API-nyckel används för att autentisera anrop till vårt API. Om du använder/vill börja använda en webhook, då kan du uppdatera/lägga till URL:en här.",
                ]}
            />
            <TextSection
                title="Fördelar med att använda vårt API"
                paragraphs={[
                    "Smidig orderhantering där du kan skapa, uppdatera och övervaka alla dina ordrar.",
                    "Realtidsuppdateringar med spårningsinformation så att du alltid vet var dina leveranser befinner sig.",
                    "Möjlighet att automatiskt ta emot uppdateringar via webhook för en ännu mer integrerad upplevelse.",
                ]}
            />
            <TextSection
                title="Hur fungerar webhooks?"
                paragraphs={[
                    "En webhook är en callback URL som vi använder för att skicka realtidsuppdateringar om dina ordrar. När en order bokats i vårt system, skickar vi automatiskt en POST-förfrågan till den angivna webhook-URL:en. För att kunna ta emot dessa uppdateringar måste du ha en endpoint som kan ta emot och hantera POST-förfrågningar.",
                    "Webhooken skickar data i JSON-format som innehåller information om orderns aktuella status, spårningsnummer och annan relevant spårningsdata. Detta gör det möjligt för dig att hålla ditt system synkroniserat med vår plattform och få uppdateringar i realtid om statusen för dina ordrar.",
                    "Om du inte anger en webhook-URL kommer du inte att ta emot dessa uppdateringar, men du kan fortfarande hämta spårningsinformation manuellt via vårt API.",
                ]}
            />
            <div className="mb-4">
                <TextSection
                    title="OpenAPI Specifikation"
                    paragraphs={[
                        "Du kan ladda ner vår OpenAPI-specifikation för att få en detaljerad beskrivning av alla våra API-endpoints och hur du använder dem.",
                    ]}
                />
                <button
                    className="c-button c-button--outlined"
                    onClick={() =>
                        handleDownloadS3({
                            url: `${
                                process.env.REACT_APP_API_URL
                            }/api/s3/integration?objectKey=${encodeURIComponent(
                                "zendr/zendr-openapi.yaml",
                            )}`,
                            desiredFileName: "zendr-openapi.yaml",
                        })
                    }
                >
                    Ladda ner OpenAPI Specifikation
                </button>
            </div>
            <form
                onSubmit={handleSubmit}
                className="grid grid-cols-1 md:grid-cols-2 gap-4"
                noValidate
            >
                <FormSection title="API-inloggningsuppgifter">
                    <Input
                        onChange={(value) =>
                            handleChange({
                                system: value.system,
                            })
                        }
                        type="list"
                        object={{
                            system: {
                                title: "Systeminformation",
                                type: "dropdown",
                                autoComplete: "chrome-off",
                                required: true,
                                options: integrationOptions,
                                value: formData.system,
                            },
                        }}
                    />
                    {formData.system === "Annat System" && (
                        <Input
                            onChange={(value) =>
                                handleChange({
                                    otherSystem: value.otherSystem,
                                })
                            }
                            type="list"
                            object={{
                                otherSystem: {
                                    title: "Ange System Namn",
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    required: true,
                                    value: formData.otherSystem,
                                },
                            }}
                        />
                    )}
                    <Input
                        onChange={(value) =>
                            handleChange({
                                webhookUrl: value.webhookUrl,
                            })
                        }
                        type="list"
                        object={{
                            webhookUrl: {
                                title: "Webhook URL",
                                type: "text",
                                autoComplete: "chrome-off",
                                value: formData.webhookUrl,
                            },
                        }}
                    />
                    <DisplayField
                        label="API-nyckel"
                        value={formData.apiKey}
                        copyToClipboard
                    />
                </FormSection>
                <div className="col-span-1 md:col-span-2 flex justify-between items-center">
                    <button
                        type="submit"
                        className={`w-1/3 c-button c-button--raised text-white p-2 rounded ml-4`}
                        disabled={isLoading}
                    >
                        {isLoading ? "Uppdaterar..." : "Uppdatera"}
                    </button>
                    <button
                        type="button"
                        className="w-1/3 c-button c-button--raised text-white p-2 rounded ml-4"
                        onClick={handleRemove}
                        disabled={removing}
                    >
                        {removing ? "Tar bort..." : "Ta bort"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ViewIntegrationDetails;
