import { useContext, useEffect, useMemo, useState } from "react";
import EmailNotificationsForm from "../EmailNotificationsForm";
import { mergeAdditionalOptions } from "../utils/mergeAdditionalOptions";
import Context from "context/Global";
import { validateEmail } from "../EmailInput/validateEmail";

const useNotificationSettingsForm = (
    orders,
    showSender = true,
    showReceiver = true,
) => {
    const notificationsSettings = orders?.reduce((all, order) => {
        const notificationSettings = order.data?.additionalOptions?.find(
            (option) => option.name === "notificationSettings",
        );
        return notificationSettings
            ? [
                  ...all,
                  {
                      sender: notificationSettings?.value?.[0],
                      receiver: notificationSettings?.value?.[1],
                  },
              ]
            : all;
    }, []);

    const getSettingIfAllSame = (key) =>
        notificationsSettings &&
        notificationsSettings.length === (orders?.length ?? 0) &&
        notificationsSettings.length > 0 &&
        notificationsSettings.every(
            (setting) =>
                setting[key] &&
                notificationsSettings[0][key] &&
                setting[key].email === notificationsSettings[0][key].email,
        )
            ? {
                  ...notificationsSettings[0][key],
                  onTender: notificationsSettings.every(
                      (setting) => setting[key]?.onTender,
                  ),
              }
            : null;

    const notificationSettingsSender = getSettingIfAllSame("sender");
    const notificationSettingsReceiver = getSettingIfAllSame("receiver");

    const context = useContext(Context);

    const [senderEmailNotification, setSenderEmailNotification] = useState({
        email: { value: "", error: false },
        enabled: false,
    });
    const [receiverEmailNotification, setReceiverEmailNotification] = useState({
        email: {
            value: "",
            error: false,
        },
        enabled: false,
    });

    const customerSettings = context.user.customer.settings;

    useEffect(() => {
        setSenderEmail(notificationSettingsSender?.email);
        setReceiverEmail(notificationSettingsReceiver?.email);
    }, [orders]);

    const getAdditionalOptionsNotificationOption = () => {
        const settings = [
            {
                email: senderEmailNotification.email.value,
                onTender: senderEmailNotification.enabled,
            },
            {
                email: receiverEmailNotification.email.value,
                onTender: receiverEmailNotification.enabled,
            },
        ];
        if (settings.length > 0) {
            return [
                {
                    name: "notificationSettings",
                    value: settings,
                },
            ];
        } else {
            return null;
        }
    };

    const mergeNotificationSettings = (orders, onlyNotificationType) => {
        return orders.map((order) => {
            const notificationOptions = order.data?.additionalOptions?.find(
                (option) => option.name === "notificationSettings",
            )?.value;
            const existingSender = notificationOptions?.[0] ?? {
                email: "",
                onTender: false,
            };
            const existingReceiver = notificationOptions?.[1] ?? {
                email: "",
                onTender: false,
            };
            const formattedNotificationSettings =
                getAdditionalOptionsNotificationOption();

            const notificationSettings = formattedNotificationSettings.find(
                (setting) => setting.name === "notificationSettings",
            );

            // First element of array is the sender due to how notificationSettings was implemented in the normal order flow
            notificationSettings.value[0] =
                onlyNotificationType === "receiver"
                    ? existingSender
                    : notificationSettings.value[0];
            // Second element of array is the receiver
            notificationSettings.value[1] =
                onlyNotificationType === "sender"
                    ? existingReceiver
                    : notificationSettings.value[1];
            const mergedOptions = mergeAdditionalOptions(
                formattedNotificationSettings,
                "notificationSettings",
                order.data?.additionalOptions,
            );
            return {
                ...order,
                data: {
                    ...order.data,
                    additionalOptions: mergedOptions,
                },
            };
        });
    };

    /*
     * Sets the email of the sender or receiver based on emailData keys
     */
    const setEmail = ({
        email,
        updateFunc,
        customerSettingsKey,
        existingSettings,
        resetToDefaultEnable = false,
    }) => {
        const defaultValue =
            typeof customerSettings?.[customerSettingsKey] !== "undefined"
                ? customerSettings?.[customerSettingsKey]
                : null;

        const enabled =
            !!email &&
            ((resetToDefaultEnable ? defaultValue : null) ??
                existingSettings?.onTender ??
                false);
        updateFunc?.({
            email: { value: email ?? "", error: !validateEmail(email) },
            enabled,
        });
    };

    const setSenderEmail = (email, resetToDefaultEnable = false) => {
        setEmail({
            email,
            resetToDefaultEnable,
            updateFunc: setSenderEmailNotification,
            customerSettingsKey: "batchNotificationSender",
            existingSettings: notificationSettingsSender,
        });
    };

    const setReceiverEmail = (email, resetToDefaultEnable = false) => {
        setEmail({
            email,
            resetToDefaultEnable,
            updateFunc: setReceiverEmailNotification,
            customerSettingsKey: "batchNotificationReceiver",
            existingSettings: notificationSettingsReceiver,
        });
    };

    const areEmailsValid =
        (showSender && senderEmailNotification.enabled
            ? senderEmailNotification.email.error
            : false) === false &&
        (showReceiver && receiverEmailNotification.enabled
            ? receiverEmailNotification.email.error
            : false) === false;

    const emailNotificationForm = useMemo(
        () => (
            <EmailNotificationsForm
                senderEmailNotification={senderEmailNotification}
                showSender={showSender}
                setSenderEmailNotification={setSenderEmailNotification}
                receiverEmailNotification={receiverEmailNotification}
                showReceiver={showReceiver}
                setReceiverEmailNotification={setReceiverEmailNotification}
            />
        ),
        [
            senderEmailNotification,
            setSenderEmailNotification,
            receiverEmailNotification,
            setReceiverEmailNotification,
        ],
    );

    return {
        senderEmailNotification,
        receiverEmailNotification,
        emailNotificationForm,
        getAdditionalOptionsNotificationOption,
        areEmailsValid,
        mergeNotificationSettings,
        setSenderEmail,
        setReceiverEmail,
    };
};

export { useNotificationSettingsForm };
