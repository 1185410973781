import axios from "axios";
import { BATCH_ORDER } from "constants/batch/batchConstants";
import toast from "react-hot-toast";
import useOrdersAndSelectedUpdater from "./useOrdersAndSelectedUpdater";
import { useEffect, useState } from "react";
import useBatchStore from "views/Batch/store";
import { getJsonString } from "views/Batch/utils/getJsonString";

/**
 * @description Quotes the selected orders and updates the UI with the quotes.
 */
export const useQuoteOrders = () => {
    const { updateOrdersAndSelectedOrders } = useOrdersAndSelectedUpdater();
    const orders = useBatchStore((state) => state.orders);
    const selectedOrders = useBatchStore((state) => state.selectedOrders);

    const [ordersToQuote, setOrdersToQuote] = useState([]);
    const [proccessedQuotes, setProccessedQuotes] = useState([]);

    useEffect(() => {
        if (proccessedQuotes.length > 0) {
            const quoteToProcess =
                proccessedQuotes[proccessedQuotes.length - 1];

            const order = orders.find(
                (order) => order.id === quoteToProcess.id,
            );

            const selectedOrder = selectedOrders.find(
                (order) => order.id === quoteToProcess.id,
            );

            if (
                Object.keys(quoteToProcess).length > 0 &&
                (order?.state === BATCH_ORDER.State.Loading ||
                    selectedOrder?.state === BATCH_ORDER.State.Loading)
            ) {
                const order = ordersToQuote.find(
                    (order) => order.id === quoteToProcess.id,
                );

                if (order) {
                    if (quoteToProcess.quotes?.length > 0) {
                        const newOrder = {
                            ...order,
                            data: {
                                ...order.data,
                                selectedQuote: quoteToProcess.quotes[0],
                                quotations: quoteToProcess.quotes,
                                quotesExpireUTC: quoteToProcess.quotesExpireUTC,
                            },
                            quotesExpired: false,
                            state: quoteToProcess.state,
                            error: quoteToProcess.error,
                        };
                        updateOrdersAndSelectedOrders([newOrder]);
                    } else {
                        const newOrder = {
                            ...order,
                            state: quoteToProcess.state,
                            error: quoteToProcess.error,
                        };
                        updateOrdersAndSelectedOrders([newOrder]);
                    }
                }
            }
        }
    }, [proccessedQuotes]);

    useEffect(() => {
        if (ordersToQuote.length > 0 && Array.isArray(ordersToQuote)) {
            updateOrdersAndSelectedOrders(
                ordersToQuote.map((order) => {
                    return {
                        ...order,
                        state: BATCH_ORDER.State.Loading,
                    };
                }),
            );

            // Get the quotes for the selected orders and update the state of the selected order to success or error
            toast.loading("Hämtar priser...");
            quoteGivenOrders(ordersToQuote)
                .then(() => {
                    setOrdersToQuote([]);
                    toast.dismiss();
                    toast.success("Priser hämtade");
                })
                .catch(() => {
                    toast.dismiss();
                    toast.error(
                        "Något gick fel. Försök igen annars kontakta kundservice.",
                    );
                });
        }
    }, [ordersToQuote]);

    const checkForNewQuotes = ({ progressEvent }) => {
        try {
            const dataFromQuoteProgress = progressEvent.target.response;

            // Split the data into json objects and parse them to objects and check if the quote has already been proccessed
            // If the quote has not been proccessed, call the onNewQuote callback with the quote
            dataFromQuoteProgress.split("}{").map((json) => {
                const jsonString = getJsonString(json);

                const newQuote = JSON.parse(jsonString);

                if (
                    !proccessedQuotes.some((quoteId) => quoteId === newQuote.id)
                ) {
                    setProccessedQuotes([...proccessedQuotes, newQuote]);
                }
            });
        } catch (error) {
            progressEvent.target.abort();
        }
    };

    const onQuotingProgress = (progressEvent) => {
        checkForNewQuotes({
            progressEvent,
        });
    };

    const quoteGivenOrders = (ordersToQuote) => {
        return axios.post(
            process.env.REACT_APP_API_URL + "/api/batch/orders/quote",
            { orders: ordersToQuote },
            {
                onDownloadProgress: (progressEvent) =>
                    onQuotingProgress(progressEvent),
            },
        );
    };

    const quoteOrders = (ordersThatWillBeQuoted) => {
        setOrdersToQuote(ordersThatWillBeQuoted);
    };

    return { quoteOrders };
};
