import Integration from "constants/integrations";
import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import FortnoxIntegration from "./Fortnox";
import LogTradeIntegration from "./LogTrade";

function Component({ integrationId, ...props }) {
    const context = useContext(Context);
    const [integration, setIntegration] = useState(null);

    useEffect(() => {
        loadIntegration();
    }, []);

    async function loadIntegration() {
        setIntegration(
            await Api.getCustomerIntegration({
                customerId: context.user.customer.id,
                integrationId: integrationId,
            }),
        );
    }

    if (integration) {
        switch (integration.type) {
            case Integration.FORTNOX: {
                return (
                    <FortnoxIntegration integration={integration} {...props} />
                );
            }
            case Integration.LOG_TRADE: {
                return (
                    <LogTradeIntegration integration={integration} {...props} />
                );
            }
        }
    }
    return <div>Loading integration</div>;
}

export default Component;
