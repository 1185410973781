import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components/macro";

let bump = keyframes`
    0% {
		transform: scale3d(1,1,1);
    }

	15% {
		transform: scale3d(1.3,1.3,1);
	}

    100% {
		transform: scale3d(1,1,1);
    }
`;

let Container = styled.div`
    height: 100%;
    width: 100%;

    > .background {
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--menu-badge-color, #eaa);
        border-radius: 200px;
        box-sizing: border-box;
        overflow: hidden;
        transition: all 1s ease;
    }

    > .content {
        position: absolute;
        width: 100%;
        height: 100%;
        font-size: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
    }

    &.bump {
        > .background {
            animation: ${bump} 1s ease;
        }
    }

    @media (max-width: 700px) {
        top: 6px;
        left: calc(50% + 6px);
    }
`;

function Component(props) {
    const [badge, setBadge] = useState();
    const [badgeClass, setBadgeClass] = useState("");

    useEffect(() => {
        if (badge && props.badge && props.badge !== badge) {
            setBadgeClass("bump");
        }
        setBadge(props.badge);
    }, [props.badge]);

    useEffect(() => {
        if (badgeClass) {
            setTimeout(() => {
                setBadgeClass("");
            }, 2000);
        }
    }, [badgeClass]);

    if (!badge) {
        return null;
    }

    return (
        <Container className={badgeClass}>
            <div className="background"></div>
            <div className="content">{badge}</div>
        </Container>
    );
}

export default Component;
