import Modal from "components/Modal";
import React, { useEffect, useMemo, useRef, useState } from "react";
import CompanyForm from "views/Drafts/Create/Route/CompanyForm";
import Packages from "../../Drafts/Create/Packages";
import ShipmentInformation from "../../Drafts/Create/Information";
import useSaveOrders from "hooks/batch/useSaveOrders";
import { useNotificationSettingsForm } from "../hooks/useNotificationSettingsForm";
import { mergeAdditionalOptions } from "../utils/mergeAdditionalOptions";

const EditSingleOrderModal = ({ setIsOpen, isOpen, order }) => {
    const [sender, setSender] = useState({});
    const [receiver, setReceiver] = useState({});
    const [packages, setPackages] = useState([]);
    const [loading, setLoading] = useState(false);
    const packagesRef = useRef();
    const senderRef = useRef();
    const receiverRef = useRef();
    const informationRef = useRef();
    const [information, setInformation] = useState({});

    const { saveOrders } = useSaveOrders();

    useEffect(() => {
        setInformation(order.data.information);
    }, [order]);

    // Using memo to stop this value from being recalculated on every render
    const ordersForNotificationEdit = useMemo(() => [order], [order]);
    const {
        setSenderEmail,
        setReceiverEmail,
        emailNotificationForm,
        getAdditionalOptionsNotificationOption,
        areEmailsValid,
    } = useNotificationSettingsForm(ordersForNotificationEdit, true, true);

    const updateSenderNotification = (sender) => {
        setSenderEmail(sender.contact?.email, true);
    };
    const updateReceiverNotification = (receiver) => {
        setReceiverEmail(receiver.contact?.email, true);
    };

    async function save() {
        if (!isValid()) {
            return;
        }

        const orderToUpdate = {
            ...order,
            data: {
                ...order.data,
                sender: sender,
                receiver: receiver,
                packages: packages,
                information: information,
            },
        };

        const notificationOptions = getAdditionalOptionsNotificationOption();
        orderToUpdate.data.additionalOptions = mergeAdditionalOptions(
            notificationOptions,
            "notificationSettings",
            orderToUpdate.data.additionalOptions,
        );

        setLoading(true);
        await saveOrders([orderToUpdate]);

        setLoading(false);
        setIsOpen(false);
    }

    const isValid = () => {
        return (
            senderRef.current.validate() &&
            receiverRef.current.validate() &&
            packagesRef.current.validate() &&
            informationRef.current.validate() &&
            areEmailsValid
        );
    };

    function requireEmail() {
        return sender?.countryCode !== "SE" || receiver?.countryCode !== "SE";
    }

    function onChangedPackages(packages) {
        setPackages(packages);
        const packageWithOwnGoodsType = packages.find(
            (pkg) => pkg.description !== "",
        );

        if (packageWithOwnGoodsType) {
            setInformation({
                ...information,
                description: packageWithOwnGoodsType.description
                    ? packageWithOwnGoodsType.description
                    : "",
            });
        }
    }

    function swapParties() {
        const currentSender = sender;
        const currentReceiver = receiver;
        updateSenderNotification(receiverRef.current.value());
        updateReceiverNotification(senderRef.current.value());
        senderRef.current.set(currentReceiver);
        receiverRef.current.set(currentSender);
    }

    return (
        <Modal
            title={"Ändra försändelse"}
            description={"Uppdatera din försändelse här"}
            maxWidth="max-w-7xl"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <div className="overflow-y-auto overflow-x-hidden max-h-4xl py-6 pr-6">
                <div className="flex gap-3 ">
                    <div>
                        <h2 className="py-3 text-lg font-semibold">
                            Avsändare
                        </h2>
                        <CompanyForm
                            onChange={setSender}
                            onSelect={(company) =>
                                updateSenderNotification(company)
                            }
                            ref={senderRef}
                            company={order.data.sender}
                            requireEmail={requireEmail()}
                            enableDifferentPickupAddress={true}
                        />
                    </div>
                    <div className="w-32 mt-80 px-1">
                        <button onClick={swapParties}>
                            <img
                                src="/images/icons/swap-arrow.svg"
                                alt="info"
                                className="cursor-pointer"
                            />
                        </button>
                    </div>
                    <div>
                        <h2 className="py-3 text-lg font-semibold">
                            Mottagare
                        </h2>
                        <CompanyForm
                            onChange={setReceiver}
                            onSelect={(company) =>
                                updateReceiverNotification(company)
                            }
                            ref={receiverRef}
                            // Spread the company object to maintain state immutability
                            company={{ ...order.data.receiver }}
                            enablePrivateCustomer={true}
                            enableDifferentPickupAddress={false}
                            requireEmail={requireEmail()}
                        />
                    </div>
                </div>
                <div>
                    <h2 className="py-3 text-lg font-semibold">
                        Bokningsbekräftelse
                    </h2>
                    <div className="border-1 rounded-sm p-4">
                        <div className="w-1/2">{emailNotificationForm}</div>
                    </div>
                </div>
                <div>
                    <h2 className="py-3 text-lg font-semibold">Kolli</h2>
                    <Packages
                        packages={order.data.packages}
                        onChange={onChangedPackages}
                        ref={packagesRef}
                        fromBatch={true}
                    />
                </div>
                <div>
                    <h2 className="py-3 text-lg font-semibold">Information</h2>
                    <ShipmentInformation
                        onChange={setInformation}
                        isBatchView={true}
                        ref={informationRef}
                        information={order.data.information}
                    />
                </div>
                <div className="flex justify-end mt-4">
                    <button
                        className="c-button--raised c-button"
                        onClick={() => {
                            save();
                        }}
                    >
                        {loading ? "Sparar..." : "Ersätt"}
                    </button>
                    <button
                        className="c-button c-button--ghost delete"
                        onClick={() => setIsOpen(false)}
                    >
                        Avbryt
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default EditSingleOrderModal;
