import { POSTNORD } from "constants/carriers/postNord";

// Prices have been taken from PostNord online price list for account customers 2024-05-02
export const calculateInsurancePrice = (value) => {
    if (value === 0) return 0;
    if (value <= 5000) return 26;
    if (value <= 10000) return 52;
    if (value <= 15000) return 65;
    if (value <= 25000) return 78;
    if (value <= 30000) return 91;
    if (value <= 35000) return 104;
    if (value <= 40000) return 117;
    if (value <= 50000) return 130;
    if (value <= 75000) return 140;
    if (value <= 100000) return 160;
    if (value <= 250000) return 200;
    if (value <= 500000) return 400;
    if (value <= 750000) return 601;
    if (value <= 1000000) return 801;
    if (value <= 1500000) return 1201;
    if (value <= 2000000) return 1602;
    return 0;
};

export const calculateMaximumInsuranceValue = (productCode) => {
    switch (productCode) {
        case POSTNORD.services.mypack_collect:
        case POSTNORD.services.postnord_parcel:
            return 130000; // 13000 Euros per documentation
        default:
            return 2000000;
    }
};

export const determineAdditionalServices = (productCode, packages) => {
    switch (productCode) {
        case POSTNORD.services.mypack_collect:
            return [
                POSTNORD.additional_services.climate_compensation,
                POSTNORD.additional_services.pre_notification,
                POSTNORD.additional_services.return_drop_off,
            ];
        case POSTNORD.services.postnord_parcel:
            let parcelAdditionalServices = [
                POSTNORD.additional_services.climate_compensation,
                POSTNORD.additional_services.pre_notification,
                POSTNORD.additional_services.delivery_notification,
                POSTNORD.additional_services.completion_notification,
                POSTNORD.additional_services.before_9,
                POSTNORD.additional_services.before_12,
                POSTNORD.additional_services.driver_notification,
                POSTNORD.additional_services.return_drop_off,
            ];

            if (packages.some((pkg) => pkg.weight > 20)) {
                parcelAdditionalServices = parcelAdditionalServices.filter(
                    (additionalService) =>
                        additionalService !==
                            POSTNORD.additional_services.before_9 &&
                        additionalService !==
                            POSTNORD.additional_services.before_12 &&
                        additionalService !==
                            POSTNORD.additional_services.return_drop_off,
                );
            }
            return parcelAdditionalServices;
        case POSTNORD.services.postnord_pallet:
            return [
                POSTNORD.additional_services.climate_compensation,
                POSTNORD.additional_services.pre_notification,
                POSTNORD.additional_services.delivery_notification,
                POSTNORD.additional_services.completion_notification,
                POSTNORD.additional_services.before_9,
                POSTNORD.additional_services.before_12,
                POSTNORD.additional_services.driver_notification,
                POSTNORD.additional_services.private_receiver_pallet,
            ];
        case POSTNORD.services.varubrev_1_klass:
            return [
                POSTNORD.additional_services.e_letter_notification,
                POSTNORD.additional_services.varubrev_return,
            ];
        case POSTNORD.services.varubrev_ekonomi:
            return [
                POSTNORD.additional_services.email_notification,
                POSTNORD.additional_services.delivery_notification,
                POSTNORD.additional_services.varubrev_return,
            ];
        default:
            return [];
    }
};

export const determineShippingOptions = (productCode, packages) => {
    const hasOverweightPackage = packages.some((pkg) => pkg.weight > 20);
    if (
        (productCode === POSTNORD.services.postnord_parcel &&
            !hasOverweightPackage) ||
        productCode === POSTNORD.services.postnord_pallet
    ) {
        return [POSTNORD.additional_services.delivery_without_receipt];
    }
    return [];
};
