import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import moment from "moment";
import { Link } from "react-router-dom";

import Api from "services/Api/Api";
import axios, { CancelToken } from "axios";
import Input from "components/OldInput";
import Loader from "components/Loader/Loader";
import Context from "context/Global";

let Container = styled.div`
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;

    > .title {
        font-size: 32px;
        color: #aaa;
        margin-bottom: 16px;
    }

    > .info {
        display: inline-flex;
        flex-direction: column;
        padding: 16px;
        border-radius: 5px;
        background: rgba(230, 230, 250, 1);
        max-width: 300px;

        > .title {
            font-weight: bold;
        }
    }

    > .text {
        margin-top: 16px;
        font-size: 1.5rem;
    }
`;

function Component(props) {
    const context = useContext(Context);

    useEffect(() => {}, []);

    return (
        <Container>
            <div className="title">DHL</div>
            <div className="text">
                Bokning av upphämtning med DHL görs över telefon. Ring oss på{" "}
                <a
                    href={`tel:${context.user.customer.brand.supportPhoneNumber}`}
                >
                    {context.user.customer.brand.supportPhoneNumber}
                </a>{" "}
                så hjälper vi dig.
            </div>
        </Container>
    );
}

export default Component;
