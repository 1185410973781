import React, { useContext, useEffect, useState } from "react";
import Table from "../../../../../components/Table";
import Pagination from "../../../../../components/Pagination";
import InputField from "components/OldInput/Text";
import InputDropdown from "components/OldInput/Dropdown/InputDropdown";
import Context from "../../../../../context/Global";
import { getFilteredContacts } from "../api";
import toast from "react-hot-toast";
import useReceiverPaysStore from "../receiverPaysStore";
import Api from "../../../../../services/Api/Api";
import ConfirmModal from "../../../../../components/ConfirmModal";
import InformationHover from "components/InformationHover";

const ReceiverPaysTable = ({ setRefreshContacts }) => {
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [receiverSearch, setReceiverSearch] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [receiverPaysToDelete, setReceiverPaysToDelete] = useState(null);
    const limitOptions = [{ title: 10 }, { title: 20 }, { title: 50 }];
    const context = useContext(Context);
    const customerId = context.user.customer.id;
    const shouldRefresh = useReceiverPaysStore((state) => state.shouldRefresh);

    useEffect(() => {
        getReceiverPaysData(customerId, receiverSearch, limit, page);
    }, [page, limit, shouldRefresh]);

    const getReceiverPaysData = async (
        customerId,
        receiverSearch,
        limit,
        page,
    ) => {
        setIsSearching(true);
        try {
            const filteredContacts = await getFilteredContacts(
                customerId,
                receiverSearch,
                limit,
                page,
            );
            if (!filteredContacts) {
                return;
            }
            setContacts(filteredContacts.contacts || []);
            setCount(filteredContacts.totalAmount || 0);
        } catch (error) {
            toast.error(
                "Något gick fel när vi försökte hämta dina kontakter. Försök igen senare.",
            );
        } finally {
            setIsSearching(false);
        }
    };
    const handleDeleteClick = (receiverPaysData) => {
        setReceiverPaysToDelete(receiverPaysData);
        setOpenConfirmationModal(true);
    };

    const handleDeleteConfirm = async () => {
        setOpenConfirmationModal(false);
        toast.loading("Raderar avtalsnummret");
        try {
            const deletedReceiverPays = await Api.deleteReceiverPays({
                customerId,
                receiverPaysToDelete: receiverPaysToDelete,
            });
            const updatedContacts = contacts.filter(
                (contact) =>
                    !(
                        contact.contactId === deletedReceiverPays.contactId &&
                        contact.accountNumber ===
                            deletedReceiverPays.accountNumber &&
                        contact.carrier === deletedReceiverPays.carrier
                    ),
            );
            setContacts(updatedContacts);
            setCount(count - 1);
            setRefreshContacts((prev) => !prev);
            toast.dismiss();
            toast.success(
                `Avtalsnummer ${deletedReceiverPays.carrier}: ${deletedReceiverPays.accountNumber} har raderats`,
            );
            setReceiverPaysToDelete(null);
        } catch (error) {
            toast.error(
                `Fel uppstod när avtalsnummer ${receiverPaysToDelete.carrier}: ${receiverPaysToDelete.accountNumber} för kund ${receiverPaysToDelete.name} skulle raderas. Försök igen eller kontakta kundtjänst.`,
            );
        }
    };

    const searchReceiverPays = () => {
        getReceiverPaysData(customerId, receiverSearch, limit, page);
    };

    return (
        <div>
            <div className="flex px-4 items-center mb-4 justify-between">
                <div className="flex items-center space-x-4">
                    <InputField
                        placeholder="Sök mottagare"
                        maxLength={50}
                        onChange={setReceiverSearch}
                        onClickSearch={searchReceiverPays}
                    />
                    <button
                        onClick={searchReceiverPays}
                        className="px-4 py-2 bg-buttonColor text-white rounded-md hover:bg-primary500 disabled:opacity-50"
                        disabled={!!isSearching}
                    >
                        Sök
                    </button>
                </div>

                <div className="flex items-center justify-end space-x-4">
                    <div className="text-neutral-800 text-sm font-extralight">
                        Visa antal per sida:
                    </div>
                    <InputDropdown
                        options={limitOptions}
                        default={limit}
                        onChange={setLimit}
                    />
                </div>
            </div>

            <Table id="table" className="packageTypes">
                <thead>
                    <tr>
                        <th>
                            <p className="font-bold text-sm">Mottagare</p>
                        </th>
                        <th>
                            <p className="font-bold text-sm">Transportör</p>
                        </th>
                        <th>
                            <div className="flex flex-row items-center gap-2">
                                <p className="font-bold text-sm">
                                    Avtalsnummer
                                </p>
                                <InformationHover>
                                    Avtalsnumret vi söker är det kundnummer din
                                    mottagare har hos transportören
                                </InformationHover>
                            </div>
                        </th>
                        <th>
                            <p className="font-bold text-sm">Registrerad</p>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {contacts.map((row, index) => (
                        <tr key={index}>
                            <td>{row.name}</td>
                            <td>{row.carrier}</td>
                            <td>{row.accountNumber}</td>
                            <td>{row.addedAt}</td>
                            <td>
                                <div className="flex flex-row-reverse gap-4">
                                    <div
                                        className="cursor-pointer"
                                        onClick={() => handleDeleteClick(row)}
                                    >
                                        <img
                                            src="/images/icons/trash-can.svg"
                                            alt="delete"
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {!contacts.length && !isSearching && (
                <div className="flex justify-center items-center h-32">
                    <p className="text-xl">Inga mottagare registrerade</p>
                </div>
            )}
            {!!contacts.length && (
                <Pagination
                    setPage={setPage}
                    page={page}
                    maxPage={
                        Math.ceil(count / limit) > 0
                            ? Math.ceil(count / limit)
                            : 1
                    }
                />
            )}
            <ConfirmModal
                isOpen={openConfirmationModal}
                setIsOpen={setOpenConfirmationModal}
                onConfirm={handleDeleteConfirm}
                title={"Bekräfta åtgärd"}
                description={`Är du säker på att du vill ta bort valt avtalsnummer?`}
            />
        </div>
    );
};

export default ReceiverPaysTable;
