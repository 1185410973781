import React, { useContext, useEffect, useState } from "react";
import SearchableDropdown from "../../../../../components/Input/SearchableDropdown";
import InputText from "components/Input/Text";
import Api from "../../../../../services/Api/Api";
import { CARRIER_CONSTS } from "../../../../../constants/carriers/const";
import Context from "../../../../../context/Global";
import toast from "react-hot-toast";
import moment from "moment";
import { getAllContacts, searchContacts, sortContactsByName } from "../api";
import useReceiverPaysStore from "../receiverPaysStore";
import InformationHover from "components/InformationHover";

const ReceiverPaysInput = ({ refreshContacts }) => {
    const [saving, setSaving] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);
    const [carrier, setCarrier] = useState(null);
    const [accountNumber, setAccountNumber] = useState("");
    const [contacts, setContacts] = useState([]);
    const [contactsSearchTerm, setContactsSearchTerm] = useState("");
    const context = useContext(Context);
    const customerId = context.user.customer.id;
    const refreshTableData = useReceiverPaysStore(
        (state) => state.refreshTableData,
    );

    const buttonDisabled =
        !selectedContact || !carrier || !accountNumber || saving;

    useEffect(() => {
        try {
            getAllContacts(customerId).then((contacts) => {
                setContacts(contacts);
            });
        } catch (error) {
            toast.error(
                "Något gick fel när vi försökte hämta dina kontakter. Försök igen senare.",
            );
        }
    }, [refreshContacts]);

    useEffect(() => {
        if (contactsSearchTerm.length !== 0) {
            try {
                const ids = new Set(contacts.map((contact) => contact.id));
                searchContacts(customerId, contactsSearchTerm).then(
                    (searchedContacts) => {
                        const mergedContacts = searchedContacts.filter(
                            (contact) => !ids.has(contact.id),
                        );
                        const sortedMergedContacts = sortContactsByName([
                            ...contacts,
                            ...mergedContacts,
                        ]);

                        setContacts(sortedMergedContacts);
                    },
                );
            } catch (error) {
                toast.error(
                    "Något gick fel när vi försökte hämta dina kontakter. Försök igen senare.",
                );
            }
        }
    }, [contactsSearchTerm]);

    const handleContactChange = (selectedOption) => {
        setSelectedContact(selectedOption);
    };

    const handleCarrierChange = (selectedOption) => {
        setCarrier(selectedOption);
    };

    const saveReceiverPaysSettings = async () => {
        const contactToSave = contacts.find((c) => c.id === selectedContact);
        const {
            id,
            name,
            customerId,
            company,
            clientNumber,
            receiverPays,
            ...data
        } = contactToSave;

        if (
            receiverPays?.find(
                (receiverPay) =>
                    receiverPay.carrier === carrier &&
                    receiverPay.accountNumber === accountNumber,
            )
        ) {
            toast.error("Mottagaren har redan detta avtalsnummer registrerat.");
            return;
        }

        const updatedReceiverPays = {
            receiverPays: receiverPays
                ? [
                      ...receiverPays,
                      {
                          carrier: carrier,
                          accountNumber: accountNumber,
                          addedAt: moment().format("YYYY-MM-DD"),
                      },
                  ]
                : [
                      {
                          carrier: carrier,
                          accountNumber: accountNumber,
                          addedAt: moment().format("YYYY-MM-DD"),
                      },
                  ],
        };

        setSaving(true);
        try {
            await Api.updateContact({
                contactId: id,
                name: name,
                data: { ...data, ...updatedReceiverPays },
            });
            contactToSave.receiverPays = updatedReceiverPays.receiverPays;
            toast.success("Avtalsnummret sparades");
            resetFormFields();
            refreshTableData();
        } catch (error) {
            toast.error(
                "Något gick fel vid registrering av mottagarfrakt. Försök igen senare.",
            );
            setSaving(false);
        }
    };

    function resetFormFields() {
        setSaving(false);
        setAccountNumber("");
        setCarrier(null);
        setSelectedContact(null);
    }

    return (
        <div>
            <div className="mb-4 mt-4">
                <SearchableDropdown
                    options={contacts.map((contact) => {
                        return {
                            value: contact.id,
                            label:
                                contact.company?.length > 0
                                    ? contact.company
                                    : contact.name,
                        };
                    })}
                    placeholder="Välj mottagare"
                    onChange={handleContactChange}
                    title={"Välj mottagare"}
                    required={true}
                    value={selectedContact}
                    searchViaApi={setContactsSearchTerm}
                />
            </div>
            <div className="mt-4">
                <SearchableDropdown
                    options={CARRIER_CONSTS.carriers}
                    placeholder="Välj transportör"
                    onChange={handleCarrierChange}
                    title={"Välj transportör"}
                    required={true}
                    value={carrier}
                />
            </div>
            <div className="mt-4">
                <div className="text-sm mb-2 flex items-center">
                    <p className="font-bold">Avtalsnummer hos transportör</p>
                    <span className="text-red-500 ml-1">*</span>
                    <div className="ml-2 z-0">
                        <InformationHover>
                            Avtalsnumret vi söker är det kundnummer din
                            mottagare har hos transportören
                        </InformationHover>
                    </div>
                </div>
                <InputText
                    onChange={setAccountNumber}
                    value={accountNumber}
                    placeholder="Mottagarens avtalsnummer"
                />
            </div>
            <button
                onClick={saveReceiverPaysSettings}
                className={`mt-4 w-1/2 px-4 py-2 text-white rounded-md ${
                    buttonDisabled
                        ? "bg-gray-400 hover:bg-gray-400 cursor-not-allowed"
                        : "bg-buttonColor hover:bg-primary500"
                }`}
                disabled={buttonDisabled}
            >
                Spara
            </button>
        </div>
    );
};

export default ReceiverPaysInput;
