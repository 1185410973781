import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

let Container = styled.div`
    > .inputContainer {
        display: flex;
        align-items: center;

        > .option {
            + .option {
                margin-left: 1rem;
            }

            > input {
                cursor: pointer;
            }

            > label {
                margin-left: 0.5rem;
            }
        }

        > .helpText {
            padding-left: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            > .text {
                position: absolute;
                opacity: 0;
                display: flex;
                justify-content: center;
                text-align: center;
                background: #fff;
                padding: 0.4em;
                border-radius: 0.4em;
                box-shadow: 0px 1px 8px 5px rgba(0, 0, 0, 0.1);
                bottom: 100%;
                color: #555;
                font-weight: normal;
                transition: opacity 0.3s ease;
                pointer-events: none;
                width: 100vw;
                max-width: 20vw;
            }

            > .icon {
                width: 12px;
                height: 12px;
                padding: 4px;
                box-sizing: content-box;
            }

            &:hover {
                > .text {
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }
    }

    > .message {
        font-weight: bold;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
    }

    &.is-invalid {
        > .inputContainer {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }
        > .message {
            color: rgba(255, 0, 0, 0.6);
        }
    }

    &.is-disabled {
        > .inputContainer {
            background: rgb(235, 235, 228);
            border: 1px solid rgba(0, 0, 0, 0.05);

            > input {
                color: rgba(0, 0, 0, 0.5);
                background: transparent;
            }
        }
    }
`;

function Component(props, ref) {
    const [value, setValue] = useState();
    const [options, setOptions] = useState([]);
    const [message, setMessage] = useState();
    const [helpText, setHelpText] = useState();
    const [disabled, setDisabled] = useState();
    const [isInvalid, setIsInvalid] = useState(false);
    const guid = useRef();

    useEffect(() => {
        guid.current = uuidv4();
    }, []);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        setOptions(props.options);
    }, [props.options]);

    useEffect(() => {
        setHelpText(props.helpText);
    }, [props.helpText]);

    useEffect(() => {
        setMessage(props.message);
    }, [props.message]);

    useEffect(() => {
        setDisabled(props.disabled || false);
    }, [props.disabled]);

    // useEffect(() => {
    // 	setValue(props.value || false);
    // }, [props.value]);

    function onChange(event) {
        // setValue(event.target.value);
        // setTimeout(() => {
        // 	props?.onChange(event.target.value);
        // }, 0);
    }

    function validate() {}
    function clear() {}

    // useEffect(() => {
    // 	props.onChange(value)
    // }, [value]);

    useImperativeHandle(ref, () => {
        return {
            validate: validate,
            clear: clear,
            value: () => value,
        };
    });

    function getHelpTextElement(helpText) {
        if (!helpText) {
            return null;
        }
        return (
            <div className="helpText">
                <div className="text">{helpText}</div>
                <svg
                    className="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                    <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z" />
                </svg>
            </div>
        );
    }

    function changeValue(value) {
        setValue(value);
        setTimeout(() => {
            props.onChange(value);
        }, 0);
    }

    return (
        <Container
            className={
                (disabled ? " is-disabled" : "") +
                (isInvalid ? " is-invalid" : "")
            }
        >
            <div className="inputContainer">
                {options.map((option) => {
                    return (
                        <div className="option" key={option.value}>
                            <input
                                type="radio"
                                name={guid.current}
                                className="option"
                                value={option.value}
                                checked={option.value === value}
                                onClick={() => changeValue(option.value)}
                            />
                            <label htmlFor={option.value}>{option.title}</label>
                        </div>
                    );
                })}
                {helpText && getHelpTextElement(helpText)}
            </div>
            {message && <div className="message">{message}</div>}
        </Container>
    );
}

export default forwardRef(Component);
