export const parseDHLGoodsRows = (data) => {
    if (data.ext === "xml") {
        return {
            commodities: data.content[
                "DHL-SHIPMENT-IMPORT"
            ]?.SHIPMENT?.INV_ITEM.map((item) => {
                return {
                    description: item.INV_DESCRIPTION,
                    quantity: item.QUANTITY,
                    value: item.ITEM_VALUE,
                    currency: item.ITEM_VALUE_CURRENCY,
                    origin: item.COUNTRY_ORIGIN,
                    netWeight: item.NET_WEIGHT,
                    grossWeight: item.GROSS_WEIGHT,
                    vatPaid: item.VAT_PAID,
                    hsCode: item.COMMODITY,
                    refrenceNumber: item.REFERENCE_NUMBER,
                    refrenceType: item.REFERENCE_TYPE,
                };
            }),
        };
    } else if (data.ext === "csv") {
        const goodRows = data.content.map((row) => {
            const columns = row.split(",");
            return {
                description: columns[2],
                hsCode: columns[3],
                quantity: columns[4],
                value: columns[6],
                currency: columns[7],
                netWeight: columns[8],
                grossWeight: columns[9],
                origin: columns[10],
                refrenceType: columns[11],
                refrenceNumber: columns[12],
                vatPaid: columns[13],
            };
        });
        return {
            commodities: goodRows,
        };
    } else if (data.ext === "txt") {
        const goodRows = data.content.map((row) => {
            const columns = row.split("|");
            return {
                description: columns[2],
                hsCode: columns[3],
                quantity: columns[5],
                value: columns[6],
                currency: columns[7],
                netWeight: columns[8],
                grossWeight: columns[9],
                origin: columns[10],
                refrenceType: columns[11],
                refrenceNumber: columns[12],
                vatPaid: columns[13],
            };
        });
        return {
            commodities: goodRows,
        };
    }
};
