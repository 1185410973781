import axios from "axios";
import React, { useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import Summary from "../View/Summary";
import PickupPicker from "./PickupPicker";

let Container = styled.div`
    padding: 16px;
    box-sizing: border-box;
    max-width: 1000px;
    margin-bottom: 16px;
`;

let Card = styled.div`
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background: #fff;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    align-items: flex-start;
`;

function Component(props) {
    const [order, setOrder] = useState();

    useEffect(() => {
        const source = axios.CancelToken.source();

        loadOrder(source);

        return () => {
            source.cancel();
        };
    }, []);

    function loadOrder(source) {
        Api.getOrder({
            orderId: props.match.params.orderId,
            cancelToken: source.token,
        })
            .then(setOrder)
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    console.error("Failed to get drafts");
                    console.error(error);
                }
            });
    }

    if (order) {
        return (
            <Container>
                <Card>
                    <PickupPicker order={order} />
                </Card>
                <Summary order={order} />
            </Container>
        );
    }
    return null;
}

export default Component;
