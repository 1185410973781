import Context from "context/Global";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    width: 100%;
    max-width: 1200px;

    > .buttons {
        width: 100%;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
    }

    > table {
        width: 100%;

        th,
        td {
            padding: 0.5rem 1rem;
        }

        tr:hover {
            td {
                background: #ddd;
                cursor: pointer;
            }
        }
    }
`;

function View({ history }) {
    const context = useContext(Context);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setMessages(await Api.getPortalMessages({}));
    }

    return (
        <Container>
            <div className="buttons">
                <Link
                    to="/admin/messages/create"
                    className="c-button c-button--raised"
                >
                    Nytt meddelande
                </Link>
                <Link
                    to="/admin/messages/templates"
                    className="c-button c-button--flat"
                >
                    Mallar
                </Link>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Titel</th>
                        <th>Startdatum</th>
                        <th>Slutdatum</th>
                    </tr>
                </thead>
                <tbody>
                    {messages.map((message) => {
                        return (
                            <tr
                                key={message.id}
                                onClick={() =>
                                    history.push(
                                        `/admin/messages/${message.id}`,
                                    )
                                }
                            >
                                <td>{message.title}</td>
                                <td>
                                    {!!message.start &&
                                        moment(message.start).format(
                                            "YYYY-MM-DD",
                                        )}
                                </td>
                                <td>
                                    {!!message.end &&
                                        moment(message.end).format(
                                            "YYYY-MM-DD",
                                        )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Container>
    );
}

export default withRouter(View);
