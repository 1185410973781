import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import styled from "styled-components";

let Container = styled.div`
    > .inputContainer {
        background: #fff;
        width: 100%;
        max-width: 180px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 40px;
        display: grid;
        grid-template: 1fr / 1fr 1fr 1fr;

        > .decrease,
        > .increase {
            text-align: center;
            cursor: pointer;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        > .decrease {
            border-right: 1px solid rgba(0, 0, 0, 0.1);
        }
        > .increase {
            border-left: 1px solid rgba(0, 0, 0, 0.1);
        }

        > input {
            padding: 4px;
            border: 0;
            outline: none;
            width: 60px;
            text-align: center;
            justify-self: center;
        }

        > .unit {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            width: 60px;
            min-width: 60px;
            color: rgba(0, 0, 0, 0.4);
        }
    }

    > .message {
        font-weight: bold;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
    }

    &.is-invalid {
        > .inputContainer {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }
        > .message {
            color: rgba(255, 0, 0, 0.6);
        }
    }

    &.is-disabled {
        > .inputContainer {
            background: rgb(235, 235, 228);
            border: 1px solid rgba(0, 0, 0, 0.05);

            > input {
                color: rgba(0, 0, 0, 0.5);
                background: transparent;
            }
        }
    }
`;

const defaultMessages = {
    min: "Value cannot be less than ${min}",
    max: "Value cannot be more than ${max}",
};

function Component({ autoValidate, ...props }, ref) {
    const [value, setValue] = useState(props.value || props.min || 0);
    const [unit, setUnit] = useState();
    const [message, setMessage] = useState("");
    const [disabled, setDisabled] = useState();
    const [isInvalid, setIsInvalid] = useState(false);
    const [isInteger, setIsInteger] = useState();
    const messages = {
        ...defaultMessages,
        ...props.messages,
    };

    useEffect(() => {
        setUnit(props.unit);
    }, [props.unit]);

    useEffect(() => {
        setDisabled(props.disabled || false);
    }, [props.disabled]);

    useEffect(() => {
        setIsInteger(props.isInteger || false);
    }, [props.isInteger]);

    useEffect(() => {
        setValue(props.value || props.min || 0);
    }, [props.value]);

    function onKeyUp() {}

    function onChange(event) {
        setValue(parseFloat(event.target.value || 0));

        if (props?.onChange) {
            props.onChange(parseFloat(event.target.value || 0));
        }
    }

    useEffect(() => {
        if (autoValidate) {
            validate();
        }
    }, [value]);

    function validate() {
        let message = "";
        if (value !== undefined) {
            if (parseFloat(value) < props.min) {
                message = messages.min.replace("${min}", props.min);
            }
            if (parseFloat(value) > props.max) {
                message = messages.max.replace("${max}", props.max);
            }
        }

        setMessage(message);
        return !message;
    }
    function clear() {}
    function getValue() {
        return value;
    }
    function set(value) {
        setValue(value);
        setTimeout(() => {
            props?.onChange(value);
        }, 0);
    }
    function decrease() {
        setValue((v) => {
            let newValue = v;
            if (props.min === undefined || newValue > props.min) {
                newValue = (v || 0) - 1;
            }
            props.onChange(newValue);
            return newValue;
        });
    }
    function increase() {
        setValue((v) => {
            let newValue = v;
            if (props.max === undefined || newValue < props.max) {
                newValue = (v || 0) + 1;
            }
            props.onChange(newValue);
            return newValue;
        });
    }

    useImperativeHandle(ref, () => {
        return {
            validate: validate,
            clear: clear,
            value: getValue,
            set: set,
        };
    });

    return (
        <Container
            className={
                (disabled ? " is-disabled" : "") +
                (isInvalid ? " is-invalid" : "") +
                " " +
                props.className
            }
        >
            <div className="inputContainer">
                <div className="decrease" onClick={decrease}>
                    -
                </div>
                <input
                    type="text"
                    value={value}
                    onKeyUp={onKeyUp}
                    onChange={onChange}
                    placeholder={props.placeholder}
                />
                <div className="increase" onClick={increase}>
                    +
                </div>
                {/* {unit &&
				<div className="unit">{unit}</div>
			} */}
            </div>
            {message && <div className="message">{message}</div>}
        </Container>
    );
}

export default forwardRef(Component);
