import Input from "components/OldInput";
import InputEmail from "components/Input/Email";
import InputPostalCode from "components/Input/PostalCode";
import { countryList } from "constants/countryList";
import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useEffect, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import toast from "react-hot-toast";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;

    > .search {
        width: 20%;
    }

    > .inputFields {
        padding: 16px 8px;
        display: flex;
        flex-direction: row;

        > form {
            width: 50%;

            padding: 0 8px;
        }
    }

    > .buttons {
        padding: 16px;
        display: flex;
        align-items: center;

        > .remove {
            color: rgba(255, 100, 100, 1);
            border-color: rgba(255, 100, 100, 1);

            &:hover {
                background-color: rgba(255, 200, 200, 1);
            }
        }
    }
`;

let Title = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.6);
`;

const validationMessages = {
    required: "Fältet är obligatoriskt",
};

function Component(props) {
    const context = useContext(Context);
    const [customer, setCustomer] = useState();
    const [contact, setContact] = useState();
    const [showStateCode, setShowStateCode] = useState(false);
    const [isPrivate, setIsPrivate] = useState(false);
    const [saving, setSaving] = useState(false);
    const customerRef = useRef();
    const contactRef = useRef();
    const [requireEmail, setRequireEmail] = useState(true);
    const contactId = props.match.params.contactId;

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (contact) {
            setIsPrivate(contact.private);
        }
    }, [contact]);

    useEffect(() => {
        if (customer?.countryCode === "SE") {
            setRequireEmail(false);
        } else {
            setRequireEmail(true);
        }
    }, [customer]);

    async function loadData() {
        let customer = await Api.getContact({
            contactId: props.match.params.contactId,
        });
        setCustomer(customer);
        setContact(customer.contact);
    }

    const getContactReceiverPays = async () => {
        try {
            return await Api.getReceiverPays(contactId);
        } catch (error) {
            toast.error("Fel uppstod när mottagarfraktuppgifter skulle hämtas");
        }
    };

    async function save() {
        if (!customerRef.current.validate() || !contactRef.current.validate()) {
            return;
        }
        setSaving(true);
        let data = {
            countryCode: customer.countryCode,
            postalCode: customer.postalCode,
            postalTown: customer.postalTown,
            stateCode: showStateCode ? customer.stateCode : null,
            addressLine1: customer.addressLine1,
            addressLine2: customer.addressLine2,
            addressLine3: customer.addressLine3,
            dhlPalletExchangeNumber: customer.dhlPalletExchangeNumber,
            contact: {
                company: isPrivate ? null : contact.company,
                clientNumber: contact.clientNumber,
                name: contact.name,
                phoneNumber: contact.phoneNumber,
                email: contact.email,
                vatNumber: isPrivate ? null : contact.vatNumber,
                private: contact.private,
            },
        };
        const contactReceiverPays = await getContactReceiverPays();
        try {
            await Api.updateContact({
                contactId,
                name: data.contact.company || data.contact.name,
                data: {
                    ...data,
                    receiverPays: contactReceiverPays || null,
                },
            });
            toast.success("Kontakten har uppdaterats");
        } catch (error) {
            toast.error("Oväntat fel uppstod när kontakten skulle uppdateras");
        }

        props.history.push("/contacts");
    }

    async function remove() {
        try {
            await Api.deleteContact({
                contactId,
            });
            toast.success("Kontakten har tagits bort");
            props.history.push("/contacts");
        } catch (error) {
            toast.error("Oväntat fel uppstod när kontakten skulle tas bort");
        }
    }

    if (!customer || !contact) {
        return null;
    }

    return (
        <Container>
            <div className="inputFields">
                <form className="section" autoComplete="off">
                    <Input
                        ref={customerRef}
                        messages={validationMessages}
                        onChange={setCustomer}
                        type="list"
                        object={{
                            countryCode: {
                                title: "Land eller territorium",
                                type: "dropdown",
                                autoComplete: "chrome-off",
                                options: countryList,
                            },
                            postalCode: {
                                type: InputPostalCode,
                                title: "Postnummer",
                                autoComplete: "chrome-off",
                                required: true,
                                countryCode: customer
                                    ? customer.countryCode
                                    : "SE",
                                onPostalTownSelected: (item) => {
                                    customerRef.current.set({
                                        postalTown: item.value.postalTown,
                                        stateCode: item.value.stateCode,
                                    });
                                    setCustomer({
                                        ...customer,
                                        postalTown: item.value.postalTown,
                                        stateCode: item.value.stateCode,
                                    });
                                },
                            },
                            postalTown: {
                                type: "text",
                                autoComplete: "chrome-off",
                                title: "Postort",
                                required: true,
                            },
                            stateCode: {
                                type: "text",
                                autoComplete: "chrome-off",
                                title: "Stat",
                                hidden: !showStateCode,
                            },
                            addressLine1: {
                                type: "text",
                                autoComplete: "chrome-off",
                                title: "Adress",
                                maxLength: 30,
                                required: true,
                            },
                            addressLine2: {
                                type: "text",
                                autoComplete: "chrome-off",
                                maxLength: 30,
                            },
                            addressLine3: {
                                type: "text",
                                autoComplete: "chrome-off",
                                maxLength: 30,
                            },
                            dhlPalletExchangeNumber: {
                                title: "DHL PÖS-nummer",
                                type: "text",
                                autoComplete: "chrome-off",
                                maxLength: 6,
                                hidden: !context.user.customer.settings
                                    .dhlPalletExchange,
                            },
                        }}
                        value={customer}
                    />
                </form>
                <form className="section" autoComplete="off">
                    <Input
                        ref={contactRef}
                        messages={validationMessages}
                        onChange={setContact}
                        type="list"
                        object={{
                            company: {
                                type: "text",
                                autoComplete: "chrome-off",
                                title: "Företag",
                                maxLength: 35,
                                required: !isPrivate,
                                hidden: isPrivate,
                            },
                            clientNumber: {
                                type: "text",
                                autoComplete: "chrome-off",
                                maxLength: 64,
                                title: "Kund-id",
                                required: false,
                            },
                            name: {
                                type: "text",
                                autoComplete: "chrome-off",
                                title: "Kontakt",
                                required: true,
                            },
                            phoneNumber: {
                                type: "text",
                                autoComplete: "chrome-off",
                                title: "Telefonnummer",
                                required: true,
                            },
                            email: {
                                type: InputEmail,
                                autoComplete: "chrome-off",
                                title: "E-postadress",
                                required: requireEmail,
                                messages: {
                                    required:
                                        "Fältet är obligatoriskt för utrikessändningar",
                                    invalid:
                                        "Det ifyllda värdet verkar inte vara en e-postadress",
                                },
                            },
                            vatNumber: {
                                type: "text",
                                autoComplete: "chrome-off",
                                title: "Tull-id",
                                helpText:
                                    "Momsnummer, VAT number, Tax number, etc. Rekommenderas vid transporter utanför EU.",
                                hidden: isPrivate,
                            },
                            private: {
                                type: "checkbox",
                                autoComplete: "chrome-off",
                                title: "Privat-kund",
                            },
                        }}
                        value={contact}
                    />
                </form>
            </div>
            <div className="buttons">
                <div className="c-button c-button--raised" onClick={save}>
                    Spara
                </div>
                <div
                    className="c-button c-button--ghost remove"
                    onClick={remove}
                >
                    Ta bort
                </div>
            </div>
        </Container>
    );
}

export default Component;
