import Modal from "components/Modal";
import { useState } from "react";
import { useNotificationSettingsForm } from "../hooks/useNotificationSettingsForm";
import useSaveOrders from "hooks/batch/useSaveOrders";
import toast from "react-hot-toast";
import { mergeAdditionalOptions } from "../utils/mergeAdditionalOptions";

const EditNotificationSettingsModal = ({
    ordersToEdit,
    setOrdersToEdit,
    notificationType,
}) => {
    const [loading, setLoading] = useState(false);
    const handleSetIsOpen = (val) => {
        if (!val) {
            setOrdersToEdit(null);
        }
    };

    const { saveOrders } = useSaveOrders();

    const { emailNotificationForm, areEmailsValid, mergeNotificationSettings } =
        useNotificationSettingsForm(
            ordersToEdit,
            notificationType ? notificationType === "sender" : true,
            notificationType ? notificationType === "receiver" : true,
        );

    const handleSaveOrders = async () => {
        setLoading(true);
        try {
            const ordersToUpdate = mergeNotificationSettings(
                ordersToEdit.map((order) => ({
                    ...order,
                    data: {
                        ...order.data,
                    },
                })),
                notificationType,
            );

            await saveOrders(ordersToUpdate);
            setOrdersToEdit(null);
        } catch (error) {
            toast.error("Något gick fel. Försök igen senare.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title={"Bokningsbekräftelse"}
            isOpen={!!ordersToEdit && ordersToEdit.length > 0}
            setIsOpen={handleSetIsOpen}
        >
            <div className="px-12">
                <div className="pb-10 pt-4">{emailNotificationForm}</div>

                <div className="flex justify-center">
                    <button
                        className="c-button--raised c-button"
                        onClick={() => {
                            handleSaveOrders();
                        }}
                        disabled={loading || !areEmailsValid}
                    >
                        Bekräfta
                    </button>
                    <button
                        className="c-button c-button--ghost delete"
                        onClick={() => handleSetIsOpen(false)}
                    >
                        Avbryt
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default EditNotificationSettingsModal;
