export default ({ amount, unit = "SEK", decimals = 2 }) => {
    amount = parseFloat(amount).toFixed(decimals);
    return (
        (typeof amount === "string"
            ? amount.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")
            : 0) +
        " " +
        unit
    );
};
