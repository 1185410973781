import Axios from "axios";
import Input from "components/OldInput";
import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import { handleDownloadS3 } from "../../../../utils/s3";

let Container = styled.div`
    padding: 1rem;

    > .title {
        font-weight: bold;
        font-size: 2rem;
    }
    > .subTitle {
        font-weight: bold;
        margin-top: 1rem;
    }

    > .settings {
        display: inline-block;
    }
    > . > .list {
        display: grid;
        grid-template: auto / repeat(auto-fit, minmax(200px, 300px));
        grid-gap: 1rem;

        > .template {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            padding: 0.5rem 1rem;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.05);
            &:hover {
                background: rgba(0, 0, 0, 0.1);
            }

            > .name {
                color: #000;
                text-decoration: none;
            }

            > .remove {
                width: 16px;
                height: 16px;
                min-width: 16px;
                min-height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #aaa;
                cursor: pointer;

                &:hover {
                    color: #555;
                }

                > svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    > .buttons {
        padding: 1rem 0;
    }
`;

function Component({ ...props }) {
    const context = useContext(Context);
    const [integration, setIntegration] = useState(props.integration);

    const accessTokenRef = useRef();

    async function removeIntegration() {
        await Api.removeCustomerIntegration({
            customerId: context.user.customer.id,
            integrationId: integration.id,
        });
        props.history.push("/addons");
    }

    async function copyClipboard(e) {
        // @ts-ignore
        const accessToken = accessTokenRef?.current?.outerText;
        await navigator.clipboard.writeText(accessToken);
        alert("Kopierad till urklipp");
    }

    return (
        <Container>
            <div className="title">Joomla-Virtuemart - integration</div>
            <div className="subTitle">
                <h4>Inställingar</h4>
            </div>
            <div className="mt-5">
                Förutsättning
                <ul className="pl-5 pt-3">
                    <ol className="styled-list pl-5 space-y-2">
                        <li> 1. Joomla version 3 eller sernare </li>
                        <li> 2. PHP version 7.4 eller senare </li>
                        <li>
                            {" "}
                            3. Virtuemart E-Commerce Extension installerat på
                            ditt Joomla konto{" "}
                        </li>
                    </ol>
                </ul>
            </div>
            <div className="content mt-5 mb-10">
                <p className="mt-3">
                    Steg för att installera och konfigurera Joomla - Virtuemart
                    integrationen:
                </p>{" "}
                <p>
                    <ul className="pl-5 steps mt-3 mb-3 space-y-2">
                        <ol className="styled-list pl-5 space-y-2">
                            <li>
                                1. Ladda ner installationspaketet:{" "}
                                <a
                                    className="underline cursor-pointer text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                    onClick={() =>
                                        handleDownloadS3({
                                            url: `${
                                                process.env.REACT_APP_API_URL
                                            }/api/s3/integration?objectKey=${encodeURIComponent(
                                                "joomla/zendr-joomla.zip",
                                            )}`,
                                            desiredFileName: "zendr-joomla.zip",
                                        })
                                    }
                                >
                                    Joomla Extension Download
                                </a>
                            </li>
                            <li>
                                2. Navigera till Joomla och installera det som
                                tillägg:
                                <ol className="sub-steps pl-5">
                                    <li>
                                        Gå till "Extensions > Manage > Install"
                                        i din Joomla's adminpanel.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                3. Aktivera Zendr-tillägget efter
                                installationen:
                                <ol className="sub-steps pl-5">
                                    <li>
                                        Navigera till "Components > VirtueMart >
                                        Payment Methods".
                                    </li>
                                    <li>
                                        Välj "New" för att skapa en ny
                                        betalningsmetod.
                                    </li>
                                    <li>Välj Zendr som betalningsmetod.</li>
                                    <li>
                                        Tryck "Save and Close" och verifiera att
                                        den nya betalningsmetoden är listad.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                4. Konfigurera Zendr-tillägget:
                                <ol className="sub-steps pl-5">
                                    <li>
                                        Gå till "Components > Zendr >
                                        Configuration".
                                    </li>
                                    <li>
                                        Kopiera "Åtkomsttoken" som visas
                                        nedanför.
                                    </li>
                                    <li>
                                        Klistra in token i fältet "Access
                                        Token".
                                    </li>
                                    <li>
                                        Ange standardvärden för paketvikt och
                                        beskrivning om så önskas.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                5. Hantera beställningar i Zendr-portalen:
                                <ol className="sub-steps pl-5">
                                    <li>
                                        Efter att en beställning har lagts
                                        kommer den att skickas till
                                        Zendr-portalen.
                                    </li>
                                    <li>
                                        Gå till fliken "Importerade
                                        försändelser" för att hantera
                                        beställningen.
                                    </li>
                                    <li>
                                        Härifrån kan du skapa nya försändelser
                                        efter behov.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </ul>
                    <hr></hr>
                    <div className="mt-10">
                        Åtkomsttoken:
                        <code ref={accessTokenRef} className="p-3 ml-3">
                            {" "}
                            {integration?.data.accessToken}{" "}
                        </code>
                        <button
                            onClick={copyClipboard}
                            className="c-button c-button--raised ml-3"
                        >
                            {" "}
                            Kopiera{" "}
                        </button>
                    </div>
                </p>
            </div>
            <hr></hr>
            <div className="settings">
                <div className="buttons">
                    <button
                        onClick={removeIntegration}
                        className="c-button c-button--raised mt-5"
                    >
                        Ta bort integrationen
                    </button>
                </div>
            </div>
        </Container>
    );
}

export default Component;
