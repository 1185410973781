import React from "react";

export const ProductButton = ({ children, onClick, disabled }) => {
    return (
        <button
            onClick={!disabled ? onClick : undefined}
            className={`bg-white p-3 rounded-md flex items-center gap-3 max-w-sm w-full ${
                disabled ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={disabled}
        >
            {children}
            <div className="ml-auto">
                <img
                    alt="Chevron right"
                    src="/images/icons/chevron-right.svg"
                />
            </div>
        </button>
    );
};
