import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    padding: 1rem;

    > .title {
        font-weight: bold;
        margin-top: 1rem;
    }

    > .list {
        display: grid;
        grid-template: auto / repeat(4, minmax(200px, 1fr));
        grid-gap: 1rem;

        > .template {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            padding: 0.2rem 1rem;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.05);
            &:hover {
                background: rgba(0, 0, 0, 0.1);
            }

            > .name {
                color: #000;
                text-decoration: none;
            }

            > .remove {
                width: 16px;
                height: 16px;
                min-width: 16px;
                min-height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #aaa;
                cursor: pointer;

                &:hover {
                    color: #555;
                }

                > svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    > .buttons {
        padding: 1rem 0;
    }
`;

function Component({ ...props }) {
    const context = useContext(Context);
    const [integration, setIntegration] = useState(props.integration);

    async function removeIntegration() {
        if (!confirm("Är du säker på att du vill ta bort integrationen?")) {
            return;
        }
        await Api.removeCustomerIntegration({
            customerId: context.user.customer.id,
            integrationId: integration.id,
        });
        props.history.push("/addons");
    }

    async function removeTemplate(template) {
        if (!integration.data.templates) {
            integration.data.templates = [];
        }

        await Api.updateIntegration({
            integrationId: integration.id,
            data: {
                templates: integration.data.templates.filter((t) => {
                    return t.productCode !== template.productCode;
                }),
            },
        });
        context.setUser(await Api.getCurrentUser());
        setIntegration(
            (
                await Api.getCustomerIntegrations({
                    customerId: context.user.customer.id,
                })
            ).find((i) => {
                return i.id === integration.id;
            }),
        );
    }

    return (
        <Container>
            <table>
                <tbody>
                    <tr>
                        <th className="pr-2 align-top pt-2">Integration</th>
                        <td className="pt-2">Visma Administration</td>
                    </tr>
                    <tr>
                        <th className="pr-2 align-top pt-2">
                            Integrationsnyckel
                        </th>
                        <td className="pt-2">{integration.identifier}</td>
                    </tr>
                    <tr>
                        <th className="pr-2 align-top pt-2">
                            Installationsfil
                        </th>
                        <td className="pt-2">
                            <a
                                target="_blank"
                                className="text-blue-700"
                                href="https://github.com/MKM-Group-AB/zendr-electron-release/releases/latest"
                            >
                                Zendr-Integration-Setup.exe
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className="max-w-2xl pt-2">
                Efter att ni har installerat och initierat programvaran på er
                dator, vänligen navigera till programmets inställningar. Inom
                detta avsnitt specificerar ni bland annat vilket företag i Visma
                som ni önskar koppla samman med, samt om det krävs en signatur
                och lösenord vid inloggning. Vidare kommer ni att ombedas att
                mata in en integrationsnyckel. Vänligen använd den ovanstående
                nyckeln genom att kopiera och infoga den i det avsedda fältet.
            </p>
            <div className="buttons">
                <button
                    className="c-button c-button--raised"
                    onClick={removeIntegration}
                >
                    Ta bort
                </button>
            </div>
        </Container>
    );
}

export default Component;
