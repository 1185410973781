function getProductName(code) {
    switch (code) {
        case "07": {
            return "UPS Express 12.00";
        }
        case "08": {
            return "UPS Ekonomi";
        }
        case "11": {
            return "UPS Ekonomi";
        }
        case "54": {
            return "UPS Express 09.00";
        }
        case "65": {
            return "UPS Express";
        }
        case "70": {
            return "UPS Utlämningställe";
        }
        case "71": {
            return "UPS Utlämningställe";
        }
        case "96": {
            return "UPS Ekonomi";
        }
        default: {
            return "UPS";
        }
    }
}

function getOriginalName(code) {
    switch (code) {
        case "07": {
            return "UPS Express";
        }
        case "08": {
            return "UPS Expedited";
        }
        case "11": {
            return "UPS Standard";
        }
        case "54": {
            return "UPS Express Plus";
        }
        case "65": {
            return "UPS Express Saver";
        }
        case "70": {
            return "UPS Access Point";
        }
        case "71": {
            return "UPS Access Point";
        }
        case "96": {
            return "UPS Worldwide Express Freight";
        }
        default: {
            return "UPS";
        }
    }
}

function getExpressFlag(code) {
    switch (code) {
        case "07": {
            return true;
        }
        case "08": {
            return false;
        }
        case "11": {
            return false;
        }
        case "54": {
            return true;
        }
        case "65": {
            return true;
        }
        case "70": {
            return false;
        }
        case "71": {
            return false;
        }
        case "96": {
            return false;
        }
        default: {
            return false;
        }
    }
}

function getDeliveryPointFlag(code) {
    switch (code) {
        case "07": {
            return false;
        }
        case "08": {
            return false;
        }
        case "11": {
            return false;
        }
        case "54": {
            return false;
        }
        case "65": {
            return false;
        }
        case "70": {
            return true;
        }
        case "71": {
            return false;
        }
        case "96": {
            return false;
        }
        default: {
            return false;
        }
    }
}

function getUPSDisplayData(code) {
    return {
        originalName: getOriginalName(code),
        name: getProductName(code),
        isExpress: getExpressFlag(code),
        isDeliveryPoint: getDeliveryPointFlag(code),
        tags: [],
    };
}

export const UPS_ACCESS_POINT = "70";

export { getUPSDisplayData, getProductName };
