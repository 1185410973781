import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

let Container = styled.div`
    box-sizing: border-box;
    outline: none;
    min-height: 40px;

    > .inputContainer {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        box-sizing: border-box;
        cursor: pointer;
        background: #fff;

        > .value {
            padding: 0 8px;
            min-width: 120px;
            min-height: 24px;
        }

        > .searchInput {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: transparent;
            border: 0;
            padding: 0 0.5rem;
        }

        > .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            padding: 0 8px;
            box-sizing: content-box;

            > svg {
                width: 100%;
                height: 100%;
            }
        }

        > .options {
            position: absolute;
            left: 0;
            bottom: -5px;
            min-width: 100%;
            z-index: 100;

            .overlay {
                position: fixed;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: 1;
                cursor: default;
            }

            > .list {
                position: absolute;
                background: #fff;
                min-width: 100%;
                box-shadow: 0 1px 2px 1px rgb(0 0 0 / 10%);
                z-index: 5;
                border-radius: 4px;
                max-height: 400px;
                overflow-y: auto;
                right: 0;
                box-sizing: border-box;

                > .divider {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                }

                > .option {
                    border-bottom: 1px solid #fafafa;
                    white-space: nowrap;
                    cursor: pointer;
                    padding: 4px 8px;
                    z-index: 2;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &.is-selected {
                        background-color: rgba(0, 0, 0, 0.025);
                    }

                    > .check {
                        color: green;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 16px;
                        height: 16px;

                        > svg {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.05);
                    }
                }
            }
        }
    }

    > .message {
        font-weight: bold;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
    }

    &.is-invalid {
        > .inputContainer {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }

        > .message {
            color: rgba(255, 0, 0, 0.6);
        }
    }

    &.is-disabled {
        > .inputContainer {
            pointer-events: none;
            background: rgb(235, 235, 228);
            border: 1px solid rgba(0, 0, 0, 0.05);
            color: rgba(0, 0, 0, 0.5);

            > .icon {
                color: rgba(0, 0, 0, 0.5);
            }
        }
    }
`;

function getListStyle(el) {
    if (!el) {
        return;
    }
    var rect = el.getBoundingClientRect();

    const padding = 16;

    if (
        rect.left > padding &&
        rect.top > padding &&
        rect.right < window.innerWidth + padding &&
        rect.bottom < window.innerHeight + padding
    ) {
        return undefined;
    }

    let dx = 0;
    let dy = 0;

    if (rect.left < 0) {
        dx = rect.left + padding;
    } else if (rect.right > window.innerWidth) {
        dx = window.innerWidth - rect.right - padding;
    }

    if (rect.top < 0) {
        dy = rect.top + padding;
    } else if (rect.bottom > window.innerHeight) {
        dy = window.innerHeight - rect.bottom - padding;
    }

    return {
        transform: `translateY(${dy}px)`,
        right: dx ? 0 : undefined,
    };
}

function Component({ className, ...props }, ref) {
    const [value, setValue] = useState(props.value);
    const [displayValue, setDisplayValue] = useState("");
    const [options, setOptions] = useState([]);
    const [unit, setUnit] = useState();
    const [message, setMessage] = useState();
    const [disabled, setDisabled] = useState(props.disabled || false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [isInteger, setIsInteger] = useState();
    const [showList, setShowList] = useState(false);
    const [listStyle, setListStyle] = useState(undefined);
    const [validationMessage, setValidationMessage] = useState("");
    const [searchstring, setSearchstring] = useState("");
    const listRef = useRef();

    useEffect(() => {
        if (searchstring) {
            setDisplayValue("");
        }
        setDisplayValue(
            options.find((option) => {
                return checkIsSelected(option, value);
            })?.title || "",
        );
    }, [value, searchstring, options]);

    useEffect(() => {
        if (props.onChange) {
            props.onChange(props.value);
        }
    }, []);

    useEffect(() => {
        setOptions(props.options);
    }, [props.options]);

    useEffect(() => {
        setUnit(props.unit);
    }, [props.unit]);

    useEffect(() => {
        setDisabled(props.disabled || false);
    }, [props.disabled]);

    useEffect(() => {
        setIsInteger(props.isInteger || false);
    }, [props.isInteger]);

    useEffect(() => {
        if (showList) {
            setTimeout(() => {
                setListStyle(getListStyle(listRef.current));
            }, 0);
        } else {
            setListStyle(undefined);
        }
    }, [showList]);

    function onKeyUp() {}

    function toggleList() {
        setShowList((showList) => !showList);
    }

    function close() {
        setShowList(false);
    }

    function blur(ev) {
        setShowList(false);
        ev?.stopPropagation();
    }

    function focus() {
        setShowList(false);
    }

    function onChange(value) {
        setValue(value);
        if (props.onChange) {
            props.onChange(value);
        }
    }

    function filteredList() {
        if (searchstring) {
            return options.filter((option) => {
                return ("" + option.title.toLowerCase()).includes(
                    searchstring.toLowerCase(),
                );
            });
        }
        return options;
    }

    function set(value) {
        onChange(value);
    }

    function validate() {
        const valid = props.required && !!value?.length;
        if (!valid) {
            setIsInvalid(true);
            setValidationMessage("Ett alternativ måste väljas");
        } else {
            setIsInvalid(false);
            setValidationMessage("");
        }
        return valid;
    }

    useImperativeHandle(ref, () => {
        return {
            validate: validate,
            clear: () => {},
            value: () => value,
            set: set,
        };
    });

    function checkIsSelected(option, currentValue) {
        if (typeof currentValue === "object" && currentValue !== null) {
            if (typeof option.value === "object" && option.value !== null) {
                return currentValue.id === option.value.id;
            }
        } else {
            return option.value === currentValue;
        }
        return false;
    }

    return (
        <Container
            {...props}
            className={`${className} ${isInvalid ? " is-invalid" : ""} ${
                disabled ? " is-disabled" : ""
            }`}
        >
            <div className="inputContainer" onClick={toggleList}>
                <div className="value">
                    {props.searchable && showList ? "" : displayValue}
                </div>
                {!props.searchable && (
                    <div className="icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"
                            />
                        </svg>
                    </div>
                )}
                {props.searchable && (
                    <>
                        <div className="icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="currentColor"
                                    d="M23.111 20.058l-4.977-4.977c.965-1.52 1.523-3.322 1.523-5.251 0-5.42-4.409-9.83-9.829-9.83-5.42 0-9.828 4.41-9.828 9.83s4.408 9.83 9.829 9.83c1.834 0 3.552-.505 5.022-1.383l5.021 5.021c2.144 2.141 5.384-1.096 3.239-3.24zm-20.064-10.228c0-3.739 3.043-6.782 6.782-6.782s6.782 3.042 6.782 6.782-3.043 6.782-6.782 6.782-6.782-3.043-6.782-6.782zm2.01-1.764c1.984-4.599 8.664-4.066 9.922.749-2.534-2.974-6.993-3.294-9.922-.749z"
                                />
                            </svg>
                        </div>
                        <input
                            type="text"
                            className="searchInput"
                            onChange={(event) => {
                                event.stopPropagation();
                                setSearchstring(event.target.value);
                            }}
                        />
                    </>
                )}
                {!!(showList && filteredList() && filteredList().length) && (
                    <div className="options">
                        <div className="overlay" onClick={blur}></div>
                        <div ref={listRef} className="list" style={listStyle}>
                            {filteredList().map((option) => {
                                if (option.type === "divider") {
                                    return (
                                        <div className={`divider`}>
                                            {option.title}
                                        </div>
                                    );
                                }
                                return (
                                    <div
                                        key={
                                            option.identifier ||
                                            option.value ||
                                            option.title
                                        }
                                        className={`option ${
                                            option.value === value
                                                ? "is-selected"
                                                : ""
                                        }`}
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            onChange(option.value);
                                            blur();
                                        }}
                                    >
                                        {option.title}
                                        {checkIsSelected(option, value) && (
                                            <div className="check">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
            {validationMessage && (
                <div className="message">{validationMessage}</div>
            )}
        </Container>
    );
}

export default forwardRef(Component);
