import React, { useContext, useEffect } from "react";
import styled from "styled-components/macro";

import Loader from "components/Loader/Loader";
import Api from "services/Api/Api";

import Context from "context/Global";

let Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > .loader {
        width: 200px;
        height: 200px;
    }
`;

let Title = styled.div`
    font-size: 30px;
    margin-bottom: 16px;
`;
function View({ history }) {
    const context = useContext(Context);

    useEffect(() => {
        logoutUser();
    }, []);

    function logoutUser() {
        Api.logout().finally(() => {
            context.clearUserData();
            history.replace("/");
        });
    }

    return (
        <Container>
            <Title>Du loggas nu ut</Title>
            <div className="loader">
                <Loader />
            </div>
        </Container>
    );
}

export default View;
