import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PickupGrid from "./PickupGrid";

function Component() {
    const { t } = useTranslation();
    return (
        <div className="w-full flex flex-col p-6">
            <h1 className="text-slate-700 text-3xl mb-3">
                {t("user.pickups.title")}
            </h1>
            <span className="text-slate-500 mb-6">
                {t("user.pickups.subtitleP1")}{" "}
                <Link
                    to="/create"
                    className="text-red-500 font-medium hover:underline transition-all"
                >
                    {t("user.pickups.subtitleP2")}
                </Link>
                !
            </span>
            <PickupGrid />
        </div>
    );
}

export default Component;
