import React from "react";
import TrackingParties from "./TrackingParties";
import TrackingTimeline from "./Timeline/TrackingTimeline";
import TrackingDetails from "./TrackingDetails";
import { useTranslation } from "react-i18next";

const TrackingWrapper = ({ shipment, trackingNo }) => {
    const { t } = useTranslation();

    return (
        <div className="my-10 flex w-full max-w-6xl flex-col p-3 sm:my-3 sm:p-8 ">
            <div className="flex justify-center sm:justify-start">
                <img
                    className={"w-52"}
                    src={"/images/logos/zendr-logo-hallon.svg"}
                    alt={"Zendr Logo"}
                />
            </div>

            <div className="mt-4 flex justify-center sm:justify-start">
                <h1 className="text-xl font-bold text-black md:text-2xl">
                    {t("tracking.trackingNumber")} {trackingNo}
                </h1>
            </div>

            {shipment.hasError && (
                <div className="mt-3 rounded-md bg-red-300 p-4">
                    {t("tracking.issues")} {shipment.messages[0]?.code}
                </div>
            )}

            {shipment.status === "CANCELLED" && (
                <div className="mt-3 rounded-md bg-blue-200 p-4">
                    {t("tracking.cancelled")}
                </div>
            )}
            <TrackingParties
                carrier={shipment.service}
                sender={shipment.route.from}
                recipient={shipment.route.to}
                customer={shipment?.customer}
            />
            <TrackingTimeline
                hasError={shipment.hasError}
                state={shipment.state}
            />
            <TrackingDetails events={shipment.events} />
        </div>
    );
};

export default TrackingWrapper;
