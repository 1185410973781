function calculateSubscriptionEndDate(activatedAt, billingType) {
    const activatedAtDate = new Date(activatedAt);
    const currentDate = new Date();
    let month = currentDate.getMonth();
    let year = currentDate.getFullYear();

    if (billingType === "monthly") {
        if (activatedAtDate.getDate() <= currentDate.getDate()) {
            month++;
            if (month > 11) {
                month = 0;
                year++;
            }
        }
        return new Date(year, month, activatedAtDate.getDate()).toISOString();
    } else {
        return new Date(
            year + 1,
            month,
            activatedAtDate.getDate(),
        ).toISOString();
    }
}

export { calculateSubscriptionEndDate };
