import React, { Component } from "react";
import styled from "styled-components";

let Container = styled.div`
    width: 100%;

    > select {
        width: 100%;
        padding: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        box-sizing: border-box;
        cursor: pointer;
    }

    &.is-invalid select {
        border: 1px solid rgba(255, 0, 0, 0.2);
    }

    &.is-disabled {
        > select {
            border: 1px solid rgba(0, 0, 0, 0.05);
            background: rgb(235, 235, 228);
            pointer-events: none;
        }
    }

    > .note {
        padding: 4px;
        font-size: 0.8rem;
    }

    > .message {
        font-weight: bold;
        font-size: 12px;
        color: rgba(255, 0, 0, 0.6);
    }
`;

class InputDropdown extends Component {
    constructor(props) {
        super(props);
        this.dropdown = React.createRef();
        if (props.value) {
            this.state = {
                value: props.value,
                disabled: props.disabled,
                note: this.getNoteFromValue(props.value),
                isInvalid: false,
                message: null,
            };
        } else if (props.options?.length > 0) {
            this.state = {
                value: props.options[0].value,
                note: props.options[0].note || null,
                isInvalid: false,
                message: null,
            };
        } else {
            this.state = {
                value: "",
                note: null,
                isInvalid: false,
                message: null,
            };
        }

        if (typeof props.onChange === "function") {
            this.onChange = props.onChange;
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let state = {};
        if (typeof nextProps.disabled !== "undefined") {
            state.disabled = nextProps.disabled;
        }
        return state;
    }

    onChange() {}

    value() {
        return this.dropdown.current.value;
    }

    set(value) {
        if (typeof value !== "undefined" && value !== null) {
            this.setState({
                value: value,
            });
        }
    }

    empty() {
        if (this.props.options.length > 0) {
            this.setState({
                value: this.props.options[0].value,
            });
        } else {
            this.setState({
                value: null,
            });
        }
    }

    getNoteFromValue(value) {
        let option = this.props.options.find((option) => {
            return option.value !== undefined
                ? option.value === value
                : option.title === value;
        });
        return option ? option.note : null;
    }

    validate() {
        if (this.props.required && this.state.value?.length === 0) {
            this.setState({
                isInvalid: true,
                message: this.props.messages.required,
            });
            return false;
        }
        this.setState({
            isInvalid: false,
            message: null,
        });
        return true;
    }

    render() {
        let { name, options } = this.props;

        return (
            <Container
                className={
                    (this.state.disabled ? " is-disabled" : "") +
                    (this.state.isInvalid ? " is-invalid" : "")
                }
            >
                <select
                    name={name || ""}
                    ref={this.dropdown}
                    value={this.state.value}
                    onChange={(event) => {
                        this.setState(
                            {
                                value: event.target.value,
                                note: this.getNoteFromValue(event.target.value),
                                isInvalid: false,
                                message: null,
                            },
                            () => {
                                this.onChange(this.value());
                            },
                        );
                    }}
                >
                    {options &&
                        options.map((option, index) => {
                            if (!option.title) {
                                option.title = option.value;
                            }
                            if (option.type === "divider") {
                                return (
                                    <optgroup
                                        key={index}
                                        label={option.title}
                                    />
                                );
                            }

                            var props = {
                                key: index,
                                value: option.value,
                                disabled: option.disabled || false,
                            };

                            return (
                                <option key={index} {...props}>
                                    {option.title}
                                </option>
                            );
                        })}
                </select>
                {this.state.note && (
                    <div className="note">{this.state.note}</div>
                )}
                {this.state.message && (
                    <div className="message">{this.state.message}</div>
                )}
            </Container>
        );
    }
}

export default InputDropdown;
