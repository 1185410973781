import Modal from "components/Modal";
import React, { useState } from "react";
import Api from "services/Api/Api";
import toast from "react-hot-toast";

const UploadOrdersModal = ({
    setIsOpen,
    isOpen,
    customerId,
    getBatchOrders,
}) => {
    const [file, setFile] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const verifyFile = (file) => {
        if (!file) {
            toast.error("Du måste välja en fil");
            return false;
        }
        if (
            file.type !==
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
            file.type !== "text/csv"
        ) {
            toast.error("Filen måste vara en .xlsx eller .csv fil");
            return false;
        }
        return true;
    };

    const uploadFile = async (file) => {
        if (!verifyFile(file)) {
            return;
        }

        setIsLoading(true);

        try {
            const res = await Api.uploadBatchOrders({
                customerId: customerId,
                file: file,
            });
            if (res.status === 200) {
                await getBatchOrders();
                toast.success("Dina ordrar har blivit importerade");
            }
            setIsOpen(false);
        } catch (err) {
            setIsOpen(false);
            if (err.response?.status === 400) {
                return toast.error(
                    "Filen är tom eller innehåller felaktiga uppgifter",
                );
            }

            toast.error("Något gick fel försök igen eller kontaka kundtjänst");
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={"Ladda upp ordrar"}
            description={"Ladda upp ordrar från en .xlsx eller .csv fil."}
        >
            <div className="flex flex-col gap-3">
                <p>
                    Vänligen ladda upp en .xlsx eller .csv fil med dina ordrar
                    som följer vårt format.
                    <p>
                        Ladda ner vår mall här (
                        <a
                            className="text-blue-600 hover:underline cursor-pointer"
                            href="https://zendr-public.s3.eu-north-1.amazonaws.com/examples/batch-exempel.xlsx"
                        >
                            .xlsx
                        </a>{" "}
                        <a
                            className="text-blue-600 hover:underline cursor-pointer"
                            href="https://zendr-public.s3.eu-north-1.amazonaws.com/examples/batch-exempel.csv"
                        >
                            .csv
                        </a>
                        )
                    </p>{" "}
                </p>
                <input
                    accept=".xlsx, .csv"
                    multiple={false}
                    type="file"
                    onChange={(e) => {
                        setFile(e.target.files?.[0]);
                    }}
                ></input>
                <div className="ml-auto">
                    <button
                        className="c-button c-button--ghost"
                        onClick={() => setIsOpen(false)}
                    >
                        <p>Avbryt</p>
                    </button>
                    <button
                        className={
                            file || !isLoading
                                ? "c-button c-button--raised"
                                : "c-button c-button--disabled"
                        }
                        onClick={() => uploadFile(file)}
                        disabled={!file || isLoading}
                    >
                        <p>Ladda Upp</p>
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default UploadOrdersModal;
