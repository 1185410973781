import axios from "axios";
import { Chart as ChartJS, registerables } from "chart.js";
import InputDropdown from "components/Input/Dropdown";
import Number from "components/Input/Number2";
import { PrettyState } from "constants/orderState";
import Context from "context/Global";
import { getTransporterLogo } from "helpers/Logos";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Link, withRouter } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

ChartJS.register(...registerables);

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    > .header {
        padding: 1rem;
        display: flex;
        justify-content: flex-end;
    }

    > .searchInput {
        padding: 1em;
    }

    .button {
        margin-right: 1em;
    }

    > .graphs {
        display: flex;
        flex-direction: row;

        > .chart {
            flex: 1 1 50%;
            height: 600px;
            margin: 1em 1em;
            display: flex;
            flex-direction: column;
            padding: 1em;
            background: #fff;
            border-radius: 5px;
            box-shadow: 0 3px 12px 1px rgba(0, 0, 0, 0.1);

            > .graph {
                width: 100%;
                height: 100%;
                flex-shrink: 1;
            }
        }
    }

    > .newCustomers {
        display: flex;
        padding: 3rem 1rem 2rem;
        justify-content: center;
        width: 100%;

        + .newCustomers {
            padding-top: 0;
        }

        > .week {
            flex: 1 1 100%;
            text-align: center;

            > .title {
            }

            > .count {
                font-weight: bold;
                font-size: 2rem;
            }

            > .demoCount {
                display: flex;
                justify-content: center;
                margin-top: 1rem;
            }
        }
    }

    > table.orders {
        width: 100%;
        margin-bottom: 16px;
        flex-direction: column;

        > thead {
            > tr {
                > th {
                    height: 40px;
                    background: #fafafa;
                    padding: 1vh 0;
                    border-bottom: 1px solid #ddd;
                    border-top: 1px solid #ddd;
                    color: #333;
                    font-weight: normal;

                    &:first-child {
                        padding-left: 1vw;
                    }
                    &:last-child {
                        padding-right: 1vw;
                    }
                }
            }
        }

        > tbody {
            > tr {
                > td {
                    height: 80px;
                    background: #fff;
                    padding: 1vh 0;

                    > .emptyState {
                        font-style: italic;
                        color: #555;
                    }

                    &:first-child {
                        padding-left: 1vw;
                    }
                    &:last-child {
                        padding-right: 1vw;
                    }

                    &.service {
                        > img {
                            width: 32px;
                            object-size: contain;
                        }
                    }
                }
            }
        }
    }
`;

let Order = styled.tr`
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    text-decoration: none;
    color: #000;

    flex-shrink: 0;

    &:last-child {
        border-bottom: 0;
    }

    .trackingNumber {
        display: flex;
        flex-direction: row;
        align-items: center;

        > img {
            width: 60px;
            margin-right: 8px;
        }
    }

    > .timestamp {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.8);
    }

    > .notifications {
        > .notification {
            position: relative;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;

            > .text {
                opacity: 0;
                pointer-events: none;
                position: absolute;
                transition: all 0.3s ease;
                background: #fff;
                white-space: nowrap;
                display: flex;
                justify-content: center;
                align-items: center;
                left: calc(100% + 8px);
                box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
                padding: 4px 12px;
                border-radius: 4px;
                z-index: 100;
            }

            &:hover {
                .text {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            > .icon {
                border-radius: 100%;
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 48px;
                height: 48px;
                color: #fff;

                > svg {
                    width: 100%;
                    height: 100%;
                }
            }

            &.issue {
                > .icon {
                    background: #e57c1b;
                }
            }

            &.information {
                > .icon {
                    background: #72c3ed;
                }
            }

            &.noPickup {
                > .icon {
                    > svg {
                        margin-left: 2px;
                        margin-top: 4px;
                    }
                }
            }

            &.notPickedUp {
                > .icon {
                    > svg {
                        margin-left: 2px;
                        margin-top: 4px;
                    }
                }
            }
            &.exception {
                > .icon {
                    > svg {
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }

    > .type {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 500;
    }

    > .state {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 500;
    }

    > .pickup {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 500;

        > .wrapper {
            display: flex;
            align-items: center;

            > .pickup-required {
                padding: 2px 4px;
                min-height: 20px;
                font-size: 10px;
            }
        }
    }

    &.is-delivered {
        > .state {
            color: var(--color-good);
        }
    }

    &.is-warning {
        > .state {
            color: var(--color-bad);
        }
    }

    &.is-rejected {
        > .state {
            color: var(--color-bad);
        }
    }

    &.is-intransit {
        > .state {
            color: rgba(0, 80, 180, 1);
        }
    }

    &.is-rejected,
    &.is-new {
        > .price {
            text-decoration: line-through;
        }
    }

    > .title {
        font-size: 16px;
    }

    > .price {
        font-size: 16px;
        text-align: right;
    }

    &:hover {
        background: rgba(0, 0, 0, 0.02);
    }
`;

function View({ history }) {
    const context = useContext(Context);
    const [searchstring, setSearchstring] = useState("");
    const [orders, setOrders] = useState(null);
    const searchRef = useRef();
    const salesChart = useRef();
    const [salesStatistics, setSalesStatistics] = useState(null);
    const [economyStatistics, setEconomyStatistics] = useState(null);
    const [demosThisWeek, setDemosThisWeek] = useState();
    const [demosLastWeek, setDemosLastWeek] = useState();
    const [demosWeekBefore, setDemosWeekBefore] = useState();
    const [demosAnotherWeekBefore, setDemosAnotherWeekBefore] = useState();
    const [weeklyNewCustomers, setWeeklyNewCustomers] = useState(null);
    const [monthlyDemos, setMonthlyDemos] = useState(null);
    const [clientManagers, setClientManagers] = useState([]);
    const [selectedClientManagerId, setSelectedClientManagerId] =
        useState(null);
    const [salesLabels] = useState(
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].reverse().map((offset) => {
            return moment().subtract(offset, "month").format("YYYY-MM");
        }),
    );

    useEffect(() => {
        init();
    }, []);

    async function init() {
        if (
            !context.user.administrator &&
            !(context.user.brand && context.user.brand.id)
        ) {
            history.replace("/");
            return;
        }

        loadData();
    }

    async function loadData() {
        Api.getSalesStatistics({
            clientManagerId: selectedClientManagerId || context.user.id,
        }).then(setSalesStatistics);

        Api.getEconomyStatistics({
            interval: "week",
            brandId: null,
            clientManagerId: selectedClientManagerId || context.user.id,
        }).then(setEconomyStatistics);

        setWeeklyNewCustomers(
            await Api.getWeeklyNewCustomers({
                clientManagerId: selectedClientManagerId || context.user.id,
            }),
        );

        if (context.user.role === "OWNER" && !clientManagers?.length) {
            Api.getClientManagers({
                includeOwners: true,
            }).then(setClientManagers);
        }
    }

    useEffect(() => {
        loadData();
    }, [selectedClientManagerId]);

    useEffect(() => {
        if (salesStatistics) {
            const [
                demosThisWeek,
                demosLastWeek,
                demosWeekBefore,
                demosAnotherWeekBefore,
            ] = salesStatistics.demos.reduce(
                (acc, demo) => {
                    // This week
                    if (
                        moment()
                            .startOf("week")
                            .add(2, "days")
                            .format("YYYY-MM-DD") === demo.date
                    ) {
                        acc[0] += demo.amount;
                    } else if (
                        moment()
                            .startOf("week")
                            .subtract(1, "week")
                            .add(2, "days")
                            .format("YYYY-MM-DD") === demo.date
                    ) {
                        acc[1] += demo.amount;
                    } else if (
                        moment()
                            .startOf("week")
                            .subtract(2, "week")
                            .add(2, "days")
                            .format("YYYY-MM-DD") === demo.date
                    ) {
                        acc[2] += demo.amount;
                    } else if (
                        moment()
                            .startOf("week")
                            .subtract(3, "week")
                            .add(2, "days")
                            .format("YYYY-MM-DD") === demo.date
                    ) {
                        acc[3] += demo.amount;
                    }
                    return acc;
                },
                [0, 0, 0, 0],
            );
            setDemosThisWeek(demosThisWeek);
            setDemosLastWeek(demosLastWeek);
            setDemosWeekBefore(demosWeekBefore);
            setDemosAnotherWeekBefore(demosAnotherWeekBefore);

            let demoLookup = salesStatistics.demos.reduce((acc, demo) => {
                const month = demo.date.substr(0, 7);
                if (!acc[month]) {
                    acc[month] = 0;
                }
                acc[month] += demo.amount;
                return acc;
            }, {});

            let demoArray = Object.keys(demoLookup).map((key) => {
                return {
                    x: key,
                    y: demoLookup[key],
                };
            });
            setMonthlyDemos(demoArray);
        }
    }, [salesStatistics]);

    async function setDemoCount({ weekOffset, count }) {
        const date = moment()
            .startOf("week")
            .add(2, "days")
            .subtract(weekOffset, "weeks")
            .format("YYYY-MM-DD");

        await Api.updateUserDemoCount({
            clientManagerId: selectedClientManagerId || context.user.id,
            date,
            count,
        });
        setSalesStatistics((stats) => {
            if (
                !stats.demos.find((demo) => {
                    return demo.date === date;
                })
            ) {
                stats.demos.push({
                    amount: count,
                    date: date,
                });
                return {
                    ...stats,
                };
            }
            return {
                ...stats,
                demos: stats.demos.map((demo) => {
                    if (demo.date === date) {
                        demo.amount = count;
                    }
                    return demo;
                }),
            };
        });
    }

    useEffect(() => {
        // Bounce!
        if (searchRef.current) {
            searchRef.current.cancel();
        }
        searchRef.current = axios.CancelToken.source();
        if (searchstring && searchstring.length > 3) {
            Api.adminSearchOrders({
                query: searchstring,
                cancelToken: searchRef.current.token,
            })
                .then((response) => {
                    setOrders(response.orders);
                })
                .catch(() => {
                    // Cancelled
                });
        } else {
            setOrders(null);
        }
    }, [searchstring]);

    return (
        <Container>
            {/* <div className="searchInput">
				<Input type="text" placeholder={"Sök efter försändelser..."} value={searchstring} onChange={setSearchstring} />
			</div> */}
            {clientManagers.length > 0 && (
                <div className="header">
                    <div className="button">
                        <Link
                            to="/admin/sales/turnover"
                            className="c-button c-button--raised"
                        >
                            Omsättning per Säljare
                        </Link>
                    </div>
                    <InputDropdown
                        options={clientManagers.map((user) => {
                            return {
                                title: user.name,
                                value: user.id,
                            };
                        })}
                        value={context.user.id}
                        onChange={setSelectedClientManagerId}
                    />
                </div>
            )}
            {orders && (
                <table className="orders">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Referens</th>
                            <th>Fraktsedelnummer</th>
                            {context.user.administrator && <th>Brand</th>}
                            <th>Mottagare</th>
                            <th>Bokad</th>
                            <th>Status</th>
                            <th>Pris</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.length === 0 && (
                            <tr>
                                <td colSpan="100%">
                                    <div className="emptyState">
                                        Hittade inte några försändelser som
                                        matchade din sökning.
                                    </div>
                                </td>
                            </tr>
                        )}
                        {orders.map((order) => {
                            let exception = null;
                            let pickupInPast = false;
                            let noPickup = false;
                            let orderBookingFailed = false;
                            if (!order.trackingNumber) {
                                orderBookingFailed = true;
                            } else if (order.hasError) {
                                exception = order.messages.find((message) => {
                                    return message.type === "error";
                                });
                            } else if (
                                order.state === "APPROVED" &&
                                order.confirmationNumber &&
                                moment().diff(moment(order.pickupDate)) > 0
                            ) {
                                pickupInPast = true;
                            } else if (
                                order.state === "APPROVED" &&
                                !order.confirmationNumber
                            ) {
                                noPickup = true;
                            }

                            return (
                                <Order
                                    key={order.id}
                                    className={`is-${order.state
                                        .toLowerCase()
                                        .replace(" ", "")}`}
                                    href={`/orders/${order.id}`}
                                    onClick={() => {
                                        history.push(`/orders/${order.id}`);
                                    }}
                                >
                                    <td className="notifications">
                                        {exception && (
                                            <div className="notification issue exception">
                                                <div className="text">
                                                    {getMessageText(exception)}
                                                </div>
                                                <div className="icon">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        )}
                                        {pickupInPast && (
                                            <div className="notification information notPickedUp">
                                                <div className="text">
                                                    Upphämtningen har inte
                                                    registrerats ännu.
                                                </div>
                                                <div className="icon">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M2 11.741c-1.221-1.009-2-2.535-2-4.241 0-3.036 2.464-5.5 5.5-5.5 1.706 0 3.232.779 4.241 2h4.259c.552 0 1 .448 1 1v2h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035v4.48c0 1.156-.616 2-2 2h-1c0 1.656-1.344 3-3 3s-3-1.344-3-3h-4c0 1.656-1.344 3-3 3s-3-1.344-3-3h-2c-.552 0-1-.448-1-1v-6.259zm6 6.059c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm10 0c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm-7.207-11.8c.135.477.207.98.207 1.5 0 3.036-2.464 5.5-5.5 5.5-.52 0-1.023-.072-1.5-.207v4.207h1.765c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h5.53c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h1.765v-4.575l-1.711-2.929c-.179-.307-.508-.496-.863-.496h-4.426v6h-2v-9h-2.207zm5.207 4v3h5l-1.427-2.496c-.178-.312-.509-.504-.868-.504h-2.705zm-10.5-6c1.932 0 3.5 1.568 3.5 3.5s-1.568 3.5-3.5 3.5-3.5-1.568-3.5-3.5 1.568-3.5 3.5-3.5zm.5 3h2v1h-3v-3h1v2z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        )}
                                        {noPickup && (
                                            <div className="notification issue noPickup">
                                                <div className="text">
                                                    Ingen upphämtning bokad!
                                                </div>
                                                <div className="icon">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M2 11.741c-1.221-1.009-2-2.535-2-4.241 0-3.036 2.464-5.5 5.5-5.5 1.706 0 3.232.779 4.241 2h4.259c.552 0 1 .448 1 1v2h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035v4.48c0 1.156-.616 2-2 2h-1c0 1.656-1.344 3-3 3s-3-1.344-3-3h-4c0 1.656-1.344 3-3 3s-3-1.344-3-3h-2c-.552 0-1-.448-1-1v-6.259zm6 6.059c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm10 0c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm-7.207-11.8c.135.477.207.98.207 1.5 0 3.036-2.464 5.5-5.5 5.5-.52 0-1.023-.072-1.5-.207v4.207h1.765c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h5.53c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h1.765v-4.575l-1.711-2.929c-.179-.307-.508-.496-.863-.496h-4.426v6h-2v-9h-2.207zm5.207 4v3h5l-1.427-2.496c-.178-.312-.509-.504-.868-.504h-2.705zm-10.5-6c1.932 0 3.5 1.568 3.5 3.5s-1.568 3.5-3.5 3.5-3.5-1.568-3.5-3.5 1.568-3.5 3.5-3.5zm.5 3h2v1h-3v-3h1v2z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        )}
                                    </td>
                                    <td className="type">
                                        {order.information.reference}
                                    </td>
                                    <td>
                                        <div className="trackingNumber">
                                            <img
                                                src={`/images/icons/${getTransporterLogo(
                                                    order.service.name,
                                                )}`}
                                            />
                                            {order.trackingNumber}
                                        </div>
                                    </td>
                                    {context.user.administrator && (
                                        <td>{order.brand.name}</td>
                                    )}
                                    <td>
                                        <div className="receiver">
                                            <div className="title">
                                                {order.route.to.contact.private
                                                    ? order.route.to.contact
                                                          .name
                                                    : order.route.to.contact
                                                          .company}
                                            </div>
                                            <div className="location">
                                                {order.route.to.postalTown},{" "}
                                                {order.route.to.countryCode}
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        className="timestamp"
                                        title={moment(order.created).format(
                                            "YYYY-MM-DD HH:mm:ss",
                                        )}
                                    >
                                        {moment(order.created).calendar()}
                                    </td>
                                    <td className="state">
                                        {renderOrderState(order)}
                                    </td>
                                    <td className="price">
                                        {order.price.amount
                                            ?.toString()
                                            .replace(
                                                /(\d)(?=(\d\d\d)+(?!\d))/g,
                                                "$1 ",
                                            )}{" "}
                                        {order.price.unit || ""}
                                    </td>
                                </Order>
                            );
                        })}
                    </tbody>
                </table>
            )}
            {salesStatistics && economyStatistics && (
                <div className="graphs">
                    <div className="chart">
                        <Bar
                            ref={salesChart}
                            data={{
                                // labels: salesStatistics.map((row) => {
                                // 	switch(graphSettings.interval) {
                                // 		case "year": {
                                // 			return moment(row.title, "YYYY").format('YYYY');
                                // 		}
                                // 		case "month": {
                                // 			return moment(row.title, "YYYY-MM").format('MMM');
                                // 		}
                                // 		case "week": {
                                // 			return `v${row.title.substr(5)}`;
                                // 		}
                                // 		case "day": {
                                // 			return moment(row.title, "YYYY-MM-DD").format('YYY-MM-DD');
                                // 		}
                                // 	}
                                // }),
                                labels: salesLabels,
                                datasets: [
                                    {
                                        label: "Budget",
                                        // @ts-ignore
                                        type: "line",
                                        borderColor: "rgb(0,0,0)",
                                        borderWidth: 1,
                                        borderDash: [10, 5],
                                        fill: false,
                                        data: [
                                            salesStatistics.budget,
                                            salesStatistics.budget,
                                            salesStatistics.budget,
                                            salesStatistics.budget,
                                            salesStatistics.budget,
                                            salesStatistics.budget,
                                            salesStatistics.budget,
                                            salesStatistics.budget,
                                            salesStatistics.budget,
                                            salesStatistics.budget,
                                            salesStatistics.budget,
                                            salesStatistics.budget,
                                            salesStatistics.budget,
                                        ],
                                        radius: 0,
                                        yAxisID: "y",
                                    },
                                    {
                                        label: "Antal kunder som börjat boka",
                                        type: "bar",
                                        borderColor: "rgb(147,212,140)",
                                        backgroundColor: "rgb(144,238,144)",
                                        data: salesStatistics.firstShipments.map(
                                            (data) => {
                                                return {
                                                    x: data.month,
                                                    y: data.customerCount,
                                                };
                                            },
                                        ),
                                        yAxisID: "y",
                                    },
                                    {
                                        label: "Nya inlogg",
                                        type: "bar",
                                        borderColor: "rgb(107,212,140)",
                                        backgroundColor: "rgb(4,238,144)",
                                        data: salesStatistics.newCustomers.map(
                                            (data) => {
                                                return {
                                                    x: data.month,
                                                    y: data.customerCount,
                                                };
                                            },
                                        ),
                                        yAxisID: "y",
                                    },
                                    {
                                        label: "Antal demos",
                                        type: "bar",
                                        borderColor: "rgb(147,112,140)",
                                        backgroundColor: "rgb(144,138,144)",
                                        data: monthlyDemos,
                                        yAxisID: "y",
                                    },
                                ],
                            }}
                            options={{
                                maintainAspectRatio: false,

                                plugins: {},
                                scales: {
                                    x: {
                                        ticks: {
                                            callback: function (value) {
                                                return moment()
                                                    .set("day", 1)
                                                    .set(
                                                        "month",
                                                        salesLabels[
                                                            value
                                                        ].substr(5) - 1,
                                                    )
                                                    .set(
                                                        "year",
                                                        salesLabels[
                                                            value
                                                        ].substr(0, 4),
                                                    )
                                                    .format("MMMM");
                                            },
                                        },
                                    },
                                    y: {},
                                },
                            }}
                            tooltips={{
                                callbacks: {
                                    label: function (tooltipItem, data) {
                                        var label =
                                            data.datasets[
                                                tooltipItem.datasetIndex
                                            ].label || "";

                                        if (label) {
                                            label += ": ";
                                        }
                                        label += tooltipItem.yLabel
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                " ",
                                            );

                                        return label;
                                    },
                                },
                            }}
                        />
                    </div>
                    <div className="chart">
                        <Bar
                            data={{
                                labels: economyStatistics.map((row) => {
                                    return `v${row.title.substr(5)}`;
                                }),
                                datasets: [
                                    {
                                        label: "Försändelser",
                                        tension: 0.3,
                                        borderColor: "#00000033",
                                        // @ts-ignore
                                        type: "line",
                                        fill: false,
                                        data: economyStatistics.map((row) => {
                                            return Math.round(
                                                row.numberOfOrders,
                                            );
                                        }),
                                        yAxisID: "y",
                                    },
                                    {
                                        label: "Vinst",
                                        borderWidth: 1,
                                        borderColor: "rgb(147,212,140)",
                                        backgroundColor: "rgb(144,238,144)",
                                        data: economyStatistics.map((row) => {
                                            return Math.round(row.revenue);
                                        }),
                                        yAxisID: "y1",
                                    },
                                    {
                                        label: "Omsättning",
                                        borderWidth: 1,
                                        borderColor: "rgb(176,196,222)",
                                        backgroundColor: "rgb(173,216,230)",
                                        data: economyStatistics.map((row) => {
                                            return Math.round(row.income);
                                        }),
                                        yAxisID: "y1",
                                    },
                                ],
                            }}
                            options={{
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        stacked: true,
                                    },
                                    y: {
                                        position: "right",
                                    },
                                    y1: {
                                        ticks: {
                                            callback: (
                                                value,
                                                index,
                                                values,
                                            ) => {
                                                if (value >= 1000) {
                                                    return value
                                                        .toString()
                                                        .replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            " ",
                                                        );
                                                } else {
                                                    return value;
                                                }
                                            },
                                        },
                                    },
                                },
                            }}
                            tooltips={{
                                callbacks: {
                                    label: function (tooltipItem, data) {
                                        var label =
                                            data.datasets[
                                                tooltipItem.datasetIndex
                                            ].label || "";

                                        if (label) {
                                            label += ": ";
                                        }
                                        label += tooltipItem.yLabel
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                " ",
                                            );

                                        return label;
                                    },
                                },
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="newCustomers">
                <div className="week">
                    <div className="title">Nya kunder denna vecka</div>
                    <div className="count">
                        {weeklyNewCustomers?.newThisWeek}
                    </div>
                </div>
                <div className="week">
                    <div className="title">Föregående vecka</div>
                    <div className="count">
                        {weeklyNewCustomers?.newLastWeek}
                    </div>
                </div>
                <div className="week">
                    <div className="title">Veckan innan dess</div>
                    <div className="count">
                        {weeklyNewCustomers?.newWeekBefore}
                    </div>
                </div>
                <div className="week">
                    <div className="title">Veckan innan den</div>
                    <div className="count">
                        {weeklyNewCustomers?.newAnotherWeekBefore}
                    </div>
                </div>
            </div>
            <div className="newCustomers">
                <div className="week">
                    <div className="title">
                        Kunder med första bokning denna vecka
                    </div>
                    <div className="count">
                        {weeklyNewCustomers?.firstOrderThisWeek}
                    </div>
                </div>
                <div className="week">
                    <div className="title">Föregående vecka</div>
                    <div className="count">
                        {weeklyNewCustomers?.firstOrderLastWeek}
                    </div>
                </div>
                <div className="week">
                    <div className="title">Veckan innan dess</div>
                    <div className="count">
                        {weeklyNewCustomers?.firstOrderWeekBefore}
                    </div>
                </div>
                <div className="week">
                    <div className="title">Veckan innan den</div>
                    <div className="count">
                        {weeklyNewCustomers?.firstOrderAnotherWeekBefore}
                    </div>
                </div>
            </div>
            <div className="newCustomers">
                <div className="week">
                    <div className="title">Demos denna vecka</div>
                    {typeof demosThisWeek === "number" && (
                        <div className="demoCount">
                            <Number
                                min={0}
                                value={demosThisWeek}
                                onChange={(value) => {
                                    setDemoCount({
                                        weekOffset: 0,
                                        count: value,
                                    });
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className="week">
                    <div className="title">Föregående vecka</div>
                    {typeof demosLastWeek === "number" && (
                        <div className="demoCount">
                            <Number
                                min={0}
                                value={demosLastWeek}
                                onChange={(value) => {
                                    setDemoCount({
                                        weekOffset: 1,
                                        count: value,
                                    });
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className="week">
                    <div className="title">Veckan innan dess</div>
                    {typeof demosWeekBefore === "number" && (
                        <div className="demoCount">
                            <Number
                                min={0}
                                value={demosWeekBefore}
                                onChange={(value) => {
                                    setDemoCount({
                                        weekOffset: 2,
                                        count: value,
                                    });
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className="week">
                    <div className="title">Veckan innan den</div>
                    {typeof demosAnotherWeekBefore === "number" && (
                        <div className="demoCount">
                            <Number
                                min={0}
                                value={demosAnotherWeekBefore}
                                onChange={(value) => {
                                    setDemoCount({
                                        weekOffset: 3,
                                        count: value,
                                    });
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Container>
    );
}

function renderOrderState(order) {
    if (order.state === "DELIVERED") {
        return `Levererad ${moment(order.deliveryDate).format("YYYY-MM-DD")}`;
    } else if (
        order.state === "APPROVED" &&
        order.confirmationNumber &&
        order.pickupDate
    ) {
        return `Bokad för upphämtning ${moment(order.pickupDate).format(
            "YYYY-MM-DD",
        )}`;
    }
    return PrettyState(order.state);
}

function getMessageText(message) {
    switch (message.code) {
        case "DELIVERY_FAILED": {
            return "Leveransen misslyckades. Se detaljer och kontakta kundservice vid behov.";
        }
        case "MISROUTED": {
            return "Försändelsen försenas pga felsortering.";
        }
        case "MISSING_CUSTOMS": {
            return "Saknas eller felaktiga tull-dokument. Var god kontakta kundservice!";
        }
        case "DELIVERY_FAILED": {
            return "Misslyckat leveransförsök. Inväntar nästa utkörningstillfälle.";
        }
        case "INVALID_ADDRESS": {
            return "Felaktig leveransadress. Var god kontakta kundservice!";
        }
        case "CHANGED": {
            return "Ändring av transportuppdrag. Kontakta kundservice för detaljer.";
        }
        case "INCOMPLETE": {
            return "Saknas ellerfelaktiga dokument. Var god kontakta kundservice!";
        }
        case "RECLAIMED": {
            return "Försändelsen är reklamerad. Var god kontakta kundservice!";
        }
        case "REFUSED": {
            return "Mottagaren vägrar ta emot godset. Sändningen returneras till avsändaren.";
        }
        default: {
            return message.text;
        }
    }
}

export default withRouter(View);
