import Checkbox from "components/Input/Checkbox";
import { PrettyState } from "constants/orderState";
import Context from "context/Global";
import { getTransporterLogo } from "helpers/Logos";
import { match } from "helpers/Match";
import {
    getTransporterName,
    getUserFriendlyServiceName,
} from "helpers/Services";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import HelpText from "../helptext";
import useCreateDraftsStore from "../../../Drafts/Create/createDraftsStore";
import { getIntegrationName } from "../../../../helpers/Integrations";

let Container = styled.tr`
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    text-decoration: none;
    color: #000;

    flex-shrink: 0;

    &:last-child {
        border-bottom: 0;
    }

    > .notifications {
        width: 90px;
        min-width: 90px;

        > .notification {
            position: relative;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;

            > .text {
                opacity: 0;
                pointer-events: none;
                position: absolute;
                transition: all 0.3s ease;
                background: #fff;
                white-space: nowrap;
                display: flex;
                justify-content: center;
                align-items: center;
                left: calc(100% + 8px);
                box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
                padding: 4px 12px;
                border-radius: 4px;
                z-index: 100;
            }

            &:hover {
                .text {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            > .icon {
                border-radius: 100%;
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 48px;
                height: 48px;
                color: #fff;

                > svg {
                    width: 100%;
                    height: 100%;
                }
            }

            &.issue {
                > .icon {
                    background: #e57c1b;
                }
            }

            &.information {
                > .icon {
                    background: #72c3ed;
                }
            }

            &.noPickup {
                > .icon {
                    > svg {
                        margin-left: 2px;
                        margin-top: 4px;
                    }
                }
            }

            &.notPickedUp {
                > .icon {
                    > svg {
                        margin-left: 2px;
                        margin-top: 4px;
                    }
                }
            }

            &.exception {
                > .icon {
                    > svg {
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }

    > .notification {
        padding-left: 1em;
        width: 48px;
        box-sizing: border-box;
        padding-right: 1em;
    }

    .created {
        > .wrapper {
            display: flex;
            flex-direction: column;
            padding: 0 1rem 0 0;

            > .date {
                white-space: nowrap;
            }

            > .source {
                font-size: 0.8rem;
                color: #555;
            }
        }
    }

    .information {
        > .wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 1rem 0 0;
            width: max-content;

            .reference,
            .service {
                font-size: 0.8rem;
                color: #555;
                white-space: nowrap;
            }

            > img {
                margin-right: 8px;
                width: 60px;
                max-height: 30px;
            }
        }
    }

    .sender,
    .recipient {
        > .wrapper {
            display: flex;
            flex-direction: column;
            padding: 0 1rem 0 0;

            > .receiver {
                font-weight: bold;
                white-space: nowrap;
            }

            > .location {
                white-space: nowrap;
            }
        }
    }

    > .state {
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.8);
        width: 100%;
    }

    &.is-delivered {
        > .state {
            color: var(--color-good);
        }
    }

    &.is-warning {
        > .state {
            color: var(--color-bad);
        }
    }

    &.is-rejected {
        > .state {
            color: var(--color-bad);
        }
    }

    &.is-intransit {
        > .state {
            color: rgba(0, 80, 180, 1);
        }
    }

    &.is-rejected,
    &.is-new {
        > .price {
            text-decoration: line-through;
        }
    }

    &.is-archiving {
        pointer-events: none;
        opacity: 0.1;
        transition: all 0.3s ease;
    }

    > .price {
        font-size: 16px;
        white-space: nowrap;
    }

    .menu {
        width: 100px;

        > .button {
            color: rgba(0, 0, 0, 0.5);
            padding: 1rem;
            padding-left: 2rem;
            display: inline-flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;

            &:hover {
                color: rgba(0, 0, 0, 1);
            }

            > .menu-wrapper {
                pointer-events: none;
                display: flex;
                justify-content: center;
                align-items: center;

                > .menu-background {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 100;
                }

                > .menu-container {
                    position: absolute;
                    background: #fff;
                    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
                    right: 0;
                    transition: all 0.3s ease;
                    opacity: 0;
                    z-index: 101;
                    transform: translateY(10px);

                    > .option {
                        padding: 1rem;
                        white-space: nowrap;
                        color: #000;

                        &:hover {
                            background: #eaeaea;
                        }
                    }
                }

                &.is-visible {
                    pointer-events: all;

                    > .menu-container {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
        }
    }

    > .title {
        font-size: 16px;
    }

    &:hover {
        background: rgba(0, 0, 0, 0.02);
    }
`;

function Component({ history, onToggleSelected, ...props }) {
    const [order, setOrder] = useState(props.order);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [isArchiving, setIsArchiving] = useState(false);
    const context = useContext(Context);
    const { setReceiverPaysContactId } = useCreateDraftsStore();

    useEffect(() => {
        setOrder(props.order);
    }, [props.order]);
    let exception = null;
    let pickupInPast = false;
    let noPickup = false;
    if (order.hasError) {
        exception = order.messages.find((message) => {
            return message.type === "error";
        });
    } else if (
        order.state === "APPROVED" &&
        order.confirmationNumber &&
        moment().diff(moment(order.pickupDate)) > 0
    ) {
        pickupInPast = true;
    } else if (order.state === "APPROVED" && !order.confirmationNumber) {
        noPickup = true;
    }

    function renderOrderState(order) {
        let response = [];

        if (order.state === "DELIVERED") {
            response.push(
                <div key="delivered">
                    Levererad {moment(order.deliveryDate).format("YYYY-MM-DD")}
                </div>,
            );
        } else if (
            order.state === "APPROVED" &&
            order.confirmationNumber &&
            order.pickupDate
        ) {
            if (
                moment(order.pickupDate).format("YYYY-MM-DD") <
                moment().format("YYYY-MM-DD")
            ) {
                response.push(
                    <div key="booked">
                        Bokades för upphämtning{" "}
                        {moment(order.pickupDate).calendar(null, {
                            lastDay: "[igår]",
                            lastWeek: "[i] dddd[s]",
                            sameElse: "LL",
                        })}
                        .
                    </div>,
                );
            } else {
                response.push(
                    <div key="booked">
                        Bokad för upphämtning{" "}
                        {moment(order.pickupDate).calendar(null, {
                            sameDay: "[idag]",
                            nextDay: "[imorgon]",
                            nextWeek: "[på] dddd",
                            sameElse: "LL",
                        })}
                        .
                    </div>,
                );
            }
        } else if (order.state === "APPROVED") {
            response.push(<div key="nopickup">Ingen upphämtning bokad!</div>);
        } else if (order.state === "REJECTED") {
            response.push(<div key="rejected">Bokningen misslyckades!</div>);
        } else if (order.state === "IN_TRANSIT") {
            if (order.deliveryDate) {
                response.push(
                    <div key="rejected">
                        Preliminär leverans:{" "}
                        <b>
                            {moment(order.deliveryDate).calendar(null, {
                                nextWeek: "[På] dddd",
                                nextDay: "[Imorgon]",
                                sameDay: "[Idag]",
                                lastDay: "[Igår]",
                                lastWeek: "[I] dddd[s]",
                                sameElse: "LL",
                            })}
                        </b>
                    </div>,
                );
            } else {
                response.push(
                    <div key="state">{PrettyState(order.state)}</div>,
                );
            }
        } else {
            response.push(<div key="state">{PrettyState(order.state)}</div>);
        }

        return response;
    }

    function getMessageText(message) {
        switch (message.code) {
            case "DELIVERY_FAILED": {
                return "Leveransen misslyckades. Se detaljer och kontakta kundservice vid behov.";
            }
            case "MISROUTED": {
                return "Försändelsen försenas pga felsortering.";
            }
            case "MISSING_CUSTOMS": {
                return "Saknas eller felaktiga tull-dokument. Var god kontakta kundservice!";
            }
            case "DELIVERY_FAILED": {
                return "Misslyckat leveransförsök. Inväntar nästa utkörningstillfälle.";
            }
            case "INVALID_ADDRESS": {
                return "Felaktig leveransadress. Var god kontakta kundservice!";
            }
            case "CHANGED": {
                return "Ändring av transportuppdrag. Kontakta kundservice för detaljer.";
            }
            case "INCOMPLETE": {
                return "Saknas ellerfelaktiga dokument. Var god kontakta kundservice!";
            }
            case "RECLAIMED": {
                return "Försändelsen är reklamerad. Var god kontakta kundservice!";
            }
            case "REFUSED": {
                return "Mottagaren vägrar ta emot godset. Sändningen returneras till avsändaren.";
            }
            default: {
                return message.text;
            }
        }
    }

    function openMenu(ev) {
        ev.stopPropagation();
        setMenuIsOpen(true);
    }

    function closeMenu(ev) {
        ev.stopPropagation();
        setMenuIsOpen(false);
    }
    async function duplicateOrder() {
        setReceiverPaysContactId(null);
        if (order.receiverPays) {
            setReceiverPaysContactId(order.receiverPays.contactId);
        }
        context.updateOrder({
            packages: order.packages,
            sender: order.route.from,
            receiver: order.route.to,
            information: order.information,
            customsInvoice: null,
        });
        history.push(`/create/${order.id}`);
    }

    async function archiveOrder(ev) {
        ev.stopPropagation();
        setMenuIsOpen(false);
        setIsArchiving(true);
        await Api.archiveOrder({
            orderId: order.id,
        });

        props.refresh();
    }

    if (!order) {
        return null;
    }

    const disallowedStates = ["REJECTED", "CANCELLED", "NEW", "DRAFT"];
    const isCreatedAfterCutoff = moment(
        order.created,
        "YYYY-MM-DD HH:mm:ss",
    ).isAfter(moment("2022-11-01"));

    const shouldAllowPrint =
        !disallowedStates.includes(order.state) && isCreatedAfterCutoff;

    return (
        <Container
            className={`is-${order.state.toLowerCase().replace(" ", "")}${
                isArchiving ? " is-archiving" : ""
            }`}
            href={`/orders/${order.id}`}
            onClick={() => {
                history.push(`/orders/${order.id}`);
            }}
        >
            <td className="select" onClick={(ev) => ev.stopPropagation()}>
                {shouldAllowPrint ? (
                    <Checkbox
                        onChange={onToggleSelected}
                        value={order.selected}
                    />
                ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div>Utskrift ej tillgänglig</div>
                        <HelpText helpText="Dokument för beställningar före november 2022 har tagits bort i en dataflytt. Framöver kommer dina filer att finnas tillgängliga i 3 år från beställningsdatumet." />
                    </div>
                )}
            </td>
            <td className="notifications">
                {exception && (
                    <div className="notification issue exception">
                        <div className="text">{getMessageText(exception)}</div>
                        <div className="icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="currentColor"
                                    d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
                                />
                            </svg>
                        </div>
                    </div>
                )}
            </td>
            <td className="information">
                <div className="wrapper">
                    <img
                        src={`/images/icons/${getTransporterLogo(
                            order.service.name,
                        )}`}
                    />
                    <div className="information">
                        {order.information.reference && (
                            <div className="reference">
                                Ref: {order.information.reference}
                            </div>
                        )}
                        <div className="tracking">{order.trackingNumber}</div>
                        <div className="service">
                            {getTransporterName(order)}{" "}
                            {getUserFriendlyServiceName(order)}
                        </div>
                    </div>
                </div>
            </td>
            <td className="created">
                <div className="wrapper">
                    <div className="date">
                        {moment(order.created).calendar()}
                    </div>
                    <div className="source">
                        {getIntegrationName(order.customerIntegrationType)}
                    </div>
                </div>
            </td>
            <td className="sender">
                <div className="wrapper">
                    <div className="receiver">
                        {order.route?.from.contact.private
                            ? order.route?.from.contact.name
                            : order.route?.from.contact.company}
                    </div>
                    <div className="location">
                        {[
                            order.route?.from.postalTown,
                            order.route?.from.countryCode,
                        ]
                            .filter((v) => v)
                            .join(", ")}
                    </div>
                </div>
            </td>
            <td className="recipient">
                <div className="wrapper">
                    <div className="receiver">
                        {order.route?.to.contact.private
                            ? order.route?.to.contact.name
                            : order.route?.to.contact.company}
                    </div>
                    <div className="location">
                        {[
                            order.route?.to.postalTown,
                            order.route?.to.countryCode,
                        ]
                            .filter((v) => v)
                            .join(", ")}
                    </div>
                </div>
            </td>
            {/* <td className="type">{order.information.reference}</td>
        <td>
            <div className="trackingNumber">
                <img src={`/images/icons/${order.service.name.toLowerCase()}.svg`} />{order.trackingNumber}
            </div>
        </td>
        <td>
            <div className="receiver">
                <div className="title">{order.route.to.contact.private ? order.route.to.contact.name : order.route.to.contact.company}</div>
                <div className="location">{order.route.to.postalTown}, {order.route.to.countryCode}</div>
            </div>
        </td>
        <td className="timestamp" title={moment(order.created).format('YYYY-MM-DD HH:mm:ss')}>{moment(order.created).calendar()}</td> */}
            <td className="state">{renderOrderState(order)}</td>
            <td className="price">
                {order.receiverPays ? (
                    "Bokad med mottagarfrakt"
                ) : (
                    <>
                        {order.price?.amount
                            ?.toString()
                            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}{" "}
                        {order.price?.unit}
                    </>
                )}
            </td>
            <td className="menu">
                <div className="button" onClick={openMenu}>
                    <div
                        className={`menu-wrapper ${
                            menuIsOpen ? " is-visible" : ""
                        }`}
                    >
                        <div
                            className="menu-background"
                            onClick={closeMenu}
                        ></div>
                        <div className="menu-container">
                            <div className="option" onClick={duplicateOrder}>
                                Skapa kopia
                            </div>
                            {!order.archived &&
                                ["SHIPPED", "IN_TRANSIT", "DELIVERING"].indexOf(
                                    order.state,
                                ) < 0 && (
                                    <div
                                        className="option"
                                        onClick={archiveOrder}
                                    >
                                        Arkivera
                                    </div>
                                )}
                        </div>
                    </div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M12 18c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z"
                        />
                    </svg>
                </div>
            </td>
        </Container>
    );
}

export default withRouter(Component);
