import Input from "components/OldInput";
import React, { useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import toast from "react-hot-toast";
import ConfirmModal from "../../components/ConfirmModal";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;

    > .search {
        padding: 1em;
        width: 20%;
    }

    > table {
        width: 100%;
        margin-bottom: 16px;
        flex-direction: column;

        input[type="checkbox"] {
            transform: scale(1.5);
        }

        > thead {
            > tr {
                > th {
                    background: #fafafa;
                    padding: 1em 0;
                    border-bottom: 1px solid #ddd;
                    border-top: 1px solid #ddd;
                    color: #333;
                    font-weight: normal;

                    &:first-child {
                        padding-left: 1em;
                    }
                    &:last-child {
                        padding-right: 1em;
                    }
                }
            }
        }

        > tbody {
            > tr {
                > td {
                    background: #fff;
                    padding: 0.5em 0;
                    cursor: pointer;

                    &:first-child {
                        padding-left: 1em;
                    }
                    &:last-child {
                        padding-right: 1em;
                    }

                    &.service {
                        > img {
                            width: 32px;
                            object-size: contain;
                        }
                    }
                }

                &:hover > td {
                    background: rgba(250, 250, 250, 1);
                }
            }
        }
    }
    > .buttons {
        padding: 16px 0;
        display: flex;
        align-items: center;

        > .remove {
            color: rgba(255, 100, 100, 1);
            border-color: rgba(255, 100, 100, 1);

            &:hover {
                background-color: rgba(255, 200, 200, 1);
            }
        }
    }
`;

let Title = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.6);
`;

function Component({ history }) {
    const [contacts, setContacts] = useState([]);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [selectedContactIds, setSelectedContactIds] = useState([]);

    useEffect(() => {
        search();
    }, []);

    async function search(searchstring) {
        setSelectedContactIds([]);
        try {
            const response = await Api.searchContacts({ searchstring });
            setContacts(response);
        } catch (error) {
            toast.error("Det gick inte att hämta kontakterna");
        }
    }

    const handleSelectAllChange = (isChecked) => {
        if (isChecked) {
            const allContactIds = contacts.map((contact) => contact.id);
            setSelectedContactIds(allContactIds);
        } else {
            setSelectedContactIds([]);
        }
    };

    const handleCheckboxChange = (contactId) => {
        setSelectedContactIds((prevSelectedIds) => {
            const isSelected = prevSelectedIds.includes(contactId);
            if (isSelected) {
                return prevSelectedIds.filter((id) => id !== contactId);
            } else {
                return [...prevSelectedIds, contactId];
            }
        });
    };

    const removeSelectedContacts = () => {
        setIsConfirmModalOpen(true);
    };

    const handleConfirm = async () => {
        try {
            const response =
                await Api.deleteSelectedContacts(selectedContactIds);

            setContacts((prevContacts) =>
                prevContacts.filter(
                    (contact) => !selectedContactIds.includes(contact.id),
                ),
            );

            setSelectedContactIds([]);

            toast.success("Kontakterna har tagits bort");
        } catch (error) {
            toast.error("Det gick inte att ta bort kontakterna.");
        } finally {
            setIsConfirmModalOpen(false);
        }
    };

    const navigateToContact = (contactId) => {
        history.push(`/contacts/${contactId}`);
    };

    return (
        <Container>
            <div className="search">
                <Input type="text" onChange={search} placeholder={"Sök..."} />
            </div>
            <table className="contacts">
                <thead>
                    <tr>
                        <th>
                            <input
                                type="checkbox"
                                checked={
                                    contacts.length > 0 &&
                                    selectedContactIds.length ===
                                        contacts.length
                                }
                                onChange={(e) =>
                                    handleSelectAllChange(e.target.checked)
                                }
                            />
                        </th>
                        <th>Namn</th>
                        <th>Land</th>
                        <th>Ort</th>
                        <th>Kontaktperson</th>
                        <th>Adress</th>
                    </tr>
                </thead>
                <tbody>
                    {contacts.map((contact) => (
                        <tr
                            key={contact.id}
                            onClick={() => navigateToContact(contact.id)}
                        >
                            <td onClick={(event) => event.stopPropagation()}>
                                <input
                                    type="checkbox"
                                    checked={selectedContactIds.includes(
                                        contact.id,
                                    )}
                                    onChange={(e) =>
                                        handleCheckboxChange(contact.id)
                                    }
                                />
                            </td>
                            <td>
                                {contact.contact.company ||
                                    contact.contact.name}
                            </td>
                            <td>{contact.countryCode}</td>
                            <td>{contact.postalTown}</td>
                            <td>
                                {!!contact.contact.company
                                    ? contact.contact.name
                                    : ""}
                            </td>
                            <td>
                                {[
                                    contact.addressLine1,
                                    contact.addressLine2,
                                    contact.addressLine3,
                                ]
                                    .filter((v) => v)
                                    .join(", ")}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="buttons">
                {selectedContactIds.length > 0 && (
                    <div
                        className="c-button c-button--ghost remove"
                        onClick={removeSelectedContacts}
                    >
                        Ta bort
                    </div>
                )}
            </div>
            <div>
                <ConfirmModal
                    isOpen={isConfirmModalOpen}
                    setIsOpen={setIsConfirmModalOpen}
                    onConfirm={handleConfirm}
                    title={"Bekräfta borttagning"}
                    description={`Är du säker på att du vill ta bort valda kontakter? (${selectedContactIds.length} st)`}
                />
            </div>
        </Container>
    );
}

export default Component;
