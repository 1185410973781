import React from "react";

const Checkbox = ({ label, onChange, value }) => {
    return (
        <div className="flex flex-col gap-2 items-center" key={value}>
            <label>{label}</label>
            <input
                type="checkbox"
                className="h-5 w-5 rounded-lg accent-red-500"
                onChange={onChange}
                defaultChecked={value}
            />
        </div>
    );
};

export default Checkbox;
