import { DOTS, usePagination } from "hooks/usePagination";
import React, { useEffect } from "react";

const Pagination = ({ page, setPage, maxPage, useUrlParam = true }) => {
    useEffect(() => {
        if (!useUrlParam) {
            return;
        }
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get("page");

        if (page) {
            setPage(parseInt(page));
        }
    }, []);

    useEffect(() => {
        if (!useUrlParam) {
            return;
        }
        window.history.pushState(null, null, `?page=${page}`);
    }, [page]);

    const pagination = usePagination({
        currentPage: page,
        totalPageCount: maxPage,
        siblingCount: 1,
        boundaryPageCount: 1,
    });

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= maxPage) {
            setPage(newPage);
        }
    };

    return (
        <div className="flex justify-center mt-8">
            <div className="flex items-center">
                {pagination.map((pageNumber, index) => {
                    if (pageNumber === DOTS) {
                        return (
                            <button
                                className={`px-3 py-2 mx-1 w-10 h-10 border border-gray-300 rounded-md focus:outline-none bg-white border-buttonColor text-buttonColor cursor-default`}
                                key={index}
                            >
                                ...
                            </button>
                        );
                    }

                    return (
                        <button
                            key={index}
                            className={`px-3 py-2 mx-1 w-10 h-10 border border-gray-300 rounded-md focus:outline-none ${
                                pageNumber === page
                                    ? "bg-white border-buttonColor text-buttonColor cursor-default"
                                    : "text-white bg-buttonColor"
                            }`}
                            onClick={() => handlePageChange(pageNumber)}
                        >
                            {pageNumber}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export default Pagination;
