import { v4 as uuidv4 } from "uuid";
let okValues = ["ja", "j", "yes", "y"];

function parse({ data, fileName }) {
    let orders = [];
    let error = "";
    for (const row of data) {
        if (row.Trackingnummer && row.Benämning) {
            const orderIndex = orders.findIndex(
                (o) => o.trackingNumber === row.Trackingnummer,
            );
            if (!row.Transportör) {
                error =
                    "Transportör saknas. Kolla att all info finns i XML:en!";
            }
            if (orderIndex < 0) {
                orders.push({
                    fileName: fileName,
                    transporter: row.Transportör,
                    trackingNumber: row.Trackingnummer,
                    reference: row.Ref,
                    currentInvoiceNumber: row["Externt fakturanummer"],
                    date: row["Avs. Datum"],
                    title: row.Benämning,
                    sender: row.Avsändare,
                    senderCountry: row.Från,
                    recipient: row.Mottagare,
                    recipientCountry: row.Till,
                    customerPrice: row["Inköpspris"],
                    totalShipmentCost: row.Netto,
                    vat: okValues.indexOf(row.Moms.toLowerCase()) >= 0,
                    surcharge: false,
                    rows: [
                        {
                            title: "Antal kolli: " + row["Antal kolli"],
                            key: uuidv4(),
                        },
                        {
                            title: "Fakturerad vikt: " + row["Vikt"],
                            key: uuidv4(),
                        },
                    ],
                });
            } else {
                orders[orderIndex].rows.push({
                    title: row.Benämning,
                    cost: row.Netto ? row.Netto : undefined,
                    vat: row.Netto
                        ? okValues.indexOf(row.Moms.toLowerCase()) >= 0
                        : undefined,
                    surcharge: false,
                    key: uuidv4(),
                });
            }
        }
    }

    if (error) {
        alert(error);
    }

    return orders.map((order) => {
        return {
            ...order,
            totalShipmentCost: order.rows.reduce((cost, row) => {
                return row.cost ? cost + row.cost : cost;
            }, order.totalShipmentCost),
        };
    });
}

export default {
    parse,
};
