import React from "react";
import { ReactComponent as WarningIcon } from "../../TrackingIcons/WarningIcon.svg";
import { ReactComponent as AcceptedIcon } from "../../TrackingIcons/AcceptedIcon.svg";

const TrackingStep = ({ checked, hasError, text, icon }) => {
    return (
        <div>
            {checked ? (
                hasError ? (
                    <div className="flex flex-wrap items-center justify-center gap-3">
                        <div className="rounded-full border bg-red-300 p-2 md:p-3">
                            <WarningIcon />
                        </div>
                        <div className="whitespace-nowrap text-secondary-800">
                            <p className="text-xs font-bold sm:text-base sm:font-normal">
                                {text}
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-wrap items-center justify-center gap-3">
                        <div className="rounded-full border bg-trackGreen p-2 md:p-3">
                            <AcceptedIcon />
                        </div>
                        <div className="whitespace-nowrap font-bold text-secondary-800">
                            <p className="text-xs  sm:text-base sm:font-bold">
                                {text}
                            </p>
                        </div>
                    </div>
                )
            ) : (
                <div className="flex flex-wrap items-center justify-center gap-3">
                    <div className="relative rounded-full border bg-secondary-500 p-2 md:p-3">
                        {icon}
                    </div>
                    <div className="whitespace-nowrap text-secondary-800">
                        <p className="text-xs font-bold sm:text-base sm:font-normal">
                            {text}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TrackingStep;
