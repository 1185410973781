import InputCheckbox from "components/Input/Checkbox";
import InputText from "components/Input/Text";
import Context from "context/Global";
import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

function Row(props) {
    const context = useContext(Context);
    const [title, setTitle] = useState(props.row?.title || "");
    const [cost, setCost] = useState(props.row?.cost || "");
    const [vat, setVat] = useState(props.row?.vat || false);
    const [key] = useState(props.row?.key || uuidv4());
    const [reference, setReference] = useState(props.row?.reference || "");

    useEffect(() => {
        if (props.onChange) {
            props.onChange({
                index: props.index,
                reference,
                title,
                cost: parseFloat(cost) || 0,
                vat,
                key,
            });
        }
    }, [title, cost, vat, reference]);

    return (
        <>
            <div className="cell"></div>
            <div className="cell">
                {reference !== null ? (
                    <InputText value={reference} onChange={setReference} />
                ) : null}
            </div>
            <div className="cell"></div>
            <div className="cell">
                <InputText value={title} onChange={setTitle} />
            </div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cell">
                <InputText value={cost} onChange={setCost} align="right" />
            </div>
            <div className="cell checkbox">
                <InputCheckbox value={vat} onChange={setVat} />
            </div>
            <div className="cell center">
                <div className="icon remove" onClick={props.onRemove}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
                    </svg>
                </div>
            </div>
        </>
    );
}

export default Row;
