import Api from "../../../../../services/Api/Api";

export const getAllContacts = async (customerId) => {
    const contacts = await Api.searchContacts({
        customerId: customerId,
    });

    return sortContactsByName(contacts);
};

export const searchContacts = async (customerId, searchString) => {
    const contacts = await Api.searchContacts({
        customerId: customerId,
        searchstring: searchString,
    });

    return sortContactsByName(contacts);
};

export const getFilteredContacts = async (
    customerId,
    receiver,
    limit,
    page,
) => {
    return await Api.getReceiverPaysContacts({
        customerId: customerId,
        receiverSearchString: receiver || undefined,
        limit: limit,
        offset: page * limit - limit,
    });
};

export function sortContactsByName(contacts) {
    return contacts.sort((a, b) => {
        let nameA = a.company?.length > 0 ? a.company : a.name;
        let nameB = b.company?.length > 0 ? b.company : b.name;
        return nameA.localeCompare(nameB);
    });
}
