import Context from "context/Global";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import SelectAll from "./SelectAll";
import TopBar from "./TopBar/TopBar";
import BottomBar from "./BottomBar/BottomBar";
import toast from "react-hot-toast";
import EditSingleOrderModal from "../modals/EditSingleOrderModal";
import Order from "./Order/Order";
import FilterSearchBar from "./FilterSearchBar";
import { BATCH_ORDER } from "constants/batch/batchConstants";
import useBatchStore from "../store";
import { hasQuotesExpired } from "utils/batchTableUtils";
import useOrdersAndSelectedUpdater from "hooks/batch/useOrdersAndSelectedUpdater";
import DisplayMessage from "./DisplayMessage";
import EditNotificationSettingsModal from "../modals/EditNotificationSettingsModal";

const BatchTable = () => {
    const context = useContext(Context);
    const [count, setCount] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedSingleOrder, setSelectedSingleOrder] = useState({});
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [isEditSingleOrderModalOpen, setIsEditSingleOrderModalOpen] =
        useState(false);
    const [ordersEditNotificationInfo, setOrdersEditNotificationInfo] =
        useState({ orders: null, notificationType: null });
    const [searchQuery, setSearchQuery] = useState("");
    const [filter, setFilter] = useState("");
    const [sort, setSort] = useState("");
    const orders = useBatchStore((state) => state.orders);
    const setOrders = useBatchStore((state) => state.setOrders);
    const setSelectedOrders = useBatchStore((state) => state.setSelectedOrders);
    const selectedOrders = useBatchStore((state) => state.selectedOrders);
    const { updateOrdersAndSelectedOrders } = useOrdersAndSelectedUpdater();

    const addAllOrdersToSelectedOrders = useBatchStore(
        (state) => state.addAllOrdersToSelectedOrders,
    );
    const removeAllOrdersFromSelectedOrders = useBatchStore(
        (state) => state.removeAllOrdersFromSelectedOrders,
    );

    const isAllOrdersSelected = orders?.every((order) =>
        selectedOrders.some((item) => item.id === order.id),
    );

    const selectSingleShipment = useCallback(
        (order) => {
            if (order.state === "loading") {
                return;
            }

            setSelectedSingleOrder(order);
            setIsEditSingleOrderModalOpen(true);
        },
        [orders],
    );

    useEffect(() => {
        return () => {
            setSelectedOrders([]);
        };
    }, []);

    useEffect(() => {
        setPage(1);
    }, [limit, searchQuery]);

    useEffect(() => {
        getBatchOrders();
    }, [page, limit, searchQuery, filter, sort]);

    useEffect(() => {
        if (selectAll && !isAllOrdersSelected) {
            return addAllOrdersToSelectedOrders();
        } else if (!selectAll && isAllOrdersSelected) {
            return removeAllOrdersFromSelectedOrders();
        }
    }, [selectAll]);

    useEffect(() => {
        if (!!orders.length && isAllOrdersSelected) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [selectedOrders, orders]);

    useEffect(() => {
        const checkForOrdersWithExpiredQuotes = setInterval(() => {
            const ordersWithExpiredQuotes = orders?.filter(
                (order) =>
                    hasQuotesExpired(order) && order.quotesExpired !== true,
            );

            if (ordersWithExpiredQuotes.length) {
                updateOrdersAndSelectedOrders(
                    ordersWithExpiredQuotes.map((order) => {
                        return {
                            ...order,
                            quotesExpired: hasQuotesExpired(order),
                        };
                    }),
                );
            }
        }, 20000);

        return () => {
            clearInterval(checkForOrdersWithExpiredQuotes);
        };
    }, [orders]);

    async function getBatchOrders() {
        try {
            const res = await Api.getBatchOrders({
                customerId: context.user.customer.id,
                limit: limit,
                offset: page * limit - limit,
                searchQuery: searchQuery,
                filterQuery: filter,
                sortQuery: sort,
            });

            if (selectedOrders.length > 0) {
                const lookup = selectedOrders.reduce((acc, item) => {
                    acc[item.id] = {
                        state: item.state,
                        error: item.error,
                    };

                    return acc;
                }, {});

                const ordersWithPreviousState = res.orders?.map((order) => {
                    const selectedOrder = lookup[order.id];

                    return {
                        ...order,
                        state: selectedOrder?.state,
                        error: selectedOrder?.error,
                        quotesExpired: hasQuotesExpired(order),
                    };
                });

                setOrders(ordersWithPreviousState);
            } else {
                setOrders(
                    res.orders?.map((order) => {
                        return {
                            ...order,
                            quotesExpired: hasQuotesExpired(order),
                        };
                    }),
                );
            }
            setCount(res.count);
        } catch (error) {
            toast.error(
                "Något gick fel när vi hämtade dina ordrar. Kontakta kundservice om problemet kvarstår.",
            );
        }
    }

    const headings = [
        <SelectAll
            onChange={setSelectAll}
            value={selectAll}
            disabled={selectedOrders.some(
                (order) => order.state === BATCH_ORDER.State.Loading,
            )}
        />,
        "Avsändare",
        "Mottagare",
        "Gods",
        "Information",
        "Tjänster",
        // Empty heading used for notification settings icon column
        " ",
        "Källa",
    ];

    return (
        <div className="flex flex-col p-3 relative bg-white ">
            <TopBar
                getBatchOrders={getBatchOrders}
                openEditNotificationModal={(notificationType) => {
                    setOrdersEditNotificationInfo({
                        orders: selectedOrders,
                        notificationType,
                    });
                }}
            />
            <FilterSearchBar
                setSearchQuery={setSearchQuery}
                setFilter={setFilter}
                setLimit={setLimit}
                setSort={setSort}
                limit={limit}
            />
            <DisplayMessage />
            <table className="overflow-x-auto whitespace-nowrap">
                <thead className="top-56 z-10 pr-10">
                    <tr className="border-b border-secondary-500">
                        {headings?.map((heading, index) => (
                            <th
                                key={index}
                                className={
                                    heading === "Källa"
                                        ? `bg-gray-50 py-3 text-center`
                                        : `bg-gray-50 py-3`
                                }
                            >
                                {heading}
                            </th>
                        ))}
                        <th className="bg-gray-50 py-3 "></th>
                    </tr>
                </thead>
                <tbody>
                    {orders?.map((order, index) => (
                        <Order
                            key={index}
                            order={order}
                            selectSingleShipment={selectSingleShipment}
                            openEditNotificationModal={(orders) => {
                                setOrdersEditNotificationInfo({
                                    orders,
                                    notificationType: null,
                                });
                            }}
                        />
                    ))}
                </tbody>
            </table>
            {!orders.length && (
                <div className=" flex flex-col p-4 m-auto font-medium justify-center bg-white">
                    <span>
                        Vi kan inte hitta några ordrar som passar det du sökte
                        på.
                    </span>
                    <span> Redigera sökinställningarna och försök igen</span>
                </div>
            )}
            {isEditSingleOrderModalOpen && (
                <EditSingleOrderModal
                    setIsOpen={setIsEditSingleOrderModalOpen}
                    isOpen={isEditSingleOrderModalOpen}
                    order={selectedSingleOrder}
                />
            )}
            {ordersEditNotificationInfo && (
                <EditNotificationSettingsModal
                    ordersToEdit={ordersEditNotificationInfo.orders}
                    notificationType={
                        ordersEditNotificationInfo.notificationType
                    }
                    setOrdersToEdit={(orders) => {
                        setOrdersEditNotificationInfo({
                            orders,
                            notificationType: null,
                        });
                    }}
                />
            )}

            {!!orders.length && (
                <BottomBar
                    setPage={setPage}
                    page={page}
                    maxPage={
                        Math.ceil(count / limit) > 0
                            ? Math.ceil(count / limit)
                            : 1
                    }
                />
            )}
        </div>
    );
};

export default BatchTable;
