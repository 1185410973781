import { useContext } from "react";
import Api from "../../services/Api/Api";
import toast from "react-hot-toast";
import Context from "../../context/Global";
import useOrdersAndSelectedUpdater from "./useOrdersAndSelectedUpdater";
import { hasQuotesExpired, mustRevalidate } from "utils/batchTableUtils";
import useBatchStore from "views/Batch/store";

const useSaveOrders = () => {
    const context = useContext(Context);
    const { updateOrdersAndSelectedOrders } = useOrdersAndSelectedUpdater();
    const orders = useBatchStore((state) => state.orders);
    const selectedOrders = useBatchStore((state) => state.selectedOrders);

    /**
     * @description Saves the orders to the backend.
     * @param {Array} ordersToUpdate - The orders to save.
     * @returns {Promise<Array>} orders
     */
    const saveOrders = async (ordersToUpdate) => {
        try {
            const ordersLookup = orders.reduce((acc, order) => {
                acc[order.id] = order;
                return acc;
            }, {});

            const selectedOrdersLookup = selectedOrders.reduce((acc, order) => {
                acc[order.id] = order;
                return acc;
            }, {});

            const ordersToSave = ordersToUpdate.map((orderToUpdate) => {
                const order =
                    ordersLookup[orderToUpdate.id] ||
                    selectedOrdersLookup[orderToUpdate.id];

                if (
                    order?.data?.selectedQuote &&
                    order?.data?.quotations &&
                    mustRevalidate(order, orderToUpdate)
                ) {
                    return {
                        ...orderToUpdate,
                        data: {
                            ...orderToUpdate.data,
                            selectedQuote: undefined,
                            quotations: undefined,
                            servicePoints: undefined,
                        },
                    };
                } else {
                    return orderToUpdate;
                }
            });

            const res = await Api.updateBatchOrders({
                orders: ordersToSave,
                customerId: context.user.customer.id,
            });

            if (res.orders && res.orders.length > 0) {
                updateOrdersAndSelectedOrders(
                    res.orders.map((order) => {
                        return {
                            ...order,
                            quotesExpired: hasQuotesExpired(order),
                        };
                    }),
                );

                toast.success("Försändelserna har sparats.");
            }

            return res.orders;
        } catch (error) {
            console.error(error);

            toast.error(
                "Ett fel uppstod medans försändelserna sparades, försök igen eller kontakta kundtjänst.",
            );
        }
    };

    return { saveOrders };
};

export default useSaveOrders;
