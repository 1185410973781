import Input from "components/OldInput";
import FileList from "components/FileList";
import InputNumber from "components/Input/Number2";
import Context from "context/Global";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import OrderSummary from "views/Orders/View/Summary";
import Chat from "./Chat";
import toast from "react-hot-toast";

let Container = styled.div`
    padding: 1rem;
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
    height: 100%;
    gap: 1rem;

    > .ticket,
    > .information,
    > .order {
        flex: 0 1 100%;
    }

    > .ticket {
        align-self: stretch;
        display: flex;
        flex-direction: column;

        > .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > .title {
                font-size: 2rem;
                color: #777;
                margin-bottom: 1rem;
                width: 100%;
            }
        }
    }

    > .order {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 1rem;

        > .summary {
            overflow: auto;
        }

        > .images {
            flex-shrink: 0;

            > .title {
                font-size: 2rem;
                color: #777;
                margin-bottom: 1rem;
            }

            > .list {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(60px, 80px));
                grid-gap: 1rem;

                > img {
                    background-color: #eee;
                    max-width: 80px;
                    max-height: 80px;
                }
            }
        }
    }

    .ticketDetails {
        display: flex;
        flex-direction: column;

        > table {
            th {
                vertical-align: top;
                padding-right: 1rem;
            }

            .divide td {
                padding-bottom: 2rem;
            }
        }

        .customer {
            display: flex;
            gap: 1rem;

            > .change {
                height: 16px;
                width: 16px;
                cursor: pointer;

                > svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        > .copy {
            display: flex;
            align-self: flex-start;
            align-items: center;
            width: auto;
            gap: 0.5rem;
            font-size: 0.8rem;
            cursor: pointer;
            padding: 1rem 0.5rem;
            border-radius: 1rem;
            position: relative;

            &:hover {
                background: rgba(0, 0, 0, 0.2);
            }

            > .icon {
                transition: all 0.3s ease;
            }

            > .check {
                position: absolute;
                color: #00c300;
                opacity: 0;
                transition: all 0.3s ease;
            }

            &.is-copied {
                > .icon {
                    opacity: 0.2;
                }

                > .check {
                    opacity: 1;
                }
            }
        }
    }

    > .information {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
        gap: 1rem;

        > .edit {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            > .title {
                font-weight: bold;
            }

            > .postphone {
                display: flex;
                gap: 1rem;
                flex-wrap: wrap;

                > .c-button + .c-button {
                    margin-left: 0;
                }

                > .close {
                    border-color: rgba(200, 50, 50, 1);
                    color: rgba(200, 50, 50, 1);

                    &:hover {
                        background: rgba(200, 50, 50, 0.2);
                    }
                }
            }
        }

        > .description {
            padding-top: 1rem;
            height: 50%;
            display: flex;
            flex-direction: column;
            word-break: break-all;

            > .title {
                font-size: 1.5rem;

                > svg {
                    cursor: pointer;
                }
            }

            > .view,
            > .emptyState {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                > .content {
                }
            }

            > .edit {
                flex: 1 1 100%;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                > .Input {
                    flex: 1 1 100%;

                    > div {
                        height: 100%;

                        > textarea {
                            height: 100%;
                        }
                    }
                }

                > .buttons {
                    flex-shrink: 1;
                }
            }

            > .emptyState {
                > .text {
                    font-style: italic;
                }
            }
        }
    }

    > .buttons {
        padding: 1rem 0;
    }
`;

function Component({ ticketId, ...props }) {
    const context = useContext(Context);
    const [ticket, setTicket] = useState(null);
    const [messages, setMessages] = useState(null);
    const [order, setOrder] = useState(null);
    const [postphoneButtons, setPostphoneButtons] = useState([]);
    const [description, setDescription] = useState("");
    const [editDescription, setEditDescription] = useState(false);
    const [currentDescriptionValue, setCurrentDescriptionValue] = useState("");
    const [changingCustomer, setChangingCustomer] = useState(false);
    const [changeCustomerList, setChangeCustomerList] = useState([]);
    const [supportUsers, setSupportUsers] = useState([]);
    const [copyDone, setCopyDone] = useState(false);
    const { t, i18n } = useTranslation();
    const textareaRef = useRef(null);

    useEffect(() => {
        context.socket.emit("openTicket", ticketId, (ticket, messages) => {
            setMessages(messages);
            setTicket(ticket);
        });

        context.socket.on(`ticketUpdated`, updateTicket);

        const buttons = [];
        if (
            moment()
                .set("hour", 15)
                .set("minute", 0)
                .set("second", 0)
                .diff(moment()) > 0
        ) {
            buttons.push({
                timestamp: moment()
                    .set("hour", 15)
                    .set("minute", 0)
                    .set("second", 0)
                    .format("YYYY-MM-DD HH:mm:ss"),
                text: "I eftermiddag 15:00",
            });
        }
        if (moment().add(1, "day").isoWeekday() > 5) {
            buttons.push({
                timestamp: moment()
                    .startOf("week")
                    .add(1, "week")
                    .set("hour", 9)
                    .set("minute", 0)
                    .set("second", 0)
                    .format("YYYY-MM-DD HH:mm:ss"),
                text: "Måndag",
            });
        } else {
            buttons.push({
                timestamp: moment()
                    .add(1, "day")
                    .set("hour", 9)
                    .set("minute", 0)
                    .set("second", 0)
                    .format("YYYY-MM-DD HH:mm:ss"),
                text: "Imorgon 09:00",
            });
        }

        [2, 3, 4, 5]
            .map((offset) => {
                if (moment().add(offset, "days").isoWeekday() > 5) {
                    offset += 2;
                } else if (
                    moment().isoWeek() !==
                    moment().add(offset, "days").isoWeek()
                ) {
                    offset += 2;
                }
                return offset;
            })
            .forEach((offset) => {
                buttons.push({
                    timestamp: moment()
                        .add(offset, "days")
                        .set("hour", 9)
                        .set("minute", 0)
                        .set("second", 0)
                        .format("YYYY-MM-DD HH:mm:ss"),
                    text: moment().add(offset, "days").format("dddd"),
                });
            });

        setPostphoneButtons(buttons);

        if (context.user.brand?.id) {
            Api.getSupportUsers({
                brandId: context.user.brand?.id,
                includeOwners: true,
            }).then((supportUsers) => {
                setSupportUsers(
                    supportUsers.map((user) => {
                        return {
                            title: user.name,
                            value: user.id,
                        };
                    }),
                );
            });
        }

        return () => {
            context.socket.emit("closeTicket", ticketId);
            context.socket.off(`ticketUpdated`);
        };
    }, []);

    function changeAssignedUser(userId) {
        context.socket.emit("updateTicket", {
            ticketId: ticket.id,
            data: {
                assignedTo: userId,
            },
        });

        const user = supportUsers.find((user) => {
            return Number(user.value) === Number(userId);
        });

        if (user) {
            context.socket.emit("sendTicketMessage", {
                message: {
                    type: "ADMIN_NOTE",
                    text: `Byter ansvarig till ${user.title}.`,
                },
                ticketId: ticket.id,
            });
        }
    }

    function updateTicket(updatedTicket) {
        // Update ticket in tickets.
        setTicket(updatedTicket);
    }

    useEffect(() => {
        if (ticket?.trackingNumber || ticket?.data?.trackingNumber) {
            loadOrder();
        }
        if (ticket) {
            setDescription(ticket.description || "");
            setCurrentDescriptionValue(ticket.description || "");
        }

        if (ticket?.type === "RECLAIM") {
            setPostphoneButtons((buttons) => {
                buttons.push({
                    timestamp: moment()
                        .add(25, "days")
                        .set("hour", 9)
                        .set("minute", 0)
                        .set("second", 0)
                        .format("YYYY-MM-DD HH:mm:ss"),
                    text: "25 dagar fram",
                });
                return buttons;
            });
        }
    }, [ticket]);

    const currentUser = supportUsers.find(
        (user) => user.value === context?.user.id,
    );

    const noteTimestamp = `*${
        currentUser ? currentUser.title : `Okänd användare`
    }: ${moment().format("YYYY-MM-DD HH:mm")}:-* `;

    function updateTicketData(ticketData, redirect = false) {
        const data = {
            timestamp: ticket.timestamp
                ? moment(ticket.timestamp).format("YYYY-MM-DD HH:mm:ss")
                : null,
            priority: ticket.priority,
            ...ticketData,
        };

        const addNote = ticketData.timestamp;
        context.socket.emit("updateTicket", {
            ticketId: ticket.id,
            data: data,
        });

        if (addNote) {
            context.socket.emit("sendTicketMessage", {
                message: {
                    type: "ADMIN_NOTE",
                    text: `Vilar ärendet till ${moment(data.timestamp).format(
                        "YYYY-MM-DD",
                    )}.`,
                },
                ticketId: ticket.id,
            });
        }

        if (redirect) {
            props.history.push("/admin/support");
        }
    }

    function updateTicketType(type) {
        if (type) {
            context.socket.emit("updateTicket", {
                ticketId: ticket.id,
                type,
            });
        }
    }

    async function loadOrder() {
        try {
            setOrder(
                await Api.getOrderByTrackingNumber({
                    trackingNumber:
                        ticket?.trackingNumber || ticket.data.trackingNumber,
                }),
            );
        } catch (error) {}
    }

    async function saveDescription() {
        if (description === currentDescriptionValue) {
            setEditDescription(false);
            return;
        }

        context.socket.emit("updateTicket", {
            ticketId: ticket.id,
            data: {
                description: currentDescriptionValue,
            },
        });

        setDescription(currentDescriptionValue);
        setEditDescription(false);
    }

    async function cancelEditDescription() {
        setCurrentDescriptionValue(description);
        setEditDescription(false);
    }

    async function closeTicket() {
        if (confirm("Konfirmera att du verkligen vill stänga ärendet.")) {
            context.socket.emit("updateTicket", {
                ticketId: ticket.id,
                data: {
                    status: "CLOSED",
                },
            });

            context.socket.emit("sendTicketMessage", {
                message: {
                    type: "EVENT",
                    event: `CLOSE`,
                },
                ticketId: ticket.id,
            });

            props.history.push("/admin/support");
        }
    }

    async function remindCustomer() {
        if (confirm("Bekräfta att du verkligen vill påminna kunden.")) {
            const res = await Api.post({
                url: `/api/tickets/${ticket.id}/remind`,
                data: {
                    ticketId: ticket.id,
                },
            });
            if (res.status === 200) {
                context.socket.emit("sendTicketMessage", {
                    message: {
                        type: "ADMIN_NOTE",
                        text: `Kunden påmindes via mail.`,
                    },
                    ticketId: ticket.id,
                });

                toast.success("Kunden påmind via mail.");
            } else {
                toast.error("Fel uppstod när kunden skulle påminnas via mail.");
            }
        }
    }

    async function reopenTicket() {
        if (
            confirm("Konfirmera att du verkligen vill öppna ärendet på nytt.")
        ) {
            context.socket.emit("updateTicket", {
                ticketId: ticket.id,
                data: {
                    status: "ONGOING",
                },
            });

            context.socket.emit("sendTicketMessage", {
                message: {
                    type: "EVENT",
                    event: `REOPEN`,
                },
                ticketId: ticket.id,
            });
        }
    }

    function copyTicketData() {
        const text = [];
        // }).join('\r\n\r\n')

        text.push(`Kund: ${ticket.customerName}`);
        if (ticket.data.contactPhone) {
            text.push(`Telefonnummmer: ${ticket.data.contactPhone}`);
        }
        if (ticket?.trackingNumber || ticket.data.trackingNumber) {
            text.push(
                `Trackingnummer: ${
                    ticket?.trackingNumber || ticket.data.trackingNumber
                }`,
            );
        }
        if (ticket.data.forwardingId) {
            text.push(`Speditionsnummer: ${ticket.data.forwardingId}`);
        }
        text.push(`Skapad av: ${ticket.createdByName}`);
        text.push(`Ärendetyp: ${t(`tickets.type.${ticket.type}`)}`);

        Object.keys(ticket.data).forEach((key) => {
            if (
                ["contactPhone", "trackingNumber", "forwardingId"].indexOf(
                    key,
                ) >= 0
            ) {
                return;
            }
            let value = "";
            if (typeof ticket.data[key] === "string") {
                value = ticket.data[key];
            } else if (typeof ticket.data[key] === "boolean") {
                value = ticket.data[key] ? "Ja" : "Nej";
            } else {
                value = ticket.data[key]?.toString();
            }
            text.push(`${t(`tickets.parameter.${key}`)}: ${value}`);
        });

        navigator.clipboard.writeText(text.join("\r\n"));
        setCopyDone(true);
        setTimeout(() => {
            setCopyDone(false);
        }, 1000);
    }

    async function changeCustomer() {
        setChangingCustomer(true);
        setChangeCustomerList(await Api.getCustomers());
    }

    async function moveToCustomer(customerId) {
        context.socket.emit("updateTicket", {
            ticketId: ticket.id,
            data: {
                customerId: customerId,
            },
        });

        setChangeCustomerList([]);
        setChangingCustomer(false);
    }

    return (
        <Container>
            <div className="ticket">
                {ticket && (
                    <>
                        <div className="header">
                            <div className="title">
                                #{ticket.id}: {ticket.title}
                            </div>
                        </div>
                        {messages && (
                            <Chat ticket={ticket} messages={messages} />
                        )}
                    </>
                )}
            </div>
            <div className="order">
                {ticket && (
                    <div className="ticketDetails">
                        <table>
                            <tbody>
                                {(ticket?.trackingNumber ||
                                    ticket.data?.trackingNumber) && (
                                    <tr>
                                        <th>Trackingnummer</th>
                                        {order?.id ? (
                                            <td>
                                                <Link
                                                    to={`/orders/${order.id}`}
                                                >
                                                    {ticket?.trackingNumber ||
                                                        ticket.data
                                                            .trackingNumber}
                                                </Link>
                                            </td>
                                        ) : (
                                            <td>
                                                {ticket?.trackingNumber ||
                                                    ticket.data.trackingNumber}
                                            </td>
                                        )}
                                    </tr>
                                )}
                                {ticket.data?.forwardingId &&
                                    context.user.brand?.id && (
                                        <tr>
                                            <th>Speditionsnummer</th>
                                            <td>
                                                <Link
                                                    to={`/spedition/${ticket.data?.forwardingId}`}
                                                >
                                                    {ticket.data?.forwardingId}
                                                </Link>
                                            </td>
                                        </tr>
                                    )}
                                {context.user.brand?.id && (
                                    <>
                                        <tr>
                                            <th>Kund</th>
                                            <td>
                                                <div className="customer">
                                                    {changingCustomer ? (
                                                        <>
                                                            {!!changeCustomerList.length ? (
                                                                <div className="customer">
                                                                    <Input
                                                                        type="dropdown"
                                                                        onChange={
                                                                            moveToCustomer
                                                                        }
                                                                        value={
                                                                            ticket.customerId
                                                                        }
                                                                        options={changeCustomerList.map(
                                                                            (
                                                                                customer,
                                                                            ) => {
                                                                                return {
                                                                                    title: customer.name,
                                                                                    value: customer.id,
                                                                                };
                                                                            },
                                                                        )}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div className="customer">
                                                                    Loading
                                                                    customers...
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Link
                                                                to={`/admin/customers/${ticket.customerId}`}
                                                            >
                                                                {
                                                                    ticket.customerName
                                                                }
                                                            </Link>
                                                            <div
                                                                className="change"
                                                                onClick={
                                                                    changeCustomer
                                                                }
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                        {ticket.data?.contactPhone && (
                                            <tr>
                                                <th>Telefonnummmer</th>
                                                <td>
                                                    <Link
                                                        to={`tel:${ticket.data.contactPhone}`}
                                                    >
                                                        {
                                                            ticket.data
                                                                .contactPhone
                                                        }
                                                    </Link>
                                                </td>
                                            </tr>
                                        )}
                                        <tr>
                                            <th>Skapad av</th>
                                            <td>{ticket.createdByName}</td>
                                        </tr>
                                        <tr>
                                            <th>Ansvarig</th>
                                            <td>
                                                {supportUsers.length && (
                                                    <Input
                                                        type="dropdown"
                                                        onChange={
                                                            changeAssignedUser
                                                        }
                                                        value={
                                                            ticket.assignedToId ||
                                                            "none"
                                                        }
                                                        options={[
                                                            !ticket.assignedToId
                                                                ? {
                                                                      value: "none",
                                                                      title: "Ingen ansvarig",
                                                                  }
                                                                : null,
                                                            ...supportUsers,
                                                        ].filter(Boolean)}
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    </>
                                )}
                                <tr className="divide">
                                    <th>Ärendetyp</th>
                                    {context.user.brand ? (
                                        <td>
                                            <Input
                                                type="dropdown"
                                                onChange={updateTicketType}
                                                options={[
                                                    {
                                                        title: "Problem vid bokning",
                                                        value: "BOOKING",
                                                    },
                                                    {
                                                        title: "Fråga om leverans",
                                                        value: "SEARCH",
                                                    },
                                                    {
                                                        title: "Fakturafråga",
                                                        value: "INVOICE",
                                                    },
                                                    {
                                                        title: "Reklamation",
                                                        value: "RECLAIM",
                                                    },
                                                    {
                                                        title: "Spedition",
                                                        value: "FORWARDING",
                                                    },
                                                    {
                                                        title: "Övrigt",
                                                        value: "OTHER",
                                                    },
                                                ]}
                                                value={ticket.type}
                                            />
                                        </td>
                                    ) : (
                                        <td>
                                            {t(`tickets.type.${ticket.type}`)}
                                        </td>
                                    )}
                                </tr>
                                {Object.keys(ticket.data).map((key) => {
                                    if (
                                        [
                                            "contactPhone",
                                            "trackingNumber",
                                            "forwardingId",
                                        ].indexOf(key) >= 0
                                    ) {
                                        return;
                                    }
                                    let value = "";
                                    if (typeof ticket.data[key] === "string") {
                                        value = ticket.data[key];
                                    } else if (
                                        typeof ticket.data[key] === "boolean"
                                    ) {
                                        value = ticket.data[key] ? "Ja" : "Nej";
                                    } else {
                                        value = ticket.data[key]?.toString();
                                    }
                                    return (
                                        <tr key={key}>
                                            <th>
                                                {t(`tickets.parameter.${key}`)}
                                            </th>
                                            <td>{value}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        {context.user.brand?.id && (
                            <div
                                className={`copy ${
                                    copyDone ? "is-copied" : ""
                                }`}
                                onClick={copyTicketData}
                            >
                                <svg
                                    className="icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M18 6v-6h-18v18h6v6h18v-18h-6zm-12 10h-4v-14h14v4h-10v10zm16 6h-14v-14h14v14z" />
                                </svg>
                                Kopiera informationen ovan
                                <svg
                                    className="check"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
                                    />
                                </svg>
                            </div>
                        )}
                    </div>
                )}
                <div className="images">
                    <div className="title">Uppladdade filer</div>
                    {ticket?.uploads && (
                        <div className="list">
                            <FileList
                                files={ticket?.uploads?.map((file) => {
                                    return {
                                        key: file.id,
                                        url: file.path,
                                        mimetype: file.mimetype,
                                    };
                                })}
                            />
                        </div>
                    )}
                </div>
                {order && (
                    <div className="summary">
                        <OrderSummary order={order} />
                    </div>
                )}
            </div>
            {context.user.brand?.id && (
                <div className="information">
                    {ticket && context.user.brand?.id && (
                        <>
                            <div className="edit">
                                <div className="title">Prioritet</div>
                                <InputNumber
                                    min={1}
                                    max={9}
                                    value={ticket.priority || 5}
                                    onChange={(value) => {
                                        updateTicketData({
                                            priority: value,
                                        });
                                    }}
                                />
                                <div className="title">Vila ärendet</div>
                                <div className="postphone">
                                    {ticket.status !== "CLOSED" &&
                                        postphoneButtons.map((button) => {
                                            return (
                                                <button
                                                    className="c-button c-button--raised"
                                                    key={button.timestamp}
                                                    onClick={() => {
                                                        updateTicketData(
                                                            {
                                                                timestamp:
                                                                    button.timestamp,
                                                            },
                                                            true,
                                                        );
                                                    }}
                                                    disabled={
                                                        button.timestamp ===
                                                        moment(
                                                            ticket.timestamp,
                                                        ).format(
                                                            "YYYY-MM-DD HH:mm:ss",
                                                        )
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    {button.text}
                                                </button>
                                            );
                                        })}
                                    {ticket.status === "CLOSED" ? (
                                        <button
                                            className="c-button c-button--ghost"
                                            onClick={reopenTicket}
                                        >
                                            Öppna
                                        </button>
                                    ) : (
                                        <button
                                            className="c-button c-button--ghost close"
                                            onClick={closeTicket}
                                        >
                                            Stäng
                                        </button>
                                    )}
                                </div>
                                <div className="title">Verktyg</div>
                                <div className="postphone">
                                    <button
                                        className="c-button c-button--raised"
                                        onClick={() => remindCustomer()}
                                    >
                                        Påminn kund via mail
                                    </button>
                                </div>
                            </div>

                            <div className="description">
                                {!editDescription && (
                                    <>
                                        <div
                                            className="title"
                                            onClick={() =>
                                                setEditDescription(true)
                                            }
                                        >
                                            Anteckningar{" "}
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M7.127 22.564l-7.126 1.436 1.438-7.125 5.688 5.689zm-4.274-7.104l5.688 5.689 15.46-15.46-5.689-5.689-15.459 15.46z"
                                                />
                                            </svg>
                                        </div>
                                        {description && (
                                            <div
                                                className="view"
                                                onClick={() =>
                                                    setEditDescription(true)
                                                }
                                            >
                                                <div className="content">
                                                    <ReactMarkdown
                                                        renderers={{
                                                            link: (props) => (
                                                                <a
                                                                    href={
                                                                        props.href
                                                                    }
                                                                    target="_blank"
                                                                >
                                                                    {
                                                                        props.children
                                                                    }
                                                                </a>
                                                            ),
                                                        }}
                                                    >
                                                        {description}
                                                    </ReactMarkdown>
                                                </div>
                                            </div>
                                        )}
                                        {!description && (
                                            <div
                                                className="emptyState"
                                                onClick={() =>
                                                    setEditDescription(true)
                                                }
                                            >
                                                <div className="text">
                                                    Inga anteckningar för detta
                                                    ärende
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                                {editDescription && (
                                    <>
                                        <div className="title">
                                            Anteckningar
                                        </div>
                                        <div className="edit">
                                            <Input
                                                type="textarea"
                                                textAreaRef={textareaRef}
                                                onChange={
                                                    setCurrentDescriptionValue
                                                }
                                                value={noteTimestamp.concat(
                                                    "\n\n\n ",
                                                    description,
                                                )}
                                            />
                                            <div className="buttons">
                                                <div
                                                    className="c-button c-button--raised"
                                                    onClick={saveDescription}
                                                >
                                                    Spara
                                                </div>
                                                <div
                                                    className="c-button c-button--ghost"
                                                    onClick={
                                                        cancelEditDescription
                                                    }
                                                >
                                                    Avbryt
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
        </Container>
    );
}

export default Component;
