import React from "react";
const TrackingLine = ({ checked }) => {
    return (
        <>
            {checked ? (
                <div className="order-1 mb-6 h-0 w-3 border border-trackGreen sm:mb-8 sm:w-10 md:w-20 lg:mb-0 lg:w-16 xl:w-16"></div>
            ) : (
                <div className="order-1 mb-6 h-0 w-3 border border-dashed border-secondary-700 sm:mb-8 sm:w-10 md:w-20 lg:mb-0 lg:w-16 xl:w-16"></div>
            )}
        </>
    );
};

export default TrackingLine;
