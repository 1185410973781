import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    > .title {
        padding: 0 1rem;
        font-size: 1.5rem;
    }

    > .description {
        padding: 0 1rem;
    }

    > .layouts {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
        padding: 1rem;

        > .layout {
            > .title {
                text-align: center;
            }

            > .icon {
                height: 200px;
                color: #ddd;
                cursor: pointer;

                > svg {
                    border: 2px solid #ccc;
                    background: #ccc;
                    height: 100%;
                    width: auto;
                    box-sizing: border-box;
                    color: #eee;
                }
            }

            &:hover {
                > .icon {
                    > svg {
                        color: #fff;
                    }
                }
            }

            &.is-selected {
                > .icon {
                    > svg {
                        border: 2px solid #555;
                        color: #fff;
                        background: #dadada;
                    }
                }
            }
        }
    }

    > .buttons {
        padding: 1rem;
    }
`;

let Form = styled.form`
    padding: 16px;

    > .buttons {
        padding: 16px 0;
    }
`;

const a4LayoutOptions = [
    {
        title: "1 liggande",
        value: "a4",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8.26667in"
                height="11.6933in"
                viewBox="0 0 2480 3508"
            >
                <path
                    fill="currentColor"
                    stroke="#eee"
                    strokeWidth="5"
                    d="M 123.00,195.00 C 123.00,195.00 2357.00,195.00 2357.00,195.00 2357.00,195.00 2357.00,1678.00 2357.00,1678.00 2357.00,1678.00 123.00,1678.00 123.00,1678.00 123.00,1678.00 123.00,195.00 123.00,195.00 Z"
                />
            </svg>
        ),
    },
    {
        title: "2 liggande",
        value: "a4-2-landscape",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8.26667in"
                height="11.6889in"
                viewBox="0 0 744 1052"
            >
                <path
                    fill="currentColor"
                    stroke="#eee"
                    strokeWidth="5"
                    d="M 36.90,552.48 C 36.90,552.48 707.10,552.48 707.10,552.48 707.10,552.48 707.10,997.21 707.10,997.21 707.10,997.21 36.90,997.21 36.90,997.21 36.90,997.21 36.90,552.48 36.90,552.48 Z M 36.90,58.48 C 36.90,58.48 707.10,58.48 707.10,58.48 707.10,58.48 707.10,503.21 707.10,503.21 707.10,503.21 36.90,503.21 36.90,503.21 36.90,503.21 36.90,58.48 36.90,58.48 Z"
                />
            </svg>
        ),
    },
    {
        title: "3 liggande",
        value: "a4-3-landscape",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8.26667in"
                height="11.6933in"
                viewBox="0 0 2480 3508"
            >
                <path
                    fill="currentColor"
                    stroke="#eee"
                    strokeWidth="5"
                    d="M 312.00,141.00 C 312.00,141.00 2168.00,141.00 2168.00,141.00 2168.00,141.00 2168.00,1030.00 2168.00,1030.00 2168.00,1030.00 312.00,1030.00 312.00,1030.00 312.00,1030.00 312.00,141.00 312.00,141.00 Z M 312.00,1307.00 C 312.00,1307.00 2168.00,1307.00 2168.00,1307.00 2168.00,1307.00 2168.00,2196.00 2168.00,2196.00 2168.00,2196.00 312.00,2196.00 312.00,2196.00 312.00,2196.00 312.00,1307.00 312.00,1307.00 Z M 312.00,2470.00 C 312.00,2470.00 2168.00,2470.00 2168.00,2470.00 2168.00,2470.00 2168.00,3359.00 2168.00,3359.00 2168.00,3359.00 312.00,3359.00 312.00,3359.00 312.00,3359.00 312.00,2470.00 312.00,2470.00 Z"
                />
            </svg>
        ),
    },
    {
        title: "2 stående",
        value: "a4-2-portrait",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8.26667in"
                height="11.6889in"
                viewBox="0 0 744 1052"
            >
                <path
                    fill="currentColor"
                    stroke="#eee"
                    strokeWidth="5"
                    d="M 39.60,44.32 C 39.60,44.32 332.70,44.32 332.70,44.32 332.70,44.32 332.70,689.68 332.70,689.68 332.70,689.68 39.60,689.68 39.60,689.68 39.60,689.68 39.60,44.32 39.60,44.32 Z M 411.30,44.32 C 411.30,44.32 704.40,44.32 704.40,44.32 704.40,44.32 704.40,689.68 704.40,689.68 704.40,689.68 411.30,689.68 411.30,689.68 411.30,689.68 411.30,44.32 411.30,44.32 Z"
                />
            </svg>
        ),
    },
    {
        title: "2 stående",
        value: "a4-2-portrait-center",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8.26667in"
                height="11.6933in"
                viewBox="0 0 2480 3508"
            >
                <path
                    fill="currentColor"
                    stroke="#eee"
                    strokeWidth="5"
                    d="M 132.00,678.00 C 132.00,678.00 1109.00,678.00 1109.00,678.00 1109.00,678.00 1109.00,2830.00 1109.00,2830.00 1109.00,2830.00 132.00,2830.00 132.00,2830.00 132.00,2830.00 132.00,678.00 132.00,678.00 Z M 1371.00,678.00 C 1371.00,678.00 2348.00,678.00 2348.00,678.00 2348.00,678.00 2348.00,2830.00 2348.00,2830.00 2348.00,2830.00 1371.00,2830.00 1371.00,2830.00 1371.00,2830.00 1371.00,678.00 1371.00,678.00 Z"
                />
            </svg>
        ),
    },
    {
        title: "1 stående",
        value: "a4-1-portrait",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8.26667in"
                height="11.6889in"
                viewBox="0 0 744 1052"
            >
                <path
                    fill="currentColor"
                    stroke="#eee"
                    strokeWidth="5"
                    d="M 39.60,44.32 C 39.60,44.32 332.70,44.32 332.70,44.32 332.70,44.32 332.70,689.68 332.70,689.68 332.70,689.68 39.60,689.68 39.60,689.68 39.60,689.68 39.60,44.32 39.60,44.32 Z"
                />
            </svg>
        ),
    },
];

const printerLayoutOptions = [
    {
        title: "210 x 297 mm",
        value: "label",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8.26667in"
                height="11.6933in"
                viewBox="0 0 2480 3508"
            >
                <path
                    fill="currentColor"
                    stroke="#eee"
                    strokeWidth="5"
                    d="M 327.00,207.00 C 327.00,207.00 2153.00,207.00 2153.00,207.00 2153.00,207.00 2153.00,3301.00 2153.00,3301.00 2153.00,3301.00 327.00,3301.00 327.00,3301.00 327.00,3301.00 327.00,207.00 327.00,207.00 Z"
                />
            </svg>
        ),
    },
    {
        title: "107 x 251 mm",
        value: "107x251",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="4.21333in"
                height="9.88333in"
                viewBox="0 0 1264 2965"
            >
                <path
                    fill="currentColor"
                    stroke="#eee"
                    strokeWidth="5"
                    d="M 118.00,138.00 C 118.00,138.00 1146.00,138.00 1146.00,138.00 1146.00,138.00 1146.00,2827.00 1146.00,2827.00 1146.00,2827.00 118.00,2827.00 118.00,2827.00 118.00,2827.00 118.00,138.00 118.00,138.00 Z"
                />
            </svg>
        ),
    },
    {
        title: "103 x 165 mm",
        value: "103x165",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="4.05667in"
                height="6.49667in"
                viewBox="0 0 1217 1949"
            >
                <path
                    fill="currentColor"
                    stroke="#eee"
                    strokeWidth="5"
                    d="M 121.00,153.00 C 121.00,153.00 1097.00,153.00 1097.00,153.00 1097.00,153.00 1097.00,1798.00 1097.00,1798.00 1097.00,1798.00 121.00,1798.00 121.00,1798.00 121.00,1798.00 121.00,153.00 121.00,153.00 Z"
                />
            </svg>
        ),
    },
];

function Component({ customer, onUpdate, ...props }) {
    const context = useContext(Context);
    const [updating, setUpdating] = useState(false);
    const customerInputRef = useRef();
    const [selectedLayout, setSelectedLayout] = useState(customer.labelFormat);

    async function updateLabelSettings() {
        setUpdating(true);
        await Api.updateCustomer({
            customerId: customer.id,
            customer: {
                labelFormat: selectedLayout,
            },
        });
        onUpdate();
        setUpdating(false);
    }

    return (
        <Container>
            <div className="title">Vanlig skrivare (A4)</div>
            <div className="layouts">
                {a4LayoutOptions.map((layout) => {
                    return (
                        <div
                            className={`layout ${
                                selectedLayout === layout.value
                                    ? "is-selected"
                                    : ""
                            }`}
                            onClick={() => setSelectedLayout(layout.value)}
                        >
                            <div className="title">{layout.title}</div>
                            <div className="icon">{layout.icon}</div>
                        </div>
                    );
                })}
            </div>
            <div className="title">Etikett-skrivare</div>
            <div className="layouts">
                {printerLayoutOptions.map((layout) => {
                    return (
                        <div
                            className={`layout ${
                                selectedLayout === layout.value
                                    ? "is-selected"
                                    : ""
                            }`}
                            onClick={() => setSelectedLayout(layout.value)}
                        >
                            <div className="title">{layout.title}</div>
                            <div className="icon">{layout.icon}</div>
                        </div>
                    );
                })}
            </div>
            <div className="title">Vad ska jag välja?</div>
            <div className="description">
                <ul>
                    <li>
                        <b>Vanlig skrivare (A4)</b>
                    </li>
                    <li>
                        Har ni en A4-skrivare rekommenderar vi att använda
                        etikett-papper med 2 stående eller 3 liggande etiketter.
                    </li>
                    <li>
                        Skriv gärna ut fler etiketter samtidigt via
                        utskriftsfunktionen i "Mina försändelser".
                    </li>
                    <li>
                        Har ni vanligt papper måste ni tejpa fast etiketterna
                        alternativt lägga dem i en plastficka som i sin tur är
                        fasttejpad på kollit.
                    </li>
                    <li>
                        Välj då 1 liggande eller 1 stående om ni inte vill
                        klippa pappret.
                    </li>
                </ul>
                <ul>
                    <li>
                        <b>Etikett-skrivare</b>
                    </li>
                    <li>Välj de mått som passar era etiketter bäst.</li>
                    <li>Testa er fram om ni är osäkra.</li>
                    <li>
                        Hittar ni inte något format som passar er skrivare får
                        ni gärna höra av er till oss så hittar vi en lösning
                        tillsammans.
                    </li>
                </ul>
            </div>
            <div className="buttons">
                <button
                    className="button c-button c-button--raised"
                    disabled={!!updating}
                    onClick={updateLabelSettings}
                >
                    Spara
                </button>
            </div>
        </Container>
    );
}

export default Component;
