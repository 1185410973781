import { isEqual } from "lodash";

export const checkForObjectEquality = (selected, type) => {
    if (!selected.length) return null;

    let controlObject;
    switch (type) {
        case "sender":
            controlObject = selected[0].data.sender;
            break;
        case "receiver":
            controlObject = selected[0].data.receiver;
            break;
        case "information":
            controlObject = selected[0].data.information;
            break;
    }

    const count = selected.reduce((acc, order) => {
        let comparisonObject;

        switch (type) {
            case "sender":
                comparisonObject = order.data.sender;
                break;
            case "receiver":
                comparisonObject = order.data.receiver;
                break;
            case "information":
                comparisonObject = order.data.information;
                break;
        }

        return isEqual(comparisonObject, controlObject) ? acc : acc + 1;
    }, 0);

    return count === 0 ? controlObject : null;
};

export const checkForArrayEquality = (selected, type) => {
    if (!selected.length) return null;
    let controlArray;
    if (type === "package") {
        controlArray = selected[0].data.packages;
    }

    const count = selected.reduce((acc, order) => {
        let comparisonArray;
        if (type === "package") {
            comparisonArray = order.data.packages;
        }

        return isEqual(comparisonArray, controlArray) ? acc : acc + 1;
    }, 0);

    return count === 0 ? controlArray : null;
};
