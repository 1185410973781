import React from "react";

const TextSection = ({ title, paragraphs, className = "" }) => {
    return (
        <div className={`mb-4 ${className}`}>
            <h3 className="text-lg font-semibold mb-2">{title}</h3>
            {paragraphs.map((paragraph, index) => (
                <p key={index} className="mb-2 text-sm">
                    {paragraph}
                </p>
            ))}
        </div>
    );
};

export default TextSection;
