import Input from "components/OldInput";
import axios from "axios";
import React, { Component } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    width: 100%;
    box-sizing: border-box;
    outline: none;
    min-height: 40px;

    > .inputContainer {
        width: 100%;
        position: relative;

        > .backdrop {
            opacity: 0;
            position: fixed;
            z-index: 10;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        > .searchResult {
            max-height: 50vh;
            overflow-y: auto;
            position: absolute;
            opacity: 0;
            pointer-events: none;
            width: 100%;
            background: #fff;
            box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.3);
            border-radius: 5px;
            top: 100%;
            padding-bottom: 8px;
            z-index: 10;

            > .title {
                padding: 4px 8px;
                color: rgba(0, 0, 0, 0.5);
                font-weight: bold;
                font-size: 14px;
            }

            > .item {
                padding: 4px 8px;
                cursor: pointer;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.is-selected {
                    background: rgba(0, 0, 0, 0.04);
                }

                &:hover {
                    background: rgba(0, 0, 0, 0.02);
                }
            }

            &.is-visible {
                opacity: 1;
                pointer-events: all;
            }
        }

        > input {
            padding: 4px;
            border: none;
            outline: none;
            width: 100%;
        }
    }

    .selectedContainer {
        background: rgb(255, 255, 255);
        padding: 4px 8px;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 40px;
        overflow: hidden;

        > .remove {
            height: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            > svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    .countryContainer {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        height: 100%;

        .form-group {
            margin-bottom: 0;

            .dropdown {
                width: 60px;
                min-width: 60px;
                letter-spacing: -1em;

                .c-select {
                    color: rgba(0, 0, 0, 0);
                    border: 0;
                }

                .dropdown-toggle::after {
                    color: rgba(0, 0, 0, 0.4);
                }

                .c-dropdown-menu-overflow {
                    letter-spacing: 0em;
                }
            }
        }
    }

    > .message {
        font-weight: bold;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
    }

    &.is-invalid {
        > .inputContainer {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }

        > .message {
            color: rgba(255, 0, 0, 0.6);
        }
    }
`;

class InputField extends Component {
    searchInputRef = React.createRef();
    cancelSearchToken = React.createRef();
    searchRequestHandle = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            countryCode: props.countryCode || "",
            searchResult: [],
            autoComplete: props.autoComplete,
            disabled: props.disabled,
            name: props.name,
            currentUser: null,
            selectedIndex: null,
        };

        if (typeof this.props.onKeyUp === "function") {
            this.onKeyUp = this.props.onKeyUp;
        }
        this.handleSearch = this.handleSearch.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.deselect = this.deselect.bind(this);
        this.close = this.close.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            countryCode: props.countryCode,
            disabled: props.disabled,
        };
    }

    onKeyUp() {}
    handleInputChange(newValue) {
        const inputValue = newValue.replace(/[^a-zA-Z0-9-]/g, "");
        this.setState({
            inputValue: inputValue,
        });
        return inputValue;
    }

    value() {
        return this.state.value;
    }

    set(value) {}

    empty() {
        this.setState({
            inputValue: "",
            value: "",
            currentUser: null,
        });
        if (this.searchInputRef.current) {
            this.searchInputRef.current.empty();
        }
    }

    setValidationMessage(message) {
        this.searchInputRef.current.setValidationMessage(message);
    }

    validate() {
        if (this.props.required && !this.state.value) {
            this.setState({
                isInvalid: true,
            });
            return false;
        }
        this.setState({
            isInvalid: false,
        });
        return true;
    }

    async handleSearch(inputValue) {
        clearTimeout(this.searchRequestHandle.current);
        if (this.cancelSearchToken.current) {
            this.cancelSearchToken.current.cancel();
            this.cancelSearchToken.current = null;
        }
        this.searchRequestHandle.current = setTimeout(async () => {
            try {
                this.cancelSearchToken.current = axios.CancelToken.source();

                let results = await Api.getClientManagerSuggestions({
                    cancelToken: this.cancelSearchToken.current.token,
                    query: inputValue,
                });

                if (results) {
                    let data = results.map((result) => {
                        return {
                            title: `${result.name} (${result.email})`,
                            value: result.id,
                        };
                    });

                    this.setState({
                        inputValue: inputValue,
                        searchResult: data,
                    });
                }
            } catch (err) {
                // Cancel error appear here which is expected. Maybe catch other errors?
            }
        }, 200);
    }

    handleKeyPress(ev) {
        switch (ev.key) {
            case "Enter": {
                if (this.state.selectedIndex !== null) {
                    this.selectItem(
                        this.state.searchResult[this.state.selectedIndex],
                    );
                    ev.preventDefault();
                }
                break;
            }
            case "Escape": {
                this.close();
                break;
            }
        }
    }

    handleKeyUp(ev) {
        switch (ev.key) {
            case "ArrowDown": {
                if (this.state.selectedIndex === null) {
                    this.setState({
                        selectedIndex: 0,
                    });
                } else {
                    let nextIndex = this.state.selectedIndex + 1;
                    if (nextIndex >= this.state.searchResult.length) {
                        nextIndex = this.state.selectedIndex;
                    }
                    this.setState({
                        selectedIndex: nextIndex,
                    });
                }
                break;
            }
            case "ArrowUp": {
                if (this.state.selectedIndex === null) {
                    this.setState({
                        selectedIndex: this.state.searchResult.length - 1,
                    });
                } else {
                    let nextIndex = this.state.selectedIndex - 1;
                    if (nextIndex < 0) {
                        nextIndex = 0;
                    }
                    this.setState({
                        selectedIndex: nextIndex,
                    });
                }
                break;
            }
        }
    }

    selectItem(item) {
        this.searchInputRef.current.empty();
        this.setState({
            value: item.value,
            currentUser: item.title,
            searchResult: [],
            selectedIndex: null,
        });
        setTimeout(() => {
            this.props.onChange(item.value);
        }, 0);
    }

    deselect() {
        this.setState({
            currentUser: null,
            value: null,
        });
        setTimeout(() => {
            this.props.onChange(null);
        }, 0);
    }

    close() {
        this.setState({
            searchResult: [],
            selectedIndex: null,
        });
    }

    render() {
        return (
            <Container className={this.state.isInvalid ? " is-invalid" : ""}>
                {!this.state.currentUser && (
                    <div className="inputContainer">
                        {parseInt(this.state.searchResult.length) > 0 && (
                            <div
                                className="backdrop"
                                onClick={this.close}
                            ></div>
                        )}
                        <div
                            className={
                                "searchResult" +
                                (parseInt(this.state.searchResult.length) > 0
                                    ? " is-visible"
                                    : "")
                            }
                        >
                            <div className="title">Välj en användare</div>
                            {this.state.searchResult.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={
                                            "item" +
                                            (index === this.state.selectedIndex
                                                ? " is-selected"
                                                : "")
                                        }
                                        onClick={() => {
                                            this.selectItem(item);
                                        }}
                                    >
                                        {item.title}
                                    </div>
                                );
                            })}
                        </div>
                        <Input
                            ref={this.searchInputRef}
                            disabled={this.state.disabled}
                            autoComplete={this.state.autoComplete}
                            placeholder={this.props.placeholder}
                            name={this.state.name}
                            type="text"
                            onKeyPress={this.handleKeyPress}
                            onKeyUp={this.handleKeyUp}
                            onChange={this.handleSearch}
                        />
                    </div>
                )}
                {!!this.state.currentUser && (
                    <div className="selectedContainer">
                        <div className="user">{this.state.currentUser}</div>
                        <div className="remove" onClick={this.deselect}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="currentColor"
                                    d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
                                />
                            </svg>
                        </div>
                    </div>
                )}
            </Container>
        );
    }
}

export default InputField;
