import Modal from "components/Modal";
import useSaveOrders from "hooks/batch/useSaveOrders";
import React, { useState, useEffect, useRef } from "react";
import useBatchStore from "views/Batch/store";
import ShipmentInformation from "views/Drafts/Create/Information";

const InformationModal = ({ setIsOpen, isOpen, commonInformation }) => {
    const [information, setInformation] = useState(commonInformation || {});
    const [loading, setLoading] = useState(false);
    const informationRef = useRef();

    const { saveOrders } = useSaveOrders();
    const selectedOrders = useBatchStore((state) => state.selectedOrders);

    async function save() {
        if (!informationRef.current.validate()) {
            return;
        }

        setLoading(true);
        await saveOrders(
            selectedOrders.map((order) => {
                return {
                    ...order,
                    data: {
                        ...order.data,
                        information: information,
                    },
                };
            }),
        );

        setLoading(false);
        setIsOpen(false);
    }

    //Tobias components are handling input weirdly and therefore we need to reset the
    //information that it has in it's input field when we change if it's open or not
    useEffect(() => {
        if (isOpen) {
            setInformation({});
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title="Information"
            description="Ersätt övrig information för valda försändelser."
            maxWidth="max-w-7xl"
        >
            <ShipmentInformation
                onChange={setInformation}
                isBatchView={true}
                ref={informationRef}
                information={information}
            />
            <div className="flex justify-start mt-4">
                <button
                    className="c-button--raised c-button"
                    onClick={() => save()}
                >
                    {loading ? "Sparar..." : "Ersätt"}
                </button>
                <button
                    className="c-button c-button--ghost delete"
                    onClick={() => setIsOpen(false)}
                >
                    Avbryt
                </button>
            </div>
        </Modal>
    );
};

export default InformationModal;
