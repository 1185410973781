import Input from "components/OldInput";
import axios from "axios";
import InputMultiToggle from "components/Input/MultiToggle";
import InputRadioButton from "components/Input/Radiobutton";
import InputTextSuggestions from "components/Input/TextSuggestions";
import Context from "context/Global";
import React, {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";

let Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 1rem;

    > div {
        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    > .count {
        max-width: 80px;
        min-width: 80px;
    }

    > .dimension {
        max-width: 140px;
        min-width: 140px;

        &.is-hidden {
            pointer-events: none;
            opacity: 0;
        }
    }

    > .description {
        flex-basis: 100%;
        margin: 0;
        margin-bottom: 1rem;

        > .title {
            margin-bottom: 0.5rem;
            padding: 0 0.5rem;

            > .required {
                color: red;
            }
        }

        > .input {
            width: 30%;
            min-width: 200px;
        }
    }

    > .type {
        max-width: 160px;
        min-width: 160px;
    }

    > .text {
        color: rgba(0, 0, 0, 0.5);
        font-style: italic;
    }

    > .stackable {
        margin-left: 16px;
    }

    > .buttons {
        display: flex;
        align-items: center;
        margin-left: auto;

        > .button {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px;
            width: 32px;
            height: 32px;
            color: rgba(0, 0, 0, 0.5);
            cursor: pointer;

            &.delete:hover {
                color: rgba(200, 0, 0, 0.7);
            }

            &.duplicate:hover {
                color: rgba(0, 0, 100, 0.7);
            }

            > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
`;

function parseTest(inc) {
    switch (inc) {
        case "DHL Paket":
            return "dhl-paket";
        case "DHL Stycke":
            return "dhl-stycke";
        case "DHL Pall":
            return "dhl-pall";
        case "DHL Parti":
            return "dhl-parti";
        case "DHL EuroConnect":
            return "dhl-euroconnect";
        case "DHL Euroline":
            return "dhl-euroline";
        case "DHL EuroConnect Plus":
            return "dhl-euroconnect-plus";
        case "DHL Service Point":
            return "dhl-service-point";
        case "DHL Service Point Retur":
            return "dhl-return";
    }
    return null;
}

function Component(props, ref) {
    const context = useContext(Context);
    const [count, setCount] = useState(
        props.value ? props.value.count || 1 : 1,
    );
    const [type, setType] = useState(
        props.value
            ? parseTest(props.value.productSpecificPackage) ||
                  props.value.type ||
                  "package"
            : "package",
    );
    const [length, setLength] = useState(
        props.value ? props.value.length || "" : "",
    );
    const [width, setWidth] = useState(
        props.value ? props.value.width || "" : "",
    );
    const [height, setHeight] = useState(
        props.value ? props.value.height || "" : "",
    );
    const [volume, setVolume] = useState(
        props.value ? props.value.volume || "" : "",
    );
    const [loadingMeters, setLoadingMeters] = useState(
        props.value ? props.value.loadingMeters || "" : "",
    );
    const [weight, setWeight] = useState(
        props.value ? props.value.weight || "" : "",
    );
    const [productSpecificPackage, setProductSpecificPackage] = useState(
        props.value?.productSpecificPackage || null,
    );
    const [description, setDescription] = useState("");
    const [stackable, setStackable] = useState(
        props.value ? !!props.value.stackable : true,
    );
    const [packageTypes, setPackageTypes] = useState([]);
    const [envelopeContents, setEnvelopeContents] = useState(
        props.value ? props.value.envelopeContents : null,
    );
    const [identifier] = useState(props.value.id || uuidv4());
    const [descriptionSuggestions, setDescriptionSuggestions] = useState([]);
    const cancelSearchTokenRef = useRef();

    const lengthRef = useRef();
    const widthRef = useRef();
    const heightRef = useRef();
    const volumeRef = useRef();
    const loadingMetersRef = useRef();
    const weightRef = useRef();
    const descriptionRef = useRef();
    const typeRef = useRef();
    const stackableRef = useRef();

    useEffect(() => {
        let defaultPackageTypes = [
            {
                title: "Envelope",
                value: "envelope",
            },
            {
                title: "Paket",
                value: "package",
            },
            {
                title: "Pall",
                value: "pallet",
            },
        ];

        let groupedPackageTypes = {};

        if (props.packageTypes.length) {
            props.packageTypes.forEach((packageType, index) => {
                const packageGroup =
                    packageType.data.packageGroup || "Ingen grupp";

                if (!groupedPackageTypes[packageGroup]) {
                    groupedPackageTypes[packageGroup] = [];
                }

                groupedPackageTypes[packageGroup].push({
                    title: packageType.name,
                    value: index,
                });
            });
        }

        let customPackageTypes = [];

        for (const [group, types] of Object.entries(groupedPackageTypes)) {
            customPackageTypes.push({
                type: "divider",
                title: group,
                value: null,
            });
            customPackageTypes = customPackageTypes.concat(types);
        }

        let finalPackageTypes = [...defaultPackageTypes, ...customPackageTypes];

        if (context.user.customer.settings.productSpecificPackages) {
            finalPackageTypes = finalPackageTypes.concat([
                {
                    type: "divider",
                    title: "Produktspecifika kollin",
                    value: null,
                },
                {
                    title: "DHL Paket",
                    value: "dhl-paket",
                },
                {
                    title: "DHL Stycke",
                    value: "dhl-stycke",
                },
                {
                    title: "DHL Pall",
                    value: "dhl-pall",
                },
                {
                    title: "DHL Halvpall",
                    value: "dhl-halvpall",
                },
                {
                    title: "DHL Parti",
                    value: "dhl-parti",
                },
                {
                    title: "DHL Service Point",
                    value: "dhl-service-point",
                },
                {
                    title: "DHL Service Point Retur",
                    value: "dhl-return",
                },
                {
                    title: "EuroConnect",
                    value: "dhl-euroconnect",
                },
                {
                    title: "Euroline",
                    value: "dhl-euroline",
                },
                {
                    title: "EuroConnect Plus",
                    value: "dhl-euroconnect-plus",
                },
            ]);
        }

        setPackageTypes(finalPackageTypes);
    }, [
        props.packageTypes,
        context.user.customer.settings.productSpecificPackages,
    ]);

    function getTypeBaseValue(type) {
        if (!isNaN(parseInt(type))) {
            return props.packageTypes[parseInt(type)]?.data.type;
        } else {
            switch (type) {
                case "dhl-paket":
                case "dhl-stycke":
                case "dhl-parti":
                case "dhl-euroconnect":
                case "dhl-euroline":
                case "dhl-euroconnect-plus":
                case "dhl-return":
                case "dhl-service-point":
                    return "package";
                case "dhl-halvpall":
                case "dhl-pall":
                    return "pallet";
            }
        }

        return type;
    }

    useEffect(() => {
        props.onChange({
            id: identifier,
            type: getTypeBaseValue(type),
            count,
            length:
                context.user.config.packageSizeDefinition !== "volume"
                    ? length
                        ? parseFloat(length.toString().replace(",", "."))
                        : 0
                    : null,
            width:
                context.user.config.packageSizeDefinition !== "volume"
                    ? width
                        ? parseFloat(width.toString().replace(",", "."))
                        : 0
                    : null,
            height:
                context.user.config.packageSizeDefinition !== "volume"
                    ? height
                        ? parseFloat(height.toString().replace(",", "."))
                        : 0
                    : null,
            weight: weight
                ? parseFloat(weight.toString().replace(",", "."))
                : 0,
            volume:
                context.user.config.packageSizeDefinition === "volume"
                    ? volume
                        ? parseFloat(volume.toString().replace(",", "."))
                        : 0
                    : null,
            loadingMeters:
                context.user.config.packageSizeDefinition === "volume"
                    ? loadingMeters
                        ? parseFloat(loadingMeters.toString().replace(",", "."))
                        : 0
                    : null,
            envelopeContents,
            description,
            productSpecificPackage,
            stackable:
                context.user.config.packageSizeDefinition === "volume"
                    ? true
                    : stackable,
        });
    }, [
        count,
        type,
        volume,
        loadingMeters,
        length,
        width,
        height,
        weight,
        stackable,
        envelopeContents,
        identifier,
        description,
        productSpecificPackage,
        context.user.config.packageSizeDefinition,
    ]);

    function changeType(type, packageSizeDefinition) {
        setType(type);
        setTimeout(() => {
            if (!isNaN(parseInt(type))) {
                let packageType = props.packageTypes[parseInt(type)];
                if (packageSizeDefinition === "volume") {
                    setVolume(packageType.data.volume || 0);
                    setLoadingMeters(packageType.data.loadingMeters || 0);
                    setWidth(0);
                    setHeight(0);
                    setLength(0);
                    setStackable(true);
                    volumeRef.current?.set(packageType.data.volume || 0);
                    loadingMetersRef.current?.set(
                        packageType.data.loadingMeters || 0,
                    );
                    widthRef.current?.set(0);
                    heightRef.current?.set(0);
                    lengthRef.current?.set(0);
                    stackableRef.current?.set(true);
                } else {
                    setWidth(packageType.data.width || 0);
                    setHeight(packageType.data.height || 0);
                    setLength(packageType.data.length || 0);
                    setVolume(0);
                    setLoadingMeters(0);
                    setStackable(packageType.data.stackable);
                    widthRef.current?.set(packageType.data.width || 0);
                    heightRef.current?.set(packageType.data.height || 0);
                    lengthRef.current?.set(packageType.data.length || 0);
                    volumeRef.current?.set(0);
                    loadingMetersRef.current?.set(0);
                    stackableRef.current?.set(packageType.data.stackable);
                }
                setDescription(packageType.data?.goodsDescription);
                descriptionRef.current?.set(packageType.data.goodsDescription);
                setWeight(packageType.data.weight);
                weightRef.current?.set(packageType.data.weight);
                setProductSpecificPackage(null);
            } else if (type === "package") {
                stackableRef.current?.set(true);
                setStackable(true);
                setProductSpecificPackage(null);
            } else if (type === "pallet") {
                stackableRef.current?.set(null);
                setStackable(null);
                setProductSpecificPackage(null);
            } else {
                switch (type) {
                    case "dhl-paket": {
                        setProductSpecificPackage("DHL Paket");
                        setLoadingMeters(0);
                        setWidth(0);
                        setHeight(0);
                        setLength(0);
                        widthRef.current?.set(0);
                        heightRef.current?.set(0);
                        lengthRef.current?.set(0);
                        loadingMetersRef.current?.set(0);
                        break;
                    }
                    case "dhl-stycke": {
                        setProductSpecificPackage("DHL Stycke");
                        setWidth(0);
                        setHeight(0);
                        setLength(0);
                        widthRef.current?.set(0);
                        heightRef.current?.set(0);
                        lengthRef.current?.set(0);
                        break;
                    }
                    case "dhl-halvpall": {
                        setProductSpecificPackage("DHL Pall");
                        setWidth(80);
                        setHeight(0);
                        setLength(60);
                        widthRef.current?.set(80);
                        heightRef.current?.set(0);
                        lengthRef.current?.set(60);
                        setLoadingMeters(0.2);
                        setVolume(null);
                        loadingMetersRef?.current?.set(0.2);
                        volumeRef?.current?.set(null);
                        break;
                    }
                    case "dhl-pall": {
                        setProductSpecificPackage("DHL Pall");
                        setWidth(80);
                        setHeight(0);
                        setLength(120);
                        widthRef.current?.set(80);
                        heightRef.current?.set(0);
                        lengthRef.current?.set(120);
                        setLoadingMeters(0.4);
                        setVolume(null);
                        loadingMetersRef?.current?.set(0.4);
                        volumeRef?.current?.set(null);
                        break;
                    }
                    case "dhl-parti": {
                        setProductSpecificPackage("DHL Parti");
                        setWidth(0);
                        setHeight(0);
                        setLength(0);
                        widthRef.current?.set(0);
                        heightRef.current?.set(0);
                        lengthRef.current?.set(0);
                        break;
                    }
                    case "dhl-service-point": {
                        setProductSpecificPackage("DHL Service Point");
                        setWidth(0);
                        setHeight(0);
                        setLength(0);
                        widthRef.current?.set(0);
                        heightRef.current?.set(0);
                        lengthRef.current?.set(0);
                        setLoadingMeters(0);
                        loadingMetersRef.current?.set(0);
                        break;
                    }
                    case "dhl-return": {
                        setProductSpecificPackage("DHL Service Point Retur");
                        setWidth(0);
                        setHeight(0);
                        setLength(0);
                        widthRef.current?.set(0);
                        heightRef.current?.set(0);
                        lengthRef.current?.set(0);
                        setLoadingMeters(0);
                        loadingMetersRef.current?.set(0);
                        break;
                    }
                    case "dhl-euroconnect": {
                        setProductSpecificPackage("DHL EuroConnect");
                        setWidth(0);
                        setHeight(0);
                        setLength(0);
                        widthRef.current?.set(0);
                        heightRef.current?.set(0);
                        lengthRef.current?.set(0);
                        break;
                    }
                    case "dhl-euroline": {
                        setProductSpecificPackage("DHL Euroline");
                        setWidth(0);
                        setHeight(0);
                        setLength(0);
                        widthRef.current?.set(0);
                        heightRef.current?.set(0);
                        lengthRef.current?.set(0);
                        break;
                    }
                    case "dhl-euroconnect-plus": {
                        setProductSpecificPackage("DHL EuroConnect Plus");
                        setWidth(0);
                        setHeight(0);
                        setLength(0);
                        widthRef.current?.set(0);
                        heightRef.current?.set(0);
                        lengthRef.current?.set(0);
                        setLoadingMeters(0);
                        loadingMetersRef.current?.set(0);
                        break;
                    }
                }
            }
        }, 0);
    }

    useEffect(() => {
        if (type === "envelope") {
            setVolume(0); // TODO: Decide what to do here. Maybe the transport API:s should overwrite this? Maybe the UI Shouldn't print the total volume?
            setLoadingMeters(0); // TODO: Decide what to do here. Maybe the transport API:s should overwrite this? Maybe the UI Shouldn't print the total volume?
            setLength(0); // TODO: Decide what to do here. Maybe the transport API:s should overwrite this? Maybe the UI Shouldn't print the total volume?
            setWidth(0); // TODO: Decide what to do here. Maybe the transport API:s should overwrite this? Maybe the UI Shouldn't print the total volume?
            setHeight(0); // TODO: Decide what to do here. Maybe the transport API:s should overwrite this? Maybe the UI Shouldn't print the total volume?
            setWeight(0.5);
        }
    }, [type]);

    const validate = () => {
        return !!envelopeContents;
    };

    useImperativeHandle(ref, () => {
        return {
            validate: validate,
        };
    });

    function selectPackageDescription(value) {
        setDescription(value);
        descriptionRef.current?.set(value);
        descriptionRef.current?.blur();
    }

    async function searchPackageDescriptions(searchstring) {
        if (cancelSearchTokenRef.current) {
            cancelSearchTokenRef.current.cancel();
        }
        cancelSearchTokenRef.current = axios.CancelToken.source();

        const descriptions = await Api.getCustomerPackageDescription({
            cancelToken: cancelSearchTokenRef.current?.token,
            customerId: context.user.customer.id,
            searchstring,
        });
        setDescriptionSuggestions(
            descriptions.map((text) => {
                return {
                    title: text,
                };
            }),
        );
    }

    useEffect(() => {
        searchPackageDescriptions(description);
    }, [description]);

    function onDescriptionFocus() {
        searchPackageDescriptions(description);
    }
    useEffect(() => {
        if (context.user.config.packageSizeDefinition === "volume") {
            if (!volume && !loadingMeters) {
                setVolume(0);
                setLoadingMeters(0);
                volumeRef.current?.set(0);
                loadingMetersRef.current?.set(0);
                setHeight(0);
                setWidth(0);
                setLength(0);
                stackableRef.current?.set(true);
                setStackable(true);
            }
        } else {
            if (volume || loadingMeters) {
                setVolume(0);
                setLoadingMeters(0);
                setHeight(0);
                setWidth(0);
                setLength(0);
                heightRef.current?.set(0);
                widthRef.current?.set(0);
                lengthRef.current?.set(0);
            }
        }
    }, [context.user.config.packageSizeDefinition]);

    return (
        <Container>
            <Input
                name="count"
                className="count"
                type="number"
                min={1}
                onChange={setCount}
                value={count}
            />
            {context.user.config.packageSizeDefinition === "volume" && (
                <Input
                    name="type"
                    ref={typeRef}
                    className="type"
                    type="dropdown"
                    onChange={(value) => {
                        changeType(value, "volume");
                    }}
                    value={type}
                    options={packageTypes}
                />
            )}
            {context.user.config.packageSizeDefinition !== "volume" && (
                <Input
                    name="type"
                    ref={typeRef}
                    className="type"
                    type="dropdown"
                    onChange={(value) => {
                        changeType(value, "dimensions");
                    }}
                    value={type}
                    options={packageTypes}
                />
            )}
            {type !== "envelope" && (
                <>
                    {context.user.config.packageSizeDefinition === "volume" ? (
                        <>
                            {["dhl-pall", "dhl-halvpall"].indexOf(type) >= 0 ? (
                                <div className="dimension"></div>
                            ) : (
                                <Input
                                    key="volumeKey"
                                    name="volume"
                                    ref={volumeRef}
                                    className={
                                        "dimension volume" +
                                        (type === "envelope"
                                            ? " is-hidden"
                                            : "")
                                    }
                                    placeholder="0"
                                    onChange={setVolume}
                                    value={volume}
                                    type="text"
                                    unit="m³"
                                    disabled={props.fromBatch}
                                />
                            )}
                            {["dhl-pall"].indexOf(type) >= 0 && (
                                <Input
                                    key="loadingMetersKey"
                                    name="loadingMeters"
                                    disabled
                                    ref={loadingMetersRef}
                                    className={"dimension loadingMeters"}
                                    placeholder="0"
                                    onChange={setLoadingMeters}
                                    value={loadingMeters}
                                    type="text"
                                    unit="flm"
                                />
                            )}
                            {["dhl-halvpall"].indexOf(type) >= 0 && (
                                <Input
                                    key="loadingMetersKey"
                                    name="loadingMeters"
                                    disabled
                                    ref={loadingMetersRef}
                                    className={"dimension loadingMeters"}
                                    placeholder="0"
                                    onChange={setLoadingMeters}
                                    value={loadingMeters}
                                    type="text"
                                    unit="flm"
                                />
                            )}
                            {[
                                "dhl-stycke",
                                "dhl-parti",
                                "dhl-euroconnect",
                                "dhl-euroline",
                                "pallet",
                            ].indexOf(type) >= 0 && (
                                <Input
                                    key="loadingMetersKey"
                                    name="loadingMeters"
                                    ref={loadingMetersRef}
                                    className={"dimension loadingMeters"}
                                    placeholder="0"
                                    onChange={setLoadingMeters}
                                    value={loadingMeters}
                                    type="text"
                                    unit="flm"
                                />
                            )}
                            {[
                                "dhl-pall",
                                "dhl-halvpall",
                                "dhl-stycke",
                                "dhl-parti",
                                "dhl-euroconnect",
                                "dhl-euroline",
                                "pallet",
                            ].indexOf(type) < 0 && (
                                <div className="dimension"></div>
                            )}
                        </>
                    ) : (
                        <>
                            {["dhl-pall"].indexOf(type) >= 0 && (
                                <>
                                    <Input
                                        key="lengthKey"
                                        name={`length`}
                                        ref={lengthRef}
                                        disabled
                                        className={
                                            "dimension" +
                                            (type === "envelope"
                                                ? " is-hidden"
                                                : "")
                                        }
                                        placeholder="0"
                                        onChange={setLength}
                                        value={length}
                                        type="text"
                                        unit="cm"
                                    />
                                    <Input
                                        key="widthKey"
                                        name={`width`}
                                        ref={widthRef}
                                        disabled
                                        className={
                                            "dimension" +
                                            (type === "envelope"
                                                ? " is-hidden"
                                                : "")
                                        }
                                        placeholder="0"
                                        onChange={setWidth}
                                        value={width}
                                        type="text"
                                        unit="cm"
                                    />
                                </>
                            )}
                            {["dhl-halvpall"].indexOf(type) >= 0 && (
                                <>
                                    <Input
                                        key="lengthKey"
                                        name={`length`}
                                        ref={lengthRef}
                                        disabled
                                        className={
                                            "dimension" +
                                            (type === "envelope"
                                                ? " is-hidden"
                                                : "")
                                        }
                                        placeholder="0"
                                        onChange={setLength}
                                        value={length}
                                        type="text"
                                        unit="cm"
                                    />
                                    <Input
                                        key="widthKey"
                                        name={`width`}
                                        ref={widthRef}
                                        disabled
                                        className={
                                            "dimension" +
                                            (type === "envelope"
                                                ? " is-hidden"
                                                : "")
                                        }
                                        placeholder="0"
                                        onChange={setWidth}
                                        value={width}
                                        type="text"
                                        unit="cm"
                                    />
                                </>
                            )}
                            {["dhl-pall", "dhl-halvpall"].indexOf(type) < 0 && (
                                <>
                                    <Input
                                        key="lengthKey"
                                        name={`length`}
                                        ref={lengthRef}
                                        className={
                                            "dimension" +
                                            (type === "envelope"
                                                ? " is-hidden"
                                                : "")
                                        }
                                        placeholder="0"
                                        onChange={setLength}
                                        value={length}
                                        type="text"
                                        unit="cm"
                                    />
                                    <Input
                                        key="widthKey"
                                        name={`width`}
                                        ref={widthRef}
                                        className={
                                            "dimension" +
                                            (type === "envelope"
                                                ? " is-hidden"
                                                : "")
                                        }
                                        placeholder="0"
                                        onChange={setWidth}
                                        value={width}
                                        type="text"
                                        unit="cm"
                                    />
                                </>
                            )}
                            <Input
                                key="heightKey"
                                name={`height`}
                                ref={heightRef}
                                className={
                                    "dimension" +
                                    (type === "envelope" ? " is-hidden" : "")
                                }
                                placeholder="0"
                                onChange={setHeight}
                                value={height}
                                type="text"
                                unit="cm"
                            />
                        </>
                    )}
                    <Input
                        name="weight"
                        ref={weightRef}
                        className="dimension"
                        placeholder="0"
                        onChange={setWeight}
                        min={0}
                        max={type === "envelope" ? 0.5 : null}
                        step={type === "envelope" ? 0.1 : 1}
                        value={weight}
                        type="text"
                        unit="kg"
                    />
                    {context.user.config.packageSizeDefinition !== "volume" && (
                        <Input
                            name="stackable"
                            ref={stackableRef}
                            className="stackable"
                            onChange={setStackable}
                            value={stackable}
                            type={InputMultiToggle}
                            options={[
                                {
                                    title: "Ja",
                                    value: true,
                                },
                                {
                                    title: "Nej",
                                    value: false,
                                },
                            ]}
                        />
                    )}
                </>
            )}
            {type === "envelope" && (
                <>
                    <Input
                        type={InputRadioButton}
                        onChange={setEnvelopeContents}
                        value={envelopeContents}
                        options={[
                            {
                                title: "Endast dokument",
                                value: "documents",
                            },
                            {
                                title: "Produkter/Varor",
                                value: "products",
                            },
                        ]}
                        helpText="Endast dokument kräver ingen tull-faktura när man skickar utanför EU. Oavsett innehåll måste transportörens egna envelope användas och får max väga 0.5 kg."
                    />
                </>
            )}
            <div className="buttons">
                <div
                    className="button duplicate"
                    onClick={() => {
                        // TODO: Move them inside of the Row instead and use a callback.
                        props.onDuplicate({
                            id: uuidv4(),
                            type: getTypeBaseValue(type),
                            count,
                            length: length
                                ? parseFloat(
                                      length.toString().replace(",", "."),
                                  )
                                : 0,
                            width: width
                                ? parseFloat(width.toString().replace(",", "."))
                                : 0,
                            height: height
                                ? parseFloat(
                                      height.toString().replace(",", "."),
                                  )
                                : 0,
                            volume: volume
                                ? parseFloat(
                                      volume.toString().replace(",", "."),
                                  )
                                : 0,
                            loadingMeters: loadingMeters
                                ? parseFloat(
                                      loadingMeters
                                          .toString()
                                          .replace(",", "."),
                                  )
                                : 0,
                            weight: weight
                                ? parseFloat(
                                      weight.toString().replace(",", "."),
                                  )
                                : 0,
                            description: description,
                            envelopeContents,
                            stackable,
                        });
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M22 6v16h-16v-16h16zm2-2h-20v20h20v-20zm-24 17v-21h21v2h-19v19h-2z"
                        />
                    </svg>
                </div>
                <div
                    className="button delete"
                    onClick={() => {
                        props.onDelete(identifier);
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.094l-4.157-4.104 4.1-4.141-1.849-1.849-4.105 4.159-4.156-4.102-1.833 1.834 4.161 4.12-4.104 4.157 1.834 1.832 4.118-4.159 4.143 4.102 1.848-1.849z"
                        />
                    </svg>
                </div>
            </div>
            {context.user.customer.settings.goodsRowDescription && (
                <div className="description">
                    <div className="title">
                        Godsbeskrivning <span className="required">*</span>
                    </div>
                    <div className="input">
                        <InputTextSuggestions
                            ref={descriptionRef}
                            required={true}
                            maxLength={17}
                            onFocus={onDescriptionFocus}
                            suggestions={descriptionSuggestions}
                            onSelect={selectPackageDescription}
                            onChange={setDescription}
                            value={description}
                        />
                    </div>
                </div>
            )}
        </Container>
    );
}

export default forwardRef(Component);
