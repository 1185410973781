import React from "react";
import DHLPickup from "./DHL";
import FedexPickup from "./Fedex";
import TNTPickup from "./TNT";
import UPSPickup from "./UPS";
import { Redirect } from "react-router-dom";

function Component({ order, onChange, onPickupNotAvailable }) {
    switch (order.service.name.toUpperCase()) {
        case "DHL": {
            return (
                <DHLPickup
                    order={order}
                    onChange={onChange}
                    onPickupNotAvailable={onPickupNotAvailable}
                />
            );
        }
        case "FEDEX": {
            return (
                <FedexPickup
                    order={order}
                    onChange={onChange}
                    onPickupNotAvailable={onPickupNotAvailable}
                />
            );
        }
        case "TNT": {
            return (
                <TNTPickup
                    order={order}
                    onChange={onChange}
                    onPickupNotAvailable={onPickupNotAvailable}
                />
            );
        }
        case "UPS": {
            return (
                <UPSPickup
                    order={order}
                    onChange={onChange}
                    onPickupNotAvailable={onPickupNotAvailable}
                />
            );
        }
        case "POSTNORD": {
            return <Redirect to="/pickup/postnord" />;
        }
        case "BRING": {
            return <Redirect to="/pickup/bring" />;
        }
        default: {
            console.error(
                "PickupPicker not defined for service " + order.service.name,
            );
            return null;
        }
    }
}

export default Component;
