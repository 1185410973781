import Context from "context/Global";
import React, { useContext, useEffect, useRef, useState } from "react";
import PackagesTable from "./PackagesTable";
import PickupModeSelector from "./PickupModeSelector";
import PickupInput from "./PickupInput";
import PickupAddress from "./PickupAddress";
import {
    bookPickup,
    getOrders,
    getPackagesToPickup,
    getPickupLocation,
} from "./utils";
import Loader from "components/Loader/Loader";
import toast from "react-hot-toast";
import moment from "moment";

function Component() {
    const context = useContext(Context);
    const [otherPickupAddress, setOtherPickupAddress] = useState(false);
    const [pickup, setPickup] = useState(null);
    const [orders, setOrders] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorMessageTodaysShipments, setErrorMessageTodaysShipments] =
        useState(null);
    const pickupInputRef = useRef();
    const companyRef = useRef();
    const [isManualPickup, setIsManualPickup] = useState(false);
    const [isPickupModeSelected, setIsPickupModeSelected] = useState(false);
    const [checkedStates, setCheckedStates] = useState({});
    const [loading, setLoading] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [pickupDate, setPickupDate] = useState(moment().format("YYYY-MM-DD"));

    useEffect(() => {
        fetchOrders();
    }, []);

    async function fetchOrders() {
        try {
            const orders = await getOrders(context.user.customer.id);
            setOrders(orders);
        } catch (error) {
            setErrorMessageTodaysShipments(
                error.response?.data?.message || "Ett oväntat fel uppstod",
            );
        }
    }
    useEffect(() => {
        if (orders && !orders.length) {
            setIsPickupModeSelected(true);
            setIsManualPickup(true);
        } else if (orders) {
            const initialCheckedState = orders.reduce(
                (acc, order) => ({
                    ...acc,
                    [order.orderId]: false,
                }),
                {},
            );
            setCheckedStates(initialCheckedState);
        }
    }, [orders]);

    if (pickup) {
        return (
            <div className="w-full max-w-xl p-4 mb-4 relative bg-gray-100 border border-gray-300 shadow-md rounded-lg">
                <div className="mb-4 text-gray-800 text-3xl font-semibold">
                    Boka PostNord upphämtning
                </div>
                <p className="text-gray-700 text-lg">
                    Din upphämtning är bokad med bokningsnummer{" "}
                    <span className="font-bold">
                        {pickup.confirmationNumber}
                    </span>
                    .
                </p>
            </div>
        );
    }

    const disableBookingButton = () => {
        if (loading) return true;
        if (!agreeToTerms) return true;
        if (!isManualPickup)
            return Object.values(checkedStates).every((value) => !value);
    };

    const handlePickupBooking = () => {
        if (pickupInputRef?.current.validate()) {
            let packages = pickupInputRef.current.value();
            if (packages.totalWeight === 0) {
                pickupInputRef.current.setValidationMessage({
                    key: "totalWeight",
                    message: "Vikten måste vara större än 0",
                });
            } else {
                setLoading(true);

                const selectedOrders = orders.filter(
                    (order) => checkedStates[order.orderId] === true,
                );

                const selectedPackages = selectedOrders.flatMap(
                    (order) => order.packages,
                );

                const orderIds = selectedOrders.map((order) => order.orderId);

                const packagesToPickup = getPackagesToPickup(
                    selectedPackages,
                    isManualPickup,
                    pickupInputRef.current.value(),
                );

                const pickupLocation = getPickupLocation(
                    context.user,
                    otherPickupAddress,
                    companyRef,
                );

                const pickupInstructions = {
                    pickupDate: pickupDate,
                    instructions: pickupInputRef.current.value().instructions,
                };

                bookPickup({
                    orderIds: orderIds || undefined,
                    packagesToPickup,
                    pickupLocation,
                    pickupInstructions,
                    setErrorMessage,
                }).then((pickup) => {
                    setLoading(false);
                    if (!!pickup) {
                        setPickup(pickup);
                        toast.success("Din upphämtning är bokad");
                    } else {
                        toast.error("Ett fel uppstod, försök igen");
                    }
                });
            }
        }
    };

    return (
        <div className="w-full max-w-xl p-5 mb-4 relative ">
            <div className="mb-4 text-stone-400 text-3xl font-bold">
                Boka PostNord upphämtning
            </div>
            <div className=" h-40 mb-6 p-6 gap-6 border rounded border-yellow-300 ">
                <div className="mb-4 text-gray-500 text-2xl font-normal leading-7">
                    Vänligen observera! Upphämtning med PostNord går inte att
                    avboka
                </div>
                <div className="text-stone-400 text-base font-medium leading-5">
                    PostNord stödjer inte avbokning av upphämtning. Om du bokar
                    upphämtning kommer du att bli debiterad för försändelsen.
                </div>
            </div>
            {loading && (
                <div className="h-full w-full justify-center items-center">
                    <Loader />
                </div>
            )}
            {!loading && (
                <PickupModeSelector
                    isManualPickup={isManualPickup}
                    isPickupModeSelected={isPickupModeSelected}
                    setIsManualPickup={setIsManualPickup}
                    setIsPickupModeSelected={setIsPickupModeSelected}
                    hasShipments={!!orders?.length}
                />
            )}
            {isPickupModeSelected && !loading && (
                <>
                    {!isManualPickup && (
                        <PackagesTable
                            orders={orders}
                            checkedStates={checkedStates}
                            setCheckedStates={setCheckedStates}
                            errorMessage={errorMessageTodaysShipments}
                            pickupDate={pickupDate}
                        />
                    )}
                    <PickupInput
                        ref={pickupInputRef}
                        isManualPickup={isManualPickup}
                        setPickupDate={setPickupDate}
                        pickupDate={pickupDate}
                    />

                    <div
                        className={`border rounded border-yellow-300 p-4 mb-4 mt-4`}
                    >
                        <PickupAddress
                            ref={companyRef}
                            otherPickupAddress={otherPickupAddress}
                            setOtherPickupAddress={setOtherPickupAddress}
                            user={context.user}
                        />
                        {!otherPickupAddress && (
                            <button
                                className="c-button c-button--ghost"
                                onClick={() => setOtherPickupAddress(true)}
                                disabled={loading}
                            >
                                Ändra adress
                            </button>
                        )}
                    </div>

                    <div className="col-span-1 flex justify-start gap-3 items-center pb-2 mb-2 mt-8">
                        <input
                            type="checkbox"
                            checked={agreeToTerms}
                            onChange={() => setAgreeToTerms(!agreeToTerms)}
                            className="h-6 w-6 text-green-500 focus:ring-green-500 border-gray-300 rounded"
                        />
                        <div className="text-black text-sm font-bold leading-4">
                            Jag är medveten om att jag inte kan avboka
                            upphämtningen, och att jag kommer att bli debiterad
                            för en bomkörning.
                        </div>
                    </div>

                    <div className="buttons">
                        <button
                            onClick={() => handlePickupBooking()}
                            className="c-button c-button--raised"
                            disabled={disableBookingButton()}
                        >
                            Boka upphämtning
                        </button>
                    </div>
                </>
            )}
            {errorMessage && isPickupModeSelected && (
                <div className="mt-4">
                    <div className=" text-red-500 text-sm font-bold">
                        Ett fel uppstod, kontrollera att informationen ovan är
                        korrekt och försök igen. Ta gärna kontakt med vår
                        kundservice på{" "}
                        <a
                            href={`tel:${context.user.customer.brand.supportPhoneNumber}`}
                        >
                            {context.user.customer.brand.supportPhoneNumber}
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Component;
