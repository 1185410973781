import React, { Component } from "react";
import styled from "styled-components";

let Container = styled.div`
    width: 100%;
    position: relative;

    > textarea {
        width: 100%;
        min-width: 100%;
        height: 120px;
        padding: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        box-sizing: border-box;
        overflow-x: hidden;
    }

    &.is-invalid {
        > textarea {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }
    }

    > .length {
        font-size: 0.8rem;
        padding: 2px 8px;
        margin: 8px 8px;
        font-style: italic;
        font-weight: bold;
        color: #555;
        top: 0;
        right: 0;
        position: absolute;
        background: rgba(255, 255, 255, 1);
        border-radius: 5px;
        box-shadow: 0 0 2px 0px rgb(0 0 0 / 10%);

        &.is-invalid {
            color: #ff5555;
        }
    }
`;

class InputTextarea extends Component {
    constructor(props) {
        super(props);
        this.textarea = props?.textAreaRef || React.createRef();
        this.state = {
            value: props.value || "",
        };
        if (typeof this.props.onChange === "function") {
            this.onChange = this.props.onChange;
        }
    }

    setCursorOnSecondRow = () => {
        if (!!this.props.textAreaRef && this.textarea.current) {
            const secondRowStart =
                this.textarea.current.value.indexOf("\n") + 1;
            this.textarea.current.setSelectionRange(
                secondRowStart,
                secondRowStart,
            );
        }
    };

    componentDidMount() {
        this.setCursorOnSecondRow();
    }

    onChange() {}

    value() {
        return this.state.value;
    }

    set(value) {
        this.setState({
            value: value,
        });
    }

    empty() {
        this.setState({
            value: "",
        });
    }

    focus() {
        this.textarea.focus();
    }

    onBlur() {
        this.textarea.onBlur();
    }

    validate() {
        if (
            this.props.required &&
            (!this.state.value || this.state.value.length === 0)
        ) {
            this.setState({
                isInvalid: true,
                message: this.props.messages.required,
            });
            return false;
        }
        if (this.state.value) {
            if (this.state.value.trim().length > this.props.maxLength) {
                this.setState({
                    isInvalid: true,
                    message: this.props.messages.maxLength,
                });
                return false;
            }
        }
        this.setState({
            isInvalid: false,
        });
        return true;
    }

    showLength() {
        if (
            this.props.maxLength &&
            (this.props.maxLength <= 10 ||
                (this.state.value &&
                    this.state.value.trim().length / this.props.maxLength >
                        0.8))
        ) {
            return true;
        }
        return false;
    }

    getLengthClass() {
        let classes = ["length"];
        if (
            this.state.value &&
            this.state.value.trim().length > this.props.maxLength
        ) {
            classes.push("is-invalid");
        }
        return classes.join(" ");
    }

    render() {
        let { maxLength, ...otherProps } = this.props;
        return (
            <Container className={this.state.isInvalid ? " is-invalid" : ""}>
                <textarea
                    ref={this.textarea}
                    autoFocus={true}
                    {...otherProps}
                    value={this.state.value}
                    onChange={(event) => {
                        this.setState(
                            {
                                value: event.target.value,
                                isInvalid: false,
                            },
                            () => {
                                this.onChange(this.value());
                            },
                        );
                    }}
                />
                {this.showLength() && (
                    <div className={this.getLengthClass()}>
                        {this.state.value ? this.state.value.trim().length : 0}/
                        {maxLength}
                    </div>
                )}
            </Container>
        );
    }
}

InputTextarea.defaultProps = {
    disabled: false,
    messages: {
        required: "This is a required field",
        maxLength: "The field contains too many characters",
    },
};

export default InputTextarea;
