exports.currencyList = [
    {
        value: "SEK",
        title: "SEK, Swedish Krona",
    },
    {
        value: "EUR",
        title: "EUR, Euro",
    },
    {
        value: "USD",
        title: "USD, US Dollar",
    },
    {
        value: "GBP",
        title: "GBP, British Pound Sterling",
    },
    {
        value: "NOK",
        title: "NOK, Norwegian Krone",
    },
    {
        value: "ISK",
        title: "ISK, Icelandic króna",
    },
    {
        value: "JPY",
        title: "JPY, Japanese Yen",
    },
    {
        value: "CAD",
        title: "CAD, Canadian Dollar",
    },
    {
        value: "CHF",
        title: "CHF, Swiss Franc",
    },
    {
        value: "SGD",
        title: "SGD, Singapore Dollar",
    },
    {
        value: "AUD",
        title: "AUD, Australian Dollar",
    },
    {
        value: "DKK",
        title: "DKK, Danish Krone",
    },
    // {
    //     "value": "AED",
    //     "title": "AED, United Arab Emirates Dirham"
    // },
    // {
    //     "value": "AFN",
    //     "title": "AFN, Afghan Afghani"
    // },
    // {
    //     "value": "ALL",
    //     "title": "ALL, Albanian Lek"
    // },
    // {
    //     "value": "AMD",
    //     "title": "AMD, Armenian Dram"
    // },
    // {
    //     "value": "ARS",
    //     "title": "ARS, Argentine Peso"
    // },
    // {
    //     "value": "AUD",
    //     "title": "AUD, Australian Dollar"
    // },
    // {
    //     "value": "AZN",
    //     "title": "AZN, Azerbaijani Manat"
    // },
    // {
    //     "value": "BAM",
    //     "title": "BAM, Bosnia-Herzegovina Convertible Mark"
    // },
    // {
    //     "value": "BDT",
    //     "title": "BDT, Bangladeshi Taka"
    // },
    // {
    //     "value": "BGN",
    //     "title": "BGN, Bulgarian Lev"
    // },
    // {
    //     "value": "BHD",
    //     "title": "BHD, Bahraini Dinar"
    // },
    // {
    //     "value": "BIF",
    //     "title": "BIF, Burundian Franc"
    // },
    // {
    //     "value": "BND",
    //     "title": "BND, Brunei Dollar"
    // },
    // {
    //     "value": "BOB",
    //     "title": "BOB, Bolivian Boliviano"
    // },
    // {
    //     "value": "BRL",
    //     "title": "BRL, Brazilian Real"
    // },
    // {
    //     "value": "BWP",
    //     "title": "BWP, Botswanan Pula"
    // },
    // {
    //     "value": "BYN",
    //     "title": "BYN, Belarusian Ruble"
    // },
    // {
    //     "value": "BZD",
    //     "title": "BZD, Belize Dollar"
    // },
    // {
    //     "value": "CDF",
    //     "title": "CDF, Congolese Franc"
    // },
    // {
    //     "value": "CLP",
    //     "title": "CLP, Chilean Peso"
    // },
    // {
    //     "value": "CNY",
    //     "title": "CNY, Chinese Yuan"
    // },
    // {
    //     "value": "COP",
    //     "title": "COP, Colombian Peso"
    // },
    // {
    //     "value": "CRC",
    //     "title": "CRC, Costa Rican Colón"
    // },
    // {
    //     "value": "CVE",
    //     "title": "CVE, Cape Verdean Escudo"
    // },
    // {
    //     "value": "CZK",
    //     "title": "CZK, Czech Republic Koruna"
    // },
    // {
    //     "value": "DJF",
    //     "title": "DJF, Djiboutian Franc"
    // },
    // {
    //     "value": "DKK",
    //     "title": "DKK, Danish Krone"
    // },
    // {
    //     "value": "DOP",
    //     "title": "DOP, Dominican Peso"
    // },
    // {
    //     "value": "DZD",
    //     "title": "DZD, Algerian Dinar"
    // },
    // {
    //     "value": "EEK",
    //     "title": "EEK, Estonian Kroon"
    // },
    // {
    //     "value": "EGP",
    //     "title": "EGP, Egyptian Pound"
    // },
    // {
    //     "value": "ERN",
    //     "title": "ERN, Eritrean Nakfa"
    // },
    // {
    //     "value": "ETB",
    //     "title": "ETB, Ethiopian Birr"
    // },
    // {
    //     "value": "GBP",
    //     "title": "GBP, British Pound Sterling"
    // },
    // {
    //     "value": "GEL",
    //     "title": "GEL, Georgian Lari"
    // },
    // {
    //     "value": "GHS",
    //     "title": "GHS, Ghanaian Cedi"
    // },
    // {
    //     "value": "GNF",
    //     "title": "GNF, Guinean Franc"
    // },
    // {
    //     "value": "GTQ",
    //     "title": "GTQ, Guatemalan Quetzal"
    // },
    // {
    //     "value": "HKD",
    //     "title": "HKD, Hong Kong Dollar"
    // },
    // {
    //     "value": "HNL",
    //     "title": "HNL, Honduran Lempira"
    // },
    // {
    //     "value": "HRK",
    //     "title": "HRK, Croatian Kuna"
    // },
    // {
    //     "value": "HUF",
    //     "title": "HUF, Hungarian Forint"
    // },
    // {
    //     "value": "IDR",
    //     "title": "IDR, Indonesian Rupiah"
    // },
    // {
    //     "value": "ILS",
    //     "title": "ILS, Israeli New Sheqel"
    // },
    // {
    //     "value": "INR",
    //     "title": "INR, Indian Rupee"
    // },
    // {
    //     "value": "IQD",
    //     "title": "IQD, Iraqi Dinar"
    // },
    // {
    //     "value": "IRR",
    //     "title": "IRR, Iranian Rial"
    // },
    // {
    //     "value": "ISK",
    //     "title": "ISK, Icelandic Króna"
    // },
    // {
    //     "value": "JMD",
    //     "title": "JMD, Jamaican Dollar"
    // },
    // {
    //     "value": "JOD",
    //     "title": "JOD, Jordanian Dinar"
    // },
    // {
    //     "value": "JPY",
    //     "title": "JPY, Japanese Yen"
    // },
    // {
    //     "value": "KES",
    //     "title": "KES, Kenyan Shilling"
    // },
    // {
    //     "value": "KHR",
    //     "title": "KHR, Cambodian Riel"
    // },
    // {
    //     "value": "KMF",
    //     "title": "KMF, Comorian Franc"
    // },
    {
        value: "KRW",
        title: "KRW, South Korean Won",
    },
    // {
    //     "value": "KWD",
    //     "title": "KWD, Kuwaiti Dinar"
    // },
    // {
    //     "value": "KZT",
    //     "title": "KZT, Kazakhstani Tenge"
    // },
    // {
    //     "value": "LBP",
    //     "title": "LBP, Lebanese Pound"
    // },
    // {
    //     "value": "LKR",
    //     "title": "LKR, Sri Lankan Rupee"
    // },
    // {
    //     "value": "LTL",
    //     "title": "LTL, Lithuanian Litas"
    // },
    // {
    //     "value": "LVL",
    //     "title": "LVL, Latvian Lats"
    // },
    // {
    //     "value": "LYD",
    //     "title": "LYD, Libyan Dinar"
    // },
    // {
    //     "value": "MAD",
    //     "title": "MAD, Moroccan Dirham"
    // },
    // {
    //     "value": "MDL",
    //     "title": "MDL, Moldovan Leu"
    // },
    // {
    //     "value": "MGA",
    //     "title": "MGA, Malagasy Ariary"
    // },
    // {
    //     "value": "MKD",
    //     "title": "MKD, Macedonian Denar"
    // },
    // {
    //     "value": "MMK",
    //     "title": "MMK, Myanma Kyat"
    // },
    // {
    //     "value": "MOP",
    //     "title": "MOP, Macanese Pataca"
    // },
    // {
    //     "value": "MUR",
    //     "title": "MUR, Mauritian Rupee"
    // },
    // {
    //     "value": "MXN",
    //     "title": "MXN, Mexican Peso"
    // },
    // {
    //     "value": "MYR",
    //     "title": "MYR, Malaysian Ringgit"
    // },
    // {
    //     "value": "MZN",
    //     "title": "MZN, Mozambican Metical"
    // },
    // {
    //     "value": "NAD",
    //     "title": "NAD, Namibian Dollar"
    // },
    // {
    //     "value": "NGN",
    //     "title": "NGN, Nigerian Naira"
    // },
    // {
    //     "value": "NIO",
    //     "title": "NIO, Nicaraguan Córdoba"
    // },
    // {
    //     "value": "NPR",
    //     "title": "NPR, Nepalese Rupee"
    // },
    // {
    //     "value": "NZD",
    //     "title": "NZD, New Zealand Dollar"
    // },
    // {
    //     "value": "OMR",
    //     "title": "OMR, Omani Rial"
    // },
    // {
    //     "value": "PAB",
    //     "title": "PAB, Panamanian Balboa"
    // },
    // {
    //     "value": "PEN",
    //     "title": "PEN, Peruvian Nuevo Sol"
    // },
    // {
    //     "value": "PHP",
    //     "title": "PHP, Philippine Peso"
    // },
    // {
    //     "value": "PKR",
    //     "title": "PKR, Pakistani Rupee"
    // },
    // {
    //     "value": "PLN",
    //     "title": "PLN, Polish Zloty"
    // },
    // {
    //     "value": "PYG",
    //     "title": "PYG, Paraguayan Guarani"
    // },
    // {
    //     "value": "QAR",
    //     "title": "QAR, Qatari Rial"
    // },
    // {
    //     "value": "RON",
    //     "title": "RON, Romanian Leu"
    // },
    // {
    //     "value": "RSD",
    //     "title": "RSD, Serbian Dinar"
    // },
    // {
    //     "value": "RUB",
    //     "title": "RUB, Russian Ruble"
    // },
    // {
    //     "value": "RWF",
    //     "title": "RWF, Rwandan Franc"
    // },
    // {
    //     "value": "SAR",
    //     "title": "SAR, Saudi Riyal"
    // },
    // {
    //     "value": "SDG",
    //     "title": "SDG, Sudanese Pound"
    // },
    // {
    //     "value": "SEK",
    //     "title": "SEK, Swedish Krona"
    // },
    // {
    //     "value": "SGD",
    //     "title": "SGD, Singapore Dollar"
    // },
    // {
    //     "value": "SOS",
    //     "title": "SOS, Somali Shilling"
    // },
    // {
    //     "value": "SYP",
    //     "title": "SYP, Syrian Pound"
    // },
    // {
    //     "value": "THB",
    //     "title": "THB, Thai Baht"
    // },
    // {
    //     "value": "TND",
    //     "title": "TND, Tunisian Dinar"
    // },
    // {
    //     "value": "TOP",
    //     "title": "TOP, Tongan Paʻanga"
    // },
    // {
    //     "value": "TRY",
    //     "title": "TRY, Turkish Lira"
    // },
    // {
    //     "value": "TTD",
    //     "title": "TTD, Trinidad and Tobago Dollar"
    // },
    // {
    //     "value": "TWD",
    //     "title": "TWD, New Taiwan Dollar"
    // },
    // {
    //     "value": "TZS",
    //     "title": "TZS, Tanzanian Shilling"
    // },
    // {
    //     "value": "UAH",
    //     "title": "UAH, Ukrainian Hryvnia"
    // },
    // {
    //     "value": "UGX",
    //     "title": "UGX, Ugandan Shilling"
    // },
    // {
    //     "value": "UYU",
    //     "title": "UYU, Uruguayan Peso"
    // },
    // {
    //     "value": "UZS",
    //     "title": "UZS, Uzbekistan Som"
    // },
    // {
    //     "value": "VEF",
    //     "title": "VEF, Venezuelan Bolívar"
    // },
    // {
    //     "value": "VND",
    //     "title": "VND, Vietnamese Dong"
    // },
    // {
    //     "value": "XAF",
    //     "title": "XAF, CFA Franc BEAC"
    // },
    // {
    //     "value": "XOF",
    //     "title": "XOF, CFA Franc BCEAO"
    // },
    // {
    //     "value": "YER",
    //     "title": "YER, Yemeni Rial"
    // },
    // {
    //     "value": "ZAR",
    //     "title": "ZAR, South African Rand"
    // },
    // {
    //     "value": "ZMK",
    //     "title": "ZMK, Zambian Kwacha"
    // },
    // {
    //     "value": "ZWL",
    //     "title": "ZWL, Zimbabwean Dollar"
    // }
];

exports.convertToSEK = function (amount, currency) {
    switch (currency.toUpperCase()) {
        case "SEK": {
            return amount;
        }
        case "EUR": {
            return Math.round(amount * 11.5);
        }
        case "USD": {
            return Math.round(amount * 10.5);
        }
        case "NOK": {
            return Math.round(amount * 1);
        }
        case "ISK": {
            return Math.round(amount * 0.08);
        }
        case "UKL":
        case "GBP": {
            return Math.round(amount * 13);
        }
        case "JPY": {
            return Math.round(amount * 0.08);
        }
        case "CAD": {
            return Math.round(amount * 7.8);
        }
        case "MYR":
        case "SGD": {
            return Math.round(amount * 8);
        }
        case "AUD": {
            return Math.round(amount * 7);
        }
        case "CHF": {
            return Math.round(amount * 11.6);
        }
        case "DKK": {
            return Math.round(amount * 1.55);
        }
    }
};
