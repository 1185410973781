import React from "react";

const SalesCustomersFilter = ({ onChange, clientManagers }) => {
    return (
        <select
            disabled={!clientManagers}
            onChange={onChange}
            className={"border rounded w-40 p-2 mb-2 cursor-pointer"}
        >
            <option selected value={"All"}>
                {" "}
                Allt{" "}
            </option>
            {clientManagers?.map(({ title, value }) => (
                <option key={value} value={value}>
                    {" "}
                    {title}
                </option>
            ))}
        </select>
    );
};

export default SalesCustomersFilter;
