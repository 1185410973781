import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    padding: 1rem;

    > .title {
        font-weight: bold;
        margin-top: 1rem;
    }

    > .list {
        display: grid;
        grid-template: auto / repeat(4, minmax(200px, 1fr));
        grid-gap: 1rem;

        > .template {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            padding: 0.2rem 1rem;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.05);
            &:hover {
                background: rgba(0, 0, 0, 0.1);
            }

            > .name {
                color: #000;
                text-decoration: none;
            }

            > .remove {
                width: 16px;
                height: 16px;
                min-width: 16px;
                min-height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #aaa;
                cursor: pointer;

                &:hover {
                    color: #555;
                }

                > svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    > .buttons {
        padding: 1rem 0;
    }
`;

function Component({ ...props }) {
    const context = useContext(Context);
    const [integration, setIntegration] = useState(props.integration);

    async function removeIntegration() {
        if (!confirm("Är du säker på att du vill ta bort integrationen?")) {
            return;
        }
        await Api.removeCustomerIntegration({
            customerId: context.user.customer.id,
            integrationId: integration.id,
        });
        props.history.push("/addons");
    }

    return (
        <Container>
            <h1 className="text-2xl font-semibold mb-4">
                DIY - Skapa din egen integration
            </h1>
            <h3 className="text-xl font-semibold mb-2">
                Skicka in ordrar till Zendr Portalen
            </h3>
            <div>
                <strong>API-Nyckel:</strong> {integration.identifier}
            </div>
            <h3 className="font-bold mt-2">Instruktioner</h3>
            <p>
                För att skicka in ordrar till Zendr Portalen gör ett HTTP-anrop
                av typen POST till URL:en nedan.
            </p>
            <code className="bg-gray-200 p-2 rounded-md inline-block my-2">
                {process.env.REACT_APP_API_URL}
                /api/wh/6sFi0aqEv2X43g0k8h0etQfH3S7883322yg7C9KnNutrS6M9v6H6K35JOqum1712
            </code>
            <p>
                För autentisiering använder ni er av headern{" "}
                <span className="bg-gray-200 px-2 rounded-md">
                    integration-key
                </span>{" "}
                med er API- nyckel som värde.
            </p>
            <code className="bg-gray-200 p-2 rounded-md inline-block my-2">
                integration-key: {integration.identifier}
            </code>
            <p>
                I er request body skickar ni in ett order-objekt enligt nedan.
            </p>
            <code className="bg-gray-200 p-2 rounded-md inline-block my-2 text-gray-600 mb-10">
                {"{"}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;"id": "Orderns id i ert system",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;"reference": "Referens",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;"description": "Godsbeskrivning",
                {/* <br /> */}
                {/* &nbsp;&nbsp;&nbsp;&nbsp;"sender": null, */}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;"recipient": {"{"}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name":
                "Mottagarens Företag AB",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"contactName":
                "Sven Svensson",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"countryCode":
                "SE",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"addressLine1":
                "Adressrad 1",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"addressLine2":
                "Adressrad 2",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"addressLine3":
                "Adressrad 3",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"postalCode":
                "123 45",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"postalTown":
                "Postort",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"phoneNumber":
                "0789456123",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"email":
                "sven@exempel.se",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"vatNumber":
                "SE123781231201"
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;{"}"},
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;"packages": {"[{"}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"count": 1,
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"height": 20,
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"width": 20,
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"length": 25,
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"weight": 12,
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"type":
                "package"
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;{"}]"}
                <br />
                {"}"}
            </code>

            <div>
                <strong>Callback:</strong>
                <p>
                    Denna URL ({integration.data.callbackUrl}) kommer att
                    användas för att skicka händelsenotifikationer och
                    trackingnummerstatus när en lyckad orderbokning genomförts i
                    Zendr Portalen. <br />
                    Det innebär att ni kommer att få realtidsnotifikationer både
                    vid orderbokning och vid eventuella förändringar i
                    leveransstatus.
                </p>
                <code className="bg-gray-200 p-2 rounded-md inline-block my-2 text-gray-600 mb-10">
                    {"{"}
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;"orderId": "Ert id för ordern",
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;"trackingNumber": "Trackingnummer
                    för den bokade ordern",
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;"trackingUrl":
                    "https://portal.zendr.se/tracking/1234567890",
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;"status": "APPROVED", "SHIPPED",
                    "IN_TRANSIT", "DELIVERING",
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;"DELIVERED", "REJECTED", "CANCELLED"
                    <br />
                    {"}"}
                </code>
            </div>
            <div className="buttons">
                <button
                    className="c-button c-button--raised"
                    onClick={removeIntegration}
                >
                    Ta bort
                </button>
            </div>
        </Container>
    );
}

export default Component;
