import React, { Component } from "react";
import styled from "styled-components/macro";

let Container = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    min-height: 40px;

    > .inputContainer {
        background: #fff;
        padding: 4px;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 40px;
        overflow: hidden;

        > .icon {
            width: 16px;
            height: 16px;
            padding: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        > .backdrop {
            opacity: 0;
            position: fixed;
            z-index: 10;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        > .searchResult {
            max-height: 50vh;
            overflow-y: auto;
            position: absolute;
            opacity: 0;
            pointer-events: none;
            width: 100%;
            background: #fff;
            box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.3);
            border-radius: 5px;
            top: 100%;
            padding-bottom: 8px;
            z-index: 10;

            > .title {
                padding: 4px 8px;
                color: rgba(0, 0, 0, 0.5);
                font-weight: bold;
                font-size: 14px;
            }

            > .item {
                padding: 4px 8px;
                cursor: pointer;

                &.is-selected {
                    background: rgba(0, 0, 0, 0.04);
                }

                &:hover {
                    background: rgba(0, 0, 0, 0.02);
                }
            }

            &.is-visible {
                opacity: 1;
                pointer-events: all;
            }
        }

        > input {
            padding: 4px;
            border: none;
            outline: none;
            width: 100%;
        }

        > .length {
            font-size: 0.8rem;
            padding: 2px 4px;
            font-style: italic;
            font-weight: bold;
            color: #555;

            &.is-invalid {
                color: #ff5555;
            }
        }
    }

    > .suggestions {
        .overlay {
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 100;
        }

        > .list {
            position: absolute;
            background: #fff;
            min-width: 100%;
            box-shadow: 0 2px 2px 1px rgb(0 0 0 / 10%);
            z-index: 101;
            border-radius: 4px;
            max-height: 20vh;
            overflow: auto;

            > .suggestion {
                border-bottom: 1px solid #fafafa;
                white-space: nowrap;
                cursor: pointer;
                padding: 4px 8px;
                z-index: 2;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:hover {
                    background: rgba(0, 0, 0, 0.01);
                }

                > .text {
                }

                > .remove {
                    width: 12px;
                    height: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-left: 1rem;
                    box-sizing: content-box;
                    color: #777;

                    > svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    > .message {
        font-weight: bold;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
    }

    &.is-invalid {
        > .inputContainer {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }

        > .message {
            color: rgba(255, 0, 0, 0.6);
        }
    }
`;

class InputField extends Component {
    inputRef = React.createRef();
    cancelSearchToken = React.createRef();
    searchRequestHandle = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            value: props.value || "",
            countryCode: props.countryCode || "",
            searchResult: [],
            suggestions: props.suggestions || [],
            blurOnSelect: !!props.blurOnSelect,
            autoComplete: props.autoComplete,
            disabled: props.disabled,
            name: props.name,
            showSuggestions: false,
            message: null,
            selectedIndex: null,
        };

        if (typeof this.props.onKeyUp === "function") {
            this.onKeyUp = this.props.onKeyUp;
        }
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.close = this.close.bind(this);
        this.onChange = this.onChange.bind(this);
        this.select = this.select.bind(this);
        this.blur = this.blur.bind(this);
        this.focus = this.focus.bind(this);
        this.focusInputField = this.focusInputField.bind(this);
    }

    static getDerivedStateFromProps(props) {
        return {
            countryCode: props.countryCode,
            suggestions: props.suggestions || [],
            disabled: props.disabled,
        };
    }

    onChange(value) {
        this.setState(
            {
                value: value,
            },
            () => {
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this.state.value);
                }
            },
        );
    }

    onKeyUp() {}
    handleInputChange(newValue) {
        const inputValue = newValue.replace(/[^a-zA-Z0-9-]/g, "");
        this.setState({
            value: inputValue,
        });
        return inputValue;
    }

    value() {
        return this.state.value;
    }

    set(value) {
        this.setState({
            value: value,
        });
        this.inputRef.current.value = value;
    }

    empty() {
        this.setState({
            value: "",
        });
        this.inputRef.current.empty();
    }

    validate() {
        if (this.props.required && this.state.value.trim().length === 0) {
            this.setState({
                isInvalid: true,
                message: this.props.messages.required,
            });
            return false;
        }
        if (this.getLength() > this.props.maxLength) {
            this.setState({
                isInvalid: true,
                message: this.props.messages.maxLength,
            });
            return false;
        }
        if (this.getLength() < this.props.minLength) {
            this.setState({
                isInvalid: true,
                message: this.props.messages.minLength,
            });
            return false;
        }
        this.setState({
            isInvalid: false,
            message: null,
        });
        return true;
    }

    handleKeyPress(ev) {
        switch (ev.key) {
            case "Enter": {
                if (this.state.selectedIndex !== null) {
                    this.selectItem(
                        this.state.searchResult[this.state.selectedIndex],
                    );
                    ev.preventDefault();
                }
                break;
            }
            case "Escape": {
                this.close();
                break;
            }
        }
    }

    handleKeyUp(ev) {
        switch (ev.key) {
            case "ArrowDown": {
                if (this.state.selectedIndex === null) {
                    this.setState({
                        selectedIndex: 0,
                    });
                } else {
                    let nextIndex = this.state.selectedIndex + 1;
                    if (nextIndex >= this.state.searchResult.length) {
                        nextIndex = this.state.selectedIndex;
                    }
                    this.setState({
                        selectedIndex: nextIndex,
                    });
                }
                break;
            }
            case "ArrowUp": {
                if (this.state.selectedIndex === null) {
                    this.setState({
                        selectedIndex: this.state.searchResult.length - 1,
                    });
                } else {
                    let nextIndex = this.state.selectedIndex - 1;
                    if (nextIndex < 0) {
                        nextIndex = 0;
                    }
                    this.setState({
                        selectedIndex: nextIndex,
                    });
                }
                break;
            }
        }
    }

    selectItem(item) {
        if (typeof this.props.onSelect === "function") {
            this.props.onSelect(item);
        }
        this.setState({
            searchResult: [],
            selectedIndex: null,
        });
    }

    close() {
        this.setState({
            searchResult: [],
            selectedIndex: null,
        });
    }

    select(suggestion) {
        if (this.props.onSelect) {
            this.props.onSelect(suggestion.value || suggestion.title);
        }
        if (this.props.blurOnSelect) {
            this.blur();
        }
        setTimeout(() => {
            this.validate();
        }, 0);
    }

    blur() {
        this.setState({
            showSuggestions: false,
        });
        this.validate();
    }

    focus() {
        this.setState({
            showSuggestions: true,
        });
        if (typeof this.props.onFocus === "function") {
            this.props.onFocus();
        }
    }

    remove(suggestion) {
        if (this.props.onRemove) {
            this.props.onRemove(suggestion);
        }
    }

    focusInputField() {
        this.inputRef.current.focus();
    }

    getLength() {
        return this.state.value.length;
    }

    showLength() {
        if (
            this.props.maxLength &&
            (this.props.maxLength <= 10 ||
                this.getLength() / this.props.maxLength > 0.8)
        ) {
            return true;
        }
        return false;
    }
    getLengthClass() {
        let classes = ["length"];
        if (this.getLength() > this.props.maxLength) {
            classes.push("is-invalid");
        }
        return classes.join(" ");
    }

    render() {
        return (
            <Container className={this.state.isInvalid ? " is-invalid" : ""}>
                <div className="inputContainer">
                    {parseInt(this.state.searchResult.length) > 0 && (
                        <div className="backdrop" onClick={this.close}></div>
                    )}
                    <div
                        className={
                            "searchResult" +
                            (parseInt(this.state.searchResult.length) > 0
                                ? " is-visible"
                                : "")
                        }
                    >
                        <div className="title">Välj en postort</div>
                        {this.state.searchResult.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={
                                        "item" +
                                        (index === this.state.selectedIndex
                                            ? " is-selected"
                                            : "")
                                    }
                                    onClick={() => {
                                        this.selectItem(item);
                                    }}
                                >
                                    {item.title}
                                </div>
                            );
                        })}
                    </div>
                    <input
                        name={this.props.name}
                        ref={this.inputRef}
                        type="text"
                        onChange={(ev) => {
                            this.onChange(ev.target.value);
                        }}
                        value={this.state.value}
                        onFocus={this.focus}
                    />
                    {this.showLength() && (
                        <div className={this.getLengthClass()}>
                            {this.getLength()}/{this.props.maxLength}
                        </div>
                    )}
                    <div className="icon" onClick={this.focusInputField}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                        </svg>
                    </div>
                </div>
                {!!(
                    this.state.showSuggestions &&
                    this.state.suggestions &&
                    this.state.suggestions.length
                ) && (
                    <div className="suggestions">
                        <div className="overlay" onClick={this.blur}></div>
                        <div className="list">
                            {this.state.suggestions.map((suggestion) => {
                                return (
                                    <div
                                        key={suggestion.title}
                                        className="suggestion"
                                        onClick={() => this.select(suggestion)}
                                    >
                                        <div className="title">
                                            {suggestion.title}
                                        </div>
                                        {typeof this.props.onRemove ===
                                            "function" && (
                                            <div
                                                className="remove"
                                                onClick={(ev) => {
                                                    this.remove(suggestion);
                                                    ev.stopPropagation();
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                {this.state.message && (
                    <div className="message">{this.state.message}</div>
                )}
            </Container>
        );
    }
}

InputField.defaultProps = {
    disabled: false,
    messages: {
        required: "This is a required field",
        maxLength: "The field contains too many characters",
        minLength: "The field contains too few characters",
        invalidCharacter: "The phone number contains invalid characters",
    },
};

export default InputField;
