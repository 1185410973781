import InformationHover from "components/InformationHover";
import React, { useEffect } from "react";
import { validateEmail } from "./validateEmail";

function EmailInput({ inputState, onChange, name, shouldValidate }) {
    useEffect(() => {
        onChange({
            value: inputState.value,
            error: shouldValidate && !validateEmail(inputState.value),
        });
    }, [shouldValidate]);
    return (
        <div className="flex items-center">
            <input
                type="text"
                name={name}
                value={inputState.value}
                onChange={(e) => {
                    const newValue = e.target.value;
                    onChange({
                        value: newValue,
                        error: shouldValidate && !validateEmail(newValue),
                    });
                }}
                className="w-full p-2 rounded box-border outline-none border"
            />

            <div
                className={`text-red-500 flex justify-center ml-2 h-8 ${
                    shouldValidate ? "visible" : "invisible"
                }`}
            >
                <InformationHover
                    icon={
                        inputState.error
                            ? "/images/icons/error.svg"
                            : "/images/icons/check-circle.svg"
                    }
                    disabled={!inputState.error}
                >
                    Felaktigt format på e-postadress
                </InformationHover>
            </div>
        </div>
    );
}

export { EmailInput };
