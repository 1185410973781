import React from "react";
import { useTranslation } from "react-i18next";
import TrackingStep from "./TrackingStep";
import TrackingLine from "./TrackingLine";
import { ReactComponent as DeliveryIcon } from "../../TrackingIcons/DeliveryIcon.svg";
import { ReactComponent as PickupIcon } from "../../TrackingIcons/PickupIcon.svg";
import { ReactComponent as FreightTruckIcon } from "../../TrackingIcons/FreightTruckIcon.svg";
import { ReactComponent as BookedIcon } from "../../TrackingIcons/BookedIcon.svg";

const TrackingTimeline = ({ hasError, state }) => {
    const { t } = useTranslation();
    return (
        <div className="flex items-center justify-center gap-1 rounded-md bg-white p-4 lg:gap-3">
            <div className="flex items-center gap-1 lg:gap-3">
                <TrackingStep
                    hasError={hasError && state === "APPROVED"}
                    text={t("tracking.status.booked")}
                    icon={<BookedIcon />}
                    checked={[
                        "APPROVED",
                        "IN_TRANSIT",
                        "DELIVERING",
                        "DELIVERED",
                        "SHIPPED",
                    ].includes(state)}
                />
                <TrackingLine
                    checked={[
                        "IN_TRANSIT",
                        "DELIVERING",
                        "DELIVERED",
                        "SHIPPED",
                    ].includes(state)}
                />
            </div>
            <div className="flex items-center gap-1 lg:gap-3">
                <TrackingStep
                    hasError={hasError && state === "SHIPPED"}
                    text={t("tracking.status.pickedUp")}
                    icon={<PickupIcon />}
                    checked={[
                        "IN_TRANSIT",
                        "DELIVERING",
                        "DELIVERED",
                        "SHIPPED",
                    ].includes(state)}
                />
                <TrackingLine
                    checked={["IN_TRANSIT", "DELIVERING", "DELIVERED"].includes(
                        state,
                    )}
                />
            </div>
            <div className="flex items-center gap-1 lg:gap-3">
                <TrackingStep
                    hasError={hasError && state === "IN_TRANSIT"}
                    text={t("tracking.status.inTransit")}
                    icon={<FreightTruckIcon />}
                    checked={["IN_TRANSIT", "DELIVERING", "DELIVERED"].includes(
                        state,
                    )}
                />
                <TrackingLine
                    checked={["DELIVERING", "DELIVERED"].includes(state)}
                />
            </div>
            <div className="flex items-center gap-1 lg:gap-3">
                <TrackingStep
                    hasError={hasError && state === "DELIVERING"}
                    text={t("tracking.status.delivering")}
                    icon={<FreightTruckIcon />}
                    checked={["DELIVERING", "DELIVERED"].includes(state)}
                />
                <TrackingLine checked={["DELIVERED"].includes(state)} />
            </div>
            <div className="flex items-center gap-1 lg:gap-3">
                <TrackingStep
                    hasError={hasError && state === "DELIVERED"}
                    text={t("tracking.status.delivered")}
                    icon={<DeliveryIcon />}
                    checked={["DELIVERED"].includes(state)}
                />
            </div>
        </div>
    );
};

export default TrackingTimeline;
