import Loader from "components/Loader/Loader";
import OrderSummary from "components/OrderSummary";
import Context from "context/Global";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import OrderSettings from "./Settings";
import toast from "react-hot-toast";

let Container = styled.div`
    box-sizing: border-box;
    padding: 16px;
    max-width: 1000px;
`;

let Content = styled.div`
    > .loader {
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

let Title = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.6);
`;

let Card = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 8px;
    min-height: 60px;
    align-items: flex-start;
    background: #fff;
    padding: 16px > .verification {
        display: flex;
        align-items: center;
        width: 100%;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding-top: 16px;
        margin-top: 32px;

        > .service {
            max-height: 40px;
            max-width: 100px;
        }
        > .price {
            font-size: 24px;
            padding-left: 32px;
            font-weight: bold;

            > .loader {
                height: 20px;
                width: 20px;
            }

            > .transport {
                .unit {
                    font-size: 0.6em;
                }
            }

            > .insurance {
                font-size: 0.6em;

                .unit {
                    font-size: 0.6em;
                }
            }
        }

        > .u-push-left {
            margin-left: auto;
        }
    }

    > .message {
        > .text {
            font-weight: bold;
            font-size: 14px;
            color: #900;
        }

        > .details {
            font-size: 12px;
            color: #900;
        }
    }
`;

class CreateOrder extends Component {
    packages = React.createRef();
    information = React.createRef();
    orderOptions = React.createRef();
    pickupPickerRef = React.createRef();
    shipmentRoute = null;
    shipmentPackages = null;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            bookingOrder: false,
            loadingPrice: false,
            message: "",
            from: null,
            to: null,
            packages: null,
            information: null,
            pickup: null,
            delivery: null,
            pickupNotAvailable: false,
        };

        this.bookOrder = this.bookOrder.bind(this);
        this.onOptionsChanged = this.onOptionsChanged.bind(this);
        this.quoteForPrice = this.quoteForPrice.bind(this);
        this.setPickupOptions = this.setPickupOptions.bind(this);
        this.onPickupNotAvailable = this.onPickupNotAvailable.bind(this);
    }

    componentWillMount() {
        this.setState({
            quotation: this.context.quotation,
            loading: false,
        });
    }

    componentDidMount() {}

    bookOrder(settings) {
        this.setState({
            loading: true,
            bookingOrder: true,
            message: "",
        });

        let order = {
            ...this.context.getApiOrderObject(),
        };
        Api.createOrder({
            service: this.context.quotation.service,
            price: this.context.quotation.price,
            order: order,
            reference: order.information.reference,
            pickup: settings.pickup,
            delivery: settings.delivery,
            additionalOptions: settings.additionalOptions,
            shipmentOptions: settings.shipmentOptions,
            notifications: settings.notifications,
            dryIce: settings.dryIce,
            lithiumSettings: settings.lithiumSettings,
            insurance: settings.insurance,
            palletExchange: settings.palletExchange,
            receiverPays: settings.receiverPays,
        })
            .then(async (response) => {
                localStorage.removeItem("order");
                this.context.clearOrder();
                this.context.setUser(await Api.getCurrentUser());

                const tailLiftLoading = settings?.additionalOptions?.find(
                    (option) => {
                        if (option.value && option.name === "tailLiftLoading") {
                            return true;
                        }
                        return false;
                    },
                );
                const tailLiftUnloading = settings?.additionalOptions?.find(
                    (option) => {
                        if (
                            option.value &&
                            option.name === "tailLiftUnloading"
                        ) {
                            return true;
                        }
                        return false;
                    },
                );

                if (this.context?.quotation?.price?.total > 10000) {
                    this.context.socket.emit(
                        "createTicket",
                        {
                            title: `Bevakning ${response.data.trackingNumber}`,
                            type: "OTHER",
                            trackingNumber: response.data.trackingNumber,
                            data: {},
                        },
                        async () => {},
                    );
                }

                if (tailLiftLoading || tailLiftUnloading) {
                    if (!this.context.socket.connected) {
                        alert(
                            `Vi lyckades inte informera vår kundtjänst angående bakgavellift. Ring ${this.context.user.customer.brand.supportPhoneNumber} så tar vi reda på kostnaden åt dig!`,
                        );
                        this.props.history.push(`/orders/${response.data.id}`);
                    } else {
                        this.context.socket.emit(
                            "createTicket",
                            {
                                title: `Prisfråga bakgavellift ${response.data.trackingNumber}`,
                                type: "OTHER",
                                trackingNumber: response.data.trackingNumber,
                                data: {},
                            },
                            async (newTicket) => {
                                if (!newTicket) {
                                    alert(
                                        `Vi lyckades inte informera vår kundtjänst angående bakgavellift. Ring ${this.context.user.customer.brand.supportPhoneNumber} så tar vi reda på kostnaden åt dig!`,
                                    );
                                }
                                this.props.history.push(
                                    `/orders/${response.data.id}`,
                                );
                            },
                        );
                    }
                } else {
                    this.props.history.push(`/orders/${response.data.id}`);
                }
            })
            .catch((error) => {
                const errorObject = error.toJSON();
                if (errorObject.message === "Network Error") {
                    this.props.history.push(`/orders`);
                } else {
                    let details = "Unknown error";
                    if (error && error.response && error.response.data) {
                        if (
                            error.response.data.service &&
                            error.response.data.service.name
                        ) {
                            details = `${error.response.data.service.name.toUpperCase()}: `;
                        }
                        details += `"${error.response.data.message}"`;
                    }
                    this.setState({
                        loading: false,
                        message: "Beställningen kunde inte genomföras",
                        details: details,
                    });
                }
            });
    }

    onOptionsChanged(value) {
        this.setState(
            {
                extras: value,
            },
            () => {
                this.quoteForPrice();
            },
        );
    }

    setPickupOptions(data) {
        this.setState(
            {
                pickup: data.pickup,
                delivery: data.delivery,
            },
            () => {
                if (data.updateQuote) {
                    this.quoteForPrice();
                }
            },
        );
    }

    quoteForPrice() {
        this.setState({
            loadingPrice: true,
        });

        let query = this.context.getApiOrderObject();

        query.service = this.context.quotation.service;
        query.extras = this.state.extras;

        Api.quoteOrder({
            quote: query,
        })
            .then((response) => {
                if (response.data.code === 500) {
                    this.setState({
                        message: "Meddelande från leverantören",
                        details: response.data.error,
                        loading: false,
                        loadingPrice: false,
                        quotation: {
                            price: "-",
                            unit: "",
                        },
                    });
                } else {
                    this.context.setQuotation(response.data);
                    this.setState({
                        loading: false,
                        loadingPrice: false,
                    });
                }
            })
            .catch((error) => {
                toast.error(
                    "Det gick inte att hämta prisförfrågan just nu, försök gärna igen eller kontakta kundtjänst",
                );
                console.error("error!");
                console.error(error);
            });
    }

    onPickupNotAvailable() {
        this.setState({
            pickupNotAvailable: true,
        });
    }

    onSettingsChange() {}

    render() {
        if (!this.context.quotation || !this.context.order.sender) {
            return <Redirect to="/quotations/create" />;
        }
        if (this.state.loading) {
            return (
                <Container>
                    <Content>
                        <div className="loader">
                            <Loader />
                            {this.state.bookingOrder && (
                                <div>Många kolli kan ta en minut eller mer</div>
                            )}
                        </div>
                    </Content>
                </Container>
            );
        } else {
            return (
                <Container>
                    <Content>
                        <Title>Bekräfta uppgifter och pris</Title>
                        <OrderSummary order={this.context.order} />
                        <OrderSettings
                            order={this.context.order}
                            service={this.context.quotation.service}
                            onChange={this.onSettingsChange}
                            bookOrder={this.bookOrder}
                            receiverPays={this.context.quotation.receiverPays}
                        />
                    </Content>
                </Container>
            );
        }
    }
}

CreateOrder.contextType = Context;

export default CreateOrder;
