import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Loader from "components/Loader/Loader";
import Input from "components/OldInput";
import Table from "components/Table";
import Context from "context/Global";
import useDebounce from "hooks/useDebounce";
import Api from "services/Api/Api";
import OrderRow from "../Orders/Row";
import toast from "react-hot-toast";

const MissedPickups = (props) => {
    const dateFromUrl = props.location.search.replace("?date=", "");

    const context = useContext(Context);
    const [date, setDate] = useState(
        dateFromUrl || moment().subtract(1, "day").format("YYYY-MM-DD"),
    );
    const debouncedDate = useDebounce(date, 700);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    async function loadOrders() {
        if (moment(date).isValid() === false) return;
        try {
            setLoading(true);
            const response = await Api.getMissedPickups({
                startDate: date,
            });
            setOrders(response?.orders);

            setLoading(false);
        } catch (error) {
            toast.error("Det gick inte att hämta upphämtningar. Försök igen.");
        }
    }

    useEffect(() => {
        loadOrders();

        if (date) {
            history.push(`/admin/missed-pickups/?date=${date}`);
        }
    }, [debouncedDate]);

    return (
        <div className="flex flex-col p-3 relative">
            <div className="pb-3">
                <div className="input flex items-center gap-10">
                    <div>
                        <label className="title text-sm font-medium">
                            Ändra datum
                        </label>
                        <Input
                            type="date"
                            key={date}
                            value={date}
                            onChange={(value) => {
                                setDate(value);
                            }}
                        />
                    </div>
                    <div className="self-end">
                        <button
                            onClick={() => {
                                setDate(
                                    moment(date)
                                        .subtract(1, "days")
                                        .format("YYYY-MM-DD"),
                                );
                            }}
                        >
                            <img
                                src="/images/icons/chevron-left.svg"
                                alt="chevron-left"
                            ></img>
                        </button>
                        <button
                            onClick={() =>
                                setDate(
                                    moment(date)
                                        .add(1, "days")
                                        .format("YYYY-MM-DD"),
                                )
                            }
                            disabled={moment(date).isSame(moment(), "day")}
                        >
                            <img
                                src="/images/icons/chevron-right.svg"
                                alt="chevron-right"
                            ></img>
                        </button>
                    </div>
                </div>
            </div>
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Försändelse</th>
                        <th>Datum</th>
                        {context.user.administrator && <th>Brand</th>}
                        <th>Avsändare</th>
                        <th>Mottagare</th>
                        <th className="flex gap-1">
                            <p>Information</p>
                        </th>
                        <th>Pris</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {!!orders.length &&
                        orders.map((order) => {
                            return (
                                <OrderRow
                                    key={order.id}
                                    order={order}
                                    notPickedUp={true}
                                />
                            );
                        })}
                </tbody>
            </Table>
            <div className="flex w-full py-4 justify-center">
                {loading && <Loader>Laddar...</Loader>}
                {!loading && !orders.length && (
                    <h3 className=" w-full p-3 m-auto text-center">
                        Inga missade upphämtningar
                    </h3>
                )}
            </div>
        </div>
    );
};

export default MissedPickups;
