import Input from "components/OldInput";
import Context from "context/Global";
import moment from "moment";
import "moment/locale/sv";
import React, { useContext, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    > .title {
        padding: 0 1rem;
        font-size: 1.5rem;
    }

    > .input {
        padding: 1rem;
        display: flex;
        gap: 1rem;
    }
`;

function Component(props) {
    const context = useContext(Context);
    const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));

    async function generateEmissionReport() {
        // Download Excel data
        const data = await Api.getEmissionsReport({
            customerId: context.user.customer.id,
            year: selectedYear,
        });

        var hiddenElement = document.createElement("a");
        hiddenElement.href =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
            data;
        hiddenElement.target = "_blank";

        //provide the name for the CSV file to be downloaded
        hiddenElement.download = `Utsläppsrapport ${context.user.customer.name} ${selectedYear}`;
        hiddenElement.click();
    }

    return (
        <Container>
            <div className="title">Utsläpps-rapport</div>
            <div className="input">
                <Input
                    type="dropdown"
                    value={selectedYear}
                    onChange={setSelectedYear}
                    options={[0, 1, 2].map((i) => {
                        return {
                            value: moment().subtract(i, "year").format("YYYY"),
                        };
                    })}
                />
                <div
                    className="c-button c-button--raised"
                    onClick={generateEmissionReport}
                >
                    Generera rapport
                </div>
            </div>
        </Container>
    );
}

export default Component;
