import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import Api from "../../../../services/Api/Api";
import Context from "../../../../context/Global";

const ViewIntegrationDetails = ({ integration, history }) => {
    const context = useContext(Context);
    const [removing, setRemoving] = useState(false);

    if (!integration) {
        return <p>Ingen integrationsdata tillgänglig.</p>;
    }

    const { identifier: apiKey, created } = integration;

    const copyApiKeyToClipboard = () => {
        navigator.clipboard.writeText(apiKey);
        toast.success("API-nyckeln kopierad till urklipp!");
    };

    async function removeIntegration() {
        if (!confirm("Är du säker på att du vill ta bort integrationen?")) {
            return;
        }
        setRemoving(true);
        try {
            await Api.removeCustomerIntegration({
                customerId: context.user.customer.id,
                integrationId: integration.id,
            });
            history.push("/addons");
        } catch (error) {
            toast.error("Något gick fel när integrationen skulle tas bort.");
            setRemoving(false);
        }
    }

    function calculatePrice(creationDate) {
        const today = new Date();
        const chargeEffectiveDate = new Date(creationDate);
        chargeEffectiveDate.setDate(chargeEffectiveDate.getDate() + 30);

        return today < chargeEffectiveDate ? "0 kr/månad" : "299 kr/månad";
    }

    return (
        <>
            <div className="my-10 ml-10">
                <h2 className="text-2xl font-semibold mb-4">
                    Detaljer för Pyramid Integration
                </h2>
                <div className="space-y-2 mb-4 text-left">
                    <p>
                        <strong>API-nyckel:</strong> {apiKey}
                        <button
                            onClick={copyApiKeyToClipboard}
                            className="c-button--raised rounded-sm px-2 py-1 text-sm transition duration-300 ease-in-out ml-2"
                        >
                            Kopiera
                        </button>
                    </p>
                    <p>
                        <strong>Pris:</strong> {calculatePrice(created)}
                    </p>
                    <p>
                        <strong>Skapad:</strong>{" "}
                        {new Date(created).toLocaleDateString("sv-SE")}
                    </p>
                </div>
                <div className="mb-4 text-base text-left">
                    <h3 className="text-xl font-semibold">
                        Funktioner och Fördelar
                    </h3>
                    <p>
                        Genom att integrera Pyramid mot Zendr Portalen skapas
                        ett smidigare arbetsflöde för att överföra och hantera
                        ordrar.
                        <br />
                        Klicka på "Skicka till Zendr" för att flytta en order
                        till Zendr Portalen.
                        <br />
                        Ordern visas sedan i "Batch"-vyn där transportbokning
                        för en eller flera ordrar kan utföras.
                    </p>
                    <p className="mt-4">
                        Efter framgångsrik transportbokning returneras data till
                        Pyramid.
                        <br />
                        Information så som boknings-ID, transportör, pris och
                        aktuell transportstatus blir tillgänglig i Pyramid.
                        <br />
                        Statusen uppdateras fortlöpande för att ge dig
                        realtidsinsikt över transportens förlopp.
                    </p>
                    <p className="mt-4">
                        Nyckelinformationen som du kan dra nytta av i Pyramid:
                    </p>
                    <ul className="list-inside list-disc mb-6 ml-2">
                        <li>Boknings-ID för att spåra varje transport.</li>
                        <li>
                            Transportören som ger insyn i vem som levererar din
                            order.
                        </li>
                        <li>Priset så att du kan hålla koll på kostnaderna.</li>
                        <li>
                            Transportstatusen som håller dig uppdaterad om
                            leveransens framsteg.
                        </li>
                    </ul>
                    <h3 className="text-xl font-semibold">
                        Grundkrav Pyramid:
                    </h3>
                    <ul className="list-inside list-disc mb-6 ml-2">
                        <li>
                            Integrationen är anpassad för version 4.16 eller
                            senare
                        </li>
                        <li>Användarstudion</li>
                        <li>Konsultstudion</li>
                        <li>Order/Lager/Inköp (rutin 8013,410)</li>
                    </ul>
                </div>

                <button
                    className="c-button--raised w-1/5 text-white p-1 rounded"
                    onClick={removeIntegration}
                    disabled={removing}
                >
                    Ta bort integration
                </button>
            </div>
        </>
    );
};

export default ViewIntegrationDetails;
