import React from "react";
const WeekFilter = ({ onChange }) => {
    const handleWeekFilter = ({ target: { value } }) => {
        switch (value) {
            case "THIS_WEEK":
                onChange(false);
                break;
            case "LAST_WEEK":
                onChange(true);
                break;
            default:
                break;
        }
    };
    return (
        <select
            onChange={handleWeekFilter}
            className={"border rounded w-40 p-2 mb-2 cursor-pointer"}
        >
            <option selected value={"THIS_WEEK"}>
                {" "}
                Denna veckan
            </option>
            <option value={"LAST_WEEK"}> Föregående vecka </option>
        </select>
    );
};

export default WeekFilter;
