import Loader from "components/Loader/Loader";
import Context from "context/Global";
import { getTransporterLogo } from "helpers/Logos";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

let Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 1rem;
    margin-top: 1rem;

    > .service {
        max-height: 40px;
        max-width: 100px;
        margin-right: 1rem;
    }

    > .price {
        display: flex;
        flex-direction: column;

        > .amount {
            font-size: 24px;
            font-weight: bold;
        }

        > .loader {
            height: 20px;
            width: 20px;
        }

        > .note {
            font-size: 0.8rem;
            padding-left: 32px;
        }

        > .transport {
            .unit {
                font-size: 0.6em;
            }
        }

        > .insurance {
            font-size: 0.6em;

            .unit {
                font-size: 0.6em;
            }
        }
    }

    > .u-push-left {
        margin-left: auto;
    }
`;

function Component({
    note = null,
    proceedWithoutPrice,
    receiverPays,
    ...props
}) {
    const [price, setPrice] = useState(props.price);
    const [message, setMessage] = useState(props.message);
    const [disabled, setDisabled] = useState(props.disabled);
    const context = useContext(Context);

    useEffect(() => {
        setPrice(props.price);
    }, [props.price]);
    useEffect(() => {
        setMessage(props.message);
    }, [props.message]);
    useEffect(() => {
        setDisabled(props.disabled);
    }, [props.disabled]);

    const isAwaitingPricingDetails =
        price === null && !proceedWithoutPrice && !message;

    const renderPriceDetails = () => {
        if (isAwaitingPricingDetails) {
            if (receiverPays) {
                return <div>Mottagaren betalar</div>;
            } else {
                return (
                    <div className={"price" + (true ? " is-loading" : "")}>
                        <div className="loader">
                            <Loader />
                        </div>
                    </div>
                );
            }
        }
        return null;
    };

    return (
        <>
            <Container>
                <img
                    className="service"
                    src={`/images/icons/${getTransporterLogo(
                        props.service.name,
                    )}`}
                />
                {price === null && proceedWithoutPrice && (
                    <div className="price">
                        <div className="amount">Fortsätt utan pris</div>
                        {note && <div className="note">* {note}</div>}
                    </div>
                )}
                {renderPriceDetails()}
                {price && !proceedWithoutPrice && (
                    <div className="price">
                        <div className="amount">
                            {price.total} {price.unit}
                        </div>
                        {note && <div className="note">* {note}</div>}
                    </div>
                )}
                {!price && message && <div className="message">{message}</div>}
                <Link
                    to={
                        context.order?.customs
                            ? "/orders/customs/create"
                            : "/quotations/create"
                    }
                    className="u-push-left c-button c-button--text"
                >
                    Tillbaka
                </Link>
                <button
                    name="confirm-order"
                    className={
                        "c-button c-button--raised" +
                        ((!price && !proceedWithoutPrice && !receiverPays) ||
                        disabled
                            ? " is-disabled"
                            : "")
                    }
                    onClick={() => {
                        if (props?.valid) {
                            if (props?.valid()) {
                                props.bookOrder();
                            }
                        } else {
                            props.bookOrder();
                        }
                    }}
                    disabled={disabled}
                >
                    Bekräfta & beställ
                </button>
            </Container>
        </>
    );
}

export default Component;
