import Context from "context/Global";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import parseGoodsRows from "./parseGoodsRows";
import toast from "react-hot-toast";

let Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.1);
    z-index: 100;

    > .popup {
        background: #fff;
        box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.1);
        padding: 1em;
        width: 52vw;

        > .header {
            display: flex;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            justify-content: space-between;
            padding-bottom: 1em;
            align-items: center;

            > .title {
                font-size: 1.6em;
            }

            > .close {
                cursor: pointer;
                width: 20px;
                height: 20px;
                margin-right: 0.5em;

                > svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        > .dropzone {
            width: full;
            height: 50vh;
            border: 2px dashed rgba(0, 0, 0, 0.1);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2em;
        }

        > .preview {
            width: 50vw;
            height: 50vh;
        }

        > .buttons {
            display: flex;
            justify-content: flex-end;
            padding-top: 1em;
        }
    }
`;

function View(props) {
    const context = useContext(Context);
    const [dndMessage, setDndMessage] = useState(null);
    const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [blobUrl, setBlobUrl] = useState(null);
    const [previewPdf, setPreviewPdf] = useState(false);
    const [previewGoodsRows, setPreviewGoodsRows] = useState(false);
    const [goodsRows, setGoodsRows] = useState(null);
    const [goodsRowsValue, setGoodsRowsValue] = useState(null);
    const [previewGoodsRowsError, setPreviewGoodsRowsError] = useState(null);
    const onDrop = useCallback(async (acceptedFiles) => {
        let formData = new FormData();

        acceptedFiles.forEach((file) => {
            formData.append("files", file, file.name);
        });

        try {
            const response = await Api.uploadCustomsInvoice({
                formData: formData,
            });
            setFile(response);
            setPreviewUrl(
                `${
                    process.env.REACT_APP_API_URL
                }/api/s3/shipment?objectKey=${encodeURIComponent(
                    response.path,
                )}`,
            );
        } catch (error) {
            toast.error("Något gick fel när tullfaktura skulle laddas upp");
        }
    }, []);

    useEffect(() => {
        if (previewPdf && previewUrl) {
            fetch(previewUrl)
                .then((response) => response.blob())
                .then((blob) => {
                    const url = URL.createObjectURL(blob);
                    setBlobUrl(url);
                })
                .catch((error) =>
                    console.error("Error fetching the PDF", error),
                );
        }

        return () => {
            if (blobUrl) URL.revokeObjectURL(blobUrl); // Releasing memory when component unmounts or previewPdf is false.
        };
    }, [previewPdf, previewUrl]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    const handleFileChange = async (event) => {
        setGoodsRowsValue(event.target.value);
        const parsedGoods = await parseGoodsRows(event);

        if (event?.target?.files?.[0]?.name === undefined) {
            setPreviewGoodsRowsError(null);
            return setGoodsRows(null);
        }

        if (parsedGoods) {
            const diffCurrency = parsedGoods?.commodities.filter(
                (commodity) =>
                    commodity?.currency !== context.order.information.currency,
            );

            if (diffCurrency.length > 0) {
                return setPreviewGoodsRowsError(
                    "Vi stödjer inte tulldeklarationer med olika valutor än din order.",
                );
            }

            setPreviewGoodsRowsError(null);
            return setGoodsRows(parsedGoods);
        } else {
            setPreviewGoodsRowsError("Vi stödjer inte det här formatet.");
        }
    };

    useEffect(() => {
        if (context.order.customsInvoice) {
            props.onClose();
        }
    }, [context.order.customsInvoice]);

    function close() {
        context.updateOrder(context.getApiOrderObject());
        props.onClose();
    }
    function cancel() {
        setFile(null);
        setPreviewGoodsRows(false);
        setPreviewUrl(null);
        setPreviewPdf(false);
        setGoodsRowsValue("");
        setPreviewGoodsRowsError(null);
    }

    function proceed() {
        // Context set uploaded invoice*
        context.updateOrder({
            customs: null,
            customsInvoice: file,
            customsCommodities: goodsRows?.commodities,
        });
    }

    return (
        <Container>
            <div className="popup">
                <div className="header">
                    <div className="title">Ladda upp din tullfaktura</div>
                    <div className="close" onClick={close}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
                            />
                        </svg>
                    </div>
                </div>
                {!previewPdf && previewGoodsRows !== true && (
                    <>
                        <div className="dropzone" {...getRootProps()}>
                            {isDragActive ? (
                                <p>Släpp filen här</p>
                            ) : dndMessage ? (
                                dndMessage
                            ) : (
                                <p>{file?.title || "Dra filen hit"}</p>
                            )}
                        </div>
                        {["dhl express"].includes(
                            context?.quotation?.service?.name,
                        ) && (
                            <div>
                                <h1 className="text-tem mt-3 mb-3">
                                    Ladda upp dina godsrader
                                </h1>
                                {previewGoodsRowsError && (
                                    <span>
                                        <p className="text-red-500">
                                            {previewGoodsRowsError}
                                        </p>
                                    </span>
                                )}
                                <div className="flex border-2 border-black/10 border-dotted p-4">
                                    <label className="flex flex-col">
                                        <input
                                            onChange={(e) =>
                                                handleFileChange(e)
                                            }
                                            type="file"
                                            value={goodsRowsValue}
                                        ></input>
                                    </label>
                                    <button
                                        className="ml-auto text-red-500"
                                        onClick={() => {
                                            setGoodsRowsValue("");
                                            setPreviewGoodsRowsError(null);
                                        }}
                                    >
                                        Radera
                                    </button>
                                </div>
                            </div>
                        )}

                        {previewUrl && (
                            <>
                                {!previewGoodsRowsError && (
                                    <div className="buttons">
                                        <div
                                            className="c-button c-button--ghost"
                                            onClick={cancel}
                                        >
                                            Avbryt
                                        </div>
                                        <div
                                            className="c-button c-button--raised"
                                            onClick={() => setPreviewPdf(true)}
                                        >
                                            Forsätt
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
                {previewPdf && blobUrl && (
                    <>
                        <embed
                            className="preview"
                            src={blobUrl}
                            type={"application/pdf"}
                        />
                        <div className="buttons">
                            <div
                                className="c-button c-button--ghost"
                                onClick={cancel}
                            >
                                Avbryt
                            </div>
                            <div
                                className="c-button c-button--raised"
                                onClick={
                                    goodsRows
                                        ? () => {
                                              setPreviewGoodsRows(true);
                                              setPreviewPdf(false);
                                          }
                                        : proceed
                                }
                            >
                                Använd faktura
                            </div>
                        </div>
                    </>
                )}
                {previewGoodsRows && (
                    <>
                        <div className="mt-5 ">
                            <div className="text-lg">
                                <p>
                                    Se till att dina godsrader är korrekta innan
                                    du går vidare.
                                </p>
                            </div>
                            <div className="mt-3 max-h-96 overflow-scroll bg-gray-200 flex p-2">
                                <table className="p-4 bg-gray-200">
                                    <thead>
                                        <tr>
                                            <th className="border border-black p-1">
                                                Rad
                                            </th>
                                            <th className="border border-black p-1">
                                                Beskrivning
                                            </th>
                                            <th className="border border-black p-1">
                                                Antal
                                            </th>
                                            <th className="border border-black p-1">
                                                Värde
                                            </th>
                                            <th className="border border-black p-1">
                                                Valuta
                                            </th>
                                            <th className="border border-black p-1">
                                                Land
                                            </th>
                                            <th className="border border-black p-1">
                                                Nettovikt
                                            </th>

                                            <th className="border border-black p-1">
                                                HS Kod
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {goodsRows?.commodities.map(
                                            (commodity, index) => (
                                                <tr key={index}>
                                                    <td className="border border-black p-1">
                                                        #{index + 1}
                                                    </td>
                                                    <td className="border border-black p-1">
                                                        {commodity?.description}
                                                    </td>
                                                    <td className="border border-black p-1">
                                                        {commodity?.quantity}
                                                    </td>
                                                    <td className="border border-black p-1">
                                                        {commodity?.value}
                                                    </td>
                                                    <td className="border border-black p-1">
                                                        {commodity?.currency}
                                                    </td>
                                                    <td className="border border-black p-1">
                                                        {commodity?.origin}
                                                    </td>
                                                    <td className="border border-black p-1">
                                                        {commodity?.netWeight ||
                                                            commodity?.grossWeight}
                                                    </td>
                                                    <td className="border border-black p-1">
                                                        {commodity?.hsCode}
                                                    </td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="buttons">
                            <div
                                className="c-button c-button--ghost"
                                onClick={cancel}
                            >
                                Avbryt
                            </div>
                            <div
                                className="c-button c-button--raised"
                                onClick={proceed}
                            >
                                Använd godsrader
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Container>
    );
}

export default View;
