import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TrackingWrapper from "./TrackingComponents/TrackingWrapper";
import ShipmentError from "./TrackingComponents/ShipmentError";
import Loader from "../../components/Loader/Loader";
import Api from "services/Api/Api";
import { useParams } from "react-router-dom";
import i18next from "i18next";

const TrackingView = () => {
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const language = urlParams.get("language");

    const { trackingNo } = useParams();
    const [shipment, setShipment] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchShipment = async () => {
        try {
            const shipmentData =
                await Api.getShipmentByTrackingNumber(trackingNo);
            if (shipmentData) {
                setShipment(shipmentData);
            } else {
                setShipment(null);
            }
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchShipment();
        i18next.changeLanguage(language);
    }, []);

    if (loading) {
        return <Loader />;
    }
    return (
        // Had to addd inline style because bg-[#f9f7f3] tailwind not working even with !important
        <div
            style={{ backgroundColor: "#f9f7f3" }}
            className="flex justify-center h-full"
        >
            {shipment ? (
                <TrackingWrapper trackingNo={trackingNo} shipment={shipment} />
            ) : (
                <ShipmentError errorMessage={errorMessage} />
            )}
        </div>
    );
};

export default TrackingView;
