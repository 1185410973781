import Input from "components/OldInput";
import Loader from "components/Loader/Loader";
import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import { isHttpsUrl } from "../../../../utils/url";

let Container = styled.div`
    > .title {
        font-size: 2rem;
        padding: 1rem;
    }
    > .description {
        padding: 0 1rem;
    }

    > .form {
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    > .buttons {
        padding: 1rem;
    }

    ul {
        padding: 0.5rem 0;
        margin-bottom: 2rem;
        > li {
            list-style-type: disc;
            list-style-position: inside;
            margin-left: 2rem;
        }
    }
`;

function Component(props) {
    const context = useContext(Context);
    const [data, setData] = useState({});
    const [plans, setPlans] = useState(null);
    const [identificationToken, setIdentificationToken] = useState();
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [callbackUrl, setCallbackUrl] = useState("");
    const [isCallbackUrlValid, setIsCallbackUrlValid] = useState(false);

    useEffect(() => {
        loadIntegration();
    }, []);

    useEffect(() => {
        if (plans && plans.length) {
            setData({
                type: "Custom",
                planId: plans[0].id,
            });
        }
    }, [plans]);

    async function loadIntegration() {
        setPlans(
            await Api.getIntegrationPlans({
                integrationId: 4,
            }),
        );
    }

    const handleCallbackUrlChange = (value) => {
        setCallbackUrl(value);
        setIsCallbackUrlValid(isHttpsUrl(value));
    };

    async function addIntegration() {
        try {
            if (isCallbackUrlValid) {
                setLoading(true);
                await Api.createCustomerIntegration({
                    customerId: context.user.customer.id,
                    data: data,
                });
                context.setUser(await Api.getCurrentUser());
                props.history.push("/addons");
            }
        } catch (error) {
            setErrorMessage(
                "Vi kunde inte aktivera integrationen med din aktiveringskod. Verifiera att du gjort rätt och testa igen.",
            );
            setLoading(false);
        }
    }

    if (plans === null) {
        return <Loader />;
    }

    return (
        <Container>
            <div className="title">
                Aktivering av integration och API-Nyckelåtkomst.
            </div>
            <div className="description mb-10">
                Här aktiverar du mot Zendr ́s DIY integration. Du får då tillgång
                till en API- nyckel. <br /> Denna använder du för att
                authentisera dig när du skickar ordrar från affärssystem till
                Zendr Portalen.
            </div>
            <h3 className="text-xl font-semibold ml-4">Att tänka på:</h3>
            <ul className="steps">
                <li>
                    Du behöver använda POST-requests för att skicka ordrar till
                    Zendr Portalen.
                </li>
                <li>
                    Callback URL som du uppger behöver vara öppen för Zendr och
                    ha en endpoint som Zendr kan nå.
                </li>
                <li>
                    Notifikationer och trackingdata kommer att skickas till den
                    Callback genom POST-requests.
                </li>
            </ul>
            {plans.length > 0 && (
                <>
                    <div className="form">
                        <Input
                            type="list"
                            onChange={(newData) => {
                                setData(newData);
                                handleCallbackUrlChange(
                                    newData.callbackUrl || "",
                                );
                            }}
                            object={{
                                type: {
                                    type: "text",
                                    hidden: true,
                                    value: "Custom",
                                },
                                callbackUrl: {
                                    type: "text",
                                    title: "Callback URL för Tracking",
                                    helpText:
                                        "Ange URL:n där trackingdata ska skickas. Denna URL kommer att användas för att skicka notifikationer om trackinghändelser.",
                                    placeholder: "https://dittföretag.com",
                                    value: callbackUrl,
                                },
                                planId: {
                                    type: "text",
                                    hidden: true,
                                    title: "Prenumerationstyp",
                                    value: plans[0].id,
                                },
                            }}
                        />
                    </div>
                    <div className="buttons">
                        <button
                            className="c-button c-button--raised"
                            disabled={!isCallbackUrlValid || loading}
                            onClick={addIntegration}
                        >
                            Aktivera
                        </button>
                    </div>
                </>
            )}
            {errorMessage && <div className="error">{errorMessage}</div>}
        </Container>
    );
}

export default Component;
