import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import Api from "services/Api/Api";
import DisclosureItem from "../DisclosureItem";
import PickupForm from "./PickupForm";

const Pickups = ({ customerId }) => {
    const [pickupData, setPickupData] = useState([]);
    const { data, refetch: refetchPickups } = useQuery(
        ["fixedPickups", customerId],
        Api.fetchAllPickupsByCustomerId,
        {
            onSuccess: (data) => {
                setPickupData(data);
            },
            refetchOnWindowFocus: false,
        },
    );

    const { mutateAsync: submitPickupForm } = useMutation(
        Api.updatePickupById,
        {
            onSuccess: () => {
                refetchPickups();
            },
        },
    );

    const submitHandler = (formBody) => {
        const pickupIndex = pickupData
            .map((item) => item.carrier)
            .indexOf(formBody.carrier);
        let newPickupData = [...pickupData];
        newPickupData[pickupIndex] = formBody;
        setPickupData(newPickupData);
        submitPickupForm({ formData: newPickupData, customerId });
    };

    return (
        <div className="min-w-[480px] grid grid-cols-1 gap-3 sm:grid-cols-2 2xl:grid-cols-3">
            {data?.map((item) => (
                <DisclosureItem
                    title={item.carrier}
                    key={item.carrier}
                    pickupData={item}
                >
                    <PickupForm
                        carrier={item.carrier}
                        handleSubmit={submitHandler}
                        defaultData={item}
                    />
                </DisclosureItem>
            ))}
        </div>
    );
};

export default Pickups;
