import React, { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import Checkbox from "./Checkbox";
import Input from "./Input";

const pickupActionsKind = {
    MONDAY: "MONDAY",
    TUESDAY: "TUESDAY",
    WEDNESDAY: "WEDNESDAY",
    THURSDAY: "THURSDAY",
    FRIDAY: "FRIDAY",
    UPDATE_TIME: "UPDATE_TIME",
    UPDATE_PACKAGES: "UPDATE_PACKAGES",
    UPDATE_PALLETS: "UPDATE_PALLETS",
    UPDATE_CAGES: "UPDATE_CAGES",
    RESET: "RESET",
    CLEAR: "CLEAR",
    DEFAULT_STATE: "DEFAULT_STATE",
};

const initialState = {
    time: "",
    packages: 0,
    cages: 0,
    pallets: 0,
    weekdays: {
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
    },
};

const reducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case pickupActionsKind.MONDAY:
            return {
                ...state,
                weekdays: { ...state.weekdays, mon: !state.weekdays.mon },
            };
        case pickupActionsKind.TUESDAY:
            return {
                ...state,
                weekdays: { ...state.weekdays, tue: !state.weekdays.tue },
            };
        case pickupActionsKind.WEDNESDAY:
            return {
                ...state,
                weekdays: { ...state.weekdays, wed: !state.weekdays.wed },
            };
        case pickupActionsKind.THURSDAY:
            return {
                ...state,
                weekdays: { ...state.weekdays, thu: !state.weekdays.thu },
            };
        case pickupActionsKind.FRIDAY:
            return {
                ...state,
                weekdays: { ...state.weekdays, fri: !state.weekdays.fri },
            };
        case pickupActionsKind.UPDATE_TIME:
            return { ...state, time: payload };
        case pickupActionsKind.UPDATE_PACKAGES:
            return { ...state, packages: +payload };
        case pickupActionsKind.UPDATE_CAGES:
            return { ...state, cages: +payload };
        case pickupActionsKind.UPDATE_PALLETS:
            return { ...state, pallets: +payload };
        case pickupActionsKind.DEFAULT_STATE:
            return payload;
        case pickupActionsKind.RESET:
            return initialState;
        case pickupActionsKind.CLEAR:
            return {
                time: "12:00",
                packages: 0,
                cages: 0,
                pallets: 0,
                weekdays: {
                    mon: false,
                    tue: false,
                    wed: false,
                    thu: false,
                    fri: false,
                },
            };
        default:
            throw new Error();
    }
};

const PickupForm = ({ carrier, handleSubmit, defaultData }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { t } = useTranslation();

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleSubmit({
            carrier: carrier,
            data: state,
        });
    };

    useEffect(() => {
        dispatch({
            type: pickupActionsKind.DEFAULT_STATE,
            payload: defaultData.data,
        });
    }, [defaultData]);

    return (
        <form
            className="grid grid-rows-2 gap-2 min-w-[250px]"
            onSubmit={handleFormSubmit}
        >
            <div className="flex flex-col items-center gap-3">
                <div className="flex gap-3">
                    <Checkbox
                        label={t("weekdays.monday.char")}
                        onChange={() => {
                            dispatch({ type: pickupActionsKind.MONDAY });
                        }}
                        value={state.weekdays.mon}
                    />
                    <Checkbox
                        label={t("weekdays.tuesday.char")}
                        onChange={() => {
                            dispatch({ type: pickupActionsKind.TUESDAY });
                        }}
                        value={state.weekdays.tue}
                    />
                    <Checkbox
                        label={t("weekdays.wednesday.char")}
                        onChange={() => {
                            dispatch({ type: pickupActionsKind.WEDNESDAY });
                        }}
                        value={state.weekdays.wed}
                    />
                    <Checkbox
                        label={t("weekdays.thursday.char")}
                        onChange={() => {
                            dispatch({ type: pickupActionsKind.THURSDAY });
                        }}
                        value={state.weekdays.thu}
                    />
                    <Checkbox
                        label={t("weekdays.friday.char")}
                        onChange={() => {
                            dispatch({ type: pickupActionsKind.FRIDAY });
                        }}
                        value={state.weekdays.fri}
                    />
                </div>
                <Input
                    label={t("pickups.time")}
                    width="w-36"
                    placeholder="14:00"
                    type="time"
                    defaultValue={state.time}
                    onChange={(e) => {
                        dispatch({
                            type: pickupActionsKind.UPDATE_TIME,
                            payload: e.target.value,
                        });
                    }}
                />
            </div>
            <div className="mt-4 flex items-center justify-center gap-4">
                <Input
                    label={t("pickups.boxes")}
                    width="w-16"
                    placeholder="5"
                    type="number"
                    defaultValue={state.packages}
                    onChange={(e) => {
                        dispatch({
                            type: pickupActionsKind.UPDATE_PACKAGES,
                            payload: e.target.value,
                        });
                    }}
                />
                <Input
                    label={t("pickups.cages")}
                    width="w-16"
                    placeholder="2"
                    type="number"
                    defaultValue={state.cages}
                    onChange={(e) => {
                        dispatch({
                            type: pickupActionsKind.UPDATE_CAGES,
                            payload: e.target.value,
                        });
                    }}
                />
                <Input
                    label={t("pickups.pallets")}
                    width="w-16"
                    placeholder="10"
                    type="number"
                    defaultValue={state.pallets}
                    onChange={(e) => {
                        dispatch({
                            type: pickupActionsKind.UPDATE_PALLETS,
                            payload: e.target.value,
                        });
                    }}
                />
            </div>
            <div className="mt-3 flex w-full justify-end gap-3">
                <Button
                    type="button"
                    variant={"reset"}
                    onClick={() => {
                        dispatch({ type: pickupActionsKind.CLEAR });
                    }}
                >
                    {t("button.reset")}
                </Button>
                <Button type="submit" variant={"submit"}>
                    {t("button.submit")}
                </Button>
            </div>
        </form>
    );
};

export default PickupForm;
