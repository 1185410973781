import Axios from "axios";
import Api from "services/Api/Api";

const LABEL = "labels";

export const printDocuments = async (printType, selectedSuccessfulOrderIds) => {
    let endpointUrl =
        printType === LABEL
            ? "/api/print/orders/labels"
            : "/api/print/orders/documents";

    const loadingWindow = window.open(
        "",
        "PRINT",
        "title=Laddar,height=800,width=800",
    );
    loadingWindow.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Laddar...</title>
            <style>
                .spinner {
                    margin: auto;
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    top: 0; bottom: 0; left: 0; right: 0;
                }
                .spinner:before {
                    content: '';
                    display: block;
                    margin: auto;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: 4px solid #ccc;
                    border-top-color: #333;
                    animation: spin 1s linear infinite;
                }
                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
            </style>
        </head>
        <body>
            <div class="spinner"></div>
        </body>
        </html>
`);
    loadingWindow.document.title =
        printType === LABEL ? "Etiketter" : "Fraktdokument";

    try {
        const apiUrl = process.env.REACT_APP_API_URL || "";
        const accessToken = Api.getAuthenticationToken();
        const urlParams = new URLSearchParams({
            accessToken,
            ids: JSON.stringify(selectedSuccessfulOrderIds),
        });
        const url = `${apiUrl}${endpointUrl}?${urlParams}`;

        const response = await Axios.get(url, {
            responseType: "blob",
        });

        const blob = new Blob([response.data], { type: "application/pdf" });

        const pdfUrl = URL.createObjectURL(blob);

        loadingWindow.document.body.innerHTML = `
            <style>
                #pdfEmbed {
                    width: 100vw;
                    height: 100vh;
                }
            </style>
            <embed id="pdfEmbed" src="${pdfUrl}" type="application/pdf" width="100%" height="100%" />
`;
    } catch (error) {
        loadingWindow.document.body.innerHTML =
            "Fel: Det gick inte att ladda innehållet";
    }
};
