import Input from "components/OldInput";
import axios from "axios";
import { PrettyState } from "constants/orderState";
import Context from "context/Global";
import { getTransporterLogo } from "helpers/Logos";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Link, withRouter } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import toast from "react-hot-toast";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;

    > .searchInput {
        padding: 1em;
    }

    > .maps {
        display: flex;

        > .map {
            width: 50%;
            padding: 1em;

            path {
                cursor: pointer;
                color: rgba(255, 255, 255, 1);
                stroke: rgba(160, 160, 160, 1);
                stroke-width: 1px;
                stroke-linejoin: round;
                transition: all 0.3s ease;
            }
            path:hover {
                color: rgba(160, 160, 160, 1);
                transition: all 0.1s ease;
            }
        }
    }

    > .graphContainer {
        display: flex;

        > .menu {
            width: 20%;
            display: flex;
            flex-direction: column;
            padding: 1rem;
            box-sizing: border-box;

            > .box {
                padding-top: 1rem;

                > .list {
                    max-height: 200px;
                    overflow: auto;
                }

                > .title {
                    font-weight: bold;
                }
            }
        }

        > .chart {
            width: 80%;
            height: 600px;
            margin: 1em 1em;
            display: flex;
            flex-direction: column;
            padding: 1em;
            background: #fff;
            border-radius: 5px;
            box-shadow: 0 3px 12px 1px rgba(0, 0, 0, 0.1);

            > .graph {
                width: 100%;
                height: 100%;
                flex-shrink: 1;
            }
        }
    }

    > .title {
        padding: 16px;
        font-size: 1.2em;
        color: #aaa;
    }

    > .newCustomers {
        display: flex;
        padding: 3rem 1rem 2rem;
        justify-content: center;

        + .newCustomers {
            padding-top: 0;
        }

        > .week {
            width: calc(100% / 3);
            min-width: calc(100% / 3);
            text-align: center;

            > .title {
            }

            > .count,
            a .count {
                font-weight: bold;
                font-size: 2rem;
            }
        }
    }

    > table.orders {
        width: 100%;
        margin-bottom: 16px;
        flex-direction: column;

        > thead {
            > tr {
                > th {
                    height: 40px;
                    background: #fafafa;
                    padding: 1vh 0;
                    border-bottom: 1px solid #ddd;
                    border-top: 1px solid #ddd;
                    color: #333;
                    font-weight: normal;

                    &:first-child {
                        padding-left: 1vw;
                    }
                    &:last-child {
                        padding-right: 1vw;
                    }
                }
            }
        }

        > tbody {
            > tr {
                > td {
                    height: 80px;
                    background: #fff;
                    padding: 1vh 0;

                    > .emptyState {
                        font-style: italic;
                        color: #555;
                    }

                    &:first-child {
                        padding-left: 1vw;
                    }
                    &:last-child {
                        padding-right: 1vw;
                    }

                    &.service {
                        > img {
                            width: 32px;
                            object-size: contain;
                        }
                    }
                }
            }
        }
    }
`;

let Order = styled.tr`
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    text-decoration: none;
    color: #000;

    flex-shrink: 0;

    &:last-child {
        border-bottom: 0;
    }

    .trackingNumber {
        display: flex;
        flex-direction: row;
        align-items: center;

        > img {
            width: 60px;
            margin-right: 8px;
        }
    }

    > .timestamp {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.8);
    }

    > .notifications {
        > .notification {
            position: relative;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;

            > .text {
                opacity: 0;
                pointer-events: none;
                position: absolute;
                transition: all 0.3s ease;
                background: #fff;
                white-space: nowrap;
                display: flex;
                justify-content: center;
                align-items: center;
                left: calc(100% + 8px);
                box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
                padding: 4px 12px;
                border-radius: 4px;
                z-index: 100;
            }

            &:hover {
                .text {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            > .icon {
                border-radius: 100%;
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 48px;
                height: 48px;
                color: #fff;

                > svg {
                    width: 100%;
                    height: 100%;
                }
            }

            &.issue {
                > .icon {
                    background: #e57c1b;
                }
            }

            &.information {
                > .icon {
                    background: #72c3ed;
                }
            }

            &.noPickup {
                > .icon {
                    > svg {
                        margin-left: 2px;
                        margin-top: 4px;
                    }
                }
            }

            &.notPickedUp {
                > .icon {
                    > svg {
                        margin-left: 2px;
                        margin-top: 4px;
                    }
                }
            }
            &.exception {
                > .icon {
                    > svg {
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }

    > .type {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 500;
    }

    > .state {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 500;
    }

    > .pickup {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 500;

        > .wrapper {
            display: flex;
            align-items: center;

            > .pickup-required {
                padding: 2px 4px;
                min-height: 20px;
                font-size: 10px;
            }
        }
    }

    &.is-delivered {
        > .state {
            color: var(--color-good);
        }
    }

    &.is-warning {
        > .state {
            color: var(--color-bad);
        }
    }

    &.is-rejected {
        > .state {
            color: var(--color-bad);
        }
    }

    &.is-intransit {
        > .state {
            color: rgba(0, 80, 180, 1);
        }
    }

    &.is-rejected,
    &.is-new {
        > .price {
            text-decoration: line-through;
        }
    }

    > .title {
        font-size: 16px;
    }

    > .price {
        font-size: 16px;
        text-align: right;
    }

    &:hover {
        background: rgba(0, 0, 0, 0.02);
    }
`;

function View({ history }) {
    const context = useContext(Context);
    const [searchstring, setSearchstring] = useState("");
    const [orders, setOrders] = useState(null);
    const [ordersWithIssues, setOrdersWithIssues] = useState(null);
    const [loadingCustomerLists, setLoadingCustomerLists] = useState(false);
    const [inactiveCustomers, setInactiveCustomers] = useState([]);
    const [newCustomers, setNewCustomers] = useState([]);
    const [weeklyNewCustomers, setWeeklyNewCustomers] = useState([]);
    const [graphSettings, setGraphSettings] = useState({
        interval: "week",
        aggregation: "",
    });
    const searchRef = useRef();
    const orderIssuesRef = useRef();
    const [economyStatistics, setEconomyStatistics] = useState(null);
    const [statisticsAggregation, setStatisticsAggregation] = useState();

    useEffect(() => {
        if (
            !context.user.administrator &&
            !(context.user.brand && context.user.brand.id)
        ) {
            history.replace("/");
        }
    }, [context.user.administrator, context.user.brand]);

    useEffect(() => {
        // Bounce!
        if (searchRef.current) {
            searchRef.current.cancel();
        }
        searchRef.current = axios.CancelToken.source();
        if (searchstring && searchstring.length > 3) {
            Api.adminSearchOrders({
                query: searchstring,
                cancelToken: searchRef.current.token,
            })
                .then((response) => {
                    setOrders(response.orders);
                })
                .catch(() => {
                    // Cancelled
                });
        } else {
            setOrders(null);
        }
    }, [searchstring]);

    useEffect(() => {
        Api.getEconomyStatistics({
            interval: graphSettings.interval,
            brandId:
                graphSettings.aggregation.indexOf("brand-") === 0
                    ? parseInt(graphSettings.aggregation.substr(6))
                    : null,
            clientManagerId: parseInt(graphSettings.aggregation)
                ? parseInt(graphSettings.aggregation)
                : null,
        })
            .then(setEconomyStatistics)
            .catch(() => {});

        loadCustomerLists();
    }, [graphSettings]);

    async function loadCustomerLists() {
        setLoadingCustomerLists(true);

        setInactiveCustomers(
            await Api.getInactiveCustomers({
                brandId:
                    graphSettings.aggregation.indexOf("brand-") === 0
                        ? parseInt(graphSettings.aggregation.substr(6))
                        : null,
                clientManagerId: parseInt(graphSettings.aggregation)
                    ? parseInt(graphSettings.aggregation)
                    : null,
            }),
        );

        setNewCustomers(
            await Api.getNewCustomers({
                brandId:
                    graphSettings.aggregation.indexOf("brand-") === 0
                        ? parseInt(graphSettings.aggregation.substr(6))
                        : null,
                clientManagerId: parseInt(graphSettings.aggregation)
                    ? parseInt(graphSettings.aggregation)
                    : context.user.role === "CLIENT_MANAGER"
                    ? context.user.id
                    : null,
            }),
        );

        setWeeklyNewCustomers(
            await Api.getWeeklyNewCustomers({
                brandId:
                    graphSettings.aggregation.indexOf("brand-") === 0
                        ? parseInt(graphSettings.aggregation.substr(6))
                        : null,
            }),
        );
        setLoadingCustomerLists(false);
    }

    useEffect(() => {
        orderIssuesRef.current = axios.CancelToken.source();

        loadData();

        return () => {
            orderIssuesRef.current.cancel();
        };
    }, []);

    async function loadData() {
        if (context.user.administrator || context.user.role === "OWNER") {
            let aggregationDropdown = [
                {
                    title: "Alla kunder",
                    value: "",
                },
            ];

            if (context.user.administrator) {
                const brands = (await Api.getBrands()).map((brand) => {
                    return {
                        title: brand.name,
                        value: "brand-" + brand.id,
                    };
                });
                aggregationDropdown = []
                    .concat(aggregationDropdown)
                    .concat(brands);
            }

            const clientManagers = (
                await Api.getClientManagers({
                    brandId: context.user.administrator
                        ? null
                        : context.user.brand.id,
                    includeOwners: true,
                })
            ).map((clientManager) => {
                return {
                    title: clientManager.name,
                    value: clientManager.id,
                };
            });

            aggregationDropdown = []
                .concat(aggregationDropdown)
                .concat(clientManagers);

            setStatisticsAggregation(aggregationDropdown);
        }

        await Api.getOrderIssues({
            cancelToken: orderIssuesRef.current.token,
        })
            .then(setOrdersWithIssues)
            .catch(() => {
                // Cancelled
            });
    }

    const onClickRemoveInactiveCustomer = (customerId) => {
        Api.updateInactiveStatusForCustomer(customerId)
            .then(() => {
                toast.success("Kunden har tagits bort från inaktiva kunder");
                const newInactiveCustomers = inactiveCustomers.filter(
                    (customer) => customer.id !== customerId,
                );
                setInactiveCustomers(newInactiveCustomers);
            })
            .catch((error) => {
                toast.error("Kunde kunde inte tas bort från inaktiva kunder");
            });
    };

    return (
        <Container>
            <div className="searchInput">
                <Input
                    type="text"
                    placeholder={"Sök efter försändelser..."}
                    value={searchstring}
                    onChange={setSearchstring}
                />
            </div>
            {orders && (
                <table className="orders">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Referens</th>
                            <th>Fraktsedelnummer</th>
                            {context.user.administrator && <th>Brand</th>}
                            <th>Mottagare</th>
                            <th>Bokad</th>
                            <th>Status</th>
                            <th>Pris</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.length === 0 && (
                            <tr>
                                <td colSpan="100%">
                                    <div className="emptyState">
                                        Hittade inte några försändelser som
                                        matchade din sökning.
                                    </div>
                                </td>
                            </tr>
                        )}
                        {orders.map((order) => {
                            let exception = null;
                            let pickupInPast = false;
                            let noPickup = false;
                            let orderBookingFailed = false;
                            if (!order.trackingNumber) {
                                orderBookingFailed = true;
                            } else if (order.hasError) {
                                exception = order.messages.find((message) => {
                                    return message.type === "error";
                                });
                            } else if (
                                order.state === "APPROVED" &&
                                order.confirmationNumber &&
                                moment().diff(moment(order.pickupDate)) > 0
                            ) {
                                pickupInPast = true;
                            } else if (
                                order.state === "APPROVED" &&
                                !order.confirmationNumber
                            ) {
                                noPickup = true;
                            }

                            return (
                                <Order
                                    key={order.id}
                                    className={`is-${order.state
                                        .toLowerCase()
                                        .replace(" ", "")}`}
                                    href={`/orders/${order.id}`}
                                    onClick={() => {
                                        history.push(`/orders/${order.id}`);
                                    }}
                                >
                                    <td className="notifications">
                                        {exception && (
                                            <div className="notification issue exception">
                                                <div className="text">
                                                    {getMessageText(exception)}
                                                </div>
                                                <div className="icon">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        )}
                                        {pickupInPast && (
                                            <div className="notification information notPickedUp">
                                                <div className="text">
                                                    Upphämtningen har inte
                                                    registrerats ännu.
                                                </div>
                                                <div className="icon">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M2 11.741c-1.221-1.009-2-2.535-2-4.241 0-3.036 2.464-5.5 5.5-5.5 1.706 0 3.232.779 4.241 2h4.259c.552 0 1 .448 1 1v2h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035v4.48c0 1.156-.616 2-2 2h-1c0 1.656-1.344 3-3 3s-3-1.344-3-3h-4c0 1.656-1.344 3-3 3s-3-1.344-3-3h-2c-.552 0-1-.448-1-1v-6.259zm6 6.059c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm10 0c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm-7.207-11.8c.135.477.207.98.207 1.5 0 3.036-2.464 5.5-5.5 5.5-.52 0-1.023-.072-1.5-.207v4.207h1.765c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h5.53c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h1.765v-4.575l-1.711-2.929c-.179-.307-.508-.496-.863-.496h-4.426v6h-2v-9h-2.207zm5.207 4v3h5l-1.427-2.496c-.178-.312-.509-.504-.868-.504h-2.705zm-10.5-6c1.932 0 3.5 1.568 3.5 3.5s-1.568 3.5-3.5 3.5-3.5-1.568-3.5-3.5 1.568-3.5 3.5-3.5zm.5 3h2v1h-3v-3h1v2z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        )}
                                        {noPickup && (
                                            <div className="notification issue noPickup">
                                                <div className="text">
                                                    Ingen upphämtning bokad!
                                                </div>
                                                <div className="icon">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M2 11.741c-1.221-1.009-2-2.535-2-4.241 0-3.036 2.464-5.5 5.5-5.5 1.706 0 3.232.779 4.241 2h4.259c.552 0 1 .448 1 1v2h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035v4.48c0 1.156-.616 2-2 2h-1c0 1.656-1.344 3-3 3s-3-1.344-3-3h-4c0 1.656-1.344 3-3 3s-3-1.344-3-3h-2c-.552 0-1-.448-1-1v-6.259zm6 6.059c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm10 0c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm-7.207-11.8c.135.477.207.98.207 1.5 0 3.036-2.464 5.5-5.5 5.5-.52 0-1.023-.072-1.5-.207v4.207h1.765c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h5.53c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h1.765v-4.575l-1.711-2.929c-.179-.307-.508-.496-.863-.496h-4.426v6h-2v-9h-2.207zm5.207 4v3h5l-1.427-2.496c-.178-.312-.509-.504-.868-.504h-2.705zm-10.5-6c1.932 0 3.5 1.568 3.5 3.5s-1.568 3.5-3.5 3.5-3.5-1.568-3.5-3.5 1.568-3.5 3.5-3.5zm.5 3h2v1h-3v-3h1v2z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        )}
                                    </td>
                                    <td className="type">
                                        {order.information.reference}
                                    </td>
                                    <td>
                                        <div className="trackingNumber">
                                            <img
                                                src={`/images/icons/${getTransporterLogo(
                                                    order.service.name,
                                                )}`}
                                            />
                                            {order.trackingNumber}
                                        </div>
                                    </td>
                                    {context.user.administrator && (
                                        <td>{order.brand.name}</td>
                                    )}
                                    <td>
                                        <div className="receiver">
                                            <div className="title">
                                                {order.route.to.contact.private
                                                    ? order.route.to.contact
                                                          .name
                                                    : order.route.to.contact
                                                          .company}
                                            </div>
                                            <div className="location">
                                                {order.route.to.postalTown},{" "}
                                                {order.route.to.countryCode}
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        className="timestamp"
                                        title={moment(order.created).format(
                                            "YYYY-MM-DD HH:mm:ss",
                                        )}
                                    >
                                        {moment(order.created).calendar()}
                                    </td>
                                    <td className="state">
                                        {renderOrderState(order)}
                                    </td>
                                    <td className="price">
                                        {order.price.amount
                                            ?.toString()
                                            .replace(
                                                /(\d)(?=(\d\d\d)+(?!\d))/g,
                                                "$1 ",
                                            )}{" "}
                                        {order.price.unit || ""}
                                    </td>
                                </Order>
                            );
                        })}
                    </tbody>
                </table>
            )}
            {/* <div className="maps">
				<div className="map">
					<WorldMap onClick={(countryCode) => {
						console.log('Clicked on', countryCode)
					}} />
				</div>
				<div className="map">
					<WorldMap onClick={(countryCode) => {
						console.log('Clicked on', countryCode)
					}} />
				</div>
			</div> */}
            {economyStatistics && (
                <div className="graphContainer">
                    <div className="menu">
                        <Input
                            onChange={setGraphSettings}
                            value={graphSettings}
                            type="list"
                            object={{
                                interval: {
                                    title: "Intervall",
                                    type: "dropdown",
                                    options: [
                                        {
                                            title: "År",
                                            value: "year",
                                        },
                                        {
                                            title: "Månad",
                                            value: "month",
                                        },
                                        {
                                            title: "Vecka",
                                            value: "week",
                                        },
                                        {
                                            title: "Dag",
                                            value: "day",
                                        },
                                    ],
                                },
                                aggregation: {
                                    title: "Aggregering",
                                    type: "dropdown",
                                    options: statisticsAggregation || [],
                                    hidden: !(
                                        context.user.administrator ||
                                        context.user.role === "OWNER"
                                    ),
                                },
                            }}
                        />
                        <div className="box">
                            {loadingCustomerLists && (
                                <>
                                    <div className="title">Inaktiva kunder</div>
                                    <div className="list">
                                        <div className="item">Laddar</div>
                                    </div>
                                </>
                            )}
                            {!loadingCustomerLists && (
                                <>
                                    <div className="title">
                                        Inaktiva kunder{" "}
                                        {inactiveCustomers.length
                                            ? `(${inactiveCustomers.length})`
                                            : ""}
                                    </div>
                                    <div className="list">
                                        {!inactiveCustomers.length && (
                                            <div className="item">
                                                Inga inaktiva kunder just nu
                                            </div>
                                        )}
                                        {inactiveCustomers.map((customer) => {
                                            return (
                                                <div
                                                    key={customer.id}
                                                    className="item flex flex-row justify-between gap-1"
                                                >
                                                    <Link
                                                        to={`/admin/customers/${customer.id}`}
                                                    >
                                                        {customer.name}
                                                        {context.user
                                                            .administrator &&
                                                        customer.brand
                                                            ? ` (${customer.brand.name})`
                                                            : ""}
                                                    </Link>
                                                    <div className="pr-3 flex items-center">
                                                        <svg
                                                            height={20}
                                                            width={20}
                                                            className=" text-gray-400 hover:text-red-300 cursor-pointer"
                                                            onClick={() =>
                                                                onClickRemoveInactiveCustomer(
                                                                    customer.id,
                                                                )
                                                            }
                                                        >
                                                            <use href="/images/icons/circle-cross.svg#icon-cross" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="box">
                            {loadingCustomerLists && (
                                <>
                                    <div className="title">Nya kunder</div>
                                    <div className="list">
                                        <div className="item">Laddar</div>
                                    </div>
                                </>
                            )}
                            {!loadingCustomerLists && (
                                <>
                                    <div className="title">
                                        Nya kunder{" "}
                                        {newCustomers.length
                                            ? `(${newCustomers.length})`
                                            : ""}
                                    </div>
                                    <div className="list">
                                        {!newCustomers.length && (
                                            <div className="item">
                                                Inga nya kunder just nu
                                            </div>
                                        )}
                                        {newCustomers.map((customer) => {
                                            return (
                                                <div
                                                    key={customer.id}
                                                    className="item"
                                                >
                                                    <Link
                                                        to={`/admin/customers/${customer.id}`}
                                                    >
                                                        {customer.name}
                                                        {context.user
                                                            .administrator &&
                                                        customer.brand
                                                            ? ` (${customer.brand.name})`
                                                            : ""}
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="chart">
                        <Bar
                            data={{
                                labels: economyStatistics.map((row) => {
                                    switch (graphSettings.interval) {
                                        case "year": {
                                            return moment(
                                                row.title,
                                                "YYYY",
                                            ).format("YYYY");
                                        }
                                        case "month": {
                                            return moment(
                                                row.title,
                                                "YYYY-MM",
                                            ).format("MMM");
                                        }
                                        case "week": {
                                            return `v${row.title.substr(5)}`;
                                        }
                                        case "day": {
                                            return moment(
                                                row.title,
                                                "YYYY-MM-DD",
                                            ).format("YYY-MM-DD");
                                        }
                                    }
                                }),
                                datasets: [
                                    {
                                        label: "Försändelser",
                                        tension: 0.3,
                                        borderColor: "#00000033",
                                        // @ts-ignore
                                        type: "line",
                                        fill: false,
                                        data: economyStatistics.map((row) => {
                                            return Math.round(
                                                row.numberOfOrders,
                                            );
                                        }),
                                        yAxisID: "y",
                                    },
                                    {
                                        label: "Vinst",
                                        borderWidth: 1,
                                        borderColor: "rgb(147,212,140)",
                                        backgroundColor: "rgb(144,238,144)",
                                        data: economyStatistics.map((row) => {
                                            return Math.round(row.totalRevenue);
                                        }),
                                        yAxisID: "y1",
                                    },
                                    {
                                        label: "Omsättning",
                                        borderWidth: 1,
                                        borderColor: "rgb(176,196,222)",
                                        backgroundColor: "rgb(173,216,230)",
                                        data: economyStatistics.map((row) => {
                                            return Math.round(row.totalIncome);
                                        }),
                                        yAxisID: "y1",
                                    },
                                ],
                            }}
                            options={{
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        stacked: true,
                                    },
                                    y: {
                                        position: "right",
                                    },
                                    y1: {
                                        ticks: {
                                            callback: (
                                                value,
                                                index,
                                                values,
                                            ) => {
                                                if (value >= 1000) {
                                                    return value
                                                        .toString()
                                                        .replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            " ",
                                                        );
                                                } else {
                                                    return value;
                                                }
                                            },
                                        },
                                    },
                                },
                                plugins: {
                                    tooltip: {
                                        callbacks: {
                                            label: function (data) {
                                                if (
                                                    data.dataset.label ===
                                                    "Vinst"
                                                ) {
                                                    const revenue = parseInt(
                                                        "" + data.raw,
                                                    );
                                                    const freightPercent =
                                                        economyStatistics[
                                                            data.parsed.x
                                                        ].freightRevenue /
                                                        economyStatistics[
                                                            data.parsed.x
                                                        ].totalRevenue;
                                                    const fivePercent =
                                                        (revenue > 40000
                                                            ? 40000
                                                            : revenue) * 0.05;
                                                    const sevenPercent =
                                                        (revenue > 40000
                                                            ? revenue - 40000
                                                            : 0) * 0.07;
                                                    const totalProvision =
                                                        fivePercent +
                                                        sevenPercent;
                                                    const newCustomersProvision =
                                                        parseInt(
                                                            economyStatistics[
                                                                data.parsed.x
                                                            ].newCustomers,
                                                        ) * 300;

                                                    const freightProvision =
                                                        Math.round(
                                                            totalProvision *
                                                                freightPercent,
                                                        );
                                                    const speditionProvision =
                                                        Math.round(
                                                            economyStatistics[
                                                                data.parsed.x
                                                            ].speditionRevenue *
                                                                0.07,
                                                        );
                                                    return [
                                                        `Total vinst: ${formatPrice(
                                                            data.raw,
                                                        )}`,
                                                        `Provision frakt: ${formatPrice(
                                                            freightProvision ===
                                                                Infinity
                                                                ? 0
                                                                : freightProvision,
                                                        )}`,
                                                        `Provision spedition: ${formatPrice(
                                                            speditionProvision ===
                                                                Infinity
                                                                ? 0
                                                                : speditionProvision,
                                                        )}`,
                                                        `Provision nya kunder: ${formatPrice(
                                                            newCustomersProvision,
                                                        )}`,
                                                        `Provision: ${formatPrice(
                                                            Math.round(
                                                                totalProvision +
                                                                    newCustomersProvision,
                                                            ),
                                                        )}`,
                                                    ];
                                                }
                                                return `${
                                                    data.dataset.label
                                                }: ${formatPrice(data.raw)}`;
                                            },
                                        },
                                    },
                                },
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="newCustomers">
                <div className="week">
                    <div className="title">Nya kunder denna vecka</div>
                    <div className="count">
                        {weeklyNewCustomers?.newThisWeek}
                    </div>
                </div>
                <div className="week">
                    <div className="title">Föregående vecka</div>
                    <div className="count">
                        {weeklyNewCustomers?.newLastWeek}
                    </div>
                </div>
                <div className="week">
                    <div className="title">Veckan innan dess</div>
                    <div className="count">
                        {weeklyNewCustomers?.newWeekBefore}
                    </div>
                </div>
            </div>
            <div className="newCustomers">
                <div className="week">
                    <div className="title">
                        Kunder med första bokning denna vecka
                    </div>
                    <Link className="w-full" to="/admin/sales-customers">
                        <div className="count">
                            {weeklyNewCustomers?.firstOrderThisWeek}
                        </div>
                    </Link>
                </div>
                <div className="week">
                    <div className="title">Föregående vecka</div>
                    <div className="count">
                        {weeklyNewCustomers?.firstOrderLastWeek}
                    </div>
                </div>
                <div className="week">
                    <div className="title">Veckan innan dess</div>
                    <div className="count">
                        {weeklyNewCustomers?.firstOrderWeekBefore}
                    </div>
                </div>
            </div>
            <div className="title">Försändelser med eventuella problem</div>
            {ordersWithIssues && (
                <table className="orders">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Referens</th>
                            <th>Fraktsedelnummer</th>
                            {context.user.administrator && <th>Brand</th>}
                            <th>Mottagare</th>
                            <th>Bokad</th>
                            <th>Status</th>
                            <th>Pris</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ordersWithIssues.length === 0 && (
                            <tr>
                                <td colSpan={100}>
                                    <div className="emptyState">
                                        Hittade inte några försändelser
                                        eventuella problem.
                                    </div>
                                </td>
                            </tr>
                        )}
                        {ordersWithIssues.map((order) => {
                            let exception = null;
                            let pickupInPast = false;
                            let noPickup = false;
                            if (order.hasError) {
                                exception = order.messages.find((message) => {
                                    return message.type === "error";
                                });
                            } else if (
                                order.state === "APPROVED" &&
                                moment().diff(moment(order.pickupDate)) > 0
                            ) {
                                pickupInPast = true;
                            }
                            // No way to see if pickup as been booked?
                            // else if(order.state === 'APPROVED' && !order.pickupDate) {
                            // 	noPickup = true;
                            // }

                            return (
                                <Order
                                    key={order.id}
                                    className={`is-${order.state
                                        .toLowerCase()
                                        .replace(" ", "")}`}
                                    href={`/orders/${order.id}`}
                                    onClick={() => {
                                        history.push(`/orders/${order.id}`);
                                    }}
                                >
                                    <td className="notifications">
                                        {exception && (
                                            <div className="notification issue exception">
                                                <div className="text">
                                                    {getMessageText(exception)}
                                                </div>
                                                <div className="icon">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        )}
                                        {pickupInPast && (
                                            <div className="notification information notPickedUp">
                                                <div className="text">
                                                    Upphämtningen har inte
                                                    registrerats ännu.
                                                </div>
                                                <div className="icon">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M2 11.741c-1.221-1.009-2-2.535-2-4.241 0-3.036 2.464-5.5 5.5-5.5 1.706 0 3.232.779 4.241 2h4.259c.552 0 1 .448 1 1v2h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035v4.48c0 1.156-.616 2-2 2h-1c0 1.656-1.344 3-3 3s-3-1.344-3-3h-4c0 1.656-1.344 3-3 3s-3-1.344-3-3h-2c-.552 0-1-.448-1-1v-6.259zm6 6.059c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm10 0c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm-7.207-11.8c.135.477.207.98.207 1.5 0 3.036-2.464 5.5-5.5 5.5-.52 0-1.023-.072-1.5-.207v4.207h1.765c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h5.53c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h1.765v-4.575l-1.711-2.929c-.179-.307-.508-.496-.863-.496h-4.426v6h-2v-9h-2.207zm5.207 4v3h5l-1.427-2.496c-.178-.312-.509-.504-.868-.504h-2.705zm-10.5-6c1.932 0 3.5 1.568 3.5 3.5s-1.568 3.5-3.5 3.5-3.5-1.568-3.5-3.5 1.568-3.5 3.5-3.5zm.5 3h2v1h-3v-3h1v2z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        )}
                                        {noPickup && (
                                            <div className="notification issue noPickup">
                                                <div className="text">
                                                    Ingen upphämtning bokad!
                                                </div>
                                                <div className="icon">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M2 11.741c-1.221-1.009-2-2.535-2-4.241 0-3.036 2.464-5.5 5.5-5.5 1.706 0 3.232.779 4.241 2h4.259c.552 0 1 .448 1 1v2h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035v4.48c0 1.156-.616 2-2 2h-1c0 1.656-1.344 3-3 3s-3-1.344-3-3h-4c0 1.656-1.344 3-3 3s-3-1.344-3-3h-2c-.552 0-1-.448-1-1v-6.259zm6 6.059c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm10 0c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm-7.207-11.8c.135.477.207.98.207 1.5 0 3.036-2.464 5.5-5.5 5.5-.52 0-1.023-.072-1.5-.207v4.207h1.765c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h5.53c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h1.765v-4.575l-1.711-2.929c-.179-.307-.508-.496-.863-.496h-4.426v6h-2v-9h-2.207zm5.207 4v3h5l-1.427-2.496c-.178-.312-.509-.504-.868-.504h-2.705zm-10.5-6c1.932 0 3.5 1.568 3.5 3.5s-1.568 3.5-3.5 3.5-3.5-1.568-3.5-3.5 1.568-3.5 3.5-3.5zm.5 3h2v1h-3v-3h1v2z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        )}
                                    </td>
                                    <td className="type">
                                        {order.information.reference}
                                    </td>
                                    <td>
                                        <div className="trackingNumber">
                                            <img
                                                src={`/images/icons/${getTransporterLogo(
                                                    order.service.name,
                                                )}`}
                                            />
                                            <div className="number">
                                                {order.trackingNumber}
                                            </div>
                                        </div>
                                    </td>
                                    {context.user.administrator && (
                                        <td>{order.brand?.name}</td>
                                    )}
                                    <td>
                                        <div className="receiver">
                                            <div className="title">
                                                {order.route?.to?.contact
                                                    .private
                                                    ? order.route?.to?.contact
                                                          .name
                                                    : order.route?.to?.contact
                                                          .company}
                                            </div>
                                            <div className="location">
                                                {order.route?.to?.postalTown},{" "}
                                                {order.route?.to?.countryCode}
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        className="timestamp"
                                        title={moment(order.created).format(
                                            "YYYY-MM-DD HH:mm:ss",
                                        )}
                                    >
                                        {moment(order.created).calendar()}
                                    </td>
                                    <td className="state">
                                        {renderOrderState(order)}
                                    </td>
                                    <td className="price">
                                        {order.price.amount
                                            ?.toString()
                                            .replace(
                                                /(\d)(?=(\d\d\d)+(?!\d))/g,
                                                "$1 ",
                                            )}{" "}
                                        {order.price.unit || ""}
                                    </td>
                                </Order>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </Container>
    );
}

function renderOrderState(order) {
    if (order.state === "DELIVERED") {
        return `Levererad ${moment(order.deliveryDate).format("YYYY-MM-DD")}`;
    } else if (
        order.state === "APPROVED" &&
        order.confirmationNumber &&
        order.pickupDate
    ) {
        return `Bokad för upphämtning ${moment(order.pickupDate).format(
            "YYYY-MM-DD",
        )}`;
    }
    return PrettyState(order.state);
}

function getMessageText(message) {
    switch (message.code) {
        case "DELIVERY_FAILED": {
            return "Leveransen misslyckades. Se detaljer och kontakta kundservice vid behov.";
        }
        case "MISROUTED": {
            return "Försändelsen försenas pga felsortering.";
        }
        case "MISSING_CUSTOMS": {
            return "Saknas eller felaktiga tull-dokument. Var god kontakta kundservice!";
        }
        case "DELIVERY_FAILED": {
            return "Misslyckat leveransförsök. Inväntar nästa utkörningstillfälle.";
        }
        case "INVALID_ADDRESS": {
            return "Felaktig leveransadress. Var god kontakta kundservice!";
        }
        case "CHANGED": {
            return "Ändring av transportuppdrag. Kontakta kundservice för detaljer.";
        }
        case "INCOMPLETE": {
            return "Saknas ellerfelaktiga dokument. Var god kontakta kundservice!";
        }
        case "RECLAIMED": {
            return "Försändelsen är reklamerad. Var god kontakta kundservice!";
        }
        case "REFUSED": {
            return "Mottagaren vägrar ta emot godset. Sändningen returneras till avsändaren.";
        }
        default: {
            return message.text;
        }
    }
}

function formatPrice(amount, unit = "SEK") {
    if (!amount) {
        return "0 " + unit;
    }
    amount = parseFloat(amount).toFixed(0);
    return (
        (typeof amount === "string"
            ? amount.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")
            : 0) +
        " " +
        unit
    );
}

export default withRouter(View);
