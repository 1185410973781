import Input from "components/OldInput";
import InputDropdown from "components/Input/Dropdown";
import Context from "context/Global";
import queryString from "query-string";
import React, { useContext, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import ShipmentPackages from "views/Drafts/Create/Packages";
import CompanyForm from "views/Drafts/Create/Route/CompanyForm";
import toast from "react-hot-toast";

let Container = styled.div`
    box-sizing: border-box;
    max-width: 1200px;

    > .subTitle {
        padding: 1rem 1rem 0;
        font-size: 1.5rem;
    }

    > .information {
        padding: 0 1rem;

        & + .information {
            margin-top: 1rem;
        }
    }

    > .section {
        padding: 0 1rem;

        &.flex {
            display: flex;
        }

        &.route {
            display: flex;
            gap: 1rem;
            margin-bottom: 0;
        }

        &.packages {
            margin-top: 0;
        }

        > .textarea {
            min-width: 320px;
        }

        &.textareas {
            display: flex;
            flex-direction: row;
            gap: 1rem;

            > .flex {
                flex: 1 1 100%;
                display: flex;
                flex-direction: column;

                > .label {
                    font-size: 1.5rem;
                }
            }
        }

        &.terms {
            width: 25%;
            min-width: 200px;
        }
    }

    > .buttons {
        padding: 1rem;
        gap: 1rem;
    }
`;

let Title = styled.div`
    font-size: 24px;
    color: rgba(0, 0, 0, 0.6);
    padding: 1rem;
`;

function View({ history }) {
    const context = useContext(Context);
    const [type, setType] = useState("");
    const [sender, setSender] = useState({});
    const [recipient, setRecipient] = useState({});
    const [packages, setPackages] = useState([]);
    const [pickupInformation, setPickupInformation] = useState("");
    const [otherInformation, setOtherInformation] = useState("");
    const [terms, setTerms] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const typeRef = useRef();
    const senderRef = useRef();
    const recipientRef = useRef();
    const packagesRef = useRef();
    const pickupInformationRef = useRef();
    const otherInformationRef = useRef();
    const termsRef = useRef();

    useEffect(() => {
        setIsButtonDisabled(false);
    }, [
        type,
        sender,
        recipient,
        packages,
        pickupInformation,
        otherInformation,
        terms,
    ]);

    const valid = () => {
        let isValid = true;
        if (!typeRef.current.validate()) {
            isValid = false;
        }
        if (!senderRef.current.validate()) {
            isValid = false;
        }
        if (!recipientRef.current.validate()) {
            isValid = false;
        }
        if (!packagesRef.current.validate()) {
            isValid = false;
        }
        if (!pickupInformationRef.current.validate()) {
            isValid = false;
        }
        if (!otherInformationRef.current.validate()) {
            isValid = false;
        }
        if (!termsRef.current.validate()) {
            isValid = false;
        }
        return isValid;
    };

    async function order() {
        if (valid()) {
            setIsLoading(true);
            try {
                const {
                    name,
                    addressLine1,
                    contactName,
                    contactEmail,
                    countryCode,
                    postalCode,
                    postalTown,
                } = context?.user?.customer;

                const forwarding = await Api.createForwarding({
                    forwarding: {
                        type,
                        sender,
                        recipient,
                        packages,
                        information: {
                            pickup: pickupInformation,
                            other: otherInformation,
                            terms: terms,
                        },
                        customer: {
                            name,
                            contactName,
                            contactEmail,
                            countryCode,
                            addressLine1,
                            postalCode,
                            postalTown,
                        },
                    },
                });
                context.socket.emit(
                    "createTicket",
                    {
                        title: `Spedition #${forwarding.id}`,
                        type: "FORWARDING",
                        data: {
                            forwardingId: forwarding.id,
                        },
                    },
                    (newTicket) => {
                        history.replace(`/tickets/${newTicket.id}`);
                    },
                );
                toast.success("Förfrågan skickades!");
                setIsButtonDisabled(true);
            } catch (error) {
                console.error("Error occured when creating spedition:", error);
                toast.error("Något gick fel. Refresha sidan och försök igen.");
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        const query = queryString.parse(history.location.search);
        if (!!query.quote) {
            setSender(context.order.sender);
            setRecipient(context.order.receiver);
            setPackages(context.order.packages);
            senderRef.current?.set(
                JSON.parse(JSON.stringify(context.order.sender)),
            );
            recipientRef.current?.set(
                JSON.parse(JSON.stringify(context.order.receiver)),
            );
            packagesRef.current?.set(
                JSON.parse(JSON.stringify(context.order.packages)),
            );
        }
    }, []);

    return (
        <Container>
            <Title>Spedition</Title>
            <div className="information">
                Ibland räcker inte transportörernas tjänster till och det kan
                vara bättre att köpa en speditionstjänst. Vi hjälper er hela
                vägen och allt du behöver göra är att skicka in uppgifterna
                nedan så kontaktar vi rätt speditör och ger er en offert.
            </div>
            <div className="information">
                Är du osäker på när spedition är rätt val? Ring oss på{" "}
                <a
                    href={`tel:${context.user.customer.brand.supportPhoneNumber}`}
                >
                    {context.user.customer.brand.supportPhoneNumber}
                </a>{" "}
                så reder vi ut vilka av era transporter som skulle passa för
                spedition.
            </div>
            <div className="subTitle">Typ av transport</div>
            <div className="flex section">
                <InputDropdown
                    ref={typeRef}
                    value={type}
                    onChange={setType}
                    required={true}
                    placeholder="Typ av transport"
                    options={[
                        {
                            title: "Typ av transport",
                            value: "",
                        },
                        {
                            title: "Väg",
                            value: "ROAD",
                        },
                        {
                            title: "Flyg",
                            value: "AIR",
                        },
                        {
                            title: "Sjö",
                            value: "SEA",
                        },
                    ]}
                />
            </div>
            <div className="subTitle">Rutt</div>
            <div className="route section">
                <CompanyForm
                    ref={senderRef}
                    customsRequired={false}
                    onChange={setSender}
                    enableDifferentPickupAddress={false}
                    requireEmail={false}
                    requireCompanyName={false}
                    requireAddress={false}
                    requireContactName={false}
                    requirePhoneNumber={false}
                    enableDhlPalletExchangeNumber={false}
                />
                <CompanyForm
                    ref={recipientRef}
                    customsRequired={false}
                    onChange={setRecipient}
                    enableDifferentPickupAddress={false}
                    requireEmail={false}
                    requireCompanyName={false}
                    requireAddress={false}
                    requireContactName={false}
                    requirePhoneNumber={false}
                    enableDhlPalletExchangeNumber={false}
                />
            </div>
            <div className="subTitle">Kollin</div>
            <div className="packages section">
                <ShipmentPackages
                    ref={packagesRef}
                    packages={packages}
                    onChange={setPackages}
                />
            </div>
            <div className="textareas section">
                <div className="flex">
                    <div className="label">
                        Klart för hämtning (behövs ej vid prisförfrågan)
                    </div>
                    <Input
                        ref={pickupInformationRef}
                        onChange={setPickupInformation}
                        value={pickupInformation}
                        className="textarea"
                        type="textarea"
                        placeholder="Datum och mer information kring hämtningen..."
                    />
                </div>
                <div className="flex">
                    <div className="label">Övrig information</div>
                    <Input
                        ref={otherInformationRef}
                        onChange={setOtherInformation}
                        value={otherInformation}
                        className="textarea"
                        type="textarea"
                        placeholder="Övriga önskemål eller information om försändelsen..."
                    />
                </div>
            </div>
            <div className="section terms">
                <div className="label">Tull-villkor</div>
                <Input
                    ref={termsRef}
                    onChange={setTerms}
                    value={terms}
                    className="text"
                    type="text"
                    placeholder="DAP, DDP, EXW, etc."
                />
            </div>
            <div className="buttons">
                <button
                    className="c-button c-button--raised"
                    onClick={order}
                    disabled={isLoading || isButtonDisabled}
                >
                    {isButtonDisabled ? "Förfrågan Skickad" : "Beställ offert"}
                </button>
            </div>
        </Container>
    );
}

export default withRouter(View);
