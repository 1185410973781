import React, { useEffect, useRef } from "react";
import InputCheckbox from "components/Input/Checkbox";
import { PN_MARGIN_MULTIPLIER, POSTNORD } from "constants/carriers/postNord";

const AdditionalOptions = ({
    additionalOptions,
    setAdditionalOptions,
    receiverPays,
    packagesCount,
}) => {
    const previousOptionsRef = useRef(additionalOptions);
    const conflictMapping = {
        [POSTNORD.additional_services.before_9.code]: [
            POSTNORD.additional_services.before_12.code,
        ],
        [POSTNORD.additional_services.before_12.code]: [
            POSTNORD.additional_services.before_9.code,
        ],
        [POSTNORD.additional_services.private_receiver_pallet.code]: [
            POSTNORD.additional_services.driver_notification.code,
            POSTNORD.additional_services.delivery_notification.code,
            POSTNORD.additional_services.completion_notification.code,
        ],
    };

    useEffect(() => {
        const conflictingOptions = additionalOptions.map((option) => {
            if (option.value && conflictMapping[option.code]) {
                return {
                    ...option,
                    conflictsWith: conflictMapping[option.code],
                };
            }
            return { ...option, conflictsWith: null };
        });

        const updatedOptions = conflictingOptions.map((option) => {
            if (
                conflictingOptions.some(
                    (opt) =>
                        opt.conflictsWith?.includes(option.code) && opt.value,
                )
            ) {
                return { ...option, disabled: true };
            } else {
                return { ...option, disabled: false };
            }
        });

        const optionsChanged =
            JSON.stringify(previousOptionsRef.current) !==
            JSON.stringify(updatedOptions);
        if (optionsChanged) {
            previousOptionsRef.current = updatedOptions;
            setAdditionalOptions(updatedOptions);
        }
    }, [additionalOptions, setAdditionalOptions]);

    const handleOptionChange = (code) => {
        setAdditionalOptions((additionalOptions) => {
            return additionalOptions.map((option) => {
                if (option.code === code) {
                    return {
                        ...option,
                        value: !option.value,
                    };
                } else {
                    return option;
                }
            });
        });
    };

    const renderAdditionalOptions = additionalOptions.map((option) => {
        const cost = option.price
            ? `+ ${Math.ceil(
                  option.price * PN_MARGIN_MULTIPLIER * packagesCount,
              )} kr`
            : "Enligt ö.k.";
        return (
            <div className="flex items-center mb-4" key={option.name}>
                <div className="px-8 items-center">
                    <InputCheckbox
                        value={option.value}
                        disabled={option.disabled}
                        onChange={() => handleOptionChange(option.code)}
                    />
                </div>
                <div className="text-xl font-bold">
                    <div>{option.name}</div>
                    <div className="text-lg font-normal">
                        {option.description}
                    </div>
                </div>
                <div className="ml-auto whitespace-nowrap pl-8 text-right text-2xl font-bold">
                    {receiverPays ? null : cost}
                </div>
            </div>
        );
    });

    return (
        <div className="w-full flex flex-col align-center ">
            {renderAdditionalOptions}
        </div>
    );
};

export default AdditionalOptions;
