import { create } from "zustand";

const setOrders = (set) => (newOrders) => {
    set({ orders: newOrders });
};

const addSelectedOrder = (set) => (order) => {
    set((state) => ({
        selectedOrders: [...state.selectedOrders, order],
    }));
};

const removeSelectedOrder = (set) => (order) => {
    set((state) => ({
        selectedOrders: state.selectedOrders.filter(
            (selectedOrder) => selectedOrder.id !== order.id,
        ),
    }));
};

const setSelectedOrders = (set) => (orders) => {
    set({ selectedOrders: orders });
};

const selectOrders = (set) => () => {
    set((state) => {
        const selectedOrdersIds = new Set(
            state.selectedOrders.map((order) => order.id),
        );

        const ordersToAdd = state.orders.filter(
            (order) => !selectedOrdersIds.has(order.id),
        );

        return {
            selectedOrders: [...state.selectedOrders, ...ordersToAdd],
        };
    });
};

const unselectOrders = (set) => () => {
    set((state) => ({
        selectedOrders: state.selectedOrders.filter(
            (order) =>
                !state.orders.some(
                    (selectedOrder) => selectedOrder.id === order.id,
                ),
        ),
    }));
};

const clearSelectedOrders = (set) => () => {
    set({ selectedOrders: [] });
};

const useBatchStore = create((set, get) => ({
    orders: [],
    selectedOrders: [],
    setOrders: setOrders(set),
    setSelectedOrders: setSelectedOrders(set),
    addOrderToSelectedOrders: addSelectedOrder(set),
    removeOrderFromSelectedOrders: removeSelectedOrder(set),
    addAllOrdersToSelectedOrders: selectOrders(set),
    removeAllOrdersFromSelectedOrders: unselectOrders(set),
    clearSelectedOrders: clearSelectedOrders(set),
}));

export default useBatchStore;
