import Context from "context/Global";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

const Container = styled.div`
    width: 100%;
`;

function Component({ order }) {
    const context = useContext(Context);
    return (
        <Container>
            Ring vår kundservice på{" "}
            <a href={`tel:${context.user.customer.brand.supportPhoneNumber}`}>
                {context.user.customer.brand.supportPhoneNumber}
            </a>{" "}
            eller skapa ett digitalt ärende hos oss{" "}
            <Link
                to={`/tickets/create?autofill=tnt-pickup&trackingNumber=${order.trackingNumber}`}
            >
                här
            </Link>{" "}
            så hjälper vi er.
        </Container>
    );
}

export default Component;
