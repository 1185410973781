import { getTransporterLogo } from "helpers/Logos";
import {
    getProductName,
    getProductNameWithService,
    getTransporterName,
} from "helpers/Services";
import React from "react";
import ProductDates from "views/Batch/BatchTable/Order/Product/ProductDates";
import SelectServicePoint from "./SelectServicePoint";
import { isServicePoint } from "utils/batchTableUtils";

const Product = ({
    setSelectedQuotation,
    product,
    selectedQuotation,
    order,
    singleMode,
    showPriceView,
    modalRef,
}) => {
    const isSelectedQuotation =
        getProductNameWithService(selectedQuotation) ===
        getProductNameWithService(product);

    return (
        <div
            key={getProductNameWithService(product)}
            onClick={() => {
                setSelectedQuotation(product);
            }}
            className="flex flex-col gap-3"
        >
            <div
                className={`flex items-center gap-3 p-3 rounded-md border border-gray-300
            ${
                isSelectedQuotation
                    ? "bg-lightGreen"
                    : "bg-white   hover:bg-gray-50 cursor-pointer"
            }`}
                ref={modalRef}
            >
                <img
                    className="w-28 h-10 border-r border-gray-300 pr-3"
                    src={`/images/icons/${getTransporterLogo(
                        product?.service?.name,
                    )}`}
                />
                <div className="flex flex-col">
                    <span className="flex items-center">
                        <p>{getTransporterName(product)}</p>
                        &nbsp;
                        <p>{getProductName(product)}</p>
                    </span>
                    {showPriceView && (
                        <span>
                            <p>
                                Pris: {product.price?.total}{" "}
                                {product.price?.unit}
                            </p>
                        </span>
                    )}
                </div>
                {singleMode && (
                    <div className="flex flex-col ml-auto">
                        <ProductDates
                            pickupDate={product.pickupDate}
                            deliveryDate={product.deliveryDate}
                            requestedPickupDate={order.data.requestedPickupDate}
                            service={product.service.name}
                            blackText
                        />
                    </div>
                )}
            </div>
            {isSelectedQuotation && isServicePoint(product.service) && (
                <SelectServicePoint
                    setSelectedQuotation={setSelectedQuotation}
                    order={order}
                    selectedQuotation={selectedQuotation}
                />
            )}
        </div>
    );
};

export default Product;
