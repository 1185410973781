import React, { Component } from "react";
import styled from "styled-components";

let Container = styled.div`
    > input {
        width: 100%;
        padding: 8px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        box-sizing: border-box;
        outline: none;
    }

    > .message {
        font-weight: bold;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
    }

    &.is-invalid {
        > input {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }
        > .message {
            color: rgba(255, 0, 0, 0.6);
        }
    }
`;

const emailValidationRegex = /^[\S_\+-]+@[\S_\+-]+\.[\S_\+-]+$/;
class InputField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value || "",
            isInvalid: false,
        };

        this.inputRef = React.createRef();

        if (typeof this.props.onKeyUp === "function") {
            this.onKeyUp = this.props.onKeyUp;
        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        this.setState(
            {
                value: event.target.value.replace("‑", "-"),
            },
            () => {
                if (this.state.isInvalid) {
                    this.validate();
                }
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(event.target.value.replace("‑", "-"));
                }
            },
        );
    }

    onKeyUp() {}

    value() {
        return this.state.value.trim();
    }

    set(value) {
        this.setState({
            value: value,
        });
    }

    empty() {
        this.setState({
            value: "",
        });
    }

    focus() {
        this.inputRef.current.focus();
    }

    validate() {
        if (this.props.required && this.state.value?.length === 0) {
            this.setState({
                isInvalid: true,
                message: this.props.messages.required,
            });
            return false;
        }

        if (this.state.value) {
            if (
                this.state.value.trim() !== "" &&
                !emailValidationRegex.test(this.state.value.trim())
            ) {
                this.setState({
                    isInvalid: true,
                    message: this.props.messages.invalid,
                });
                return false;
            }
        }
        this.setState({
            isInvalid: false,
            message: null,
        });
        return true;
    }

    render() {
        const { type, ...otherProps } = this.props;
        return (
            <Container className={this.state.isInvalid ? " is-invalid" : ""}>
                <input
                    type="text"
                    {...otherProps}
                    ref={this.inputRef}
                    value={this.state.value}
                    onKeyUp={this.onKeyUp}
                    onChange={this.onChange}
                />
                {this.state.message && (
                    <div className="message">{this.state.message}</div>
                )}
            </Container>
        );
    }
}

InputField.defaultProps = {
    messages: {
        required: "This is a required field",
        invalid: "The provided content is not recognized as an email",
    },
};

export default InputField;
