import Modal from "components/Modal";
import React, { useContext, useRef, useState } from "react";
import CompanyForm from "views/Drafts/Create/Route/CompanyForm";
import Packages from "../../Drafts/Create/Packages";
import ShipmentInformation from "../../Drafts/Create/Information";
import toast from "react-hot-toast";
import Api from "services/Api/Api";
import Context from "../../../context/Global";
import { useNotificationSettingsForm } from "../hooks/useNotificationSettingsForm";

const CreateBatchOrderModal = ({
    setIsOpen,
    isOpen,
    customerId,
    getBatchOrders,
}) => {
    const [sender, setSender] = useState({});
    const [receiver, setReceiver] = useState({});
    const [packages, setPackages] = useState([]);
    const [loading, setLoading] = useState(false);
    const packagesRef = useRef();
    const senderRef = useRef();
    const receiverRef = useRef();
    const informationRef = useRef();
    const [information, setInformation] = useState({
        description: "",
        reference: "",
        dangerousGoods: {
            active: false,
        },
    });

    const {
        emailNotificationForm,
        getAdditionalOptionsNotificationOption,
        areEmailsValid,
        setSenderEmail,
        setReceiverEmail,
    } = useNotificationSettingsForm();

    const isInitialized = useRef(false);
    // Initialize the sender and receiver once when they have values set without using a useEffect
    if (
        Object.keys(sender).length > 0 &&
        Object.keys(receiver).length > 0 &&
        !isInitialized.current
    ) {
        isInitialized.current = true;
        setSenderEmail(sender.contact?.email, true);
        setReceiverEmail(receiver.contact?.email, true);
    }

    const handleSetSender = (sender) => {
        setSender(sender);
    };

    const handleSetReceiver = (receiver) => {
        setReceiver(receiver);
    };

    const handleSelectSender = (sender) => {
        setSenderEmail(sender.contact?.email, true);
    };
    const handleSelectReceiver = (receiver) => {
        setReceiverEmail(receiver.contact?.email, true);
    };

    const context = useContext(Context);

    async function createBatchOrder(order, customerId) {
        try {
            const response = await Api.createBatchOrder({
                order,
                customerId,
            });

            if (response?.message === "OK") {
                toast.dismiss();
                toast.success("Ny order har skapats");
            }
        } catch (error) {
            toast.error(
                "Något gick fel. Försök igen annars kontakta kundservice.",
            );
        }
    }

    async function save() {
        if (!isValid()) {
            return;
        }
        const order = {
            route: {
                from: sender,
                to: receiver,
            },
            packages: packages,
            information: information,
        };

        const notificationOptions = getAdditionalOptionsNotificationOption();
        if (notificationOptions) {
            order.additionalOptions = [...notificationOptions];
        }

        setLoading(true);
        toast.loading("Skapar order...");
        await createBatchOrder(order, customerId);
        setLoading(false);
        await getBatchOrders();
        setIsOpen(false);
    }

    const isValid = () => {
        return (
            senderRef.current.validate() &&
            receiverRef.current.validate() &&
            packagesRef.current.validate() &&
            informationRef.current.validate() &&
            areEmailsValid
        );
    };

    function requireEmail() {
        return sender?.countryCode !== "SE" || receiver?.countryCode !== "SE";
    }

    function onChangedPackages(packages) {
        setPackages(packages);
        const packageWithOwnGoodsType = packages.find(
            (pkg) => pkg.description !== "",
        );

        if (packageWithOwnGoodsType) {
            setInformation({
                description: packageWithOwnGoodsType.description
                    ? packageWithOwnGoodsType.description
                    : "",
                reference: information?.reference || "",
                dangerousGoods: information?.dangerousGoods || {
                    active: false,
                },
            });
        }
    }

    function swapParties() {
        const currentSender = sender;
        const currentReceiver = receiver;
        handleSelectSender(currentReceiver);
        handleSelectReceiver(currentSender);
        senderRef.current.set(currentReceiver);
        receiverRef.current.set(currentSender);
    }

    return (
        <Modal
            title={"Skapa försändelse"}
            description={"Skapa ny försändelse här"}
            maxWidth="max-w-7xl"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <div className="overflow-y-auto overflow-x-hidden max-h-4xl py-6 pr-6">
                <div className="flex gap-3 ">
                    <div>
                        <h2 className="py-3 text-lg font-semibold">
                            Avsändare
                        </h2>
                        <CompanyForm
                            onChange={handleSetSender}
                            onSelect={handleSelectSender}
                            ref={senderRef}
                            requireEmail={requireEmail()}
                            enableDifferentPickupAddress={true}
                            company={{
                                countryCode:
                                    context.user.customer.countryCode || "SE",
                                addressLine1:
                                    context.user.customer.addressLine1 || "",
                                addressLine2:
                                    context.user.customer.addressLine2 || "",
                                addressLine3:
                                    context.user.customer.addressLine3 || "",
                                postalTown:
                                    context.user.customer.postalTown || "",
                                postalCode:
                                    context.user.customer.postalCode || "",
                                contact: {
                                    name:
                                        context.user.customer.contactName || "",
                                    phoneNumber:
                                        context.user.customer
                                            .contactPhoneNumber || "",
                                    email: context.user.email || "",
                                    company: context.user.customer.name || "",
                                    vatNumber:
                                        context.user.customer.vatNumber || "",
                                },
                            }}
                        />
                    </div>
                    <div className="w-32 mt-80 px-1">
                        <button onClick={swapParties}>
                            <img
                                src="/images/icons/swap-arrow.svg"
                                alt="info"
                                className="cursor-pointer"
                            />
                        </button>
                    </div>
                    <div>
                        <h2 className="py-3 text-lg font-semibold">
                            Mottagare
                        </h2>
                        <CompanyForm
                            enablePrivateCustomer={true}
                            onChange={handleSetReceiver}
                            onSelect={handleSelectReceiver}
                            ref={receiverRef}
                            enableDifferentPickupAddress={false}
                            requireEmail={requireEmail()}
                        />
                    </div>
                </div>
                <div>
                    <h2 className="py-3 text-lg font-semibold">
                        Bokningsbekräftelse
                    </h2>
                    <div className="border-1 rounded-sm p-4">
                        <div className="w-1/2">{emailNotificationForm}</div>
                    </div>
                </div>
                <div>
                    <h2 className="py-3 text-lg font-semibold">Kolli</h2>
                    <Packages
                        onChange={onChangedPackages}
                        ref={packagesRef}
                        fromBatch={true}
                    />
                </div>
                <div>
                    <h2 className="py-3 text-lg font-semibold">Information</h2>
                    <ShipmentInformation
                        information={information}
                        onChange={setInformation}
                        isBatchView={true}
                        ref={informationRef}
                    />
                </div>
                <div className="flex justify-end mt-4">
                    <button
                        className="c-button--raised c-button"
                        onClick={() => {
                            save();
                        }}
                        disabled={loading}
                    >
                        Skapa
                    </button>
                    <button
                        className="c-button c-button--ghost delete"
                        onClick={() => setIsOpen(false)}
                    >
                        Avbryt
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default CreateBatchOrderModal;
