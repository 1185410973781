import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

let style = `
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	background: var(--button-color);
	box-sizing: border-box;
	font-size: 12px;
	border-radius: 5px;
	box-shadow: 0 1px 5px 1px rgba(0,0,0,.2);
	cursor: pointer;
	outline: none;
	transition: all .3s ease;
	color: #fff;
	text-decoration: none;

	&:hover {
		box-shadow: 0 1px 7px 2px rgba(0,0,0,.2);
		background: var(--button-color-darken);
	}
`;

let ContainerDiv = styled.div`
    ${style}
    ${(props) =>
        props.primary &&
        `
		background: var(--color-primary);

		&:hover {
			background: var(--color-primary-darken);
		}
	`}

	${(props) =>
        props.secondary &&
        `
		background: var(--color-secondary);

		&:hover {
			background: var(--color-secondary-darken);
		}
	`}

	${(props) =>
        props.cancel &&
        `
		background: var(--color-cancel);

		&:hover {
			background: var(--color-cancel-darken);
		}
	`}
`;

let ContainerLink = styled(Link)`
    ${style}
    ${(props) =>
        props.primary &&
        `
		background: var(--color-primary);

		&:hover {
			background: var(--color-primary-darken);
		}
	`}

	${(props) =>
        props.secondary &&
        `
		background: var(--color-secondary);

		&:hover {
			background: var(--color-secondary-darken);
		}
	`}

	${(props) =>
        props.cancel &&
        `
		background: var(--color-cancel);

		&:hover {
			background: var(--color-cancel-darken);
		}
	`}
`;

class Button extends Component {
    render() {
        if (this.props.link) {
            return <ContainerLink {...this.props} />;
        } else {
            return <ContainerDiv {...this.props} />;
        }
    }
}

export default Button;
