import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";

import Loader from "components/Loader/Loader";
import Api from "services/Api/Api";

import Context from "context/Global";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
`;

function View(props) {
    const context = useContext(Context);

    const [brands, setBrands] = useState([]);

    async function loadBrands() {
        setBrands(await Api.getBrands());
    }

    useEffect(() => {
        if (context.user.administrator) {
            loadBrands();
        } else {
            props.history.replace("/admin");
        }
    }, []);

    if (brands.length === 0) {
        return <Loader />;
    }

    return (
        <Container>
            <h1>Brands</h1>
            {brands.map((brand) => {
                return (
                    <Link
                        key={brand.id}
                        to={`/admin/brands/${brand.id}`}
                        className="button"
                    >
                        {brand.name}
                    </Link>
                );
            })}
        </Container>
    );
}

export default View;
