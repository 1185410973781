import Integrations from "../constants/integrations";
import Api from "../services/Api/Api";

/**
 * Updates the state with information about expiring or expired integrations.
 * It filters specific integration types and fetches their expiration status.
 *
 * @param {Object[]} integrations - The array of integration objects to check.
 * @param {Function} updateExpiredIntegrationsState - A function to update the state with expired integrations data.
 * @returns {Promise<void>} - A promise that resolves when the state is updated with expired integrations information.
 * @throws {Error} - Throws an error if the API call fails.
 */
export const useExpiredIntegrationsUpdater = (
    integrations,
    updateExpiredIntegrationsState,
) => {
    const updateExpiredIntegrations = async () => {
        if (!integrations || !integrations.length) {
            return;
        }

        const filterTypes = [
            Integrations.FORTNOX,
            Integrations.BUSINESS_CENTRAL,
        ];
        const integrationIds = integrations
            .filter((integration) => filterTypes.includes(integration?.type))
            .map((integration) => integration.id);

        if (integrationIds.length > 0) {
            try {
                const expiredIntegrationsData =
                    await Api.getIntegrationTokenExpiryStatuses({
                        integrationIds,
                    });
                updateExpiredIntegrationsState(expiredIntegrationsData);
            } catch (error) {
                console.error("Failed to update expired integrations: ", error);
            }
        }
    };

    updateExpiredIntegrations();
};
