import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import CustomInput from "components/CustomInput";

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
    z-index: 1000;

    &.is-visible {
        opacity: 1;
        pointer-events: all;
    }

    > .container {
        background: #fff;
        width: 500px;
        height: 300px;
        padding: 1rem;
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > .title {
            font-size: 1.4rem;
            margin-bottom: 1rem;
        }

        > p {
            margin-bottom: 1.5rem;
        }

        > form {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            > select,
            > input {
                width: 100%;
                padding: 0.5rem;
                margin-bottom: 1rem;
                border: 1px solid #ccc;
                border-radius: 4px;

                &:focus {
                    border-color: #007bff;
                }
            }

            > .buttons {
                margin-top: auto;
                display: flex;
                justify-content: flex-end;

                > .c-button {
                    padding: 0.5rem 1rem;
                    border: none;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                }
            }
        }
    }
`;

const Component = ({ visible, existingGroups, save, close }) => {
    const [groupName, setGroupName] = useState("");
    const [selectedGroup, setSelectedGroup] = useState("");

    useEffect(() => {
        if (visible) {
            setGroupName("");
        }
    }, [visible]);

    const handleSave = (e) => {
        e.preventDefault();
        const groupToSave = groupName || selectedGroup;
        if (groupToSave) {
            save(groupToSave);
            close();
        }
    };

    const handleGroupNameChange = (value) => {
        setGroupName(value);
        setSelectedGroup("");
    };

    return (
        <Container className={visible ? "is-visible" : ""}>
            <div className="container">
                <div className="title">Koppla godsgrupp</div>
                <p>
                    Du kan antingen skapa en ny grupp eller välja en befintlig.
                </p>
                <form onSubmit={handleSave}>
                    <CustomInput
                        existingGroups={existingGroups}
                        value={groupName}
                        onChange={handleGroupNameChange}
                        type="text"
                        placeholder={
                            existingGroups.length > 0
                                ? "Skapa ny eller välj befintlig grupp..."
                                : "Skapa ny grupp..."
                        }
                        maxLength={45}
                    />
                    <div className="buttons">
                        <div className="c-button" onClick={close}>
                            Avbryt
                        </div>
                        <button
                            type="submit"
                            className="c-button c-button--raised"
                        >
                            Spara
                        </button>
                    </div>
                </form>
            </div>
        </Container>
    );
};

export default Component;
