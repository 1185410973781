import InputCheckbox from "components/Input/Checkbox";
import InputDropdown from "components/Input/Dropdown";
import InputText from "components/Input/Text";
import Context from "context/Global";
import { priceParser } from "helpers/StringParser";
import React, { useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";
import Row from "./Row";

let ChangeCustomer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    > .container {
        background: #fff;
        border-radius: 1rem;
        padding: 1rem;
    }
`;

let Menu = styled.div`
	> .button {
		color: rgba(0,0,0,.5);
		display: inline-flex;
		align-items: center;
		justify-content: flex-end;
		position: relative;
		cursor: pointer;

		&:hover {
			color: rgba(0,0,0,1);
		}

		> .menu-wrapper {
			pointer-events: none;
			display: flex;
			justify-content: center;
			align-items: center;

			> .menu-background {
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 100;
			}

			> .menu-container {
				position: absolute;
				background: #fff;
				box-shadow: 0 0 5px 1px rgba(0,0,0,.1);
				right: 0;
				transition: all .3s ease;
				opacity: 0;
				z-index: 101;
				transform: translateY(10px);

				> .option {
					padding: 1rem;
					white-space: nowrap;
					color: #000;

					&:hover {
						background: #eaeaea;
					}
				}
			}

			&.is-visible {
				pointer-events: all;

				> .menu-container {
					opacity: 1;
					transform: translateY(0);
				}
			}
		}
	}
}
`;

function roundToTwo(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}

function Order(props) {
    const context = useContext(Context);
    const [orderId, setOrderId] = useState(props.order?.id);
    const [transporter, setTransporter] = useState(props.order?.transporter);
    const [trackingNumber, setTrackingNumber] = useState(
        props.order?.trackingNumber,
    );
    const [date, setDate] = useState(props.order?.date);
    const [fileName, setFileName] = useState(props.order?.fileName);
    const [title, setTitle] = useState(props.order?.title);
    const [sender, setSender] = useState(props.order?.sender);
    const [senderCountry, setSenderCountry] = useState(
        props.order?.senderCountry,
    );
    const [recipient, setRecipient] = useState(props.order?.recipient);
    const [recipientCountry, setRecipientCountry] = useState(
        props.order?.recipientCountry,
    );
    const [vat, setVat] = useState(props.order?.vat);
    const [reference, setReference] = useState(props.order?.reference);
    const [totalShipmentCost, setTotalShipmentCost] = useState(
        props.order?.totalShipmentCost,
    );
    const [totalCustomerPrice, setTotalCustomerPrice] = useState(
        props.order?.totalCustomerPrice,
    );
    const [customerTransportPrice, setCustomerTransportPrice] = useState(
        props.order?.customerTransportPrice,
    );
    const [transporterInvoiceNumber, setTransporterInvoiceNumber] = useState(
        props.order?.transporterInvoiceNumber,
    );
    const [currentInvoiceNumber, setCurrentInvoiceNumber] = useState(
        props.order?.currentInvoiceNumber,
    );
    const [externalInvoices, setExternalInvoices] = useState(
        props.order?.externalInvoices || [],
    );
    const [invoiceIds, setInvoiceIds] = useState(props.order?.invoiceIds);
    const [partnerCost, setPartnerCost] = useState(props.order?.partnerCost);
    const [rows, setRows] = useState(props.order?.rows);
    const [insuranceValue] = useState(props.order?.insuranceValue);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [showChangeCustomer, setShowChangeCustomer] = useState(false);
    const [customerList, setCustomerList] = useState(null);

    function doChange() {
        if (props.onChange) {
            props.onChange({
                id: orderId,
                transporter,
                trackingNumber,
                date,
                fileName,
                title,
                sender,
                senderCountry,
                recipient,
                recipientCountry,
                customerTransportPrice: parseFloat(customerTransportPrice) || 0,
                transporterInvoiceNumber,
                currentInvoiceNumber,
                externalInvoices,
                invoiceIds,
                insuranceValue,
                vat,
                reference,
                totalShipmentCost: parseFloat(totalShipmentCost) || 0,
                partnerCost: parseFloat(partnerCost) || 0,
                rows,
            });
        }
    }

    useEffect(() => {
        setRows(props.order?.rows?.length ? props.order?.rows : []);
    }, []);

    useEffect(() => {
        if (props.order?.rows) {
            setRows(props.order?.rows);
        }
    }, [props.order?.rows]);

    useEffect(() => {
        doChange();
    }, [
        transporter,
        trackingNumber,
        date,
        title,
        sender,
        recipient,
        senderCountry,
        recipientCountry,
        vat,
        reference,
        totalCustomerPrice,
        totalShipmentCost,
        partnerCost,
        rows,
    ]);

    useEffect(() => {
        setTotalCustomerPrice(
            roundToTwo(
                rows.reduce((cost, row) => {
                    return (
                        (parseFloat(cost) || 0) + (parseFloat(row.cost) || 0)
                    );
                }, parseFloat(customerTransportPrice)),
            ),
        );
    }, [customerTransportPrice, rows]);

    function getResult() {
        return (
            Math.round(totalCustomerPrice - totalShipmentCost - partnerCost) ||
            0
        );
    }

    function addRow() {
        setRows((rows) => {
            return rows.concat([
                {
                    key: uuidv4(),
                },
            ]);
        });
    }

    function getWarnings() {
        let warnings = [];
        if (externalInvoices.indexOf("" + currentInvoiceNumber) >= 0) {
            warnings.push(
                <div className="done" key="done">
                    <div className="text">
                        Försändelsen har fakturerats för med samma externa
                        fakturanummer: {currentInvoiceNumber}
                    </div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M22 2v22h-20v-22h3c1.23 0 2.181-1.084 3-2h8c.82.916 1.771 2 3 2h3zm-11 1c0 .552.448 1 1 1 .553 0 1-.448 1-1s-.447-1-1-1c-.552 0-1 .448-1 1zm9 1h-4l-2 2h-3.897l-2.103-2h-4v18h16v-18zm-13 9.729l.855-.791c1 .484 1.635.852 2.76 1.654 2.113-2.399 3.511-3.616 6.106-5.231l.279.64c-2.141 1.869-3.709 3.949-5.967 7.999-1.393-1.64-2.322-2.686-4.033-4.271z"
                        />
                    </svg>
                </div>,
            );
        } else if (externalInvoices?.length) {
            warnings.push(
                <div className="warning" key="warning">
                    <div className="text">
                        Försändelsen har fakturerats för tidigare.
                        <br />
                        Externa fakturanummer: {externalInvoices.join(", ")}
                    </div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
                        />
                    </svg>
                </div>,
            );
        } else if (invoiceIds?.length) {
            warnings.push(
                <div className="warning" key="warning">
                    <div className="text">
                        Försändelsen har fakturerats för tidigare.
                        <br />
                        Interna fakturanummer:{" "}
                        {getInternalInvoicesList(invoiceIds)}
                    </div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
                        />
                    </svg>
                </div>,
            );
        }
        if (!!insuranceValue) {
            warnings.push(
                <div className="insurance" key="insurance">
                    <div className="text">
                        Försäkrad: {priceParser(insuranceValue)}
                    </div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M15.762 8.047l-4.381 4.475-2.215-2.123-1.236 1.239 3.451 3.362 5.619-5.715-1.238-1.238zm-3.762-5.503c2.5 1.805 4.555 2.292 7 2.416v9.575c0 3.042-1.686 3.827-7 7.107-5.309-3.278-7-4.065-7-7.107v-9.575c2.447-.124 4.5-.611 7-2.416zm0-2.544c-3.371 2.866-5.484 3-9 3v11.535c0 4.603 3.203 5.804 9 9.465 5.797-3.661 9-4.862 9-9.465v-11.535c-3.516 0-5.629-.134-9-3z"
                        />
                    </svg>
                </div>,
            );
        }
        return warnings;
    }

    function openMenu(ev) {
        ev.stopPropagation();
        setMenuIsOpen(true);
    }

    function closeMenu(ev) {
        ev.stopPropagation();
        setMenuIsOpen(false);
    }

    function changeCustomer(ev) {
        ev.stopPropagation();
        setMenuIsOpen(false);
        setShowChangeCustomer(true);
    }

    useEffect(() => {
        loadCustomerList();
    }, [showChangeCustomer]);

    async function loadCustomerList() {
        setCustomerList(await Api.getCustomers());
    }

    return (
        <>
            <div key={"transporter-" + orderId} className="cell">
                <InputText value={transporter} onChange={setTransporter} />
            </div>
            <div key={"trackingNumber-" + orderId} className="cell">
                <InputText
                    value={trackingNumber}
                    onChange={setTrackingNumber}
                />
            </div>
            <div key={"date-" + orderId} className="cell">
                <InputText value={date} onChange={setDate} />
            </div>
            <div key={"title-" + orderId} className="cell">
                <InputText value={title} onChange={setTitle} />
            </div>
            <div key={"sender-" + orderId} className="cell">
                <InputText value={sender} onChange={setSender} />
            </div>
            <div key={"senderCountry-" + orderId} className="cell">
                <InputText value={senderCountry} onChange={setSenderCountry} />
            </div>
            <div key={"recipient-" + orderId} className="cell">
                <InputText value={recipient} onChange={setRecipient} />
            </div>
            <div key={"recipientCountry-" + orderId} className="cell">
                <InputText
                    value={recipientCountry}
                    onChange={setRecipientCountry}
                />
            </div>
            <div key={"customerTransportPrice-" + orderId} className="cell">
                <InputText
                    value={customerTransportPrice}
                    onChange={setCustomerTransportPrice}
                    align="right"
                />
            </div>
            <div key={"vat-" + orderId} className="cell checkbox">
                <InputCheckbox value={vat} onChange={setVat} />
            </div>
            <Menu className="cell center">
                <div className="button" onClick={openMenu}>
                    <div
                        className={`menu-wrapper ${
                            menuIsOpen ? " is-visible" : ""
                        }`}
                    >
                        <div
                            className="menu-background"
                            onClick={closeMenu}
                        ></div>
                        <div className="menu-container">
                            <div className="option" onClick={props.onRemove}>
                                Ta bort order
                            </div>
                            <div className="option" onClick={changeCustomer}>
                                Flytta till annan kund
                            </div>
                        </div>
                    </div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M12 18c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z"
                        />
                    </svg>
                </div>
            </Menu>
            {/* <div className="cell"><div className="icon remove" onClick={props.onRemove}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg></div></div> */}
            {rows.map((row, index) => {
                if (index === 0) {
                    row.reference = reference;
                }
                return (
                    <Row
                        key={row.key}
                        row={row}
                        index={index}
                        onChange={(newValue) => {
                            props.updateRows(
                                rows.map((r, oldIndex) => {
                                    if (oldIndex === index) {
                                        return newValue;
                                    }
                                    return r;
                                }),
                            );
                        }}
                        onRemove={() => {
                            props.updateRows(
                                rows.filter((r) => {
                                    return r.key !== row.key;
                                }),
                            );
                        }}
                    />
                );
            })}
            <div className="cell">
                <div className="c-button c-button--raised" onClick={addRow}>
                    Ny rad
                </div>
            </div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cell bold">Kundens pris</div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cell bold align-right">
                {priceParser(totalCustomerPrice || 0)}
            </div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div
                data-tip
                data-for={`filename-${orderId}`}
                className="cell info"
            >
                {fileName}
            </div>
            <ReactTooltip
                id={`filename-${orderId}`}
                type="light"
                effect="solid"
                arrowColor="transparent"
                backgroundColor="#eee"
            >
                Filnamn: {fileName}
            </ReactTooltip>
            <div className="cell icons">{getWarnings()}</div>
            <div className="cell"></div>
            <div className="cell">Kostnad</div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cell align-right">
                <InputText
                    value={totalShipmentCost || 0}
                    onChange={setTotalShipmentCost}
                    align="right"
                />
            </div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div
                data-tip
                data-for={`externalInvoice-${orderId}`}
                className="cell info"
            >
                {currentInvoiceNumber}
            </div>
            <ReactTooltip
                id={`externalInvoice-${orderId}`}
                type="light"
                effect="solid"
                arrowColor="transparent"
                backgroundColor="#eee"
            >
                Externt fakturanummer: {currentInvoiceNumber}
            </ReactTooltip>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cell">Partnerkostnad</div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cell align-right">
                <InputText
                    value={partnerCost || 0}
                    onChange={setPartnerCost}
                    align="right"
                />
            </div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cell bold">Resultat</div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div
                className={`cell bold align-right ${
                    getResult() > 0 ? "is-positive" : "is-negative"
                }`}
            >
                {priceParser(getResult())}
            </div>
            <div className="cell"></div>
            <div className="cell"></div>
            <div className="cellDivider"></div>
            {showChangeCustomer && (
                <ChangeCustomer
                    onClick={() => {
                        setShowChangeCustomer(false);
                    }}
                >
                    <div
                        className="container"
                        onClick={(ev) => {
                            ev.stopPropagation();
                        }}
                    >
                        <InputDropdown
                            searchable
                            onChange={(customer) =>
                                props.moveOrder({ customer })
                            }
                            value={null}
                            options={[
                                {
                                    title: "Välj kund",
                                    value: null,
                                },
                            ].concat(
                                customerList.map((customer) => {
                                    return {
                                        title: customer.name,
                                        value: customer,
                                        identifier: customer.id,
                                    };
                                }),
                            )}
                        />
                    </div>
                </ChangeCustomer>
            )}
        </>
    );
}

export default Order;

function getInternalInvoicesList(invoiceIds) {
    return (
        <span className="list">
            {invoiceIds.map((id) => {
                return <a href={`/invoices/${id}`}>{id}</a>;
            })}
        </span>
    );
}
