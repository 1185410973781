import Table from "components/Table";
import React, { useContext, useState } from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/sv";
import Context from "context/Global";
import Api from "services/Api/Api";
import toast from "react-hot-toast";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
`;

function Component({ customerId, users, ...props }) {
    const context = useContext(Context);
    const [userList, setUserList] = useState(users);
    const [loading, setLoading] = useState(false);

    async function removeUser(user) {
        let remove = window.confirm(
            `Vill du ta bort användaren: ${user.name}?`,
        );
        if (remove) {
            setLoading(true);

            await Api.removeUserFromCustomer({
                userId: user.id,
                customerId: customerId,
            })
                .then(() => {
                    setUserList((prevUsers) =>
                        prevUsers.filter((u) => u.id !== user.id),
                    );
                    toast.success(`Användare ${user.name} har tagits bort.`);
                })
                .catch((error) => {
                    toast.error(
                        "Ett fel inträffade vid borttagningen av användaren.",
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    return (
        <Container>
            <Table className="users">
                <thead>
                    <tr>
                        <th>Namn</th>
                        <th>Skapad</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {userList.map((user) => {
                        return (
                            <tr key={user.id} className="user">
                                <td>
                                    {user.name} ({user.email})
                                </td>
                                <td
                                    title={moment(user.created).format(
                                        "YYYY-MM-DD HH:mm:ss",
                                    )}
                                >
                                    {moment(user.created).fromNow()}
                                </td>
                                <td>
                                    <button
                                        onClick={() => removeUser(user)}
                                        className="c-button c-button--text"
                                        disabled={
                                            loading ||
                                            user.id === context.user.id
                                        }
                                    >
                                        {loading ? "Tar bort..." : "Ta bort"}
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <div className="section">
                <div className="buttons">
                    <Link
                        to={`/account/users/add`}
                        className="c-button c-button--raised"
                    >
                        Ny användare
                    </Link>
                </div>
            </div>
        </Container>
    );
}

export default Component;
