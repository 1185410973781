import moment from "moment";
import Papa from "papaparse";
import { v4 as uuidv4 } from "uuid";

function parse({ data, fileName }) {
    let rows = Papa.parse(data, {
        delimiter: ",",
    });

    if (rows.errors.length) {
        window.alert(
            "Filen är inte korrekt formatterad. Granska (!!!) och skicka till Tobbe om något saknas.\nFelmeddelanden:\n" +
                rows.errors.map((err) => err.message).join("\n"),
        );
    }

    return rows.data
        .map((row) => {
            if (row[0] === "Account Number" || !row[13]) {
                return null;
            }

            const totalShipmentCost = Math.round(parseFloat(row[23]));
            const vat = !!parseFloat(row[24]);
            const totalShipmentCostVat = vat ? totalShipmentCost : 0;
            const totalShipmentCostNoVat = !vat ? totalShipmentCost : 0;
            const billedWeight = row[17] || null;
            let extras = [];
            if (billedWeight) {
                extras.push({
                    title: `Fakturerad vikt: ${billedWeight} kg`,
                    key: uuidv4(),
                });
            }

            return {
                fileName: fileName,
                transporter: "TNT",
                trackingNumber: row[13],
                currentInvoiceNumber: row[1],
                date: moment(row[16], "DD/MM/YY").format("YYYY-MM-DD"),
                title: "Transport",
                sender: row[12],
                senderCountry: "",
                recipient: row[7],
                recipientCountry: row[11],
                totalShipmentCost: totalShipmentCost,
                totalShipmentCostVat: totalShipmentCostVat,
                totalShipmentCostNoVat: totalShipmentCostNoVat,
                vat: vat,
                rows: extras,
            };
        })
        .filter((v) => !!v);
}

export default {
    parse,
};
