import Input from "components/OldInput";
import Context from "context/Global";
import { getCountryName } from "helpers/Country";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import { getProductName } from "../Helpers";
import GoodsDescription from "./Input/GoodsDescription";
import Packages from "./Input/Packages";

let Container = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    padding: 1rem;
    box-sizing: border-box;

    > .box {
        width: fit-content;
        min-width: 60%;
        height: fit-content;
        max-height: 80%;
        background: #fff;
        border-radius: 1rem;
        box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.5);
        overflow: hidden;
        box-sizing: border-box;

        > .wrapper {
            overflow: auto;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;

            > .title {
                font-size: 2rem;
                padding: 1rem 1rem 0 1rem;
                text-align: center;
            }

            > .summary {
                padding: 2rem;
                display: grid;
                grid-template: auto / 1fr 1fr;
                gap: 0 4rem;

                > .title {
                    text-align: center;
                    font-size: 1.3rem;
                    color: rgba(0, 0, 0, 0.6);
                    padding: 0px 0 0.5rem;
                }

                > .party {
                    display: flex;
                    gap: 1rem;
                    justify-content: center;

                    > .column {
                        display: flex;
                        flex-direction: column;

                        > label {
                            display: inline-flex;
                            -webkit-box-align: center;
                            align-items: center;
                            font-size: 0.7rem;
                            font-weight: bold;
                            padding: 4px;
                            margin-top: 0.5rem;
                        }

                        > .Input + .Input {
                            margin-top: 0.5rem;
                        }
                    }
                }
            }

            > .input {
                padding: 0 1rem;
                width: fit-content;
                align-self: center;

                > .Input > div {
                    display: flex;
                    flex-direction: column;

                    > .item {
                        width: fit-content;
                    }
                }
            }

            > .buttons {
                padding: 1rem;
                display: flex;
                justify-content: flex-end;
                margin-top: auto;
                gap: 1rem;
                align-items: center;

                > .cancel {
                    margin-right: auto;
                }

                > .service {
                    display: flex;
                    flex-direction: column;
                    white-space: nowrap;
                    font-size: 0.8rem;
                    margin-top: 0.2rem;

                    > img {
                        height: 20px;
                    }

                    > .name {
                    }
                }
            }
        }
    }
`;

function Component({ history, order, closeDialog = () => {}, ...props }) {
    const context = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [inputObject, setInputObject] = useState(null);
    const [value, setValue] = useState(null);
    const [bookingOrder, setBookingOrder] = useState(false);
    const [template, setTemplate] = useState(props.template);
    const inputRef = useRef();
    const senderContactPhoneNumberRef = useRef();
    const senderContactEmailRef = useRef();
    const [senderContact, setSenderContact] = useState(context.user);

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (senderContact) {
            senderContactPhoneNumberRef.current?.set(senderContact.phoneNumber);
            senderContactEmailRef.current?.set(senderContact.email);
        }
    }, [senderContact]);

    async function init() {
        let bookingObject = {};
        if (template.packages) {
            bookingObject.packages = {
                type: Packages,
                value: order.data.packages,
                placeholder: "0",
                options: template.packages,
                showStackable:
                    [
                        "DHL_EUROCONNECT",
                        "DHL_EUROLINE",
                        "DHL_EUROCONNECT_PLUS",
                        "DHL_HOME_DELIVERY",
                        "DHL_PARTI",
                        "DHL_STYCKE",
                    ].indexOf(template.productCode) >= 0,
                multiRow:
                    ["DHL_SERVICE_POINT", "DHL_SERVICE_POINT2"].indexOf(
                        template.productCode,
                    ) < 0,
                isPallet:
                    ["DHL_PALL", "DHL_HALV_PALL"].indexOf(
                        template.productCode,
                    ) >= 0,
                isHalfPallet: template.productCode === "DHL_HALV_PALL",
            };
        }

        if (template.description) {
            bookingObject.description = {
                title: "Godsbeskrivning",
                type: GoodsDescription,
                freetext: template.description.freetext,
                options: template.description.options,
                value: order.data.information.description,
            };
        }
        setInputObject(bookingObject);
    }

    function close(ev) {
        ev.stopPropagation();
        closeDialog();
    }

    async function book() {
        if (!inputRef.current.validate()) {
            return;
        }
        setBookingOrder(true);
        props.onStateChanged({
            status: "BOOKING",
        });

        const orderResponse = await Api.createOrder({
            externalId: order.externalId,
            customerIntegrationId: order.integrationId,
            service: {
                productCode: props.template.productCode,
            },
            price: null,
            order: {
                importedOrderId: order.id,
                customs: null,
                customsInvoice: null,
                information: {
                    dangerousGoods: { active: false },
                    description: value.description,
                    reference: order.data.information.reference,
                },
                route: {
                    from: {
                        countryCode: order.data.sender.countryCode,
                        postalTown: order.data.sender.postalTown,
                        postalCode: order.data.sender.postalCode,
                        stateCode: order.data.sender.stateCode,
                        otherPickupAddress: false,
                        addressLine1: order.data.sender.addressLine1,
                        addressLine2: order.data.sender.addressLine2,
                        addressLine3: order.data.sender.addressLine3,
                        contact: {
                            private: order.data.sender.contact.private || false,
                            company: order.data.sender.contact.company,
                            name: senderContact.name,
                            email: senderContact.email,
                            phoneNumber: senderContact.phoneNumber,
                            vatNumber: order.data.sender.contact.vatNumber,
                        },
                    },
                    to: {
                        countryCode: order.data.receiver.countryCode,
                        postalTown: order.data.receiver.postalTown,
                        postalCode: order.data.receiver.postalCode,
                        stateCode: order.data.receiver.stateCode,
                        otherPickupAddress: false,
                        addressLine1: order.data.receiver.addressLine1,
                        addressLine2: order.data.receiver.addressLine2,
                        addressLine3: order.data.receiver.addressLine3,
                        contact: {
                            private:
                                order.data.receiver.contact.private || false,
                            company: order.data.receiver.contact.company,
                            name: order.data.receiver.contact.name,
                            email: order.data.receiver.contact.email,
                            phoneNumber:
                                order.data.receiver.contact.phoneNumber,
                            vatNumber: order.data.receiver.contact.vatNumber,
                        },
                    },
                },
                packages: value.packages.map((pkg) => {
                    return {
                        count: pkg.count,
                        height: pkg.height,
                        length: pkg.length,
                        stackable: pkg.stackable,
                        type: pkg.type,
                        weight: pkg.weight,
                        width: pkg.width,
                    };
                }),
            },
            pickup: {
                date: moment().format("YYYY-MM-DD"),
                // instructions: "...",
                // location: null,
                timeInterval: {
                    start: moment().format("YYYY-MM-DD 08:00:00"),
                    end: moment().format("YYYY-MM-DD 19:00:00"),
                },
                skipPickupOrder: true,
            },
            reference: order.reference,
            delivery: {
                servicePoint: null,
                date: "2012-12-01",
                instructions: "...",
            },
            notifications: null,
            lithiumSettings: null,
            dryIce: null,
            customs: null,
            customsInvoice: null,
            timeInterval: null,
            additionalOptions: [],
            insurance: null,
            palletExchange: null,
        });

        if (orderResponse.data.id && orderResponse.data.trackingNumber) {
            props.onStateChanged({
                status: "BOOKED",
                order: orderResponse.data,
            });
        } else {
            props.onStateChanged({
                status: "FAILED",
            });
        }
    }

    function changeSenderContact(userId) {
        const selectedUser = context.user.customer.users.find((user) => {
            return user.id === parseInt(userId);
        });

        setSenderContact(selectedUser);
    }

    if (!inputObject) {
        return null;
    }

    return (
        <Container onClick={close}>
            <div className="box" onClick={(ev) => ev.stopPropagation()}>
                <div className="wrapper">
                    <div className="summary">
                        <div className="title">Avsändare</div>
                        <div className="title">Mottagare</div>
                        <div className="party">
                            <div className="column">
                                <label>Land eller territorium</label>
                                <Input
                                    type="text"
                                    value={getCountryName(
                                        order.data.sender.countryCode,
                                    )}
                                    disabled
                                />
                                <label>Postnummer</label>
                                <Input
                                    type="text"
                                    value={order.data.sender.postalCode}
                                    disabled
                                />
                                <label>Postort</label>
                                <Input
                                    type="text"
                                    value={order.data.sender.postalTown}
                                    disabled
                                />
                                <label>Adress</label>
                                <Input
                                    type="text"
                                    value={order.data.sender.addressLine1}
                                    disabled
                                />
                                <Input
                                    type="text"
                                    value={order.data.sender.addressLine2}
                                    disabled
                                />
                                <Input
                                    type="text"
                                    value={order.data.sender.addressLine3}
                                    disabled
                                />
                            </div>
                            <div className="column">
                                <label>Företag</label>
                                <Input
                                    type="text"
                                    value={order.data.sender.contact.company}
                                    disabled
                                />
                                <label>Kontakt</label>
                                <Input
                                    type="dropdown"
                                    options={context.user.customer.users.map(
                                        (user) => {
                                            return {
                                                title: user.name,
                                                value: user.id,
                                            };
                                        },
                                    )}
                                    onChange={changeSenderContact}
                                />
                                <label>Telefonnummer</label>
                                <Input
                                    ref={senderContactPhoneNumberRef}
                                    type="text"
                                    value={senderContact.phoneNumber}
                                    disabled
                                />
                                <label>E-postadress</label>
                                <Input
                                    ref={senderContactEmailRef}
                                    type="text"
                                    value={senderContact.email}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="party">
                            <div className="column">
                                <label>Land eller territorium</label>
                                <Input
                                    type="text"
                                    value={getCountryName(
                                        order.data.receiver.countryCode,
                                    )}
                                    disabled
                                />
                                <label>Postnummer</label>
                                <Input
                                    type="text"
                                    value={order.data.receiver.postalCode}
                                    disabled
                                />
                                <label>Postort</label>
                                <Input
                                    type="text"
                                    value={order.data.receiver.postalTown}
                                    disabled
                                />
                                <label>Adress</label>
                                <Input
                                    type="text"
                                    value={order.data.receiver.addressLine1}
                                    disabled
                                />
                                <Input
                                    type="text"
                                    value={order.data.receiver.addressLine2}
                                    disabled
                                />
                                <Input
                                    type="text"
                                    value={order.data.receiver.addressLine3}
                                    disabled
                                />
                            </div>
                            <div className="column">
                                <label>Företag</label>
                                <Input
                                    type="text"
                                    value={order.data.receiver.contact.company}
                                    disabled
                                />
                                <label>Kontakt</label>
                                <Input
                                    type="text"
                                    value={order.data.receiver.contact.name}
                                    disabled
                                />
                                <label>Telefonnummer</label>
                                <Input
                                    type="text"
                                    value={
                                        order.data.receiver.contact.phoneNumber
                                    }
                                    disabled
                                />
                                <label>E-postadress</label>
                                <Input
                                    type="text"
                                    value={order.data.receiver.contact.email}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div className="input">
                        <Input
                            ref={inputRef}
                            type="list"
                            object={inputObject}
                            onChange={setValue}
                        />
                    </div>
                    <div className="buttons">
                        <button
                            className="c-button c-button--ghost cancel"
                            onClick={close}
                        >
                            Avbryt
                        </button>
                        <div className="service">
                            <img
                                src={`/images/icons/${template.service.toLowerCase()}.svg`}
                            />
                            <div className="name">
                                {getProductName(template.productCode)}
                            </div>
                        </div>
                        <button
                            className="c-button c-button--raised"
                            onClick={book}
                            disabled={bookingOrder}
                        >
                            Boka
                        </button>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default withRouter(Component);
