import React, { Component } from "react";
import styled from "styled-components";

let Container = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: none;
    cursor: pointer;

    > .tick {
        width: 100%;
        height: 100%;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        > svg {
            width: 100%;
            height: 100%;
        }
    }

    &.is-selected {
        border: 1px solid rgba(97, 184, 146, 1);
        color: rgba(97, 184, 146, 1);

        > .tick {
            opacity: 1;
        }
    }

    &.is-disabled {
        background: rgba(0, 0, 0, 0.1);
        pointer-events: none;

        > .tick {
            box-shadow: 0;
            border: 0;
        }
    }

    &.is-disabled.is-selected {
        background: rgba(97, 184, 146, 0.2);
        border-color: rgba(97, 184, 146, 0.5);

        > .tick {
            opacity: 0.3;
        }
    }
`;

class Checkbox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value || false,
            disabled:
                (this.props.disabled && this.props.disabled !== "false") ||
                false,
        };
        if (typeof this.props.onChange === "function") {
            this.onChange = this.props.onChange;
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        var newState = {};
        var updateState = false;

        if (nextProps.value !== undefined) {
            newState.value = nextProps.value || false;
            updateState = true;
        }
        if (nextProps.disabled !== undefined) {
            newState.disabled = nextProps.disabled || false;
            updateState = true;
        }
        if (updateState) {
            return {
                newState,
            };
        } else {
            return null;
        }
    }

    onChange() {}

    set(value) {
        this.setState({
            value: value,
        });
    }

    empty() {
        this.setState({
            value: false,
        });
    }

    value() {
        return this.state.value;
    }

    validate() {
        return true;
    }

    disable() {
        this.setState({
            disabled: true,
        });
    }

    enable() {
        this.setState({
            disabled: false,
        });
    }

    onClick() {
        this.setState(
            {
                value: !this.state.value,
            },
            () => {
                this.onChange(this.state.value);
            },
        );
    }

    render() {
        let className =
            (this.state.value ? " is-selected" : "") +
            (this.state.disabled ? " is-disabled" : "");
        return (
            <Container
                tabIndex={this.props.tabindex || "0"}
                className={className}
                onClick={() => {
                    this.onClick();
                }}
                onKeyPress={(ev) => {
                    if (ev.key === " ") {
                        this.onClick();
                    }
                }}
            >
                <div className="tick">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M0 0v24h24v-24h-24zm10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"
                        />
                    </svg>
                </div>
            </Container>
        );
    }
}

export default Checkbox;
