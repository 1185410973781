import React from "react";

/**
 * This component will do reauth for Fortnox integration
 * @returns {JSX.Element}
 * @constructor
 */
const FortnoxReAuth = ({ className }) => {
    const handleReAuth = (e) => {
        e.preventDefault();
        const windowRef = window.open(
            `https://apps.fortnox.se/oauth-v1/auth?client_id=${process.env.REACT_APP_FORTNOX_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FORTNOX_OAUTH_REDIRECT_URL}&scope=article%20order%20customer&state=random%20string&access_type=offline&response_type=code`,
            "Connect to Fortnox",
            "width=500,height=500",
        );

        const timer = setInterval(() => {
            if (windowRef.closed) {
                clearInterval(timer);
                window.location.reload();
            }
        }, 1000);
    };

    return (
        <button className={className} onClick={handleReAuth}>
            Uppdatera nu
        </button>
    );
};

export default FortnoxReAuth;
