import Input from "components/OldInput";
import InputMultiToggle from "components/Input/MultiToggle";
import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useEffect, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import CustomInput from "components/CustomInput";

let Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 100;
    transition: all 0.3s ease;
    opacity: 0;

    &.is-visible {
        background: rgba(0, 0, 0, 0.2);
        pointer-events: all;
        opacity: 1;
    }

    > .container {
        background: #fff;
        min-width: 400px;
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        display: flex;
        max-width: 650px;
        flex-direction: column;

        > .title {
            font-size: 1.4rem;
            padding-bottom: 1rem;
            padding: 1rem;
        }

        > .package {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 1rem;

            > .toggle {
                padding: 8px;
                cursor: pointer;
                display: flex;
                font-size: 8px;
                align-items: center;
                font-weight: bold;
                gap: 4px;
                border-radius: 500px;
                margin-left: auto;

                &:hover {
                    background: #fafafa;
                    color: #333;
                }

                > svg {
                    width: 12px;
                    height: 12px;
                }
            }

            > .dimension-row {
                display: flex;
                justify-content: space-between;
                gap: 1rem;

                > .dimension-pair {
                    display: flex;
                    flex-direction: column;
                    gap: 0.2rem;
                    flex: 1;

                    > div {
                        width: 100%;
                    }
                }
            }
            > .stackable-toggle-row {
                display: flex;
                justify-content: space-between;
                align-items: center;

                > .toggle {
                    display: flex; // Make it a flex container
                    align-items: center; // Vertically align the items
                    justify-content: center; // Horizontally align the items
                    cursor: pointer;

                    > svg {
                        width: 12px;
                        height: 12px;
                    }

                    > span {
                        font-weight: bold;
                        font-size: 8px; // Adjust to make "dim" and "vol" smaller
                    }
                }
            }
        }

        > .buttons {
            padding: 1rem;
            display: flex;
            justify-content: flex-end;
        }
    }
`;

const Component = ({ visible, existingGroups, save, close }) => {
    const context = useContext(Context);
    const [type, setType] = useState("package");
    const [isVisible, setIsVisible] = useState(false);
    const [name, setName] = useState();
    const [length, setLength] = useState();
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();
    const [weight, setWeight] = useState();
    const [volume, setVolume] = useState();
    const [packageGroup, setPackageGroup] = useState();
    const [goodsDescription, setGoodsDescription] = useState();
    const [stackable, setStackable] = useState(true);
    const nameInputRef = useRef();
    const lengthInputRef = useRef();
    const widthInputRef = useRef();
    const heightInputRef = useRef();
    const volumeInputRef = useRef();
    const weightInputRef = useRef();
    const typeInputRef = useRef();
    const goodsDecsriptionInputRef = useRef();
    const stackableInputRef = useRef();
    const packageTypes = [
        {
            title: "Paket",
            value: "package",
        },
        {
            title: "Pall",
            value: "pallet",
        },
    ];

    useEffect(() => {
        if (visible) {
            reset();
        }
        setIsVisible(visible);
    }, [visible]);

    function onSave() {
        let valid = [
            nameInputRef.current.validate(),
            weightInputRef.current.validate(),
            goodsDecsriptionInputRef.current.validate(),
        ].find((v) => {
            return v === false;
        });
        if (
            (lengthInputRef.current,
            widthInputRef.current,
            heightInputRef.current)
        ) {
            valid =
                valid &&
                [
                    lengthInputRef.current.validate(),
                    widthInputRef.current.validate(),
                    heightInputRef.current.validate(),
                ].find((v) => {
                    return v === false;
                });
        } else {
            valid =
                valid &&
                [volumeInputRef.current.validate()].find((v) => {
                    return v === false;
                });
        }
        if (valid !== false) {
            save({
                name,
                type,
                length,
                width,
                height,
                volume,
                weight,
                packageGroup,
                goodsDescription,
                stackable,
            });
            reset();
        }
    }

    function reset() {
        nameInputRef.current.empty();
        typeInputRef.current.set("package");
        lengthInputRef.current?.empty();
        widthInputRef.current?.empty();
        heightInputRef.current?.empty();
        volumeInputRef.current?.empty();
        weightInputRef.current.empty();
        setPackageGroup("");
        setGoodsDescription("");
        goodsDecsriptionInputRef.current.empty();
        stackableInputRef.current.set(true);
    }

    async function changePackageSizeDefinition() {
        await Api.updateUserConfig({
            cancelToken: null,
            userId: context.user.id,
            config: {
                packageSizeDefinition:
                    context.user.config.packageSizeDefinition === "dimensions"
                        ? "volume"
                        : "dimensions",
            },
        });
        context.setUser(await Api.getCurrentUser());
    }

    function showVolume() {
        return (
            context.user.customer.settings.togglePackageSizeDefinition &&
            context.user.config.packageSizeDefinition === "volume"
        );
    }

    return (
        <Container className={isVisible ? "is-visible" : ""}>
            <div className="container">
                <div className="title">Ny godstyp</div>
                <div
                    className={`package ${
                        context.user.customer.settings
                            .togglePackageSizeDefinition
                            ? "show-toggle"
                            : ""
                    } ${showVolume() ? "is-volume" : ""}`}
                >
                    <label htmlFor="name">Namn</label>
                    <Input
                        id="name"
                        ref={nameInputRef}
                        placeholder=""
                        required={true}
                        onChange={setName}
                        value={name}
                        type="text"
                        maxLength={45}
                    />

                    <label htmlFor="type">Typ</label>
                    <Input
                        id="type"
                        ref={typeInputRef}
                        type="dropdown"
                        onChange={setType}
                        value={type}
                        options={packageTypes}
                    />

                    {showVolume() ? (
                        <div className="dimension-row">
                            <div className="dimension-pair">
                                <label htmlFor="volume">Volym</label>
                                <Input
                                    id="volume"
                                    ref={volumeInputRef}
                                    placeholder="0"
                                    onChange={setVolume}
                                    value={volume}
                                    type="text"
                                    unit="m³"
                                />
                            </div>
                            <div className="dimension-pair">
                                <label htmlFor="weight">Vikt</label>
                                <Input
                                    id="weight"
                                    ref={weightInputRef}
                                    placeholder="0"
                                    required={true}
                                    onChange={setWeight}
                                    value={weight}
                                    type="text"
                                    unit="kg"
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="dimension-row">
                                <div className="dimension-pair">
                                    <label htmlFor="length">Längd</label>
                                    <Input
                                        id="length"
                                        ref={lengthInputRef}
                                        required={true}
                                        placeholder="0"
                                        onChange={setLength}
                                        value={length}
                                        type="text"
                                        unit="cm"
                                    />
                                </div>
                                <div className="dimension-pair">
                                    <label htmlFor="width">Bredd</label>
                                    <Input
                                        id="width"
                                        ref={widthInputRef}
                                        required={true}
                                        placeholder="0"
                                        onChange={setWidth}
                                        value={width}
                                        type="text"
                                        unit="cm"
                                    />
                                </div>
                            </div>

                            <div className="dimension-row">
                                <div className="dimension-pair">
                                    <label htmlFor="height">Höjd</label>
                                    <Input
                                        id="height"
                                        ref={heightInputRef}
                                        required={true}
                                        placeholder="0"
                                        onChange={setHeight}
                                        value={height}
                                        type="text"
                                        unit="cm"
                                    />
                                </div>
                                <div className="dimension-pair">
                                    <label htmlFor="weight">Vikt</label>
                                    <Input
                                        id="weight"
                                        ref={weightInputRef}
                                        placeholder="0"
                                        required={true}
                                        onChange={setWeight}
                                        value={weight}
                                        type="text"
                                        unit="kg"
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    <label htmlFor="packageGroup">Grupp</label>
                    <CustomInput
                        id="packageGroup"
                        placeholder="Skapa ny grupp..."
                        existingGroups={existingGroups}
                        value={packageGroup}
                        onChange={setPackageGroup}
                        maxLength={45}
                    />

                    <label htmlFor="goodsDescription">Godsbeskrivning</label>
                    <Input
                        id="goodsDescription"
                        ref={goodsDecsriptionInputRef}
                        placeholder="Beskrivning"
                        onChange={setGoodsDescription}
                        value={goodsDescription}
                        type="text"
                        maxLength={30}
                        style={{ resize: "none" }}
                    />

                    <div className="stackable-toggle-row">
                        <div>
                            <label htmlFor="stackable" style={{ flex: 1 }}>
                                Stapelbar
                            </label>
                            <Input
                                id="stackable"
                                ref={stackableInputRef}
                                onChange={setStackable}
                                value={stackable}
                                type={InputMultiToggle}
                                options={[
                                    {
                                        title: "Ja",
                                        value: true,
                                    },
                                    {
                                        title: "Nej",
                                        value: false,
                                    },
                                ]}
                            />
                        </div>

                        {context.user.customer.settings
                            .togglePackageSizeDefinition && (
                            <div
                                className="toggle"
                                onClick={changePackageSizeDefinition}
                            >
                                <span>dim&nbsp;</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="0.26in"
                                    height="0.26in"
                                    viewBox="0 0 78 78"
                                >
                                    <path
                                        id="Selection"
                                        fill="currentColor"
                                        d="M 58.00,1.00 C 61.12,-3.50 75.31,13.13 75.26,16.91 75.23,19.07 72.88,21.53 71.53,23.09 67.50,27.76 60.88,36.69 58.00,33.00 56.06,30.06 61.37,24.65 66.00,20.00 66.00,20.00 23.00,20.00 23.00,20.00 17.52,20.03 13.29,20.34 9.65,25.10 6.73,28.93 6.93,35.55 3.21,34.27 0.72,33.41 1.28,29.92 1.77,28.00 3.26,22.10 7.42,17.76 13.00,15.48 18.16,13.37 26.30,14.00 32.00,14.00 32.00,14.00 66.00,14.00 66.00,14.00 61.57,9.47 55.31,4.94 58.00,1.00 Z M 11.00,58.00 C 11.00,58.00 53.00,58.00 53.00,58.00 58.94,57.99 63.16,57.95 67.20,52.82 70.41,48.73 69.87,42.48 73.74,43.74 76.55,44.66 75.41,49.00 74.78,51.00 72.93,56.79 68.72,60.90 63.00,62.89 58.36,64.50 50.13,64.00 45.00,64.00 45.00,64.00 11.00,64.00 11.00,64.00 11.00,64.00 19.50,73.19 17.41,76.60 14.09,80.27 1.63,64.07 1.63,61.00 1.63,57.47 15.31,42.81 17.79,45.73 20.56,48.81 11.00,58.00 11.00,58.00 Z"
                                    />
                                </svg>
                                <span>&nbsp;vol</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="buttons">
                    <div className="c-button" onClick={close}>
                        Avbryt
                    </div>
                    <div className="c-button c-button--raised" onClick={onSave}>
                        Spara
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default Component;
