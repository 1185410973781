import Input from "components/OldInput";
import InputDropdown from "components/Input/Dropdown";
import Loader from "components/Loader/Loader";
import Context from "context/Global";
import { getProductId } from "helpers/Services";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import InputGoodsDescription from "../../Input/GoodsDescription";
import InputPackages from "../../Input/Packages";

let Container = styled.div`
    padding: 1rem;
    display: inline-flex;
    flex-direction: column;

    > .buttons {
        padding: 1rem 0;
    }
`;

function Component({ ...props }) {
    const context = useContext(Context);
    const [template, setTemplate] = useState();
    const [availableProducts, setAvailableProducts] = useState(null);
    const [integration, setIntegration] = useState(props.integration);
    const [testingIntegration, setTestingIntegration] = useState(false);

    useEffect(() => {
        // Get all products for this customer
        init();
    }, []);

    async function init() {
        if (props.templateId) {
            setTemplate(
                integration.data.templates?.find((template) => {
                    return template.productCode === props.templateId;
                }),
            );
        }
        setAvailableProducts(await getPossibleProducts());
    }

    async function getPossibleProducts() {
        const enabledTransportProducts = (
            await Api.getCustomerProducts({
                customerId: context.user.customer.id,
            })
        ).filter((product) => product.enabled);

        let fortnoxProducts = [];
        enabledTransportProducts.forEach((product) => {
            fortnoxProducts.push(product);
            if (product.name === "DHL Pall") {
                fortnoxProducts.push({
                    name: "DHL Halvpall",
                    productId: "DHL_HALV_PALL",
                });
            }
        });

        fortnoxProducts = fortnoxProducts
            .filter((product) => {
                return (integration.data.templates || []).reduce(
                    (response, template) => {
                        if (getProductId(product.name) === props.templateId) {
                            return true;
                        }
                        return (
                            response &&
                            getProductId(product.name) !== template.productCode
                        );
                    },
                    true,
                );
            })
            .map((product) => {
                return {
                    title: product.name,
                    value: product.productId || getProductId(product.name),
                };
            });

        return fortnoxProducts;
    }

    async function saveTemplate() {
        if (!integration.data.templates) {
            integration.data.templates = [];
        }

        await Api.updateIntegration({
            integrationId: integration.id,
            data: {
                templates: integration.data.templates
                    .filter((t) => {
                        return t.productCode !== template.productCode;
                    })
                    .concat(template)
                    .sort((t1, t2) => {
                        if (t1.productCode < t2.productCode) {
                            return -1;
                        } else if (t1.productCode > t2.productCode) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }),
            },
        });
        context.setUser(await Api.getCurrentUser());
        props.history.push(`/addons/${integration.id}`);
    }

    if (!availableProducts) {
        return <Loader />;
    }

    return (
        <Container>
            <div>Skapa mall för Fortnox</div>
            <Input
                type="list"
                onChange={setTemplate}
                value={template}
                object={{
                    productCode: {
                        title: "Produkt",
                        type: InputDropdown,
                        options: availableProducts,
                    },
                    description: {
                        title: "Godsbeskrivning",
                        type: InputGoodsDescription,
                    },
                    packages: {
                        title: "Kollin",
                        type: InputPackages,
                        template: template?.productCode,
                        multiRow:
                            template?.productCode &&
                            ["DHL_SERVICE_POINT", "DHL_SERVICE_POINT2"].indexOf(
                                template?.productCode,
                            ) < 0,
                    },
                }}
            />
            <div className="buttons">
                <button
                    className="c-button c-button--raised"
                    onClick={saveTemplate}
                >
                    Spara
                </button>
            </div>
        </Container>
    );
}

export default Component;
