import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import Input from "components/OldInput";
import axios from "axios";
import Api from "services/Api/Api";
import toast from "react-hot-toast";

let Container = styled.div`
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > .loader {
        width: 200px;
        height: 200px;
    }

    > .newBrand {
        display: flex;
        gap: 1rem;
        align-items: center;
        padding-top: 4rem;

        > img {
            height: 32px;
        }
    }
    > .newBrand-text {
        padding-top: 1rem;
        display: flex;
        text-align: center;
    }
`;

let Form = styled.form`
    display: flex;
    flex-direction: column;

    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    padding: 32px;
    border-radius: 8px;
    box-sizing: border-box;
    width: 100%;
    max-width: 320px;

    > .inputFields {
        margin-bottom: 16px;
    }

    > .logo {
        width: 100%;
        margin-bottom: 20px;

        > .c-logo {
            width: 100%;
            height: 50px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }

    > .forgot {
        text-align: center;
        margin-top: 20px;
        gap: 1rem;
        display: flex;
        justify-content: center;

        > a {
            text-decoration: none;
            font-size: 10px;
            text-align: center;
            font-weight: bold;
            color: #777;
        }
        > a:hover {
            text-decoration: underline;
        }
    }

    > .disclaimer {
        text-align: center;
        margin-top: 20px;
        font-size: 0.8rem;
        color: rgba(0, 0, 0, 0.5);
    }
`;

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (!disabled) {
            return;
        }

        const timer = setTimeout(() => {
            setDisabled(false);
        }, 5000);
        return () => clearTimeout(timer);
    }, [disabled]);

    async function handleSubmit(e) {
        e.preventDefault();
        setDisabled(true);

        try {
            const res = await Api.post({
                url: "/api/password/forgot",
                data: {
                    email,
                },
            });

            if (res.status === 200) {
                toast.success("Återställningsmail skickat");
            }
        } catch (e) {
            toast.error(
                "Något gick fel, försök igen senare. Annars kontakta våran kundtjänst.",
            );
            console.log(
                "Error occured when sending forgot password reset email:",
            );
        }
    }

    return (
        <Container>
            <Form>
                <Input
                    className="inputFields"
                    type="text"
                    onChange={setEmail}
                    value={email}
                    placeholder="E-post"
                />
                <input
                    type="submit"
                    className="c-button c-button--raised"
                    onClick={handleSubmit}
                    value="Återställ lösenord"
                    disabled={disabled}
                />
                <div className="disclaimer">
                    Vi skickar ett mail med instruktioner för att återställa
                    ditt lösenord.
                </div>
            </Form>
        </Container>
    );
};

export default ForgotPassword;
