import React from "react";
import useBatchStore from "views/Batch/store";

const Select = ({ order, disabled }) => {
    const addOrderToSelectedOrders = useBatchStore(
        (state) => state.addOrderToSelectedOrders,
    );
    const removeOrderFromSelectedOrders = useBatchStore(
        (state) => state.removeOrderFromSelectedOrders,
    );
    const selectedOrders = useBatchStore((state) => state.selectedOrders);

    const isSelected = selectedOrders.some(
        (selectedOrder) => selectedOrder.id === order.id,
    );

    return (
        <input
            checked={isSelected}
            onChange={() => {
                if (!isSelected) {
                    addOrderToSelectedOrders(order);
                } else {
                    removeOrderFromSelectedOrders(order);
                }
            }}
            type="checkbox"
            className="h-5 w-5"
            disabled={disabled}
            style={{ accentColor: "rgba(76, 80, 175, 1)" }}
        />
    );
};

export default Select;
