import React, { Component } from "react";
import styled from "styled-components/macro";
import moment from "moment";
import "moment/locale/sv";
import { Link } from "react-router-dom";

import Button from "components/Button/Button";
import Input from "components/OldInput";
import Loader from "components/Loader/Loader";
import Api from "services/Api/Api";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
`;

let Form = styled.form`
    padding: 16px;

    > .button {
        margin-top: 32px;
    }

    > .errorMessage {
        font-size: 12px;
        color: rgba(200, 50, 50, 1);
        margin-top: 16px;
    }
`;

let Title = styled.form`
    padding: 16px;
    padding-bottom: 4px;
    font-size: 26px;
`;

class AddUser extends Component {
    userInputRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            submitting: false,
            customer: null,
            errorMessage: null,
        };

        this.submit = this.submit.bind(this);
    }

    componentWillMount() {
        // Api.getCustomer({
        // 	customerId: this.props.match.params.customerId
        // }).then((response) => {
        // 	this.setState({
        // 		customer: response.data,
        // 		loading: false
        // 	});
        // }).catch((response) => {
        // 	console.error('Failed to load customer');
        // 	this.setState({
        // 		customer: null,
        // 		loading: false
        // 	});
        // });
    }

    submit(ev) {
        ev.preventDefault();
        if (this.userInputRef.current.validate()) {
            let userData = this.userInputRef.current.value();
            this.setState(
                {
                    submitting: true,
                },
                () => {
                    Api.createUser({
                        customerId: this.props.match.params.customerId,
                        user: userData,
                    })
                        .then((response) => {
                            this.props.history.push(
                                `/admin/customers/${this.props.match.params.customerId}`,
                            );
                            this.setState({
                                submitting: false,
                            });
                        })
                        .catch((request) => {
                            switch (request.response.data.code) {
                                case "DUPLICATE": {
                                    this.setState({
                                        submitting: false,
                                        errorMessage:
                                            "E-postadressen är redan registrerad.",
                                    });
                                    break;
                                }
                                default: {
                                    this.setState({
                                        submitting: false,
                                        errorMessage:
                                            "Hoppsan! Det gick inte att lägga till en användare just nu, försök gärna igen lite senare!",
                                    });
                                }
                            }
                        });
                },
            );
        }
        return false;
    }

    render() {
        if (this.state.loading) {
            return (
                <Container>
                    <div className="loader">
                        <Loader />
                    </div>
                </Container>
            );
        } else {
            return (
                <Container>
                    <Title>Lägg till användare</Title>
                    <Form>
                        <Input
                            ref={this.userInputRef}
                            type="list"
                            object={{
                                name: {
                                    title: "Namn",
                                    type: "text",
                                    required: true,
                                },
                                email: {
                                    title: "E-post",
                                    type: "text",
                                    required: true,
                                },
                                phoneNumber: {
                                    title: "Telefonnummer",
                                    type: "text",
                                    required: true,
                                },
                                password: {
                                    title: "Lösenord",
                                    type: "password",
                                    placeholder: "",
                                    required: true,
                                    autoComplete: false,
                                },
                            }}
                            value={{
                                priceMultiplier: 120,
                            }}
                        />
                        <button
                            className={
                                "button c-button c-button--raised" +
                                (this.state.submitting ? " is-disabled" : "")
                            }
                            onClick={this.submit}
                        >
                            Skapa
                        </button>
                        {this.state.errorMessage && (
                            <div className="errorMessage">
                                {this.state.errorMessage}
                            </div>
                        )}
                    </Form>
                </Container>
            );
        }
    }
}

export default AddUser;
