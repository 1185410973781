import Context from "context/Global";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components/macro";

const bounceAnimation = keyframes`
	0% {
		background-color: #ff8347;
	}
	50% {
		background-color: #fff;
	}
	100% {
		background-color: #ff8347;
	}
`;

const Container = styled(Link)`
    position: fixed;
    top: 4px;
    left: 40vw;
    width: 20vw;
    height: 52px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: #fff;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    background: #ff8347;
    font-size: 2rem;
    animation: ${bounceAnimation} 5s linear infinite;
    color: #000;
    text-decoration: none;
    z-index: 100;
`;

function Component(props) {
    const context = useContext(Context);

    if (
        !context.supportNotificationCount ||
        window.location.pathname.indexOf("/admin/support") === 0 ||
        window.location.pathname.indexOf("/admin/tickets") === 0
    ) {
        return null;
    }

    return (
        <Container to="/admin/support">
            <div className="content">
                {context.supportNotificationCount === 1
                    ? `${context.supportNotificationCount} aktivt ärende`
                    : `${context.supportNotificationCount} aktiva ärenden`}
            </div>
        </Container>
    );
}

export default Component;
