import React, { Component } from "react";
import styled from "styled-components";

let Container = styled.div`
    width: 32px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.3s ease;
    background: rgba(0, 0, 0, 0.2);

    > .groove {
        width: 18px;
        height: 18px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background: #fff;
        transition: all 0.3s ease;
        cursor: pointer;
    }

    &.is-selected {
        background: rgba(97, 184, 146, 1);
        border-color: rgba(97, 184, 146, 1);

        > .groove {
            margin-left: 12px;
        }
    }

    &.is-disabled {
        background: rgba(0, 0, 0, 0.1);
        pointer-events: none;

        > .groove {
            box-shadow: 0;
            border: 0;
        }
    }

    &.is-disabled.is-selected {
        background: rgba(97, 184, 146, 0.5);
        border-color: rgba(97, 184, 146, 0.5);

        > .groove {
            margin-left: 14px;
        }
    }
`;

class Toggle extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: !!props.value,
            disabled:
                (this.props.disabled && this.props.disabled !== "false") ||
                false,
        };
        if (typeof this.props.onChange === "function") {
            this.onChange = this.props.onChange;
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        var newState = {};
        var updateState = false;

        if (nextProps.value !== undefined) {
            newState.value = !!nextProps.value;
            updateState = true;
        }
        if (nextProps.disabled !== undefined) {
            newState.disabled = !!nextProps.disabled;
            updateState = true;
        }
        if (updateState) {
            return {
                newState,
            };
        } else {
            return null;
        }
    }

    onChange() {}

    set(value) {
        this.setState({
            value: value,
        });
    }

    empty() {
        this.setState({
            value: false,
        });
    }

    value() {
        return this.state.value;
    }

    validate() {
        return true;
    }

    disable() {
        this.setState({
            disabled: true,
        });
    }

    enable() {
        this.setState({
            disabled: false,
        });
    }

    onClick() {
        this.setState(
            {
                value: !this.state.value,
            },
            () => {
                this.onChange(this.state.value);
            },
        );
    }

    render() {
        let className =
            (this.state.value ? " is-selected" : "") +
            (this.state.disabled ? " is-disabled" : "");
        return (
            <Container
                tabIndex={this.props.tabindex || "0"}
                className={className}
                onClick={() => {
                    this.onClick();
                }}
                onKeyPress={(ev) => {
                    if (ev.key === " ") {
                        this.onClick();
                    }
                }}
            >
                <div className="groove" />
            </Container>
        );
    }
}

export default Toggle;
