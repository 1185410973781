import LoadingIndicator from "components/LoadingIndicator";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import XLSX from "xlsx";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    position: relative;

    .dropzone {
        position: absolute;
        width: 100%;
        height: 100%;
        outline: none;

        > .dropIndicator {
            position: fixed;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(255, 255, 255, 0.8);
            z-index: 100;
            top: 0;
            left: 0;
        }

        &.is-visible {
            opacity: 1;
        }
    }

    > table {
        width: 100%;
        margin-bottom: 16px;
        flex-direction: column;

        > thead {
            > tr {
                > th {
                    background: #fafafa;
                    padding: 1em 0;
                    border-bottom: 1px solid #ddd;
                    border-top: 1px solid #ddd;
                    color: #333;
                    font-weight: normal;

                    &:first-child {
                        padding-left: 1em;
                    }
                    &:last-child {
                        padding-right: 1em;
                    }
                }
            }
        }

        > tbody {
            > tr {
                > td {
                    background: #fff;
                    padding: 0.5em 0;
                    cursor: pointer;

                    &:first-child {
                        padding-left: 1em;
                    }
                    &:last-child {
                        padding-right: 1em;
                    }

                    &.service {
                        > img {
                            width: 32px;
                            object-size: contain;
                        }
                    }
                }

                &:hover > td {
                    background: rgba(250, 250, 250, 1);
                }
            }
        }
    }
`;

let Title = styled.div`
    font-size: 24px;
    color: rgba(0, 0, 0, 0.6);
    padding: 1rem;
`;

function Component({ customerId, ...props }) {
    const [customer, setCustomer] = useState(null);
    const [contacts, setContacts] = useState(null);
    const [dndMessage, setDndMessage] = useState(null);

    function mapper1(rowObject) {
        const addressInfo = decodeString(rowObject.Postadress);
        return {
            addressLine1: decodeString(rowObject.Gatuadress),
            postalCode: /^\d{3} \d{2}/.test(addressInfo)
                ? addressInfo.substr(0, 6).trim()
                : /^\d{5}/.test(addressInfo)
                ? addressInfo.substr(0, 5).trim()
                : "",
            postalTown: /^\d{3} \d{2}/.test(addressInfo)
                ? addressInfo.substr(6).trim()
                : /^\d{5}/.test(addressInfo)
                ? addressInfo.substr(0, 5).trim()
                : addressInfo.trim(),
            clientNumber: decodeString(rowObject["Företagskod"]),
            contact: {
                name: decodeString(rowObject["Referens"]),
                company: decodeString(rowObject["Företag"]),
                email: decodeString(rowObject["E-postadress"]),
            },
        };
    }

    function mapper2(rowObject) {
        const addressInfo = decodeString(rowObject["Leveransadress 4"]);
        return {
            clientNumber: decodeString(rowObject.Kundkod),
            addressLine1: decodeString(rowObject["Leveransadress 2"]),
            addressLine2: decodeString(rowObject["Leveransadress 3"]),
            postalCode: /^\d{3} \d{2}/.test(addressInfo)
                ? addressInfo.substr(0, 6).trim()
                : /^\d{5}/.test(addressInfo)
                ? addressInfo.substr(0, 5).trim()
                : "",
            postalTown: /^\d{3} \d{2}/.test(addressInfo)
                ? addressInfo.substr(6).trim()
                : /^\d{5}/.test(addressInfo)
                ? addressInfo.substr(0, 5).trim()
                : addressInfo.trim(),
            contact: {
                company: decodeString(rowObject["Företag"]),
            },
        };
    }

    function tntMapper(rowObject) {
        return {
            addressLine1: decodeString(rowObject["addressLine1"]),
            addressLine2: [
                decodeString(rowObject["addressLine2"]),
                decodeString(rowObject["addressLine3"]),
            ]
                .filter((v) => v)
                .join(", "),
            countryCode: decodeString(rowObject["countryCode"]),
            postalCode: decodeString(rowObject["postcode"]),
            postalTown: decodeString(rowObject["city"]),
            contact: {
                company: decodeString(rowObject["company"]),
                name: decodeString(rowObject["contactName"]),
                private: rowObject["private"] === "no" ? false : true,
                vatNumber:
                    decodeString(rowObject["VATNumber"]) &&
                    decodeString(rowObject["VATNumber"]) !== "0"
                        ? decodeString(rowObject["VATNumber"])
                        : decodeString(rowObject["internationalAccountNumber"]),
                email:
                    decodeString(rowObject["email"]) &&
                    decodeString(rowObject["email"]) !== ";"
                        ? decodeString(rowObject["email"])
                        : "",
                phoneNumber: decodeString(rowObject["phone"]),
            },
        };
    }

    function unifaunMapper(rowObject) {
        return {
            clientNumber: decodeString(rowObject["id"]),
            addressLine1: decodeString(rowObject["address1"]),
            addressLine2: [
                decodeString(rowObject["address2"]),
                decodeString(rowObject["address3"]),
            ]
                .filter((v) => v)
                .join(", "),
            countryCode: decodeString(rowObject["countrycode"]),
            postalCode: decodeString(rowObject["postcode"]),
            postalTown: decodeString(rowObject["city"]),
            contact: {
                company: decodeString(rowObject["name"]),
                name: decodeString(rowObject["contactPerson"]),
                private: false,
                vatNumber: decodeString(rowObject["vatNo"]),
                email: decodeString(rowObject["email"]),
                phoneNumber:
                    decodeString(rowObject["mobile"]) ||
                    decodeString(rowObject["phone"]),
            },
        };
    }

    function upsMapper(rowObject) {
        return {
            clientNumber: decodeString(rowObject["Account Number"]),
            addressLine1: decodeString(rowObject["Street Address Line 1"]),
            addressLine2: [
                decodeString(rowObject["Street Address Line 2"]),
                decodeString(rowObject["Street Address Line 3"]),
            ]
                .filter((v) => v)
                .join(", "),
            countryCode: decodeString(rowObject["Country"]),
            postalCode: decodeString(rowObject["Postal Cd"]),
            postalTown: decodeString(rowObject["City"]),
            stateCode: decodeString(rowObject["St/Prov"]),
            contact: {
                company: decodeString(rowObject["Company Name"]),
                name: decodeString(rowObject["Contact Name"]),
                private: false,
                email: decodeString(rowObject["Contact Email"]),
                phoneNumber: decodeString(rowObject["Contact Phone"]),
            },
        };
    }

    function nshiftMapper(rowObject) {
        return {
            clientNumber: decodeString(rowObject["[quickid]"]),
            addressLine1: decodeString(rowObject["[address1]"]),
            addressLine2: decodeString(rowObject["[address2]"]),
            countryCode: decodeString(rowObject["[country]"]),
            postalCode: decodeString(rowObject["[zipcode]"]),
            postalTown: decodeString(rowObject["[city]"]),
            stateCode: decodeString(rowObject["[state]"]),
            contact: {
                company: decodeString(rowObject["[name]"]),
                name: decodeString(rowObject["[contact]"]),
                private: !decodeString(rowObject["[contact]"]),
                email: decodeString(rowObject["[email]"]),
                phoneNumber:
                    decodeString(rowObject["[sms]"]) ||
                    decodeString(rowObject["[phone]"]),
            },
        };
    }

    function unknownMapper(rowObject) {
        return {
            clientNumber: decodeString(rowObject["No."]),
            addressLine1: decodeString(rowObject["Address"]),
            addressLine2: "",
            countryCode: decodeString(rowObject["Country/Region Code"]),
            postalCode: "",
            postalTown: decodeString(rowObject["City"]),
            stateCode: "",
            contact: {
                company: decodeString(rowObject["Name"]).substr(0, 64),
                name: decodeString(rowObject["Contact"]),
                private: !decodeString(rowObject["Contact"]),
                email: "",
                phoneNumber: decodeString(rowObject["Phone No."]),
            },
        };
    }

    function newMapper(rowObject) {
        const privateContact = !!decodeString(
            rowObject["First and last name (Private only)"],
        );
        return {
            clientNumber: decodeString(rowObject["Customer Number"]),
            addressLine1: decodeString(rowObject["Address"]),
            addressLine2: decodeString(rowObject["Address 2"]),
            addressLine3: decodeString(rowObject["CO"]),
            countryCode: decodeString(rowObject["Country Code"]),
            postalCode: decodeString(rowObject["Postcode"]),
            postalTown: decodeString(rowObject["Area"]),
            contact: {
                company: !privateContact
                    ? decodeString(rowObject["Company Name"])
                    : "",
                name: privateContact
                    ? decodeString(
                          rowObject["First and last name (Private only)"],
                      )
                    : decodeString(rowObject["Att (Company only)"]),
                private: privateContact,
                email: decodeString(rowObject["E-mail"]),
                phoneNumber: decodeString(
                    rowObject["Phone (remember +45/ country code)"],
                ),
            },
        };
    }

    function fedexMapper(rowObject) {
        return {
            addressLine1: decodeString(rowObject["AddressOne"]),
            addressLine2: decodeString(rowObject["AddressTwo"]),
            countryCode: decodeString(rowObject["CountryCode"]),
            postalCode: decodeString(rowObject["Zip"]),
            postalTown: decodeString(rowObject["City"]),
            stateCode: decodeString(rowObject["State"]),
            contact: {
                company: decodeString(rowObject["Company"]),
                name: decodeString(rowObject["FullName"]),
                private: decodeString(rowObject["ResidentialFlag"]) === "R",
                email:
                    decodeString(rowObject["EmailAddress"]) ||
                    decodeString(
                        rowObject["ShipmentNotificationRecipientEmail"],
                    ),
                phoneNumber: decodeString(rowObject["PhoneNumber"]),
            },
        };
    }

    function sendifyMapper(rowObject) {
        return {
            addressLine1: decodeString(rowObject["address_line_1"]),
            addressLine2: decodeString(rowObject["address_line_2"]),
            addressLine3: decodeString(rowObject["address_line_3"]),
            countryCode: decodeString(rowObject["country_code"]),
            postalCode: decodeString(rowObject["zip_code"]),
            postalTown: decodeString(rowObject["city"]),
            stateCode: decodeString(rowObject["state"]),
            contact: {
                company: decodeString(rowObject["name"]),
                private: false,
                email: "",
                phoneNumber: "",
            },
        };
    }

    function dhlFreightMapper(rowObject) {
        return {
            clientNumber: decodeString(
                rowObject["Förvald Avsändare Kundnummer"],
            ),
            addressLine1: decodeString(rowObject["Adress 1"]),
            addressLine2: decodeString(rowObject["Adress 2"]),
            addressLine3: decodeString(rowObject["Adress 3"]),
            countryCode: decodeString(rowObject["Landskod"]),
            postalCode: decodeString(rowObject["Postnummer/postkod"]),
            postalTown: decodeString(rowObject["Stad"]),
            stateCode: decodeString(rowObject["Stat/provins Landskod"]),
            contact: {
                company: decodeString(rowObject["Företag"]),
                name: decodeString(rowObject["﻿Namn"] || rowObject["Namn"]),
                private:
                    decodeString(rowObject["Ytterligare part Roll"]) === "PR",
                vatNumber: decodeString(rowObject["Moms-ID"]),
                email: decodeString(rowObject["E-postadress 1"]),
                phoneNumber:
                    decodeString(rowObject["Telefonnummer Landskod"]) +
                    " " +
                    decodeString(rowObject["Telefonnummer"]),
            },
        };
    }

    // DHL multishipping probably.
    function dhlMapper(rowObject) {
        return {
            clientNumber: decodeString(rowObject["Kontakt-ID"]),
            addressLine1: decodeString(
                [rowObject["Gata*"], rowObject["Gatunummer"]]
                    .filter((v) => v)
                    .join(" "),
            ),
            countryCode: decodeString(rowObject["Landskod*"]),
            postalCode: decodeString(rowObject["postnummer*"]),
            postalTown: decodeString(rowObject["Stad"]),
            stateCode: decodeString(rowObject["Stat/provins Landskod"]),
            contact: {
                company: decodeString(rowObject["Företag"]),
                name: decodeString(rowObject["namn"] || rowObject["Referens"]),
                private: false,
                email: decodeString(rowObject["E-post"]),
                phoneNumber: decodeString(rowObject["Telefon"]),
            },
        };
    }

    function generalMapper(rowObject) {
        return {
            clientNumber: decodeString(rowObject["clientNumber"]),
            addressLine1: decodeString(rowObject["addressLine1"]),
            addressLine2: decodeString(rowObject["addressLine2"]),
            countryCode: decodeString(rowObject["countrycode"]),
            postalCode: decodeString(rowObject["postcode"]),
            postalTown: decodeString(rowObject["city"]),
            stateCode: decodeString(rowObject["state"]),
            contact: {
                company: decodeString(rowObject["company"]),
                name: decodeString(rowObject["name"]),
                private: decodeString(rowObject["private"]) === "yes",
                vatNumber: decodeString(rowObject["vatNumber"]),
                email: decodeString(rowObject["email"]),
                phoneNumber: decodeString(rowObject["phoneNumber"]),
            },
        };
    }

    function decodeString(str) {
        try {
            str = str.toString();
        } catch (error) {
            str = "";
        }
        try {
            str = decodeURIComponent(escape(str));
        } catch (error) {
            // Do nothing
        }

        return str.trim();
    }

    function getMapperFunction(sheet) {
        if (sheet && sheet.A1) {
            if (
                sheet.A1.v === "name" &&
                sheet.B1.v === "address_line_1" &&
                sheet.C1.v === "address_line_2"
            ) {
                return sendifyMapper;
            } else if (
                (sheet.A1.v === "﻿Namn" || sheet.A1.v === "Namn") &&
                sheet.B1.v === "Företag" &&
                sheet.D1.v === "E-postadress 1"
            ) {
                return dhlFreightMapper;
            } else if (
                sheet.A1.v === "Kontakt-ID" &&
                sheet.B1.v === "Företag" &&
                sheet.D1.v === "Gata*"
            ) {
                return dhlMapper;
            } else if (sheet.A1.v === "General") {
                return generalMapper;
            } else if (sheet.A1.v === "Företagskod") {
                return mapper1;
            } else if (sheet.A1.v === "Kundkod") {
                return mapper2;
            } else if (sheet.A1.v === "contactName") {
                return tntMapper;
            } else if (sheet.A1.v === "header") {
                return unifaunMapper;
            } else if (sheet.A1.v === "Nickname") {
                return fedexMapper;
            } else if (sheet.A1.v === "Company Name") {
                return upsMapper;
            } else if (sheet.A1.v === "[quickid]") {
                return nshiftMapper;
            } else if (sheet.A1.v === "No.") {
                return unknownMapper;
            } else if (sheet.A1.v === "First and last name (Private only)") {
                return newMapper;
            }
        }

        return () => {
            return null;
        };
    }

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            if ([".xls"].indexOf(file.name.substr(-4).toLowerCase())) {
                setDndMessage(null);
                const reader = new FileReader();

                reader.onabort = () =>
                    console.error("file reading was aborted");
                reader.onerror = () => console.error("file reading has failed");
                reader.onload = async () => {
                    var workbook = XLSX.read(reader.result, {
                        type: "binary",
                    });

                    const contacts = [].concat
                        .apply(
                            [],
                            workbook.SheetNames.map(function (sheetName) {
                                const mapper = getMapperFunction(
                                    workbook.Sheets[sheetName],
                                );
                                return XLSX.utils
                                    .sheet_to_row_object_array(
                                        workbook.Sheets[sheetName],
                                    )
                                    .map((rowObject) => {
                                        return mapper(rowObject);
                                    });
                            }),
                        )
                        .filter((c) => c);

                    if (!contacts.length) {
                        window.alert(
                            "Vi lyckades inte hitta några kontakter i filen. Formatet känns inte igen.",
                        );
                    } else if (
                        window.confirm(
                            `Importera ${contacts.length} kontakter?`,
                        )
                    ) {
                        await Api.importContacts({
                            customerId: customerId,
                            contacts: contacts,
                        });
                        setContacts(
                            await Api.getCustomerContacts({
                                customerId: customerId,
                            }),
                        );
                    }
                };
                reader.readAsBinaryString(file);
            } else {
                setDndMessage("Endast CSV-filer går att använda.");
            }
        });
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        noClick: true,
        onDrop,
    });

    useEffect(() => {
        loadCustomer();
    }, []);

    async function loadCustomer() {
        setCustomer(
            await Api.getCustomer({
                customerId: customerId,
            }),
        );
        setContacts(
            await Api.getCustomerContacts({
                customerId: customerId,
            }),
        );
    }

    return (
        <Container>
            <LoadingIndicator loading={!(contacts && customer)}>
                {customer && contacts && (
                    <>
                        <div
                            className={
                                "dropzone" + (isDragActive ? " is-visible" : "")
                            }
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} />
                            {isDragActive && (
                                <div className="dropIndicator">Importera</div>
                            )}
                        </div>
                        <Title>Adressbok för {customer.name}</Title>
                        <table>
                            <thead>
                                <tr>
                                    <th>Kund-id</th>
                                    <th>Företag</th>
                                    <th>Kontakt</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contacts.map((contact) => {
                                    return (
                                        <tr key={contact.id}>
                                            <td>{contact.clientNumber}</td>
                                            <td>{contact.contact.company}</td>
                                            <td>{contact.contact.name}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </>
                )}
            </LoadingIndicator>
        </Container>
    );
}

export default Component;
