import { useEffect, useState } from "react";

/*
 * A hook to manage notification settings for an order.
 * @param {Object} order - Order Object
 * @param {Array} additionalEvents - Array of events, e.g. ["onTender", "onDelivery", "onException", "onShipment"]
 *
 * @returns {Object} - notificationSettings, setNotificationSettings
 */
export const useNotificationSettings = (order, additionalEvents) => {
    const [notificationSettings, setNotificationSettings] = useState(null);

    useEffect(() => {
        if (notificationSettings) {
            localStorage.setItem(
                "notificationSettings",
                JSON.stringify(notificationSettings),
            );
        }
    }, [notificationSettings]);

    useEffect(() => {
        if (order) {
            const senderEmail = order.sender.contact.email;
            const receiverEmail = order.receiver.contact.email;

            const notificationSettings = JSON.parse(
                localStorage.getItem("notificationSettings"),
            );

            // Convert the events array to an object with the values based on onTender which handles the value of the UI checkbox
            const getEventObject = (value) =>
                ["onTender", ...additionalEvents].reduce(
                    (obj, next) => ({ ...obj, [next]: !!value }),
                    {},
                );

            const senderEventObject = getEventObject(
                senderEmail &&
                    (notificationSettings?.[0]?.onTender ?? senderEmail),
            );
            const receiverEventObject = getEventObject(
                receiverEmail &&
                    (notificationSettings?.[1]?.onTender ?? receiverEmail),
            );
            setNotificationSettings([
                {
                    name: order.sender.contact.name,
                    email: senderEmail,
                    ...senderEventObject,
                },
                {
                    name: order.receiver.contact.name,
                    email: receiverEmail,
                    ...receiverEventObject,
                },
            ]);
        }
    }, [order]);

    return { notificationSettings, setNotificationSettings };
};
