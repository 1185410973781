import React from "react";
import { ErrorIcon } from "react-hot-toast";
const ShipmentError = ({ errorMessage }) => {
    return (
        <div className="mt-10 items-center gap-3">
            <div className={"bg-red-200 rounded flex items-center gap-3 p-3"}>
                <ErrorIcon />
                {errorMessage ||
                    "Something went wrong, please try again later."}
            </div>
        </div>
    );
};

export default ShipmentError;
