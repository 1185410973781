import React from "react";
import { Calendar } from "react-calendar";
import "./Calendar.css";
import { swedishHolidays } from "utils/holidays";
import moment from "moment";

const CalendarComponent = ({ onChange, value }) => {
    return (
        <Calendar
            defaultView="month"
            selectRange={false}
            returnValue="end"
            value={value}
            minDate={new Date()}
            maxDate={moment().add(7, "days").toDate()}
            allowPartialRange={false}
            minDetail="month"
            defaultValue={new Date()}
            tileDisabled={({ date }) => {
                return (
                    swedishHolidays.includes(date.toISOString().slice(0, 10)) ||
                    date.getDay() === 0 ||
                    date.getDay() === 6
                );
            }}
            locale="sv-SE"
            onChange={(date) => {
                const dateObj = new Date(date.toString());

                const formatDate = dateObj.toISOString().slice(0, 10);

                onChange(formatDate);
            }}
        />
    );
};

export default CalendarComponent;
