import Input from "components/OldInput";
import Context from "context/Global";
import React, { useContext, useEffect, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0);
    z-index: 100;
    transition: all 0.3s ease;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 5%;
    box-sizing: border-box;

    pointer-events: none;

    > .content {
        padding: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        background: #fff;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        transform: scale3d(0, 0, 1);
        transition: all 0.3s ease;

        > .header {
            height: 50px;
            min-height: 50px;
            width: 100%;
            display: flex;

            > .close {
                margin-left: auto;
                padding: 12px;
                cursor: pointer;
                transition: all 0.3s ease;

                &:hover {
                    color: #000;
                }
            }
        }

        > .body {
            height: 100%;
            width: 100%;
            overflow: auto;

            > .search {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.5s ease;
            }

            > .result {
                width: 100%;

                > tbody > tr.contact {
                    cursor: pointer;

                    > td {
                        height: 40px;
                    }

                    &:hover > td {
                        background: rgba(0, 0, 0, 0.05);
                    }

                    .delete {
                        color: rgba(0, 0, 0, 0.5);
                        width: 32px;
                        height: 32px;
                        padding: 8px;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:hover {
                            color: rgba(200, 0, 0, 0.5);
                        }

                        > svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    &.is-visible {
        background: rgba(0, 0, 0, 0.5);
        pointer-events: all;

        > .content {
            transform: scale3d(1, 1, 1);
        }
    }
`;

function Component({ visible, onClose, onSelect }) {
    const context = useContext(Context);
    const [searching, setSearching] = useState(false);
    const [contactList, setContactList] = useState([]);
    const searchstringRef = useRef();
    const searchInputRef = useRef();

    useEffect(() => {
        if (visible) {
            if (searchInputRef.current) {
                searchInputRef.current.empty();
            }
            searchContacts("");
            searchInputRef.current.focus();
        }
    }, [visible]);

    async function searchContacts(searchstring) {
        setSearching(true);
        setContactList(
            await Api.searchContacts({
                searchstring: searchstring,
            }),
        );
        searchstringRef.current = searchstring;
        setSearching(false);
    }

    async function deleteContact(customer) {
        if (
            window.confirm(
                "Är du säker på att du vill ta bort " +
                    (customer.company || customer.name) +
                    "?",
            )
        ) {
            await Api.deleteContact({
                contactId: customer.id,
            });
            searchContacts(searchstringRef.current);
        }
    }

    return (
        <Container className={visible ? "is-visible" : ""}>
            <div className="content">
                <div className="header">
                    <div
                        className={
                            "search" + (searching ? " is-searching" : "")
                        }
                    >
                        <Input
                            type="text"
                            placeholder="Sök företag"
                            onChange={searchContacts}
                            ref={searchInputRef}
                        />
                    </div>
                    <div className="close" onClick={onClose}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
                            />
                        </svg>
                    </div>
                </div>
                <div className="body">
                    <table
                        className={"result" + (!searching ? " is-visible" : "")}
                    >
                        <thead>
                            <tr>
                                <th>Kund-id</th>
                                <th>Företag</th>
                                <th>Adress</th>
                                <th>Postort</th>
                                <th>Kontaktperson</th>
                                <th>Ta bort kontakt</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                className="contact self"
                                onClick={() => {
                                    onSelect({
                                        id: "self",
                                        countryCode:
                                            context.user.customer.countryCode,
                                        addressLine1:
                                            context.user.customer.addressLine1,
                                        addressLine2:
                                            context.user.customer.addressLine2,
                                        addressLine3:
                                            context.user.customer.addressLine3,
                                        dhlPalletExchangeNumber:
                                            context.user.customer
                                                .dhlPalletExchangeNumber,
                                        postalCode:
                                            context.user.customer.postalCode,
                                        postalTown:
                                            context.user.customer.postalTown,
                                        otherPickup: false,
                                        contact: {
                                            name: context.user.name,
                                            phoneNumber:
                                                context.user.phoneNumber,
                                            email: context.user.email,
                                            vatNumber:
                                                context.user.customer.vatNumber,
                                            private: false,
                                            company: context.user.customer.name,
                                        },
                                    });
                                }}
                            >
                                <td></td>
                                <td>{context.user.customer.name}</td>
                                <td>
                                    {context.user.customer.addressLine1}
                                    {context.user.customer.addressLine2 && (
                                        <>
                                            <br />
                                            {context.user.customer.addressLine2}
                                        </>
                                    )}
                                    {context.user.customer.addressLine3 && (
                                        <>
                                            <br />
                                            {context.user.customer.addressLine3}
                                        </>
                                    )}
                                </td>
                                <td>{context.user.customer.postalTown}</td>
                                <td>{context.user.name}</td>
                                <td></td>
                            </tr>
                            {contactList.map((customer, index) => {
                                return (
                                    <tr
                                        key={index}
                                        className="contact"
                                        onClick={() => onSelect(customer)}
                                    >
                                        <td>{customer.clientNumber}</td>
                                        <td>
                                            {customer.contact.company ||
                                                customer.contact.name}
                                        </td>
                                        <td>
                                            {customer.addressLine1}
                                            {customer.addressLine2 && (
                                                <>
                                                    <br />
                                                    {customer.addressLine2}
                                                </>
                                            )}
                                            {customer.addressLine3 && (
                                                <>
                                                    <br />
                                                    {customer.addressLine3}
                                                </>
                                            )}
                                        </td>
                                        <td>{customer.postalTown}</td>
                                        <td>
                                            {customer.contact &&
                                                customer.contact.name}
                                        </td>
                                        <td>
                                            <div
                                                className="delete"
                                                onClick={(ev) => {
                                                    ev.stopPropagation();
                                                    deleteContact(customer);
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z"
                                                    />
                                                </svg>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </Container>
    );
}

export default Component;
