import React, { useEffect, useState } from "react";
import { getDeliveryCodes } from "../../Create/BusinessCentral/businessCentralApi";
import toast, { LoaderIcon } from "react-hot-toast";
import { ReactComponent as DeleteIcon } from "../../../../imgs/ActionIcons/delete-x-icon.svg";

const DeliveryCodesSelection = ({ integration, handleUpdate }) => {
    const [selectedItems, setSelectedItems] = useState(
        integration.data.deliveryCodes || [],
    );
    const [selectedValue, setSelectedValue] = useState("");
    const [deliveryCodes, setDeliveryCodes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Selection change handler
    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
    };

    // Add delivery codes
    const handleAddItem = () => {
        // Prevent adding empty items or duplicates
        if (
            selectedValue.trim() === "" ||
            selectedItems.includes(selectedValue)
        ) {
            toast.error("Välj leveranskod.");
            return;
        }
        setIsLoading(true);

        const deliveryCodes = [...selectedItems, selectedValue];
        const currentIntegration = integration;
        currentIntegration.data.deliveryCodes = deliveryCodes;

        handleUpdate(currentIntegration.data).then((response) => {
            if (response) {
                setSelectedItems(deliveryCodes);
                setIsLoading(false);
                toast.success("Leveranskoden är tillagd.");
            }
        });
    };

    // Remove delivery codes
    const handleRemoveItem = (itemToRemove) => {
        const updatedItems = selectedItems.filter(
            (item) => item !== itemToRemove,
        );

        const currentIntegration = integration;
        currentIntegration.data.deliveryCodes = updatedItems;

        handleUpdate(currentIntegration.data).then((response) => {
            if (response) {
                setSelectedItems(updatedItems);
                toast.success("Leveranskoden raderades.");
            }
        });
    };

    // Get delivery codes from API
    const fetchDeliveryCodes = async () => {
        try {
            const deliveryCodes = await getDeliveryCodes(integration);
            setDeliveryCodes(deliveryCodes);
        } catch (error) {
            toast.error("Kunde inte hämta leveranskoder.");
        }
    };

    // Check if option should be disabled in selection
    const isCodeDisabled = (deliveryCode) => {
        return selectedItems?.includes(deliveryCode);
    };

    useEffect(() => {
        fetchDeliveryCodes();
    }, [selectedItems]);

    return (
        <div className="border p-3 rounded mt-5">
            <h3 className="font-bold mt-3  capitalize">
                Lägg till leveranskoder
            </h3>
            <small className="mb-5">
                Om du inte väljer leveranskod kommer samtliga leveranskoder att
                importeras.
            </small>
            <ul className="flex flex-wrap w-1/2">
                {selectedItems?.map((item, index) => (
                    <li
                        className="border rounded p-2 w-36 flex center justify-between m-2 ml-0"
                        key={index}
                    >
                        {item}
                        <button
                            className="button delete"
                            onClick={() => handleRemoveItem(item)}
                        >
                            {/* This is delete icon, On global scale project don't have any packages for icons */}
                            <DeleteIcon />
                        </button>
                    </li>
                ))}
            </ul>
            <div className="mb-5">
                <div className="mb-3">
                    <select
                        className="border p-2 w-52 mt-3 rounded"
                        value={selectedValue}
                        onChange={handleSelectChange}
                    >
                        <option value="">Välj leveranskoder...</option>
                        {deliveryCodes?.map((item) => {
                            const { code, id, displayName } = item;
                            return (
                                <option
                                    disabled={isCodeDisabled(code)}
                                    key={id}
                                    value={code}
                                >
                                    {`${code} - ${displayName}`}
                                </option>
                            );
                        })}
                    </select>
                    <button
                        disabled={isLoading}
                        className="ml-2 c-button c-button--raised"
                        onClick={handleAddItem}
                    >
                        Lägg till
                        {isLoading && <LoaderIcon />}
                    </button>
                </div>
                <small>
                    NOTERA: Om du lägger till leveranskoder importeras endast
                    beställningar som matchar leveranskoden från listan.
                </small>
            </div>
        </div>
    );
};

export default DeliveryCodesSelection;
