import React, { Component } from "react";
import styled from "styled-components";

let Container = styled.div`
    width: 100%;

    > input {
        width: 100%;
        padding: 8px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        box-sizing: border-box;
        outline: none;
    }

    > .message {
        font-weight: bold;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
    }

    &.is-invalid {
        > input {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }

        > .message {
            color: rgba(255, 0, 0, 0.6);
        }
    }
`;

class InputPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || "",
            minLength:
                parseInt(props.minLength) >= 0 ? parseInt(props.minLength) : 8,
            isInvalid: false,
        };
        this.onChange = this.onChange.bind(this);
    }

    value() {
        return this.state.value;
    }

    onChange(event) {
        this.setState(
            {
                value: event.target.value,
            },
            () => {
                if (this.state.isInvalid) {
                    this.validate();
                }
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this.state.value);
                }
            },
        );
    }

    validate() {
        if (this.props.required && this.state.value.length === 0) {
            this.setState({
                isInvalid: true,
                message: this.props.messages.required,
            });
            return false;
        }

        if (this.state.value.length < this.state.minLength) {
            this.setState({
                isInvalid: true,
                message: this.props.messages.tooShort,
            });
            return false;
        }
        this.setState({
            isInvalid: false,
            message: null,
        });
        return true;
    }

    render() {
        let { name, placeholder, autoComplete, ...otherProps } = this.props;

        return (
            <Container className={this.state.isInvalid ? " is-invalid" : ""}>
                <input
                    type="password"
                    name={name || ""}
                    autoComplete={autoComplete === true ? "" : "new-password"}
                    placeholder={placeholder}
                    value={this.state.value}
                    onChange={this.onChange}
                />
                {this.state.message && (
                    <div className="message">{this.state.message}</div>
                )}
            </Container>
        );
    }
}

InputPassword.defaultProps = {
    messages: {
        required: "This is a required field",
        tooShort: "Password is too short",
    },
};

export default InputPassword;
