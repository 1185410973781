import React from "react";
import { useTranslation } from "react-i18next";

const TrackingParties = ({ carrier, sender, recipient, customer }) => {
    const { t } = useTranslation();
    return (
        <div className="my-4 flex flex-wrap justify-between gap-6 sm:flex-nowrap md:gap-6 lg:gap-16">
            <div className=" w-full rounded-md bg-white p-6">
                <div className="flex flex-col">
                    <h1 className="mb-4 text-lg font-bold text-black">
                        {t("tracking.carrier")}
                    </h1>
                    <div className="flex flex-wrap justify-between">
                        <p className="whitespace-nowrap text-secondary-800">
                            {t("tracking.carrier.name")}
                        </p>
                        <p className="whitespace-nowrap font-bold text-secondary-800">
                            {carrier?.name?.toUpperCase() || "N/A"}
                        </p>
                    </div>
                </div>
            </div>
            <div className=" w-full rounded-md bg-white p-6">
                <div className="flex flex-col">
                    <h1 className="mb-4 text-lg font-bold text-black">
                        {t("tracking.sender")}
                    </h1>
                    <div className="flex flex-wrap justify-between">
                        <p className="whitespace-nowrap text-secondary-800">
                            {t("tracking.sender.companyName")}:
                        </p>
                        <p className="whitespace-nowrap font-bold text-secondary-800">
                            {sender.contact.company || customer?.name}
                        </p>
                    </div>
                    <div className="flex flex-wrap justify-between">
                        <p className="whitespace-nowrap text-secondary-800">
                            {t("tracking.country")}
                        </p>
                        <p className="whitespace-nowrap font-bold text-secondary-800">
                            {sender.countryCode || "N/A"}
                        </p>
                    </div>
                </div>
            </div>
            <div className=" w-full rounded-md bg-white p-6">
                <div className="flex flex-col">
                    <h1 className="mb-4 text-lg font-bold text-black">
                        {t("tracking.receiver")}
                    </h1>
                    <div className="flex flex-wrap justify-between">
                        <p className="whitespace-nowrap text-secondary-800">
                            {t("tracking.receiver.postalNumber")}
                        </p>
                        <p className="whitespace-nowrap font-bold text-secondary-800">
                            {recipient.postalCode || "N/A"}
                        </p>
                    </div>
                    <div className="flex flex-wrap justify-between">
                        <p className="whitespace-nowrap text-secondary-800">
                            {t("tracking.country")}
                        </p>
                        <p className="whitespace-nowrap font-bold text-secondary-800">
                            {recipient.countryCode || "N/A"}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrackingParties;
