import React, { forwardRef } from "react";
import CompanyForm from "../../../Drafts/Create/Route/CompanyForm";

const PickupAddress = forwardRef(
    ({ otherPickupAddress, user, setOtherPickupAddress }, ref) => {
        return (
            <div className="my-3">
                {otherPickupAddress ? (
                    <>
                        <CompanyForm
                            ref={ref}
                            disableSaveButton={true}
                            company={{
                                ...user.customer,
                                contact: {
                                    ...user,
                                    company: user.customer.name,
                                },
                            }}
                        />
                        <button
                            className="c-button c-button--ghost"
                            onClick={() => setOtherPickupAddress(false)}
                        >
                            Stäng annan adress
                        </button>
                    </>
                ) : (
                    <div>
                        <b>
                            Observera att den adress som anges i denna vy är den
                            som gäller för upphämtning.
                        </b>
                        <div className="text mt-4">
                            Upphämtning bokas till <b>{user.customer.name}</b>{" "}
                            på <b>{user.customer.addressLine1}</b>.
                        </div>
                    </div>
                )}
            </div>
        );
    },
);

export default PickupAddress;
