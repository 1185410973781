import InputCheckbox from "components/Input/Checkbox";
import Context from "context/Global";
import { match } from "helpers/Match";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components";

let Container = styled.tr`
    > td,
    > th {
        &:nth-child(1) {
            padding-left: 1em;
            padding-right: 2rem;
            width: 0px;
        }
        &:nth-child(2) {
            width: 80px;
            padding-right: 2rem;
        }
        &:nth-child(6) {
            width: 80px;
            padding-left: 2rem;
        }
    }

    > td {
        background: #fff;
        padding: 0.5em 0;

        &:first-child {
            padding-left: 1em;
        }
        &:last-child {
            padding-right: 1em;
        }

        > .source {
            width: 80px;
            max-height: 30px;
        }
    }

    &:hover > td {
        background: rgba(250, 250, 250, 1);
    }

    &.is-disabled > td {
        opacity: 0.5;
        pointer-events: none;
    }

    &.is-removing > td {
        opacity: 0.3;
        pointer-events: none;
    }

    .recipient,
    .sender {
        > .name {
            font-weight: bold;
        }
    }

    .logo {
        padding-right: 1rem;
        min-width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        height: 100%;
    }

    .icon {
        width: 32px;
        height: 32px;
        cursor: pointer;
        padding: 8px;

        > svg {
            width: 100%;
            height: 100%;
        }

        &.delete {
            color: #999;

            &:hover {
                color: #333;
            }
        }
    }
`;

function getSourceLogo(source) {
    return match(source, {
        FORTNOX: () => {
            return (
                <div className="logo">
                    <img src="/images/icons/fortnox.svg" />
                </div>
            );
        },
    });
}

function Component(props) {
    const context = useContext(Context);
    const [order, setOrder] = useState(props.order);
    const [selected, setSelected] = useState(props.selected);
    const [removingOrder, setRemovingOrder] = useState(false);
    const [disabled, setDisabled] = useState(!!props.disabled);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
        setDisabled(props.disabled);
    }, [props.disabled]);

    async function ignoreOrder() {
        setRemovingOrder(true);
        await Api.ignoreExternalOrder({
            customerId: context.user.customer.id,
            externalId: order.externalId,
            customerIntegrationId: order.customerIntegrationId,
        });

        await props.remove({
            externalId: order.externalId,
            customerIntegrationId: order.customerIntegrationId,
        });
        setRemovingOrder(false);
    }

    return (
        <Container
            className={`${removingOrder ? "is-removing" : ""} ${
                disabled ? "is-disabled" : ""
            }`}
        >
            <td>
                <InputCheckbox value={selected} onChange={props.onChange} />
            </td>
            <td>
                <div className="source">{getSourceLogo(order.source)}</div>
            </td>
            <td>
                <div className="recipient">
                    <div className="name">{order.recipientName}</div>
                    <div className="location">
                        {[order.recipientCity, order.recipientCountryCode]
                            .filter((v) => v)
                            .join(", ")}
                    </div>
                </div>
            </td>
            <td>{order.reference}</td>
            <td>
                {moment(order.created).calendar(null, {
                    sameDay: "[Idag]",
                    lastDay: "[Igår]",
                    lastWeek: "[I] dddd[s]",
                    sameElse: "LL",
                })}
            </td>
            <td>
                <div className="icon delete" onClick={ignoreOrder}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
                        />
                    </svg>
                </div>
            </td>
        </Container>
    );
}

export default Component;
