import Input from "components/OldInput";
import Loader from "components/Loader/Loader";
import Table from "components/Table";
import Context from "context/Global";
import { priceParser } from "helpers/StringParser";
import moment from "moment";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import OrderRow from "./Row";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useDebounce from "hooks/useDebounce";
import RevenueIcon from "components/RevenueIcon";
import ProfitIcon from "components/ProfitIcon";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;

    > .header {
        padding: 1em;
        display: flex;
        flex-direction: row;

        > .input {
            display: flex;
            margin-right: 1rem;
            align-items: center;

            > .title {
                padding-right: 1rem;
            }
        }

        > .count {
            display: flex;
            margin-right: 1rem;
            align-items: center;
            font-style: italic;

            > .Loader {
                height: 24px;
                width: 24px;
            }
        }

        > .summary {
            display: flex;
            align-items: center;
            margin-left: auto;

            > .revenue,
            > .profit {
                font-weight: bold;
                padding-left: 2rem;
                display: flex;
                align-items: center;

                > svg {
                    margin-right: 1rem;
                }
            }

            > .profit {
                color: rgba(100, 200, 100, 1);
            }
        }
    }

    > .buttons {
        padding: 16px 0;
    }
`;

let Title = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.6);
`;

function Component(props) {
    const dateFromUrl = props.location.search.replace("?date=", "");

    const context = useContext(Context);
    const [date, setDate] = useState(
        dateFromUrl || moment().format("YYYY-MM-DD"),
    );

    const [orders, setOrders] = useState([]);
    const [clientManagers, setClientManagers] = useState([]);
    const [clientManagerIdFilter, setClientManagerIdFilter] = useState(null);
    const [orderCount, setOrderCount] = useState(0);
    const [summary, setSummary] = useState(null);
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    async function loadOrders(clientManagerId = undefined) {
        setLoading(true);
        const response = await Api.getAllOrders({
            startDate: date,

            endDate: date,
            includeImported: false,
            salesPersonId: clientManagerId || clientManagerIdFilter,
        });
        setOrders(response.orders);
        setOrderCount(
            response.orders.filter((order) => {
                return (
                    [
                        "APPROVED",
                        "SHIPPED",
                        "IN_TRANSIT",
                        "DELIVERING",
                        "DELIVERED",
                    ].indexOf(order.state) >= 0
                );
            }).length,
        );
        setSummary(response.summary);
        setLoading(false);
    }

    const loadClientManagers = async () => {
        const clientManagers = await Api.getClientManagers({
            includeOwners: true,
        });
        setClientManagers(clientManagers);
    };

    // this is going to handle filter per sales person
    const handleFilterBySales = (e) => {
        const clientManagerId = parseInt(e.target.value);
        if (!isNaN(clientManagerId)) {
            setClientManagerIdFilter(clientManagerId);
            loadOrders(clientManagerId);
        } else {
            setClientManagerIdFilter(undefined);
        }
    };

    useEffect(() => {
        loadOrders();
        loadClientManagers();
        if (date) {
            history.push(`/admin/orders/?date=${date}`);
        }
    }, [clientManagerIdFilter, date]);

    return (
        <Container>
            <div className="header">
                <div className="input flex items-center gap-10">
                    <div className={"flex flex-col"}>
                        <label className="title text-sm font-medium">
                            Filtrera efter person
                        </label>
                        <select
                            onChange={handleFilterBySales}
                            className={"border p-2 rounded w-40 cursor-pointer"}
                        >
                            <option selected value={"All"}>
                                {" "}
                                Alla{" "}
                            </option>
                            {clientManagers?.map(({ name, id }) => (
                                <option key={id} value={id}>
                                    {name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="title text-sm font-medium">
                            Ändra datum
                        </label>
                        <Input
                            type="date"
                            key={date}
                            value={date}
                            onChange={(value) => {
                                setDate(value);
                            }}
                        />
                    </div>
                </div>
                <div className="count">
                    {loading && <Loader>Laddar...</Loader>}
                    {!loading && moment().format("YYYY-MM-DD") === date && (
                        <>{orderCount} försändelser har skapats idag</>
                    )}
                    {!loading && moment().format("YYYY-MM-DD") !== date && (
                        <>{orderCount} försändelser skapades</>
                    )}
                </div>
                {summary && (
                    <div className="summary">
                        <div className="revenue">
                            <RevenueIcon />
                            {priceParser(summary.price)} SEK
                        </div>
                        <div className="profit">
                            <ProfitIcon />
                            {priceParser(
                                summary.price -
                                    summary.transportCost -
                                    summary.partnerFee,
                            )}{" "}
                            SEK
                        </div>
                    </div>
                )}
            </div>
            <Table className="users">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Försändelse</th>
                        <th>Datum</th>
                        {context.user.administrator && <th>Brand</th>}
                        <th>Avsändare</th>
                        <th>Mottagare</th>
                        <th>
                            <p>Information</p>
                        </th>
                        <th>Pris</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order) => {
                        return <OrderRow key={order.id} order={order} />;
                    })}
                </tbody>
            </Table>
        </Container>
    );
}

export default Component;
