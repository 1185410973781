import Context from "context/Global";
import React, {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import Api from "services/Api/Api";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import Row from "./Row";

let Container = styled.div`
    display: inline-flex;
    flex-direction: column;
    width: 100%;

    &.is-disabled {
        > .inputContainer {
            background: rgb(235, 235, 228);
            border: 1px solid rgba(0, 0, 0, 0.05);

            > input {
                color: rgba(0, 0, 0, 0.5);
                background: transparent;
            }
        }
    }

    > .inputContainer {
        > .message {
            font-weight: bold;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.7);
            color: rgba(255, 0, 0, 0.6);
            padding-bottom: 1rem;
        }

        > .addDescription {
            display: flex;
            gap: 1rem;
        }

        > .header {
            display: flex;
            gap: 1rem;

            > .title {
                font-size: 0.8em;
                padding: 0 4px;
                width: 160px;
                font-weight: bold;
            }
            > .toggle {
                padding: 8px;
                cursor: pointer;
                display: flex;
                font-size: 8px;
                align-items: center;
                font-weight: bold;
                gap: 4px;
                border-radius: 500px;
                margin-left: auto;

                &:hover {
                    background: #fafafa;
                    color: #333;
                }

                > svg {
                    width: 12px;
                    height: 12px;
                }
            }
        }

        > .list {
            display: flex;
            flex-direction: column;
            padding: 0.5rem 0;
            gap: 0.5rem;

            > .title {
                font-weight: bold;
            }

            > .dimensions {
                display: flex;
                align-items: center;

                > .dimension {
                    margin-left: 1rem;
                }
            }
        }

        > .freetext {
            display: flex;
            gap: 0.5rem;
            align-items: center;
        }
    }

    > .buttons {
        padding: 1rem 0;
    }
`;

function Component(props, ref) {
    const context = useContext(Context);
    const [message, setMessage] = useState("");
    const [disabled, setDisabled] = useState(props.disabled || false);
    const [rows, setRows] = useState(
        props.value
            ? props.value.slice(0, props.multiRow !== false ? undefined : 1)
            : [
                  {
                      id: uuidv4(),
                      count: 1,
                      type: props.isPallet ? "pallet" : "package",
                      length: props.isPallet
                          ? props.isHalfPallet
                              ? 60
                              : 120
                          : 0,
                      width: props.isPallet ? 80 : 0,
                      height: 0,
                      weight: 0,
                      volume: 0,
                      stackable: true,
                  },
              ].map((row) => {
                  if (!row.id) {
                      row.id = uuidv4();
                  }
                  return row;
              }),
    );

    useEffect(() => {
        if (rows.length === 0) {
            if (props.options.length === 1) {
                setRows(
                    rows.concat({
                        id: uuidv4(),
                        count: 1,
                        type: props.options[0],
                        length: props.options[0].length,
                        width: props.options[0].width,
                        height: props.options[0].height,
                        weight: props.options[0].weight,
                        volume: props.options[0].volume,
                        stackable: props.options[0].stackable,
                    }),
                );
            } else {
                addRow();
            }
        }
    }, []);

    useEffect(() => {
        setMessage(props.message);
    }, [props.message]);

    useEffect(() => {
        setDisabled(props.disabled || false);
    }, [props.disabled]);

    function validate() {
        const valid = rows.reduce((acc, row) => {
            if (row.stackable !== true && row.stackable !== false) {
                acc = false;
            }
            if (!row.type) {
                acc = false;
            }
            return acc;
        }, true);

        if (!valid) {
            setMessage("Alla fält är obligatoriska.");
        } else {
            setMessage("");
        }

        return valid;
    }
    function clear() {}

    function updateRow(row) {
        setRows((rows) => {
            const newRows = rows.map((r) => {
                if (r.id === row.id) {
                    return row;
                }
                return r;
            });
            props.onChange(newRows);
            return newRows;
        });
    }

    function deleteRow(id) {
        setRows((rows) => {
            const newRows = rows.filter((row) => {
                return row.id !== id;
            });
            props.onChange(newRows);
            return newRows;
        });
    }

    function addRow() {
        setRows((rows) => {
            let newRows;
            newRows = rows.concat({
                id: uuidv4(),
                count: 1,
                type: props.isPallet ? "pallet" : "package",
                length: props.isPallet ? (props.isHalfPallet ? 60 : 120) : 0,
                width: props.isPallet ? 80 : 0,
                height: 0,
                weight: 0,
                volume: 0,
                stackable: true,
            });
            props.onChange(newRows);
            return newRows;
        });
    }

    useImperativeHandle(ref, () => {
        return {
            validate: validate,
            clear: clear,
            value: () => rows,
        };
    });

    async function changePackageSizeDefinition() {
        await Api.updateUserConfig({
            cancelToken: null,
            userId: context.user.id,
            config: {
                packageSizeDefinition:
                    context.user.config.packageSizeDefinition === "dimensions"
                        ? "volume"
                        : "dimensions",
            },
        });
        context.setUser(await Api.getCurrentUser());
    }

    return (
        <Container className={disabled ? " is-disabled" : ""}>
            <div className="inputContainer">
                <div className="header">
                    <div className="title">Antal kolli</div>
                    <div className="title">Typ</div>
                    {context.user.config.packageSizeDefinition === "volume" ? (
                        <div className="title">Volym</div>
                    ) : (
                        <>
                            <div className="title">Längd</div>
                            <div className="title">Bredd</div>
                            <div className="title">Höjd</div>
                        </>
                    )}
                    <div className="title">Vikt</div>
                    {props.showStackable && (
                        <div className="title">Stapelbar</div>
                    )}
                    {context.user.customer.settings
                        .togglePackageSizeDefinition && (
                        <div
                            className="toggle"
                            onClick={changePackageSizeDefinition}
                        >
                            dim{" "}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="0.26in"
                                height="0.26in"
                                viewBox="0 0 78 78"
                            >
                                <path
                                    id="Selection"
                                    fill="currentColor"
                                    d="M 58.00,1.00 C 61.12,-3.50 75.31,13.13 75.26,16.91 75.23,19.07 72.88,21.53 71.53,23.09 67.50,27.76 60.88,36.69 58.00,33.00 56.06,30.06 61.37,24.65 66.00,20.00 66.00,20.00 23.00,20.00 23.00,20.00 17.52,20.03 13.29,20.34 9.65,25.10 6.73,28.93 6.93,35.55 3.21,34.27 0.72,33.41 1.28,29.92 1.77,28.00 3.26,22.10 7.42,17.76 13.00,15.48 18.16,13.37 26.30,14.00 32.00,14.00 32.00,14.00 66.00,14.00 66.00,14.00 61.57,9.47 55.31,4.94 58.00,1.00 Z M 11.00,58.00 C 11.00,58.00 53.00,58.00 53.00,58.00 58.94,57.99 63.16,57.95 67.20,52.82 70.41,48.73 69.87,42.48 73.74,43.74 76.55,44.66 75.41,49.00 74.78,51.00 72.93,56.79 68.72,60.90 63.00,62.89 58.36,64.50 50.13,64.00 45.00,64.00 45.00,64.00 11.00,64.00 11.00,64.00 11.00,64.00 19.50,73.19 17.41,76.60 14.09,80.27 1.63,64.07 1.63,61.00 1.63,57.47 15.31,42.81 17.79,45.73 20.56,48.81 11.00,58.00 11.00,58.00 Z"
                                />
                            </svg>{" "}
                            vol
                        </div>
                    )}
                </div>
                <div className="list">
                    {rows.map((pkg) => {
                        if (!pkg.id) {
                            pkg.id = uuidv4();
                        }
                        if (!props.showStackable) {
                            pkg.stackable = true;
                        }
                        return (
                            <Row
                                key={pkg.id}
                                value={pkg}
                                isPallet={props.isPallet}
                                showStackable={props.showStackable}
                                showRemove={props.multiRow !== false}
                                packages={props.options}
                                onChange={updateRow}
                                onDelete={deleteRow}
                            />
                        );
                    })}
                </div>
                {message && <div className="message">{message}</div>}
                {props.multiRow !== false && (
                    <button
                        className="c-button c-button--ghost"
                        onClick={addRow}
                    >
                        Lägg till
                    </button>
                )}
            </div>
        </Container>
    );
}

export default forwardRef(Component);
