import create from "zustand";

const setReceiverPaysContactId = (set) => (newReceiverPaysContactId) => {
    set({ receiverPaysContactId: newReceiverPaysContactId });
};

const setReceiverPaysAccountNumber =
    (set) => (newReceiverPaysAccountNumber) => {
        set({ receiverPaysAccountNumber: newReceiverPaysAccountNumber });
    };

const setReceiverPaysCarrier = (set) => (newReceiverPaysCarrier) => {
    set({ receiverPaysCarrier: newReceiverPaysCarrier });
};

const initialState = {
    receiverPaysContactId: null,
    receiverPaysAccountNumber: null,
    receiverPaysCarrier: null,
};

const useCreateDraftsStore = create((set) => ({
    ...initialState,
    setReceiverPaysAccountNumber: setReceiverPaysAccountNumber(set),
    setReceiverPaysCarrier: setReceiverPaysCarrier(set),
    setReceiverPaysContactId: setReceiverPaysContactId(set),
    resetStates: () => set(initialState),
}));

export default useCreateDraftsStore;
