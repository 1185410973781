import React from "react";
function CheckboxInput({ value, setValue, name = "checkbox" }) {
    return (
        <div className="relative flex items-center">
            <input
                type="checkbox"
                onChange={(e) => {
                    setValue(e.target.checked);
                }}
                checked={value}
                className="h-5 w-5"
                style={{ accentColor: "rgba(76, 80, 175, 1)" }}
            />
        </div>
    );
}

export { CheckboxInput };
