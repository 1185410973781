import Input from "components/OldInput";
import axios from "axios";
import Loader from "components/Loader/Loader";
import Context from "context/Global";
import { getTransporterLogo } from "helpers/Logos";
import { match } from "helpers/Match";
import moment from "moment";
import "moment/locale/sv";
import React, { useContext, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import { getIntegrationName } from "../../../../../helpers/Integrations";

function getPrettyName(service) {
    switch (service.productCode) {
        case 102:
        case "DHLPaket":
        case "DHLPaket2":
        case "DHL PAKET2":
        case "DHL PAKET": {
            return "DHL Paket";
        }
        case 103:
        case "DHLServicePointB2C":
        case "DHL SERVICE POINT": {
            return "DHL ServicePointB2C";
        }
        case 104:
        case "DHLServicePointReturn": {
            return "DHL ServicePointReturn";
        }
        case 109:
        case "DHLParcelConnect": {
            return "DHL ParcelConnect";
        }
        case 112:
        case "DHLPaketExport": {
            return "DHL PaketExport";
        }
        case 202:
        case "DHLEuroConnect":
        case "ECE": {
            return "DHL EuroConnect";
        }
        case 205:
        case "DHL Euroline":
        case "DHLEuroline": {
            return "DHL Euroline";
        }
        case 210:
        case "DHLPall":
        case "DHL PALL": {
            return "DHL Pall";
        }
        case 211:
        case "DHLStycke":
        case "DHL STYCKE": {
            return "DHL Stycke";
        }
        case 212:
        case "DHLParti": {
            return "DHL Parti";
        }
        case 232:
        case "DHLEuroConnectPlus": {
            return "DHL EuroConnectPlus";
        }
        case 233:
        case "DHLEurapid":
        case "ERA":
        case "DHL Eurapid": {
            return "DHL EU Rapid";
        }
        case 401:
        case "DHLHomeDelivery":
        case "DHL HomeDelivery": {
            return "DHL HomeDelivery";
        }
    }
}

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;

    > .title {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.6);
        padding: 0 1rem;
    }

    > .header {
        padding: 1rem;
        display: flex;
        flex-direction: row;

        > .input {
            display: flex;
            margin-right: 1rem;
            align-items: center;

            > .title {
                padding-right: 1rem;
            }
        }

        .pagination {
            display: flex;
            align-items: center;
            margin-left: auto;
            cursor: pointer;

            > .previous,
            > .next {
                width: 16px;
                height: 16px;
                padding: 0 8px;
                box-sizing: content-box;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &.is-disabled {
                    color: #aaa;
                    pointer-events: none;
                }

                > svg {
                    width: 100%;
                    height: 100%;
                }
            }
            > .previous {
                transform: rotateZ(180deg);
            }
        }
    }

    > .emptyState {
        padding: 0 1rem;
        font-style: italic;
    }

    > .section {
        padding: 1rem;
    }

    > .loader {
        width: 200px;
        height: 200px;
    }

    > table {
        > thead {
            > tr {
                > th:first-child {
                    padding-left: 8px;
                }
            }
        }
        > tbody {
            > tr {
                &:hover {
                    td {
                        background: rgba(0, 0, 0, 0.03);
                    }
                }

                td.created {
                    > .wrapper {
                        display: flex;
                        flex-direction: column;
                        padding: 0 1rem 0 0;

                        > .date {
                            white-space: nowrap;
                        }
                        > .source {
                            font-size: 0.8rem;
                            color: #555;
                        }
                    }
                }

                td:first-child {
                    border-radius: 5px 0 0 5px;
                    padding-left: 8px;
                }
                td:last-child {
                    text-align: right;
                    border-radius: 0 5px 5px 0;
                }
            }
        }
    }

    > .buttons {
        padding: 16px 0;
    }

    > .products {
        padding: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        background: #fff;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;

        table {
            thead {
                th {
                    font-weight: normal;
                    padding: 4px 16px;
                }
            }

            tbody {
                th {
                    font-weight: normal;
                }
                .product {
                    th {
                        width: 100%;
                        padding: 4px 16px;
                    }

                    th,
                    td {
                        &:first-child {
                            border-radius: 5px 0 0 5px;
                        }
                        &:last-child {
                            border-radius: 0 5px 5px 0;
                        }
                    }

                    .input {
                        padding: 4px 16px;
                    }

                    &:hover {
                        th,
                        td {
                            background: rgba(0, 0, 0, 0.05);
                        }
                    }
                }
            }
        }
    }

    > table.orders {
        width: 100%;
        margin-bottom: 16px;
        flex-direction: column;

        > thead {
            > tr {
                > th {
                    background: #fafafa;
                    padding: 1rem 0;
                    border-bottom: 1px solid #ddd;
                    border-top: 1px solid #ddd;
                    color: #333;
                    font-weight: normal;

                    &:first-child {
                        padding-left: 1rem;
                    }
                    &:last-child {
                        padding-right: 1rem;
                        text-align: right;
                    }
                }
            }
        }

        > tbody {
            > tr {
                &:first-child {
                    > td {
                        padding-top: 0.5rem;
                    }
                }
                > .transporter {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    font-size: 0.6rem;

                    > img {
                        height: 32px;
                        max-width: 100px;
                    }
                }

                > td {
                    background: #fff;
                    padding: 0.2rem 0;

                    &:first-child {
                        padding-left: 1rem;
                    }
                    &:last-child {
                        padding-right: 1rem;
                        text-align: right;
                    }

                    &:nth-child(3),
                    &:nth-child(4) {
                        font-size: 0.6rem;
                    }

                    &.service {
                        > img {
                            width: 32px;
                            object-size: contain;
                        }
                    }
                }

                &:hover > td {
                    background: rgba(250, 250, 250, 1);
                }
            }

            > .emptyState {
                > td {
                    text-align: center !important;
                    font-style: italic;
                }
            }
        }
    }
`;

let Title = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.6);
`;

let TEN_SECONDS = 1000 * 10;

function Component({ customerId, ...props }) {
    const context = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState(null);
    const [searchstring, setSearchstring] = useState("");
    const [startDate, setStartDate] = useState(
        sessionStorage.getItem("adminViewCustomerOrderListStartDate") || "",
    );
    const [endDate, setEndDate] = useState(
        sessionStorage.getItem("adminViewCustomerOrderListEndDate") || "",
    );
    const [allSelected, setAllSelected] = useState(false);
    const [showPrintDialog, setShowPrintDialog] = useState(false);
    const [dateInterval, setDateInterval] = useState(
        sessionStorage.getItem("adminViewCustomerOrderListDateInterval") ||
            "all",
    );
    const [limit, setLimit] = useState(200);
    const [offset, setOffset] = useState(0);
    const [totalOrders, setTotalOrders] = useState(0);
    const cancelSearchTokenRef = useRef();
    const startDateRef = useRef();
    const endDateRef = useRef();
    const dateIntervalRef = useRef();

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
        if (
            startDate === endDate &&
            startDate === moment().format("YYYY-MM-DD")
        ) {
            dateIntervalRef.current.set("today");
        } else if (
            startDate === endDate &&
            startDate === moment().subtract(1, "day").format("YYYY-MM-DD")
        ) {
            dateIntervalRef.current.set("yesterday");
        } else if (
            startDate === moment().startOf("isoWeek").format("YYYY-MM-DD") &&
            endDate === moment().endOf("isoWeek").format("YYYY-MM-DD")
        ) {
            dateIntervalRef.current.set("week");
        } else if (
            startDate ===
                moment()
                    .startOf("isoWeek")
                    .subtract(1, "week")
                    .format("YYYY-MM-DD") &&
            endDate ===
                moment()
                    .endOf("isoWeek")
                    .subtract(1, "week")
                    .format("YYYY-MM-DD")
        ) {
            dateIntervalRef.current.set("lastWeek");
        } else if (startDate === "" && endDate === "") {
            dateIntervalRef.current.set("all");
        } else {
            dateIntervalRef.current.set("manual");
        }
    }, [searchstring, startDate, endDate, limit, offset]);

    useEffect(() => {
        switch (dateInterval) {
            case "today": {
                setStartDate(moment().format("YYYY-MM-DD"));
                setEndDate(moment().format("YYYY-MM-DD"));
                startDateRef.current.set(moment().format("YYYY-MM-DD"));
                endDateRef.current.set(moment().format("YYYY-MM-DD"));
                break;
            }
            case "yesterday": {
                setStartDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
                setEndDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
                startDateRef.current.set(
                    moment().subtract(1, "day").format("YYYY-MM-DD"),
                );
                endDateRef.current.set(
                    moment().subtract(1, "day").format("YYYY-MM-DD"),
                );
                break;
            }
            case "week": {
                setStartDate(moment().startOf("isoWeek").format("YYYY-MM-DD"));
                setEndDate(moment().endOf("isoWeek").format("YYYY-MM-DD"));
                startDateRef.current.set(
                    moment().startOf("isoWeek").format("YYYY-MM-DD"),
                );
                endDateRef.current.set(
                    moment().endOf("isoWeek").format("YYYY-MM-DD"),
                );
                break;
            }
            case "lastWeek": {
                setStartDate(
                    moment()
                        .startOf("isoWeek")
                        .subtract(1, "week")
                        .format("YYYY-MM-DD"),
                );
                setEndDate(
                    moment()
                        .endOf("isoWeek")
                        .subtract(1, "week")
                        .format("YYYY-MM-DD"),
                );
                startDateRef.current.set(
                    moment()
                        .startOf("isoWeek")
                        .subtract(1, "week")
                        .format("YYYY-MM-DD"),
                );
                endDateRef.current.set(
                    moment()
                        .endOf("isoWeek")
                        .subtract(1, "week")
                        .format("YYYY-MM-DD"),
                );
                break;
            }
            case "all": {
                setStartDate("");
                setEndDate("");
                startDateRef.current.set("");
                endDateRef.current.set("");
                break;
            }
        }
        sessionStorage.removeItem("adminViewCustomerOrderListStartDate");
        sessionStorage.removeItem("adminViewCustomerOrderListEndDate");
        sessionStorage.setItem(
            "adminViewCustomerOrderListDateInterval",
            dateInterval,
        );
    }, [dateInterval]);

    async function loadData() {
        if (cancelSearchTokenRef.current) {
            cancelSearchTokenRef.current.cancel();
        }
        cancelSearchTokenRef.current = axios.CancelToken.source();

        setLoading(true);

        const { orders, count } = await Api.getCustomerOrders({
            customerId: customerId,
            includeArchived: true,
            query: searchstring,
            startDate: startDate,
            endDate: endDate,
            limit: limit,
            offset: offset,
            cancelToken: cancelSearchTokenRef.current.token,
        });
        // Filter out drafts from the list since DRAFTS are batch orders that are not yet booked.
        setOrders(orders?.filter((order) => order.state !== "DRAFT"));
        setTotalOrders(count);
        setLoading(false);
    }

    async function toggleLimit() {
        if (limit < 200 && totalOrders > 100) {
            setLimit(200);
        } else if (limit < 500 && totalOrders > 200) {
            setLimit(500);
        } else if (limit < 1000 && totalOrders > 500) {
            setLimit(1000);
        } else if (limit < 5000 && totalOrders > 1000) {
            setLimit(5000);
        } else {
            setLimit(100);
        }
    }

    async function previousPage() {
        setOffset(offset - limit < 0 ? 0 : offset - limit);
    }
    async function nextPage() {
        if (offset + limit < totalOrders) {
            setOffset(offset + limit);
        }
    }

    return (
        <Container>
            <div className="title">Försändelser</div>
            <div className="header">
                <div className="input">
                    <Input
                        type="text"
                        onChange={setSearchstring}
                        placeholder={"Sök..."}
                    />
                </div>
                <div className="input">
                    <div className="title">Från</div>
                    <Input
                        ref={startDateRef}
                        type="date"
                        value={startDate}
                        onChange={(value) => {
                            sessionStorage.removeItem(
                                "adminViewCustomerOrderListDateInterval",
                            );
                            sessionStorage.setItem(
                                "adminViewCustomerOrderListStartDate",
                                value,
                            );
                            setStartDate(value);
                        }}
                    />
                </div>
                <div className="input">
                    <div className="title">Till</div>
                    <Input
                        ref={endDateRef}
                        type="date"
                        value={endDate}
                        onChange={(value) => {
                            sessionStorage.removeItem(
                                "adminViewCustomerOrderListDateInterval",
                            );
                            sessionStorage.setItem(
                                "adminViewCustomerOrderListEndDate",
                                value,
                            );
                            setEndDate(value);
                        }}
                    />
                </div>
                <div className="input">
                    <div className="title">Byt intervall</div>
                    <Input
                        ref={dateIntervalRef}
                        type="dropdown"
                        value={dateInterval}
                        onChange={setDateInterval}
                        options={[
                            {
                                title: "Välj",
                                value: "manual",
                            },
                            {
                                title: "Dagens",
                                value: "today",
                            },
                            {
                                title: "Gårdagens",
                                value: "yesterday",
                            },
                            {
                                title: "Veckans",
                                value: "week",
                            },
                            {
                                title: "Föregående vecka",
                                value: "lastWeek",
                            },
                            {
                                title: "Visa alla försändelser",
                                value: "all",
                            },
                        ]}
                    />
                </div>
                <div className="pagination">
                    <div
                        className={`previous ${
                            offset === 0 ? "is-disabled" : ""
                        }`}
                        onClick={previousPage}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path fill="currentColor" d="M6 0l12 12-12 12z" />
                        </svg>
                    </div>
                    <div className="showing" onClick={toggleLimit}>
                        Visar {totalOrders ? offset + 1 : 0} -{" "}
                        {totalOrders < offset + limit
                            ? totalOrders
                            : offset + limit}{" "}
                        av {totalOrders}
                    </div>
                    <div
                        className={`next ${
                            offset + limit > totalOrders ? "is-disabled" : ""
                        }`}
                        onClick={nextPage}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path fill="currentColor" d="M6 0l12 12-12 12z" />
                        </svg>
                    </div>
                </div>
            </div>
            {!orders && (
                <div className="loader">
                    <Loader />
                </div>
            )}
            {!!orders && (
                <table className="orders">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Transportör</th>
                            <th>Skapad</th>
                            <th>Status</th>
                            <th>Destination</th>
                            <th>Kostnad</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order) => {
                            return (
                                <tr
                                    key={order.id}
                                    className={`order is-${order.state.toLowerCase()}`}
                                    href={`/admin/orders/${order.id}`}
                                    onClick={() => {
                                        props.history.push(
                                            `/admin/orders/${order.id}`,
                                        );
                                    }}
                                >
                                    <td className="id">{order.id}</td>
                                    <td className="transporter">
                                        <img
                                            src={`/images/icons/${getTransporterLogo(
                                                order.service.name,
                                            )}`}
                                        />
                                        {getPrettyName(order.service)}
                                    </td>
                                    <td className="created">
                                        <div className="wrapper">
                                            <div className="date">
                                                {moment(
                                                    order.created,
                                                ).calendar()}
                                            </div>
                                            <div className="source">
                                                {getIntegrationName(
                                                    order.customerIntegrationType,
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="state">{order.state}</td>
                                    <td className="title">
                                        {[
                                            [
                                                order.route?.to?.postalCode,
                                                order.route?.to?.postalTown,
                                            ]
                                                .filter((v) => v)
                                                .join(" "),
                                            order.route?.to?.countryCode,
                                        ]
                                            .filter((v) => v)
                                            .join(", ")}
                                    </td>
                                    <td className="price">
                                        {order.receiverPays ? (
                                            "Mottagarfrakt"
                                        ) : (
                                            <>
                                                {order.price &&
                                                    order.price?.amount?.toFixed(
                                                        2,
                                                    )}{" "}
                                                {order.price?.unit || ""}
                                            </>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                        {orders.length === 0 &&
                            (searchstring || startDate || endDate) && (
                                <tr className="emptyState">
                                    <td colSpan="100">
                                        Inga försändelser hittades med dessa
                                        sökkriterier
                                    </td>
                                </tr>
                            )}
                        {orders.length === 0 &&
                            !(searchstring || startDate || endDate) && (
                                <tr className="emptyState">
                                    <td colSpan="100">
                                        Kunden har inte skickat några
                                        försändelser ännu
                                    </td>
                                </tr>
                            )}
                    </tbody>
                </table>
            )}
        </Container>
    );
}

export default withRouter(Component);
