const RETURN_DESCRIPTION =
    "Skriv ut en returfraktsedel och skicka med i din försändelse. Vid eventuell retur sätter mottagaren på fraktsedeln och lämnar in försändelsen på ett utlämningsställe som stödjer PostNord. Returfraktsedeln är giltig i två månader efter utskrivning. Ni kommer enbart att debiteras vid genomförd retur.";

export const POSTNORD = {
    services: {
        mypack_collect: "PostNord MyPack Collect",
        postnord_parcel: "PostNord Parcel",
        varubrev_ekonomi: "PostNord Varubrev Ekonomi",
        varubrev_1_klass: "PostNord Varubrev 1 Klass",
        postnord_pallet: "PostNord Pallet",
    },
    additional_services: {
        email_notification: {
            name: "e-postavisering",
            code: "A4",
            description:
                "Din mottagare får meddelande via mejl om vilken dag paketet kommer att levereras.",
            price: 1.5,
        },
        climate_compensation: {
            name: "Klimatkompensation",
            code: "D9",
            description:
                "PostNord investerar ersättningen oavkortat i förnybar energiproduktion i utvecklingsländer. Fossil energiproduktion ersätts av förnybar. Det görs med hjälp av FN certifierade CDM-projekt (Clean Development Mechanism) via företaget 2050.",
            price: 0.05,
        },
        delivery_notification: {
            name: "Leveransavisering",
            code: "C5",
            description:
                "Avisera din mottagare, eller en tredje part, om att paket har levererats.",
            price: 2.5,
        },
        driver_notification: {
            name: "Chaufförsavisering",
            code: "B8",
            description: "Mottagaren blir uppringd av chaufför före leverans.",
            price: 55,
        },
        pre_notification: {
            name: "Föravisering",
            code: "D3",
            description:
                "Din mottagare får meddelande via mejl eller sms om vilken dag paketet kommer att levereras.",
            price: 2.5,
        },
        private_receiver_pallet: {
            name: "Privat mottagare direktleverans",
            code: "F8",
            description:
                "Om pallen skickas till en privatperson är det obligatoriskt att använda tilläggstjänsten Privat mottagare eller Privat mottagare direktleverans (inom Sverige). Inom Sverige ingår Leveransavisering och Utlämningsavisering kostnadsfritt när tilläggstjänsterna används. Chaufförsavisering ingår om paketet levereras mot kvittens. ",
            price: 200,
        },
        e_letter_notification: {
            name: "eBrevsavisering",
            code: "A2",
            price: 22,
            description:
                "Din mottagare får meddelande via eBrev om vilken dag paketet kommer att levereras.",
        },
        delivery_without_receipt: {
            name: "Leverans utan kvittens",
            code: "A6",
            description:
                "Paketet kan lämnas ut utan att mottagaren personligen kvitterar ut paketet.",
            price: 0,
        },
        completion_notification: {
            name: "Utlämningsavisering",
            code: "C6",
            description:
                "Avisera din mottagare, eller en tredje part, om att paket har lämnats ut.",
            price: 2.5,
        },
        before_9: {
            name: "Leverans före 09:00",
            code: "D6",
            description:
                "Leverans till mottagaren före klockan 09.00 på vardagar. Tilläggstjänsten kan användas till ett begränsat antal orter.",
            price: 565,
        },
        before_12: {
            name: "Leverans före 12:00",
            code: "D7",
            description:
                "Leverans till mottagaren före klockan 12.00 på vardagar. Tilläggstjänsten kan användas till ett begränsat antal orter.",
            price: 120,
        },
        varubrev_return: {
            name: "Varubrev Retur",
            code: "87",
            description: RETURN_DESCRIPTION,
            price: 0,
        },
        return_drop_off: {
            name: "PostNord Return Drop Off",
            code: "24",
            description: RETURN_DESCRIPTION,
            price: 0,
        },
    },
};

export const PN_MARGIN_MULTIPLIER = 1.2; // 20% margin for insurance and additional services
