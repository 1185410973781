import React from "react";

const SelectAll = ({ onChange, value, disabled }) => {
    return (
        <div className="flex items-center justify-start px-3">
            <input
                checked={value}
                value={value}
                disabled={disabled}
                onChange={() => {
                    onChange((prev) => !prev);
                }}
                type="checkbox"
                className="h-5 w-5"
                style={{ accentColor: "rgba(76, 80, 175, 1)" }}
            />
        </div>
    );
};

export default SelectAll;
