import { match } from "helpers/Match";

export const getTransporterLogo = (name) => {
    switch (name?.toLowerCase()) {
        case "tnt leman":
            return "tnt.svg";
        case "begoma":
            return "begoma.png";
        case "dhl express":
            return "dhl-express.png";
        case "zendr":
            return "zendr.png";
        default:
            return `${name?.toLowerCase()}.svg`;
    }
};

export const getIntegrationImage = (integrationType) => {
    return match(integrationType, {
        ZENDR: () => "../logos/zendr-logo-hallon.svg",
        LOG_TRADE: () => "logtrade.png",
        STARWEB: () => "starweb.png",
        FORTNOX: () => "fortnox.svg",
        SHOPIFY: () => "shopify.svg",
        WOO_COMMERCE: () => "woocommerce.svg",
        MAGENTO: () => "magento.svg",
        VISMA_ADMINISTRATION: () => "visma-administration.svg",
        BUSINESS_CENTRAL: () => "bc-icon.png",
        CUSTOM: () => "zendr.png",
        QUICK_BUTIK: () => "quickbutik.jpg",
        ONGOING_WMS: () => "ongoing.svg",
        JOOMLA_VIRTUEMART: () => "virtuemart.svg",
        PYRAMID: () => "pyramid.png",
    });
};
