import Input from "components/OldInput";
import Loader from "components/Loader/Loader";
import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    > .title {
        font-size: 2rem;
        padding: 1rem;
    }
    > .description {
        padding: 0 1rem;
    }

    > .form {
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    > .buttons {
        padding: 1rem;
    }

    ul {
        padding: 1rem 0;
        > li {
            list-style-position: inside;
            margin-left: 2rem;
        }
    }

    a {
        color: #0b0bc2;

        &:hover {
            text-decoration: underline;
        }
    }

    > .error {
        margin-top: 16px;
        color: #d77;
        font-weight: bold;
    }
`;

function Component(props) {
    const context = useContext(Context);
    const [formData, setFormData] = useState({});
    const [plans, setPlans] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    async function loadIntegration() {
        setPlans(
            await Api.getIntegrationPlans({
                integrationId: 1,
            }),
        );
    }

    // Here we are going to validate the integration input fields
    function isValidated(formData) {
        if (!formData.identifier || formData.identifier.trim() === "") {
            setErrorMessage("Lagerägarens namn är obligatoriskt.");
            return false;
        }

        if (!formData.endpoint || formData.endpoint.trim() === "") {
            setErrorMessage("REST API-URL är obligatorisk.");
            return false;
        } else if (
            !/^https?:\/\/[\w.-]+(?:\:[0-9]+)?\/?.*$/.test(formData.endpoint)
        ) {
            setErrorMessage("Ogiltigt format för URL.");
            return false;
        }

        if (!formData.username || formData.username.trim() === "") {
            setErrorMessage("API-användarnamn är obligatoriskt.");
            return false;
        }

        if (!formData.password || formData.password.trim() === "") {
            setErrorMessage("API-lösenord är obligatoriskt.");
            return false;
        }

        if (!formData.goodsOwnerId) {
            setErrorMessage("GoodsägarId är obligatoriskt.");
            return false;
        }

        return true;
    }

    // Adding integration to Zendr
    async function addOngoingWmsIntegration() {
        setLoading(true);
        const customerId = context.user.customerId;
        const data = {
            ...formData,
            planId: plans ? plans[0].id : null,
        };

        if (!isValidated(formData)) {
            setLoading(false);
            return;
        }

        await Api.createCustomerIntegration({ customerId, data })
            .then((response) => {
                props.history.push(`/addons/${response.id}`);
            })
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        loadIntegration();
    }, []);

    if (plans === null) return <Loader />;

    return (
        <Container>
            <div className="title">Lägg till Ongoing WMS-integration</div>
            <div className="description">
                För att aktivera Ongoing WMS-integrationen, följ dessa steg:
                <ul className="steps space-y-4">
                    <li>
                        1. Skapa en ny webhook i Ongoing WMS och välj hur du
                        vill att den ska utlösas, till exempel när en ny order
                        skapas eller en status ändras. Lägg sedan till följande
                        URL i URL-fältet:
                        <code className="ml-3">
                            {process.env.REACT_APP_API_URL}
                            /api/wh/fRf4sWMwVJDd1ckRaRVf7s4XdPXT05OVzhtPmr79KH3M8sbiXMFr3PUmC32mXPDT
                        </code>
                    </li>
                    <li>
                        2. Innan du sparar webhooken väljer du ägarnamnet som
                        ska gälla. Du behöver även ange företagsnamnet
                        (warehouse name) i Zendrs portal.
                    </li>
                    <li>
                        3. Generera ett "API for automation" eller "API for
                        goodsowners" och skicka dessa uppgifter till din e-post.
                        Du kommer att få API-ändpunkter, användarnamn och
                        lösenord som du måste ange i Zendrs portal
                    </li>
                    <li>4. Slutligen, aktivera integrationen i Zendr.</li>
                    <li>
                        5. När integrationen är aktiverad, kopiera åtkomsttoken
                        och klistra in den i fältet för webhook-autentisering
                        under skapandet av webhooken i Ongoing WMS, specifikt
                        för <code>X-Auth-Token</code>.
                    </li>
                </ul>
            </div>

            {plans.length > 0 && (
                <>
                    <div className="form">
                        <Input
                            type="list"
                            onChange={setFormData}
                            object={{
                                type: {
                                    type: "text",
                                    hidden: true,
                                    value: "OngoingWms",
                                },
                                identifier: {
                                    title: "Lagerägarens namn",
                                    placeholder: "Ange lagerägarens namn",
                                    type: "text",
                                },
                                endpoint: {
                                    title: "API URL",
                                    placeholder:
                                        "E.g. https://wms1.ongoingsystems.se/zendr/automation.asmx",
                                    type: "text",
                                },
                                username: {
                                    title: "API användarnamn",
                                    placeholder: "Ange API-användarnamn",
                                    type: "text",
                                },
                                password: {
                                    title: "API lösenord",
                                    placeholder: "Ange ditt API-lösenord",
                                    type: "text",
                                },
                                goodsOwnerId: {
                                    title: "Godsägar ID",
                                    placeholder: "Ange ditt godsägare ID",
                                    type: "text",
                                    helpText: (
                                        <>
                                            <p>
                                                Detta Id:t finner du genom
                                                menyvalet
                                                Administration/GoodsOwners i
                                                OngoingWMS.
                                            </p>
                                        </>
                                    ),
                                },
                                goodsOwnerName: {
                                    title: "Godsägarnamn",
                                    placeholder: "Ange ditt godsägare namn",
                                    type: "text",
                                    helpText: (
                                        <>
                                            <p>
                                                Detta namnet finner du genom
                                                menyvalet
                                                Administration/GoodsOwners i
                                                OngoingWMS.
                                            </p>
                                        </>
                                    ),
                                },
                                pickupAddress: {
                                    type: "dropdown",
                                    title: "Upphämtningsadress",
                                    helpText: (
                                        <>
                                            <p
                                                style={{
                                                    marginBottom: "1em",
                                                }}
                                            >
                                                Godsägarens upphämtningsadress
                                                från Ongoing.
                                            </p>
                                            <p>
                                                Kontohavarens upphämtningsadress
                                                från Zendr.
                                            </p>
                                        </>
                                    ),
                                    options: [
                                        {
                                            label: "Använd godsägarens upphämtningsadress",
                                            value: "Godsägare",
                                        },
                                        {
                                            label: "Använd kontohavarens upphämtningsadress",
                                            value: "Kontohavare",
                                        },
                                    ],
                                },
                                includeOrderIdInReference: {
                                    type: "dropdown",
                                    title: "Inkludera orderId i referens",
                                    helpText:
                                        "Välj om orderId ska inkluderas i referensfältet.",

                                    options: [
                                        { label: false, value: "Nej" },
                                        { label: true, value: "Ja" },
                                    ],
                                },
                                selectGoodsSource: {
                                    type: "dropdown",
                                    title: "Välj källa för paketdimensioner",
                                    helpText:
                                        "Ange om paketens dimensioner ska baseras på information från orderraderna eller fraktsedelsraderna i Ongoing.",

                                    options: [
                                        {
                                            label: "Dimensioner från orderrader",
                                            value: "Orderrader",
                                        },
                                        {
                                            label: "Dimensioner från fraktsedelsrader",
                                            value: "Fraktsedelsrader",
                                        },
                                    ],
                                },
                                planId: {
                                    type: "text",
                                    hidden: true,
                                    title: "Prenumerationstyp",
                                    value: plans[0].id,
                                },
                            }}
                        />
                    </div>
                    <div className="buttons">
                        <button
                            className="c-button c-button--raised"
                            disabled={loading}
                            onClick={addOngoingWmsIntegration}
                        >
                            Aktivera
                        </button>
                    </div>
                </>
            )}
            {errorMessage && <div className="error">{errorMessage}</div>}
        </Container>
    );
}

export default Component;
