import moment from "moment";
import Papa from "papaparse";
import { v4 as uuidv4 } from "uuid";

function parse({ data, fileName }) {
    let rows = Papa.parse(data, {
        delimiter: ";",
    });

    if (rows.errors.length) {
        window.alert(
            "Filen är inte korrekt formatterad. Granska (!!!) och skicka till Tobbe om något saknas.\nFelmeddelanden:\n" +
                rows.errors.map((err) => err.message).join("\n"),
        );
    }

    let orders = rows.data.slice(1).map((row) => {
        if (!row[0]) {
            return null;
        }
        let trackingNumber = row[1];
        let transporter = "";
        if (row[14].toUpperCase() === "DHL EXPRESS") {
            transporter = "DHL EXPRESS";
        }
        if (row[14].startsWith("TNT")) {
            transporter = "TNT";
            trackingNumber = trackingNumber.replace(/\D/g, "");
        }
        const isVat = row[15] === "Y";

        const extraRows = [];

        let columnTitleExists = true;
        let columnIndex = 20;

        while (columnTitleExists) {
            const title = rows.data[0][columnIndex];
            if (!title) {
                columnTitleExists = false;
            } else {
                const cost =
                    Math.round(
                        parseFloat(row[columnIndex].replace(",", ".")) * 100,
                    ) / 100;
                if (cost) {
                    extraRows.push({
                        title: title,
                        cost: cost,
                        vat: isVat,
                        surcharge: title === "Fuel",
                        key: uuidv4(),
                        isInsurance: false,
                    });
                }
            }
            columnIndex++;
        }

        extraRows.push({
            title:
                "Fakturerad vikt: " +
                parseFloat(row[12].replace(",", ".")) +
                " kg",
            key: uuidv4(),
        });

        return {
            fileName: fileName,
            transporter,
            trackingNumber,
            reference: row[2],
            currentInvoiceNumber: fileName.split(".")[0],
            date: moment(row[0], "DD-MM-YYYY").format("YYYY-MM-DD"),
            deliveryDate: "",
            title: "Transport",
            sender: row[3],
            senderCountry: row[4],
            recipient: row[5],
            recipientCountry: row[8],
            totalShipmentCost: parseFloat(row[18].replace(",", ".")),
            totalShipmentCostVat: parseFloat(row[18].replace(",", ".")),
            totalShipmentCostNoVat: 0,
            vat: isVat,
            rows: extraRows,
        };
    });

    return orders.filter((v) => v);
}

export default {
    parse,
};
