// Quick messages used in "Snabbtext"
export const quickMessages = [
    {
        title: "Stänga ärendet",
        content: [
            {
                language: "SV",
                text: "Vi ser nu att er förfrågan är åtgärdad och stänger detta ärende. Du kan enkelt öppna ärendet igen, genom att skriva i chatten.\n\nÖnskar er en fin dag!",
            },
            {
                language: "EN",
                text: "We now see that your request has been resolved and close this case. You can easily reopen the case by typing in the chat.\n\nWishing you a nice day!",
            },
        ],
    },
    {
        title: "Bekräfta att vi mottagit ärende",
        content: [
            {
                language: "SV",
                text: "Tack för att ni kontaktar oss, vi undersöker er förfrågan och återkommer till er.\n\nVänligen,",
            },
            {
                language: "EN",
                text: "Thank you for contacting us, we will investigate your request and get back to you.\n\nKindly,",
            },
        ],
    },
    {
        title: "Prisfråga Bakgavellyft",
        content: [
            {
                language: "SV",
                text:
                    "Hej,\n" +
                    "Vi ser att ni har bokat bakgavellift vid lossning.\n" +
                    "Vänligen öppna ärendet om ni behöver mer hjälp eller undrar något.\n" +
                    "Önskar dig en fortsatt fin dag.",
            },
        ],
    },
    {
        title: "UPS",
        content: [
            {
                language: "SV",
                text:
                    "Vad innehåller paketet\n" +
                    "Varans användningsområde\n" +
                    "Varubenämnings/varans namn\n" +
                    "Serie/artikelnummer\n" +
                    "Antal (finns det flera olika varor, antal av varje vara för sig)\n" +
                    "Modell\n" +
                    "Färg/storlek (om detta finns till varan)\n" +
                    "Värde\n" +
                    "Har ni skickat en ersättningsvara?\n" +
                    "Om en ersättningsvara har skickats, vänligen ange sändningsnummer för detta\n" +
                    "Paketets utseende (om möjligt gärna bild)\n" +
                    "Kontaktperson och telefonnummer till avsändaren och mottagaren",
            },
        ],
    },
    {
        title: "Stäng pga spedition",
        content: [
            {
                language: "SV",
                text: "Hej! \n\n Jag stänger detta ärende och återkommer med prisförslag på din mail. Du kan enkelt öppna det igen om du vill.\n\nHa en fin dag.",
            },
            {
                language: "EN",
                text: "Hello! \n\n I'm closing this case now and will get back to you by email with prices. You can easily reopen it if you need to.\n\nHave a nice day.",
            },
        ],
    },
    {
        title: "Bevakning",
        content: [
            {
                language: "SV",
                text: "Hej!\n\n Tack för din bokning!\n\nVi kommer att bevaka den och återkomma vid viktiga scanningar samt avvikelser. Önskar er en fin dag!",
            },
            {
                language: "EN",
                text: "Hello!\n\nThank you for your booking!\n\nWe will monitor it and get back to you in case of important scans and deviations. Wishing you a nice day!",
            },
        ],
    },
    {
        title: "Reklamation Mall",
        content: [
            {
                language: "SV",
                text:
                    "Vänligen komplettera ärendet med följande information: " +
                    "\n\nUppgifter om skada: " +
                    "\n\n - Bilder" +
                    "\n\n - Antal skadade kolli " +
                    "\n\n - Vikt på skadat gods (kg) " +
                    "\n\n - Skadat innehåll" +
                    "\n\n - Artikelnummer/serienummer/referensnummer" +
                    "\n\n - Mått och vikt (längd, bredd, höjd samt specifik vikt per enskilt kolli)" +
                    "\n\n - Var skadan synlig innan avemballering?" +
                    "\n\n - Ytteremballage. Beskrivning av emballaget (material, loggor, tejp, annan markering eller kännetecken)" +
                    "\n\n - Inneremballage" +
                    "\n\n - Godsbeskrivning" +
                    "\n\n - Skadans omfattning" +
                    "\n\n - Värdet på det eftersökta godset, samt något som styrker värdet. T.ex. kvitto, webblänk etc.",
            },
            {
                language: "EN",
                text:
                    "Please provide the following information:" +
                    "\n\nOuter packaging:" +
                    "\n\n - Material" +
                    "\n\n - Color" +
                    "\n\n - Tape" +
                    "\n\n - Logo/Text" +
                    "\n\n - Dimensions" +
                    "\n\n - Weight" +
                    "\n\nInside each package:" +
                    "\n\n - Content" +
                    "\n\n - Product name" +
                    "\n\n - Size" +
                    "\n\n - Color" +
                    "\n\n - Brand" +
                    "\n\n - Article/Serial number" +
                    "\n\n - Material",
            },
        ],
    },
    {
        title: "Godssök",
        content: [
            {
                language: "SV",
                text:
                    "Sökningsärende:" +
                    "\n\nUppgifter:" +
                    "\n\n - Antal kolli:" +
                    "\n\n - Vikt på gods" +
                    "\n\n - Innehåll" +
                    "\n\n - Artikelnummer/serienummmer/referensnummer" +
                    "\n\n - Mått och vikt(längd, bredd, höjd samt specifik vikt per enskilt kolli)" +
                    "\n\n - Artikelnummer/serienummer/referensnummer" +
                    "\n\n - Mått och vikt (längd, bredd, höjd samt specifik vikt per enskilt kolli)" +
                    "\n\n - Emballering" +
                    "\n\n - Ytteremballage. Beskrivning av emballaget (material, loggor, tejp, annan markering eller kännetecken)" +
                    "\n\n - Vad innehåller paketet (färg, storlek, form etc)" +
                    "\n\n - Godsbeskrivning" +
                    "\n\n - *Viktig: Värdet på det eftersökta godset, samt något som styrker värdet. T.ex. kvitto, webblänk etc.",
            },
            {
                language: "EN",
                text:
                    "Please provide the following information:" +
                    "\n\nOuter packaging:" +
                    "\n\n - Material" +
                    "\n\n - Color" +
                    "\n\n - Tape" +
                    "\n\n - Logo/Text" +
                    "\n\n - Dimensions" +
                    "\n\n - Weight" +
                    "\n\nInside each package:" +
                    "\n\n - Content" +
                    "\n\n - Product name" +
                    "\n\n - Size" +
                    "\n\n - Color" +
                    "\n\n - Brand" +
                    "\n\n - Article/Serial number" +
                    "\n\n - Material",
            },
        ],
    },
    {
        title: "Ombokad upphämntning",
        content: [
            {
                language: "SV",
                text: "Hej!\n\nVi har noterat att er leverans inte blivit hämtad enligt bokning, vi har nu bokat en ny upphämtning åt er.\n\nÖnskar er en fin dag!",
            },
            {
                language: "EN",
                text: "Hello!\n\nWe have noticed that your delivery has not been picked up according to booking, we have now booked a new collection for you.\n\nWishing you a nice day!",
            },
        ],
    },
];
