import Loader from "components/Loader/Loader";
import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import { Link, Route, Switch } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import Emissions from "./Emissions";
import Goods from "./Goods";
import Labels from "./Labels";
import Settings from "./Settings";
import Users from "./Users";
import toast from "react-hot-toast";
import ReceiverPays from "./ReceiverPays";

let Information = styled.div`
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > .parameter {
        margin-right: 16px;
        width: 100%;
        max-width: 360px;

        > label {
            font-size: 10px;
            font-weight: bold;
        }

        > .value {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            padding-bottom: 8px;
            margin-bottom: 4px;
            font-size: 20px;
            min-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;

let Container = styled.div`
	width: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	box-sizing: border-box;

	> .menu {
		display: flex;
		width: 100%;
		gap: 2rem;
		justify-content: center;
		padding: 2rem 0;
		font-size: 2rem;

		> .item {
			color: #999;
			font-style: none;
			text-decoration: none;

			&.is-selected {
				color: #000;
			}
		}
	}

	> .section {
		padding: 1rem;
	}

	> .loader {
		width: 200px;
		height: 200px;
	}

	> table {
		> thead {
			> tr {
				> th:first-child {
					padding-left: 8px;
				}
			}
		}
		> tbody {
			> tr {
				&:hover {
					td {
  						background: rgba(0,0,0,.03);
					}
				}

				td:first-child {
					border-radius: 5px 0 0 5px;
					padding-left: 8px;
				}
				td:last-child {
					text-align: right;
					border-radius: 0 5px 5px 0;
				}
			}
		}
	}

	> .buttons {
		padding: 16px 0;
	}

	> .products {
		padding: 16px;
		border: 1px solid rgba(0,0,0,.1);
		border-radius: 2px;
		background: #fff;
		margin-bottom: 16px;
		display: flex;
		flex-direction: column;

		table {
			thead {
				th {
					font-weight: normal;
					padding: 4px 16px;
				}
			}

			tbody {
				th {
					font-weight: normal;
				}
				.product {
					th {
						width: 100%
						padding: 4px 16px;
					}

					th, td {
						&:first-child {
							border-radius: 5px 0 0 5px;
						}
						&:last-child {
							border-radius: 0 5px 5px 0;
						}
					}

					.input {
						padding: 4px 16px;
					}

					&:hover {
						th, td {
							background: rgba(0,0,0,.05);
						}
					}
				}
			}
		}
	}
`;

let Title = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.6);
`;

function Component(props) {
    const context = useContext(Context);
    const [customer, setCustomer] = useState(null);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        loadCustomer();
    }, []);

    function loadCustomer() {
        Api.getCustomer({
            customerId: context.user.customer.id,
        })
            .then((customer) => {
                setCustomer(customer);
            })
            .catch(() => {
                toast.error(
                    "Kunde inte ladda kunduppgifter, försök igen eller kontakta support.",
                );
            });
    }

    if (!customer) {
        return (
            <Container>
                <div className="loader">
                    <Loader />
                </div>
            </Container>
        );
    } else {
        return (
            <Container>
                <div className="menu">
                    <Link
                        to="/account"
                        className={`item ${
                            props.history.location.pathname === "/account"
                                ? "is-selected"
                                : ""
                        }`}
                    >
                        Mitt konto
                    </Link>
                    <Link
                        to="/account/users"
                        className={`item ${
                            props.history.location.pathname === "/account/users"
                                ? "is-selected"
                                : ""
                        }`}
                    >
                        Användare
                    </Link>
                    <Link
                        to="/account/labels"
                        className={`item ${
                            props.history.location.pathname ===
                            "/account/labels"
                                ? "is-selected"
                                : ""
                        }`}
                    >
                        Etiketter
                    </Link>
                    <Link
                        to="/account/goods"
                        className={`item ${
                            props.history.location.pathname === "/account/goods"
                                ? "is-selected"
                                : ""
                        }`}
                    >
                        Godstyper
                    </Link>
                    {context.user.customer.settings.emissions && (
                        <Link
                            to="/account/emissions"
                            className={`item ${
                                props.history.location.pathname ===
                                "/account/emissions"
                                    ? "is-selected"
                                    : ""
                            }`}
                        >
                            Miljö
                        </Link>
                    )}
                    <Link
                        to="/account/receiver-pay"
                        className={`item ${
                            props.history.location.pathname ===
                            "/account/receiver-pay"
                                ? "is-selected"
                                : ""
                        }`}
                    >
                        Mottagarfrakt
                    </Link>
                </div>
                <Switch>
                    <Route exact path="/account" component={Settings} />
                    <Route
                        exact
                        path="/account/users"
                        render={() => {
                            return (
                                <Users
                                    users={customer.users}
                                    customerId={customer.id}
                                />
                            );
                        }}
                    />
                    <Route
                        exact
                        path="/account/labels"
                        render={() => {
                            return (
                                <Labels
                                    customer={customer}
                                    onUpdate={loadCustomer}
                                />
                            );
                        }}
                    />
                    <Route exact path="/account/goods" component={Goods} />
                    <Route
                        exact
                        path="/account/emissions"
                        component={Emissions}
                    />
                    <Route
                        exact
                        path="/account/receiver-pay"
                        component={ReceiverPays}
                    />
                </Switch>
            </Container>
        );
    }
}

export default Component;
