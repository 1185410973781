import Input from "components/OldInput";
import React, { useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 1rem;

    > .tabs {
        display: flex;

        > .tab {
            background: #ddd;
            border: 1px solid #aaa;
            border-right: 0;
            margin-bottom: -1px;
            padding: 0.5rem 1rem;
            z-index: 2;
            cursor: pointer;
            display: flex;
            align-items: center;

            &:last-child {
                border-right: 1px solid #aaa;
            }

            &:hover {
                background: #eee;
            }

            &.is-selected {
                background: #fff;
                border-bottom: 0;
            }

            > .remove {
                margin-left: 1rem;
                width: 16px;
                height: 16px;

                > svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        > .add {
            padding: 0.5rem 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-left: 1px solid #aaa;
            cursor: pointer;
            color: #000;

            &:hover {
                color: #555;
            }

            &:first-child {
                border-left: 0;
            }

            > svg {
                height: 80%;
            }
        }
    }

    > .content {
        background: #fff;
        border: 1px solid #aaa;
        padding: 1rem 0.5rem;

        > .wrapper {
            display: none;
            grid-template-columns: min-content min-content min-content min-content;
            align-items: center;
            column-gap: 8px;
            row-gap: 8px;
            width: auto;

            &.is-selected {
                display: grid;
            }

            > .cell {
                white-space: nowrap;

                &.title {
                    font-weight: bold;
                }
            }
        }
    }

    > .buttons {
        padding: 1rem 0;
    }
`;

function View({ brandId }) {
    const [pricelists, setPricelists] = useState(null);
    const [products, setProducts] = useState(null);
    const [selectedPricelistIndex, setSelectedPricelistIndex] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadPricelists();
    }, []);

    function updatePricelists(
        pricelistIndex,
        changedProduct,
        parameter,
        value,
    ) {
        setPricelists((pricelists) => {
            return pricelists.map((pricelist, index) => {
                if (pricelistIndex === index) {
                    return {
                        ...pricelist,
                        products: pricelist.products.map((product) => {
                            if (product.name === changedProduct.name) {
                                return {
                                    name: product.name,
                                    partnerMultiplier:
                                        parameter === "partnerMultiplier"
                                            ? value
                                            : product.partnerMultiplier,
                                    customerMultiplier:
                                        parameter === "customerMultiplier"
                                            ? value
                                            : product.customerMultiplier,
                                    enabled:
                                        parameter === "enabled"
                                            ? value
                                            : product.enabled,
                                };
                            }
                            return product;
                        }),
                    };
                }
                return pricelist;
            });
        });
    }

    useEffect(() => {
        if (
            pricelists &&
            !pricelists[selectedPricelistIndex] &&
            pricelists.length
        ) {
            setSelectedPricelistIndex(pricelists.length - 1);
        }
    }, [pricelists]);

    async function loadPricelists() {
        setPricelists(
            await Api.getBrandPricelists({
                brandId,
            }),
        );
        setProducts(await Api.getProducts());
        setLoading(false);
    }

    async function save() {
        setLoading(true);
        await Api.updatePricelists({
            brandId,
            pricelists,
        });
        setLoading(false);
    }

    function addPricelist() {
        let pricelistName = window.prompt("Namn på prislistan", "");
        if (pricelistName) {
            setPricelists(
                [].concat(pricelists).concat({
                    name: pricelistName,
                    products: products.map((product) => {
                        return {
                            name: product.name,
                            customerMultiplier: 0,
                            partnerMultiplier: 0,
                            enabled: false,
                        };
                    }),
                }),
            );
        }
    }

    function removePricelist(index) {
        let newList = [].concat(pricelists);
        newList.splice(index, 1);
        setPricelists(newList);
    }

    return (
        <Container>
            <h2>Prislistor</h2>
            <div className="tabs">
                {pricelists?.map((pricelist, index) => {
                    return (
                        <div
                            key={index}
                            className={
                                "tab" +
                                (index === selectedPricelistIndex
                                    ? " is-selected"
                                    : "")
                            }
                            onClick={() => {
                                setSelectedPricelistIndex(index);
                            }}
                        >
                            <div className="name">{pricelist.name}</div>
                            <div
                                className="remove"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    removePricelist(index);
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"
                                    />
                                </svg>
                            </div>
                        </div>
                    );
                })}
                <div className="add" onClick={addPricelist}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"
                        />
                    </svg>
                </div>
            </div>
            <div className="content">
                {pricelists?.map((pricelist, index) => {
                    return (
                        <div
                            className={
                                "wrapper" +
                                (index === selectedPricelistIndex
                                    ? " is-selected"
                                    : "")
                            }
                        >
                            <div className="cell title"></div>
                            <div className="cell title">Partner</div>
                            <div className="cell title">Kund</div>
                            <div className="cell title">Aktiverad</div>
                            {pricelist.products.map((product) => {
                                return (
                                    <>
                                        <div className="cell">
                                            {product.name}
                                        </div>
                                        <div className="cell">
                                            <Input
                                                onChange={(value) => {
                                                    updatePricelists(
                                                        index,
                                                        product,
                                                        "partnerMultiplier",
                                                        value,
                                                    );
                                                }}
                                                type="number"
                                                unit="%"
                                                value={
                                                    product.partnerMultiplier
                                                }
                                            />
                                        </div>
                                        <div className="cell">
                                            <Input
                                                onChange={(value) => {
                                                    updatePricelists(
                                                        index,
                                                        product,
                                                        "customerMultiplier",
                                                        value,
                                                    );
                                                }}
                                                type="number"
                                                unit="%"
                                                value={
                                                    product.customerMultiplier
                                                }
                                            />
                                        </div>
                                        <div className="cell">
                                            <Input
                                                onChange={(value) => {
                                                    updatePricelists(
                                                        index,
                                                        product,
                                                        "enabled",
                                                        value,
                                                    );
                                                }}
                                                type="toggle"
                                                value={product.enabled}
                                            />
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            <div className="buttons">
                <button
                    className="c-button c-button--raised"
                    disabled={loading}
                    onClick={save}
                >
                    Spara prislistor
                </button>
            </div>
        </Container>
    );
}

export default View;
