import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import ApprovedTerms from "./ApprovedTerms";
import ActivateReceiverPays from "./ActivateReceiverPays";
import ReceiverPaysInput from "./ReceiverPaysInput";
import ReceiverPaysTable from "./ReceiverPaysTable";

const ReceiverPays = () => {
    const context = useContext(Context);
    const userSettings = context.user.customer.settings;
    const [isActivated, setIsActivated] = useState(false);
    const [refreshContacts, setRefreshContacts] = useState(false);

    useEffect(() => {
        if (
            (userSettings.receiverPays &&
                !userSettings.receiverPays.subscriptionEndsAt) ||
            new Date() < new Date(userSettings.receiverPays?.subscriptionEndsAt)
        ) {
            setIsActivated(true);
        }
    }, [userSettings.receiverPays]);

    return (
        (!isActivated && <ActivateReceiverPays />) || (
            <div className="ml-8">
                <ApprovedTerms />
                <p className="text-2xl mt-16">
                    Registrera mottagares fraktavtal
                </p>
                <div className="w-1/5">
                    <ReceiverPaysInput refreshContacts={refreshContacts} />
                </div>
                <p className="text-2xl mt-16 mb-4">Dina registrerade val</p>
                <div className="mr-8">
                    <ReceiverPaysTable
                        setRefreshContacts={setRefreshContacts}
                    />
                </div>
            </div>
        )
    );
};

export default ReceiverPays;
