import Input from "components/OldInput";
import axios from "axios";
import React, { Component } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    width: 100%;
    box-sizing: border-box;
    outline: none;
    min-height: 40px;

    > .inputContainer {
        width: 100%;
        position: relative;

        > .backdrop {
            opacity: 0;
            position: fixed;
            z-index: 10;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        > .searchResult {
            max-height: 50vh;
            overflow-y: auto;
            position: absolute;
            opacity: 0;
            pointer-events: none;
            width: 100%;
            background: #fff;
            box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.3);
            border-radius: 5px;
            top: 100%;
            padding-bottom: 8px;
            z-index: 10;

            > .title {
                padding: 4px 8px;
                color: rgba(0, 0, 0, 0.5);
                font-weight: bold;
                font-size: 14px;
            }

            > .item {
                padding: 4px 8px;
                cursor: pointer;

                &.is-selected {
                    background: rgba(0, 0, 0, 0.04);
                }

                &:hover {
                    background: rgba(0, 0, 0, 0.02);
                }
            }

            &.is-visible {
                opacity: 1;
                pointer-events: all;
            }
        }

        > .emptySearchResult {
            max-height: 50vh;
            overflow-y: auto;
            position: absolute;
            opacity: 0;
            pointer-events: none;
            width: 100%;
            background: #fff;
            box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.3);
            border-radius: 5px;
            top: 100%;
            padding-bottom: 8px;
            z-index: 10;

            > .text {
                padding: 4px 8px;
                font-size: 0.8rem;
            }

            &.is-visible {
                opacity: 1;
                pointer-events: all;
            }
        }

        > input {
            padding: 4px;
            border: none;
            outline: none;
            width: 100%;
        }
    }

    .countryContainer {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        height: 100%;

        .form-group {
            margin-bottom: 0;

            .dropdown {
                width: 60px;
                min-width: 60px;
                letter-spacing: -1em;

                .c-select {
                    color: rgba(0, 0, 0, 0);
                    border: 0;
                }

                .dropdown-toggle::after {
                    color: rgba(0, 0, 0, 0.4);
                }

                .c-dropdown-menu-overflow {
                    letter-spacing: 0em;
                }
            }
        }
    }

    > .message {
        font-weight: bold;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
    }

    &.is-invalid {
        > .inputContainer {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }

        > .message {
            color: rgba(255, 0, 0, 0.6);
        }
    }
`;

class InputField extends Component {
    postCodeInputRef = React.createRef();
    cancelSearchToken = React.createRef();
    searchRequestHandle = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            value: props.value || "",
            countryCode: props.countryCode || "",
            searchResult: null,
            autoComplete: props.autoComplete,
            disabled: props.disabled,
            name: props.name,
            selectedIndex: null,
        };

        if (typeof this.props.onKeyUp === "function") {
            this.onKeyUp = this.props.onKeyUp;
        }
        this.handleSearch = this.handleSearch.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.close = this.close.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            countryCode: props.countryCode,
            disabled: props.disabled,
        };
    }

    onChange() {
        this.setState(
            {
                value: this.postCodeInputRef.current.value(),
            },
            () => {
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this.state.value);
                }
            },
        );
    }

    onKeyUp() {}
    handleInputChange(newValue) {
        const inputValue = newValue.replace(/[^a-zA-Z0-9-]/g, "");
        this.setState({
            value: inputValue,
        });
        return inputValue;
    }

    value() {
        return this.state.value;
    }

    set(value) {
        this.setState({
            value: value,
        });
        this.postCodeInputRef.current.set(value);
    }

    empty() {
        this.setState({
            value: "",
        });
        this.postCodeInputRef.current.empty();
    }

    setValidationMessage(message) {
        this.postCodeInputRef.current.setValidationMessage(message);
    }

    validate() {
        if (this.props.required && this.state.value.length === 0) {
            this.setState({
                isInvalid: true,
            });
            return false;
        }
        this.setState({
            isInvalid: false,
        });
        return true;
    }

    handleSearch(inputValue) {
        clearTimeout(this.searchRequestHandle.current);
        if (this.cancelSearchToken.current) {
            this.cancelSearchToken.current.cancel();
            this.cancelSearchToken.current = null;
        }

        if (inputValue.length < 3) {
            this.setState({ value: inputValue, searchResult: null });
            return;
        }

        this.onChange(inputValue);
        this.setState({ searchResult: null });

        this.searchRequestHandle.current = setTimeout(() => {
            this.cancelSearchToken.current = axios.CancelToken.source();

            Api.searchPostalTown({
                cancelToken: this.cancelSearchToken.current.token,
                countryCode: this.state.countryCode,
                postalCode: inputValue.replace(/[^a-zA-Z0-9-]/g, ""),
            })
                .then((results) => {
                    if (results) {
                        const data = results.map((result) => ({
                            title:
                                result.postalTown +
                                (result.stateCode
                                    ? ", " + result.stateCode
                                    : ""),
                            value: {
                                postalTown: result.postalTown,
                                stateCode: result.stateCode,
                            },
                        }));

                        this.setState({
                            value: inputValue,
                            searchResult: data,
                        });
                    }
                })
                .catch((err) => {
                    // Cancel error appear here which is expected. Maybe catch other errors?
                });
        }, 1500); // Increase time between searches to avoid too many requests.
    }

    handleKeyPress(ev) {
        switch (ev.key) {
            case "Enter": {
                if (this.state.selectedIndex !== null) {
                    this.selectItem(
                        this.state.searchResult[this.state.selectedIndex],
                    );
                    ev.preventDefault();
                }
                break;
            }
            case "Escape": {
                this.close();
                break;
            }
        }
    }

    handleKeyUp(ev) {
        switch (ev.key) {
            case "ArrowDown": {
                if (this.state.selectedIndex === null) {
                    this.setState({
                        selectedIndex: 0,
                    });
                } else {
                    let nextIndex = this.state.selectedIndex + 1;
                    if (nextIndex >= this.state.searchResult.length) {
                        nextIndex = this.state.selectedIndex;
                    }
                    this.setState({
                        selectedIndex: nextIndex,
                    });
                }
                break;
            }
            case "ArrowUp": {
                if (this.state.selectedIndex === null) {
                    this.setState({
                        selectedIndex: this.state.searchResult.length - 1,
                    });
                } else {
                    let nextIndex = this.state.selectedIndex - 1;
                    if (nextIndex < 0) {
                        nextIndex = 0;
                    }
                    this.setState({
                        selectedIndex: nextIndex,
                    });
                }
                break;
            }
        }
    }

    selectItem(item) {
        if (typeof this.props.onPostalTownSelected === "function") {
            this.props.onPostalTownSelected(item);
        }
        this.setState({
            searchResult: null,
            selectedIndex: null,
        });
    }

    close() {
        this.setState({
            searchResult: null,
            selectedIndex: null,
        });
    }

    render() {
        return (
            <Container className={this.state.isInvalid ? " is-invalid" : ""}>
                <div className="inputContainer">
                    {parseInt(this.state.searchResult?.length) > 0 && (
                        <div className="backdrop" onClick={this.close}></div>
                    )}
                    <div
                        className={
                            "searchResult" +
                            (parseInt(this.state.searchResult?.length) > 0
                                ? " is-visible"
                                : "")
                        }
                    >
                        <div className="title">Välj en postort</div>
                        {this.state.searchResult?.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={
                                        "item" +
                                        (index === this.state.selectedIndex
                                            ? " is-selected"
                                            : "")
                                    }
                                    onClick={() => {
                                        this.selectItem(item);
                                    }}
                                >
                                    {item.title}
                                </div>
                            );
                        })}
                    </div>
                    {parseInt(this.state.searchResult?.length) === 0 && (
                        <div className="backdrop" onClick={this.close}></div>
                    )}
                    <div
                        className={
                            "emptySearchResult" +
                            (this.state.searchResult?.length === 0
                                ? " is-visible"
                                : "")
                        }
                    >
                        <div className="text">
                            Vi hittade inga matchande postorter i det här
                            landet. Verifiera att uppgifterna stämmer och skriv
                            in postorten manuellt om det behövs.
                        </div>
                    </div>
                    <Input
                        ref={this.postCodeInputRef}
                        disabled={this.state.disabled}
                        autoComplete={this.state.autoComplete}
                        name={this.state.name}
                        value={this.state.value}
                        type="text"
                        onKeyPress={this.handleKeyPress}
                        onKeyUp={this.handleKeyUp}
                        onChange={this.handleSearch}
                    />
                </div>
            </Container>
        );
    }
}

export default InputField;
