import React, { Component } from "react";
import InputList from "./List/InputList";
import InputTable from "./Table";
import InputText from "./Text";
import InputCheckbox from "./Checkbox";
import InputEmail from "./Email";
import InputNumber from "./Number";
import InputDate from "./Date";
import InputTime from "./Time/InputTime";
import InputTextarea from "./Textarea/InputTextarea";
import InputToggle from "./Toggle/InputToggle";
import InputPassword from "./Password/InputPassword";
import InputDropdown from "./Dropdown/InputDropdown";

class Input extends Component {
    constructor(props) {
        super(props);
        this.input = props?.textAreaRef || React.createRef();

        let inputType = "";

        this.state = {
            options: props.options ? props.options : props,
            value: props.value,
        };

        if (typeof this.props.onChange === "function") {
            this.onChange = this.props.onChange;
        }
    }

    onChange() {}

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            options: nextProps.options ? nextProps.options : nextProps,
            value: nextProps.value,
        };
    }

    fadeOutValue() {
        return this.input.current.fadeOutValue();
    }

    fadeInValue() {
        return this.input.current.fadeInValue();
    }

    set(value) {
        return this.input.current.set(value);
    }

    empty() {
        return this.input.current.empty();
    }

    value() {
        return this.input.current.value();
    }

    select() {
        return this.input.current.select();
    }

    validate() {
        return this.input.current.validate();
    }

    setValidationMessage(options) {
        if (this.input.current.setValidationMessage) {
            return this.input.current.setValidationMessage(options);
        }
    }

    addItem() {
        return this.input.current.addItem();
    }

    addItems() {
        return this.input.current.addItems();
    }

    disable() {
        return this.input.current.disable();
    }

    enable() {
        return this.input.current.enable();
    }

    focus(data) {
        return this.input.current.focus(data);
    }

    onBlur() {
        return this.input.current.onBlur();
    }

    render() {
        let components = {
            Text: InputText,
            Checkbox: InputCheckbox,
            Email: InputEmail,
            Number: InputNumber,
            Date: InputDate,
            Time: InputTime,
            Textarea: InputTextarea,
            Toggle: InputToggle,
            List: InputList,
            Table: InputTable,
            Password: InputPassword,
            Dropdown: InputDropdown,
        };
        let inputType;
        let Type;

        if (typeof this.props.type === "string") {
            inputType =
                this.props.type.charAt(0).toUpperCase() +
                this.props.type.slice(1);
            Type = components[inputType];
        } else {
            Type = this.props.type;
        }

        return (
            <div
                className={
                    "Input" +
                    (this.props.className ? " " + this.props.className : "")
                }
            >
                <Type
                    ref={this.input}
                    {...this.props}
                    value={this.state.value}
                    onChange={() => {
                        this.onChange(this.value());
                    }}
                />
            </div>
        );
    }
}

export default Input;
