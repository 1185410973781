import Loader from "components/Loader/Loader";
import Input from "components/OldInput";
import OrderState from "constants/orderState";
import Context from "context/Global";
import { match } from "helpers/Match";
import moment from "moment";
import "moment/locale/sv";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import PickupInformation from "./PickupInformation";
import RawData from "./RawData";
import Summary from "./Summary";
import Timeline from "./Timeline";
import { handleDownloadS3 } from "../../../utils/s3";
import HelpText from "../List/helptext";
import { withCreateDraftsStore } from "./withCreateDraftsStore";
import InformationHover from "components/InformationHover";

let Card = styled.div`
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background: #fff;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    align-items: flex-start;

    &.has-warning {
        background: rgba(255, 200, 0, 0.2);
        color: rgba(180, 80, 0, 1);
        font-weight: bold;
    }

    &.has-information {
        background: rgba(100, 200, 255, 0.2);
        color: rgba(40, 80, 180, 1);
        font-weight: bold;
    }
`;

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 8px;
    box-sizing: border-box;
    max-width: 1000px;

    .track {
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        gap: 1rem;

        > a {
            text-decoration: none;
            color: #fff;

            :hover {
                color: #888;
            }
        }
    }

    > .loader {
        width: 200px;
        height: 200px;
    }

    > .cancelled {
        padding: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        align-items: flex-start;
        background: rgba(100, 200, 255, 0.2);
        color: rgba(40, 80, 180, 1);
        font-weight: bold;
    }

    > .exception {
        padding: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        display: flex;
        flex-direction: row;
        margin-bottom: 16px;
        align-items: center;
        background: rgba(255, 200, 100, 0.2);
        color: rgba(180, 80, 40, 1);
        font-weight: bold;

        > svg {
            height: 32px;
            width: 32px;
            margin-right: 32px;
        }
    }

    > .message {
        padding: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        display: flex;
        flex-direction: row;
        margin-bottom: 16px;
        align-items: center;
        color: rgba(180, 80, 40, 1);
        font-weight: bold;

        &.is-information,
        &.is-info {
            background: rgba(200, 200, 255, 0.2);
            color: rgba(80, 80, 180, 1);
        }

        &.is-error {
            background: rgba(255, 200, 100, 0.2);
        }

        &.is-warning {
            background: rgba(255, 200, 100, 0.2);
        }

        > .text {
            margin-right: 8px;
        }

        > svg {
            height: 32px;
            width: 32px;
            margin-right: 32px;
        }
    }

    .pickupContainer {
        display: flex;
        align-items: center;
        width: 100%;

        > .icon {
            margin-right: 32px;

            > img {
                height: 32px;
                width: 32px;
            }

            > svg {
                height: 32px;
                width: 32px;
            }
        }

        > .bookPickup {
            margin-left: auto;
        }
    }

    .informationContainer {
        display: flex;
        align-items: center;

        > .information {
            display: flex;
            flex-direction: column;

            > .state {
                font-size: 26px;
                color: rgba(0, 0, 0, 0.8);
                font-weight: 500;

                &.is-delivered {
                    color: var(--color-good);
                }

                &.is-warning {
                    color: var(--color-bad);
                }

                &.is-rejected {
                    color: var(--color-bad);
                }

                &.is-intransit {
                    color: rgba(0, 80, 180, 1);
                }

                &.is-loading {
                    opacity: 0.5;
                    transition: opacity 0.3s ease;
                }
            }
        }

        > .reload {
            width: 32px;
            height: 32px;
            cursor: pointer;
            margin-left: 32px;
            display: flex;
            justify-content: center;
            align-items: center;

            &.is-loading {
                transform: rotateZ(-180deg);
                transition: all 1s ease;
            }

            > svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    > .aggregate {
        margin-top: 16px;
    }

    > .images {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 32px 0;

        > img {
            max-height: 600px;
            object-fit: contain;
            object-position: top;
        }
    }

    > .subTitle {
        font-size: 20px;
        margin-bottom: 8px;
        color: rgba(0, 0, 0, 0.6);
    }

    > .tickets {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;

        > .createTicket {
            padding: 1rem;
        }

        > .ticket {
            display: flex;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid #ddd;

            &:last-child {
                border-bottom: 0;
            }

            > a {
                display: flex;
                text-decoration: none;
                color: #000;
                height: 100%;
                width: 100%;
                padding: 1rem;
                align-items: center;
                gap: 1rem;

                &:hover {
                    background: rgba(0, 0, 0, 0.05);
                }

                > .id {
                    font-size: 1.8rem;
                    font-weight: bold;
                    white-space: nowrap;
                }

                > .title {
                    font-size: 1.2rem;
                    margin-right: auto;
                }

                > .created {
                    white-space: nowrap;
                }
            }
        }
    }

    > .documents {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;

        > .emptyState {
            padding: 1rem 1rem 0;
        }

        > .document {
            display: flex;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid #ddd;
            padding: 1rem;

            > .checkbox {
                padding: 0.6rem;
            }

            > .name {
                font-size: 1.2rem;
                margin: 0 1rem;
                width: 20%;
                min-width: 20%;
            }

            > .description {
            }
        }

        .print {
            width: 100%;
            padding: 1rem;

            > .c-button {
                display: inline-flex;
                align-items: center;

                > svg {
                    margin-right: 1rem;
                }
            }
        }

        > .item {
            display: flex;
            flex-direction: row;
            padding: 4px 8px 4px 2px;
            cursor: pointer;
            align-items: center;
            border-radius: 4px;
            color: rgba(0, 0, 0, 0.8);
            text-decoration: none;

            &:hover {
                background: rgba(0, 0, 0, 0.05);
            }

            > .title {
                font-size: 16px;
                padding-right: 32px;
                margin-right: auto;
            }

            > .tag {
                border: 1px solid rgba(0, 0, 0, 0.2);
                padding: 0em 1em;
                font-size: 0.9em;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 1em;
                background: #fff;
                border-radius: 3px;
            }
        }
    }

    > .documentsOld {
        > .list {
            display: flex;
            flex-direction: column;
            width: 100%;

            > .item {
                display: flex;
                flex-direction: row;
                padding: 4px 8px 4px 2px;
                cursor: pointer;
                align-items: center;
                border-radius: 4px;
                color: rgba(0, 0, 0, 0.8);
                text-decoration: none;

                &:hover {
                    background: rgba(0, 0, 0, 0.05);
                }

                > .icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 4px;
                    width: 24px;
                    height: 24px;
                }

                > .title {
                    font-size: 16px;
                    padding-right: 32px;
                    margin-right: auto;
                }

                > .tag {
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    padding: 0em 1em;
                    font-size: 0.9em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 1em;
                    background: #fff;
                    border-radius: 3px;
                }

                > .timestamp {
                    font-size: 16px;
                }
            }
        }
    }

    > .buttons {
        padding: 16px 0;
    }
`;

class ViewOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            reloadingState: false,
            rawData: null,
            cancellingShipment: false,
            stateLevel: 0,
            lineWidth: `100%`,
            print: {
                labels: false,
                manifest: false,
                cmr: false,
                customsInvoice: false,
                insurance: false,
            },
            selectedForPrint: {
                labels: false,
                manifest: false,
                cmr: false,
                customsInvoice: false,
                insurance: false,
            },
            noDocumentsSelected: true,
            etd: {
                customsInvoice: false,
            },
        };

        this.confirm = this.confirm.bind(this);
        this.loadOrder = this.loadOrder.bind(this);
        this.printDocuments = this.printDocuments.bind(this);
        this.togglePrint = this.togglePrint.bind(this);
        this.cancelShipment = this.cancelShipment.bind(this);
    }

    async componentDidMount() {
        let order = await this.loadOrder({ firstTime: true });
        if (
            order &&
            ["SUPPORT", "OWNER", "CLIENT_MANAGER"].indexOf(
                this.context.user.role,
            ) >= 0
        ) {
            this.setState({
                rawData: await Api.getOrderRawTransactionData({
                    orderId: this.props.match.params.orderId,
                }),
            });
        }
    }

    async loadOrder({ firstTime = null } = {}) {
        try {
            let order = await Api.getOrder({
                orderId: this.props.match.params.orderId,
            });
            let zebraFiles = order.documents.filter((document) => {
                return document.title.indexOf("Zebra") === 0;
            });

            order.documents = order.documents.filter((document) => {
                return document.title.indexOf("Zebra") !== 0;
            });

            if (firstTime) {
                let printLabels = !!order.documents.find((document) => {
                    return document.type === "LABEL";
                });
                let printAttachments = !!order.documents.find((document) => {
                    return document.type === "ATTACHMENT";
                });
                let printManifest = !!order.documents.find((document) => {
                    return document.type === "MANIFEST";
                });
                let printCMR = !!order.documents.find((document) => {
                    return document.type === "CMR";
                });
                let printCustomsInvoice = !!order.documents.find((document) => {
                    return document.type === "CUSTOMS_INVOICE";
                });
                let printInsurance = !!order.documents.find((document) => {
                    return document.type === "INSURANCE";
                });
                let printPickupNote = !!order.documents.find((document) => {
                    return document.type === "PICKUP_NOTE";
                });
                let printGuarantee = !!order.documents.find((document) => {
                    return document.type === "GUARANTEE";
                });
                let printConsignmentNote = !!order.documents.find(
                    (document) => {
                        return document.type === "CONSIGNMENT_NOTE";
                    },
                );
                let customsInvoiceEtd = !!order.documents.find((document) => {
                    return (
                        document.type === "CUSTOMS_INVOICE" &&
                        document.metadata?.etd
                    );
                });

                this.setState({
                    order: order,
                    loading: false,
                    cancellingShipment: false,
                    zebraFiles: zebraFiles,
                    noDocumentsSelected: !(
                        printLabels ||
                        printGuarantee ||
                        printPickupNote ||
                        printAttachments ||
                        printManifest ||
                        printCMR ||
                        printCustomsInvoice ||
                        printInsurance ||
                        printConsignmentNote
                    ),
                    print: {
                        labels: printLabels,
                        guarantee: printGuarantee,
                        pickupNote: printPickupNote,
                        attachments: printAttachments,
                        manifest: printManifest,
                        cmr: printCMR,
                        customsInvoice: printCustomsInvoice,
                        insurance: printInsurance,
                        consignmentNote: printConsignmentNote,
                    },
                    selectedForPrint: {
                        labels: printLabels,
                        guarantee: printGuarantee,
                        pickupNote: printPickupNote,
                        attachments: printAttachments,
                        manifest: printManifest,
                        cmr: printCMR,
                        customsInvoice:
                            printCustomsInvoice && !customsInvoiceEtd,
                        insurance: printInsurance,
                        consignmentNote: printConsignmentNote,
                    },
                    etd: {
                        customsInvoice: customsInvoiceEtd,
                    },
                });
            } else {
                this.setState({
                    order: order,
                });
            }

            return order;
        } catch (error) {
            this.props.history.replace("/orders");
        }
    }

    confirm(ev) {
        Api.confirmOrder({
            orderId: this.props.match.params.orderId,
        })
            .then((response) => {
                this.loadOrder();
            })
            .catch((response) => {
                console.error("Failed to confirm order");
                this.loadOrder();
            });

        ev.preventDefault();
        return false;
    }

    printDocuments() {
        const documentsToPrint = Object.keys(this.state.selectedForPrint)
            .map((key) => {
                if (this.state.selectedForPrint[key]) {
                    return key;
                }
                return null;
            })
            .filter((v) => !!v)
            .join(",");
        var w = window.open(
            `${process.env.REACT_APP_API_URL || ""}/api/orders/${
                this.state.order.id
            }/print/documents?accessToken=${Api.getAuthenticationToken()}&documents=${documentsToPrint}`,
            "PRINT",
            "title=Etiketter,height=800,width=800",
        );
        w.document.title = "Fraktdokument";
    }

    getDocumentType(type) {
        return (
            match(type, {
                LABEL: () => "Etiketter",
            }) || type
        );
    }

    getPrintIcon() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    d="M16 18h-8v-1h8v1zm-2 1h-6v1h6v-1zm10-14v13h-4v6h-16v-6h-4v-13h4v-5h16v5h4zm-18 0h12v-3h-12v3zm12 10h-12v7h12v-7zm4-8h-20v9h2v-3h16v3h2v-9zm-1.5 1c-.276 0-.5.224-.5.5s.224.5.5.5.5-.224.5-.5-.224-.5-.5-.5z"
                />
            </svg>
        );
    }

    togglePrint(type, value) {
        let selectedForPrint = { ...this.state.selectedForPrint };

        selectedForPrint[type] = value;

        let noDocumentsSelected = !Object.keys(selectedForPrint).find((key) => {
            return selectedForPrint[key];
        });

        this.setState({
            selectedForPrint: selectedForPrint,
            noDocumentsSelected,
        });
    }

    async cancelShipment() {
        if (
            !window.confirm("Är du säker på att du vill avboka försändelsen?")
        ) {
            return;
        }

        try {
            this.setState({
                cancellingShipment: true,
            });

            if (
                ["FEDEX", "UPS", "DHL EXPRESS"].indexOf(
                    this.state.order.service.name.toUpperCase(),
                ) >= 0
            ) {
                await Api.cancelShipment({
                    orderId: this.state.order.id,
                });
                this.loadOrder();
                return;
            } else {
                if (!this.context.socket.connected) {
                    alert(
                        `Ett fel uppstod vid avbokning. Ring ${this.context.user.customer.brand.supportPhoneNumber} så hjälper vi dig!`,
                    );
                    return;
                }
                if (!this.state.order?.pickup?.confirmationNumber) {
                    await Api.cancelShipment({
                        orderId: this.state.order.id,
                    });
                    this.loadOrder();
                    return;
                }
                if (
                    this.state.order.pickupDate &&
                    this.state.order.pickupDate < moment().format("YYYY-MM-DD")
                ) {
                    await Api.cancelShipment({
                        orderId: this.state.order.id,
                    });
                    this.loadOrder();
                    return;
                }
                if (
                    !this.state.order.pickupDate &&
                    moment(this.state.order.created).format("YYYY-MM-DD") <
                        moment().subtract(2, "days").format("YYYY-MM-DD")
                ) {
                    await Api.cancelShipment({
                        orderId: this.state.order.id,
                    });
                    this.loadOrder();
                    return;
                }

                this.context.socket.emit(
                    "createTicket",
                    {
                        title: `Avbokning ${this.state.order.trackingNumber}`,
                        type: "OTHER",
                        trackingNumber: this.state.order.trackingNumber,
                        data: {},
                    },
                    async (newTicket) => {
                        if (newTicket) {
                            await Api.cancelShipment({
                                orderId: this.state.order.id,
                            });
                            this.loadOrder();
                        } else {
                            alert(
                                `Ett fel uppstod vid avbokning. Ring ${this.context.user.customer.brand.supportPhoneNumber} så hjälper vi dig!`,
                            );
                            this.setState({
                                cancellingShipment: false,
                            });
                        }
                    },
                );
            }
        } catch (error) {
            this.setState({
                cancellingShipment: false,
            });
            alert(
                `Ett fel uppstod vid avbokning. Ring ${this.context.user.customer.brand.supportPhoneNumber} så hjälper vi dig!`,
            );
        }
    }
    duplicateOrder() {
        const { setReceiverPaysContactId } = this.props;
        setReceiverPaysContactId(null);
        if (this.state.order.receiverPays) {
            const contactId = this.state.order.receiverPays.contactId;
            setReceiverPaysContactId(contactId);
        }
        this.context.updateOrder({
            packages: this.state.order.packages,
            sender: this.state.order.route.from,
            receiver: this.state.order.route.to,
            information: this.state.order.information,
            customsInvoice: null,
        });
    }

    render() {
        const isPostnordWithPickup =
            this.state.order?.service.name.toUpperCase() === "POSTNORD" &&
            this.state.order?.pickup?.confirmationNumber;

        const disableCancelButton =
            this.state.cancellingShipment || isPostnordWithPickup;

        if (this.state.loading) {
            return (
                <Container>
                    <div className="loader">
                        <Loader />
                    </div>
                </Container>
            );
        } else {
            return (
                <Container>
                    {this.state.order.state === OrderState.CANCELLED && (
                        <div className="cancelled">
                            Denna försändelse är avbokad.
                        </div>
                    )}
                    {this.state.order.events &&
                        this.state.order.events[0] &&
                        this.state.order.events[0].state === "EXCEPTION" && (
                            <div className="exception">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
                                    />
                                </svg>
                                <div className="text">
                                    {this.state.order.events[0].description}
                                </div>
                            </div>
                        )}
                    {this.state.order.state === OrderState.APPROVED && (
                        <PickupInformation
                            order={this.state.order}
                            pickup={this.state.order.pickup}
                        />
                    )}
                    {this.state.order.messages.map((message, index) => {
                        if (!message) {
                            return null;
                        }
                        if (typeof message === "string") {
                            return (
                                <div
                                    key={index}
                                    className="message information"
                                >
                                    {message}
                                </div>
                            );
                        }
                        return (
                            <div
                                key={index}
                                className={`message ${
                                    message.type
                                        ? "is-" + message.type.toLowerCase()
                                        : ""
                                }`}
                            >
                                {getMessageIcon(message)}
                                <div className="text">
                                    {getMessageText(message)}
                                </div>
                                {message.contactSupport && (
                                    <div className="text">
                                        Kontakta vår support på{" "}
                                        <a
                                            href={`tel:${this.context.user.customer.brand.supportPhoneNumber}`}
                                        >
                                            {
                                                this.context.user.customer.brand
                                                    .supportPhoneNumber
                                            }
                                        </a>{" "}
                                        så hjälper vi er.
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    {this.state.order.trackingNumber &&
                        this.state.order.state !== OrderState.CANCELLED && (
                            <Card>
                                <Timeline order={this.state.order} />
                            </Card>
                        )}
                    {moment(this.state.order.created)
                        .utc()
                        .format("YYYY-MM-DD HH:mm:ss") >
                        "2021-10-10 16:00:00" && (
                        <>
                            <div className="subTitle">Dokument</div>
                            <Card className="documents">
                                {this.state.order.documents.length === 0 &&
                                    this.state.order.state ===
                                        OrderState.NEW && (
                                        <div className="emptyState">
                                            Försändelsen bearbetas fortfarande.
                                        </div>
                                    )}
                                {this.state.order.documents.length === 0 &&
                                    this.state.order.state !==
                                        OrderState.NEW && (
                                        <div className="emptyState">
                                            Det finns inga dokument tillgängliga
                                            för denna försändelse.
                                        </div>
                                    )}
                                {this.state.print.labels && (
                                    <div className="document">
                                        <div className="checkbox">
                                            <Input
                                                type="checkbox"
                                                value={
                                                    this.state.selectedForPrint
                                                        .labels
                                                }
                                                onChange={(value) =>
                                                    this.togglePrint(
                                                        "labels",
                                                        value,
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="name">Fraktsedel</div>
                                        <div className="description">
                                            Det skrivs ut en etikett per kolli.
                                            Fäst dem på varje paket så att
                                            streckkoden syns tydligt.
                                        </div>
                                    </div>
                                )}
                                {this.state.print.pickupNote && (
                                    <div className="document">
                                        <div className="checkbox">
                                            <Input
                                                type="checkbox"
                                                value={
                                                    this.state.selectedForPrint
                                                        .pickupNote
                                                }
                                                onChange={(value) =>
                                                    this.togglePrint(
                                                        "pickupNote",
                                                        value,
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="name">
                                            Upphämtningskvittens
                                        </div>
                                        <div className="description">
                                            Skriv ut denna kvittens och be
                                            chauffören att skriva under. Spara
                                            kvittensen tills försändelsen är
                                            levererad.
                                        </div>
                                    </div>
                                )}
                                {this.state.print.guarantee && (
                                    <div className="document">
                                        <div className="checkbox">
                                            <Input
                                                type="checkbox"
                                                value={
                                                    this.state.selectedForPrint
                                                        .guarantee
                                                }
                                                onChange={(value) =>
                                                    this.togglePrint(
                                                        "labels",
                                                        value,
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="name">Garanti</div>
                                        <div className="description">
                                            Garanti-etikett fäster du synligt på
                                            kollit. En etikett per kolli.
                                        </div>
                                    </div>
                                )}
                                {this.state.print.attachments && (
                                    <div className="document">
                                        <div className="checkbox">
                                            <Input
                                                type="checkbox"
                                                value={
                                                    this.state.selectedForPrint
                                                        .attachments
                                                }
                                                onChange={(value) =>
                                                    this.togglePrint(
                                                        "attachments",
                                                        value,
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="name">Attachment</div>
                                        <div className="description">
                                            Lägg denna etikett i en plastficka
                                            på ditt kolli.
                                        </div>
                                    </div>
                                )}
                                {this.state.print.manifest && (
                                    <div className="document">
                                        <div className="checkbox">
                                            <Input
                                                type="checkbox"
                                                value={
                                                    this.state.selectedForPrint
                                                        .manifest
                                                }
                                                onChange={(value) =>
                                                    this.togglePrint(
                                                        "manifest",
                                                        value,
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="name">Manifest</div>
                                        <div className="description">
                                            Två exemplar kommer att skrivas ut.
                                            Ge båda exemplaren till vår
                                            chaufför. Chauffören kommer att
                                            skriva under ett och lämna tillbaka
                                            det till dig, som ett kvitto.
                                        </div>
                                    </div>
                                )}
                                {this.state.print.cmr && (
                                    <div className="document">
                                        <div className="checkbox">
                                            <Input
                                                type="checkbox"
                                                value={
                                                    this.state.selectedForPrint
                                                        .cmr
                                                }
                                                onChange={(value) =>
                                                    this.togglePrint(
                                                        "cmr",
                                                        value,
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="name">CMR</div>
                                        <div className="description">
                                            Denna kommer i 4 exemplar. Behåll
                                            ett själv och ge de resterande 3
                                            till chauffören.
                                        </div>
                                    </div>
                                )}
                                {this.state.print.customsInvoice &&
                                    !this.state.etd.customsInvoice && (
                                        <div className="document">
                                            <div className="checkbox">
                                                <Input
                                                    type="checkbox"
                                                    value={
                                                        this.state
                                                            .selectedForPrint
                                                            .customsInvoice
                                                    }
                                                    onChange={(value) =>
                                                        this.togglePrint(
                                                            "customsInvoice",
                                                            value,
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="name">
                                                Tull-faktura
                                            </div>
                                            <div className="description">
                                                Tullfakturan ska bifogas med
                                                försändelsen i 3 exemplar.
                                            </div>
                                        </div>
                                    )}
                                {this.state.print.insurance && (
                                    <div className="document">
                                        <div className="checkbox">
                                            <Input
                                                type="checkbox"
                                                value={
                                                    this.state.selectedForPrint
                                                        .insurance
                                                }
                                                onChange={(value) =>
                                                    this.togglePrint(
                                                        "insurance",
                                                        value,
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="name">Försäkring</div>
                                        <div className="description">
                                            Försäkringen skrivs ut i 2 ex. Följ
                                            instruktionerna som står på
                                            dokumentet.
                                        </div>
                                    </div>
                                )}
                                {this.state.print.consignmentNote && (
                                    <div className="document">
                                        <div className="checkbox">
                                            <Input
                                                type="checkbox"
                                                value={
                                                    this.state.selectedForPrint
                                                        .consignmentNote
                                                }
                                                onChange={(value) =>
                                                    this.togglePrint(
                                                        "consignmentNote",
                                                        value,
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="name">
                                            Consignment Note
                                        </div>
                                        <div className="description">
                                            Denna kommer i två exemplar.
                                            Custom's copy ges till chauffören
                                            och receiver's copy ska fästas på
                                            paketet.
                                        </div>
                                    </div>
                                )}
                                {this.state.print.customsInvoice &&
                                    this.state.etd.customsInvoice && (
                                        <div className="document">
                                            <div className="checkbox">
                                                <Input
                                                    type="checkbox"
                                                    value={
                                                        this.state
                                                            .selectedForPrint
                                                            .customsInvoice
                                                    }
                                                    onChange={(value) =>
                                                        this.togglePrint(
                                                            "customsInvoice",
                                                            value,
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="name">
                                                Elektronisk tull-faktura
                                            </div>
                                            <div className="description">
                                                Vi har skickat tull-fakturan
                                                elektroniskt till transportören.
                                                Det betyder att ni inte behöver
                                                bifoga några tullfakturor med er
                                                transport.
                                            </div>
                                        </div>
                                    )}
                                <div className="print">
                                    {moment(this.state.order.created)
                                        .utc()
                                        .format("YYYY-MM-DD HH:mm:ss") >
                                    "2022-10-31 23:59:59" ? (
                                        <div
                                            className={`c-button c-button--raised ${
                                                this.state.noDocumentsSelected
                                                    ? "is-disabled"
                                                    : ""
                                            }`}
                                            onClick={this.printDocuments}
                                        >
                                            {this.getPrintIcon()} Skriv ut
                                        </div>
                                    ) : (
                                        <>
                                            <div className="c-button c-button--raised is-disabled">
                                                {this.getPrintIcon()} Skriv ut
                                            </div>
                                            <p className="info-text">
                                                Dokument för beställningar före
                                                november 2022 har tagits bort i
                                                en dataflytt. Framöver kommer
                                                dina filer att finnas
                                                tillgängliga i 3 år från
                                                beställningsdatumet.
                                            </p>
                                        </>
                                    )}
                                </div>
                            </Card>
                            {this.state.zebraFiles.length > 0 && (
                                <>
                                    <div className="subTitle">
                                        Dokument för Zebra Printer
                                    </div>
                                    <Card className="zebraFiles">
                                        {this.state.zebraFiles.map((zebra) => {
                                            const handleDownload = async ({
                                                objectKey,
                                                fileName,
                                            }) => {
                                                await handleDownloadS3({
                                                    url: `${
                                                        process.env
                                                            .REACT_APP_API_URL
                                                    }/api/s3/shipment?objectKey=${encodeURIComponent(
                                                        objectKey,
                                                    )}`,
                                                    desiredFileName: fileName,
                                                });
                                            };
                                            return (
                                                <a
                                                    key={zebra.fileId}
                                                    onClick={() =>
                                                        handleDownload({
                                                            objectKey:
                                                                zebra.fileId,
                                                            fileName:
                                                                zebra.title,
                                                        })
                                                    }
                                                    className="item"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    {zebra.title}
                                                </a>
                                            );
                                        })}
                                    </Card>
                                </>
                            )}
                        </>
                    )}
                    <div className="subTitle">Kundserviceärenden</div>
                    <Card className="tickets">
                        {!!this.state.order.tickets?.length &&
                            this.state.order.tickets.map((ticket) => {
                                return (
                                    <div className="ticket">
                                        <Link to={`/tickets/${ticket.id}`}>
                                            <div className="id">
                                                #{ticket.id}
                                            </div>
                                            <div className="title">
                                                {ticket.title}
                                            </div>
                                            <div className="type">
                                                {this.props.t(
                                                    `tickets.type.${ticket.type}`,
                                                )}
                                            </div>
                                            <div className="created">
                                                {moment(
                                                    ticket.created,
                                                ).fromNow()}
                                            </div>
                                        </Link>
                                    </div>
                                );
                            })}
                        <div className="createTicket">
                            <Link
                                className="c-button c-button--raised"
                                to={`/tickets/create?trackingNumber=${this.state.order.trackingNumber}`}
                            >
                                Skapa ärende
                            </Link>
                        </div>
                    </Card>
                    <div className="subTitle">Sammanfattning</div>
                    <Summary
                        order={this.state.order}
                        refresh={this.loadOrder}
                    />
                    <div className="buttons gap-3 flex flex-row">
                        <Link
                            to={"/create"}
                            className="c-button c-button--ghost duplicate "
                            onClick={() => this.duplicateOrder()}
                        >
                            Skapa kopia
                        </Link>
                        {this.state.order.state === OrderState.APPROVED && (
                            <div className=" flex flex-row">
                                <div
                                    className={`c-button c-button--ghost ${
                                        disableCancelButton ? "is-disabled" : ""
                                    }`}
                                    onClick={this.cancelShipment}
                                >
                                    Avboka försändelse
                                </div>
                                {isPostnordWithPickup && (
                                    <InformationHover>
                                        "Denna försändelse är bokad med PostNord
                                        och har en upphämtning bokad. PostNord
                                        stödjer inte avbokning av upphämtning,
                                        det går inte att avboka försändelsen."
                                    </InformationHover>
                                )}
                            </div>
                        )}
                    </div>
                    {this.state.rawData && (
                        <>
                            <div className="subTitle">
                                Anrop vid skapandet av försändelsen
                            </div>
                            <RawData
                                rawRequest={this.state.rawData.order.rawRequest}
                                rawResponse={
                                    this.state.rawData.order.rawResponse
                                }
                            />
                            <div className="subTitle">
                                Anrop vid skapandet av upphämtningen
                            </div>
                            <RawData
                                rawRequest={
                                    this.state.rawData.pickup
                                        ? this.state.rawData.pickup.rawRequest
                                        : null
                                }
                                rawResponse={
                                    this.state.rawData.pickup
                                        ? this.state.rawData.pickup.rawResponse
                                        : null
                                }
                            />
                        </>
                    )}
                </Container>
            );
        }
    }
}

function getMessageIcon(message) {
    switch (message.type?.toUpperCase()) {
        case "INFORMATION":
        case "INFO": {
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z"
                    />
                </svg>
            );
        }
        case "WARNING": {
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
                    />
                </svg>
            );
        }
        case "ERROR": {
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
                    />
                </svg>
            );
        }
    }
    return null;
}
function getMessageText(message) {
    switch (message.code) {
        case "DELIVERY_FAILED": {
            return "Leveransen misslyckades. Se detaljer och kontakta kundservice vid behov.";
        }
        case "MISROUTED": {
            return "Försändelsen försenas pga felsortering.";
        }
        case "MISSING_CUSTOMS": {
            return "Saknas eller felaktiga tull-dokument. Var god kontakta kundservice!";
        }
        case "DELIVERY_FAILED": {
            return "Misslyckat leveransförsök. Inväntar nästa utkörningstillfälle.";
        }
        case "INVALID_ADDRESS": {
            return "Felaktig leveransadress. Var god kontakta kundservice!";
        }
        case "CHANGED": {
            return "Ändring av transportuppdrag. Kontakta kundservice för detaljer.";
        }
        case "INCOMPLETE": {
            return "Saknas ellerfelaktiga dokument. Var god kontakta kundservice!";
        }
        case "RECLAIMED": {
            return "Försändelsen är reklamerad. Var god kontakta kundservice!";
        }
        case "REFUSED": {
            return "Mottagaren vägrar ta emot godset. Sändningen returneras till avsändaren.";
        }
        case "RENDER_LABEL_FAILED": {
            return "Vi lyckades tyvärr inte att ta fram en etikett.";
        }
        case "RENDER_CONSIGNMENT_NOTE_FAILED": {
            return "Vi lyckades tyvärr inte att ta fram en tullfaktura.";
        }
        case "RENDER_MANIFEST_FAILED": {
            return "Vi lyckades tyvärr inte att ta fram ett manifest.";
        }
        case "FEDEX_MAINTENANCE_PROBLEMS": {
            return "Fedex har för närvarande driftstörningar. Försök igen eller boka med annan transportör om problemet består.";
        }
        case "TIMEOUT": {
            return "Bokningen tog för lång tid för transportören, försök snart igen!";
        }
        case "TNT_RUNTIME_ERROR": {
            return "TNT tar för tillfället inte emot bokningen, försök igen senare eller välj en annan transportör.";
        }
        case "ETD_UPLOAD_ERROR": {
            return "Transportören kunde inte ta emot tullfakturan digitalt. Glöm inte att skriva ut tullfakturan och bifoga 3 fysiska exemplar.";
        }
        case "UNKNOWN_ERROR": {
            return "Ett okänt fel inträffade.";
        }
        case "DHL_INTERNAL_ERROR": {
            return "Ett internt fel inträffade hos DHL. Försök igen senare eller välj en annan transportör denna gång.";
        }
        default: {
            return message.text || message.code;
        }
    }
}

ViewOrder.contextType = Context;

export default withTranslation()(withCreateDraftsStore(ViewOrder));
