import toast from "react-hot-toast";

const DisplayField = ({ label, value, copyToClipboard, error }) => {
    const handleCopy = () => {
        navigator.clipboard.writeText(value);
        toast.success("API-nyckeln kopierad till urklipp!");
    };

    return (
        <div className="mb-2">
            <label className="font-medium">{label}</label>
            <div className="flex items-center w-full">
                <div className="flex-grow text-gray-900">{value}</div>
                {copyToClipboard && (
                    <button
                        type="button"
                        onClick={handleCopy}
                        className="ml-4 c-button--raised rounded-sm px-2 py-1 text-sm transition duration-300 ease-in-out"
                    >
                        Kopiera
                    </button>
                )}
            </div>
            {error && <span className="text-red-500 text-sm">{error}</span>}
        </div>
    );
};

export default DisplayField;
