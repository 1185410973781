import Loader from "components/Loader/Loader";
import Snackbar from "components/Snackbar";
import Context from "context/Global";
import React, { Component } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import Quote from "./Quote";

let Container = styled.div`
    box-sizing: border-box;
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    min-height: 40vh;

    > .button {
        margin-top: 16px;
    }

    > .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        > .percentage {
            position: absolute;
        }
    }

    > .notification {
        padding: 2rem;
        background: white;
        width: 100%;
        text-align: center;
        font-size: 1.6em;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 2px;
    }

    > .titles {
        display: flex;

        > .title {
            color: rgba(0, 0, 0, 0.5);
            padding: 16px;
            text-align: center;

            &.transporter {
                width: 120px;
                text-align: center;
            }

            &.price {
                margin-right: auto;
            }

            &.emission {
                width: 120px;
            }

            &.pickup {
                width: 196px;
            }

            &.delivery {
                width: 196px;
                margin-right: 100px;
            }
        }
    }

    > .searchEnd {
        margin-top: auto;
    }

    .speditionInformation {
        padding: 3rem 136px;
        font-size: 1.2rem;
    }
`;

let Title = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.6);
`;

function sortPrice(a, b) {
    if (a.proceedWithoutPrice) {
        return -1;
    }
    if (b.proceedWithoutPrice) {
        return 1;
    }
    if (a.price?.total < b.price?.total) {
        return -1;
    } else if (a.price?.total > b.price?.total) {
        return 1;
    } else {
        return 0;
    }
}

function sortDelivery(a, b) {
    if (a.proceedWithoutPrice) {
        return -1;
    }
    if (b.proceedWithoutPrice) {
        return 1;
    }
    if (a.deliveryDate < b.deliveryDate) {
        return -1;
    } else if (a.deliveryDate > b.deliveryDate) {
        return 1;
    } else {
        return sortPrice(a, b);
    }
}

function sortEmission(a, b) {
    if (a.proceedWithoutPrice) {
        return -1;
    }
    if (b.proceedWithoutPrice) {
        return 1;
    }
    if (
        a.environment?.emissions?.co2?.total <
        b.environment?.emissions?.co2?.total
    ) {
        return -1;
    } else if (
        a.environment?.emissions?.co2?.total >
        b.environment?.emissions?.co2?.total
    ) {
        return 1;
    } else {
        return sortPrice(a, b);
    }
}

class QuotationSearch extends Component {
    searchEndRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            progress: 0,
            error: null,
            quotations: null,
            sort: props.sort,
            receiverPays: null,
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            sort: props.sort,
        };
    }

    search(desiredPickupDate, receiverPays) {
        this.setState(
            {
                loading: true,
            },
            () => {
                // TODO: This is how it worked before. Maybe the context should have a getQuery - or we just create it here?
                this.scrollToBottom();
                Api.quoteOrders({
                    ...this.context.getApiOrderObject(),
                    pickupDate: desiredPickupDate,
                    receiverPays: receiverPays,
                    onProgress: (progress) => {
                        this.setState({
                            progress: progress,
                        });
                    },
                })
                    .then((response) => {
                        this.props.setQuoteLoading(false);
                        if (
                            receiverPays?.carrier &&
                            receiverPays?.accountNumber
                        ) {
                            this.setState({
                                receiverPays: receiverPays,
                            });
                            response.data.forEach((quote) => {
                                quote.price = null;
                            });
                        } else {
                            this.setState({
                                receiverPays: null,
                            });
                        }
                        this.setState({
                            quotations: response.data,
                            loading: false,
                            progress: 0,
                        });
                    })
                    .catch((error) => {
                        this.props.setQuoteLoading(false);
                        if (error && typeof error.toJSON === "function") {
                            const errorObject = error.toJSON();
                            if (errorObject.code === "ECONNABORTED") {
                                this.setState({
                                    loading: false,
                                    error: "FAILURE",
                                });
                            } else {
                                let invalidParams =
                                    error.response && error.response.data
                                        ? error.response.data.invalidParams
                                        : null;
                                this.setState({
                                    loading: false,
                                    message:
                                        error.response && error.response.data
                                            ? error.response.data.message
                                            : null,
                                });

                                if (
                                    invalidParams &&
                                    typeof this.props.invalidParams ===
                                        "function"
                                ) {
                                    this.props.invalidParams(invalidParams);
                                }
                            }
                        } else {
                            // TODO: Log to logger
                            this.setState({
                                loading: false,
                                message: "Ett fel har uppstått.",
                            });
                        }
                    });
            },
        );
    }

    scrollToBottom = () => {
        this.searchEndRef.current.scrollIntoView({ behavior: "smooth" });
    };

    getNoSearchResultInfo() {
        return (
            <>
                <div className="speditionInformation">
                    Fick du inga alternativ i portalen? Ofta handlar detta om
                    uppgifter som inte stämmer, tex postnummer, land eller mått.
                    Kontrollera att uppgifterna stämmer. Hittar ni fortfarande
                    inga priser så hjälper vi er gärna med en
                    speditionstransport. Begär då en offert nedan.
                </div>
                <Quote
                    data={{
                        forwarding: true,
                        service: {
                            name: "Zendr",
                        },
                    }}
                    order={{}}
                    history={this.props.history}
                />
            </>
        );
    }

    noPrices() {
        let noPrices = !!this.state.quotations;
        if (this.state?.quotations === null) {
            return false;
        }
        if (!this.state?.quotations?.length) {
            return true;
        }
        if (this.state?.receiverPays) {
            return false;
        }
        this.state.quotations?.forEach((quote) => {
            if (quote.price?.total) {
                noPrices = false;
            }
        });
        return noPrices;
    }

    render() {
        if (this.state.loading) {
            return (
                <Container>
                    <Snackbar text={this.state.message} />
                    <div className="loader">
                        <div className="percentage">{this.state.progress}%</div>
                        <Loader />
                    </div>
                    <div className="searchEnd" ref={this.searchEndRef} />
                </Container>
            );
        }

        return (
            <Container>
                {this.state.quotations && this.state.quotations.length > 0 ? (
                    <>
                        <div className="titles">
                            <div className="title transporter">Transportör</div>
                            <div className="title price">Pris</div>
                            {this.context.user.customer.settings
                                .searchEmissions && (
                                <div className="title emission">Miljö</div>
                            )}
                            <div className="title pickup">Upphämtning</div>
                            <div className="title delivery">
                                Beräknad leverans
                            </div>
                        </div>
                    </>
                ) : (
                    ""
                )}
                {this.state.quotations && this.state.quotations.length > 0
                    ? this.state.quotations
                          .sort((a, b) => {
                              switch (this.state.sort) {
                                  case "PRICE": {
                                      return sortPrice(a, b);
                                  }
                                  case "DELIVERY": {
                                      return sortDelivery(a, b);
                                  }
                                  case "EMISSION": {
                                      return sortEmission(a, b);
                                  }
                              }
                              return 1;
                          })
                          .map((quotation) => {
                              return (
                                  <Quote
                                      data={quotation}
                                      order={this.context.getApiOrderObject()}
                                      key={
                                          quotation.service.name +
                                          (quotation.service.productCode ||
                                              quotation.service.productName ||
                                              quotation.service.type) +
                                          quotation.service.guarantee
                                      }
                                      receiverPays={this.state.receiverPays}
                                      history={this.props.history}
                                      onSetDap={this.props.onSetDap}
                                  />
                              );
                          })
                    : ""}
                {this.noPrices() && this.getNoSearchResultInfo()}
                {this.state.error === "FAILURE" && (
                    <div>
                        Sökningen misslyckades, försök gärna igen! Om du inte
                        kommer vidare får du gärna höra av dig till oss på{" "}
                        <a
                            href={`tel:${this.context.user.customer.brand.supportPhoneNumber}`}
                        >
                            {
                                this.context.user.customer.brand
                                    .supportPhoneNumber
                            }
                        </a>{" "}
                        eller{" "}
                        <a
                            href={`mailto:${this.context.user.customer.brand.supportEmail}`}
                        >
                            {this.context.user.customer.brand.supportEmail}
                        </a>
                    </div>
                )}
                {this.state.message && <div>{this.state.message}</div>}
            </Container>
        );
    }
}

QuotationSearch.contextType = Context;

export default QuotationSearch;
