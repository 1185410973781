import React, { Component } from "react";
import styled from "styled-components";

let Container = styled.div`
    width: 100%;

    > input {
        width: 100%;
        padding: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        box-sizing: border-box;
    }

    &.is-invalid {
        > input {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }
    }
`;

class InputTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || "",
        };

        if (typeof this.props.onChange === "function") {
            this.onChange = this.props.onChange;
        }
    }

    onChange() {}

    value() {
        return this.state.value;
    }

    set(value) {
        this.setState({
            value: value,
        });
    }

    empty() {
        this.setState({
            value: "",
        });
    }

    validate() {
        if (this.props.required && this.state.value.length === 0) {
            this.setState({
                isInvalid: true,
            });
            return false;
        }
        this.setState({
            isInvalid: false,
        });
        return true;
    }

    render() {
        return (
            <Container className={this.state.isInvalid ? " is-invalid" : ""}>
                <input
                    type="time"
                    {...this.props}
                    value={this.state.value}
                    onChange={(event) => {
                        this.setState(
                            {
                                value: event.target.value,
                                isInvalid: false,
                            },
                            () => {
                                this.onChange(this.value());
                            },
                        );
                    }}
                />
            </Container>
        );
    }
}

export default InputTime;
