import Input from "components/OldInput";
import axios from "axios";
import Context from "context/Global";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import Confirmation from "../../Confirmation";
import { incoTerms } from "constants/IncoTerms";
import toast from "react-hot-toast";

let Title = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.6);
`;

let Card = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 8px;
    min-height: 60px;
    background: #fff;
    padding: 1rem;

    .subtitle {
        font-size: 1rem;
        font-weight: bold;
    }

    .textarea {
        max-width: 400px;
    }

    &.insurance {
        display: flex;
        flex-direction: column;

        > .toggle {
            display: flex;
            align-items: center;
            height: 38px;
            width: 100%;

            > .label {
                padding-left: 1rem;
            }

            > .note {
                padding-left: 1rem;
                font-size: 0.9em;
            }
        }

        > .information {
            display: flex;
            align-items: center;
            margin-top: 1rem;

            > .label {
                padding-left: 1rem;
            }

            > .note {
                padding-left: 1rem;
                font-size: 0.9em;
            }
        }

        > .reminder {
            display: flex;
            align-items: center;
            margin-top: 1rem;
            font-style: italic;
        }

        > .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 1rem;

            > .packageRows {
                display: grid;
                grid: 1fr / repeat(3, 1fr);
                grid-gap: 1rem;
                width: 100%;
            }

            > .summary {
                font-weight: bold;
            }

            > .price {
                margin-top: 1rem;

                > b {
                    > .Loader {
                        height: 1rem;
                        width: 1rem;
                        display: inline-block;
                        align-self: center;

                        > svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    &.pickup {
        display: flex;
        flex-direction: column;

        > .lateCancelFee {
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-bottom: 1rem;

            > img {
                height: 20px;
            }
        }

        > .toggle {
            display: flex;
            align-items: center;

            > .label {
                padding-right: 1rem;
            }

            > .note {
                padding-left: 1rem;
                font-size: 0.9em;
            }
        }

        .datetime {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 0 1rem;
            width: fit-content;
            padding-bottom: 1rem;
            margin-top: 1rem;

            > label {
                font-weight: bold;
            }
        }

        .timeMessage {
            padding-bottom: 1rem;
            font-size: 0.9rem;
            color: red;
            font-weight: bold;
        }

        .localtime {
            padding: 1rem 0;
            font-size: 0.9rem;
        }
    }

    &.confirm {
        > .summary {
            font-size: 1.2rem;
            margin-bottom: 1rem;
            font-style: italic;
        }
    }

    > .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;

        > .verification {
            display: flex;
            align-items: center;
            width: 100%;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding-top: 16px;
            margin-top: 32px;

            > .service {
                max-height: 40px;
                max-width: 100px;
            }
            > .price {
                font-size: 24px;
                padding-left: 32px;
                font-weight: bold;

                > .loader {
                    height: 20px;
                    width: 20px;
                }

                > .transport {
                    .unit {
                        font-size: 0.6em;
                    }
                }

                > .insurance {
                    font-size: 0.6em;

                    .unit {
                        font-size: 0.6em;
                    }
                }
            }

            > .u-push-left {
                margin-left: auto;
            }
        }

        > .intervalMessage {
            font-size: 0.8em;
            padding: 1em 0;
            color: rgba(250, 140, 0, 1);
            font-weight: bold;
        }

        .pickupDate {
            display: flex;
            align-items: center;

            .suggestion {
                padding-left: 1em;
                color: rgba(220, 140, 0, 1);
            }
        }

        > .message {
            > .text {
                font-weight: bold;
                font-size: 14px;
                color: #900;
            }

            > .details {
                font-size: 12px;
                color: #900;
            }
        }
    }
`;

function Component({ order, service, onChange, bookOrder, receiverPays }) {
    const context = useContext(Context);
    const [price, setPrice] = useState(null);
    const [pickupDate, setPickupDate] = useState(null);
    const [pickupTime, setPickupTime] = useState(null);
    const [deliveryDate, setDeliveryDate] = useState(null);
    const [message, setMessage] = useState(null);
    const [availablePickupDates, setAvailablePickupDates] = useState([]);
    const [desiredPickupDate, setDesiredPickupDate] = useState();
    const [desiredPickupStartTime, setDesiredPickupStartTime] = useState(null);
    const [desiredPickupEndTime, setDesiredPickupEndTime] = useState(null);
    const [availablePickupStartTimes, setAvailablePickupStartTimes] = useState(
        [],
    );
    const [availablePickupEndTimes, setAvailablePickupEndTimes] = useState([]);
    const [timeMessage, setTimeMessage] = useState(null);
    const pickupDateRef = useRef();
    const desiredPickupStartTimeRef = useRef();
    const desiredPickupEndTimeRef = useRef();
    const [includePickup, setIncludePickup] = useState(true);
    const [acceptManualPickupBooking, setAcceptManualPickupBooking] =
        useState(false);
    const [loadingNewQuote, setLoadingNewQuote] = useState(false);

    const [notificationSettings, setNotificationSettings] = useState(null);

    const [validPickupDate, setValidPickupDate] = useState(false);
    const [selectServicePoint, setSelectServicePoint] = useState(false);
    const [servicePoints, setServicePoints] = useState([]);
    const [selectedServicePointIndex, setSelectedServicePointIndex] =
        useState(null);
    const [selectedServicePoint, setSelectedServicePoint] = useState(null);
    const cancelSearchTokenRef = useRef();
    const cancelSearchRef = useRef();

    const [addInsurance, setAddInsurance] = useState(false);
    const [insuranceTermsAccepted, setInsuranceTermsAccepted] = useState(false);
    const [insuranceValue, setInsuranceValue] = useState(
        order.packages.map((p) => {
            return Array.from({ length: p.count }, () => null);
        }),
    );
    const insuranceInputRef = useRef();

    function getTimeList(start, end) {
        let [startHour, startMinute] = start.split(":");
        let [endHour, endMinute] = end.split(":");
        let availableTimes = [];
        for (let i = parseInt(startHour); i <= parseInt(endHour); i++) {
            if (i !== parseInt(startHour) || parseInt(startMinute) === 0) {
                availableTimes.push({
                    title: ("0" + i).slice(-2) + ":00",
                });
            }
            if (i !== parseInt(endHour) || parseInt(endMinute) === 30) {
                availableTimes.push({
                    title: ("0" + i).slice(-2) + ":30",
                });
            }
        }
        return availableTimes;
    }

    useEffect(() => {
        setAvailablePickupDates(
            [0, 1, 2, 3].map((i) => {
                return {
                    value: moment().add(i, "days").format("YYYY-MM-DD"),
                };
            }),
        );
    }, []);

    useEffect(() => {
        setDesiredPickupDate(
            context.quotation.pickupDate
                ? moment(context.quotation.pickupDate).format("YYYY-MM-DD")
                : availablePickupDates[0].value,
        );
    }, [availablePickupDates]);

    useEffect(() => {
        if (pickupDate && desiredPickupDate === pickupDate) {
            setValidPickupDate(true);
        } else {
            setValidPickupDate(false);
        }
    }, [pickupDate, desiredPickupDate]);

    useEffect(() => {
        if (availablePickupEndTimes && availablePickupEndTimes.length) {
            setDesiredPickupStartTime(availablePickupEndTimes[0].title);
            setDesiredPickupEndTime(
                availablePickupEndTimes[availablePickupEndTimes.length - 1]
                    .title,
            );
            desiredPickupEndTimeRef.current?.set(
                availablePickupEndTimes[availablePickupEndTimes.length - 1]
                    .title,
            );
        }
    }, [availablePickupEndTimes]);

    useEffect(() => {
        if (!desiredPickupDate) {
            return;
        }

        let startTime = "09:00";
        if (
            desiredPickupDate === moment().format("YYYY-MM-DD") &&
            startTime < moment().format("HH:mm")
        ) {
            let startTimeObject = moment().add(30, "minutes");
            if (startTimeObject.minute() > 30) {
                startTimeObject.minute(30);
            } else {
                startTimeObject.minute(0);
            }
            startTime = startTimeObject.format("HH:mm");
        }

        setAvailablePickupStartTimes(getTimeList(startTime, "18:00"));
        setAvailablePickupEndTimes(getTimeList(startTime, "18:00"));

        setPrice(null);

        let query = context.getApiOrderObject();
        query.service = context.quotation.service;
        query.pickupDate = desiredPickupDate;

        setPrice(null);
        setPickupDate(null);
        setLoadingNewQuote(true);

        if (cancelSearchRef.current) {
            clearTimeout(cancelSearchRef.current);
            cancelSearchRef.current = null;
        }

        cancelSearchRef.current = setTimeout(() => {
            if (cancelSearchTokenRef.current) {
                cancelSearchTokenRef.current.cancel();
            }
            cancelSearchTokenRef.current = axios.CancelToken.source();

            Api.quoteOrder({
                cancelToken: cancelSearchTokenRef.current?.token,
                quote: query,
            })
                .then((response) => {
                    if (desiredPickupDate === response.data.pickupDate) {
                        if (!receiverPays) {
                            setPrice(response.data.price);
                        }
                        setPickupDate(response.data.pickupDate);
                        setDeliveryDate(response.data.deliveryDate);
                    }
                    setLoadingNewQuote(false);
                })
                .catch((error) => {
                    toast.error(
                        "Det gick inte att hämta prisförfrågan just nu, försök gärna igen eller kontakta kundtjänst",
                    );
                });
        }, 300);
    }, [desiredPickupDate]);

    function getPriceNote() {
        if (order?.customs?.information?.terms === incoTerms.ddp.code) {
            return "Tull och moms-kostnader faktureras till avsändaren.";
        } else {
            return null;
        }
    }

    function getMessage() {
        if (!validPickupDate && !loadingNewQuote) {
            return "Upphämtningen kan inte ske denna dag. Försök med ett annat datum.";
        }
    }

    function valid() {
        let valid =
            desiredPickupDate &&
            desiredPickupEndTime &&
            desiredPickupStartTime &&
            validPickupDate;

        return valid;
    }

    return (
        <>
            <Title>Upphämtning</Title>
            <Card className="pickup">
                <div className="toggle">
                    <div className="label">Boka upphämtning</div>
                    <Input
                        type="checkbox"
                        value={includePickup}
                        onChange={setIncludePickup}
                    />
                    <div className="note">
                        (Avmarkera om ni har stående bokning eller redan bokat
                        en upphämtning på denna adress.)
                    </div>
                </div>
                {includePickup && (
                    <div className="datetime">
                        <label>Datum</label>
                        <label>Från</label>
                        <label>Till</label>
                        {desiredPickupDate && (
                            <Input
                                ref={pickupDateRef}
                                onChange={setDesiredPickupDate}
                                type="dropdown"
                                options={availablePickupDates}
                                value={desiredPickupDate}
                            />
                        )}
                        {availablePickupStartTimes && validPickupDate && (
                            <Input
                                onChange={setDesiredPickupStartTime}
                                type="dropdown"
                                value={desiredPickupStartTime}
                                options={availablePickupStartTimes}
                            />
                        )}
                        {availablePickupEndTimes && validPickupDate && (
                            <Input
                                onChange={setDesiredPickupEndTime}
                                type="dropdown"
                                value={desiredPickupEndTime}
                                options={availablePickupEndTimes}
                                ref={desiredPickupEndTimeRef}
                            />
                        )}
                    </div>
                )}
                {timeMessage && (
                    <div className="timeMessage">{timeMessage}</div>
                )}
            </Card>
            <Title>Bekräfta & beställ</Title>
            <Card className="confirm">
                {validPickupDate && (
                    <div className="summary">
                        Försändelsen hämtas {pickupDate}
                        {deliveryDate && (
                            <>
                                {" "}
                                och estimerad leverans är{" "}
                                {moment(deliveryDate).format("YYYY-MM-DD")}.
                            </>
                        )}
                    </div>
                )}
                <Confirmation
                    message={getMessage()}
                    price={price}
                    disabled={!valid()}
                    service={context.quotation.service}
                    receiverPays={receiverPays}
                    note={getPriceNote()}
                    bookOrder={() => {
                        let insurance = null;
                        if (addInsurance && insuranceValue) {
                            insurance = {
                                value: insuranceValue,
                                currency:
                                    context.order.customs?.information
                                        ?.currency ||
                                    context.order.information?.currency ||
                                    "SEK",
                            };
                        }

                        bookOrder({
                            pickup: {
                                skipPickupOrder: !includePickup,
                                date: `${moment(pickupDate).format(
                                    "YYYY-MM-DD",
                                )}`,
                                timeInterval: {
                                    start: desiredPickupStartTime,
                                    end: desiredPickupEndTime,
                                },
                            },
                            insurance,
                            receiverPays: receiverPays || undefined,
                            notifications: notificationSettings,
                            delivery: {
                                date: moment(deliveryDate).format("YYYY-MM-DD"),
                                servicePoint: selectedServicePoint,
                            },
                        });
                    }}
                    proceedWithoutPrice={undefined}
                />
                {message && (
                    <div className="message">
                        <div className="text">{message.title}</div>
                        <div className="details">{message.text}</div>
                    </div>
                )}
            </Card>
        </>
    );
}

export default Component;
