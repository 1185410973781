import Modal from "components/Modal";
import useSaveOrders from "hooks/batch/useSaveOrders";
import React, { useRef, useState } from "react";
import useBatchStore from "views/Batch/store";
import Packages from "views/Drafts/Create/Packages";

const PackagesModal = ({ setIsOpen, isOpen, commonPackages }) => {
    const [packages, setPackages] = useState(commonPackages || []);
    const [loading, setLoading] = useState(false);
    const packagesRef = useRef();

    const { saveOrders } = useSaveOrders();
    const selectedOrders = useBatchStore((state) => state.selectedOrders);

    async function savePackagesOnClick() {
        if (!packagesRef.current.validate()) {
            return;
        }

        setLoading(true);
        await saveOrders(
            selectedOrders.map((order) => {
                return {
                    ...order,
                    data: {
                        ...order.data,
                        packages: packages,
                    },
                };
            }),
        );
        setLoading(false);
        setIsOpen(false);
    }

    return (
        <>
            {isOpen && (
                <Modal
                    maxWidth="max-w-7xl"
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    title={"Kolli"}
                    description={"Ersätt kolli för valda försändelser."}
                >
                    <Packages
                        packages={packages}
                        onChange={setPackages}
                        fromBatch={true}
                        ref={packagesRef}
                    />
                    <div className="flex justify-start mt-4">
                        <button
                            className="c-button--raised c-button"
                            onClick={savePackagesOnClick}
                        >
                            {loading ? "Sparar..." : "Ersätt"}
                        </button>
                        <button
                            className="c-button c-button--ghost delete"
                            onClick={() => setIsOpen(false)}
                        >
                            Avbryt
                        </button>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default PackagesModal;
