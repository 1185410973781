import axios from "axios";
import Loader from "components/Loader/Loader";
import Table from "components/Table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    padding: 16px;
    box-sizing: border-box;
    max-width: 1000px;
    margin-bottom: 16px;
`;

function Component() {
    const [loading, setLoading] = useState(false);
    const [pickups, setPickups] = useState([]);

    useEffect(() => {
        const source = axios.CancelToken.source();

        loadPickups(source);

        return () => {
            source.cancel();
        };
    }, []);

    function loadPickups(source) {
        Api.getPickups({
            cancelToken: source.token,
        })
            .then(setPickups)
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    console.error("Failed to get pickups");
                    console.error(error);
                }
            });
    }

    if (loading) {
        return (
            <Container>
                <div className="loader">
                    <Loader />
                </div>
            </Container>
        );
    } else {
        return (
            <Container>
                <Table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Order ID</th>
                            <th>Konfirmationsnummer</th>
                            <th>Företag</th>
                            <th>Tidigast upphämtning</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pickups.map((pickup) => {
                            return (
                                <tr key={pickup.id}>
                                    <td>{pickup.id}</td>
                                    <td>{pickup.orderId}</td>
                                    <td>{pickup.confirmationNumber}</td>
                                    <td>{pickup.service}</td>
                                    <td>
                                        {moment(
                                            pickup.data.pickup.start,
                                        ).calendar()}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Container>
        );
    }
}

export default Component;
