import Modal from "components/Modal";
import { BATCH_ORDER } from "constants/batch/batchConstants";
import React, { useEffect } from "react";
import useBatchStore from "views/Batch/store";
import { printDocuments } from "utils/printDocumentsUtil";

const BookingModal = ({ setIsOpen, isOpen, getBatchOrders }) => {
    const selectedOrders = useBatchStore((state) => state.selectedOrders);
    const setSelectedOrders = useBatchStore((state) => state.setSelectedOrders);

    const selectedCount = selectedOrders.length;

    const loading = selectedOrders.some(
        (order) => order.state === BATCH_ORDER.State.Loading,
    );

    const successfulBookings = selectedOrders.filter(
        (order) =>
            order.state !== BATCH_ORDER.State.Loading &&
            order.error !== BATCH_ORDER.Errors.Booking.Failed,
    ).length;

    const failedBookings = selectedOrders.filter(
        (order) =>
            order.state === BATCH_ORDER.State.Error &&
            order.error === BATCH_ORDER.Errors.Booking.Failed,
    ).length;

    const failedPickups = selectedOrders.filter(
        (order) =>
            order.state === BATCH_ORDER.State.Error &&
            order.error === BATCH_ORDER.Errors.Pickup.Failed,
    ).length;

    const partialError =
        successfulBookings < selectedCount ||
        failedPickups > 0 ||
        selectedOrders.some(
            (order) =>
                order.state === BATCH_ORDER.State.Error &&
                successfulBookings > 0,
        );

    const success = successfulBookings === selectedCount && failedPickups === 0;

    useEffect(() => {
        if (!loading) {
            getBatchOrders();
        }
    }, [selectedOrders]);

    const close = () => {
        setIsOpen(false);

        const newSelectedOrders = selectedOrders.filter((order) => {
            return (
                order.state === BATCH_ORDER.State.Error &&
                order.error === BATCH_ORDER.Errors.Booking.Failed
            );
        });

        setSelectedOrders(newSelectedOrders);
    };
    const selectedSuccessfulOrderIds = selectedOrders
        .filter(
            (order) =>
                order.state !== BATCH_ORDER.State.Error &&
                order.error !== BATCH_ORDER.Errors.Booking.Failed,
        )
        .map((order) =>
            // convertedOrderId is used for orders that have been converted from a imported_order
            order.convertedOrderId ? order.convertedOrderId : order.id,
        );

    const renderPrintOptions = () => {
        return (
            <div className="flex-col justify-start items-center gap-8 flex">
                <div className="text-neutral-800 text-2xl font-normal ">
                    Välj vad du vill skriva ut
                </div>
                <div className="justify-center items-center gap-4 inline-flex">
                    <div
                        className="h-[46px] px-4 py-2 bg-white rounded-lg shadow border border-indigo-700 justify-center items-center gap-2 flex cursor-pointer"
                        onClick={() =>
                            printDocuments("labels", selectedSuccessfulOrderIds)
                        }
                    >
                        <div className="text-center text-indigo-700 text-base font-normal uppercase">
                            fraktsedlar
                        </div>
                        <img src="/images/icons/print.svg" alt="print" />
                    </div>
                    <div className="px-4 py-2 bg-white rounded-lg shadow border border-indigo-700 justify-center items-center gap-2 flex cursor-pointer">
                        <div
                            className="text-center text-indigo-700 text-base font-normal uppercase"
                            onClick={() =>
                                printDocuments(
                                    "documents",
                                    selectedSuccessfulOrderIds,
                                )
                            }
                        >
                            fraktdokument
                        </div>
                        <img src="/images/icons/print.svg" alt="print" />
                    </div>
                </div>
                <div className="text-neutral-800 text-base font-normal">
                    Du hittar dem annars under mina försändelser
                </div>
            </div>
        );
    };

    const Loading = () => {
        return (
            <div className="flex justify-center items-center flex-col">
                <div className="text-center">
                    <h1 className="text-2xl font-bold">
                        Dina försändelser bokas
                    </h1>
                    <p>Detta kan ta en stund</p>
                </div>
                <div className="py-8">
                    <img
                        src="/images/icons/loading-box.svg"
                        className="animate-bounce"
                    />
                </div>
                <div className="flex gap-2">
                    <img
                        src="/images/icons/check-circle.svg"
                        alt="circle check"
                        className="w-6"
                    ></img>
                    <p>
                        {successfulBookings} av {selectedCount} försändelser
                        bokade
                    </p>
                </div>
            </div>
        );
    };

    const Booked = () => {
        return (
            <div className="flex justify-center items-center flex-col gap-8">
                <div className="text-center flex flex-row gap-2">
                    <img
                        src="/images/icons/check-circle.svg"
                        alt="circle check"
                        className="w-6"
                    ></img>
                    <h1 className="text-3xl font-bold">
                        Dina försändelser är nu bokade
                    </h1>
                </div>
                {renderPrintOptions()}
                <div>
                    <button
                        className="c-button c-button--raised w-40 h-12"
                        onClick={() => {
                            close();
                        }}
                    >
                        Slutför
                    </button>
                </div>
            </div>
        );
    };

    const Error = () => {
        return (
            <div className="flex justify-center items-center flex-col gap-8">
                <div className="text-center flex gap-2">
                    <img src="/images/icons/warn-circle.svg" className="w-8" />
                    <h1 className="text-2xl font-bold">
                        Bokningen misslyckades
                    </h1>
                </div>
                <div className="text-center">
                    <p>
                        Något gick fel när vi försökte boka dina försändelser.
                    </p>
                    <p>Vänligen försök igen annars kontakta kundtjänst.</p>
                </div>
                <div>
                    <button
                        className="c-button c-button--raised"
                        onClick={() => {
                            close();
                        }}
                    >
                        Tillbaka
                    </button>
                </div>
            </div>
        );
    };

    const PartiallyBooked = () => {
        return (
            <div className="flex justify-center items-center flex-col gap-8 p-6">
                <div className="text-center flex gap-2">
                    {successfulBookings > 0 && (
                        <img
                            src="/images/icons/check-circle.svg"
                            className="w-6"
                        />
                    )}
                    <h1 className="text-2xl font-bold">
                        {successfulBookings} av {selectedCount} försändelser
                        bokade
                    </h1>
                </div>
                {successfulBookings > 0 && renderPrintOptions()}

                <div className="flex gap-7">
                    <img src="/images/icons/warn-circle.svg" className="w-6" />
                    <div className="flex flex-col text-error-550 gap-2">
                        <div>
                            {failedBookings > 0 && (
                                <p>
                                    {failedBookings}{" "}
                                    {failedBookings > 1
                                        ? "försändelser"
                                        : "försändelse"}{" "}
                                    kunde inte bokas. Se över dina försändelser
                                    och försök igen.
                                </p>
                            )}
                        </div>
                        <div>
                            {failedPickups > 0 && (
                                <p>
                                    {failedPickups}{" "}
                                    {failedPickups > 1
                                        ? "försändelser"
                                        : "försändelse"}{" "}
                                    bokades men utan upphämtning, och finns nu
                                    under Mina försändelser.
                                </p>
                            )}
                        </div>
                    </div>
                </div>

                <div>
                    <button
                        className="c-button c-button--raised"
                        onClick={() => {
                            close();
                        }}
                    >
                        Slutför
                    </button>
                </div>
            </div>
        );
    };

    const renderBookingResult = () => {
        if (loading) {
            return <Loading />;
        } else if (success) {
            return <Booked />;
        } else if (failedBookings === selectedCount) {
            return <Error />;
        } else if (partialError) {
            return <PartiallyBooked />;
        }
    };

    return (
        <Modal setIsOpen={setIsOpen} isOpen={isOpen} locked={true}>
            {renderBookingResult()}
        </Modal>
    );
};

export default BookingModal;
