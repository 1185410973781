import Loader from "components/Loader/Loader";
import { getCountryName } from "constants/countryList";
import React, { useEffect, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    align-items: flex-start;
    background: #fff;
    padding: 16px;
    width: 100%;
    height: 100%;
    position: relative;
`;

const DropdownContainer = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    flex-direction: column;
    align-items: flex-end;
`;

const CustomInput = styled.input`
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    position: relative;
`;
const DropdownList = styled.ul`
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 150px;
    overflow-y: auto;
    background-color: white;
`;

const DropdownItem = styled.li`
    padding: 8px;
    cursor: pointer;
`;

const Header = styled.div`
    font-weight: bold;
    margin-bottom: 10px;
`;

const InputWithChevron = styled.div`
    position: relative;
    display: inline-block;
`;

const ChevronIcon = styled.img`
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
`;

const Component = ({ customer: initialCustomer, onCustomerChange }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [customerList, setCustomersList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(initialCustomer);
    const [inputValue, setInputValue] = useState(initialCustomer?.name || "");
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const inputWrapperRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                setCustomersList(await Api.getCustomers());
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching customers:", error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                inputWrapperRef.current &&
                !inputWrapperRef.current.contains(event.target)
            ) {
                setDropdownVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputFocus = () => {
        setDropdownVisible(true);
    };

    const handleInputBlur = () => {
        setTimeout(() => {
            setDropdownVisible(false);
        }, 200);
    };

    const selectCustomer = (customer) => {
        const simplifiedCustomer = {
            id: customer.id,
            name: customer.name,
            contactName: customer.contactName,
            countryCode: customer.countryCode,
            addressLine1: customer.addressLine1,
            postalCode: customer.postalCode,
            postalTown: customer.postalTown,
            clientManagerName: customer.clientManagerName,
        };
        setInputValue(simplifiedCustomer.name);
        setDropdownVisible(false);
        setSelectedCustomer(simplifiedCustomer);
        onCustomerChange(simplifiedCustomer);
    };

    const filteredCustomers = customerList.filter((customer) =>
        customer.name.toLowerCase().includes(inputValue.toLowerCase()),
    );

    const {
        name = "",
        contactName = "",
        countryCode = "",
        addressLine1 = "",
        postalCode = "",
        postalTown = "",
    } = selectedCustomer || {};

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Container>
                    <DropdownContainer>
                        <Header>Välj betalande kund</Header>
                        <InputWithChevron ref={inputWrapperRef}>
                            <CustomInput
                                ref={inputRef}
                                value={inputValue}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                onBlur={handleInputBlur}
                            />
                            <ChevronIcon
                                onClick={() =>
                                    setDropdownVisible(!isDropdownVisible)
                                }
                                src={
                                    isDropdownVisible
                                        ? "/images/icons/chevron-up.svg"
                                        : "/images/icons/chevron-down.svg"
                                }
                                alt={isDropdownVisible ? "Collapse" : "Expand"}
                            />
                        </InputWithChevron>
                        {isDropdownVisible && (
                            <DropdownList ref={dropdownRef}>
                                {filteredCustomers.map((customer) => (
                                    <DropdownItem
                                        key={customer.id}
                                        onClick={() => selectCustomer(customer)}
                                    >
                                        {customer.name}
                                    </DropdownItem>
                                ))}
                            </DropdownList>
                        )}
                    </DropdownContainer>
                    <div className="company">{name}</div>
                    <div className="contact">{contactName}</div>
                    <div className="contact">{getCountryName(countryCode)}</div>
                    <div className="streetAddress">{addressLine1}</div>
                    <div className="city">
                        {postalCode && postalTown
                            ? `${postalCode} ${postalTown}`
                            : ""}
                    </div>
                </Container>
            )}
        </>
    );
};
export default Component;
