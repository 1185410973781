import CalendarComponent from "components/Calendar/CalendarComponent";
import Modal from "components/Modal";
import useSaveOrders from "hooks/batch/useSaveOrders";
import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import useBatchStore from "../store";
import { isObjectEmpty } from "utils/batchTableUtils";

const PickupModal = ({ setIsOpen, isOpen, mode, order, quoteOrders }) => {
    const requestPickupDate = order?.data?.requestedPickupDate;
    const pickupDate = order?.data?.selectedQuote?.pickupDate;
    const { saveOrders } = useSaveOrders();

    const selectedOrders = useBatchStore((state) => state.selectedOrders);

    useEffect(() => {
        if (order) {
            if (pickupDate) {
                setPickup(true);
                return setDate(pickupDate);
            }
            if (order.data.requestedPickupDate) {
                setPickup(true);
                return setDate(order.data.requestedPickupDate);
            }
        } else {
            const firstOrderPickupDate =
                selectedOrders[0].data.selectedQuote?.pickupDate;
            const firstOrderRequestedDate =
                selectedOrders[0].data.requestedPickupDate;

            const allHaveSamePickupDate = selectedOrders.every(
                (order) =>
                    order.data.selectedQuote?.pickupDate ===
                    firstOrderPickupDate,
            );
            const allHaveSameRequestedDate = selectedOrders.every(
                (order) =>
                    order.data.requestedPickupDate === firstOrderRequestedDate,
            );

            if (
                allHaveSamePickupDate &&
                selectedOrders.length > 0 &&
                firstOrderPickupDate
            ) {
                setPickup(true);
                setDate(firstOrderPickupDate);
            } else if (
                allHaveSameRequestedDate &&
                selectedOrders.length > 0 &&
                firstOrderRequestedDate
            ) {
                setPickup(true);
                setDate(firstOrderRequestedDate);
            } else {
                setPickup(true);
                setDate(new Date().toISOString().slice(0, 10));
            }
        }
    }, []);

    const [date, setDate] = useState(
        pickupDate ?? new Date().toISOString().slice(0, 10),
    );
    const [pickup, setPickup] = useState(requestPickupDate);

    const savePickupOnClick = async () => {
        setIsOpen(false);
        let ordersToQuote = [];

        if (mode === "single") {
            if (!pickup) {
                return await saveOrders([
                    {
                        ...order,
                        data: {
                            ...order.data,
                            requestedPickupDate: null,
                            quotesExpireUTC: undefined,
                            selectedQuote: {
                                ...order.data.selectedQuote,
                                pickupDate: undefined,
                            },
                        },
                    },
                ]);
            }
            ordersToQuote =
                order.data.selectedQuote &&
                !isObjectEmpty(order.data.selectedQuote?.price)
                    ? [
                          {
                              ...order,
                              data: {
                                  ...order.data,
                                  requestedPickupDate: date,
                              },
                          },
                      ]
                    : [];

            if (ordersToQuote.length < 1) {
                return await saveOrders([
                    {
                        ...order,
                        data: {
                            ...order.data,
                            requestedPickupDate: date,
                        },
                    },
                ]);
            }
        } else {
            if (!pickup) {
                return await saveOrders(
                    selectedOrders.map((order) => {
                        return {
                            ...order,
                            data: {
                                ...order.data,
                                requestedPickupDate: null,
                            },
                        };
                    }),
                );
            }

            ordersToQuote = selectedOrders
                ?.filter(
                    (order) =>
                        order.data.selectedQuote &&
                        !isObjectEmpty(order.data.selectedQuote?.price),
                )
                .map((order) => {
                    return {
                        id: order.id,
                        origin: order.origin,
                        data: {
                            ...order.data,
                            requestedPickupDate: date,
                        },
                    };
                });

            await saveOrders(
                selectedOrders.map((order) => {
                    return {
                        ...order,
                        data: {
                            ...order.data,
                            requestedPickupDate:
                                order.data.selectedQuote?.service?.name.toLowerCase() ===
                                "postnord"
                                    ? null
                                    : date,
                        },
                    };
                }),
            );
        }

        if (pickup && ordersToQuote.length >= 1) {
            const filteredOrders = ordersToQuote.filter(
                (order) =>
                    order.data.selectedQuote?.service?.name.toLowerCase() !==
                    "postnord",
            );

            if (filteredOrders.length > 0) {
                quoteOrders(filteredOrders);
            }
        }
    };

    return (
        <Modal
            title={"Upphämtning"}
            description={
                mode === "single"
                    ? "Hantera upphämtning för försändelsen"
                    : "Hantera upphämtning för valda försändelser"
            }
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <div>
                <div className="flex flex-col items-start">
                    <div className="flex gap-3 items-center">
                        <input
                            onChange={() => setPickup((prev) => !prev)}
                            checked={pickup}
                            type="checkbox"
                            className="h-5 w-5"
                            style={{ accentColor: "rgba(76, 80, 175, 1)" }}
                        />
                        <p>Upphämtning</p>
                    </div>
                    {pickup ? (
                        <div>
                            <p className="py-6">
                                Välj önskat datum för upphämtning, vi kommer att
                                försöka hitta en dag så nära som möjligt valt
                                datum.
                            </p>

                            <div className="pb-6">
                                <CalendarComponent
                                    onChange={setDate}
                                    value={date}
                                />
                            </div>
                        </div>
                    ) : (
                        <p className="py-6">
                            Ingen upphämtning vald, försändelserna kommer att
                            bokas utan upphämtning.
                        </p>
                    )}
                    <div className="flex justify-start">
                        <button
                            className="c-button--raised c-button"
                            onClick={savePickupOnClick}
                            disabled={
                                !pickup &&
                                !requestPickupDate &&
                                mode === "single"
                            }
                        >
                            Ersätt
                        </button>
                        <button
                            className="c-button c-button--ghost delete"
                            onClick={() => setIsOpen(false)}
                        >
                            Avbryt
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default PickupModal;
