import React, { useContext, useEffect, useState } from "react";
import Actions from "./Actions/Actions";
import CreateBatchOrderModal from "../../modals/CreateBatchOrderModal";
import ConfirmModal from "components/ConfirmModal";
import BookingModal from "./BookingModal";
import UploadOrdersModal from "../../modals/UploadOrders";
import useBatchStore from "views/Batch/store";
import { useBookOrders } from "hooks/batch/useBookOrder";
import { useQuoteOrders } from "hooks/batch/useQuoteOrders";
import {
    selectedOrdersAreLoading,
    selectedOrdersHasNoSelectedQuotes,
    selectedOrdersHasNonEUAddress,
    selectedOrdersHaveExpiredQuotes,
    validateBatchOrder,
} from "utils/batchTableUtils";
import Context from "context/Global";

const plusIcon = "/images/icons/plus.svg";
const uploadIcon = "/images/icons/upload.svg";

const TopBar = ({ getBatchOrders, openEditNotificationModal }) => {
    const [isSticky, setIsSticky] = useState(false);
    const [isSingleOrderModalOpen, setIsSingleOrderModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const context = useContext(Context);
    const customerId = context.user.customer.id;
    const selectedOrders = useBatchStore((state) => state.selectedOrders);

    const { bookOrders } = useBookOrders({
        customerId,
    });
    const { quoteOrders } = useQuoteOrders();

    const onConfirmBooking = () => {
        setIsConfirmModalOpen(false);

        if (selectedOrdersHaveExpiredQuotes(selectedOrders)) {
            return;
        }

        bookOrders().then(() => {
            getBatchOrders();
        });
        setIsBookingModalOpen(true);
    };

    function openNewOrderModal() {
        setIsSingleOrderModalOpen(true);
    }

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <div
            className={
                "h-20 mb-4 bg-white border border-secondary-600 rounded-md sticky top-16 duration-300 z-30 " +
                (isSticky ? "shadow-md" : "")
            }
        >
            <div className="flex justify-between h-full">
                <div className="flex items-center gap-3 p-3 w-full">
                    <Actions
                        getBatchOrders={getBatchOrders}
                        quoteOrders={quoteOrders}
                        openEditNotificationModal={openEditNotificationModal}
                    />
                    <div className="flex gap-2 ">
                        <button
                            className="c-button c-button--raised"
                            disabled={
                                selectedOrdersHasNonEUAddress(selectedOrders) ||
                                selectedOrders?.length === 0 ||
                                selectedOrdersAreLoading(selectedOrders) ||
                                selectedOrders.some(
                                    (order) => !validateBatchOrder(order),
                                )
                            }
                            onClick={() => quoteOrders(selectedOrders)}
                        >
                            Sök pris
                        </button>
                        <button
                            className="c-button c-button--raised"
                            disabled={
                                selectedOrdersHasNoSelectedQuotes(
                                    selectedOrders,
                                ) ||
                                selectedOrdersHasNonEUAddress(selectedOrders) ||
                                selectedOrdersHaveExpiredQuotes(
                                    selectedOrders,
                                ) ||
                                selectedOrders?.length === 0 ||
                                selectedOrdersAreLoading(selectedOrders)
                            }
                            onClick={() => {
                                setIsConfirmModalOpen(true);
                                if (
                                    selectedOrdersHaveExpiredQuotes(
                                        selectedOrders,
                                    )
                                ) {
                                    setIsConfirmModalOpen(false);
                                    return;
                                }
                            }}
                        >
                            Boka
                        </button>
                    </div>
                    <p>Påverkar {selectedOrders?.length} valda försändelser</p>
                    <div className="ml-auto">
                        <button
                            className="c-button c-button--outline gap-2"
                            onClick={() => setIsUploadModalOpen(true)}
                        >
                            <p>Ladda Upp</p>
                            <img
                                className="w-4 h-4"
                                src={uploadIcon}
                                alt="upload icon"
                            />
                        </button>

                        <button
                            className="c-button c-button--outline"
                            onClick={() => openNewOrderModal()}
                        >
                            <p>Skapa Ny</p>
                            <img
                                className="w-6 h-6"
                                src={plusIcon}
                                alt="plus icon"
                            />
                        </button>
                    </div>
                </div>

                {isConfirmModalOpen && (
                    <ConfirmModal
                        onConfirm={() => onConfirmBooking()}
                        isOpen={isConfirmModalOpen}
                        setIsOpen={setIsConfirmModalOpen}
                        title={"Bekräfta bokning"}
                        description={`Är du säker på att du vill boka ${
                            selectedOrders?.length
                        } ${
                            selectedOrders?.length > 1
                                ? "försändelser"
                                : "försändelse"
                        }?`}
                    />
                )}

                {isBookingModalOpen && (
                    <BookingModal
                        isOpen={isBookingModalOpen}
                        setIsOpen={setIsBookingModalOpen}
                        getBatchOrders={getBatchOrders}
                    />
                )}

                {isSingleOrderModalOpen && (
                    <CreateBatchOrderModal
                        setIsOpen={setIsSingleOrderModalOpen}
                        isOpen={isSingleOrderModalOpen}
                        customerId={customerId}
                        getBatchOrders={getBatchOrders}
                    />
                )}

                {isUploadModalOpen && (
                    <UploadOrdersModal
                        isOpen={isUploadModalOpen}
                        setIsOpen={setIsUploadModalOpen}
                        customerId={customerId}
                        getBatchOrders={getBatchOrders}
                    />
                )}
            </div>
        </div>
    );
};

export default TopBar;
