import axios from "axios";
import { BATCH_ORDER } from "constants/batch/batchConstants";
import { useEffect, useState } from "react";
import useBatchStore from "views/Batch/store";
import useOrdersAndSelectedUpdater from "./useOrdersAndSelectedUpdater";
import { getJsonString } from "views/Batch/utils/getJsonString";

export const useBookOrders = ({ customerId }) => {
    const selectedOrders = useBatchStore((state) => state.selectedOrders);
    const orders = useBatchStore((state) => state.orders);
    const [bookings, setBookings] = useState([]);
    const { updateOrdersAndSelectedOrders } = useOrdersAndSelectedUpdater();

    useEffect(() => {
        if (bookings.length > 0) {
            const newBooking = bookings[bookings.length - 1];

            const selectedOrder = selectedOrders.find(
                (order) => order.id === newBooking.id,
            );

            if (selectedOrder && selectedOrder.state !== newBooking.state) {
                updateOrdersOnNewBooking({
                    order: selectedOrder,
                    newBooking: newBooking,
                });
            }
        }
    }, [bookings]);

    const updateOrdersOnNewBooking = async ({ order, newBooking }) => {
        if (!bookings.includes(newBooking.id)) {
            if (newBooking.convertedOrderId) {
                updateOrdersAndSelectedOrders([
                    {
                        ...order,
                        state: newBooking.state,
                        error: newBooking.error,
                        convertedOrderId: newBooking.convertedOrderId,
                    },
                ]);
            } else {
                updateOrdersAndSelectedOrders([
                    {
                        ...order,
                        state: newBooking.state,
                        error: newBooking.error,
                    },
                ]);
            }
        }
    };

    const checkForNewBookings = (data) => {
        data.split("}{").map((json) => {
            const jsonString = getJsonString(json);

            const newBooking = JSON.parse(jsonString);

            if (!bookings.includes(newBooking.id)) {
                setBookings([...bookings, newBooking]);
            }
        });
    };

    const onBookingProgress = (progressEvent) => {
        checkForNewBookings(progressEvent.target.response);
    };

    const bookSelectedOrders = async (selectedOrders) => {
        return await axios.post(
            process.env.REACT_APP_API_URL +
                `/api/batch/orders/customer/${customerId}/book`,
            { orders: selectedOrders },
            {
                onDownloadProgress: onBookingProgress,
            },
        );
    };

    const bookOrders = async () => {
        const clearStateFromOrders = orders.map((order) => {
            if (
                !selectedOrders.find(
                    (selectedOrder) => selectedOrder.id === order.id,
                )
            ) {
                return {
                    ...order,
                    state: undefined,
                    error: "",
                };
            }
            return order;
        });

        const loadingOrders = selectedOrders.map((order) => {
            return {
                ...order,
                state: BATCH_ORDER.State.Loading,
            };
        });

        updateOrdersAndSelectedOrders([
            ...clearStateFromOrders,
            ...loadingOrders,
        ]);

        await bookSelectedOrders(selectedOrders);
    };

    return { bookOrders };
};
