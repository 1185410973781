import Integration from "constants/integrations";
import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import BusinessCentral from "./BusinessCentral";
import CustomIntegration from "./Custom";
import PyramidIntegration from "./Pyramid";
import FortnoxIntegration from "./Fortnox";
import LogTradeIntegration from "./LogTrade";
import MagentoIntegration from "./Magento";
import QuickButikIntegration from "./QuickButik";
import ShopifyIntegration from "./Shopify";
import StarwebIntegration from "./Starweb";
import VismaAdministrationIntegration from "./VismaAdministration";
import WooCommerceIntegration from "./WooCommerce";
import OngoingWmsIntegration from "./OngoingWms";
import JoomlaVirtueMartIntegration from "./JoomlaVirtueMart";
import ZendrIntegration from "./Zendr";

function Component({ integrationId, ...props }) {
    const context = useContext(Context);
    const [integration, setIntegration] = useState(null);

    useEffect(() => {
        loadIntegration();
    }, []);

    async function loadIntegration() {
        setIntegration(
            await Api.getCustomerIntegration({
                customerId: context.user.customer.id,
                integrationId: integrationId,
            }),
        );
    }

    if (integration) {
        if (integration.type === Integration.LOG_TRADE) {
            return <LogTradeIntegration integration={integration} {...props} />;
        } else if (integration.type === Integration.WOO_COMMERCE) {
            return (
                <WooCommerceIntegration integration={integration} {...props} />
            );
        } else if (integration.type === Integration.QUICK_BUTIK) {
            return (
                <QuickButikIntegration integration={integration} {...props} />
            );
        } else if (integration.type === Integration.STARWEB) {
            return <StarwebIntegration integration={integration} {...props} />;
        } else if (integration.type === Integration.FORTNOX) {
            return <FortnoxIntegration integration={integration} {...props} />;
        } else if (integration.type === Integration.MAGENTO) {
            return <MagentoIntegration integration={integration} {...props} />;
        } else if (integration.type === Integration.SHOPIFY) {
            return <ShopifyIntegration integration={integration} {...props} />;
        } else if (integration.type === Integration.CUSTOM) {
            return <CustomIntegration integration={integration} {...props} />;
        } else if (integration.type === Integration.PYRAMID) {
            return <PyramidIntegration integration={integration} {...props} />;
        } else if (integration.type === Integration.ZENDR) {
            return <ZendrIntegration integration={integration} {...props} />;
        } else if (integration.type === Integration.VISMA_ADMINISTRATION) {
            return (
                <VismaAdministrationIntegration
                    integration={integration}
                    {...props}
                />
            );
        } else if (integration.type === Integration.BUSINESS_CENTRAL) {
            return <BusinessCentral integration={integration} {...props} />;
        } else if (integration.type === Integration.ONGOING_WMS) {
            return (
                <OngoingWmsIntegration integration={integration} {...props} />
            );
        } else if (integration.type === Integration.JOOMLA_VIRTUEMART) {
            return (
                <JoomlaVirtueMartIntegration
                    integration={integration}
                    {...props}
                />
            );
        }
    }
    return <div>Loading integration</div>;
}

export default Component;
