import Input from "components/OldInput";
import InputDropdown from "components/Input/Dropdown";
import InputMultiToggle from "components/Input/MultiToggle";
import InputNumber from "components/Input/Number";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";

let Container = styled.tr`
    .remove {
        width: 16px;
        height: 16px;
        cursor: pointer;
        color: #aaa;

        &:hover {
            color: #555;
        }

        > svg {
            width: 100%;
            height: 100%;
        }
    }
`;

function Component({ canDelete, ...props }, ref) {
    const [value, setValue] = useState({
        id: props.id,
        name: props.name || null,
        type: props.type || "package",
        length: props.length || null,
        width: props.width || null,
        height: props.height || null,
        weight: props.weight || null,
        stackable: props.stackable || null,
    });

    const [isPalletType, setIsPalletType] = useState(false);

    const typeRef = useRef();
    const lengthRef = useRef();
    const widthRef = useRef();
    const heightRef = useRef();
    const weightRef = useRef();

    useEffect(() => {
        if (props.template === "DHL_HALV_PALL") {
            typeRef.current.set("pallet");
            lengthRef.current.set(60);
            widthRef.current.set(80);
            setIsPalletType(true);
            onChange("length", 60);
            onChange("width", 80);
            onChange("type", "pallet");
        } else if (props.template === "DHL_PALL") {
            typeRef.current.set("pallet");
            lengthRef.current.set(120);
            widthRef.current.set(80);
            setIsPalletType(true);
            onChange("length", 120);
            onChange("width", 80);
            onChange("type", "pallet");
        } else {
            setIsPalletType(false);
        }
    }, [props.template]);

    function onRemove() {
        props.onRemove(props.id);
    }

    function onChange(param, newValue) {
        setValue((oldValue) => {
            oldValue[param] = newValue;
            return { ...oldValue };
        });
    }

    useEffect(() => {
        props.onChange(value);
    }, [value]);

    return (
        <Container>
            <td>
                <Input
                    type="text"
                    placeholder="Namn"
                    value={value.name}
                    onChange={(value) => onChange("name", value)}
                />
            </td>
            <td>
                <Input
                    disabled={isPalletType}
                    ref={typeRef}
                    type={InputDropdown}
                    options={[
                        {
                            title: "Envelope",
                            value: "envelope",
                        },
                        {
                            title: "Paket",
                            value: "package",
                        },
                        {
                            title: "Pall",
                            value: "pallet",
                        },
                    ]}
                    value={value.type}
                    onChange={(value) => onChange("type", value)}
                />
            </td>
            <td>
                <Input
                    disabled={isPalletType}
                    ref={lengthRef}
                    type={InputNumber}
                    unit="cm"
                    min={0.1}
                    placeholder="0"
                    value={value.length}
                    onChange={(value) => onChange("length", value)}
                />
            </td>
            <td>
                <Input
                    disabled={isPalletType}
                    ref={widthRef}
                    type={InputNumber}
                    unit="cm"
                    min={0.1}
                    placeholder="0"
                    value={value.width}
                    onChange={(value) => onChange("width", value)}
                />
            </td>
            <td>
                <Input
                    ref={heightRef}
                    type={InputNumber}
                    unit="cm"
                    min={0.1}
                    placeholder="0"
                    value={value.height}
                    onChange={(value) => onChange("height", value)}
                />
            </td>
            <td>
                <Input
                    ref={weightRef}
                    type={InputNumber}
                    unit="kg"
                    min={0.1}
                    placeholder="0"
                    value={value.weight}
                    onChange={(value) => onChange("weight", value)}
                />
            </td>
            <td>
                <Input
                    className="stackable"
                    onChange={(value) => onChange("stackable", value)}
                    value={value.stackable}
                    type={InputMultiToggle}
                    options={[
                        {
                            title: "Ja",
                            value: true,
                        },
                        {
                            title: "Nej",
                            value: false,
                        },
                    ]}
                />
            </td>
            <td>
                {canDelete && (
                    <div className="remove" onClick={onRemove}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
                            />
                        </svg>
                    </div>
                )}
            </td>
        </Container>
    );
}

export default forwardRef(Component);
