import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import styled from "styled-components";

let Container = styled.div`
    > .inputContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        > .browse {
            background: #eee;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            box-sizing: border-box;
            min-height: 40px;
            display: flex;
            align-items: center;
            padding: 0 1rem;
        }

        > .browseButton {
            display: none;
        }

        > .files {
            padding: 1rem;
            background: #fff;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 5px;

            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 1rem;

            > .file {
                width: 100%;
                height: 60px;
                position: relative;
                background: #eee;

                > .remove {
                    position: absolute;
                    cursor: pointer;
                    top: -10px;
                    right: -10px;
                    border: 1px solid #eee;
                    border-radius: 100px;
                    width: 20px;
                    height: 20px;
                    background: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-sizing: border-box;
                    padding: 6px;

                    &:hover {
                        background: #eaeaea;
                    }

                    > svg {
                        width: 100%;
                        height: 100%;
                    }
                }

                > img {
                    object-fit: contain;
                    object-position: center;
                    width: 100%;
                    height: 100%;
                }
            }

            &.is-empty {
                font-style: italic;
                grid-template-columns: 1fr;
            }
        }

        > .description {
            font-style: italic;
            font-size: 0.8rem;
        }
    }

    > .message {
        font-weight: bold;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
    }

    &.is-invalid {
        > .inputContainer {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }
        > .message {
            color: rgba(255, 0, 0, 0.6);
        }
    }

    &.is-disabled {
        > .inputContainer {
            background: rgb(235, 235, 228);
            border: 1px solid rgba(0, 0, 0, 0.05);

            > input {
                color: rgba(0, 0, 0, 0.5);
                background: transparent;
            }
        }
    }
`;

const defaultMessages = {
    min: "Value cannot be less than ${min}",
    max: "Value cannot be more than ${max}",
};

function Component({ autoValidate, ...props }, ref) {
    const [value, setValue] = useState(props.value || props.min || 0);
    const [files, setFiles] = useState([]);
    const [message, setMessage] = useState("");
    const [disabled, setDisabled] = useState();
    const [isInvalid, setIsInvalid] = useState(false);
    const [isInteger, setIsInteger] = useState();
    const browseButtonRef = useRef();
    const messages = {
        ...defaultMessages,
        ...props.messages,
    };

    useEffect(() => {
        setDisabled(props.disabled || false);
    }, [props.disabled]);

    useEffect(() => {
        setIsInteger(props.isInteger || false);
    }, [props.isInteger]);

    useEffect(() => {
        setValue(props.value || props.min || 0);
    }, [props.value]);

    function onKeyUp() {}

    function onChange(event) {
        setValue(parseFloat(event.target.value || 0));

        if (props?.onChange) {
            props.onChange(parseFloat(event.target.value || 0));
        }
    }

    useEffect(() => {
        if (autoValidate) {
            validate();
        }
    }, [value]);

    useEffect(() => {
        props?.onChange(files);
    }, [files]);

    async function getBase64(file) {
        return await new Promise(async (resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.onerror = function (error) {
                reject(error);
            };
        });
    }

    function onFilesChange(ev) {
        setFiles((files) => {
            return [].concat(files).concat(
                Object.keys(ev.target.files).map((key) => {
                    return {
                        file: ev.target.files[key],
                        path: URL.createObjectURL(ev.target.files[key]),
                        data: getBase64(ev.target.files[key]),
                    };
                }),
            );
        });
    }

    function removeFileIndex(index) {
        setFiles((files) => {
            files.splice(index, 1);
            return [].concat(files);
        });
    }

    function validate() {
        let message = "";
        if (value !== undefined) {
            if (parseFloat(value) < props.min) {
                message = messages.min.replace("${min}", props.min);
            }
            if (parseFloat(value) > props.max) {
                message = messages.max.replace("${max}", props.max);
            }
        }

        setMessage(message);
        return !message;
    }
    function clear() {}
    function getValue() {
        return files;
    }
    function set(value) {
        setValue(value);
        setTimeout(() => {
            props?.onChange(value);
        }, 0);
    }

    function browseFiles() {
        browseButtonRef.current.click();
    }

    useImperativeHandle(ref, () => {
        return {
            validate: validate,
            clear: clear,
            value: getValue,
            set: set,
        };
    });

    return (
        <Container
            className={
                (disabled ? " is-disabled" : "") +
                (isInvalid ? " is-invalid" : "") +
                " " +
                props.className
            }
        >
            <div className="inputContainer">
                <div
                    className="c-button c-button--raised"
                    onClick={browseFiles}
                >
                    Ladda upp filer
                </div>
                <input
                    type="file"
                    multiple
                    className="browseButton"
                    ref={browseButtonRef}
                    onChange={onFilesChange}
                ></input>
                {!!files?.length ? (
                    <div className="files">
                        {files.map((file, index) => {
                            return (
                                <div className="file" key={file.path}>
                                    <div
                                        className="remove"
                                        onClick={() => removeFileIndex(index)}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
                                        </svg>
                                    </div>
                                    <img className="image" src={file.path} />
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className="files is-empty">Inga filer valda</div>
                )}
                {!!props.description && (
                    <div className="description">{props.description}</div>
                )}
            </div>
            {message && <div className="message">{message}</div>}
        </Container>
    );
}

export default forwardRef(Component);
