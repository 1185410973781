import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import moment from "moment";
import Api from "services/Api/Api";

import Context from "context/Global";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1em;

    > table {
        width: 100%;
        margin-bottom: 16px;
        flex-direction: column;

        > thead {
            > tr {
                > th {
                    background: #fafafa;
                    padding: 1em 0;
                    border-bottom: 1px solid #ddd;
                    border-top: 1px solid #ddd;
                    color: #333;
                    font-weight: normal;

                    &:first-child {
                        padding-left: 1em;
                    }
                    &:last-child {
                        padding-right: 1em;
                    }
                }
            }
        }

        > tbody {
            > tr {
                > td {
                    background: #fff;
                    padding: 0.5em 0;
                    cursor: pointer;

                    &:first-child {
                        padding-left: 1em;
                    }
                    &:last-child {
                        padding-right: 1em;
                    }

                    &.service {
                        > img {
                            width: 32px;
                            object-size: contain;
                        }
                    }
                }

                &:hover > td {
                    background: rgba(250, 250, 250, 1);
                }
            }
        }
    }
`;

function View(props) {
    const context = useContext(Context);
    const [invoices, setInvoices] = useState([]);

    useEffect(() => {
        if (
            context.user.administrator ||
            (context.user.brand && context.user.brand.id)
        ) {
            loadInvoices();
        } else {
            props.history.replace("/admin");
        }
    }, []);

    async function loadInvoices() {
        setInvoices(
            await Api.getCustomerInvoices({
                customerId: props.customerId,
            }),
        );
    }

    return (
        <Container>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Kund</th>
                        <th>Värde</th>
                        <th>Datum</th>
                    </tr>
                </thead>
                <tbody>
                    {invoices.map((invoice) => {
                        return (
                            <tr
                                key={invoice.id}
                                className="invoice"
                                onClick={() => {
                                    props.history.push(
                                        `/invoices/${invoice.id}`,
                                    );
                                }}
                            >
                                <td>{invoice.externalId}</td>
                                <td>{invoice.customer.name}</td>
                                <td>{invoice.value}</td>
                                <td>
                                    {moment(invoice.created).format(
                                        "YYYY-MM-DD",
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Container>
    );
}

export default View;
