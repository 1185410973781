import Input from "components/OldInput";
import useDebounce from "hooks/useDebounce";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import Api from "services/Api/Api";
import Loader from "components/Loader/Loader";
import { priceParser } from "helpers/StringParser";
import Button from "components/Button/Button";

const SalesTurnover = () => {
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

    const [clientManagers, setClientManagers] = useState([]);
    const [clientManagerIdFilter, setClientManagerIdFilter] = useState(null);
    const [summary, setSummary] = useState(null);
    const [loading, setLoading] = useState(false);

    const loadClientManagers = async () => {
        const clientManagers = await Api.getClientManagers({
            includeOwners: true,
        });
        setClientManagers(clientManagers);
    };

    useEffect(() => {
        loadClientManagers();
    }, []);

    const handleFilterBySales = (event) => {
        const clientManagerId = parseInt(event.target.value);
        if (!isNaN(clientManagerId)) {
            setClientManagerIdFilter(clientManagerId);
        } else {
            setClientManagerIdFilter(null);
        }
    };

    const loadTurnover = async () => {
        if (!startDate || !endDate) return;
        setLoading(true);
        try {
            const response = await Api.getAllOrdersTurnover({
                startDate,
                endDate,
                salesPersonId: clientManagerIdFilter,
            });
            setSummary(response.summary.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const onClickLoadTurnover = () => {
        loadTurnover();
    };

    const renderResults = () => {
        return summary.map((salesPerson, index) => (
            <Fragment key={index}>
                <div className="grid grid-cols-2 gap-2 bg-white px-4 py-4 ">
                    <div>
                        <text className="title text-lg font-medium">
                            {salesPerson.SalespersonName}
                        </text>
                    </div>
                    <div>
                        <div className="flex flex-1 flex-row">
                            <div className="px-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M10.81 11.885h-1.098l.066-.739h1.069l-.037.739zm.613 1.099h1.14l-.007-.739h-1.111l-.022.739zm.085-2.971l-.021.739h1.054l-.007-.739h-1.026zm-1.894 2.971h1.14l.038-.739h-1.112l-.066.739zm1.291-2.971h-1.026l-.066.739h1.054l.038-.739zm6.308 0l.137.739h1.054l-.165-.739h-1.026zm-4.115 0l.023.739h1.054l-.051-.739h-1.026zm1.155 1.872l-.051-.739h-1.069l.022.739h1.098zm3.309-4.885h-7.406l-.204 2h8.058l-.448-2zm-6.107 4.885h1.097l-.007-.739h-1.069l-.021.739zm2.874 1.099l-.051-.739h-1.111l.023.739h1.139zm-5.888-2.971h-1.025l-.134.739h1.054l.105-.739zm10.215 1.872l-.165-.739h-1.069l.136.739h1.098zm-12.074-.739h-1.069l-.179.739h1.097l.151-.739zm.231-1.133h-1.026l-.179.739h1.054l.151-.739zm17.187 4.987v7h-24v-7h24zm-2 2h-20v3h20v-3zm-9 1h-2v1h2v-1zm-7.932-5.016h1.14l.15-.739h-1.111l-.179.739zm11.702-2.233l-.121-.739h-1.026l.093.739h1.054zm.185 1.134l-.121-.739h-1.069l.093.739h1.097zm1.946 1.099l-.165-.739h-2.833l.093.739h2.905zm-10.622-1.838h-1.069l-.134.739h1.098l.105-.739zm-1.402 1.838h1.139l.105-.739h-1.11l-.134.739zm-5.595.016l2.621-9h1.921c.112-.622.322-1.371.668-2h3.427c-.26.57-.499 1.259-.627 2h10.805l2.592 9h-2.221l-1.804-7h-9.42c.071.836.178 1.511-.107 2h-3.456c.292-.431.166-1.111.086-2h-.481l-1.739 7h-2.265zm5.101-6.631h2.298c-.157-1.076-.092-2.782.404-3.786h-2.249c-.553 1.006-.624 2.64-.453 3.786z"
                                    />
                                </svg>
                            </div>
                            {priceParser(salesPerson.TotalTurnover)} SEK
                        </div>
                    </div>
                </div>
                {index !== summary.length - 1 && <div className="border-b " />}
            </Fragment>
        ));
    };

    const renderText = () => {
        if (summary && !summary.length && !loading)
            return "Ingen omsättning hittades";

        if (!summary && !loading)
            return "Välj ett datumintervall för att börja";
    };

    return (
        <div className="flex flex-col p-3 relative container">
            <div className="title text-3xl font-regular text-gray-400 ">
                Omsättning
            </div>
            <div>
                <div className="input flex items-center gap-10">
                    <div className={"flex flex-col"}>
                        <label className="title text-sm font-medium">
                            Filtrera på person
                        </label>
                        <select
                            onChange={handleFilterBySales}
                            className={"border p-2 rounded w-40 cursor-pointer"}
                        >
                            <option selected value={clientManagerIdFilter}>
                                Alla
                            </option>
                            {clientManagers?.map(({ name, id }) => (
                                <option key={id} value={id}>
                                    {name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="title text-sm font-medium">
                            Startdatum
                        </label>
                        <Input
                            type="date"
                            key={startDate}
                            value={startDate}
                            onChange={(value) => {
                                setStartDate(value);
                            }}
                        />
                    </div>
                    <div>
                        <label className="title text-sm font-medium">
                            Slutdatum
                        </label>
                        <Input
                            type="date"
                            key={endDate}
                            value={endDate}
                            onChange={(value) => {
                                setEndDate(value);
                            }}
                        />
                    </div>
                    <Button
                        className="c-button c-button--raised self-end"
                        onClick={onClickLoadTurnover}
                        disabled={loading || !startDate || !endDate}
                    >
                        Sök
                    </Button>
                </div>
            </div>
            <div className="flex-auto py-4 mt-4 justify-start w-6/12 border rounded-lg bg-white">
                {loading ? (
                    <Loader>Laddar...</Loader>
                ) : (
                    summary && !!summary.length && renderResults()
                )}
                <div className="px-4">
                    <text className="title text-lg font-medium">
                        {renderText()}
                    </text>
                </div>
            </div>
        </div>
    );
};

export default SalesTurnover;
