import Input from "components/OldInput";
import InputTextSuggestions from "components/Input/TextSuggestions";
import { countryList } from "constants/countryList";
import Context from "context/Global";
import React, {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    display: grid;
    grid-template: 1fr / 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: flex-start;
    grid-gap: 1rem;
`;

function Component(props, ref) {
    const context = useContext(Context);
    const [description, setDescription] = useState(props.description || "");
    const [suggestions, setSuggestions] = useState([]);
    const [quantity, setQuantity] = useState(props.quantity || 1);
    const [unitWeight, setUnitWeight] = useState(props.unitWeight || 1);
    const [unitValue, setUnitValue] = useState(props.unitValue || 1);
    const [hsCode, setHsCode] = useState(props.hsCode || "");
    const [origin, setOrigin] = useState(props.origin || "");
    const [currency, setCurrency] = useState(props.currency || "SEK");

    const descriptionRef = useRef();
    const quantityRef = useRef();
    const unitWeightRef = useRef();
    const unitValueRef = useRef();
    const hsCodeRef = useRef();
    const originRef = useRef();

    useEffect(() => {
        setCurrency(props.currency || "SEK");
    }, [props.currency]);

    useEffect(() => {
        props.onChange({
            id: props.id,
            description,
            quantity,
            unitWeight,
            unitValue,
            hsCode,
            origin,
        });
    }, []);

    useEffect(() => {
        props.onChange({
            id: props.id,
            description,
            quantity,
            unitWeight,
            unitValue,
            hsCode,
            origin,
        });
    }, [description, quantity, unitWeight, unitValue, hsCode, origin]);

    async function search(description) {
        const goods = await Api.getGoods({
            searchstring: description,
            customerId: context.user.customer.id,
        });
        setSuggestions(
            goods.map((item) => {
                return {
                    id: item.id,
                    title: item.name,
                    value: item,
                };
            }),
        );
        setDescription(description);
    }

    const validate = () => {
        let valid = true;
        if (!descriptionRef.current.validate()) {
            valid = false;
        }
        if (!quantityRef.current.validate()) {
            valid = false;
        } else if (
            quantityRef.current.value() !==
            parseInt(quantityRef.current.value())
        ) {
            valid = false;
            quantityRef.current.setValidationMessage("Endast heltal tillåtet");
        }
        if (!unitWeightRef.current.validate()) {
            valid = false;
        }
        if (!unitValueRef.current.validate()) {
            valid = false;
        }
        if (!hsCodeRef.current.validate()) {
            valid = false;
        }
        if (!originRef.current.validate()) {
            valid = false;
        }
        return valid;
    };

    useImperativeHandle(ref, () => {
        return {
            validate: validate,
        };
    });

    function select(value) {
        descriptionRef.current.set(value.name);
        setDescription(value.name);
        unitWeightRef.current.set(value.weight);
        setUnitWeight(value.weight);
        unitValueRef.current.set(value.value);
        setUnitValue(value.value);
        hsCodeRef.current.set(value.code);
        setHsCode(value.code);
        originRef.current.set(value.origin);
        setOrigin(value.origin);
        setSuggestions(null);
    }

    async function remove(removedSuggestion) {
        await Api.removeGoods({
            goodsId: removedSuggestion.id,
            customerId: context.user.customer.id,
        });
        setSuggestions((suggestions) => {
            return suggestions.filter((suggestion) => {
                return suggestion.id !== removedSuggestion.id;
            });
        });
    }

    return (
        <Container>
            <InputTextSuggestions
                ref={descriptionRef}
                required={true}
                suggestions={suggestions}
                onSelect={select}
                onRemove={remove}
                onChange={search}
                value={description}
            />
            <Input
                ref={quantityRef}
                required={true}
                type="number"
                onChange={setQuantity}
                value={quantity}
            />
            <Input
                ref={unitWeightRef}
                required={true}
                type="number"
                onChange={setUnitWeight}
                unit="kg"
                value={unitWeight}
            />
            <Input
                ref={unitValueRef}
                required={true}
                min={0.01}
                type="number"
                onChange={setUnitValue}
                unit={currency}
                value={unitValue}
            />
            <Input
                ref={hsCodeRef}
                required={true}
                type="text"
                onChange={setHsCode}
                value={hsCode}
            />
            <Input
                ref={originRef}
                type="dropdown"
                onChange={setOrigin}
                value={origin}
                options={countryList.map((country) => {
                    return {
                        title: country.title,
                        value: country.value,
                    };
                })}
            />
        </Container>
    );
}

export default forwardRef(Component);
