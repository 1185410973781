import React from "react";
import { ReactComponent as ErrorIcon } from "./AlertIcons/error-icon.svg";
import { ReactComponent as WarningIcon } from "./AlertIcons/warning-icon.svg";

export const AlertTypes = {
    ERROR: "error",
    WARNING: "warning",
};

const AlertConfig = {
    [AlertTypes.ERROR]: {
        alertClass: "text-red-700",
        Icon: ErrorIcon,
    },
    [AlertTypes.WARNING]: {
        alertClass: "text-orange-500",
        Icon: WarningIcon,
    },
};

const Alert = ({ children, type, className = "" }) => {
    const config = AlertConfig[type];
    if (!config) return null;

    const { alertClass, Icon } = config;

    return (
        <p
            className={`${alertClass} flex items-center gap-3 mb-3 ${className}`}
        >
            <Icon /> {children}
        </p>
    );
};

export default Alert;
