import Loader from "components/Loader/Loader";
import Input from "components/OldInput";
import { euMemberList } from "constants/countryList";
import Context from "context/Global";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import ShipmentInformation from "./Information";
import ShipmentPackages from "./Packages";
import Route from "./Route";
import ShipmentSearch from "./Search";
import { incoTerms } from "constants/IncoTerms";
import ReceiverPays from "./ReceiverPays";
import useCreateDraftsStore from "./createDraftsStore";

const { v4: uuidv4 } = require("uuid");

let Container = styled.div`
    box-sizing: border-box;
    max-width: 1200px;
`;

let Title = styled.div`
    font-size: 24px;
    color: rgba(0, 0, 0, 0.6);
    padding: 16px;
`;

let Content = styled.div`
    padding: 16px;

    > .notification {
        margin-top: 2rem;
        padding: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        background: #fff;
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        align-items: flex-start;

        background: rgba(255, 200, 0, 0.2);
        color: rgba(180, 80, 0, 1);
        font-weight: bold;
    }

    > .loader {
        width: 100%;
        height: 200px;
    }

    > .buttons {
        margin-top: 32px;
        display: flex;
        align-items: center;

        > .message {
            padding: 0 1rem;
            color: #d83232;
        }

        .sort {
            margin-left: auto;
        }
    }

    > .description {
        padding: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        background: #fff;
        display: flex;
        flex-direction: row;

        > .section {
            width: 50%;
            margin-right: 16px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    > .packages {
        padding: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        background: #fff;
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
    }

    > .pickupDate {
        padding: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        background: #fff;
        display: flex;
        flex-direction: column;
        margin: 16px 0;

        > .title {
            font-size: 0.8em;
            font-weight: bold;
            padding: 4px;
        }
    }

    @media (max-width: 700px) {
        > .description {
            flex-direction: column;

            > .section {
                width: 100%;

                & + .section {
                    margin-top: 8px;
                }
            }
        }
    }
`;

function CreateQuotation(props) {
    const context = useContext(Context);
    const shipmentSearchRef = useRef();
    const routeRef = useRef();
    const packagesRef = useRef();
    const informationRef = useRef();
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [quoteLoading, setQuoteLoading] = useState(false);
    const [customsRequired, setCustomsRequired] = useState(false);
    const [draft, setDraft] = useState(null);
    const [importedOrder, setImportedOrder] = useState(null);
    const [invalidRouteParams, setInvalidRouteParams] = useState(null);
    const [pickupDates, setPickupDates] = useState(null);
    const [desiredPickupDate, setDesiredPickupDate] = useState(null);
    const [message, setMessage] = useState(null);
    const [canSave, setCanSave] = useState(true);
    const [receiverPayIsActive, setReceiverPayIsActive] = useState(false);
    const [sort, setSort] = useState(
        localStorage.getItem("searchSort") || "PRICE",
    );
    const receiverPaysCarrier = useCreateDraftsStore(
        (state) => state.receiverPaysCarrier,
    );
    const receiverPaysAccountNumber = useCreateDraftsStore(
        (state) => state.receiverPaysAccountNumber,
    );
    const receiverPaysContactId = useCreateDraftsStore(
        (state) => state.receiverPaysContactId,
    );
    const userSettings = context.user.customer.settings;

    useEffect(() => {
        setOrder(context.order || {});
        setPickupDates(
            [0, 1, 2, 3, 4, 5]
                .map((i) => {
                    let date = moment().add(i, "days");

                    if (date.isoWeekday() <= 5) {
                        return {
                            title: date.calendar(null, {
                                sameDay: "[Idag]",
                                nextDay: "[Imorgon]",
                                nextWeek: "dddd Do MMMM",
                                sameElse: "dddd Do MMMM",
                            }),
                            value: date.format("YYYY-MM-DD"),
                        };
                    }
                    return null;
                })
                .filter((v) => {
                    return !!v;
                }),
        );
    }, []);

    useEffect(() => {
        if (pickupDates) {
            setDesiredPickupDate(pickupDates[0].value);
        }
    }, [pickupDates]);

    useEffect(() => {
        if (sort) {
            localStorage.setItem("searchSort", sort);
        }
    }, [sort]);

    useEffect(() => {
        if (props.draftId) {
            setLoading(true);
            Api.getDraft({
                draftId: props.draftId,
            }).then((draft) => {
                if (draft) {
                    context.updateOrder({
                        ...draft.data,
                        draftId: draft.id,
                        customsInvoice: null,
                    });
                    setOrder(draft.data);
                    setDraft(draft);
                } else {
                    props.history.replace("/create");
                }
                setLoading(false);
            });
        } else if (props.orderId) {
            setLoading(true);
            Api.getOrder({
                orderId: props.orderId,
            }).then((order) => {
                if (order) {
                    context.updateOrder({
                        orderId: order.id,
                        packages: order.packages,
                        sender: order.route.from,
                        receiver: order.route.to,
                        information: order.information,
                        customsInvoice: null,
                    });
                    setOrder(order);
                } else {
                    props.history.replace("/create");
                }
                setLoading(false);
            });
        } else if (props.importedOrderId) {
            setLoading(true);
            Api.getImportedOrder({
                importedOrderId: props.importedOrderId,
            }).then((importedOrder) => {
                if (importedOrder) {
                    context.updateOrder({
                        importedOrderId: importedOrder.id,
                        packages: importedOrder.data.packages,
                        sender: importedOrder.data.sender,
                        receiver: importedOrder.data.receiver,
                        information: importedOrder.data.information,
                        customsInvoice: null,
                    });
                    setOrder(importedOrder.data);
                    setImportedOrder(importedOrder);
                } else {
                    props.history.replace("/create");
                }
                setLoading(false);
            });
        } else if (localStorage.getItem("shipmentForm")) {
            const shipment = JSON.parse(localStorage.getItem("shipmentForm"));
            const mappedShipment = {
                importedOrderId: null,
                draftId: null,
                information: {
                    reference: shipment.reference,
                    description: shipment.description,
                    customs: shipment.customs,
                },
                packages: shipment.packages.map((item) => {
                    return {
                        count: item.count,
                        description: "",
                        height: item.height,
                        weight: item.weight,
                        width: item.width,
                        length: item.length,
                        stackable: item.stackable,
                        type: item.type.toLowerCase(),
                        volume: item.volume,
                        productSpecificPackage: null,
                        loadingMeters: null,
                        id: uuidv4(),
                    };
                }),
                receiver: {
                    addressLine1: shipment.receiver.address.line1,
                    addressLine2: shipment.receiver.address.line2,
                    addresLine3: shipment.receiver.address.line3,
                    contact: {
                        clientNumber: shipment.receiver.clientNumber,
                        company: shipment.receiver.name,
                        email: shipment.receiver.email,
                        name: shipment.receiver.contactName,
                        phoneNumber: shipment.receiver.phoneNumber,
                        private:
                            shipment.receiver.type === "COMPANY" ? false : true,
                        vatNumber: shipment.receiver.vatNumber,
                    },
                    countryCode: shipment.receiver.address.countryCode,
                    dhlPalletExchangeNumber:
                        shipment.receiver.dhlPalletExchangeCode,
                    otherPickupAddress: false,
                    pickup: null,
                    postalCode: shipment.receiver.address.postalCode,
                    postalTown: shipment.receiver.address.postalTown,
                },
                sender: {
                    addressLine1: shipment.sender.address.line1,
                    addressLine2: shipment.sender.address.line2,
                    addresLine3: shipment.sender.address.line3,
                    contact: {
                        clientNumber: shipment.sender.clientNumber,
                        company: shipment.sender.name,
                        email: shipment.sender.email,
                        name: shipment.sender.contactName,
                        phoneNumber: shipment.sender.phoneNumber,
                        private:
                            shipment.sender.type === "COMPANY" ? false : true,
                        vatNumber: shipment.sender.vatNumber,
                    },
                    countryCode: shipment.sender.address.countryCode,
                    dhlPalletExchangeNumber:
                        shipment.sender.dhlPalletExchangeCode,
                    otherPickupAddress: false,
                    pickup: null,
                    postalCode: shipment.sender.address.postalCode,
                    postalTown: shipment.sender.address.postalTown,
                },
            };
            context.updateOrder(mappedShipment);
            setOrder(mappedShipment);
            setLoading(false);
        } else {
            context.updateOrder({
                importedOrderId: null,
                draftId: null,
            });
            setLoading(false);
            setDraft(null);
        }
    }, [props.draftId, props.importedOrderId, props.order]);

    useEffect(() => {
        if (order?.sender && order?.receiver && order?.packages) {
            const onlyDocuments = order.packages.reduce((acc, pkg) => {
                if (
                    pkg.type !== "envelope" ||
                    pkg.envelopeContents !== "documents"
                ) {
                    acc = false;
                }
                return acc;
            }, true);
            if (
                !onlyDocuments &&
                (euMemberList.indexOf(order.sender.countryCode) < 0 ||
                    euMemberList.indexOf(order.receiver.countryCode) < 0)
            ) {
                setCustomsRequired(true);
            } else {
                setCustomsRequired(false);
            }
        }
    }, [order]);

    function clear() {
        if (routeRef.current) {
            routeRef.current.clear();
        }
        if (packagesRef.current) {
            packagesRef.current.clear();
        }
        if (informationRef.current) {
            informationRef.current.clear();
        }
    }

    async function saveDraft() {
        try {
            if (draft?.id) {
                await Api.saveDraft({
                    draftId: draft.id,
                    data: context.order,
                });
            } else {
                await Api.createDraft({
                    draft: context.order,
                });

                context.setUser(await Api.getCurrentUser());
            }
            clear();
            context.clearOrder();
            props.history.replace("/create");
        } catch (error) {
            setMessage(`Ett fel inträffade när din försändelse sparades.`);
        }
    }

    function removeDraft() {
        let confirm = window.confirm(
            "Är du säker på att du vill ta bort denna försändelse? Informationen kommer inte gå att få tillbaka.",
        );
        if (!confirm) {
            return;
        }
        clear();
        // setCanSave(false);
        Api.removeDraft({
            draftId: draft.id,
        })
            .then(async () => {
                context.setUser(await Api.getCurrentUser());
                context.clearOrder();
                props.history.replace("/create");
            })
            .catch(() => {
                console.error("Error removing draft");
            });
    }

    function search() {
        if (!validate()) {
            return;
        } else {
            setQuoteLoading(true);
        }

        shipmentSearchRef.current.search(desiredPickupDate, {
            carrier: receiverPaysCarrier,
            accountNumber: receiverPaysAccountNumber,
            contactId: receiverPaysContactId,
        });
    }

    function validate() {
        let valid = true;

        if (!routeRef?.current?.validate()) {
            valid = false;
        }
        if (!packagesRef.current.validate()) {
            valid = false;
        }
        if (!informationRef.current.validate()) {
            valid = false;
        }
        return valid;
    }

    useEffect(() => {
        if (order) {
            if (!props.draftId) {
                order.draftId = null;
            }
            if (!props.importedOrderId) {
                order.importedOrderId = null;
            }
            context.updateOrder(order);
        }
    }, [order]);

    useEffect(() => {
        if (
            (userSettings.receiverPays &&
                !userSettings.receiverPays.subscriptionEndsAt) ||
            new Date() < new Date(userSettings.receiverPays?.subscriptionEndsAt)
        ) {
            setReceiverPayIsActive(true);
        }
    }, [userSettings.receiverPays]);

    if (loading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    function onRouteChanged(route) {
        setOrder({
            ...order,
            sender: route.sender,
            receiver: route.receiver,
        });

        if (!route.sender || !route.receiver) {
            return;
        }
    }

    function onInformationChanged(information) {
        setOrder({
            ...order,
            information,
        });
    }

    function onPackagesChanged(packages) {
        setOrder({
            ...order,
            packages: packages,
        });
    }

    function setDap() {
        setOrder({
            ...order,
            information: {
                ...order.information,
                customsTerms: incoTerms.dap.code,
            },
        });
    }

    if (!order) {
        return null;
    }

    return (
        <Container>
            <Title>Ny försändelse</Title>
            <Content>
                <Route
                    sender={order.sender || order.route?.from}
                    receiver={order.receiver || order.route?.to}
                    onChange={onRouteChanged}
                    invalidParams={invalidRouteParams}
                    ref={routeRef}
                />
                {receiverPayIsActive && <ReceiverPays />}
                <ShipmentPackages
                    packages={order.packages}
                    onChange={onPackagesChanged}
                    ref={packagesRef}
                />
                <ShipmentInformation
                    information={order.information}
                    onChange={onInformationChanged}
                    ref={informationRef}
                    customsRequired={customsRequired}
                />
                {pickupDates && (
                    <div className="pickupDate">
                        <div className="title">Önskad upphämtningsdag</div>
                        <Input
                            type="dropdown"
                            onChange={setDesiredPickupDate}
                            options={pickupDates}
                        ></Input>
                    </div>
                )}
                <div className="buttons search">
                    <button
                        className="c-button c-button--raised"
                        onClick={() => {
                            search();
                        }}
                        disabled={quoteLoading}
                    >
                        {receiverPaysAccountNumber || receiverPaysCarrier
                            ? "Sök frakt"
                            : "Sök priser"}
                    </button>
                    {canSave && context.user.customer.settings.drafts && (
                        <button
                            className="c-button c-button--ghost"
                            onClick={() => {
                                saveDraft();
                            }}
                        >
                            Spara
                        </button>
                    )}
                    {draft && draft.id && (
                        <button
                            className="c-button c-button--text"
                            onClick={() => {
                                removeDraft();
                            }}
                        >
                            Ta bort
                        </button>
                    )}
                    {message && (
                        <div className="message">
                            {message} Ring oss på{" "}
                            <a
                                href={`tel:${context.user.brand.supportPhoneNumber}`}
                            >
                                {context.user.brand.supportPhoneNumber}
                            </a>{" "}
                            så hjälper vi dig!
                        </div>
                    )}
                    {context.user.customer.settings.searchEmissions && (
                        <div className="sort">
                            <Input
                                type="dropdown"
                                value={sort}
                                onChange={setSort}
                                options={[
                                    {
                                        title: "Sortera på pris",
                                        value: "PRICE",
                                    },
                                    {
                                        title: "Sortera på leverans",
                                        value: "DELIVERY",
                                    },
                                    {
                                        title: "Sortera på miljö",
                                        value: "EMISSION",
                                    },
                                ]}
                            />
                        </div>
                    )}
                </div>
                <ShipmentSearch
                    invalidParams={setInvalidRouteParams}
                    ref={shipmentSearchRef}
                    history={props.history}
                    onSetDap={setDap}
                    sort={sort}
                    setQuoteLoading={setQuoteLoading}
                    receiverPays={{
                        receiverPaysCarrier,
                        receiverPaysAccountNumber,
                        receiverPaysContactId,
                    }}
                />
            </Content>
        </Container>
    );
}

export default CreateQuotation;
