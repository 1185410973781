function getDHLExpressDisplayData(productCode) {
    switch (productCode) {
        case "U":
        case "D": {
            return {
                name: "Utrikes",
                originalName: "DHLExpress International",
                isExpress: true,
                tags: [],
                isDeliveryPoint: false,
            };
        }
        case "W": {
            return {
                name: "Utrikes Ekonomi",
                originalName: "DHLExpress Economy",
                tags: [],
                isDeliveryPoint: false,
            };
        }
        case "N": {
            return {
                name: "Inrikes",
                originalName: "DHLExpress Domestic",
                isExpress: true,
                tags: [],
                isDeliveryPoint: false,
            };
        }
        default: {
            console.warn(
                "Did not find match for product code for DHL express",
                {
                    productCode,
                },
            );
            return {
                name: "DHL Express",
                originalName: "DHL Express",
                tags: [],
            };
        }
    }
}

export { getDHLExpressDisplayData };
