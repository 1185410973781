import Modal from "../Modal";
import React from "react";

const ConfirmModal = ({ isOpen, setIsOpen, onConfirm, title, description }) => {
    return (
        <Modal
            title={title}
            description={description}
            maxWidth="max-w-xl"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <div className="flex justify-start mt-4">
                <button
                    className="c-button--raised c-button"
                    onClick={() => onConfirm()}
                >
                    Bekräfta
                </button>
                <button
                    className="c-button c-button--ghost delete"
                    onClick={() => setIsOpen(false)}
                >
                    Avbryt
                </button>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
