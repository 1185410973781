import React from "react";

const Context = React.createContext({
    order: {},
    quotation: {},
    search: {},
    user: {
        brand: {},
    },
    socket: {},
    ticketNotificationCount: 0,
    supportNotificationCount: 0,
    setTicketNotificationCount: (count) => {},
    setSupportNotificationCount: (count) => {},
    setExpiryIntegrations: () => {},
    integrationsExpiry: [],
    setUser: (user) => {},
    setSocket: (socket) => {},
    updateOrder: (order) => {},
    setQuotation: (quotation) => {},
    updateSearch: (search) => {},
    setLayout: (layout) => {},
    clearOrder: () => {},
    clearUserData: () => {},
    getApiOrderObject: () => {},
});

export default Context;
