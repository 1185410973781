import Input from "components/OldInput";
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import styled from "styled-components";

let Container = styled.div`
    display: inline-flex;
    flex-direction: column;

    > .message {
        font-weight: bold;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
    }

    &.is-invalid {
        > .inputContainer {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }
    }

    &.is-disabled {
        > .inputContainer {
            background: rgb(235, 235, 228);
            border: 1px solid rgba(0, 0, 0, 0.05);

            > input {
                color: rgba(0, 0, 0, 0.5);
                background: transparent;
            }
        }
    }

    > .inputContainer {
        display: flex;
        gap: 1rem;

        > .addDescription {
            display: flex;
            gap: 1rem;
        }

        > .list {
            display: flex;
            padding: 0.5rem 0;
            gap: 0.5rem;

            > .description {
                display: inline-flex;
                align-items: center;
                background: #b3b3ed;
                padding: 0.2rem 1rem;
                border-radius: 1rem;

                > .text {
                    font-weight: bold;
                }

                > .remove {
                    width: 12px;
                    height: 12px;
                    margin-left: 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    > svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        > .freetext {
            display: flex;
            gap: 0.5rem;
            align-items: center;
        }
    }

    > .buttons {
        padding: 1rem 0;
    }

    > .message {
        color: rgba(255, 0, 0, 0.6);
    }
`;

function Component(props, ref) {
    const [message, setMessage] = useState("");
    const [disabled, setDisabled] = useState();
    const [value, setValue] = useState(props.value || "");
    const [selectedOption, setSelectedOption] = useState();
    const [options, setOptions] = useState([]);
    const [showFreetext, setShowFreetext] = useState(false);
    const descriptionRef = useRef();

    useEffect(() => {}, []);

    useEffect(() => {
        setMessage(props.message);
    }, [props.message]);

    useEffect(() => {
        setOptions(props.options);
        if (props.options.length === 1 && !props.freetext) {
            setValue(props.options[0]);
        } else if (props.options.length > 1) {
            setSelectedOption(props.options[0]);
        }
    }, [props.options]);

    useEffect(() => {
        if (options.length > 1) {
            if (selectedOption) {
                setValue(selectedOption);
            } else {
                setValue("");
            }
        }
    }, [selectedOption]);

    useEffect(() => {
        setDisabled(props.disabled || false);
    }, [props.disabled]);

    useEffect(() => {
        props.onChange(value);
    }, [value]);

    function validate() {
        if (!value) {
            setMessage("Fältet är obligatoriskt.");
            return false;
        } else {
            setMessage("");
        }
        return true;
    }
    function clear() {}

    useImperativeHandle(ref, () => {
        return {
            validate: validate,
            clear: clear,
            value: () => value,
        };
    });

    function getOptions() {
        let list = options.map((option) => {
            return {
                title: option,
                value: option,
            };
        });
        if (props.freetext) {
            list.push({
                title: "Fritext",
                value: "",
            });
        }

        return list;
    }

    if (!options) {
        return null;
    }

    return (
        <Container className={disabled ? " is-disabled" : ""}>
            <div className="inputContainer">
                {options.length > 1 && (
                    <Input
                        type="dropdown"
                        options={getOptions()}
                        onChange={setSelectedOption}
                    />
                )}
                {options.length === 1 && !props.freetext && (
                    <div className="value">{options[0]}</div>
                )}
                {(selectedOption === "" ||
                    (options.length === 0 && props.freetext)) && (
                    <div className="freetext">
                        <Input type="text" onChange={setValue} value={value} />
                    </div>
                )}
            </div>
            {message && <div className="message">{message}</div>}
        </Container>
    );
}

export default forwardRef(Component);
