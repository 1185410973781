import React from "react";
import ReceiverPaysTable from "../ReceiverPaysTable";
import moment from "moment";

function ReceiverPaysProfitTable(props) {
    const renderHeader = () => (
        <thead>
            <tr>
                <th>Kund-ID</th>
                <th>Namn</th>
                <th>Abonnemang</th>
                <th>Period</th>
                <th>Fraktsedlar</th>
                <th>Pris</th>
                <th></th>
            </tr>
        </thead>
    );
    const renderRow = (receiverPaysData, formatPrice) => (
        <tr key={`receiverPays_profit_row_${receiverPaysData.customerId}`}>
            <td>{receiverPaysData.customerId}</td>
            <td>{receiverPaysData.customerName}</td>
            <td>
                {receiverPaysData.customerSettingsReceiverPays
                    ?.subscriptionType ?? "-"}{" "}
                /{" "}
                {receiverPaysData.customerSettingsReceiverPays?.billingType ??
                    "-"}
            </td>
            <td>{`${moment(receiverPaysData.intervalFrom).format(
                "YYYY-MM-DD",
            )} - ${moment(receiverPaysData.intervalTo).format(
                "YYYY-MM-DD",
            )}`}</td>
            <td>{receiverPaysData.shipmentLabels}</td>
            <td>{formatPrice(receiverPaysData.totalPrice)}</td>
            <td />
        </tr>
    );
    return (
        <ReceiverPaysTable
            renderHeader={renderHeader}
            renderRow={renderRow}
            initialDates={{
                start: moment().subtract(30, "day"),
                end: moment(),
            }}
        />
    );
}
export default ReceiverPaysProfitTable;
