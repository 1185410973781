import Context from "context/Global";
import React from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    > .modal {
        width: 50vw;

        display: flex;
        flex-direction: column;

        box-shadow: rgb(0 0 0 / 20%) 0px 1px 6px 0px;
        padding: 32px;
        border-radius: 8px;
        box-sizing: border-box;

        > .title {
            font-size: 2rem;
        }

        > .text {
        }

        > .buttons {
            padding-top: 1rem;
        }
    }
`;

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error);
    }

    render() {
        if (this.state.hasError) {
            return (
                <Container>
                    <div className="modal">
                        <div className="title">
                            Ett fel inträffade, försök igen
                        </div>
                        <div className="text">
                            Om problemet består, kontakta oss på{" "}
                            <a
                                href={`mailto:${this.context.user.customer.brand.supportEmail}`}
                            >
                                {this.context.user.customer.brand.supportEmail}
                            </a>{" "}
                            eller{" "}
                            <a
                                href={`tel:${this.context.user.customer.brand.supportPhoneNumber}`}
                            >
                                {
                                    this.context.user.customer.brand
                                        .supportPhoneNumber
                                }
                            </a>{" "}
                            så hjälper vi er!
                        </div>
                        <div className="buttons">
                            <a href="/" className="c-button c-button--raised">
                                Gå till startsidan
                            </a>
                        </div>
                    </div>
                </Container>
            );
        }

        return this.props.children;
    }
}

ErrorBoundary.contextType = Context;

export default ErrorBoundary;
