import Modal from "components/Modal";
import useSaveOrders from "hooks/batch/useSaveOrders";
import React, { useState, useRef } from "react";
import toast from "react-hot-toast";
import { useNotificationSettingsForm } from "views/Batch/hooks/useNotificationSettingsForm";
import useBatchStore from "views/Batch/store";
import CompanyForm from "views/Drafts/Create/Route/CompanyForm";

const FreightPartyModal = ({ setIsOpen, isOpen, type, commonAddress }) => {
    const [company, setCompany] = useState(commonAddress || {});
    const [loading, setLoading] = useState(false);
    const freightPartyRef = useRef();

    const { saveOrders } = useSaveOrders();
    const selectedOrders = useBatchStore((state) => state.selectedOrders);

    const isSender = type === "sender";
    const isReceiver = type === "receiver";

    const {
        areEmailsValid,
        emailNotificationForm,
        mergeNotificationSettings,
        setSenderEmail,
        setReceiverEmail,
    } = useNotificationSettingsForm(null, type === "sender", type !== "sender");

    const updateSenderNotification = (sender) => {
        setSenderEmail(sender.contact?.email, true);
    };
    const updateReceiverNotification = (receiver) => {
        setReceiverEmail(receiver.contact?.email, true);
    };

    const handleSetCompany = (company) => {
        setCompany(company);
        if (isSender) {
            updateSenderNotification(company);
        } else if (isReceiver) {
            updateReceiverNotification(company);
        }
    };

    function requireEmail() {
        return company?.countryCode !== "SE";
    }

    async function saveFreightPartyOnClick() {
        if (!freightPartyRef.current.validate() || !areEmailsValid) {
            return;
        }

        const newValue = () => {
            if (type === "sender") {
                return { sender: company };
            } else if (type === "receiver") {
                return { receiver: company };
            }
        };

        setLoading(true);
        try {
            const updateOrdersData = mergeNotificationSettings(
                selectedOrders.map((order) => ({
                    ...order,
                    data: { ...order.data, ...newValue() },
                })),
                type,
            );
            await saveOrders(updateOrdersData);
        } catch (error) {
            toast.error("Kunde inte uppdatera, försök igen senare");
        } finally {
            setLoading(false);
            setIsOpen(false);
        }
    }

    return (
        <Modal
            title={
                type === "sender"
                    ? "Avsändare"
                    : type === "receiver"
                    ? "Mottagare"
                    : ""
            }
            description={
                type === "sender"
                    ? "Ersätt avsändare för valda försändelser."
                    : type === "receiver"
                    ? "Ersätt mottagare för valda försändelser."
                    : ""
            }
            maxWidth="max-w-7xl"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <CompanyForm
                onChange={handleSetCompany}
                // Spread the company object to maintain state immutability
                company={{ ...company }}
                ref={freightPartyRef}
                enablePrivateCustomer={isReceiver}
                requireEmail={requireEmail()}
                enableDifferentPickupAddress={isSender}
            />

            <div>
                <h2 className="py-3 text-lg font-semibold">
                    Bokningsbekräftelse
                </h2>
                <div className="border-1 rounded-sm p-4">
                    <div className="w-1/2">{emailNotificationForm}</div>
                </div>
            </div>
            <div className="flex justify-end mt-4">
                <button
                    className="c-button--raised c-button"
                    onClick={saveFreightPartyOnClick}
                >
                    {loading ? "Sparar..." : "Ersätt"}
                </button>
                <button
                    className="c-button c-button--ghost delete"
                    onClick={() => setIsOpen(false)}
                >
                    Avbryt
                </button>
            </div>
        </Modal>
    );
};

export default FreightPartyModal;
