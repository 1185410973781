import Number from "components/Input/Number2";
import Loader from "components/Loader/Loader";
import Table from "components/Table";
import moment from "moment";
import "moment/locale/sv";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import toast from "react-hot-toast";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;

    > .title {
        font-size: 2rem;
        padding: 1rem;
    }

    > .details {
        display: grid;
        grid-template-columns: auto auto;
        width: fit-content;
        padding: 0 1rem;

        > .label {
            font-weight: bold;
            padding-right: 1rem;
        }
    }

    > .config {
        padding: 0 1rem;
        margin-top: 1rem;

        > .label {
            font-weight: bold;
            padding-right: 1rem;
        }
        > .data {
            margin-bottom: 1rem;
        }
    }
`;

function Component({ userId }) {
    const [user, setUser] = useState(null);
    const [budget, setBudget] = useState(null);
    const [demoGoal, setDemoGoal] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchUser();
    }, []);

    useEffect(() => {
        if (user) {
            setBudget(user.config?.sales?.budget || 0);
            setDemoGoal(user.config?.sales?.demoGoal || 0);
        }
    }, [user]);

    const budgetChanged = (user?.config?.sales?.budget || 0) !== (budget || 0);
    const demoGoalChanged =
        (user?.config?.sales?.demoGoal || 0) !== (demoGoal || 0);

    async function fetchUser() {
        setUser(await Api.getUser({ userId }));
    }

    async function saveSalesConfig() {
        if (typeof budget === "number" && typeof demoGoal === "number") {
            try {
                setLoading(true);
                await Api.updateUserConfig({
                    cancelToken: null,
                    userId: user.id,
                    config: {
                        sales: {
                            budget: budget,
                            demoGoal: demoGoal,
                        },
                    },
                });
                toast.success("Användarens konfiguration uppdaterad.");
                try {
                    await fetchUser();
                } catch {
                    toast.error("Något gick fel. Kunde inte hämta användaren.");
                }
            } catch {
                toast.error(
                    "Något gick fel. Kunde inte uppdatera användares konfiguration.",
                );
            } finally {
                setLoading(false);
            }
        }
    }

    if (!user) {
        return <Loader></Loader>;
    }

    return (
        <Container>
            <div className="title">{user.name}</div>
            <div className="details">
                <div className="label">Roll</div>
                <div className="data">{user.role}</div>
                <div className="label">E-post</div>
                <div className="data">{user.email}</div>
                <div className="label">Telefon</div>
                <div className="data">{user.phoneNumber}</div>
            </div>
            <div className="config">
                <div className="label">Budget (antal nya kunder per månad)</div>
                <div className="data">
                    <Number min={0} value={budget} onChange={setBudget} />
                </div>
                <div className="label">Demo (antal demos per vecka)</div>
                <div className="data">
                    <Number min={0} value={demoGoal} onChange={setDemoGoal} />
                </div>
                <button
                    className="button c-button c-button--raised"
                    disabled={(!budgetChanged && !demoGoalChanged) || loading}
                    onClick={saveSalesConfig}
                >
                    Spara
                </button>
            </div>
            <div className="title">Kunder</div>
            <Table>
                <thead>
                    <tr>
                        <th>Namn</th>
                        <th>E-post</th>
                        <th>Skapad</th>
                    </tr>
                </thead>
                <tbody>
                    {user?.clients?.map((client) => {
                        return (
                            <tr key={client.id}>
                                <td>
                                    <Link to={`/admin/customers/${client.id}`}>
                                        {client.name}
                                    </Link>
                                </td>
                                <td>{client.contactEmail}</td>
                                <td>{moment(client.created).calendar()}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </Container>
    );
}

export default Component;
