import Input from "components/OldInput";
import Loader from "components/Loader/Loader";
import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    > .title {
        font-size: 2rem;
        padding: 1rem;
    }
    > .description {
        padding: 0 1rem;
    }

    > .form {
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    > .buttons {
        padding: 1rem;
    }

    ul {
    padding: 1rem 0;
    > li {
        list-style-type: disc;
        list-style-position: outside;
        margin-left: 2rem;
    }

    h2 {
        font-size:20px;
    }

    > .error {
        padding: 0 1rem;
        color: #a00;
        font-weight: bold;
    }
`;

function Component(props) {
    const context = useContext(Context);
    const [formData, setFormData] = useState({});
    const [plans, setPlans] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    async function loadIntegration() {
        setPlans(
            await Api.getIntegrationPlans({
                integrationId: 1,
            }),
        );
    }

    const addStarwebIntegration = async () => {
        const customerId = context.user.customerId;
        const data = {
            ...formData,
            planId: plans ? plans[0].id : null,
        };
        await Api.createCustomerIntegration({ customerId, data })
            .then((response) => {
                props.history.push(`/addons/${response.id}`);
            })
            .catch((error) => {
                console.log(error);
                setErrorMessage(error.message);
            });
    };

    useEffect(() => {
        loadIntegration();
    }, []);

    if (!plans) return <Loader />;

    return (
        <Container>
            <div className="title">Lägg till Starweb</div>
            <div className="ml-4">
                För att använda Starweb tillsammans med Zendr måste en
                aktivering göras hos Starwebs API. Kontakta deras kundservice på{" "}
                <strong>support@starweb.se</strong> och be dem aktivera
                API-funktionen på ert konto.
            </div>
            <h2 className="ml-4 mt-5">Aktivera webhooks:</h2>
            <ul className="steps mb-5">
                <li>
                    Öppna Starweb och gå till <strong>Settings</strong>. Hitta,
                    aktivera och öppna <strong>Webhook</strong>.
                </li>
                <li>
                    Skapa en ny webhook och skriv in detta i fältet för URL:
                    <br />
                    <strong>
                        {process.env.REACT_APP_API_URL}
                        /api/wh/R9qSyZTouIg29lOpTF0s03xHqP77QTLRCa76wxm4eJxxDXmfwlXZQ2t5DbVjruoW
                    </strong>
                </li>
                <li>
                    Efter att URL:en ovan är det viktigt att se till att "event"
                    är satt till "Order Created".
                </li>
                <li>Spara webhooken.</li>
            </ul>

            <h2 className="ml-4 mt-3">Aktivera Starweb API:</h2>
            <ul className="steps mb-5">
                <li>
                    Öppna Starweb och gå till <strong>Settings</strong>. Hitta,
                    aktivera och öppna <strong>API</strong>.
                </li>
                <li>
                    Lägg till ett Client ID (det du vill kalla API-kopplingen).
                    <br />
                    Välj Butiksägare som API Group eller någon annan som har
                    rättigheter till <strong>Orders</strong>.<br />
                    När du sparar får du tillgång till en Client Secret.
                </li>
                <li>Lägg in värdena ovan i fälten nedan.</li>
            </ul>
            {plans.length > 0 && (
                <>
                    <div className="form">
                        <Input
                            type="list"
                            onChange={setFormData}
                            object={{
                                type: {
                                    type: "text",
                                    hidden: true,
                                    value: "Starweb",
                                },
                                clientId: {
                                    type: "text",
                                    hidden: false,
                                    title: "Client ID",
                                    placeholder: "Ange ditt client ID...",
                                },
                                clientSecret: {
                                    type: "text",
                                    hidden: false,
                                    title: "Client Secret",
                                    placeholder: "Ange din client secret...",
                                },
                                shopIdentifier: {
                                    type: "text",
                                    hidden: false,
                                    title: "Shop Identifier",
                                    placeholder:
                                        "Namnet på din shop i Starweb...",
                                },
                            }}
                        />
                    </div>
                    <div className="buttons">
                        <button
                            className="c-button c-button--raised"
                            disabled={loading}
                            onClick={addStarwebIntegration}
                        >
                            Aktivera
                        </button>
                    </div>
                </>
            )}

            {errorMessage && <div className="error">{errorMessage}</div>}
        </Container>
    );
}

export default Component;
