import React from "react";
import { useTranslation } from "react-i18next";

const TrackingDetails = ({ events }) => {
    const { t } = useTranslation();
    return (
        <div className="mt-4 w-full">
            {events?.length > 0 && (
                <div className="flex w-full flex-col gap-3">
                    <div className="flex w-full flex-col ">
                        <div className="overflow-x-auto overflow-y-hidden rounded-md">
                            <table className="w-full border-collapse divide-y divide-gray-200 rounded-md ">
                                <thead className="rounded-md border-b border-secondary-500 bg-secondary-400">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="p-4 text-left text-xs font-medium uppercase tracking-wider text-secondary-800"
                                        >
                                            {" "}
                                            {t("tracking.details.date")}{" "}
                                        </th>
                                        <th
                                            scope="col"
                                            className="p-4 text-left text-xs font-medium uppercase tracking-wider text-secondary-800"
                                        >
                                            {" "}
                                            {t(
                                                "tracking.details.location",
                                            )}{" "}
                                        </th>
                                        <th
                                            scope="col"
                                            className="p-4 text-left text-xs font-medium uppercase tracking-wider text-secondary-800"
                                        >
                                            {" "}
                                            {t("tracking.details.event")}{" "}
                                        </th>
                                    </tr>
                                </thead>
                                {events?.map(
                                    (
                                        { date, time, location, description },
                                        index,
                                    ) => (
                                        <tr
                                            key={index}
                                            className="border-b border-secondary-500"
                                        >
                                            <td className="flex flex-col whitespace-nowrap bg-white px-6 py-4 text-sm  text-secondary-800">
                                                <p className="font-bold">
                                                    {date}
                                                </p>
                                                <p>{time || "N/A"}</p>
                                            </td>
                                            <td className="whitespace-nowrap bg-white px-6 py-4 text-sm font-bold text-secondary-800">
                                                {location || "N/A"}
                                            </td>
                                            <td className="whitespace-nowrap bg-white px-6 py-4 text-sm text-secondary-800">
                                                {description || "N/A"}
                                            </td>
                                        </tr>
                                    ),
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TrackingDetails;
