import React, { useEffect, useState } from "react";
import Api from "services/Api/Api";
import moment from "moment";
import Loader from "components/Loader/Loader";
import Table from "components/Table";
import ProfitIcon from "components/ProfitIcon";
import InputField from "components/OldInput/Text";
import DateRange from "components/Input/DateRange";
import toast from "react-hot-toast";
import Pagination from "components/Pagination";

function ReceiverPaysTable({
    renderHeader,
    renderRow,
    initialDates,
    maxLengthDays,
}) {
    const limit = 10;
    const [receiverPaysData, setReceiverPaysData] = useState(null);
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [searchCustomer, setSearchCustomer] = useState("");
    const [dateInterval, setDateInterval] = useState(initialDates);
    const [isSearching, setIsSearching] = useState(false);
    const [page, setPage] = useState(1);

    const loadReceiverPayList = async (start, end, search) => {
        setReceiverPaysData(null);
        setTotalRevenue(0);
        setPage(1);
        try {
            const { customerData, totalRevenue } =
                await Api.getReceiverPaysList(
                    start.startOf("day").format("YYYY-MM-DD HH:mm"),
                    end.endOf("day").format("YYYY-MM-DD HH:mm"),
                    search,
                );
            setReceiverPaysData(customerData);
            setTotalRevenue(totalRevenue);
        } catch (error) {
            toast.error(
                "Något gick fel när vi hämtade mottagarfrakt. Försök igen.",
            );
        }
    };

    useEffect(() => {
        searchReceiverPays(dateInterval.start, dateInterval.end);
    }, []);

    const changeDates = ({ start, end }) => {
        setDateInterval((value) => {
            const newInterval = {
                start: start ? moment(start) : value.start,
                end: end ? moment(end) : value.end,
            };
            loadReceiverPayList(newInterval.start, newInterval.end);
            return newInterval;
        });
    };

    const searchReceiverPays = async () => {
        setIsSearching(true);
        await loadReceiverPayList(
            dateInterval.start,
            dateInterval.end,
            searchCustomer,
        );
        setIsSearching(false);
    };

    const formatPrice = (price) => {
        if (price != null) {
            const processedPrice = price.toFixed(2);
            return `${processedPrice} SEK`;
        } else {
            return "-";
        }
    };

    const currentPage = (receiverPaysData ?? [])?.slice(
        (page - 1) * limit,
        page * limit,
    );

    return (
        <div className="w-full flex justify-start flex-col box-border">
            <div className="flex items-center gap-10 p-3">
                <div>
                    <label className="text-sm font-medium">Startdatum</label>
                    <DateRange
                        value={{
                            start: dateInterval.start.format("YYYY-MM-DD"),
                            end: dateInterval.end.format("YYYY-MM-DD"),
                        }}
                        onChange={changeDates}
                        maxLengthDays={maxLengthDays}
                    />
                </div>
                <div className="flex gap-4 h-full items-end self-end">
                    <InputField
                        placeholder="Sök mottagare"
                        maxLength={50}
                        onChange={setSearchCustomer}
                        onClickSearch={searchReceiverPays}
                    />
                    <button
                        onClick={searchReceiverPays}
                        className="px-4 py-2 bg-buttonColor text-white rounded-md hover:bg-primary500 disabled:opacity-50"
                        disabled={!!isSearching}
                    >
                        Sök
                    </button>
                </div>
                <div
                    className="flex-grow flex font-bold justify-end"
                    style={{
                        color: "rgba(100, 200, 100, 1)",
                    }}
                >
                    <ProfitIcon classes={"mr-4"} />
                    {formatPrice(totalRevenue)}
                </div>
            </div>
            {isSearching ? (
                <Loader />
            ) : (
                <>
                    <Table>
                        {renderHeader()}
                        <tbody>
                            {currentPage.map((receiverPayData) =>
                                renderRow(receiverPayData, formatPrice),
                            )}
                        </tbody>
                    </Table>
                    {!currentPage?.length && !isSearching && (
                        <div className="flex justify-center items-center h-32">
                            <p className="text-xl">Inga kunder hittades</p>
                        </div>
                    )}

                    <Pagination
                        setPage={setPage}
                        page={page}
                        maxPage={Math.ceil(
                            (receiverPaysData?.length ?? 1) / limit,
                        )}
                        useUrlParam={false}
                    />
                </>
            )}
        </div>
    );
}

export default ReceiverPaysTable;
