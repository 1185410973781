import create from "zustand";

const useReceiverPaysStore = create((set) => ({
    shouldRefresh: false,
    refreshTableData: () => {
        set((state) => ({ shouldRefresh: !state.shouldRefresh }));
    },
}));

export default useReceiverPaysStore;
