import Context from "context/Global";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.tr`
    cursor: pointer;
    border-bottom: 1px solid rgba(0,0,0,.2);
    text-decoration: none;
    color: #000;

    flex-shrink: 0;

    &:last-child {
        border-bottom: 0;
    }

    > .notifications {
        width: 90px;
        min-width: 90px;

        > .notification {
            position: relative;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;

            > .text {
                opacity: 0;
                pointer-events: none;
                position: absolute;
                transition: all .3s ease;
                background: #fff;
                white-space: nowrap;
                display: flex;
                justify-content: center;
                align-items: center;
                left: calc(100% + 8px);
                box-shadow: 0px 2px 8px 1px rgba(0,0,0,.1);
                padding: 4px 12px;
                border-radius: 4px;
                z-index: 100;
            }

            &:hover {
                .text {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            > .icon {
                border-radius: 100%;
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 48px;
                height: 48px;
                color: #fff;

                > svg {
                    width: 100%;
                    height: 100%;
                }
            }

            &.issue {
                > .icon {
                    background: #e57c1b;
                }
            }

            &.information {
                > .icon {
                    background: #72c3ed;
                }
            }

            &.noPickup {
                > .icon {
                    > svg {
                        margin-left: 2px;
                        margin-top: 4px;
                    }
                }
            }

            &.notPickedUp {
                > .icon {
                    > svg {
                        margin-left: 2px;
                        margin-top: 4px;
                    }
                }
            }
            &.exception {
                > .icon {
                    > svg {
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }

    > .notification {
        padding-left: 1em;
        width: 48px;
        box-sizing: border-box;
        padding-right: 1em;
    }

    .date {
        > .wrapper {
            white-space: nowrap;
            padding: 0.5rem 1rem 0.5rem 0;
        }
    }
    .information {
        > .wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0.5rem 1rem 0.5rem 0;

            .reference {
                white-space: nowrap;
            }

            > img {
                margin-right: 8px;
                width: 60px;
                max-height: 30px;
            }
        }
    }

    .sender, .recipient {
        > .wrapper {
            display: flex;
            flex-direction: column;
            padding: 0.5rem 1rem 0.5rem 0;

            > .receiver {
                font-weight: bold;
                white-space: nowrap;
            }

            > .location {
                white-space: nowrap;
            }
        }
    }

    > .state {
        font-size: 1rem;
        color: rgba(0,0,0,0.8);
        width: 100%;
    }

    &.is-delivered {
        > .state {
            color: var(--color-good);
        }
    }

    &.is-warning {
        > .state {
            color: var(--color-bad);
        }
    }

    &.is-rejected {
        > .state {
            color: var(--color-bad);
        }
    }

    &.is-intransit {
        > .state {
            color: rgba(0,80,180,1);
        }
    }

    &.is-rejected, &.is-new {
        > .price {
            text-decoration: line-through;
        }
    }

    &.is-archiving {
        pointer-events: none;
        opacity: 0.1;
        transition: all .3s ease;
    }

    > .price {
        font-size: 16px;
        white-space: nowrap;
    }

    .menu {
        width: 100px;

        > .button {
            color: rgba(0,0,0,.5);
            padding: 1rem;
            padding-left: 2rem;
            display: inline-flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;

            &:hover {
                color: rgba(0,0,0,1);
            }

            > .menu-wrapper {
                pointer-events: none;
                display: flex;
                justify-content: center;
                align-items: center;

                > .menu-background {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 100;
                }

                > .menu-container {
                    position: absolute;
                    background: #fff;
                    box-shadow 0 0 5px 1px rgba(0,0,0,.1);
                    right: 0;
                    transition: all .3s ease;
                    opacity: 0;
                    z-index: 101;
                    transform: translateY(10px);

                    > .option {
                        padding: 1rem;
                        white-space: nowrap;
                        color: #000;

                        &:hover {
                            background: #eaeaea;
                        }
                    }
                }

                &.is-visible {
                    pointer-events: all;

                    > .menu-container {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
        }

    }


    > .title {
        font-size: 16px;
    }


    &:hover {
        background: rgba(0,0,0,.02);
    }
`;

function Component(props) {
    const [order, setOrder] = useState(props.order);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const context = useContext(Context);

    useEffect(() => {
        setOrder(props.order);
    }, [props.order]);

    function openMenu(ev) {
        ev.stopPropagation();
        setMenuIsOpen(true);
    }

    function closeMenu(ev) {
        ev.stopPropagation();
        setMenuIsOpen(false);
    }

    async function editOrder() {
        context.updateOrder({
            packages: order.packages,
            sender: order.data.sender,
            receiver: order.data.receiver,
            information: order.information,
            customsInvoice: null,
        });
        props.history.push(`/create/${order.id}`);
    }

    async function removeOrder() {
        let confirm = window.confirm(
            "Är du säker på att du vill ta bort denna försändelse? Informationen kommer inte gå att få tillbaka.",
        );
        if (!confirm) {
            return;
        }
        await Api.deleteDraft({
            draftId: order.id,
        });

        if (props.refresh) {
            props.refresh();
        }
    }

    if (!order) {
        return null;
    }

    return (
        <Container
            onClick={() => {
                props.history.push(`/create/${order.id}`);
            }}
        >
            <td className="date">
                <div className="wrapper">
                    {moment(order.created).calendar()}
                </div>
            </td>
            <td className="reference">
                <div className="wrapper">{order.reference}</div>
            </td>
            <td className="sender">
                <div className="wrapper">
                    <div className="receiver">
                        {order.data.sender.contact.private
                            ? order.data.sender.contact.name
                            : order.data.sender.contact.company}
                    </div>
                    <div className="location">
                        {[
                            order.data.sender.postalTown,
                            order.data.sender.countryCode,
                        ]
                            .filter((v) => v)
                            .join(", ")}
                    </div>
                </div>
            </td>
            <td className="recipient">
                <div className="wrapper">
                    <div className="receiver">
                        {order.data.receiver.contact.private
                            ? order.data.receiver.contact.name
                            : order.data.receiver.contact.company}
                    </div>
                    <div className="location">
                        {[
                            order.data.receiver.postalTown,
                            order.data.receiver.countryCode,
                        ]
                            .filter((v) => v)
                            .join(", ")}
                    </div>
                </div>
            </td>
            <td className="menu">
                <div className="button" onClick={openMenu}>
                    <div
                        className={`menu-wrapper ${
                            menuIsOpen ? " is-visible" : ""
                        }`}
                    >
                        <div
                            className="menu-background"
                            onClick={closeMenu}
                        ></div>
                        <div className="menu-container">
                            <div className="option" onClick={removeOrder}>
                                Ta bort
                            </div>
                        </div>
                    </div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M12 18c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z"
                        />
                    </svg>
                </div>
            </td>
        </Container>
    );
}

export default withRouter(Component);
