import Context from "context/Global";
import { match } from "helpers/Match";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import {
    hasExpiredIntegration,
    hasExpireSoonIntegration,
} from "../../utils/ExpiryIntegrationsUtils";
import Alert, { AlertTypes } from "../../components/Alerts/Alert";
import IntegrationStatuses from "../../constants/integrations/IntegrationStatuses";
import Api from "../../services/Api/Api";
import toast from "react-hot-toast";
import { getIntegrationImage } from "../../helpers/Logos";

let Container = styled.div`
    .title {
        padding: 1rem;
        font-size: 2rem;
    }

    > .description {
        padding: 0 1rem;
    }

    .buttons {
        padding: 1rem;
    }

    > .list {
        padding: 0 1rem;
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;

        .item {
            border-radius: 4px;
            width: 300px;
            height: 200px;
            padding: 4rem 1rem;
            background: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            > img {
                max-width: 80%;
                height: 80%;
            }

            &:hover {
                background: #eee;
            }
        }
    }
`;

function Component(props) {
    const context = useContext(Context);
    const [integrations, setIntegrations] = useState(null);

    useEffect(() => {
        context.checkIntegrationsExpiry().then(() => {
            loadIntegrations();
        });
    }, []);

    useEffect(() => {
        if (integrations && integrations.length === 0) {
            props.history.replace("/addons/create");
        }
    }, [integrations]);

    async function loadIntegrations() {
        setIntegrations(
            await Api.getCustomerIntegrations({
                customerId: context.user.customer.id,
            }),
        );
    }

    // filter out expired ones
    const activeIntegrations = integrations?.filter(
        (integration) =>
            !context.integrationsExpiry?.some(
                (item) =>
                    item.integration.type === integration.type &&
                    item.status === IntegrationStatuses.EXPIRED &&
                    item.integration.id === integration.id,
            ),
    );

    return (
        <Container>
            {!!integrations?.length && (
                <>
                    <div className="title font-bold">Tillägg</div>
                    <p className={"description mb-5"}>
                        Här kan du lägga till eller se dina integrationer. Tryck
                        på en integration för att redigera.
                    </p>
                    {hasExpireSoonIntegration(context?.integrationsExpiry) && (
                        <Alert type={AlertTypes.WARNING} className={"pl-4"}>
                            Du har integrationer som är på väg att utgå.
                            Uppdatera nu för att fortsätta importera
                            förändringar automatiskt.
                        </Alert>
                    )}
                    <h2 className={"title text-gray-500"}>
                        Dina integrationer:
                    </h2>
                    <div className="list">
                        {activeIntegrations?.map((integration) => {
                            const expireSoonIntegration =
                                context.integrationsExpiry?.find(
                                    (item) =>
                                        item.status ===
                                            IntegrationStatuses.EXPIRE_SOON &&
                                        item.integration.id === integration.id,
                                );
                            return (
                                <div className={"flex flex-col"}>
                                    <Link
                                        className={`item border-4 ${
                                            expireSoonIntegration &&
                                            "border-orange-500"
                                        }`}
                                        to={`/addons/${integration.id}`}
                                        key={integration.id}
                                    >
                                        <img
                                            src={`/images/icons/${getIntegrationImage(
                                                integration.type,
                                            )}`}
                                            alt={integration.type}
                                        />
                                    </Link>
                                    {expireSoonIntegration && (
                                        <p
                                            className={
                                                "text-center text-gray-400 text-sm mt-2"
                                            }
                                        >
                                            (
                                            {
                                                expireSoonIntegration?.daysUntilExpire
                                            }{" "}
                                            dagar kvar)
                                        </p>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <div className="buttons">
                        <Link
                            to="/addons/create"
                            className="c-button c-button--raised"
                        >
                            Lägg till ny integration
                        </Link>
                    </div>

                    {hasExpiredIntegration(context?.integrationsExpiry) && (
                        <>
                            <h2 className={"title text-gray-500"}>
                                Dina utgångna integrationer:
                            </h2>
                            <div className={"p-4"}>
                                <Alert type={AlertTypes.ERROR}>
                                    Du har integrationer som har utgått.
                                    Uppdatera nu för att fortsätta importera
                                    förändringar automatiskt.
                                </Alert>
                            </div>
                            <div className={"list"}>
                                {context?.integrationsExpiry
                                    ?.filter(
                                        ({ status }) => status === "EXPIRED",
                                    )
                                    .map(({ integration }) => {
                                        return (
                                            <div className={"flex flex-col"}>
                                                <Link
                                                    className="item border-4 border-red-600"
                                                    to={`/addons/${integration.id}`}
                                                    key={integration.id}
                                                >
                                                    <img
                                                        src={`/images/icons/${getIntegrationImage(
                                                            integration.type,
                                                        )}`}
                                                        alt={integration.type}
                                                    />
                                                </Link>
                                                <p
                                                    className={
                                                        "text-center text-gray-400 text-sm mt-2"
                                                    }
                                                >
                                                    (Utgången)
                                                </p>
                                            </div>
                                        );
                                    })}
                            </div>
                        </>
                    )}
                </>
            )}
        </Container>
    );
}

export default Component;
