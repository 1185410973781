import React, { memo } from "react";

const Information = memo(({ information, externalId }) => {
    return (
        <div className="flex flex-col justify-start text-left">
            {externalId && <p>Externt ID: {externalId}</p>}
            {information?.reference && <p>{information?.reference}</p>}
            {information?.description ? (
                <p>
                    {information?.description.length > 20
                        ? `${information?.description.slice(0, 20)}...`
                        : information?.description}
                </p>
            ) : (
                <p className="text-red-400">Ingen beskrivning</p>
            )}
        </div>
    );
});

export default Information;
