import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

import { PickupMessage } from "constants/pickup";

import TruckDrivingImage from "./images/truck-driving.png";

let Container = styled.div`
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background: #fff;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    align-items: flex-start;

    &.has-warning {
        background: rgba(255, 200, 0, 0.2);
        color: rgba(180, 80, 0, 1);
        font-weight: bold;
    }

    &.has-information {
        background: rgba(100, 200, 255, 0.2);
        color: rgba(40, 80, 180, 1);
        font-weight: bold;
    }

    > .pickupContainer {
        > .location {
            padding: 0 16px;
            color: rgba(0, 0, 0, 0.5);
        }

        > .confirmationNumber {
            padding: 0 16px;
            color: rgba(0, 0, 0, 0.5);
        }
    }
`;

function Component(props) {
    const [pickup] = useState(
        props.pickup || {
            data: {
                pickup: {},
            },
            messages: [],
        },
    );

    const [order] = useState(props.order);
    const showWarning = !pickup || pickup.state === "ERROR";
    const showInformation =
        pickup &&
        moment(order.pickupDate).format("YYYY-MM-DD") <
            moment().format("YYYY-MM-DD");

    function renderMessage(message, pickup) {
        switch (message.code) {
            case "UPS_MANUAL_PICKUP": {
                return "Upphämtningen måste bokas av avsändaren på UPS lokala kontor.";
            }
            case "SKIP_PICKUP": {
                if (order.service.productCode?.includes("Varubrev")) {
                    return "Observera att varubrev lämnas hos PostNord Företagscenter. Enstaka upphämtning ej möjligt. Kontakta din kundansvarig vid större volymer";
                }
                return (
                    <div className="pickupContainer" key={message.code}>
                        <div className="information">
                            Denna försändelse bokades utan upphämtning.{" "}
                            {pickup.pickupStopDateTime &&
                                `Stopptid vardagar ${pickup.pickupStopDateTime.slice(
                                    11,
                                    16,
                                )}`}
                        </div>
                        <Link
                            to={`/orders/${order.id}/pickup`}
                            className="bookPickup c-button c-button--raised"
                        >
                            Boka upphämtning nu
                        </Link>
                    </div>
                );
            }
            default: {
                return (
                    <div className="pickupContainer" key={message.code}>
                        <div className="information">
                            {PickupMessage[message.code] ||
                                message.text ||
                                message.code}
                        </div>
                        <Link
                            to={`/orders/${order.id}/pickup`}
                            className="bookPickup c-button c-button--raised"
                        >
                            Boka upphämtning nu
                        </Link>
                    </div>
                );
            }
        }
    }

    function shouldOnlyRenderMessage(pickup) {
        return (
            pickup.messages?.length &&
            pickup.messages.find((message) => {
                return message.code;
            })
        );
    }

    if (pickup.state === null) {
        return (
            <Container
                className={
                    (showWarning ? "has-warning" : "") +
                    (showInformation ? " has-information" : "")
                }
            >
                <div className="pickupContainer">
                    <div className="icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
                            />
                        </svg>
                    </div>
                    <div className="information">
                        Det finns ingen hämtning inbokad för den här
                        försändelsen.
                    </div>
                    <Link
                        to={`/orders/${order.id}/pickup`}
                        className="bookPickup c-button c-button--raised"
                    >
                        Boka upphämtning nu!
                    </Link>
                </div>
            </Container>
        );
    } else if (shouldOnlyRenderMessage(pickup)) {
        return (
            <Container className="has-information">
                {pickup.messages?.map((message) => {
                    return renderMessage(message, pickup);
                })}
            </Container>
        );
    } else if (pickup.state === "OK" && order.pickupDate) {
        return (
            <Container
                className={
                    (showWarning ? "has-warning" : "") +
                    (showInformation ? " has-information" : "")
                }
            >
                <div className="pickupContainer">
                    <div className="icon">
                        <img src={TruckDrivingImage} />
                    </div>
                    <div className="information">
                        {moment(order.pickupDate).calendar(null, {
                            lastDay: "[Igår]",
                            sameDay: "[Idag]",
                            nextDay: "[Imorgon]",
                            lastWeek: "[I] dddd[s]",
                            nextWeek: "[På] dddd",
                            sameElse: "L",
                        })}
                    </div>
                    {pickup.location && (
                        <div className="location">
                            ({pickup.location.addressLine1},{" "}
                            {pickup.location.postalCode},{" "}
                            {pickup.location.postalTown})
                        </div>
                    )}
                    <div className="confirmationNumber">
                        {pickup.confirmationNumber}
                    </div>
                    {moment(order.pickupDate).format("YYYY-MM-DD") <
                        moment().format("YYYY-MM-DD") && (
                        <Link
                            to={`/orders/${order.id}/pickup`}
                            className="bookPickup c-button c-button--flat"
                        >
                            Boka en ny upphämtning här
                        </Link>
                    )}
                </div>
            </Container>
        );
    } else {
        return (
            <Container
                className={
                    (showWarning ? "has-warning" : "") +
                    (showInformation ? " has-information" : "")
                }
            >
                <div className="pickupContainer">
                    <div className="icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
                            />
                        </svg>
                    </div>
                    <div className="information">
                        {pickup.messages?.length === 0 &&
                            "Det finns ingen hämtning inbokad för den här försändelsen."}
                        {pickup.messages?.length > 0 &&
                            pickup.messages?.map((message) => {
                                if (typeof message === "string") {
                                    return PickupMessage[message] || message;
                                } else if (typeof message === "object") {
                                    if (message.code) {
                                        return (
                                            PickupMessage[message.code] ||
                                            message.code
                                        );
                                    }
                                    return (
                                        PickupMessage[message.text] ||
                                        message.text
                                    );
                                }
                            })}
                    </div>
                    <Link
                        to={`/orders/${order.id}/pickup`}
                        className="bookPickup c-button c-button--raised"
                    >
                        Boka upphämtning nu!
                    </Link>
                </div>
            </Container>
        );
    }
}

export default Component;
