import React from "react";

function Component({ order, service, onChange, bookOrder }) {
    return (
        <iframe
            className="h-full w-full"
            src="https://main.d2t3gt1gwkq8gv.amplifyapp.com/"
        ></iframe>
    );
}

export default Component;
