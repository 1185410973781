exports.countryList = [
    { value: "", title: "Välj Land" },
    { value: "SE", title: "Sverige", memberOfEU: true },
    { value: "AF", title: "Afghanistan" },
    { value: "AL", title: "Albanien" },
    { value: "DZ", title: "Algeriet" },
    { value: "VI", title: "Amerikanska Jungfruöarna" },
    { value: "AS", title: "Amerikanska Samoa" },
    { value: "AD", title: "Andorra" },
    { value: "AO", title: "Angola" },
    { value: "AI", title: "Anguilla" },
    { value: "AQ", title: "Antarktis" },
    { value: "AG", title: "Antigua och Barbuda" },
    { value: "AR", title: "Argentina" },
    { value: "AM", title: "Armenien" },
    { value: "AW", title: "Aruba" },
    { value: "AU", title: "Australien" },
    { value: "AZ", title: "Azerbajdzjan" },
    { value: "BS", title: "Bahamas" },
    { value: "BH", title: "Bahrain" },
    { value: "BD", title: "Bangladesh" },
    { value: "BB", title: "Barbados" },
    { value: "BY", title: "Belarus" },
    { value: "BE", title: "Belgien", memberOfEU: true },
    { value: "BZ", title: "Belize" },
    { value: "BJ", title: "Benin" },
    { value: "BM", title: "Bermuda" },
    { value: "BT", title: "Bhutan" },
    { value: "BO", title: "Bolivia" },
    { value: "BA", title: "Bosnien och Hercegovina" },
    { value: "BW", title: "Botswana" },
    { value: "BV", title: "Bouvetön" },
    { value: "BR", title: "Brasilien" },
    { value: "VG", title: "Brittiska Jungfruöarna" },
    { value: "IO", title: "Brittiska territoriet i Indiska Oceanen" },
    { value: "BN", title: "Brunei" },
    { value: "BG", title: "Bulgarien", memberOfEU: true },
    { value: "BF", title: "Burkina Faso" },
    { value: "BI", title: "Burundi" },
    { value: "KY", title: "Caymanöarna" },
    { value: "CF", title: "Centralafrikanska republiken" },
    { value: "CL", title: "Chile" },
    { value: "CO", title: "Colombia" },
    { value: "CK", title: "Cooköarna" },
    { value: "CR", title: "Costa Rica" },
    { value: "CY", title: "Cypern", memberOfEU: true },
    { value: "CW", title: "Curaçao" },
    { value: "DK", title: "Danmark", memberOfEU: true },
    { value: "CD", title: "Demokratiska republiken Kongo" },
    { value: "DJ", title: "Djibouti" },
    { value: "DM", title: "Dominica" },
    { value: "DO", title: "Dominikanska republiken" },
    { value: "EC", title: "Ecuador" },
    { value: "EG", title: "Egypten" },
    { value: "GQ", title: "Ekvatorialguinea" },
    { value: "GB-EN", title: "England (Storbrittanien)" },
    { value: "SV", title: "El Salvador" },
    { value: "CI", title: "Elfenbenskusten" },
    { value: "ER", title: "Eritrea" },
    { value: "EE", title: "Estland", memberOfEU: true },
    { value: "ET", title: "Etiopien" },
    { value: "FK", title: "Falklandsöarna" },
    { value: "FJ", title: "Fiji" },
    { value: "PH", title: "Filippinerna" },
    { value: "FI", title: "Finland", memberOfEU: true },
    { value: "FR", title: "Frankrike", memberOfEU: true },
    { value: "GF", title: "Franska Guyana" },
    { value: "PF", title: "Franska Polynesien" },
    { value: "TF", title: "Franska södra territorierna" },
    { value: "FO", title: "Färöarna" },
    { value: "AE", title: "Förenade Arabemiraten" },
    { value: "GA", title: "Gabon" },
    { value: "GM", title: "Gambia" },
    { value: "GE", title: "Georgien" },
    { value: "GH", title: "Ghana" },
    { value: "GI", title: "Gibraltar" },
    { value: "GR", title: "Grekland", memberOfEU: true },
    { value: "GD", title: "Grenada" },
    { value: "GL", title: "Grönland" },
    { value: "GP", title: "Guadeloupe" },
    { value: "GU", title: "Guam" },
    { value: "GT", title: "Guatemala" },
    { value: "GG", title: "Guernsey" },
    { value: "GN", title: "Guinea" },
    { value: "GW", title: "Guinea Bissau" },
    { value: "GY", title: "Guyana" },
    { value: "HT", title: "Haiti" },
    { value: "HM", title: "Heard- och McDonaldöarna" },
    { value: "HN", title: "Honduras" },
    { value: "HK", title: "Hongkong" },
    { value: "IN", title: "Indien" },
    { value: "ID", title: "Indonesien" },
    { value: "IQ", title: "Irak" },
    { value: "IR", title: "Iran" },
    { value: "IE", title: "Irland", memberOfEU: true },
    { value: "IS", title: "Island" },
    { value: "IM", title: "Isle of Man" },
    { value: "IL", title: "Israel" },
    { value: "IT", title: "Italien", memberOfEU: true },
    { value: "JM", title: "Jamaica" },
    { value: "JP", title: "Japan" },
    { value: "YE", title: "Jemen" },
    { value: "JE", title: "Jersey" },
    { value: "JO", title: "Jordanien" },
    { value: "CX", title: "Julön" },
    { value: "KH", title: "Kambodja" },
    { value: "CM", title: "Kamerun" },
    { value: "CA", title: "Kanada" },
    { value: "IC", title: "Kanarieöarna" },
    { value: "CV", title: "Kap Verde" },
    { value: "KZ", title: "Kazakstan" },
    { value: "KE", title: "Kenya" },
    { value: "CN", title: "Kina" },
    { value: "KG", title: "Kirgizistan" },
    { value: "KI", title: "Kiribati" },
    { value: "CC", title: "Kokosöarna" },
    { value: "KM", title: "Komorerna" },
    { value: "CG", title: "Kongo-Brazzaville" },
    { value: "XK", title: "Kosovo" },
    { value: "HR", title: "Kroatien", memberOfEU: true },
    { value: "CU", title: "Kuba" },
    { value: "KW", title: "Kuwait" },
    { value: "LA", title: "Laos" },
    { value: "LS", title: "Lesotho" },
    { value: "LV", title: "Lettland", memberOfEU: true },
    { value: "LB", title: "Libanon" },
    { value: "LR", title: "Liberia" },
    { value: "LY", title: "Libyen" },
    { value: "LI", title: "Liechtenstein" },
    { value: "LT", title: "Litauen", memberOfEU: true },
    { value: "LU", title: "Luxemburg", memberOfEU: true },
    { value: "MO", title: "Macau" },
    { value: "MG", title: "Madagaskar" },
    { value: "MW", title: "Malawi" },
    { value: "MY", title: "Malaysia" },
    { value: "MV", title: "Maldiverna" },
    { value: "ML", title: "Mali" },
    { value: "MT", title: "Malta", memberOfEU: true },
    { value: "MA", title: "Marocko" },
    { value: "MH", title: "Marshallöarna" },
    { value: "MQ", title: "Martinique" },
    { value: "MR", title: "Mauretanien" },
    { value: "MU", title: "Mauritius" },
    { value: "YT", title: "Mayotte" },
    { value: "MX", title: "Mexiko" },
    { value: "FM", title: "Mikronesiska federationen" },
    { value: "MD", title: "Moldavien" },
    { value: "MC", title: "Monaco" },
    { value: "MN", title: "Mongoliet" },
    { value: "ME", title: "Montenegro" },
    { value: "MS", title: "Montserrat" },
    { value: "MZ", title: "Moçambique" },
    { value: "MM", title: "Myanmar" },
    { value: "MM", title: "Burma" },
    { value: "NA", title: "Namibia" },
    { value: "NR", title: "Nauru" },
    { value: "NL", title: "Nederländerna", memberOfEU: true },
    { value: "AN", title: "Nederländska Antillerna" },
    { value: "NP", title: "Nepal" },
    { value: "NI", title: "Nicaragua" },
    { value: "NE", title: "Niger" },
    { value: "NG", title: "Nigeria" },
    { value: "NU", title: "Niue" },
    { value: "GB-NI", title: "Nordirland (Storbrittanien)" },
    { value: "KP", title: "Nordkorea" },
    { value: "MK", title: "Nordmakedonien" },
    { value: "MP", title: "Nordmarianerna" },
    { value: "NF", title: "Norfolkön" },
    { value: "NO", title: "Norge" },
    { value: "NC", title: "Nya Kaledonien" },
    { value: "NZ", title: "Nya Zeeland" },
    { value: "OM", title: "Oman" },
    { value: "PK", title: "Pakistan" },
    { value: "PW", title: "Palau" },
    { value: "PS", title: "Palestina" },
    { value: "PA", title: "Panama" },
    { value: "PG", title: "Papua Nya Guinea" },
    { value: "PY", title: "Paraguay" },
    { value: "PE", title: "Peru" },
    { value: "PN", title: "Pitcairnöarna" },
    { value: "PL", title: "Polen", memberOfEU: true },
    { value: "PT", title: "Portugal", memberOfEU: true },
    { value: "PR", title: "Puerto Rico" },
    { value: "QA", title: "Qatar" },
    { value: "RO", title: "Rumänien", memberOfEU: true },
    { value: "RW", title: "Rwanda" },
    { value: "RU", title: "Ryssland" },
    { value: "RE", title: "Réunion" },
    { value: "KN", title: "Saint Kitts och Nevis" },
    { value: "LC", title: "Saint Lucia" },
    { value: "VC", title: "Saint Vincent och Grenadinerna" },
    { value: "BL", title: "Saint-Barthélemy" },
    { value: "PM", title: "Saint-Pierre och Miquelon" },
    { value: "SB", title: "Salomonöarna" },
    { value: "WS", title: "Samoa" },
    { value: "SM", title: "San Marino" },
    { value: "SH", title: "Sankta Helena" },
    { value: "SA", title: "Saudiarabien" },
    { value: "CH", title: "Schweiz" },
    { value: "SN", title: "Senegal" },
    { value: "RS", title: "Serbien" },
    { value: "SC", title: "Seychellerna" },
    { value: "SL", title: "Sierra Leone" },
    { value: "SG", title: "Singapore" },
    { value: "GB-SC", title: "Skottland (Storbrittanien)" },
    { value: "SK", title: "Slovakien", memberOfEU: true },
    { value: "SI", title: "Slovenien", memberOfEU: true },
    { value: "SO", title: "Somalia" },
    { value: "ES", title: "Spanien", memberOfEU: true },
    { value: "LK", title: "Sri Lanka" },
    { value: "GB", title: "Storbritannien" },
    { value: "SD", title: "Sudan" },
    { value: "SR", title: "Surinam" },
    { value: "SJ", title: "Svalbard och Jan Mayen" },
    { value: "SE", title: "Sverige", memberOfEU: true },
    { value: "SZ", title: "Swaziland" },
    { value: "ZA", title: "Sydafrika" },
    { value: "KR", title: "Sydkorea" },
    { value: "SS", title: "Sydsudan" },
    { value: "SY", title: "Syrien" },
    { value: "ST", title: "São Tomé och Príncipe" },
    { value: "TJ", title: "Tadzjikistan" },
    { value: "TW", title: "Taiwan" },
    { value: "TZ", title: "Tanzania" },
    { value: "TD", title: "Tchad" },
    { value: "TH", title: "Thailand" },
    { value: "CZ", title: "Tjeckien", memberOfEU: true },
    { value: "TG", title: "Togo" },
    { value: "TK", title: "Tokelauöarna" },
    { value: "TO", title: "Tonga" },
    { value: "TT", title: "Trinidad och Tobago" },
    { value: "TN", title: "Tunisien" },
    { value: "TR", title: "Turkiet" },
    { value: "TM", title: "Turkmenistan" },
    { value: "TC", title: "Turks- och Caicosöarna" },
    { value: "TV", title: "Tuvalu" },
    { value: "DE", title: "Tyskland", memberOfEU: true },
    { value: "US", title: "USA" },
    { value: "UM", title: "USA:s yttre öar" },
    { value: "UG", title: "Uganda" },
    { value: "UA", title: "Ukraina" },
    { value: "HU", title: "Ungern", memberOfEU: true },
    { value: "UY", title: "Uruguay" },
    { value: "UZ", title: "Uzbekistan" },
    { value: "VU", title: "Vanuatu" },
    { value: "VA", title: "Vatikanstaten" },
    { value: "VE", title: "Venezuela" },
    { value: "VN", title: "Vietnam" },
    { value: "BY", title: "Vitryssland" },
    { value: "EH", title: "Västsahara" },
    { value: "GB-WA", title: "Wales (Storbrittanien)" },
    { value: "WF", title: "Wallis- och Futunaöarna" },
    { value: "ZM", title: "Zambia" },
    { value: "ZW", title: "Zimbabwe" },
    { value: "AX", title: "Åland" },
    { value: "AT", title: "Österrike", memberOfEU: true },
    { value: "TP", title: "Östtimor" },
    { value: "TL", title: "Östtimor a.k.a. Timor-Leste" },
];

exports.getCountryName = function (countryCode) {
    let country = exports.countryList.find((c) => {
        return c.value === countryCode;
    });

    if (country) {
        return country.title;
    } else {
        return "";
    }
};

exports.getCountryCode = function (countryName) {
    let country = exports.countryList.find((c) => {
        return c.title === countryName;
    });

    if (country) {
        return country.value;
    } else {
        return "";
    }
};

exports.isMemberOfEU = (countryCode) => {
    let country = exports.countryList.find((country) => {
        return country.value === countryCode;
    });
    if (country) {
        return country.memberOfEU;
    }
    return false;
};

exports.countryPrefixLookup = {
    AF: "+93",
    AL: "+355",
    DZ: "+213",
    AS: "+1-684",
    AD: "+376",
    AO: "+244",
    AI: "+1-264",
    AQ: "+672",
    AG: "+1-268",
    AR: "+54",
    AM: "+374",
    AW: "+297",
    AU: "+61",
    AT: "+43",
    AZ: "+994",
    BS: "+1-242",
    BH: "+973",
    BD: "+880",
    BB: "+1-246",
    BY: "+375",
    BE: "+32",
    BZ: "+501",
    BJ: "+229",
    BM: "+1-441",
    BT: "+975",
    BO: "+591",
    BA: "+387",
    BW: "+267",
    BR: "+55",
    BN: "+673",
    BG: "+359",
    BF: "+226",
    BI: "+257",
    KH: "+855",
    CM: "+237",
    CA: "+1",
    CV: "+238",
    KY: "+1-345",
    CF: "+236",
    TD: "+235",
    CL: "+56",
    CN: "+86",
    CX: "+53",
    CC: "+61",
    CO: "+57",
    KM: "+269",
    CD: "+243",
    CG: "+242",
    CK: "+682",
    CR: "+506",
    CI: "+225",
    HR: "+385",
    CU: "+53",
    CY: "+357",
    CZ: "+420",
    DK: "+45",
    DJ: "+253",
    DM: "+1-767",
    DO: "+1-809 and +1-829  ",
    TP: "+670",
    EC: "+593 ",
    EG: "+20",
    SV: "+503",
    GQ: "+240",
    ER: "+291",
    EE: "+372",
    ET: "+251",
    FK: "+500",
    FO: "+298",
    FJ: "+679",
    FI: "+358",
    FR: "+33",
    GF: "+594",
    PF: "+689",
    GA: "+241",
    GM: "+220",
    GE: "+995",
    DE: "+49",
    GH: "+233",
    GI: "+350",
    GR: "+30",
    GL: "+299",
    GD: "+1-473",
    GP: "+590",
    GU: "+1-671",
    GT: "+502",
    GN: "+224",
    GW: "+245",
    GY: "+592",
    HT: "+509",
    HN: "+504",
    HK: "+852",
    HU: "+36",
    IS: "+354",
    IN: "+91",
    ID: "+62",
    IR: "+98",
    IQ: "+964",
    IE: "+353",
    IL: "+972",
    IT: "+39",
    JM: "+1-876",
    JP: "+81",
    JO: "+962",
    KZ: "+7",
    KE: "+254",
    KI: "+686",
    KP: "+850",
    KR: "+82",
    KW: "+965",
    KG: "+996",
    LA: "+856",
    LV: "+371",
    LB: "+961",
    LS: "+266",
    LR: "+231",
    LY: "+218",
    LI: "+423",
    LT: "+370",
    LU: "+352",
    MO: "+853",
    MK: "+389",
    MG: "+261",
    MW: "+265",
    MY: "+60",
    MV: "+960",
    ML: "+223",
    MT: "+356",
    MH: "+692",
    MQ: "+596",
    MR: "+222",
    MU: "+230",
    YT: "+269",
    MX: "+52",
    FM: "+691",
    MD: "+373",
    MC: "+377",
    MN: "+976",
    MS: "+1-664",
    MA: "+212",
    MZ: "+258",
    MM: "+95",
    NA: "+264",
    NR: "+674",
    NP: "+977",
    NL: "+31",
    AN: "+599",
    NC: "+687",
    NZ: "+64",
    NI: "+505",
    NE: "+227",
    NG: "+234",
    NU: "+683",
    NF: "+672",
    MP: "+1-670",
    NO: "+47",
    OM: "+968",
    PK: "+92",
    PW: "+680",
    PS: "+970",
    PA: "+507",
    PG: "+675",
    PY: "+595",
    PE: "+51",
    PH: "+63",
    PL: "+48",
    PT: "+351",
    PR: "+1-787 or +1-939",
    QA: "+974 ",
    RE: "+262",
    RO: "+40",
    RU: "+7",
    RW: "+250",
    SH: "+290",
    KN: "+1-869",
    LC: "+1-758",
    PM: "+508",
    VC: "+1-784",
    WS: "+685",
    SM: "+378",
    ST: "+239",
    SA: "+966",
    SN: "+221",
    SC: "+248",
    SL: "+232",
    SG: "+65",
    SK: "+421",
    SI: "+386",
    SB: "+677",
    SO: "+252",
    ZA: "+27",
    ES: "+34",
    LK: "+94",
    SD: "+249",
    SR: "+597",
    SZ: "+268",
    SE: "+46",
    CH: "+41",
    SY: "+963",
    TW: "+886",
    TJ: "+992",
    TZ: "+255",
    TH: "+66",
    TK: "+690",
    TO: "+676",
    TT: "+1-868",
    TN: "+216",
    TR: "+90",
    TM: "+993",
    TC: "+1-649",
    TV: "+688",
    UG: "+256",
    UA: "+380",
    AE: "+971",
    GB: "+44",
    US: "+1",
    UY: "+598",
    UZ: "+998",
    VU: "+678",
    VA: "+418",
    VE: "+58",
    VN: "+84",
    VI: "+1-284",
    VQ: "+1-340",
    WF: "+681",
    YE: "+967",
    ZM: "+260",
    ZW: "+263",
};

exports.euMemberList = [
    "SE",
    "BE",
    "BG",
    "CY",
    "DK",
    "EE",
    "FI",
    "FR",
    "GR",
    "IE",
    "IT",
    "HR",
    "LV",
    "LT",
    "LU",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE",
    "CZ",
    "DE",
    "HU",
    "AT",
];
