import Input from "components/OldInput";
import axios from "axios";
import InputCheckbox from "components/Input/Checkbox";
import Loader from "components/Loader/Loader";
import Context from "context/Global";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components";
import OrderRow from "./OrderRow";

let Container = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;

    > .dialog {
        background: #fff;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        min-width: 80vw;
        height: 800px;
        max-height: 100%;

        > .header {
            padding: 1em;
            display: flex;
            flex-direction: row;

            > .input {
                display: flex;
                margin-right: 1rem;
                align-items: center;

                > .title {
                    padding-right: 1rem;
                }
            }

            .pagination {
                display: flex;
                align-items: center;
                margin-left: auto;
                cursor: pointer;

                > .previous,
                > .next {
                    width: 16px;
                    height: 16px;
                    padding: 0 8px;
                    box-sizing: content-box;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    &.is-disabled {
                        color: #aaa;
                        pointer-events: none;
                    }

                    > svg {
                        width: 100%;
                        height: 100%;
                    }
                }
                > .previous {
                    transform: rotateZ(180deg);
                }
            }
        }

        > .orders {
            width: 100%;
            margin-bottom: 16px;
            min-height: 400px;
            overflow: auto;

            > table {
                width: 100%;
                > thead {
                    > tr {
                        > th {
                            background: #fafafa;
                            padding: 1em 0;
                            border-bottom: 1px solid #ddd;
                            border-top: 1px solid #ddd;
                            color: #333;
                            font-weight: normal;

                            &:first-child {
                                padding-left: 1em;
                            }
                            &:last-child {
                                padding-right: 1em;
                            }

                            > .sort {
                                cursor: pointer;
                                display: inline-flex;
                                align-items: center;

                                svg {
                                    width: 12px;
                                    height: 12px;
                                    margin-left: 1rem;
                                }
                            }
                        }
                    }
                }

                > tbody {
                    > .emptyState {
                        > td {
                            text-align: center;
                            font-style: italic;
                        }
                    }

                    > .loadingState {
                        > td {
                            text-align: center;
                        }
                    }
                }
            }
        }

        .footer {
            display: flex;
            justify-content: flex-end;
            padding: 1rem;
            margin-top: auto;
        }
    }
`;

const ascendingArrow = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
    >
        <path d="M24 22h-24l12-20z" />
    </svg>
);
const descendingArrow = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
    >
        <path d="M12 21l-12-18h24z" />
    </svg>
);

function Component(props) {
    const context = useContext(Context);

    const [searchstring, setSearchstring] = useState("");
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [totalOrders, setTotalOrders] = useState(0);
    const [integrations, setIntegrations] = useState([]);
    const [loadingList, setLoadingList] = useState(true);
    const [multiToggleValue, setMultiToggleValue] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState({});
    const [selectedOrdersCount, setSelectedOrdersCount] = useState(0);
    const [sort, setSort] = useState({
        by: "created",
        order: "desc",
    });
    const [orders, setOrders] = useState({
        data: [],
        count: 0,
    });
    const [filteredOrders, setFilteredOrders] = useState([]);
    const cancelTokenRef = useRef();

    const [startDate, setStartDate] = useState(
        sessionStorage.getItem("orderListStartDate") || "",
    );
    const [endDate, setEndDate] = useState(
        sessionStorage.getItem("orderListEndDate") || "",
    );
    const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));

    useEffect(() => {
        setIntegrations(getIntegrations());
    }, []);

    function getIntegrations() {
        return context.user.customer.integrations.filter((integration) => {
            return ["FORTNOX"].includes(integration.type);
        });
    }

    useEffect(() => {
        if (integrations?.length) {
            loadOrders();
        }
    }, [integrations]);

    useEffect(() => {
        if (sort) {
            setFilteredOrders(filterAndSortOrders(orders.data));
        }
    }, [sort]);

    useEffect(() => {
        if (orders) {
            setTotalOrders(orders.count);
            setFilteredOrders(filterAndSortOrders(orders.data));
        }
    }, [orders, searchstring]);

    useEffect(() => {
        if (fromDate) {
            loadOrders();
        }
    }, [fromDate]);

    async function loadOrders() {
        setLoadingList(true);
        if (cancelTokenRef.current) {
            cancelTokenRef.current.cancel();
        }
        cancelTokenRef.current = axios.CancelToken.source();
        let orders = await Api.getOrdersFromCustomerIntegrations({
            customerId: context.user.customer.id,
            cancelToken: cancelTokenRef.current?.token,
            fromDate: fromDate,
        });
        setOrders(orders);
        setLoadingList(false);
    }

    function filterAndSortOrders(orders) {
        return orders
            .filter((order) => {
                if (
                    searchstring &&
                    order.recipientName
                        .toLowerCase()
                        .indexOf(searchstring.toLowerCase()) < 0 &&
                    order.reference
                        .toLowerCase()
                        .indexOf(searchstring.toLowerCase()) < 0
                ) {
                    return false;
                }
                return true;
            })
            .sort((orderA, orderB) => {
                if (!orderA[sort.by]) {
                    return 1;
                }
                if (!orderB[sort.by]) {
                    return -1;
                }
                if (orderA[sort.by] < orderB[sort.by]) {
                    return sort.order === "desc" ? 1 : -1;
                }
                if (orderA[sort.by] > orderB[sort.by]) {
                    return sort.order === "desc" ? -1 : 1;
                }
                return 0;
            });
    }

    async function toggleLimit() {
        // if(limit < 200 && totalOrders > 100) {
        // 	setLimit(200);
        // }
        // else if(limit < 500 && totalOrders > 200) {
        // 	setLimit(500);
        // }
        // else if(limit < 1000 && totalOrders > 500) {
        // 	setLimit(1000);
        // }
        // else if(limit < 5000 && totalOrders > 1000) {
        // 	setLimit(5000);
        // }
        // else {
        // 	setLimit(100);
        // }
    }

    async function previousPage() {
        setOffset(offset - limit < 0 ? 0 : offset - limit);
    }
    async function nextPage() {
        if (offset + limit < totalOrders) {
            setOffset(offset + limit);
        }
    }

    function toggleAllOrders(selectAll) {
        const orderList = {};
        if (selectAll) {
            orders.data.forEach((order) => {
                orderList[
                    `${order.customerIntegrationId}-${order.externalId}`
                ] = order;
            });
        }
        setSelectedOrders(orderList);
    }

    useEffect(() => {
        const selectedCount = Object.keys(selectedOrders).length || 0;
        if (orders.count && selectedCount === orders.data.length) {
            setMultiToggleValue(true);
        } else if (selectedCount > 0) {
            setMultiToggleValue(null);
        } else {
            setMultiToggleValue(false);
        }
        setSelectedOrdersCount(selectedCount);
    }, [selectedOrders, orders]);

    async function importSelectedOrders() {
        await Api.createImportedOrders({
            customerId: context.user.customer.id,
            orders: Object.keys(selectedOrders).map((key) => {
                return selectedOrders[key];
            }),
        });

        props.close(true);
    }

    async function changeSortOrder(sortBy) {
        setSort((sort) => {
            if (sort.by === sortBy) {
                return {
                    by: sortBy,
                    order: sort.order === "desc" ? "asc" : "desc",
                };
            } else {
                return {
                    by: sortBy,
                    order: "asc",
                };
            }
        });
    }

    function removeOrder({ externalId, customerIntegrationId }) {
        setOrders((orders) => {
            const newList = orders.data.filter((order) => {
                return !(
                    order.externalId === externalId &&
                    order.customerIntegrationId === customerIntegrationId
                );
            });
            return {
                count: newList.length,
                data: newList,
            };
        });
    }

    return (
        <Container onClick={props.close}>
            <div className="dialog" onClick={(ev) => ev.stopPropagation()}>
                <div className="header">
                    <div className="input">
                        <Input
                            type="text"
                            onChange={setSearchstring}
                            placeholder={"Sök..."}
                        />
                    </div>
                    <div className="input">
                        <div className="title">Visa</div>
                        <Input
                            type="dropdown"
                            value={fromDate}
                            onChange={setFromDate}
                            options={[
                                {
                                    title: "Dagens",
                                    value: moment().format("YYYY-MM-DD"),
                                },
                                {
                                    title: "Sen igår",
                                    value: moment()
                                        .subtract(1, "day")
                                        .format("YYYY-MM-DD"),
                                },
                                {
                                    title: "2 dagar",
                                    value: moment()
                                        .subtract(2, "day")
                                        .format("YYYY-MM-DD"),
                                },
                                {
                                    title: "3 dagar",
                                    value: moment()
                                        .subtract(3, "day")
                                        .format("YYYY-MM-DD"),
                                },
                                {
                                    title: "1 vecka",
                                    value: moment()
                                        .subtract(1, "week")
                                        .format("YYYY-MM-DD"),
                                },
                                {
                                    title: "2 veckor",
                                    value: moment()
                                        .subtract(2, "week")
                                        .format("YYYY-MM-DD"),
                                },
                            ]}
                        />
                    </div>
                    <div className="pagination">
                        <div
                            className={`previous ${
                                offset === 0 ? "is-disabled" : ""
                            }`}
                            onClick={previousPage}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="currentColor"
                                    d="M6 0l12 12-12 12z"
                                />
                            </svg>
                        </div>
                        <div className="showing" onClick={toggleLimit}>
                            Visar {totalOrders ? offset + 1 : 0} -{" "}
                            {totalOrders < offset + limit
                                ? totalOrders
                                : offset + limit}{" "}
                            av {totalOrders}
                        </div>
                        <div
                            className={`next ${
                                offset + limit > totalOrders
                                    ? "is-disabled"
                                    : ""
                            }`}
                            onClick={nextPage}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="currentColor"
                                    d="M6 0l12 12-12 12z"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="orders">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <InputCheckbox
                                        onChange={toggleAllOrders}
                                        value={multiToggleValue}
                                    />
                                </th>
                                <th></th>
                                <th>
                                    <div
                                        className="sort"
                                        onClick={() =>
                                            changeSortOrder("recipientName")
                                        }
                                    >
                                        Mottagare{" "}
                                        {sort.by === "recipientName"
                                            ? sort.order === "desc"
                                                ? descendingArrow
                                                : ascendingArrow
                                            : ""}
                                    </div>
                                </th>
                                <th>
                                    <div
                                        className="sort"
                                        onClick={() =>
                                            changeSortOrder("reference")
                                        }
                                    >
                                        Referens{" "}
                                        {sort.by === "reference"
                                            ? sort.order === "desc"
                                                ? descendingArrow
                                                : ascendingArrow
                                            : ""}
                                    </div>
                                </th>
                                <th>
                                    <div
                                        className="sort"
                                        onClick={() =>
                                            changeSortOrder("created")
                                        }
                                    >
                                        Skapad{" "}
                                        {sort.by === "created"
                                            ? sort.order === "desc"
                                                ? descendingArrow
                                                : ascendingArrow
                                            : ""}
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {loadingList && filteredOrders.length === 0 && (
                                <tr className="loadingState">
                                    <td colSpan={100}>
                                        <Loader />
                                    </td>
                                </tr>
                            )}
                            {!loadingList && filteredOrders.length === 0 && (
                                <tr className="emptyState">
                                    <td colSpan={100}>
                                        Inga nya ordrar hittades.
                                    </td>
                                </tr>
                            )}
                            {filteredOrders
                                .slice(offset, offset + limit)
                                .map((order, index) => {
                                    return (
                                        <OrderRow
                                            disabled={loadingList}
                                            key={`${order.customerIntegrationId}-${order.externalId}`}
                                            remove={removeOrder}
                                            refreshList={loadOrders}
                                            order={order}
                                            selected={
                                                !!selectedOrders[
                                                    `${order.customerIntegrationId}-${order.externalId}`
                                                ]
                                            }
                                            onChange={(value) => {
                                                setSelectedOrders((orders) => {
                                                    if (value) {
                                                        orders[
                                                            `${order.customerIntegrationId}-${order.externalId}`
                                                        ] = order;
                                                    } else {
                                                        delete orders[
                                                            `${order.customerIntegrationId}-${order.externalId}`
                                                        ];
                                                    }
                                                    return {
                                                        ...orders,
                                                    };
                                                });
                                            }}
                                        />
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
                <div className="footer">
                    <button
                        disabled={selectedOrdersCount === 0}
                        className="c-button c-button--raised"
                        onClick={importSelectedOrders}
                    >
                        Importera {selectedOrdersCount} rader
                    </button>
                </div>
            </div>
        </Container>
    );
}

export default Component;
