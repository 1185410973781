import Input from "components/OldInput";
import GlobalSearch from "components/GlobalSearch";
import InputDropdown from "components/Input/Dropdown";
import Context from "context/Global";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    > .title {
        font-size: 2rem;
        color: #aaa;
        margin-bottom: 1rem;
        width: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    > .header {
        display: flex;
        padding: 1rem 0;
        gap: 1rem;

        > .show-closed {
            display: flex;
            align-items: center;
            cursor: pointer;

            > svg {
                margin-right: 1rem;
            }

            &:hover {
                opacity: 0.8;
            }
        }
    }

    > .tickets {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        overflow: hidden;

        > .list {
            display: flex;
            flex-direction: column;
            background: #fff;
            border: 1px solid #eee;
            flex: 1 1 100%;

            &.open > .items > .ticket {
                background: #fbefb6;
            }

            > .title {
                padding: 0 1rem;
                font-size: 1.5rem;
                padding: 1rem;
                padding-bottom: 0;
            }

            > .items {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                overflow: auto;
                height: 100%;
                padding: 1rem;

                > .ticket {
                    padding: 0 1rem;
                    text-decoration: none;
                    color: #000;
                    display: flex;
                    flex-direction: column;
                    background: #fff;
                    border: 1px solid #aaa;
                    border-radius: 1rem;
                    padding: 1rem;

                    &.is-highlighted {
                        background: #fbefb6;
                    }

                    > .header {
                        display: flex;
                        font-size: 0.8rem;
                        align-items: center;
                        gap: 0.5rem;

                        > .info {
                            margin-right: auto;
                        }

                        > .status {
                            font-weight: bold;
                        }

                        .indicator {
                            width: 16px;
                            height: 16px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #000;
                        }

                        .timestamp {
                            height: 16px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #000;
                            white-space: nowrap;
                            gap: 0.5rem;
                        }
                    }

                    > .title {
                        display: inline-block;
                    }

                    > .footer {
                        display: flex;
                        justify-content: space-between;
                        font-size: 0.8rem;
                        padding-top: 1rem;

                        > .assignee {
                            font-style: italic;
                        }
                    }
                }
            }
        }

        > .emptyState {
            > .title {
                font-size: 1.5rem;
                padding-bottom: 320px;
            }
        }
    }
`;

function Component(props) {
    const context = useContext(Context);
    const [searchString, setSearchString] = useState(null);
    const [tickets, setTickets] = useState([]);
    const [filteredTickets, setFilteredTickets] = useState([]);
    const [openTickets, setOpenTickets] = useState([]);
    const [underObservationTickets, setUnderObservationTickets] = useState([]);

    const [idleTickets, setIdleTickets] = useState([]);
    const [closedTickets, setClosedTickets] = useState([]);
    const [reclaimTickets, setReclaimTickets] = useState([]);
    const [supportUsers, setSupportUsers] = useState([]);
    const [assignedFilter, setAssignedFilter] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showClosed, setShowClosed] = useState(false);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        Api.getSupportUsers({
            brandId: context.user.brand.id,
            includeOwners: true,
            includeCustomerOwners: true,
        }).then((supportUsers) => {
            setSupportUsers(
                [
                    {
                        title: "Alla ärenden",
                        value: null,
                    },
                    {
                        title: "Ärenden utan ansvarig",
                        value: 0,
                    },
                ].concat(
                    supportUsers.map((user) => {
                        return {
                            title: user.name,
                            value: user.id,
                        };
                    }),
                ),
            );
        });
    }, []);

    useEffect(() => {
        localStorage.setItem("supportAssignedFilter", assignedFilter);
    }, [assignedFilter]);

    useEffect(() => {
        if (context.socket) {
            context.socket.on("ticketCreated", createTicket);
            context.socket.on("ticketUpdated", updateTicket);
            context.socket.emit("openSupport", setTickets);

            return () => {
                context.socket.off("ticketCreated");
                context.socket.off("ticketUpdated");
                context.socket.emit("closeSupport");
            };
        }
    }, [context.socket]);

    function updateTicket(updatedTicket) {
        // Update ticket in tickets.
        setTickets((ticketList) => {
            return ticketList.map((ticket) => {
                if (ticket.id === updatedTicket.id) {
                    return updatedTicket;
                } else {
                    return ticket;
                }
            });
        });
    }

    function createTicket(newTicket) {
        // Add a ticket to tickets.
        setTickets((ticketList) => {
            return ticketList.concat(newTicket);
        });
    }

    function sortByNotification(a, b) {
        if (a.supportNotification) {
            if (b.supportNotification) {
                return sortByTimestamp(a, b);
            }
            return -1;
        } else if (b.supportNotification) {
            return 1;
        }

        if (a.closed) {
            if (b.closed) {
                return sortByClosedDate(a, b);
            }
            return -1;
        } else if (b.closed) {
            return 1;
        }

        return sortByTimestamp(a, b);
    }

    function sortByTimestamp(a, b) {
        // When did
        if (!a.timestamp && !b.timestamp) {
            return sortByCreationDate(a, b);
        } else if (!a.timestamp) {
            return -1;
        } else if (!b.timestamp) {
            return 1;
        }
        if (a.timestamp < b.timestamp) {
            return -1;
        } else if (a.timestamp > b.timestamp) {
            return 1;
        }
        return 0;
    }

    function sortByClosedDate(a, b) {
        // When did
        if (a.closed < b.closed) {
            return 1;
        } else if (a.closed > b.closed) {
            return -1;
        }
        return 0;
    }

    function sortByPriority(a, b) {
        if (a.priority < b.priority) {
            return 1;
        } else if (a.priority > b.priority) {
            return -1;
        }
        return sortByCreationDate(a, b);
    }

    function sortByCreationDate(a, b) {
        if (a.created < b.created) {
            return -1;
        } else if (a.created > b.created) {
            return 1;
        }
        return 0;
    }

    useEffect(() => {
        const [open, idle, closed, reclaims, underObservation] =
            filteredTickets.reduce(
                (acc, ticket) => {
                    if (ticket.status === "CLOSED") {
                        acc[2].push(ticket);
                    } else if (ticket.type === "RECLAIM") {
                        acc[3].push(ticket);
                    } else if (
                        ticket.title?.includes("Bevakning") ||
                        ticket.title?.includes("Bevaka")
                    ) {
                        acc[4].push(ticket);
                    } else if (
                        !ticket.timestamp ||
                        moment(ticket.timestamp) < moment()
                    ) {
                        acc[0].push(ticket);
                    } else {
                        acc[1].push(ticket);
                    }
                    return acc;
                },
                [[], [], [], [], []],
            );

        setOpenTickets(open.sort(sortByPriority));
        setUnderObservationTickets(underObservation?.sort(sortByTimestamp));
        setIdleTickets(idle.sort(sortByTimestamp));
        setClosedTickets(closed.sort(sortByNotification));
        setReclaimTickets(reclaims.sort(sortByNotification));
    }, [filteredTickets]);

    useEffect(() => {
        setFilteredTickets(
            tickets
                .filter((ticket) => {
                    if (assignedFilter === null) {
                        return true;
                    }
                    if (assignedFilter === 0) {
                        return !ticket.assignedToId;
                    }
                    return (
                        !ticket.assignedToId ||
                        ticket.assignedToId === assignedFilter
                    );
                })
                .filter((ticket) => {
                    if (!searchString) {
                        return true;
                    }
                    if (
                        ticket.customerName
                            ?.toLowerCase()
                            .indexOf(searchString.toLowerCase()) >= 0
                    ) {
                        return true;
                    }
                    if (
                        ticket.trackingNumber
                            ?.toLowerCase()
                            .indexOf(searchString.toLowerCase()) >= 0
                    ) {
                        return true;
                    } else if (
                        ticket.createdBy
                            ?.toLowerCase()
                            .indexOf(searchString.toLowerCase()) >= 0
                    ) {
                        return true;
                    } else if (
                        ticket.title
                            ?.toLowerCase()
                            .indexOf(searchString.toLowerCase()) >= 0
                    ) {
                        return true;
                    } else if (
                        ticket.data?.trackingNumber
                            ?.toLowerCase()
                            .indexOf(searchString.toLowerCase()) >= 0
                    ) {
                        return true;
                    } else if (
                        ticket.description
                            ?.toLowerCase()
                            .indexOf(searchString.toLowerCase()) >= 0
                    ) {
                        return true;
                    }
                    return false;
                }),
        );
    }, [searchString, tickets, assignedFilter]);

    function toggleShowClosedTickets() {
        setShowClosed((v) => {
            return !v;
        });
    }

    function getDefaultAssignedFilter() {
        const savedFilter = localStorage.getItem("supportAssignedFilter");
        if (typeof savedFilter === "undefined" || savedFilter === "NaN") {
            return context.user.id;
        } else if (savedFilter === "null" || savedFilter === null) {
            return null;
        } else {
            return Number(savedFilter);
        }
    }

    return (
        <Container>
            <GlobalSearch />
            <div className="header">
                <InputDropdown
                    onChange={setAssignedFilter}
                    options={supportUsers}
                    value={getDefaultAssignedFilter()}
                    placeholder="Sök..."
                />
                <Input
                    type="text"
                    onChange={setSearchString}
                    value={searchString}
                    placeholder="Sök..."
                />
                <Link
                    className="c-button c-button--raised"
                    to="/admin/tickets/create"
                >
                    Skapa ärende
                </Link>
                <div className="show-closed" onClick={toggleShowClosedTickets}>
                    {showClosed ? (
                        <>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.21 0-4 1.791-4 4s1.79 4 4 4c2.209 0 4-1.791 4-4s-1.791-4-4-4zm-.004 3.999c-.564.564-1.479.564-2.044 0s-.565-1.48 0-2.044c.564-.564 1.479-.564 2.044 0s.565 1.479 0 2.044z" />
                            </svg>{" "}
                            Dölj stängda ärenden
                        </>
                    ) : (
                        <>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path d="M19.604 2.562l-3.346 3.137c-1.27-.428-2.686-.699-4.243-.699-7.569 0-12.015 6.551-12.015 6.551s1.928 2.951 5.146 5.138l-2.911 2.909 1.414 1.414 17.37-17.035-1.415-1.415zm-6.016 5.779c-3.288-1.453-6.681 1.908-5.265 5.206l-1.726 1.707c-1.814-1.16-3.225-2.65-4.06-3.66 1.493-1.648 4.817-4.594 9.478-4.594.927 0 1.796.119 2.61.315l-1.037 1.026zm-2.883 7.431l5.09-4.993c1.017 3.111-2.003 6.067-5.09 4.993zm13.295-4.221s-4.252 7.449-11.985 7.449c-1.379 0-2.662-.291-3.851-.737l1.614-1.583c.715.193 1.458.32 2.237.32 4.791 0 8.104-3.527 9.504-5.364-.729-.822-1.956-1.99-3.587-2.952l1.489-1.46c2.982 1.9 4.579 4.327 4.579 4.327z" />
                            </svg>{" "}
                            Visa stängda ärenden
                        </>
                    )}
                </div>
            </div>
            <div className="tickets">
                <div className="list open">
                    <div className="title">Aktiva ärenden</div>
                    <div className="items">
                        {openTickets.map((ticket) => {
                            return (
                                <Link
                                    key={ticket.id}
                                    className={`ticket ${
                                        !!ticket.supportNotification
                                            ? "is-highlighted"
                                            : ""
                                    }`}
                                    to={`/admin/tickets/${ticket.id}`}
                                >
                                    <div className="header">
                                        <div className="info">
                                            #{ticket.id}:{" "}
                                            {t(`tickets.type.${ticket.type}`)}
                                            {ticket.data?.trackingNumber
                                                ? " - " +
                                                  ticket.data?.trackingNumber
                                                : ""}
                                        </div>
                                        <div
                                            className={`status is-${ticket.status.toLowerCase()}`}
                                        >
                                            {ticket.status === "NEW" &&
                                                t(
                                                    `tickets.status.${ticket.status}`,
                                                )}
                                        </div>
                                        {!!ticket.supportNotification && (
                                            <div className="indicator">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M12 3c5.514 0 10 3.592 10 8.007 0 4.917-5.145 7.961-9.91 7.961-1.937 0-3.383-.397-4.394-.644-1 .613-1.595 1.037-4.272 1.82.535-1.373.723-2.748.602-4.265-.838-1-2.025-2.4-2.025-4.872-.001-4.415 4.485-8.007 9.999-8.007zm0-2c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 1.418.345 2.775.503 4.059.503 7.084 0 11.91-4.837 11.91-9.961-.001-5.811-5.702-10.006-12.001-10.006zm-3.5 10c0 .829-.671 1.5-1.5 1.5-.828 0-1.5-.671-1.5-1.5s.672-1.5 1.5-1.5c.829 0 1.5.671 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5zm5 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5z"
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                    <div className="title">{ticket.title}</div>
                                    <div className="footer">
                                        <div className="customer">
                                            {ticket.customerName}
                                        </div>
                                        <div className="assignee">
                                            {ticket.assignedToName ||
                                                "Ingen ansvarig"}
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </div>
                <div className="list idle">
                    <div className="title">Vilande ärenden</div>
                    <div className="items">
                        {idleTickets.map((ticket) => {
                            return (
                                <Link
                                    key={ticket.id}
                                    className={`ticket ${
                                        !!ticket.supportNotification
                                            ? "is-highlighted"
                                            : ""
                                    }`}
                                    to={`/admin/tickets/${ticket.id}`}
                                >
                                    <div className="header">
                                        <div className="info">
                                            #{ticket.id}:{" "}
                                            {t(`tickets.type.${ticket.type}`)}
                                            {ticket.data?.trackingNumber
                                                ? " - " +
                                                  ticket.data?.trackingNumber
                                                : ""}
                                        </div>
                                        <div
                                            className={`status is-${ticket.status.toLowerCase()}`}
                                        >
                                            {ticket.status === "NEW" &&
                                                t(
                                                    `tickets.status.${ticket.status}`,
                                                )}
                                        </div>
                                        {!!ticket.supportNotification && (
                                            <div className="indicator">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M12 3c5.514 0 10 3.592 10 8.007 0 4.917-5.145 7.961-9.91 7.961-1.937 0-3.383-.397-4.394-.644-1 .613-1.595 1.037-4.272 1.82.535-1.373.723-2.748.602-4.265-.838-1-2.025-2.4-2.025-4.872-.001-4.415 4.485-8.007 9.999-8.007zm0-2c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 1.418.345 2.775.503 4.059.503 7.084 0 11.91-4.837 11.91-9.961-.001-5.811-5.702-10.006-12.001-10.006zm-3.5 10c0 .829-.671 1.5-1.5 1.5-.828 0-1.5-.671-1.5-1.5s.672-1.5 1.5-1.5c.829 0 1.5.671 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5zm5 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5z"
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                        {!!ticket.timestamp && (
                                            <div className="timestamp">
                                                {moment(
                                                    ticket.timestamp,
                                                ).calendar()}
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                >
                                                    <path d="M24 24v-7h-1v-1c0-1.657-1.343-3-3-3h-13v4h-6v-7h-1v14h1v-2h22v2h1zm-23-3h22v-3h-22v3zm7-4h14v-1c0-1.105-.895-2-2-2h-12v3zm-4-5c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm0 1c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm8.001-13c-3.169 0-6 2.113-6 5.003 0 1.025.37 2.032 1.023 2.812.027.916-.511 2.228-.997 3.184 1.302-.234 3.15-.754 3.989-1.268.709.173 1.388.252 2.03.252 3.542 0 5.954-2.418 5.954-4.98.001-2.906-2.851-5.003-5.999-5.003zm0 1c2.631 0 4.999 1.7 4.999 4.003 0 1.914-1.895 3.98-4.954 3.98-.913 0-1.56-.167-2.197-.322-.194.119-.604.458-2.136.908.346-1.071.316-1.586.3-2.13-.593-.709-1.012-1.369-1.012-2.436 0-2.207 2.243-4.003 5-4.003zm-.888 6h-2.291v-.492l1.251-1.815v-.01h-1.159v-.683h2.156v.527l-1.224 1.789v.011h1.267v.673zm4.047 0h-3.188v-.674l1.741-2.379v-.014h-1.712v-.933h3v.717l-1.604 2.341v.016h1.763v.926z" />
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                    <div className="title">{ticket.title}</div>
                                    <div className="footer">
                                        <div className="customer">
                                            {ticket.customerName}
                                        </div>
                                        <div className="assignee">
                                            {ticket.assignedToName ||
                                                "Ingen ansvarig"}
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </div>
                <div className="list reclaim">
                    <div className="title">Bevakningar</div>
                    <div className="items">
                        {underObservationTickets?.map((ticket) => {
                            return (
                                <Link
                                    key={ticket.id}
                                    className={`ticket ${
                                        !!ticket.supportNotification ||
                                        !ticket.timestamp ||
                                        moment(ticket.timestamp).diff(
                                            moment(),
                                        ) < 0
                                            ? "is-highlighted"
                                            : ""
                                    }`}
                                    to={`/admin/tickets/${ticket.id}`}
                                >
                                    <div className="header">
                                        <div className="info">
                                            #{ticket.id}:{" "}
                                            {t(`tickets.type.${ticket.type}`)}
                                            {ticket.data?.trackingNumber
                                                ? " - " +
                                                  ticket.data?.trackingNumber
                                                : ""}
                                        </div>
                                        <div
                                            className={`status is-${ticket.status.toLowerCase()}`}
                                        >
                                            {ticket.status === "NEW" &&
                                                t(
                                                    `tickets.status.${ticket.status}`,
                                                )}
                                        </div>
                                        {!!ticket.supportNotification && (
                                            <div className="indicator">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M12 3c5.514 0 10 3.592 10 8.007 0 4.917-5.145 7.961-9.91 7.961-1.937 0-3.383-.397-4.394-.644-1 .613-1.595 1.037-4.272 1.82.535-1.373.723-2.748.602-4.265-.838-1-2.025-2.4-2.025-4.872-.001-4.415 4.485-8.007 9.999-8.007zm0-2c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 1.418.345 2.775.503 4.059.503 7.084 0 11.91-4.837 11.91-9.961-.001-5.811-5.702-10.006-12.001-10.006zm-3.5 10c0 .829-.671 1.5-1.5 1.5-.828 0-1.5-.671-1.5-1.5s.672-1.5 1.5-1.5c.829 0 1.5.671 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5zm5 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5z"
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                        {!!ticket.timestamp && (
                                            <div className="timestamp">
                                                {moment(
                                                    ticket.timestamp,
                                                ).calendar()}
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                >
                                                    <path d="M24 24v-7h-1v-1c0-1.657-1.343-3-3-3h-13v4h-6v-7h-1v14h1v-2h22v2h1zm-23-3h22v-3h-22v3zm7-4h14v-1c0-1.105-.895-2-2-2h-12v3zm-4-5c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm0 1c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm8.001-13c-3.169 0-6 2.113-6 5.003 0 1.025.37 2.032 1.023 2.812.027.916-.511 2.228-.997 3.184 1.302-.234 3.15-.754 3.989-1.268.709.173 1.388.252 2.03.252 3.542 0 5.954-2.418 5.954-4.98.001-2.906-2.851-5.003-5.999-5.003zm0 1c2.631 0 4.999 1.7 4.999 4.003 0 1.914-1.895 3.98-4.954 3.98-.913 0-1.56-.167-2.197-.322-.194.119-.604.458-2.136.908.346-1.071.316-1.586.3-2.13-.593-.709-1.012-1.369-1.012-2.436 0-2.207 2.243-4.003 5-4.003zm-.888 6h-2.291v-.492l1.251-1.815v-.01h-1.159v-.683h2.156v.527l-1.224 1.789v.011h1.267v.673zm4.047 0h-3.188v-.674l1.741-2.379v-.014h-1.712v-.933h3v.717l-1.604 2.341v.016h1.763v.926z" />
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                    <div className="title">{ticket.title}</div>
                                    <div className="footer">
                                        <div className="customer">
                                            {ticket.customerName}
                                        </div>
                                        <div className="assignee">
                                            {ticket.assignedToName ||
                                                "Ingen ansvarig"}
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </div>
                <div className="list reclaim">
                    <div className="title">Reklamationer</div>
                    <div className="items">
                        {reclaimTickets.map((ticket) => {
                            return (
                                <Link
                                    key={ticket.id}
                                    className={`ticket ${
                                        !!ticket.supportNotification ||
                                        !ticket.timestamp ||
                                        moment(ticket.timestamp).diff(
                                            moment(),
                                        ) < 0
                                            ? "is-highlighted"
                                            : ""
                                    }`}
                                    to={`/admin/tickets/${ticket.id}`}
                                >
                                    <div className="header">
                                        <div className="info">
                                            #{ticket.id}:{" "}
                                            {t(`tickets.type.${ticket.type}`)}
                                            {ticket.data?.trackingNumber
                                                ? " - " +
                                                  ticket.data?.trackingNumber
                                                : ""}
                                        </div>
                                        <div
                                            className={`status is-${ticket.status.toLowerCase()}`}
                                        >
                                            {ticket.status === "NEW" &&
                                                t(
                                                    `tickets.status.${ticket.status}`,
                                                )}
                                        </div>
                                        {!!ticket.supportNotification && (
                                            <div className="indicator">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M12 3c5.514 0 10 3.592 10 8.007 0 4.917-5.145 7.961-9.91 7.961-1.937 0-3.383-.397-4.394-.644-1 .613-1.595 1.037-4.272 1.82.535-1.373.723-2.748.602-4.265-.838-1-2.025-2.4-2.025-4.872-.001-4.415 4.485-8.007 9.999-8.007zm0-2c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 1.418.345 2.775.503 4.059.503 7.084 0 11.91-4.837 11.91-9.961-.001-5.811-5.702-10.006-12.001-10.006zm-3.5 10c0 .829-.671 1.5-1.5 1.5-.828 0-1.5-.671-1.5-1.5s.672-1.5 1.5-1.5c.829 0 1.5.671 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5zm5 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5z"
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                        {!!ticket.timestamp && (
                                            <div className="timestamp">
                                                {moment(
                                                    ticket.timestamp,
                                                ).calendar()}
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                >
                                                    <path d="M24 24v-7h-1v-1c0-1.657-1.343-3-3-3h-13v4h-6v-7h-1v14h1v-2h22v2h1zm-23-3h22v-3h-22v3zm7-4h14v-1c0-1.105-.895-2-2-2h-12v3zm-4-5c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm0 1c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm8.001-13c-3.169 0-6 2.113-6 5.003 0 1.025.37 2.032 1.023 2.812.027.916-.511 2.228-.997 3.184 1.302-.234 3.15-.754 3.989-1.268.709.173 1.388.252 2.03.252 3.542 0 5.954-2.418 5.954-4.98.001-2.906-2.851-5.003-5.999-5.003zm0 1c2.631 0 4.999 1.7 4.999 4.003 0 1.914-1.895 3.98-4.954 3.98-.913 0-1.56-.167-2.197-.322-.194.119-.604.458-2.136.908.346-1.071.316-1.586.3-2.13-.593-.709-1.012-1.369-1.012-2.436 0-2.207 2.243-4.003 5-4.003zm-.888 6h-2.291v-.492l1.251-1.815v-.01h-1.159v-.683h2.156v.527l-1.224 1.789v.011h1.267v.673zm4.047 0h-3.188v-.674l1.741-2.379v-.014h-1.712v-.933h3v.717l-1.604 2.341v.016h1.763v.926z" />
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                    <div className="title">{ticket.title}</div>
                                    <div className="footer">
                                        <div className="customer">
                                            {ticket.customerName}
                                        </div>
                                        <div className="assignee">
                                            {ticket.assignedToName ||
                                                "Ingen ansvarig"}
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </div>
                {showClosed && (
                    <div className="list closed">
                        <div className="title">Stängda ärenden</div>
                        <div className="items">
                            {closedTickets.map((ticket) => {
                                return (
                                    <Link
                                        key={ticket.id}
                                        className={`ticket ${
                                            !!ticket.supportNotification
                                                ? "is-highlighted"
                                                : ""
                                        }`}
                                        to={`/admin/tickets/${ticket.id}`}
                                    >
                                        <div className="header">
                                            <div className="info">
                                                #{ticket.id}:{" "}
                                                {t(
                                                    `tickets.type.${ticket.type}`,
                                                )}
                                                {ticket.data?.trackingNumber
                                                    ? " - " +
                                                      ticket.data
                                                          ?.trackingNumber
                                                    : ""}
                                            </div>
                                            <div
                                                className={`status is-${ticket.status.toLowerCase()}`}
                                            >
                                                {ticket.status === "NEW" &&
                                                    t(
                                                        `tickets.status.${ticket.status}`,
                                                    )}
                                            </div>
                                            {!!ticket.supportNotification && (
                                                <div className="indicator">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M12 3c5.514 0 10 3.592 10 8.007 0 4.917-5.145 7.961-9.91 7.961-1.937 0-3.383-.397-4.394-.644-1 .613-1.595 1.037-4.272 1.82.535-1.373.723-2.748.602-4.265-.838-1-2.025-2.4-2.025-4.872-.001-4.415 4.485-8.007 9.999-8.007zm0-2c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 1.418.345 2.775.503 4.059.503 7.084 0 11.91-4.837 11.91-9.961-.001-5.811-5.702-10.006-12.001-10.006zm-3.5 10c0 .829-.671 1.5-1.5 1.5-.828 0-1.5-.671-1.5-1.5s.672-1.5 1.5-1.5c.829 0 1.5.671 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5zm5 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5z"
                                                        />
                                                    </svg>
                                                </div>
                                            )}
                                        </div>
                                        <div className="title">
                                            {ticket.title}
                                        </div>
                                        <div className="footer">
                                            <div className="customer">
                                                {ticket.customerName}
                                            </div>
                                            <div className="assignee">
                                                {ticket.assignedToName ||
                                                    "Ingen ansvarig"}
                                            </div>
                                        </div>
                                    </Link>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </Container>
    );
}

export default Component;
