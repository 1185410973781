import Context from "context/Global";
import FormatPrice from "helpers/FormatPrice";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 1rem 0;

    > table.list {
        width: 100%;

        th {
            white-space: nowrap;
        }

        tr > td,
        tr > th {
            padding: 0 1rem;
        }

        tr > td:first-child,
        tr > th:first-child {
            padding-left: 1rem;
        }
        tr > td:last-child,
        tr > th:last-child {
            padding-right: 1rem;
        }

        > .columnTitle {
            white-space: nowrap;
        }

        > tbody > tr > td.subTitle {
            grid-column: span 10;
            font-size: 2rem;
            padding: 1rem 1rem 0.2rem;
            border-bottom: 1px solid #ddd;
            padding-top: 4rem;
        }
        > tbody > tr:first-child > td.subTitle {
            padding-top: 1rem;
        }

        > tbody tr td:not(.subTitle) {
            background: #fff;
            cursor: pointer;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            background: #eee;
        }
        > tbody tr:hover td:not(.subTitle) {
            background: #e5e5e5;
        }

        .id {
            > .text {
                font-size: 2rem;
                text-decoration: none;
                color: #000;

                &:hover {
                    color: #666;
                }
            }
        }

        .sender,
        .recipient,
        .customer {
            > .name {
                font-weight: bold;
                white-space: nowrap;
                display: block;
            }
            > .postalTown {
                white-space: nowrap;
            }
        }

        .information {
            font-style: italic;
        }

        .status,
        .transporter,
        .cost,
        .price,
        .created {
            white-space: nowrap;
        }

        .cost,
        .price {
            text-align: right;
        }
    }
`;

let Title = styled.div`
    font-size: 24px;
    color: rgba(0, 0, 0, 0.6);
    padding: 1rem;
`;

function View({ history }) {
    const context = useContext(Context);
    const [forwardings, setForwardings] = useState({
        new: [],
        ongoing: [],
        booked: [],
        invoiced: [],
        closed: [],
    });

    useEffect(() => {
        loadForwardings();
    }, []);

    async function loadForwardings() {
        const {
            newItems,
            ongoingItems,
            bookedItems,
            invoicedItems,
            closedItems,
        } = (await Api.getForwardings({})).reduce(
            (acc, forwarding) => {
                switch (forwarding.status) {
                    case "NEW":
                        acc.newItems.push(forwarding);
                        break;
                    case "ONGOING":
                        acc.ongoingItems.push(forwarding);
                        break;
                    case "BOOKED":
                        acc.bookedItems.push(forwarding);
                        break;
                    case "INVOICED":
                        acc.invoicedItems.push(forwarding);
                        break;
                    case "CLOSED":
                        acc.closedItems.push(forwarding);
                        break;
                }
                return acc;
            },
            {
                newItems: [],
                ongoingItems: [],
                bookedItems: [],
                invoicedItems: [],
                closedItems: [],
            },
        );

        setForwardings({
            new: newItems,
            ongoing: ongoingItems,
            booked: bookedItems,
            invoiced: invoicedItems,
            closed: closedItems,
        });
    }

    function getForwardingElement(forwarding) {
        return (
            <tr
                key={forwarding.id}
                onClick={() => {
                    history.push(`/admin/spedition/${forwarding.id}`);
                }}
            >
                <td className="id">
                    <div className="text">#{forwarding.id}</div>
                </td>
                <td className="sender">
                    <div className="name">
                        {forwarding.data?.sender.contact.company ||
                            forwarding.data?.sender.contact.name}
                    </div>
                    <div className="postalTown">
                        {[
                            forwarding.data?.sender.postalTown,
                            forwarding.data?.sender.countryCode,
                        ].join(", ")}
                    </div>
                </td>
                <td className="recipient">
                    <div className="name">
                        {forwarding.data?.recipient.contact.company ||
                            forwarding.data?.recipient.contact.name}
                    </div>
                    <div className="postalTown">
                        {[
                            forwarding.data?.recipient.postalTown,
                            forwarding.data?.recipient.countryCode,
                        ].join(", ")}
                    </div>
                </td>
                <td className="customer">
                    <div className="name">
                        {forwarding.data?.customer?.name ||
                            forwarding.data?.customer?.contactName}
                    </div>
                    <div className="postalTown">
                        {forwarding.data?.customer?.postalTown &&
                        forwarding.data?.customer?.countryCode
                            ? [
                                  forwarding.data?.customer?.postalTown,
                                  forwarding.data?.customer?.countryCode,
                              ].join(", ")
                            : ""}
                    </div>
                </td>
                <td className="information">
                    {forwarding.data?.information.other}
                </td>
                <td className="created">
                    {moment(forwarding.created).calendar()}
                </td>
                <td className="status">{forwarding.status}</td>
                <td className="transporter">{forwarding.transporter}</td>
                <td className="cost">
                    {forwarding.cost &&
                        FormatPrice({
                            amount: forwarding.cost,
                            decimals: 0,
                        })}
                </td>
                <td className="price">
                    {forwarding.customerPrice &&
                        FormatPrice({
                            amount: forwarding.customerPrice,
                            decimals: 0,
                        })}
                </td>
            </tr>
        );
    }

    return (
        <Container>
            <table className="list">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Avsändare</th>
                        <th>Mottagare</th>
                        <th>Kund</th>
                        <th>Övrig information</th>
                        <th>Skapades</th>
                        <th>Status</th>
                        <th>Speditör</th>
                        <th>Kostnad</th>
                        <th>Kundens pris</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="subTitle" colSpan={10}>
                            Nya förfrågningar
                        </td>
                    </tr>
                    {forwardings.new.map((forwarding) => {
                        return getForwardingElement(forwarding);
                    })}
                    <tr>
                        <td className="subTitle" colSpan={10}>
                            Pågående
                        </td>
                    </tr>
                    {forwardings.ongoing.map((forwarding) => {
                        return getForwardingElement(forwarding);
                    })}
                    <tr>
                        <td className="subTitle" colSpan={10}>
                            Bokade
                        </td>
                    </tr>
                    {forwardings.booked.map((forwarding) => {
                        return getForwardingElement(forwarding);
                    })}
                    <tr>
                        <td className="subTitle" colSpan={10}>
                            Fakturerade
                        </td>
                    </tr>
                    {forwardings.invoiced.map((forwarding) => {
                        return getForwardingElement(forwarding);
                    })}
                    <tr>
                        <td className="subTitle" colSpan={10}>
                            Stängda
                        </td>
                    </tr>
                    {forwardings.closed.map((forwarding) => {
                        return getForwardingElement(forwarding);
                    })}
                </tbody>
            </table>
        </Container>
    );
}

export default withRouter(View);
