import Card from "components/Card";
import Loader from "components/Loader/Loader";
import Table from "components/Table";
import Context from "context/Global";
import moment from "moment";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import Orders from "./Orders";
import Pickups from "./Pickups";
import Products from "./Products";

let Information = styled.div`
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > .parameter {
        margin-right: 16px;
        width: 100%;
        max-width: 360px;

        > label {
            font-size: 10px;
            font-weight: bold;
        }

        > .value {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            padding-bottom: 8px;
            margin-bottom: 4px;
            font-size: 20px;
            min-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;
let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;

    > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
    }

    > .emptyState {
        padding: 0 1rem;
        font-style: italic;
    }

    > .section {
        padding: 1rem;
    }

    > .loader {
        width: 200px;
        height: 200px;
    }

    > table {
        > thead {
            > tr {
                > th:first-child {
                    padding-left: 8px;
                }
            }
        }
        > tbody {
            > tr {
                &:hover {
                    td {
                        background: rgba(0, 0, 0, 0.03);
                    }
                }

                td:first-child {
                    border-radius: 5px 0 0 5px;
                    padding-left: 8px;
                }
                td:last-child {
                    text-align: right;
                    border-radius: 0 5px 5px 0;
                }
            }
        }
    }

    > .buttons {
        padding: 16px 0;
    }

    > .products {
        padding: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        background: #fff;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;

        table {
            thead {
                th {
                    font-weight: normal;
                    padding: 4px 16px;
                }
            }

            tbody {
                th {
                    font-weight: normal;
                }
                .product {
                    th {
                        width: 100%;
                        padding: 4px 16px;
                    }

                    th,
                    td {
                        &:first-child {
                            border-radius: 5px 0 0 5px;
                        }
                        &:last-child {
                            border-radius: 0 5px 5px 0;
                        }
                    }

                    .input {
                        padding: 4px 16px;
                    }

                    &:hover {
                        th,
                        td {
                            background: rgba(0, 0, 0, 0.05);
                        }
                    }
                }
            }
        }
    }
`;

let Title = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.6);
`;

let TEN_SECONDS = 1000 * 10;

function Component({ customerId, ...props }) {
    const context = useContext(Context);
    const [convertingToCustomer, setConvertingToCustomer] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [products, setProducts] = useState([]);
    const [orders, setOrders] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        loadCustomer();
    }, []);

    function loadCustomer() {
        return Api.getCustomer({
            customerId: customerId,
        })
            .then((customer) => {
                setCustomer(customer);
            })
            .catch((response) => {
                console.error("Failed to load order");
            });
    }

    function removeUser(user) {
        Api.removeUserFromCustomer({
            userId: user.id,
            customerId: customer.id,
        })
            .then(() => {
                loadCustomer();
            })
            .catch(() => {});
    }

    function userHasAccessToAdministration() {
        if (
            customer &&
            (context.user.administrator ||
                (context.user.brand &&
                    context.user.brand.id === customer.brand.id))
        ) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        if (userHasAccessToAdministration()) {
            populateAdminData();
        }
    }, [customer]);

    useEffect(() => {}, [products]);

    async function populateAdminData() {
        setProducts(
            await Api.getCustomerProducts({
                customerId: customerId,
            }),
        );
    }

    async function removeCustomer() {
        let remove = window.confirm(`Vill du ta bort kunden ${customer.name}?`);
        if (remove) {
            await Api.deleteCustomer({
                customerId: customer.id,
            });
            props.history.replace("/admin/customers");
        }
    }

    async function convertToCustomer() {
        setConvertingToCustomer(true);

        await Api.convertProspect({
            customerId: customerId,
        });
        await loadCustomer();
        setConvertingToCustomer(false);
    }

    if (!customer) {
        return (
            <Container>
                <div className="loader">
                    <Loader />
                </div>
            </Container>
        );
    } else {
        return (
            <Container>
                <div className="header">
                    <Title>
                        {customer.prospect ? "Prospekt" : "Kund"}:{" "}
                        {customer.name}
                    </Title>
                    {customer.prospect && (
                        <button
                            disabled={convertingToCustomer}
                            className="c-button c-button--raised"
                            onClick={convertToCustomer}
                        >
                            Konvertera till kund
                        </button>
                    )}
                </div>
                <div className="section">
                    <Card>
                        <Information>
                            <div className="parameter">
                                <label>Kundansvarig</label>
                                <div
                                    className="value"
                                    title={customer.clientManager?.name}
                                >
                                    {customer.clientManager?.name}
                                </div>
                            </div>
                            <div className="parameter">
                                <label>Kundnummer</label>
                                <div
                                    className="value"
                                    title={customer.clientNumber}
                                >
                                    {customer.clientNumber}
                                </div>
                            </div>
                            <div className="parameter">
                                <label>Land</label>
                                <div
                                    className="value"
                                    title={customer.countryCode}
                                >
                                    {customer.countryCode}
                                </div>
                            </div>
                            <div className="parameter">
                                <label>Postort</label>
                                <div
                                    className="value"
                                    title={customer.postalTown}
                                >
                                    {customer.postalTown}
                                </div>
                            </div>
                            <div className="parameter">
                                <label>Postnummer</label>
                                <div
                                    className="value"
                                    title={customer.postalCode}
                                >
                                    {customer.postalCode}
                                </div>
                            </div>
                            <div className="parameter">
                                <label>Adress</label>
                                <div
                                    className="value"
                                    title={customer.addressLine1}
                                >
                                    {customer.addressLine1}
                                </div>
                                <div
                                    className="value"
                                    title={customer.addressLine2 || ""}
                                >
                                    {customer.addressLine2 || ""}
                                </div>
                            </div>
                            <div className="parameter">
                                <label>Tull-id</label>
                                <div
                                    className="value"
                                    title={customer.vatNumber || ""}
                                >
                                    {customer.vatNumber || ""}
                                </div>
                            </div>
                            <div className="parameter">
                                <label>Skapad</label>
                                <div
                                    className="value"
                                    title={moment(customer.created).calendar()}
                                >
                                    {moment(customer.created).calendar()}
                                </div>
                            </div>
                            <div className="parameter">
                                <label>Kontaktperson</label>
                                <div
                                    className="value"
                                    title={customer.contactName || ""}
                                >
                                    {customer.contactName || ""}
                                </div>
                            </div>
                            <div className="parameter">
                                <label>Kontakt-mail</label>
                                <div
                                    className="value"
                                    title={customer.contactEmail || ""}
                                >
                                    {customer.contactEmail || ""}
                                </div>
                            </div>
                            <div className="parameter">
                                <label>Kontakt-telefonnummer</label>
                                <div
                                    className="value"
                                    title={customer.contactPhoneNumber || ""}
                                >
                                    {customer.contactPhoneNumber || ""}
                                </div>
                            </div>
                            <div className="parameter">
                                <label>Faktura-mail</label>
                                <div
                                    className="value"
                                    title={customer.invoiceEmail || ""}
                                >
                                    {customer.invoiceEmail || ""}
                                </div>
                            </div>
                        </Information>
                    </Card>
                    <div className="buttons">
                        <Link
                            to={`/admin/customers/${customer.id}/edit`}
                            className="c-button c-button--raised"
                        >
                            Redigera
                        </Link>
                        {context.user.role === "OWNER" && (
                            <div
                                className="c-button c-button--text"
                                onClick={removeCustomer}
                            >
                                Ta bort
                            </div>
                        )}
                        <Link
                            to={`/admin/customers/${customer.id}/contacts`}
                            className="c-button c-button--ghost"
                        >
                            Adressbok
                        </Link>
                    </div>
                </div>
                <div className="section">
                    <Title>Användare</Title>
                </div>

                {!customer.users?.length && (
                    <div className="emptyState">
                        Just nu finns det inga användare på denna kund
                    </div>
                )}
                {!!customer.users?.length && (
                    <Table className="users">
                        <thead>
                            <tr>
                                <th>Namn</th>
                                <th>Telefonnummer</th>
                                <th>Skapad</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {customer.users.map((user) => {
                                return (
                                    <tr key={user.id} className="user">
                                        <td>
                                            {user.name} ({user.email})
                                        </td>
                                        <td>{user?.phoneNumber || "N/A"} </td>
                                        <td
                                            title={moment(user.created).format(
                                                "YYYY-MM-DD HH:mm:ss",
                                            )}
                                        >
                                            {moment(user.created).fromNow()}
                                        </td>
                                        <td>
                                            <button
                                                onClick={() => {
                                                    removeUser(user);
                                                }}
                                                className="c-button c-button--text"
                                            >
                                                Ta bort
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                )}
                <div className="section">
                    <div className="buttons">
                        <Link
                            to={`/admin/customers/${customer.id}/adduser`}
                            className="c-button c-button--raised"
                        >
                            Ny användare
                        </Link>
                    </div>
                </div>
                <div className="section flex flex-col gap-3 ">
                    <Title>{t("pickups.fixedPickup")}</Title>
                    <Pickups customerId={customer.id} />
                </div>
                <div className="section">
                    <Title>Produkter</Title>
                    <Products customer={customer} />
                </div>
                <Orders customerId={customer.id} />
            </Container>
        );
    }
}

export default withRouter(Component);
