const PickupMessage = {
    PICKUP_TOO_FAR_IN_FUTURE:
        "Det gick inte att boka upphämtning. Försök igen med ett datum närmare i tiden eller gör om bokningen imorgon.",
    INVALID_TIME_ERROR: "Det gick inte att boka upphämtning. Försök igen.",
    CUT_OFF_TIME_PASSED_ERROR:
        "Din upphämtning bokades för sent på dagen och gick därför inte igenom. Boka upphämtning till en senare dag.",
    UNKNOWN_ERROR: "Det gick inte att boka upphämtning. Försök igen.",
    UNKNOWN_PICKUP_ADDRESS: "Upphämtningen kunde inte bokas på denna adress.",
    TIMEOUT:
        "Bokningen av upphämtning tog för lång tid för transportören, försök snart igen!",
};

export { PickupMessage };
