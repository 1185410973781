import { has } from "lodash";
import React from "react";

const PickupModeSelector = ({
    isManualPickup,
    isPickupModeSelected,
    setIsManualPickup,
    setIsPickupModeSelected,
    hasShipments,
}) => {
    const buttonStyle = hasShipments
        ? `cursor-pointer p-4 bg-white rounded-lg shadow-inner border-indigo-700 justify-center items-center gap-2.5 flex ${
              isPickupModeSelected && !isManualPickup ? "border-2" : "border"
          }`
        : `p-4 bg-white rounded-lg shadow-inner bg-gray-300 border-gray-300 justify-center items-center gap-2.5 flex border`;

    return (
        <div className="p-8 mb-6 rounded border border-zinc-100 flex-col justify-start items-start gap-6 inline-flex">
            <div className="text-stone-400 text-2xl font-normal">
                Steg 1 - Välj hur du vill skriva in ditt gods:
            </div>
            <div className="justify-start items-start gap-8 inline-flex">
                <div
                    className={buttonStyle}
                    onClick={() => {
                        if (!hasShipments) return;
                        setIsManualPickup(false);
                        setIsPickupModeSelected(true);
                    }}
                >
                    <div
                        className={`text-center ${
                            hasShipments ? "text-indigo-700" : "text-white"
                        } text-base font-normal uppercase`}
                    >
                        Välj bland försändelser
                    </div>
                </div>

                <div
                    className={`cursor-pointer p-4 bg-white rounded-lg shadow-inner border-indigo-700 justify-center items-center gap-2.5 flex ${
                        isPickupModeSelected && isManualPickup
                            ? "border-2"
                            : "border"
                    }`}
                    onClick={() => {
                        setIsManualPickup(true);
                        setIsPickupModeSelected(true);
                    }}
                >
                    <div className="text-center text-indigo-700 text-base font-normal uppercase">
                        Skriv in manuellt
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PickupModeSelector;
