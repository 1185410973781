import Input from "components/OldInput";
import Context from "context/Global";
import { priceParser } from "helpers/StringParser";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import { hasExpiredIntegration } from "../../../utils/ExpiryIntegrationsUtils";
import Alert, { AlertTypes } from "../../../components/Alerts/Alert";
import { match } from "../../../helpers/Match";
import { getIntegrationImage } from "../../../helpers/Logos";

let Container = styled.div`
    > .integration {
        padding: 1rem;

        > .title {
            font-size: 2rem;
        }

        > .plans {
            display: flex;
            gap: 1rem;

            > .plan {
                background: #fff;
                padding: 1rem;
                border: 1px solid #aaa;
                border-radius: 4px;
                text-decoration: none;
                color: #000;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            > .create {
                padding: 1rem;
                text-decoration: none;
                color: #000;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 100px;
                align-self: center;
                transition: all 0.3s ease;

                &:hover {
                    background: #f0f0f0;
                }
            }
        }
    }
`;

function View(props) {
    const context = useContext(Context);
    const [integrations, setIntegrations] = useState([]);

    useEffect(() => {
        loadIntegrations();
    }, []);

    async function loadIntegrations() {
        setIntegrations(await Api.getIntegrations());
    }

    async function togglePlan({ plan, active }) {
        await Api.updateIntegrationPlan({
            planId: plan.id,
            active: active,
        });
        loadIntegrations();
    }

    return (
        <Container>
            {hasExpiredIntegration(context?.integrationsExpiry) && (
                <>
                    <h2 className={"title text-gray-500"}>
                        Dina utgångna integrationer:
                    </h2>
                    <div className={"p-4"}>
                        <Alert type={AlertTypes.ERROR}>
                            Du har integrationer som har utgått. Uppdatera nu
                            för att fortsätta importera förändringar
                            automatiskt.
                        </Alert>
                    </div>
                    <div className={"list"}>
                        {context?.integrationsExpiry
                            ?.filter(({ status }) => status === "EXPIRED")
                            .map(({ integration }) => {
                                return (
                                    <div className={"flex flex-col"}>
                                        <Link
                                            className="item border-4 border-red-600"
                                            to={`/addons/${integration.id}`}
                                            key={integration.id}
                                        >
                                            <img
                                                src={`/images/icons/${getIntegrationImage(
                                                    integration.type,
                                                )}`}
                                                alt={integration.type}
                                            />
                                        </Link>
                                        <p
                                            className={
                                                "text-center text-gray-400 text-sm mt-2"
                                            }
                                        >
                                            (Utgången)
                                        </p>
                                    </div>
                                );
                            })}
                    </div>
                </>
            )}
            {integrations.map((integration) => {
                return (
                    <div className="integration" key={integration.id}>
                        <div className="title">{integration.name}</div>
                        <div className="plans">
                            {integration.plans.map((plan) => {
                                return (
                                    <div className="plan" key={plan.id}>
                                        {priceParser(plan.price)} SEK /{" "}
                                        {plan.paymentInterval}
                                        <Input
                                            type="toggle"
                                            value={plan.active}
                                            onChange={(active) => {
                                                togglePlan({ plan, active });
                                            }}
                                        />
                                    </div>
                                );
                            })}
                            <Link
                                to={`/admin/addons/${integration.id}/createplan`}
                                className="create"
                                key="createplan"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"
                                    />
                                </svg>
                            </Link>
                        </div>
                    </div>
                );
            })}
        </Container>
    );
}

export default View;
