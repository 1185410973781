import Context from "context/Global";
import React, {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import styled from "styled-components/macro";
import CompanyForm from "./CompanyForm";

let Container = styled.div`
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 16px;
`;

let Content = styled.div`
    > .parties {
        display: flex;
        flex-direction: row;

        > .swap {
            width: 10%;
            margin-top: 200px;
            display: flex;
            justify-content: center;
            align-items: flex-start;

            > .button {
                padding: 1rem;
                cursor: pointer;
            }
        }

        > .party {
            width: calc(45%);
            display: flex;
            flex-direction: column;

            > .title {
                display: flex;
                flex-direction: row;
                align-items: center;
                align-items: center;

                > .text {
                    font-size: 1.3rem;
                    color: rgba(0, 0, 0, 0.6);
                    padding: 0 1em 0.5em;
                }
            }
        }
    }

    > .loader {
        width: 100%;
        height: 200px;
    }

    > .buttons {
        margin-top: 16px;
        display: flex;
        flex-direction: row;

        > .toggle {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: auto;
            padding: 0 16px;

            > .input {
                margin-left: 8px;
            }
        }
    }

    > .sectionContainer {
        padding: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        background: #fff;
        font-size: 12px;

        .buttons {
            margin-bottom: 8px;
            display: flex;
            align-items: center;
        }
    }

    .pickupAddress {
        display: flex;
        flex-direction: row;

        > .label {
            margin-left: 16px;
        }
    }
`;

function QuotationRoute({ onChange, ...props }, ref) {
    const context = useContext(Context);
    const senderRef = useRef();
    const receiverRef = useRef();
    const [loading, setLoading] = useState(false);

    const [sender, setSender] = useState(props.sender || null);
    const [receiver, setReceiver] = useState(props.receiver || null);
    const [invalidSenderParams, setInvalidSenderParams] = useState(null);
    const [invalidReceiverParams, setInvalidReceiverParams] = useState(null);
    const [customsRequired, setCustomsRequired] = useState(false);

    useEffect(() => {
        if (receiver) {
            receiverRef.current.set(receiver);
        }
        if (sender) {
            senderRef.current.set(sender);
        } else {
            populateSenderWithDefaults();
        }
    }, []);

    const validate = () => {
        return senderRef.current.validate() && receiverRef.current.validate();
    };
    const clear = () => {
        if (senderRef.current) {
            senderRef.current.clear();
            populateSenderWithDefaults();
        }
        if (receiverRef.current) {
            receiverRef.current.clear();
        }
    };

    function populateSenderWithDefaults() {
        if (senderRef.current) {
            senderRef.current.set({
                countryCode: context.user.customer.countryCode,
                postalCode: context.user.customer.postalCode,
                postalTown: context.user.customer.postalTown,
                city: context.user.customer.city,
                addressLine1: context.user.customer.addressLine1,
                addressLine2: context.user.customer.addressLine2,
                addressLine3: context.user.customer.addressLine3,
                dhlPalletExchangeNumber:
                    context.user.customer.dhlPalletExchangeNumber,
                contact: {
                    company: context.user.customer.name,
                    vatNumber: context.user.customer.vatNumber,
                    name: context.user.name,
                    phoneNumber: context.user.phoneNumber,
                    email: context.user.email,
                },
            });
        }
    }

    useImperativeHandle(ref, () => {
        return {
            validate: validate,
            clear: clear,
        };
    });

    useEffect(() => {
        if (onChange) {
            onChange({
                sender: sender,
                receiver: receiver,
            });
        }
    }, [sender, receiver]);

    useEffect(() => {
        if (props.invalidParams) {
            let invalidSenderParams = [];
            let invalidReceiverParams = [];

            props.invalidParams.forEach((param) => {
                let parts = param.split("/");

                if (parts[0] === "to") {
                    invalidReceiverParams.push(parts[1]);
                } else if (parts[0] === "from") {
                    invalidSenderParams.push(parts[1]);
                }
            });

            setInvalidSenderParams(invalidSenderParams);
            setInvalidReceiverParams(invalidReceiverParams);
        }
    }, [props.invalidParams]);

    function swapParties() {
        const currentSender = sender;
        const currentReceiver = receiver;
        senderRef.current.set(currentReceiver);
        receiverRef.current.set(currentSender);
    }

    function requireEmail() {
        return sender?.countryCode !== "SE" || receiver?.countryCode !== "SE";
    }

    return (
        <Container>
            <Content>
                <div className="parties">
                    <div className="party sender">
                        <div className="title">
                            <div className="text">Avsändare</div>
                        </div>
                        <CompanyForm
                            ref={senderRef}
                            customsRequired={customsRequired}
                            invalidParams={invalidSenderParams}
                            onChange={setSender}
                            enableDifferentPickupAddress={true}
                            requireEmail={requireEmail()}
                            isReceiver={false}
                        />
                    </div>
                    <div className="swap">
                        <img
                            src="/images/icons/swap-arrow.svg"
                            alt="info"
                            className="cursor-pointer"
                            onClick={swapParties}
                        />
                    </div>
                    <div className="party recipient">
                        <div className="title">
                            <div className="text">Mottagare</div>
                        </div>
                        <CompanyForm
                            ref={receiverRef}
                            customsRequired={customsRequired}
                            invalidParams={invalidReceiverParams}
                            onChange={setReceiver}
                            enablePrivateCustomer={true}
                            requireEmail={requireEmail()}
                            isReceiver={true}
                        />
                    </div>
                </div>
            </Content>
        </Container>
    );
}

export default forwardRef(QuotationRoute);
