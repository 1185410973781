import Input from "components/OldInput";
import axios from "axios";
import Loader from "components/Loader/Loader";
import Table from "components/Table";
import Context from "context/Global";
import React, { useContext, useEffect, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import OrderRow from "./Row";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    box-sizing: border-box;

    > .header {
        padding: 1em;
        display: flex;
        flex-direction: row;

        > .input {
            display: flex;
            margin-right: 1rem;
            align-items: center;

            > .title {
                padding-right: 1rem;
            }
        }

        .pagination {
            display: flex;
            align-items: center;
            margin-left: auto;
            cursor: pointer;

            > .previous,
            > .next {
                width: 16px;
                height: 16px;
                padding: 0 8px;
                box-sizing: content-box;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &.is-disabled {
                    color: #aaa;
                    pointer-events: none;
                }

                > svg {
                    width: 100%;
                    height: 100%;
                }
            }
            > .previous {
                transform: rotateZ(180deg);
            }
        }
    }
`;

function View() {
    const [loading, setLoading] = useState(true);
    const context = useContext(Context);
    const [drafts, setDrafts] = useState([]);
    const [searchstring, setSearchstring] = useState("");
    const [limit, setLimit] = useState(200);
    const [offset, setOffset] = useState(0);
    const [totalDrafts, setTotalDrafts] = useState(0);
    const cancelSearchTokenRef = useRef();

    async function loadDrafts() {
        setLoading(true);
        if (cancelSearchTokenRef.current) {
            cancelSearchTokenRef.current.cancel();
        }
        cancelSearchTokenRef.current = axios.CancelToken.source();
        const { drafts, count } = await Api.getDrafts({
            customerId: context.user.customer.id,
            query: searchstring,
            limit: limit,
            offset: offset,
            cancelToken: cancelSearchTokenRef.current.token,
        });
        setDrafts(drafts);
        setTotalDrafts(count);

        context.setUser(await Api.getCurrentUser());
        setLoading(false);
    }

    useEffect(() => {
        loadDrafts();
    }, [searchstring, limit, offset]);

    function toggleLimit() {
        if (limit < 200 && totalDrafts > 100) {
            setLimit(200);
        } else if (limit < 500 && totalDrafts > 200) {
            setLimit(500);
        } else if (limit < 1000 && totalDrafts > 500) {
            setLimit(1000);
        } else if (limit < 5000 && totalDrafts > 1000) {
            setLimit(5000);
        } else {
            setLimit(100);
        }
    }

    function previousPage() {
        setOffset(offset - limit < 0 ? 0 : offset - limit);
    }
    function nextPage() {
        if (offset + limit < totalDrafts) {
            setOffset(offset + limit);
        }
    }

    return (
        <Container>
            <div className="header">
                <div className="input">
                    <Input
                        type="text"
                        onChange={setSearchstring}
                        placeholder={"Sök..."}
                    />
                </div>
                <div className="pagination">
                    <div
                        className={`previous ${
                            offset === 0 ? "is-disabled" : ""
                        }`}
                        onClick={previousPage}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path fill="currentColor" d="M6 0l12 12-12 12z" />
                        </svg>
                    </div>
                    <div className="showing" onClick={toggleLimit}>
                        Visar {totalDrafts ? offset + 1 : 0} -{" "}
                        {totalDrafts < offset + limit
                            ? totalDrafts
                            : offset + limit}{" "}
                        av {totalDrafts}
                    </div>
                    <div
                        className={`next ${
                            offset + limit > totalDrafts ? "is-disabled" : ""
                        }`}
                        onClick={nextPage}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path fill="currentColor" d="M6 0l12 12-12 12z" />
                        </svg>
                    </div>
                </div>
            </div>
            <Table>
                <thead>
                    <tr>
                        <th>Skapad</th>
                        <th>Referens</th>
                        <th>Avsändare</th>
                        <th>Mottagare</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {loading && (
                        <tr>
                            <td>
                                <Loader />
                            </td>
                        </tr>
                    )}
                    {!loading &&
                        drafts.map((draft) => {
                            return (
                                <OrderRow
                                    key={draft.id}
                                    order={draft}
                                    refresh={loadDrafts}
                                />
                            );
                        })}
                </tbody>
            </Table>
        </Container>
    );
}

export default View;
