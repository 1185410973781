import Context from "context/Global";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

let Container = styled.div`
	width: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
    box-sizing: border-box;
    padding: 1rem 0;


    > .buttons {
        padding: 1rem;
    }

    .title {
        font-size: 2rem;
        display: flex;
        width: 100%;
    }

    > .columnContainer {
        display: flex;
        flex-direction: row;

        > .versions {
            width: 50%;
            box-sizing: border-box;
            padding: 0 1rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            > .list > .version {
                cursor: pointer;
                padding: 4px 8px;
                border-radius: 4px;


                &:hover {
                    background: var(--primary-color--hover);
                }

                &.is-selected {
                    background: var(--primary-color);
                    cursor: default;
                }
            }

            > .buttons {
                padding: 16px 0;
            }
        }

        > .servers {
            width: 50%;
            box-sizing: border-box;
            padding: 0 1rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            > .list {
                display: flex;
                flex-direction: column;

                > .server {
                    padding: 16px;
                    border-radius: 4px;
                    border: 1px solid rgba(0,0,0,.2);
                    display: flex;
                    flex-direction: row;
                    margin: 0 16px 16px 0;
                    align-items: center;

                    > .icon {
                        width: 48px;
                        height: 100%;
                        color: rgba(0,200,0,0.4);
                        margin: 0 16px;

                        > svg {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    > .information {
                        margin-left 16px;
                        th {
                            padding-right: 8px;
                        }
                    }

                    &.is-selected {
                        background: var(--primary-color);
                        cursor: default;
                    }
                }
            }

            > .buttons {
                padding: 16px 0;
            }
        }
    }
`;

function View(props) {
    const context = useContext(Context);

    useEffect(() => {
        if (!context.user.administrator) {
            props.history.replace("/admin");
        }
    }, []);

    return (
        <Container>
            <div className="buttons">
                <Link
                    to="/admin/system/products"
                    className="c-button c-button--raised"
                >
                    Hantera produkter
                </Link>
            </div>
            <div className="columnContainer">
                <div className="servers">
                    <div className="title">Script</div>
                    <div className="buttons">
                        Inga script tillgängliga just nu.
                        {/* <button className="c-button c-button--raised" onClick={() => {
                            Api.runScript({
                                script: 'firstshipment'
                            });
                        }}>First shipment</button> */}
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default View;
