import React, { useContext, useEffect, useState } from "react";
import ReceiverPaysTerms from "../ReceiverPaysTerms";
import ConfirmModal from "../../../../../components/ConfirmModal";
import Api from "../../../../../services/Api/Api";
import toast from "react-hot-toast";
import Context from "../../../../../context/Global";
import { calculateSubscriptionEndDate } from "helpers/ReceiverPays";

const ApprovedTerms = () => {
    const [isChevronOpen, setIsChevronOpen] = useState(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [
        isReactivateSubscriptionModalOpen,
        setIsReactivateSubscriptionModalOpen,
    ] = useState(false);
    const context = useContext(Context);
    const userSettings = context.user.customer.settings;
    const receiverPays = context.user.customer.settings.receiverPays;
    const [subscriptionEndsAt, setSubscriptionEndsAt] = useState(null);
    const { subscriptionType, billingType } = userSettings.receiverPays;

    useEffect(() => {
        if (receiverPays?.subscriptionEndsAt) {
            setSubscriptionEndsAt(receiverPays.subscriptionEndsAt);
        } else {
            setSubscriptionEndsAt(null);
        }
    }, [receiverPays]);

    async function cancelSubscription() {
        try {
            const result = await Api.updateCustomer({
                customerId: context.user.customer.id,
                settings: {
                    ...userSettings,
                    receiverPays: {
                        ...receiverPays,
                        cancelledAt: new Date().toISOString(),
                        subscriptionEndsAt: calculateSubscriptionEndDate(
                            receiverPays.activatedAt,
                            billingType,
                        ),
                    },
                },
            });
            if (result) {
                const updatedUser = {
                    ...context.user,
                    customer: {
                        ...context.user.customer,
                        settings: {
                            ...userSettings,
                            receiverPays: {
                                ...receiverPays,
                                cancelledAt: new Date().toISOString(),
                                subscriptionEndsAt:
                                    calculateSubscriptionEndDate(
                                        receiverPays.activatedAt,
                                        billingType,
                                    ),
                            },
                        },
                    },
                };
                context.setUser(updatedUser);
            }
            toast.success("Abonnemanget har avslutats");
        } catch (error) {
            toast.error(
                "Oväntat fel uppstod. Försök igen senare eller kontakta support",
            );
        }
    }

    async function reactivateSubscription() {
        const updatedSettings = {
            ...userSettings,
            receiverPays: {
                ...receiverPays,
                cancelledAt: undefined,
                subscriptionEndsAt: undefined,
            },
        };
        try {
            const result = await Api.updateCustomer({
                customerId: context.user.customer.id,
                settings: updatedSettings,
            });
            if (result) {
                const updatedUser = {
                    ...context.user,
                    customer: {
                        ...context.user.customer,
                        settings: updatedSettings,
                    },
                };
                context.setUser(updatedUser);
            }
            toast.success("Avslutningen har ångrats");
        } catch (error) {
            toast.error(
                "Oväntat fel uppstod. Försök igen senare eller kontakta support",
            );
        }
    }

    return (
        <div>
            <div>
                <p className="text-2xl mb-4">Villkor för mottagarfrakt</p>
                <div className="flex items-center">
                    <img
                        src="/images/icons/check-circle.svg"
                        alt="Check circle"
                    />
                    <p className="text-xl ml-2 align-middle">Du har godkänt</p>
                    <button
                        className="flex items-center"
                        onClick={() => setIsChevronOpen(!isChevronOpen)}
                    >
                        <span className="text-xl ml-1.5 underline text-blue-700">
                            villkoren
                        </span>
                        {isChevronOpen ? (
                            <img
                                src="/images/icons/chevron-up.svg"
                                alt="Chevron up"
                            />
                        ) : (
                            <img
                                src="/images/icons/chevron-down.svg"
                                alt="Chevron down"
                            />
                        )}
                    </button>
                </div>
            </div>

            {subscriptionType && billingType && (
                <div className="mt-4 w-1/3 bg-gray-100 p-4 rounded-lg shadow">
                    <p className="text-gray-700 font-bold">
                        Abonnemang:{" "}
                        <span className="font-normal">
                            {subscriptionType === "unlimited"
                                ? "Obegränsad"
                                : "Begränsad (20 fraktetiketter per månad)"}
                        </span>
                    </p>
                    <p className="text-gray-700 font-bold">
                        Fakturering:{" "}
                        <span className="font-normal">
                            {subscriptionEndsAt
                                ? "Abonnemang avslutat"
                                : billingType === "monthly"
                                ? "Månadsvis"
                                : "Årsvis"}
                        </span>
                    </p>
                    {!subscriptionEndsAt && (
                        <p className="text-gray-700 font-bold">
                            Förnyas:{" "}
                            <span className="font-normal">
                                {
                                    calculateSubscriptionEndDate(
                                        receiverPays.activatedAt,
                                        billingType,
                                    ).split("T")[0]
                                }
                            </span>
                        </p>
                    )}
                </div>
            )}

            {subscriptionEndsAt && (
                <div className="mt-4">
                    <p className="font-light">
                        Ditt abonnemang är aktivt till och med{" "}
                        {subscriptionEndsAt?.split("T")[0]}
                    </p>
                    <button
                        onClick={() =>
                            setIsReactivateSubscriptionModalOpen(true)
                        }
                        className={
                            "mt-4 w-1/6 px-4 py-2 text-white rounded-md bg-buttonColor hover:bg-primary500"
                        }
                        disabled={false}
                    >
                        Återaktivera abonnemang
                    </button>
                </div>
            )}

            {isChevronOpen && (
                <div className="w-1/2 mt-4 border-solid rounded-md border-2 border-neutral-600 overflow-auto max-h-[500px] p-4 text-gray-800">
                    <ReceiverPaysTerms />
                </div>
            )}
            {!subscriptionEndsAt && (
                <button
                    onClick={() => setIsCancelModalOpen(true)}
                    className={
                        "mt-4 w-1/6 px-4 py-2 text-white rounded-md bg-red-600 hover:bg-red-500"
                    }
                    disabled={false}
                >
                    Avsluta abonnemang
                </button>
            )}
            <ConfirmModal
                isOpen={isCancelModalOpen}
                setIsOpen={setIsCancelModalOpen}
                onConfirm={() => {
                    cancelSubscription();
                    setIsCancelModalOpen(false);
                }}
                title={"Bekräfta åtgärd"}
                description={`Är du säker på att du vill avsluta ditt abonnemang?`}
            />
            <ConfirmModal
                isOpen={isReactivateSubscriptionModalOpen}
                setIsOpen={setIsReactivateSubscriptionModalOpen}
                onConfirm={() => {
                    reactivateSubscription();
                    setIsReactivateSubscriptionModalOpen(false);
                }}
                title={"Bekräfta åtgärd"}
                description={`Är du säker på att du vill ångra ditt avsluta av abonnemanget?`}
            />
        </div>
    );
};

export default ApprovedTerms;
