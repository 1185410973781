import React from "react";
import BatchTable from "./BatchTable/BatchTable";

const BatchPage = () => {
    return (
        <div className="pb-10">
            <BatchTable />
        </div>
    );
};

export default BatchPage;
