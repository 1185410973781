import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components/macro";
import moment from "moment";
import { Link } from "react-router-dom";

import Loader from "components/Loader/Loader";
import Api from "services/Api/Api";
import Table from "components/Table";

import Context from "context/Global";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;

    > .buttons {
        padding: 1rem;
    }

    > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;

        > .title {
            font-size: 2rem;
            width: 100%;
        }

        > .actions {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

function Component(props) {
    const context = useContext(Context);

    const [products, setProducts] = useState(null);
    const [creatingProduct, setCreatingProduct] = useState(false);

    useEffect(() => {
        if (context.user.administrator) {
            init();
        } else {
            props.history.replace("/admin");
        }
    }, []);

    function init() {
        loadProducts();
    }

    async function loadProducts() {
        setProducts(await Api.getProducts());
    }

    async function createProduct() {
        let productName = window.prompt("Namn på nya produkten ");

        if (productName && productName.trim()) {
            setCreatingProduct(true);
            await Api.createProduct({
                product: {
                    name: productName.trim(),
                },
            });
            await loadProducts();
            setCreatingProduct(false);
        }
    }

    async function deleteProduct(product) {
        if (
            window.confirm(
                `Är du säker på att du vill ta bort produkten ${product.name}?`,
            )
        ) {
            await Api.deleteProduct({
                productId: product.id,
            });
            loadProducts();
        }
    }

    if (!products) {
        return null;
    }

    return (
        <Container>
            <div className="header">
                <div className="title">Products</div>
                <div className="actions">
                    <button
                        className="c-button c-button--raised"
                        disabled={creatingProduct}
                        onClick={createProduct}
                    >
                        Skapa produkt
                    </button>
                </div>
            </div>
            <Table>
                <thead>
                    <tr>
                        <th>Produkt</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((product) => {
                        return (
                            <tr key={product.id}>
                                <td className="cell">{product.name}</td>
                                <td className="cell">
                                    <div
                                        className="c-button c-button--ghost"
                                        onClick={() => deleteProduct(product)}
                                    >
                                        Ta bort
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </Container>
    );
}

export default Component;
