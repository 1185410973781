import InputMultiToggle from "components/Input/MultiToggle";
import Context from "context/Global";
import { priceParser } from "helpers/StringParser";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import { downloadInvoiceFile, handleDownloadS3 } from "../../../utils/s3";
import styled from "styled-components/macro";
import toast from "react-hot-toast";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > .title {
        padding: 16px;
        font-size: 2em;
    }

    > .subTitle {
        padding: 0 16px;
    }

    > .documents {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 16px;

        > .title {
            font-size: 1.2em;
        }

        > .itemGroup {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px;
            border-radius: 4px;
            min-width: 500px;
            > .item {
                display: flex;
                flex-direction: row;
                padding: 4px 8px 4px 2px;
                cursor: pointer;
                align-items: center;
                border-radius: 4px;
                color: rgba(0, 0, 0, 0.8);
                text-decoration: none;

                &:hover {
                    background: rgba(0, 0, 0, 0.05);
                }

                > .title {
                    font-size: 16px;
                    padding-right: 32px;
                    margin-right: auto;
                }

                > .tag {
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    padding: 0em 1em;
                    font-size: 0.9em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 1em;
                    background: #fff;
                    border-radius: 3px;
                }

                > .timestamp {
                    font-size: 16px;
                }
            }

            > .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                width: 32px;
                height: 32px;

                &:hover {
                    background: rgba(0, 0, 0, 0.1);
                    border-radius: 50%;
                }
            }
        }
    }

    > table {
        width: 100%;
        margin-bottom: 16px;
        flex-direction: column;

        > thead {
            > tr {
                > th {
                    background: #fafafa;
                    padding: 1em 0;
                    border-bottom: 1px solid #ddd;
                    border-top: 1px solid #ddd;
                    color: #333;
                    font-weight: normal;

                    &:first-child {
                        padding-left: 1em;
                    }

                    &:last-child {
                        padding-right: 1em;
                    }
                }
            }
        }

        > tbody {
            > tr {
                > td {
                    background: #fff;
                    padding: 0.5em 0;
                    cursor: pointer;

                    &:first-child {
                        padding-left: 1em;
                    }

                    &:last-child {
                        padding-right: 1em;
                    }

                    &.service {
                        > img {
                            width: 32px;
                            object-size: contain;
                        }
                    }
                }

                &:hover > td {
                    background: rgba(250, 250, 250, 1);
                }
            }
        }
    }

    > .setting {
        padding: 1rem;
    }

    > .buttons {
        padding: 0 1rem;
    }

    > .information {
        padding: 0 1rem;
    }

    > .orders {
        padding: 1rem;

        > .title {
            font-size: 2rem;
        }

        > .list {
            > .order {
                margin-bottom: 1rem;
            }
        }
    }
`;

function View(props) {
    const context = useContext(Context);
    const [invoice, setInvoice] = useState(null);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [emailResponse, setEmailResponse] = useState(false);
    const [specificationFileType, setSpecificationFileType] = useState();
    useEffect(() => {
        loadInvoice();
    }, []);

    useEffect(() => {
        if (invoice) {
            setSpecificationFileType(
                invoice.customer.settings.invoiceSpecificationFileType,
            );
        }
    }, [invoice]);

    async function loadInvoice() {
        try {
            setInvoice(
                await Api.getInvoice({
                    invoiceId: props.invoiceId,
                }),
            );
        } catch (error) {
            switch (error.response.status) {
                case 403: {
                    props.history.goBack();
                    break;
                }
                default: {
                    console.error(error);
                    props.history.goBack();
                    break;
                }
            }
        }
    }

    async function emailInvoice() {
        let update = window.confirm(
            `Skicka faktura och spec till ${invoice.customer.invoiceEmail}?`,
        );
        if (update) {
            setSendingEmail(true);

            try {
                let response = await Api.emailInvoice({
                    invoiceId: props.invoiceId,
                    specificationFileType,
                });
                await loadInvoice();
                if (response.email) {
                    setEmailResponse(
                        "Fakturan skickades till " + response.email,
                    );
                }
            } catch (error) {
                switch (error.response.data.code) {
                    case "INVOICE_EMAIL_MISSING": {
                        setEmailResponse("Kunden saknar e-postadress");
                        break;
                    }
                    default: {
                        setEmailResponse(
                            "Ett okänt fel uppstod. Kontakta tech-teamet!",
                        );
                    }
                }
            }

            setSendingEmail(false);
        }
    }

    if (!invoice) {
        return null;
    }

    return (
        <Container>
            <div className="title">Faktura {invoice.externalId}</div>
            <div className="subTitle">Kund: {invoice.customer.name}</div>
            <div className="subTitle">
                Värde: {priceParser(invoice.net)} SEK
            </div>
            <div className="documents">
                <div className="title">Dokument</div>
                {invoice.documents.length === 0 && (
                    <div className="emptyState">
                        Det finns inga dokument tillgängliga ännu.
                    </div>
                )}
                {invoice.documents.map((doc) => {
                    const handleDownload = async ({
                        objectKey,
                        fileName,
                        shouldDownload,
                    }) => {
                        if (shouldDownload) {
                            await downloadInvoiceFile({
                                url: `${
                                    process.env.REACT_APP_API_URL
                                }/api/s3/invoice?objectKey=${encodeURIComponent(
                                    objectKey,
                                )}`,
                                desiredFileName: fileName,
                            });
                            return;
                        } else {
                            await handleDownloadS3({
                                url: `${
                                    process.env.REACT_APP_API_URL
                                }/api/s3/invoice?objectKey=${encodeURIComponent(
                                    objectKey,
                                )}`,
                                desiredFileName: fileName,
                            });
                        }
                    };
                    return (
                        <div className="itemGroup">
                            <a
                                key={doc.id}
                                onClick={() =>
                                    handleDownload({
                                        objectKey: doc.fileId,
                                        fileName: doc.title,
                                        shouldDownload: false,
                                    })
                                }
                                className="item"
                            >
                                <div className="title">{doc.title}</div>
                            </a>
                            <a
                                className="icon"
                                onClick={() =>
                                    handleDownload({
                                        objectKey: doc.fileId,
                                        fileName: doc.title,
                                        shouldDownload: true,
                                    }).catch(() => {
                                        toast.error(
                                            "Kunde inte ladda ner filen, försök igen senare.",
                                        );
                                    })
                                }
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M6 13h4v-7h4v7h4l-6 6-6-6zm16-1c0 5.514-4.486 10-10 10s-10-4.486-10-10 4.486-10 10-10 10 4.486 10 10zm2 0c0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12 12-5.373 12-12z" />
                                </svg>
                            </a>
                        </div>
                    );
                })}
            </div>
            {invoice.emailDate && (
                <>
                    <div className="subTitle">
                        Fakturan skickades:{" "}
                        {moment(invoice.emailDate).calendar()}
                    </div>
                </>
            )}
            <div className="setting">
                <InputMultiToggle
                    options={[
                        {
                            title: "Bifoga specifikation som excel-fil",
                            value: "excel",
                        },
                        {
                            title: "Bifoga specifikation som PDF",
                            value: "pdf",
                        },
                    ]}
                    value={
                        invoice.customer.settings.invoiceSpecificationFileType
                    }
                    onChange={setSpecificationFileType}
                />
            </div>
            <div className="buttons">
                <div
                    className="c-button c-button--raised"
                    disabled={sendingEmail}
                    onClick={emailInvoice}
                >
                    Skicka faktura
                </div>
            </div>
            {emailResponse && (
                <div className="information">{emailResponse}</div>
            )}
            {!!invoice.orders?.length && (
                <div className="orders">
                    <div className="title">
                        Externa fakturanummer denna faktura gäller
                    </div>
                    <div className="list">
                        {[
                            ...new Set(
                                invoice.orders.map((order) => {
                                    return order.externalInvoice;
                                }),
                            ),
                        ].map((number) => {
                            return (
                                <div className="number" key={number}>
                                    {number}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            {!!invoice.orders?.length && (
                <div className="orders">
                    <div className="title">Ordrar som fakturan gäller</div>
                    <div className="list">
                        {invoice.orders.map((order) => {
                            return (
                                <div className="order" key={order.id}>
                                    <div className="title">
                                        ID:{" "}
                                        <a href={`/orders/${order.id}`}>
                                            {order.id}
                                        </a>
                                    </div>
                                    <div className="reference">
                                        Referens: {order.reference}
                                    </div>
                                    <div className="externalInvoiceNumber">
                                        Externt fakturanummer:{" "}
                                        {order.externalInvoice}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </Container>
    );
}

export default View;
