export default {
    ZENDR: "ZENDR",
    FORTNOX: "FORTNOX",
    STARWEB: "STARWEB",
    VISMA: "VISMA",
    LOG_TRADE: "LOG_TRADE",
    MONITOR_G4: "MONITOR_G4",
    MONITOR_G5: "MONITOR_G5",
    WOO_COMMERCE: "WOO_COMMERCE",
    SHOPIFY: "SHOPIFY",
    MAGENTO: "MAGENTO",
    VISMA_ADMINISTRATION: "VISMA_ADMINISTRATION",
    BUSINESS_CENTRAL: "BUSINESS_CENTRAL",
    QUICK_BUTIK: "QUICK_BUTIK",
    JOOMLA_VIRTUEMART: "JOOMLA_VIRTUEMART",
    CUSTOM: "CUSTOM",
    ONGOING_WMS: "ONGOING_WMS",
    PYRAMID: "PYRAMID",
    SAGE_X3: "SAGE_X3",
    VISMA_BUSINESS_NXT: "VISMA_BUSINESS_NXT",
    JEEVES: "JEEVES",
    OTHER: "Annat System",
};
