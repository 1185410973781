import Input from "components/OldInput";
import InputMultiToggle from "components/Input/MultiToggle";
import InputPostalCode from "components/Input/PostalCode";
import Loader from "components/Loader/Loader";
import Context from "context/Global";
import React, { Component } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
`;

let Form = styled.form`
    padding: 16px;

    > .button {
        margin-top: 32px;
    }
`;

let Title = styled.form`
    padding: 16px;
    padding-bottom: 4px;
    font-size: 26px;
`;

let cutoffTimeOptions = [];

for (var i = 8; i <= 18; i++) {
    cutoffTimeOptions.push({
        title: ("0" + i).slice(-2) + ":00",
        value: ("0" + i).slice(-2) + ":00",
    });
    cutoffTimeOptions.push({
        title: ("0" + i).slice(-2) + ":30",
        value: ("0" + i).slice(-2) + ":30",
    });
}

class CreateCustomer extends Component {
    customerInputRef = React.createRef();
    settingsInputRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            clientManagers: null,
        };

        this.submit = this.submit.bind(this);
    }

    componentWillMount() {
        // Api.getCustomerGroups().then((response) => {
        // 	let groups = [];
        // 	response.data.forEach((group) => {
        // 		groups.push({
        // 			title: group.name+' ('+Math.round(group.priceMultiplier*100)+'%)',
        // 			value: group.id
        // 		});
        // 	});
        // 	if(groups.length > 0) {
        // 		groups.unshift({
        // 			title: 'Välj grupp (valfritt)',
        // 			value: ""
        // 		});
        // 	}
        // 	else {
        // 		groups.push({
        // 			title: 'Inga grupper tillgängliga',
        // 			value: ""
        // 		});
        // 	}
        // 	this.setState({
        // 		loading: false,
        // 		groups: groups
        // 	});
        // }).catch((response) => {
        // 	this.props.history.push(`/admin/customers/${response.data.id}`);
        // 	this.setState({
        // 		loading: false
        // 	});
        // });
        Api.getClientManagers({
            brandId: this.context.user.brand.id,
            includeOwners: true,
        }).then((clientManagers) => {
            this.setState({
                clientManagers: [
                    {
                        name: "Ingen kundansvarig vald",
                        id: "",
                    },
                ].concat(clientManagers),
            });
        });
    }

    submit(ev) {
        ev.preventDefault();
        if (this.customerInputRef.current.validate()) {
            let customerData = this.customerInputRef.current.value();
            let customerSettings = this.settingsInputRef.current.value();
            this.setState(
                {
                    loading: true,
                },
                () => {
                    Api.createCustomer({
                        customer: customerData,
                    })
                        .then(async (response) => {
                            await Api.updateCustomer({
                                customerId: response.data.id,
                                settings: customerSettings,
                            });
                            this.props.history.push(
                                `/admin/customers/${response.data.id}`,
                            );
                        })
                        .catch((error) => {
                            console.error("Failed to create customer");
                            console.error(error);
                            this.setState({
                                loading: false,
                            });
                        });
                },
            );
        }
        return false;
    }

    render() {
        if (this.state.loading || this.state.clientManagers === null) {
            return (
                <Container>
                    <div className="loader">
                        <Loader />
                    </div>
                </Container>
            );
        } else {
            return (
                <Container>
                    <Title>Ny kund</Title>
                    <Form>
                        <Input
                            ref={this.customerInputRef}
                            type="list"
                            object={{
                                name: {
                                    title: "Företagsnamn",
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    required: true,
                                },
                                clientNumber: {
                                    title: "Kundnummer",
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    required: false,
                                },
                                clientManagerId: {
                                    title: "Kundansvarig",
                                    type: "dropdown",
                                    options: this.state.clientManagers.map(
                                        (clientManager) => {
                                            return {
                                                title: clientManager.name,
                                                value: clientManager.id,
                                            };
                                        },
                                    ),
                                },
                                countryCode: {
                                    title: "Land",
                                    type: "dropdown",
                                    autoComplete: "chrome-off",
                                    disabled: true,
                                    value: "SE",
                                    options: [
                                        {
                                            title: "Sverige",
                                            value: "SE",
                                        },
                                    ],
                                },
                                postalCode: {
                                    type: InputPostalCode,
                                    title: "Postnummer",
                                    autoComplete: "chrome-off",
                                    required: true,
                                    countryCode: "SE",
                                    onPostalTownSelected: (item) => {
                                        this.customerInputRef.current.set({
                                            postalTown: item.value.postalTown,
                                        });
                                    },
                                },
                                postalTown: {
                                    title: "Postort",
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    placeholder: "",
                                    required: true,
                                },
                                addressLine1: {
                                    title: "Adress",
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    placeholder: "",
                                    maxLength: 30,
                                    required: true,
                                },
                                addressLine2: {
                                    title: "",
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    placeholder: "",
                                    maxLength: 30,
                                    required: false,
                                },
                                vatNumber: {
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    title: "Tull-id",
                                    required: true,
                                    helpText:
                                        "Momsnummer, VAT number, Tax number, etc.",
                                },
                                contactName: {
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    title: "Kontaktperson",
                                    required: true,
                                },
                                contactEmail: {
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    title: "Kontakt-mail",
                                    required: true,
                                },
                                contactPhoneNumber: {
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    title: "Kontakt-telefonnummer",
                                    required: true,
                                },
                                labelFormat: {
                                    type: InputMultiToggle,
                                    autoComplete: "chrome-off",
                                    title: "Föredraget format på etiketter",
                                    helpText:
                                        "Används på de leverantörer där det är applicerbart.",
                                    required: true,
                                    options: [
                                        {
                                            title: "Etikett",
                                            value: "label",
                                        },
                                        {
                                            title: "A4 - halv sida",
                                            value: "a4",
                                        },
                                        {
                                            title: "A4 - 2 liggande etiketter",
                                            value: "a4-2-landscape",
                                        },
                                        {
                                            title: "A4 - 3 liggande etiketter",
                                            value: "a4-3-landscape",
                                        },
                                        {
                                            title: "A4 - 2 stående etiketter",
                                            value: "a4-2-portrait",
                                        },
                                        {
                                            title: "A4 - 2 stående etiketter centrerat",
                                            value: "a4-2-portrait-center",
                                        },
                                        {
                                            title: "A4 - 1 stående etikett",
                                            value: "a4-1-portrait",
                                        },
                                        {
                                            title: "Etikettskrivare 107 x 251 mm",
                                            value: "107x251",
                                        },
                                        {
                                            title: "Etikettskrivare 103 x 165 mm",
                                            value: "103x165",
                                        },
                                    ],
                                },
                                invoiceEmail: {
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    title: "Faktura-mail",
                                    required: true,
                                },
                            }}
                            value={{
                                priceMultiplier: 20,
                            }}
                        />
                        <Input
                            ref={this.settingsInputRef}
                            value={{
                                dhlCutoffTime: "09:00",
                            }}
                            type="list"
                            object={{
                                dhlCutoffTime: {
                                    title: "Stopptid DHL",
                                    type: "dropdown",
                                    options: cutoffTimeOptions,
                                    autoComplete: "chrome-off",
                                },
                            }}
                        />
                        <button
                            className="button c-button c-button--raised"
                            onClick={this.submit}
                        >
                            Skapa
                        </button>
                    </Form>
                </Container>
            );
        }
    }
}

CreateCustomer.contextType = Context;

export default CreateCustomer;
