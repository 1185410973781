import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const DisclosureItem = ({ title, children, pickupData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [weekdays, setWeekdays] = useState([]);
    const [hasFixedPickups, setHasFixedPickups] = useState(false);
    const { t } = useTranslation();

    useMemo(() => {
        let weekdays = [];
        weekdays.push({
            name: t("weekdays.monday.char"),
            enabled: !!pickupData.data.weekdays.mon,
        });
        weekdays.push({
            name: t("weekdays.tuesday.char"),
            enabled: !!pickupData.data.weekdays.tue,
        });
        weekdays.push({
            name: t("weekdays.wednesday.char"),
            enabled: !!pickupData.data.weekdays.wed,
        });
        weekdays.push({
            name: t("weekdays.thursday.char"),
            enabled: !!pickupData.data.weekdays.thu,
        });
        weekdays.push({
            name: t("weekdays.friday.char"),
            enabled: !!pickupData.data.weekdays.fri,
        });

        // Check if any of the days have fixed pickups, if yes, set the fixed pickup state to true, otherwise it remains false
        if (weekdays.find((item) => item.enabled === true)) {
            setHasFixedPickups(true);
        } else setHasFixedPickups(false);

        setWeekdays(weekdays);
    }, [pickupData]);

    const handleDisclosureClick = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <div className="flex flex-col">
            <div
                className={
                    "bg-gray-200 p-3 w-full rounded-lg flex items-center justify-between cursor-pointer  transition-all " +
                    (hasFixedPickups
                        ? " bg-blue-400 text-white"
                        : " hover:bg-gray-100")
                }
                onClick={handleDisclosureClick}
            >
                <span
                    className={
                        (hasFixedPickups ? " " : " text-gray-400") +
                        " font-medium "
                    }
                >
                    {title}
                </span>
                <div className="flex gap-3 font-medium items-center">
                    {weekdays.map((day, index) => {
                        return hasFixedPickups ? (
                            <span
                                key={index}
                                className={
                                    (day.enabled
                                        ? ` text-white`
                                        : " text-blue-500 ") +
                                    " pointer-events-none"
                                }
                            >
                                {day.name}
                            </span>
                        ) : (
                            <span
                                key={index}
                                className={" text-gray-400 pointer-events-none"}
                            >
                                {day.name}
                            </span>
                        );
                    })}
                    <span
                        className={
                            (isOpen
                                ? " fill-current rotate-180 transition-all"
                                : " fill-current transition-all") +
                            " ml-5 text-xs  pointer-events-none"
                        }
                    >
                        V
                    </span>
                </div>
                {/* <Caret
          className={
            isOpen
              ? ' fill-current rotate-180 transition-all'
              : 'fill-current transition-all'
          }
        /> */}
            </div>
            {isOpen && <div className="p-3 mt-2">{children}</div>}
        </div>
    );
};

export default DisclosureItem;
