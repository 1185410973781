import Context from "context/Global";
import React, { memo, useContext } from "react";
import InfoIcon from "components/InfoIcon/InfoIconComponent";

const Packages = memo(({ packageRows, selectedQuote }) => {
    const amountOfPackages = packageRows?.reduce(
        (acc, packageRow) => acc + packageRow.count,
        0,
    );

    const item = packageRows?.[0];
    const context = useContext(Context);
    const settings = context.user.customer?.settings;

    const hasWeightOnlyPackages = packageRows?.some(
        (item) => !item.width && !item.height && !item.length,
    );

    return (
        <div className="flex flex-col justify-start text-left">
            {!amountOfPackages && <p className="text-red-400">Inga kollin</p>}
            {packageRows?.length === 1 && (
                <div>
                    <p className="text-sm font-normal">
                        {amountOfPackages}{" "}
                        {amountOfPackages > 1 ? "Kollin" : "Kolli"}
                    </p>

                    {(item.width === 0 &&
                        item.height === 0 &&
                        item.length === 0) ||
                    (!item.width && !item.height && !item.length) ? (
                        <div className="relative z-30">
                            <div className="flex flex-row justify-start items-center">
                                <p
                                    className={
                                        settings?.bookBatchDirectWithoutQuote
                                            ? "text-purple text-sm font-normal"
                                            : "text-red-400 text-sm font-normal"
                                    }
                                >
                                    Inga dimensioner
                                </p>
                                {!selectedQuote && (
                                    <InfoIcon
                                        packagesLength={amountOfPackages}
                                        bookBatchDirectWithoutQuote={
                                            settings?.bookBatchDirectWithoutQuote
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    ) : (
                        <p className="text-sm font-normal">
                            {item.length}x{item.width}x{item.height}cm
                        </p>
                    )}
                    {item.volume > 0 && (
                        <p className="text-sm font-normal">{item.volume}m³</p>
                    )}
                    <p className="text-sm font-normal">{item.weight}kg</p>
                </div>
            )}
            {amountOfPackages > 1 && packageRows.length > 1 && (
                <div className="relative z-30">
                    <div className="flex flex-row justify-start items-center">
                        <p className="text-sm font-normal">
                            {amountOfPackages} Kollin
                        </p>
                        {hasWeightOnlyPackages && !selectedQuote && (
                            <InfoIcon
                                packagesLength={amountOfPackages}
                                bookBatchDirectWithoutQuote={
                                    settings?.bookBatchDirectWithoutQuote
                                }
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
});

export default Packages;
