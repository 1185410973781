function getFedexDisplayData(productType) {
    switch (productType) {
        case "INTERNATIONAL_ECONOMY": {
            return {
                originalName: "Fedex International Economy",
                name: "Fedex Ekonomi",
                isExpress: false,
                tags: [],
            };
        }
        case "INTERNATIONAL_PRIORITY": {
            return {
                originalName: "Fedex International Priority",
                name: "Fedex Express",
                isExpress: true,
                tags: [],
            };
        }
        case "INTERNATIONAL_ECONOMY_FREIGHT": {
            return {
                originalName: "Fedex International Economy Freight",
                name: "Fedex Ekonomi",
                isExpress: false,
                tags: [],
            };
        }
        case "INTERNATIONAL_PRIORITY_FREIGHT": {
            return {
                originalName: "Fedex International Priority Freight",
                name: "Fedex Express",
                isExpress: true,
                tags: [],
            };
        }
        default: {
            return {
                originalName: "Fedex",
                name: "Fedex",
                isExpress: false,
                tags: [],
            };
        }
    }
}

export { getFedexDisplayData };
