import React from "react";

const Input = ({ label, width, placeholder, type, onChange, defaultValue }) => {
    return (
        <div className="flex flex-col" key={defaultValue}>
            <label
                htmlFor="textbox"
                className="pointer-events-none transition-all pb-3 px-2 "
            >
                {label}
            </label>
            <input
                onChange={onChange}
                required
                className={
                    width +
                    " h-10 pl-4 border border-gray-300 rounded-lg outline-none bg-transparent"
                }
                id="textbox"
                type={type}
                placeholder={placeholder}
                defaultValue={defaultValue}
            />
        </div>
    );
};

export default Input;
